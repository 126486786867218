import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

const Lab = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (index) => {
    if (activeItem === index) {
      setActiveItem(null);
    } else {
      setActiveItem(index);
    }
  };

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };
  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };
  useEffect(() => {
    document.title =
      "Why Innovation is Crucial for Business Resilience and Growth";
  }, []);
  return (
    <div className="lab-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/lab-header-img.jpg`}
                alt="Innovation to Drive Business Outcomes"
              />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5 item-wrapper">
                  <figure>
                    <img
                      src={`/img/svg/icons/io-innovationon.svg`}
                      alt="Innovation On"
                    />
                  </figure>
                  <h3>
                    Prioritize Innovation to Drive Business Outcomes Amid
                    Uncertainty
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/xonomy/`}>Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/io/labs/`} className="active">
                    INNOVATION LABS
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/io/`}>INNOVATION ON</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud/`}>EXPERIENCE CLOUD</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-crowd/`}>EXPERIENCE CROWD</NavLink>
                </li>
              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${
                    showExtraLinks ? "active" : ""
                  } inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" /> More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/business4-0/`}>
                          BUSINESS 4.0 STRATEGY
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/aury/`}>AURY PLATFORM</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/ai/`}>AI</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/agnitor/`}>AGNITOR</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/budh/`}>BUDH XONOMY</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/`}>VIRTUOS VUCA</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/zidd/`}>ZIDD E-LEARNING</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/ventures/`}>VENTURES</NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="corp-lead">
            <h2 className="section-main-heading fadeIn">
              Being Future Prepared is a Common Motive for <br />
              <span className="highlight">Innovation Initiative</span>
            </h2>
            <span className="section-desc fadeIn">
              <strong>68%</strong> Percent of corporate business leaders say
              they will{" "}
              <strong>"increase their funding of digital innovation"</strong> as
              a major tactic to sustain growth in the uncertain macro
              environment.
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <h2>Priorities for Innovation Initiative</h2>
                <h3>
                  Scale: 1 = Not at all important to 7 = Extremely important
                </h3>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/innovation-initiative-chart.svg`}
                    alt="Innovation Initiative Chart"
                  />
                </figure>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12 note1">
                n varies; IT and Business Leaders with Innovation
                Responsibilities, excluding unsure/ not applicable
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12 note2">
                Q02: How important are each of the following priorities for your
                organization's innovation initiative? <br />
                Source: 2022 Gartner Justifying and Funding Innovation Survey
              </div>
            </div>
          </section>
          <section className="breaker purple">
            <div className="row page-wrap">
              <div className="col-6">
                <h3>What is Innovation?</h3>
                <h4>
                  Innovation is the execution of new ideas that generate value
                </h4>
                <ul>
                  <li>
                    Enables businesses to{" "}
                    <strong>filter signals from noise</strong> and explore{" "}
                    <strong>novel ideas</strong>
                  </li>
                  <li>
                    Outcome that generates <strong>business value</strong>
                  </li>
                  <li>
                    The importance of{" "}
                    <strong>implementing ("execution")</strong>
                  </li>
                </ul>
              </div>
              <div className="col-5">
                <figure>
                  <img
                    src={`/img/svg/culture-of-innovation-img.svg`}
                    alt="Socrates"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="quote">
            <div className="row page-wrap">
              <div className="col-12">
                <h2>
                  "Our current belief is that{" "}
                  <span className="highlight">
                    companies should create a separate division
                  </span>{" "}
                  that operates under the protection of senior leadership{" "}
                  <span className="highlight">
                    to explore and exploit a new disruptive model
                  </span>
                  ."
                </h2>
                <p>
                  <strong>
                    Clayton M. Christensen et. al. — HBR — "Disruptive
                    innovation"
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div className="row page-wrap sanity">
              <div className="col-5">
                <h3>Is innovation lab the right thing to do?</h3>
              </div>
              <div className="col-5">
                <ul>
                  <li>"Sanity Check"</li>
                  <li>
                    Is the desired goal achievable in the{" "}
                    <strong>innovation lab only</strong>
                  </li>
                  <li>
                    Is the lab <strong>the best tool for the job</strong> at the
                    time?
                  </li>
                  <li>
                    Is the{" "}
                    <strong>lab essential or critical to the future</strong> of
                    the enterprise?
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="key-issues">
            <h2 className="section-main-heading">Key Issues</h2>
            <div className="row page-wrap">
              <div className="col-4 item active">
                <span className="highlight">Why</span>doing it — defining the
                dream
              </div>
              <div className="col-4 item">
                <span className="highlight">How</span>to make the lab "work"
              </div>
              <div className="col-4 item">
                <span className="highlight">What</span>are major pitfalls to
                avoid
              </div>
            </div>
          </section>
          <section className="operating-model">
            <h2 className="section-main-heading">
              Innovation Lab <span className="highlight">Operating Model</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/innovating-lab-operating-model.svg`}
                    alt="Innovation Lab Operating Model"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="key-goals">
            <h2 className="section-main-heading">
              Determine <span className="highlight">Key Goals</span> of an
              Innovation Lab
            </h2>
            <div className="row page-wrap">
              <div className="col-3">
                <figure>
                  <img src={`/img/svg/innovating-lab-key-goals.svg`} alt="" />
                </figure>
              </div>
              <div className="col-3">
                <figure>
                  <img
                    src={`/img/svg/innovating-lab-digital-optimization.svg`}
                    alt=""
                  />
                </figure>
                <ul>
                  <li>Improved Existing Revenue</li>
                  <li>Improve Operating Margin</li>
                  <li>Improve Workforce Productivity</li>
                  <li>Enhance Customer Experience</li>
                  <li>Increase Asset Utilization</li>
                </ul>
              </div>
              <div className="col-3">
                <figure>
                  <img
                    src={`/img/svg/innovating-lab-digital-transformation.svg`}
                    alt=""
                  />
                </figure>
                <ul>
                  <li>Sell Existing Digital Assets</li>
                  <li>Digitize Product or Service</li>
                  <li>Sell Metered Revenue</li>
                  <li>Run a Platform Business</li>
                  <li>Move into New/Adjacent Industry</li>
                </ul>
              </div>
              <div className="col-3">
                <figure>
                  <img
                    src={`/img/svg/innovating-lab-beyond-profit.svg`}
                    alt="Key Goals"
                  />
                </figure>
                <ul>
                  <li>Improve Branding</li>
                  <li>Societal Goals</li>
                  <li>Mitigate Strategic Risk</li>
                  <li>Talent Attraction and Retention</li>
                </ul>
              </div>
            </div>
          </section>
          <section className="supportive-goals">
            <h2 className="section-main-heading">
              <span className="highlight">Supportive Goals</span> of the
              Innovation Lab
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/innovating-lab-supportive-goals.svg`}
                    alt="Supportive Goals"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="key-issues">
            <h2 className="section-main-heading">Key Issues</h2>
            <div className="row page-wrap">
              <div className="col-4 item">
                <span className="highlight">Why</span>doing it — defining the
                dream
              </div>
              <div className="col-4 item active">
                <span className="highlight">How</span>to make the lab "work"
              </div>
              <div className="col-4 item">
                <span className="highlight">What</span>are major pitfalls to
                avoid
              </div>
            </div>
          </section>
          <section className="building-blocks">
            <h2 className="section-main-heading">
              Combine the <span className="highlight">9 Buildings Blocks</span>{" "}
              of <br />
              an Innovation Lab
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/innovation-lab-9-building-blocks.svg`}
                    alt="9 Building Blocks of Innovation Lab"
                  />
                </figure>
              </div>
            </div>
            <h2 className="section-main-heading">
              Buildings Blocks of Innovation Lab <br />
              <span className="highlight">Operating Model</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12 heading">Engage</div>
            </div>
            <div className="row page-wrap">
              <div className="col-4 item">
                <h3>Financials</h3>
                <ul>
                  <li>Estimate base budget to build and operate</li>
                  <li>
                    <strong>Venture fund</strong> and disburse ideas in parts
                  </li>
                  <li>Develop separate business cases to scale</li>
                </ul>
              </div>
              <div className="col-4 item">
                <h3>Decision Rights</h3>
                <ul>
                  <li>
                    Adapt <strong>different styles of governance</strong> based
                    on the requirements
                  </li>
                  <li>Venture fund and disburse ideas in parts</li>
                  <li>Develop separate business cases to scale</li>
                </ul>
              </div>
              <div className="col-4 item">
                <h3>Performance</h3>
                <ul>
                  <li>
                    Create a combination of{" "}
                    <strong>
                      leading indicators and supporting indicators
                    </strong>
                  </li>
                  <li>Venture fund and disburse ideas in parts</li>
                  <li>
                    Balance timeliness and relevance by combining metrics on{" "}
                    <span>input, process, and output</span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="quote">
            <h2 className="section-main-heading">
              <span className="highlight">Case Studies</span> of Professional
              Services Provider
            </h2>
            <div className="row page-wrap">
              <div className="col-11 sub-heading">Performance</div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <h3>Professional Services</h3>
                <h4>
                  You need to have a degree of trust in the innovation leaders.
                  Set <strong>some very strong KPIs</strong> at a board level,
                  and let the innovation leaders{" "}
                  <strong>loose to innovate</strong>. Innovation normally is
                  about the unknown, so you need to{" "}
                  <strong>incentivize problem - solving</strong> (especially for
                  failures) in your metrics while recognizing positive
                  contributions to revenue, quality and customer satisfaction.
                </h4>
                <p>
                  <strong>
                    - Head of Innovation Group, Professional Services,
                    Switzerland
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section className="building-blocks">
            <div className="row page-wrap">
              <div className="col-12 heading">Enable</div>
            </div>
            <div className="row page-wrap">
              <div className="col-4 item">
                <h3>Talent</h3>
                <ul>
                  <li>
                    Acquire skills such as{" "}
                    <strong>
                      design thinking, business acumen, subject matter experts,
                      technical skills, and good communicators
                    </strong>
                  </li>
                  <li>Leverage creative and adaptable innovators</li>
                </ul>
              </div>
              <div className="col-4 item">
                <h3>Org Structure</h3>
                <ul>
                  <li>
                    The innovation director should be an{" "}
                    <strong>enabler and champion</strong> of innovation
                  </li>
                  <li>
                    Start with getting executive buy-in, build a core and
                    extended staff, which is{" "}
                    <strong>multidisciplinary teams</strong>
                  </li>
                </ul>
              </div>
              <div className="col-4 item">
                <h3>Sourcing and Alliances</h3>
                <ul>
                  <li>
                    Prepare a <strong>clear value proposition</strong> for
                    innovation partners that consists of funding, technology and
                    expectation from the partner
                  </li>
                  <li>
                    Create a <strong>relationship manager role</strong> to
                    coordinate engagements
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="quote">
            <h2 className="section-main-heading">
              <span className="highlight">Case Studies</span> of Insurance
              Company Provider
            </h2>
            <div className="row page-wrap">
              <div className="col-11 sub-heading">Sourcing &amp; Alliances</div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <h3>Insurance Company Provider</h3>
                <h4>
                  What worked well was we had a statement of work which was
                  written out to clarify the terms of partnership agreement. It
                  described what we brought and what they were bringing to the
                  table so the expectations were pretty clear. It worked very
                  well because{" "}
                  <strong>
                    each team knew what they were contributing to the project
                  </strong>
                  .
                </h4>
                <p>
                  <strong>
                    - Associate VP of innovation and strategy, financial
                    services company, U.S. insurance productizer
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section className="building-blocks">
            <div className="row page-wrap">
              <div className="col-12 heading">Deliver</div>
            </div>
            <div className="row page-wrap">
              <div className="col-4 item">
                <h3>Ways of Working</h3>
                <ul>
                  <li>
                    Follow principle <strong>of minimum viability</strong>{" "}
                    without compromising the quality
                  </li>
                  <li>
                    Enable innovation through{" "}
                    <strong>creativity, agility, and design thinking</strong>
                  </li>
                </ul>
              </div>
              <div className="col-4 item">
                <h3>Tools</h3>
                <ul>
                  <li>
                    Focus on <strong>minimum requirements</strong> for testing
                    the technology use cases
                  </li>
                  <li>
                    Maintain a <strong>technology tools repository</strong> that
                    should be re-utilised
                  </li>
                </ul>
              </div>
              <div className="col-4 item">
                <h3>Places</h3>
                <ul>
                  <li>
                    Determine the need a{" "}
                    <strong>physical, hybrid or virtual innovation lab</strong>{" "}
                    based on the lab's goals
                  </li>
                  <li>
                    <strong>Select location based on talent access</strong> and
                    experimental
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="quote">
            <h2 className="section-main-heading">
              <span className="highlight">Case Studies</span> of Maersk Drilling
            </h2>
            <div className="row page-wrap">
              <div className="col-11 sub-heading">Ways of Working</div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/icons/maersk-drilling-logo.svg`}
                    alt="Maersk Drilling logo"
                  />
                </figure>
                <h4>
                  "It's important to get an outreach in academia, in startup and
                  tech communities," says Maersk Drilling chief commercial and
                  innovation officer Morten Kelstrup. "We needed an{" "}
                  <strong>alternative to the traditional approach</strong>, and
                  we needed access to people and organizations that are used to{" "}
                  <strong>working in different ways."</strong>.
                </h4>
              </div>
            </div>
          </section>
          <section className="key-issues">
            <h2 className="section-main-heading">Key Issues</h2>
            <div className="row page-wrap">
              <div className="col-4 item">
                <span className="highlight">Why</span>doing it — defining the
                dream
              </div>
              <div className="col-4 item">
                <span className="highlight">How</span>to make the lab "work"
              </div>
              <div className="col-4 item active">
                <span className="highlight">What</span>are major pitfalls to
                avoid
              </div>
            </div>
          </section>
          <section className="challenge-solution">
            <h2 className="section-main-heading">
              Common Innovation Lab{" "}
              <span className="highlight">Challenges and Solutions</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="item">
                  <div className="number">01</div>
                  <div className="content">
                    <strong>Fail to understand </strong>&nbsp;the key reason for
                    investing in an innovation lab
                  </div>
                  <figure className="icon">
                    <img
                      src={`/img/svg/icons/understand-icon.svg`}
                      alt="Understand"
                    />
                  </figure>
                  <ul>
                    <li>
                      <strong>Justify the key reason</strong> for investing in
                      innovation
                    </li>
                    <li>
                      Define quantified, time-bound and detailed{" "}
                      <strong>goals</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="item">
                  <div className="number">02</div>
                  <div className="content">
                    Fail in implementation and struggle to move &nbsp;
                    <strong>beyond prototypes</strong>
                  </div>
                  <figure className="icon">
                    <img src={`/img/svg/icons/steps-icon.svg`} alt="Struggle" />
                  </figure>
                  <ul>
                    <li>
                      Get business buying and focus{" "}
                      <strong>
                        on three elements ownership, behavior, and process
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="item">
                  <div className="number">03</div>
                  <div className="content">
                    Getting&nbsp;<strong>employee engagement</strong>&nbsp;or
                    time utilization for innovation
                  </div>
                  <figure className="icon">
                    <img
                      src={`/img/svg/icons/emp-engagement-icon.svg`}
                      alt="Employee Engagement"
                    />
                  </figure>
                  <ul>
                    <li>
                      <strong>Involve HR</strong> and management and have
                      allocated time
                    </li>
                    <li>
                      <strong>Blocked time</strong> for innovation rather than
                      time in parts everyday
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="item">
                  <div className="number text">CLEAR</div>
                  <div className="content">
                    "Disruption", innovation labs etc. are not universally
                    agreed terms
                  </div>
                  <div className="content">
                    <strong>Call to action:</strong>
                    <br />
                    Ensure everyone understands what investment in innovation
                    look like and the road ahead
                  </div>
                </div>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="item">
                  <div className="number text">CONSISTENT</div>
                  <div className="content">
                    To increase the chances for successful innovation, reduce
                    the friction in the operating model
                  </div>
                  <div className="content">
                    <strong>Call to action:</strong>
                    <br />
                    Leverage an interdependent modular approach to create an
                    operating model
                  </div>
                </div>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="item">
                  <div className="number text">CONSERVATIVE</div>
                  <div className="content">
                    Innovation and disruption is hard — failure rates are high
                  </div>
                  <div className="content">
                    <strong>Call to action:</strong>
                    <br />
                    Set clear expectations — and be prepared to deal with
                    setbacks
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*?php include '../../footer-xonomy.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Lab;
