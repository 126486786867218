import React, { useEffect } from "react";
import Topheader from "./Topheader";
import Main_nav_wrapper from "./Main_nav_wrapper";
import Logowrapper from "./Logowrapper";
import Sidebar from "../pages/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
const Navbar = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    console.log("i ran", pathname);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <header className="header-wrapper">
      <ScrollToTop />
      <Topheader />
      <div className="main-header">
        <div className="page-wrap">
          <div className="row">
            <Logowrapper />
            <Main_nav_wrapper />
            {/* <Sidebar /> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
