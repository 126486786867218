import React from "react";
// import Table from "../../components/Table/table";
// import Footer from "../../components/Layout/Footer";
// import Navbar from "../../components/Layout/Navbar";
import LeadTable from "../../components/Table/LeadTable";
// import Head from "next/head";

const Leads = () => {
  return (
    <>
     {/* <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head> */}
      <div className="leads ">
        {/* <h1>Leads</h1> */}
        {/* <Table />; */}
        <LeadTable />
      </div>
    </>
  );
};

export default Leads;
