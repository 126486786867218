import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Awarded_As_Cx_Partner = () => {
    useEffect(() => {
        document.title = "Virtuos is awarded as the 'CX PARTNER OF THE YEAR' by Oracle in the APAC Partner Summit – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-awarded-by-oracle-2013-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Seoul South Korea - 6 Sep, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos is awarded as the "CX PARTNER OF THE YEAR" by Oracle in the
                                    APAC Partner Summit
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Solutions, a leading CRM and Digital Transformation Services
                                    Company with India operations was recognised and awarded by Oracle
                                    Corporation as The CX Partner of the year — the most prestigious
                                    category for bringing the maximum number of logos in Asia. The awards
                                    were Given to partners who brought significant growth and maximum
                                    number of logos in each of the respective domains such As CX (Customer
                                    Experience/CRM), ERP, HCM, and BI. Virtuos was recognized as the
                                    leader in Asia in the category of CX which comprises the complete
                                    spectrum of Customer Experience Solutions from Oracle. There were
                                    several nominees for this coveted award such as CapGemini, NZ Post
                                    (Formerly Datum), Six Degrees besides many other companies who are
                                    established as CX Partners of Oracle.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/oracle-award-2013-pics.jpg`}
                                        alt="Oracle Award 2013"
                                    />
                                </figure>
                                <br />
                                <p className="italic">
                                    Ms. Yen Yen Tan, the Senior VP Oracle Asia and Michel Van Woudenberg,
                                    VP-CRM Asia Pacific, Oracle and Sunil Jose, VP-Applications, Oracle
                                    India giving away the award to Venky Vijay Reddi, CEO of Virtuos
                                    Corporation. All are dressed as "pirates of the caribbean" as the
                                    theme for gala dinner At Oracle APAC Partner Summit in Seoul, South
                                    Korea 4th-6th September 2013.
                                </p>
                                <p>
                                    "Virtuos is excited to be recognised for its contribution as the CX
                                    Partner of Oracle with special status, and I thank all the Virtuoso
                                    Teams for their splendid work; Customers for their belief and trust in
                                    Virtuos capabilities; and Oracle Senior Executive Management for their
                                    support and guidance" said Venky Vijay, CEO of Virtuos. "We continue
                                    to grow and expand by adding more solutions such as Fusion CRM, Oracle
                                    ATG/Endeca and Fusion HCM/Taleo besides Oracle RightNow &amp; Oracle
                                    Eloqua in India, and expand our operations to the other parts of Asia
                                    that include Singapore, Indonesia, Philippines, HK and Australia &amp;
                                    NZ."
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/oracle-award-glipmses-2013.jpg`}
                                        alt="Glimpses from the Event"
                                    />
                                </figure>
                                <br />
                                <p>
                                    "Virtuos is continuously investing in oracle practice by recruiting
                                    additional 30 Launchpad Consultants as part of its aggressive
                                    expansion plan and opening two new offices in Sydney during the next
                                    Financial year." said Kristina Hermanns, Director of Consulting,
                                    Virtuos Solutions. “Virtuos continues to strive hard for transforming
                                    customers’ digital engagement models.”
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services, and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely - Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas and executing them with Virtuoso teams. Virtuos was founded
                                    in 2008 by cultivating an ambitious plan to become a multi-billion
                                    dollar company and a game changer by 2020. Thanks to the hard work and
                                    commitment of our team we continue to grow each year. With new
                                    innovative business ideas, delivering incredible transformation
                                    services and building powerful engagement platforms for the future we
                                    are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink to="http://www.fb.com/virtuos" target="_blank" className="link">
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Awarded_As_Cx_Partner