import React from "react";

import ComingSoon from "../ComingSoon/ComingSoon";

const Ai_ambition = () => {
  return (
    <div className="ai-ambiton">
      <ComingSoon />
    </div>
  );
};

export default Ai_ambition;
