import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Drawer, Button } from "@mui/material";
import ReCaptcha from "react-google-recaptcha";
// import emailjs from "@emailjs/browser";
import CloseIcon from "@mui/icons-material/Close";

const Connectwithuse = () => {
  const [phoneError, setPhoneError] = useState(false);
  const [userData, setuserData] = useState({
    webURL: `/vivacis/dpa`,
    formType: "Forrester",
    name: "",
    email: "",
    mobile: "",
    company: "",
    designation: "",
  });
  const navigate = useNavigate();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(true);
  const handleRecaptchaChange = (value) => {
    console.log("Recaptcha");
    setRecaptchaValue(value);
    setCaptchaError(false);
  };
  //we are storign data in states
  const handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setuserData({ ...userData, [name]: value });
    console.log("inside handlechange");
    // console.log(userData.name)
  };
  const handleSubmit = async (e) => {
    console.log("insidde contcat form");
    e.preventDefault();
    const { webURL, formType, name, email, mobile, company, designation } =
      userData;
    if (captchaError) {
      return;
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}/db/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
      body: JSON.stringify({
        webURL: webURL,
        type: formType,
        full_name: name,
        email: email,
        mobile: mobile,
        company: company,
        designation: designation,
      }),
    });
    const data = await res.json();

    if (!data) {
      console.log("message not send");
    } else {
      // alert( "message send" );
      setuserData({ ...userData, message: "" });
      navigate("/thankyou"); // Navigate to "/thankyou" route
    }
    //////////////////EMAIL JS//////////////////////

    /*emailjs
     .send(
       process.env.REACT_APP_SERVICE_ID,
       process.env.REACT_APP_TEMPLATE_ID,
       {
         from_name: userData.name,
         to_name: process.env.REACT_APP_TO_NAME,
         from_email: userData.email,
         to_email: process.env.REACT_APP_TO_EMAIL,
         message: userData.message,
       },
       process.env.REACT_APP_PUBLIC_KEY
     )
     .then(
       () => {

         // alert("Thank you. I will get back to you as soon as possible.");

         setuserData({
           name: "",
           email: "",
           phone: "",
           message: ""
         });

       },
       (error) => {

         console.error(error);

         // alert("Ahh, something went wrong. Please try again.");
       }
     );*/
    //////////////////EMAIL JS//////////////////////
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [message, setMessage] = useState("");
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleChangeMessage = (event) => {
    const value = event.target.value;
    setMessage(value);
  };

  // const remainingChars = 200 - userData.message.length;
  const [isHovered, setIsHovered] = useState(false);
  const buttonStyle = {
    color: isHovered ? "red" : "black",
    fontWeight: "bold",
    transition: "color 0.3s",
  };

  return (
    <div>
      <NavLink
        onClick={toggleDrawer}
        className="btn btn-primary popup-form-trigger"
      >
        <i className="fa fa-download" /> &nbsp; Download
      </NavLink>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={toggleDrawer}>
        <div
          className="form-wrapper page-wrap"
          style={{ height: "400px" }}
          id="form-mobile-view"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 className="section-heading" style={{ marginTop: "1.5rem" }}>
              Download
            </h3>
            <Button
              onClick={toggleDrawer}
              style={buttonStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <CloseIcon />
            </Button>
          </div>

          <div cf-context="">
            <form
              action=""
              onSubmit={handleSubmit}
              method="POST"
              id="E_Alliances"
            >
              <div className="row" id="gap1">
                <div className="col-6 left-wrapper">
                  <div className="input-wrapper hidden-fields">
                    <input type="text" name="pageURL" className="pageURL" />
                    <input
                      type="text"
                      name="formType"
                      id="formType"
                      defaultValue="E_Alliances"
                    />
                    <input
                      type="text"
                      name="location"
                      className="userLocation"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Full Name</label>
                    <input
                      name="name"
                      tabIndex={1}
                      type="text"
                      maxlenght={100}
                      value={userData.name}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Email</label>
                    <input
                      name="email"
                      tabIndex={2}
                      value={userData.email}
                      onChange={handleInput}
                      required
                      type="email"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Mobile</label>
                    <input
                      name="mobile"
                      tabIndex={3}
                      value={userData.mobile}
                      onChange={handleInput}
                      required
                      type="tel"
                    />
                  </div>
                </div>
                <div className="col-6 right-wrapper">
                  <div className="input-wrapper">
                    <label htmlFor="">Company</label>
                    <input
                      name="company"
                      tabIndex={4}
                      type="text"
                      maxLength={100}
                      value={userData.company}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Designation</label>
                    <input
                      name="designation"
                      tabIndex={5}
                      type="text"
                      maxLength={50}
                      value={userData.designation}
                      onChange={handleInput}
                      required
                    />
                  </div>

                  <div className="input-wrapper">
                    <ReCaptcha
                      sitekey={process.env.REACT_APP_RECAPTCHA}
                      onChange={handleRecaptchaChange}
                    />
                    {captchaError && (
                      <p
                        className="required-message"
                        style={{ fontSize: "12px", color: "red" }}
                      >
                        * Please select CAPTCHA
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="btn-wrapper">
                <input
                  className="btn btn-secondary"
                  type="submit"
                  value="Send"
                  tabIndex={7}
                />
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Connectwithuse;
