import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Leadership_Meets_Maximizer_Team_In_Uk = () => {
    useEffect(() => {
        document.title = "Virtuos Leadership meets the Maximizer Team in the UK – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-leadership-meets-maximizer-team-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Bracknell, UK - 12 October, 2010</span>
                                <h2 className="article-heading">
                                    Virtuos Leadership meets the Maximizer Team in the UK
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos and Maximizer have been serving Small and Medium customers who
                                    require an integrated B2B Sales, Marketing, and Customer Service.
                                    Maximizer has been implemented by over 100,000 customers worldwide to
                                    augment their business processes and automate Sales, Service, and
                                    Marketing.
                                </p>
                                <p>
                                    “It’s an age of Customer, and we always feel that a well-integrated
                                    single suite of applications will serve Small and Medium Businesses
                                    exceptionally well compared to Siloed applications”. Said Venky Vijay
                                    Reddi, CEO of Virtuos Solutions.” With Maximizer easy to use, quick,
                                    scalable, and highly user friendly software, small companies instantly
                                    begin their CRM journey, and the complex organizations can build on
                                    its extended platforms.”
                                </p>
                                <p>
                                    It was a pleasure meeting the Leadership in Maximizer’s UK
                                    headquarters — Bracknell. Mike Richardson, Managing Director of
                                    Maximizer (Max.co.uk) and Diego Lunard, head of Sales at Maximizer,
                                    met Venky Vijay Reddi and Kristina Hermanns in their office.{" "}
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/virtuos-leadership-meets-maximizer-team-pic.jpg`}
                                        alt="Virtuos Leadership meets the Maximizer Team in the UK"
                                    />
                                    <span className="">
                                        Venky Vijay CEO Virtuos with Mike and Diego (Max) and Kristina
                                        (Virtuos)
                                    </span>
                                </figure>
                                <p>
                                    “It was a wonderful meeting with the senior leadership at Maximizer
                                    for the first time as I joined Virtuos later in 2009.” Said Kristina
                                    Hermanns. “Thank you, Mike, for a lovely lunch with my favorite
                                    dishes, it was a great discussion.”
                                </p>
                                <h3 className="title">About Maximizer</h3>
                                <p>
                                    Maximizer is a leading CRM Software Company with an integrated Sales,
                                    Marketing and Customer Service Suite of Applications with rich
                                    functionality and feature set useful across all the industries.
                                </p>
                                <p>
                                    With our head office in{" "}
                                    <NavLink
                                        to="https://www.maximizer.com/about-us/contact-us/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Vancouver, Canada, and regional offices in England, and Australia
                                    </NavLink>
                                    , as well as Certified Partners like Virtuos around the world,
                                    Maximizer CRM has helped over 100,000 companies increase sales, build
                                    relationships, and boost their bottom line for over 15 years!
                                </p>
                                <p>
                                    Maximizer CRM software comes loaded with all the features you need to
                                    succeed, all at one simple and all-inclusive price. You’ll receive
                                    modules for{" "}
                                    <NavLink
                                        to="https://www.maximizer.com/features/sales-crm/"
                                        target="_blank"
                                        className="link"
                                        rel="noreferrer"
                                    >
                                        Sales
                                    </NavLink>
                                    ,{" "}
                                    <NavLink
                                        to="https://www.maximizer.com/features/crm-marketing/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Marketing
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to="https://www.maximizer.com/features/customer-service-crm/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Service
                                    </NavLink>
                                    , as well as must-haves such as{" "}
                                    <NavLink
                                        to="https://www.maximizer.com/features/crm-integration/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        third-party integrations
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to="https://www.maximizer.com/features/mobile-crm/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        mobile CRM
                                    </NavLink>{" "}
                                    access included in your annual subscription.
                                </p>
                                <p>
                                    Maximizer were pioneers of the Customer Relationship Management
                                    Software industry, and since our beginnings in the 1980’s, our{" "}
                                    <NavLink
                                        to="https://www.maximizer.com/about-us/our-team/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        leadership team
                                    </NavLink>{" "}
                                    continues to be dedicated to helping our customers grow and be
                                    successful in whatever vertical and industry they are in. Learn more
                                    about Maximizer at{" "}
                                    <NavLink
                                        to="http://www.maximizer.com"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.maximizer.com
                                    </NavLink>{" "}
                                    or{" "}
                                    <NavLink
                                        to="http://www.max.co.uk"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.max.co.uk
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based in
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book — Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three essential needs
                                    of growing businesses — Sales, Customer Service, and Marketing.
                                    Virtuos has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has been fiercely growing in the space of CRM, Service Cloud
                                    and Cloud Computing space by delivering the cutting edge solutions to
                                    leading brands like MakeMyTrip, GE-Healthcare, Standard Chartered
                                    Bank, Party Gaming, Fundtech amongst other companies. For more
                                    information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Leadership_Meets_Maximizer_Team_In_Uk