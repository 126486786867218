import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Niit_Case_Study = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Virtuos Customer Success Stories | NIIT CRM case study – Virtuos Digital";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/niit-header-img.jpg`}
                                    alt="NIIT"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/niit-logo.png`}
                                        alt="NIIT Logo"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        Digital Process Automation Solution for a leading Professional
                                        Training Provider
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <section className="breadcrumb-kanaban">
                            <div className="page-wrap breadcrumb-wrapper">
                                <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                    <li>
                                        <NavLink to={`/corporate/`}>About Virtuos</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/customers/`} className="active">
                                            Customer Success
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={`/customers#success-stories`}
                                            className="smooth"
                                        >
                                            CUSTOMER SUCCESS STORIES
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/succeedant/`} className="smooth">
                                            SUCCEEDANT CSM
                                        </NavLink>
                                    </li>

                                </ul>
                                <ul className="more-hidden-tabs">
                                    <li
                                        className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <i className="fal fa-plus" />  More
                                        {showExtraLinks && (
                                            <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                                <li>
                                                    <NavLink to={`/corporate/experience-edge/`}>
                                                        EXPERIENCE EDGE
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`/insights/`}>
                                                        INSIGHTS AND RESOURCES
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                <h3 className="title">About NIIT</h3>
                                <p style={{fontSize:"1em!important"}}>
                                    The company is a leading Skills and Talent Development Corporation in
                                    India that is building a manpower pool for global industry
                                    requirements. To further accelerate company’s growth and
                                    profitability, the company has charted a focused business strategy to
                                    address the Digital Transformation needs for individuals and corporate
                                    customers by offering training and consulting to help them re-invent
                                    themselves &amp; their organizations. With a footprint in over 30
                                    countries, the company offers training and development solutions to
                                    Individuals, Enterprises and Institutions.
                                </p>
                                <h3 className="title">Highlights</h3>
                                <div className="highlights-wrapper">
                                    <div className="row">
                                        <div className="col-3 item">
                                            <span className="header">5</span>
                                            <p>Number of processes automated-</p>
                                        </div>
                                        <div className="col-3 item">
                                            <span className="header">22%</span>
                                            <p>Operational Cost - down</p>
                                        </div>
                                        <div className="col-3 item">
                                            <span className="header">0</span>
                                            <p>Hours of Coding Effort</p>
                                        </div>
                                        <div className="col-3 item">
                                            <span className="header">100%</span>
                                            <p>Percentage of “No-code” Implementation</p>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="title">Modules Used</h3>
                                <ul>
                                    <li className="app-p">Case Management</li>
                                    <li className="app-p">SLA and Escalations with custom Timer options</li>
                                    <li className="app-p">Email Management with custom threading</li>
                                    <li className="app-p">Event Management</li>
                                    <li className="app-p">Batch and Activities Management</li>
                                    <li className="app-p">Business Process Automation</li>
                                </ul>
                                <h3 className="title">Challenges faced by the Company</h3>
                                <div className="challenge-wrapper">
                                    <div className="row items-container">
                                        <div className="col-3 item">
                                            Huge operational cost being incurred YoY in the existing CRM
                                            solution
                                        </div>
                                        <div className="col-3 item">
                                            High maintenance costs associated with the existing setup
                                        </div>
                                        <div className="col-3 item" >
                                            Inflexibility shown by the existing CRM provider in terms of
                                            license cost and SaaS instances
                                        </div>
                                        <div className="col-3 item" >
                                            Below par customer support provided by existing CRM provider
                                        </div>
                                    </div>
                                </div>
                                <h3 className="title">Virtuos Approach</h3>
                                <p >
                                    The customer was using one of the leading CRM solutions for &gt;6
                                    years which was highly customized to suit the requirements of the
                                    Professional Training industry, thus creating tremendous change
                                    barriers. Due to the huge dependency on the existing solution and its
                                    perceived flexibility, the customer had a clear mandate that the
                                    critical functionalities provided by their existing solution, must
                                    also be achievable in Creatio. Virtuos took up the challenge and was
                                    able to deliver all functionalities using Creatio BPM and Service
                                    modules. The requirements which needed customization (coding) effort
                                    in the customer’s existing solution, all were implemented in Creatio
                                    using drag and drop configurations with 0 code!
                                </p>
                                <p>
                                    Thus, with strong platform and solutioning capabilities, combined with
                                    an attractive cost-driven value proposition, Virtuos was able to make
                                    inroads into the Account.
                                </p>
                                <figure className="blog-content-image box-shadow">
                                    <img
                                        src={`/img/niit-case-study-screenshot1.jpg`}
                                        alt="NIIT"
                                    />
                                </figure>
                                <figure className="blog-content-image box-shadow">
                                    <img
                                        src={`/img/niit-case-study-screenshot2.jpg`}
                                        alt="NIIT"
                                    />
                                </figure>
                                <figure className="blog-content-image box-shadow">
                                    <img
                                        src={`/img/niit-case-study-screenshot3.jpg`}
                                        alt="NIIT"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="customer-success-section">
                        <h2 className="section-main-heading">
                            Other <span className="highlight">Case Studies</span>
                        </h2>
                        <div className="clients-wrapper">
                            <ul className="row page-wrap">
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/standard-chartered-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/standard-chartered-casestudy-img.jpg`}
                                                alt="Standard Chartered Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/standard_chartered.svg`}
                                                alt="Standard Chartered Bank Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">CX and Contact Center Automation</p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/tvs-credit-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/tvsc-casestudy-img.jpg`}
                                                alt="TVS Credit Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/tvscs-logo.png`}
                                                alt="TVS Credit"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer Experience Transformation Project using Oracle platform
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/makemytrip-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/makemytrip-casestudy-img.jpg`}
                                                alt="Make My Trip Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/make-my-trip-logo.svg`}
                                                alt="Make My Trip Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            MakeMyTrip.com halved avg. call handling time and increased
                                            revenue with CX Cloud
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/electronics-company-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/electronics-company-casestudy-img.jpg`}
                                                alt="Electronics Company Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/confidential-logo.svg`}
                                                alt="Confidential Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer engagement on phone, email, chat, and social channels
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Niit_Case_Study