import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Team_Attends_Dreamforce_2017 = () => {
    useEffect(() => {
        document.title = "Virtuos Team Attends Dreamforce'17 in San Francisco – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-team-attends-dreamforce-2017-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">San Francisco - 9 November, 2017</span>
                                <h2 className="article-heading">
                                    Virtuos Team attends Dreamforce 17 in San Francisco for the fifth
                                    consecutive time
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    This being the fifth consecutive dreamforce for Virtuos CEO to attend
                                    Dreamforce in person to witness the transformative CRM Technologies
                                    and Industry trends catapulted by the Fortune CEOs and visionary
                                    leaders.
                                </p>
                                <p>
                                    The Dreamforce 2017 (#DF17) has attracted over 140,000 registrations
                                    and several million watching live at Salesforce.com/live. The event
                                    was packed with activities ranging from Keynotes, Fireside chats,
                                    Training, Expo, and Concerts.
                                </p>
                                <h3 className="title">Important TakeAways from #DF17:</h3>
                                <ul>
                                    <li>
                                        Trailhead is very big - Trail Blazers are in the millions. The show
                                        is even bigger this time.
                                    </li>
                                    <li>
                                        Salesforce added (my) prefix to bring personalization to All
                                        offerings like “MyTrailhead,” MySalesforce, etc.
                                    </li>
                                    <li>Google is new hope: Salesforce Lightning for Gmail.</li>
                                    <li>
                                        AI, Einstein, and big data have been showcasing many offers (but
                                        beware of the hype).
                                    </li>
                                    <li>
                                        Quip which Salesforce has acquired to position against Microsoft
                                        Office with new differentiators like Google Integrations.
                                    </li>
                                    <li>
                                        Industry 4.0 is a big play: Salesforce has been touting the
                                        bandwagon of Industrie 4.0 with connected applications and IoT.
                                    </li>
                                    <li>
                                        Big roadmaps - A lot of lightning work for the next 2-3 years.
                                    </li>
                                    <li>
                                        Almost all sessions have waitlists, and it’s impossible to attend
                                        your favorite session without reservation of 90-120 days.
                                    </li>
                                    <li>Consulting is back big time - Niche Players shine.</li>
                                    <li>
                                        More than ever, participation by partners and huge crowds at Expo.
                                        It’s impossible to digest everything in just three days.
                                    </li>
                                </ul>
                                <p>
                                    There’s more covered on the{" "}
                                    <NavLink
                                        to="https://www.salesforce.com/blog/2017/11/the-dreamforce-2017-ultimate-content-guide.html"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Salesforce blog
                                    </NavLink>
                                    .
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Team_Attends_Dreamforce_2017