import React from "react";
import { NavLink, useRouteError } from "react-router-dom";
import "./PageNotFound.css"; // Import the CSS file for styling

const PageNotFound = ({ error: propError }) => {
  console.log("propError", propError);

  // const routeError = useRouteError();
  // const error = propError || routeError;
  // console.error(error);

  // if (!error) {
  //   return <h1>Unknown Error</h1>;
  // }

  // if (error.status === 404) {
  //   return <h1>404 - Page Not Found</h1>;
  // }

  // return (
  //   <div>
  //     <h1>Something went wrong</h1>
  //     <p>{error.statusText || error.message || 'Unknown error'}</p>
  //   </div>
  // );

  return (
    <div className="page-container">
      <div id="error-page">
        <div className="content">
          <h2 className="header" data-text="404">
            404
          </h2>
          <h4 data-text="Opps! Page not found">Opps! Page not found</h4>
          <p>
            The page you are looking for might have been removed or is
            temporarily unavailable.
          </p>
          <div className="btns">
            <NavLink to="/" className="back-link">
              Go Back to Homepage
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;