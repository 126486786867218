import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Consultare_Implements_China = () => {
    useEffect(() => {
        document.title = "Virtuos Consultare to implement Oracle RightNow CX at the leading Consumer durable giant in China – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/consumer-durable-giant-in-china-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon and China - 32 August, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos Consultare to implement Oracle RightNow CX at the leading
                                    Consumer durable giant in China
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Consultare Professional Services Team is engaged on an
                                    outsourced project for a leading China based Consumer durable giant
                                    for the delivery of Web Self-Service, Knowledge Management and Social
                                    media monitoring on Oracle RightNow CX Cloud platform. The project
                                    involved visiting the customer in China and understanding the
                                    requirements at length to demonstrate value and our unparalleled
                                    commitment to the client.
                                </p>
                                <p>
                                    "The leading consumer durable giant wishes to embark on a major
                                    expansion plan by using CRM and Customer Experience (CX) tools from
                                    Oracle stable by bringing most modern cross channel customer service
                                    and engagement" said Kristina Hermanns, Director of Solution
                                    Consulting at Virtuos. "Virtuos was amongst their top choice to be the
                                    implementation partner after weeks of evaluation".
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/oracle-cx-diagram-virtuos.jpg`}
                                        alt="Oracle RightNow CX Diagram, Virtuos"
                                    />
                                </figure>
                                <br />
                                <p>
                                    "We are excited to implement the prestigious global project paving the
                                    way for the client's expansion in many other geographies to deliver
                                    connected customer experience and it's just a beginning " said Venky
                                    Vijay Reddi, CEO of Virtuos. “Virtuos core principles always revolve
                                    around transforming customer digital processes by bringing advanced
                                    integrations with CX Ecosystems.”
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely - Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas and executing them with Virtuoso teams. Virtuos was founded
                                    in 2008 by cultivating an ambitious plan to become a multi-billion
                                    dollar company and a game changer by 2020. Thanks to the hard work and
                                    commitment of our team we continue to grow each year. With new
                                    innovative business ideas, delivering incredible transformation
                                    services and building powerful engagement platforms for the future we
                                    are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink to="http://www.fb.com/virtuos" target="_blank" className="link">
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Consultare_Implements_China