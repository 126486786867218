import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Giftcart_Case_Study = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Virtuos Customer Success Stories | Giftcart Magento Commerce case study – Virtuos Digital";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/giftcart-header-img.jpg`}
                                    alt="Giftcart"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/svg/icons/giftcart-logo.svg`}
                                        alt="Giftcart Logo"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        Giftcart.com is live with Magento E-Commerce Platform built by
                                        Virtuos.Dev
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <section className="breadcrumb-kanaban">
                            <div className="page-wrap breadcrumb-wrapper">
                                <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                    <li>
                                        <NavLink to={`/corporate/`}>About Virtuos</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/customers/`} className="active">
                                            Customer Success
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={`/customers#success-stories`}
                                            className="smooth"
                                        >
                                            CUSTOMER SUCCESS STORIES
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/succeedant/`} className="smooth">
                                            SUCCEEDANT CSM
                                        </NavLink>
                                    </li>

                                </ul>
                                <ul className="more-hidden-tabs">
                                    <li
                                        className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <i className="fal fa-plus" />  More
                                        {showExtraLinks && (
                                            <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                                <li>
                                                    <NavLink to={`/corporate/experience-edge/`}>
                                                        EXPERIENCE EDGE
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`/insights/`}>
                                                        INSIGHTS AND RESOURCES
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                <h3 className="title">
                                    State of the art gifting experience commerce platform for X,Y, and Z
                                    generations
                                </h3>
                                <p>
                                    It was not a natural choice for{" "}
                                    <NavLink to="http://www.giftcart.com" target="_blank" className="link">
                                        Giftcart
                                    </NavLink>
                                    , a startup company, to go for its parent company to implement the
                                    Magento platform. Before considering with Virtuos,{" "}
                                    <NavLink to="http://www.giftcart.com" target="_blank" className="link">
                                        Giftcart
                                    </NavLink>{" "}
                                    was scouting for partners to provide them a multi-brand site on
                                    Magento. Having been frustrated with more than three months, they
                                    relied on Virtuos Commerce and Experience Design DNA to craft a
                                    strategy that includes the development of Commerce Platform on
                                    Magento, managing hosting, and security to setting up Merchant
                                    accounts. The e-gifting experiences company needed to start with
                                    Magento to reach its commerce goals. Among the list of items on their
                                    plate were exhaustive.
                                </p>
                                <p>
                                    The requirement list was exhaustive, but some of them have been
                                    high-performance, intuitive, responsive, and mobile-ready design of
                                    the site. Giftcart needed a flexible platform that would allow
                                    development teams greater autonomy in making quick changes to the
                                    E-Commerce site when required. Agility was also a crucial issue as
                                    Giftcart employees and outsourced development were continually
                                    struggling to improve the speed. Most of the manually optimized
                                    techniques did not solve problems to make the site traverse faster.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/gc-develpment-strategy-img.jpg`}
                                        alt="Giftcart Development Strategy"
                                    />
                                </figure>
                                <br />
                                <p>
                                    Giftcart Management wanted to create a differentiated story in the
                                    most promising Gifting Experiences Industry. The following
                                    functionality would require significant changes to the selected
                                    Platform — Magento 2.0, which comes with robustness, scalability,
                                    extensions marketplace, and many out of the box features.
                                </p>
                                <ul>
                                    <li>
                                        <strong>Giftwizard:</strong> A fun interactive way of engaging
                                        customers with the most well-matched gift options based on
                                        personality, gender, and budgets.
                                    </li>
                                    <li>
                                        <strong>Recipient Management:</strong> Unlike traditional
                                        buy-sell-marketplace models, gifting platforms need to deliver
                                        elements of delight, surprise, along with the anonymity of the
                                        information such as price.
                                    </li>
                                    <li>
                                        <strong>Vendor Management System (VMS):</strong>{" "}
                                        <NavLink to="http://www.giftcart.com" target="_blank" className="link">
                                            Giftcart
                                        </NavLink>{" "}
                                        needed VMS for marketplace commerce while carrying out their own
                                        in-house products. Ethnic, country, or state-specific products for
                                        every occasion and celebration required from a multitude of vendors.
                                    </li>
                                    <li>
                                        <strong>Buyer Control Panel:</strong> Customers want to check on
                                        their loyalty points, the status of the shipment, downloadable
                                        invoices, warranty cards, etc.
                                    </li>
                                    <li>
                                        <strong>Personalized Extension:</strong> Personalization of gifts,
                                        and creating "look and feel" even before they are shipped so that
                                        customers can view how their gift items (crafted from the assorted
                                        supplies, inhouse design, and other outsourced supplements) suit
                                        their requirements or their friends.
                                    </li>
                                    <li>
                                        <strong>Customization:</strong> The platform must support Gift
                                        Boxes, Gift wraps, customized/Premium boxes, packaging, etc.
                                    </li>
                                    <li>
                                        <strong>Content Management System (CMS):</strong> Giftcart wanted to
                                        dynamically manage their catalog as it has thousands of marketplace
                                        vendors with their products.
                                    </li>
                                    <li>
                                        <strong>Inventory extension:</strong> Giftcart needed an Inventory
                                        extension for Magento to manage products in the warehouse using
                                        barcode and track products in its vendors' warehouses.
                                    </li>
                                    <li>
                                        <strong>Social Channels:</strong> All social channels, including
                                        Youtube, are supported on the Magento Platform.
                                    </li>
                                </ul>
                                <h3 className="title">Virtuos Experience Design</h3>
                                <p>
                                    Virtuos has built the complete Commerce Platform for Giftcart by
                                    assembling technologies from over a dozen critical players in this
                                    space. Hosted on Google Cloud, Giftcart.com serves thousands of
                                    customers worldwide by carrying out several thousand products from
                                    hundreds of Brands, Suppliers, and Marketplace Vendors. Virtuos’ UX
                                    Design Team also has custom-built templates suiting the Magento
                                    platform.
                                </p>
                                <p>
                                    Virtuos has also built Progressive Web Apps (PWA) without opting for
                                    custom-built Apps that are highly restrictive in their design.
                                    Giftcart receives 60% of its traffic volume through native mobile
                                    browsers.
                                </p>
                                <p>
                                    Virtuos has certified Magento 2.0+ Developers who can work with
                                    extensive extensions to create user experience that’s intuitive and
                                    engaging.{" "}
                                    <NavLink
                                        to={`/consultare/engineering-services#magento`}
                                        className="link"
                                    >
                                        Magento Practice
                                    </NavLink>{" "}
                                    is part of{" "}
                                    <NavLink
                                        to={`/consultare/engineering-services/`}
                                        className="link"
                                    >
                                        Virtuos.Dev Engineering
                                    </NavLink>
                                    .
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="customer-success-section">
                        <h2 className="section-main-heading">
                            Other <span className="highlight">Case Studies</span>
                        </h2>
                        <div className="clients-wrapper">
                            <ul className="row page-wrap offport-down">
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/standard-chartered-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/standard-chartered-casestudy-img.jpg`}
                                                alt="Standard Chartered Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/standard_chartered.svg`}
                                                alt="Standard Chartered Bank Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">CX and Contact Center Automation</p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/tvs-credit-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/tvsc-casestudy-img.jpg`}
                                                alt="TVS Credit Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/tvscs-logo.png`}
                                                alt="TVS Credit"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer Experience Transformation Project using Oracle platform
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/makemytrip-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/makemytrip-casestudy-img.jpg`}
                                                alt="Make My Trip Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/make-my-trip-logo.svg`}
                                                alt="Make My Trip Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            MakeMyTrip.com halved avg. call handling time and increased
                                            revenue with CX Cloud
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/electronics-company-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/electronics-company-casestudy-img.jpg`}
                                                alt="Electronics Company Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/confidential-logo.svg`}
                                                alt="Confidential Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer engagement on phone, email, chat and social channels
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Giftcart_Case_Study