import React from 'react';

const PartnershipIcon = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 32 32"
        style={{ enableBackground: 'new 0 0 32 32' }}
        xmlSpace="preserve"
    >
        <style>
            {`.st0-icon{fill:#333333;}`}
        </style>
        <path className="st0-icon" d="M26.3,19.4L20.8,14c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1l5.4,5.4c0.4,0.4,0.4,1,0,1.5l-3.4,3.4
      c-0.4,0.4-1,0.4-1.5,0l-5.4-5.4c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1l5.5,5.4c0.5,0.5,1.1,0.6,1.7,0.6s1.2-0.3,1.7-0.6
      l3.4-3.4C27.2,22,27.2,20.4,26.3,19.4z M6.7,11.5c-0.4-0.4-0.4-1,0-1.5l3.4-3.4c0.4-0.4,1-0.4,1.5,0l5.6,5.6c0.3,0.3,0.7,0.3,1,0
      c0.3-0.3,0.3-0.7,0-1l-5.6-5.6c-0.9-0.9-2.5-0.9-3.4,0L5.6,9c-0.9,0.9-0.9,2.5,0,3.4l5.6,5.6c0.1,0.1,0.4,0.2,0.5,0.2
      c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.7,0-1L6.7,11.5z M11.4,11.4c-0.3,0.3-0.3,0.6,0,1l8.1,8.1c0.1,0.1,0.4,0.2,0.5,0.2
      s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.7,0-1l-7.9-8.1C12.1,11.1,11.7,11.1,11.4,11.4z">
        </path>
    </svg>
);

export default PartnershipIcon;
