import  React,{ useRef, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import Qualtrics_slider from "./Qualtricsslider";
import Qualirics_cards from "./QualtricsCards";
import CountUp from "react-countup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Qualtrics = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleClick = (tabId) => {
    console.log("here tab is", tabId);
    setActiveTab(tabId);
  };
  useEffect(() => {
    document.title = "Virtuos Digital | Qualtrics XM Services";
  }, []);

  const scrollToTopRef = useRef(null);

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const element = scrollToTopRef.current;

    if (element) {
      element.addEventListener("click", handleScrollToTop);
    }

    return () => {
      if (element) {
        element.removeEventListener("click", handleScrollToTop);
      }
    };
  }, []);
  const [counts, setCounts] = useState({
    customerSatisfaction: 87,
    employeeEngagement: 93,
    brandAwareness: 61,
    fortune100: 85,
  });

  const handleSectionHover = () => {
    // Increase the counts
    setCounts((prevCounts) => ({
      customerSatisfaction: prevCounts.customerSatisfaction + 1,
      employeeEngagement: prevCounts.employeeEngagement + 1,
      brandAwareness: prevCounts.brandAwareness + 1,
      fortune100: prevCounts.fortune100 + 1,
    }));
  };

  return (
    <div className="qualtrics-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <Qualtrics_slider />
          <section className="qxm-practice">
            <div className="row page-wrap">
              <div className="cl-12">
                <h2 className="tag">
                  Qualtrics XM Practice is Integral to CXNow
                </h2>
                <span className="title-content">
                  Virtuos CXNow Program has infused Qualtrics XM Technologies
                  and Services across Experience Continuum
                </span>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-4 items">
                <div className="icon-block">
                  <figure>
                    <img
                      src={`/img/svg/cx-primer-emblem.svg`}
                      alt="CX Primer"
                    />
                  </figure>
                </div>
                <div className="content-block">
                  <div className="title">CX Primer</div>
                  <div className="content">
                    Virtuos CXPrimer comes with Self-help tools and best
                    practices to define the state of CX.
                  </div>
                </div>
              </div>
              <div className="col-4 items">
                <div className="icon-block">
                  <figure>
                    <img
                      src={`/img/svg/icons/cxnow-logo-icon.svg`}
                      alt="CXNow Program"
                    />
                  </figure>
                </div>
                <div className="content-block">
                  <div className="title">CXNow Program</div>
                  <div className="content">
                    CXNow Program is most advanced 7C Framework gauging the
                    tenets of Customer Experience.
                  </div>
                </div>
              </div>
              <div className="col-4 items">
                <div className="icon-block">
                  <figure>
                    <img
                      src={`/img/svg/icons/indicial-digital.svg`}
                      alt="Indicial CX Maturity"
                    />
                  </figure>
                </div>
                <div className="content-block">
                  <div className="title">Indicial CX Maturity</div>
                  <div className="content">
                    Indicial offers the CX and Digital maturity process using
                    Qualtrics Customer and Enterprise XM.
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="cxnow-qualtrics">
            <div className="row page-wrap">
              <div className="col-6 content-block">
                <h2 className="tag">Integrated Signature Experiences</h2>
                <span className="title">
                  Virtuos CXNow and Qualtrics Core XM bring synergies
                </span>
                <span className="title-content">
                  Get started with the world’s most trusted research software —
                  Qualtrics XM. Drag-and-drop simplicity. Automated analytics.
                  It’s sophisticated research made simple. Apply Qualtrics XM
                  Technologies on the layers of CXNow CX Fusion of Digital
                  Transformation Services to offer new synergies and help
                  customers with outstanding experience edge.
                </span>
                <NavLink
                  to={`/cxnow/`}
                  className="btn"
                >
                  Read More <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
              <div className="col-3 logo-block">
                <figure className="img">
                  <img
                    src={`/img/experience-platform-img.jpg`}
                    alt="Experience Platform"
                  />
                </figure>
                <figure className="logo">
                  <img
                    src={`/img/svg/icons/virtuos-vaves-logo.svg`}
                    alt="Virtuos Vaves Logo"
                  />
                </figure>
              </div>
              <div className="col-3 hightlight-block">
                <div className="title">
                  The Experience Management (XM) Platform from Qualtrics
                </div>
                <p style={{fontSize:"16px"}}>
                  Design the experiences people want next. And continually
                  iterate and improve them. Meet the operating system for
                  experience management — Qualtrics XM from Virtuos Vaves.
                </p>
              </div>
            </div>
          </section>
          <section
            className="numbers-container"
            id="numbers"
            onMouseEnter={handleSectionHover}
          >
            <div className="row page-wrap">
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/customer-satisfaction-icon.svg`}
                    alt="Customer Satisfaction"
                  />
                </span>
                <div className="title">
                  <span className="fig-number">
                    <CountUp end={87} duration={4} />
                  </span>
                </div>
                <span className="subtitle">Customer Satisfaction</span>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/employee-engagement-icon.svg`}
                    alt="Employee Engagement"
                  />
                </span>
                <div className="title">
                  <span className="fig-number">
                    <CountUp end={93} duration={4} />
                  </span>
                </div>
                <span className="subtitle">Employee Engagement</span>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/digital-branding-icon.svg`}
                    alt="Brand Awareness"
                  />
                </span>
                <div className="title">
                  <span className="fig-number">
                    <CountUp end={61} duration={4} />
                  </span>
                </div>
                <span className="subtitle">Brand Awareness</span>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/customer-success-icon.svg`}
                    alt="Fortune 100 use Qualtrics"
                  />
                </span>
                <div className="title">
                  <span className="fig-number">
                    <CountUp end={85} duration={4} />
                  </span>
                  %
                </div>
                <span className="subtitle">
                  of the Fortune 100 use Qualtrics
                </span>
              </div>
            </div>
          </section>
          <section className="section-breaker qxm-video">
            <div className="row page-wrap content-block">
              <NavLink to="#" className="icon" ref={scrollToTopRef}>
                <img
                  src={`/img/icon-play.png`}
                  alt="Play Icon"
                />
              </NavLink>
              <span className="title">
                Virtuos delivers Qualtrics — Business Operating System for XM
              </span>
            </div>
          </section>
          <section className="usp-container">
            <div className="row page-wrap">
              <div className="col-4 item">
                <span className="number">01</span>
                <span className="title">Listen + Remember</span>
                <p>
                  Capture and store all your experience data from customers and
                  employees in a single system of record for every interaction
                  across the organisation.
                </p>
              </div>
              <div className="col-4 item">
                <span className="number">02</span>
                <span className="title">Process + Understand</span>
                <p>
                  Powerful, predictive analytics make sense of your entire
                  dataset, and proactively recommend the actions to take next.
                </p>
              </div>
              <div className="col-4 item">
                <span className="number">03</span>
                <span className="title">Build a culture of action</span>
                <p>
                  Intelligent, customisable workflows automatically alert the
                  right people, and trigger actions in every part of the
                  organisation.
                </p>
              </div>
            </div>
            <div className="row page-wrap usp">
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/expertise-icon.svg`}
                    alt="Deep Expertise in XM"
                  />
                </span>
                <span className="content">
                  <p>
                    <strong>Deep Expertise in XM</strong>
                  </p>
                  <p>More than 10 years of experience in #XM.</p>
                </span>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/customer-voice.svg`}
                    alt="Voice of Customer"
                  />
                </span>
                <span className="content">
                  <p>
                    <strong>Voice of Customer</strong>
                  </p>
                  <p>Virtuos CustomerVoice Practic is part of CXNow.</p>
                </span>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/cxaudit-emblem.svg`}
                    alt="CX Audit and Journeys"
                  />
                </span>
                <span className="content">
                  <p>
                    <strong>CX Audit and Journeys</strong>
                  </p>
                  <p>Conducting CX Audits and creating personas.</p>
                </span>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/powerful-partnership-icon.svg`}
                    alt="Close partnership"
                  />
                </span>
                <span className="content">
                  <p>
                    <strong>Close partnership</strong>
                  </p>
                  <p>Virtuos Vaves and Qualtrics work closely.</p>
                </span>
              </div>
            </div>
          </section>
          <Qualirics_cards />
          <section className="flip-container">
            <div className="row page-wrap">
              <div className="col-4 item">
                <div className="flip-wrapper vertical">
                  <div className="flipper">
                    <div className="front">
                      <span className="icon">
                        <img
                          src={`/img/svg/icons/ux-design-icon.svg`}
                          alt="Digital Design Skills"
                        />
                      </span>
                      <div className="content">
                        <span>
                          <strong>Digital Design Skills</strong>
                        </span>
                        <span>CX + EX + XX = XQ</span>
                      </div>
                    </div>
                    <div className="back">
                      <p>Digital Design Skills</p>
                      <p>
                        Digial Skills in creating and integrating CX, EX and
                        Everything Experience (XX)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 item">
                <div className="flip-wrapper vertical">
                  <div className="flipper">
                    <div className="front">
                      <span className="icon">
                        <img
                          src={`/img/svg/icons/consultare-co-icon.svg`}
                          alt="Consultare Services"
                        />
                      </span>
                      <div className="content">
                        <span>
                          <strong>Consultare Services</strong>
                        </span>
                        <span>Top-notch Consulting</span>
                      </div>
                    </div>
                    <div className="back">
                      <p>Consultare Services</p>
                      <p>
                        Business 4.0 specific high performance consulting
                        services across Industry verticals
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 item">
                <div className="flip-wrapper vertical">
                  <div className="flipper">
                    <div className="front">
                      <span className="icon">
                        <img
                          src={`/img/svg/icons/innovation-icon.svg`}
                          alt="Innovation ON"
                        />
                      </span>
                      <div className="content">
                        <span>
                          <strong>Innovation ON</strong>
                        </span>
                        <span>In-house Innovation Lab</span>
                      </div>
                    </div>
                    <div className="back">
                      <p>Innovation ON</p>
                      <p>
                        Innovation is at the heart of everything we do in the
                        Experience Business for our customers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="qxm-benefit">
            <div className="row">
              <div className="col-6 img-container" />
              <div className="col-6 content-container">
                <div className="subtitle">
                  Become An Experience Business with Qualtrics
                </div>
                <div className="title">
                  Distinctive benefits of Qualtrics CustomerXM
                </div>
                <ul>
                  <li>
                    Increase customer retention thanks to powerful analytics
                    that surface the key drivers of retention and recommend the
                    actions to take.
                  </li>
                  <li>
                    Understand the experience across every channel with
                    real-time feedback from over 120 sources and integrations
                    into your existing tech stack.
                  </li>
                  <li>
                    Act quickly to close experience gaps with automated actions
                    and workflows that turn insights into action.
                  </li>
                </ul>
                <NavLink
                  to={`/contact/`}
                  className="btn btn-white-outline"
                >
                  CONTACT US TO LEARN MORE
                </NavLink>
              </div>
            </div>
          </section>
          <section className="team-container">
            <div className="row page-wrap">
              <div className="col-12 header">
                <span className="subtitle">MEET SOME OF XM TEAM</span>
                <span className="title">EXPERIENCE ARCHITECTS</span>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-4 item">
                <div className="front">
                  <img
                    src={`/img/team-vir-img.jpg`}
                    alt="Venky Vijay"
                  />
                  <div className="overlay">
                    <div className="content">
                      <p id="whitefff" style={{ fontSize: "14px" }}>
                        Venky Vijay, the ExperienCEO comes with more than 25
                        years of experience in the Experience Business.
                      </p>
                      <div className="social-icon">
                        <NavLink to="https://www.linkedin.com/in/venkyvijay">
                          {" "}
                          <i className="fab fa-linkedin" />
                        </NavLink>
                        <NavLink to="https://www.twitter.com/venkyvijay">
                          {" "}
                          <i className="fab fa-twitter" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="des">
                  <p>Venky Vijay</p>
                  <p>ExperienCEO</p>
                </div>
              </div>
              <div className="col-4 item">
                <div className="front">
                  <img
                    src={`/img/team-ams-img.jpg`}
                    alt="Amarinder Singh"
                  />
                  <div className="overlay">
                    <div className="content">
                      <p id="whitefff" style={{ fontSize: "14px" }}>
                        Amarinder Singh, Director Delivery comes with more than
                        15 years of experience in digital design technology
                        services.
                      </p>
                      <div className="social-icon">
                        <NavLink to="https://www.linkedin.com/in/amarinder">
                          {" "}
                          <i className="fab fa-linkedin" />
                        </NavLink>
                        <NavLink to="http://www.twitter.com/singhamarinder">
                          {" "}
                          <i className="fab fa-twitter" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="des">
                  <p>Amarinder Singh</p>
                  <p>Director Delivery</p>
                </div>
              </div>
              <div className="col-4 item">
                <div className="front">
                  <img
                    src={`/img/team-gagan-img.jpg`}
                    alt="Gagan Bhatia"
                  />
                  <div className="overlay">
                    <div className="content">
                      <p id="whitefff" style={{ fontSize: "14px" }}>
                        Gagan is responsible for Qualtrics Sales and Experience
                        Management Delivery. He has about 8 years experience in
                        XM Business.
                      </p>
                      <div className="social-icon">
                        <NavLink to="https://www.linkedin.com/in/gagan-bhatia-a48765160/">
                          {" "}
                          <i className="fab fa-linkedin" />
                        </NavLink>
                        <NavLink to="http://www./twitter.com/letsgagaround">
                          {" "}
                          <i className="fab fa-twitter" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="des">
                  <p>Gagan Bhatia</p>
                  <p>Business Consultant</p>
                </div>
              </div>
            </div>
          </section>
          <section className="qualtrics-partner">
            <div className="row">
              <div className="col-6 img-container">
                <figure>
                  <img
                    src={`/img/qualtrics-partner-img.jpg`}
                    alt="Qualtrics Partner"
                  />
                </figure>
                <div className="logo">
                  <img
                    src={`/img/qualtrics-partner-logo.png`}
                    alt="Qualtrics Partner Logo"
                  />
                </div>
              </div>
              <div className="col-6 content-container">
                <h1 style={{ color: "white", fontSize: "2.4rem" }}>
                  Unique Opportunity to Partner with Qualtrics
                </h1>
                <ul className="points">
                  <li>
                    <div className="heading">
                      <span className="number">01.</span>
                      <span className="heading">CX.Digital Fusion of XM</span>
                    </div>
                    <p>
                      Virtuos + Qualtrics offer a combination of digital design
                      skills and cutting edge XM Technologies that 85% of
                      Fortune 100 Companies use.
                    </p>
                  </li>
                  <li>
                    <div className="heading">
                      <span className="number">02.</span>
                      <span className="heading">CRM and CX Expertise</span>
                    </div>
                    <p>
                      Virtuos is one of the leading Consulting Organizations in
                      delivering CRM Professional Services and CX Technologies.
                      Qualtrics XM becomes a perfect fit.
                    </p>
                  </li>
                  <li>
                    <div className="heading">
                      <span className="number">03.</span>
                      <span className="heading">
                        CXNOW Digital Transformation
                      </span>
                    </div>
                    <p>
                      Virtuos CXNOW, now in its 7th year of operations
                      delivering superior CX Transformation. Virtuos leverages
                      Qualtrics XM for its Core Offerings.
                    </p>
                  </li>
                  <li>
                    <div className="heading">
                      <span className="number">04.</span>
                      <span className="heading">
                        CX Strategy — Roadmap to Success
                      </span>
                    </div>
                    <p>
                      At Virtuos, we create and use Moments of Truth to enhance
                      your CX with Industry's first and most{" "}
                      <NavLink
                        to={`/cxnow/`}
                        className="link"
                      >
                        integrated building blocks
                      </NavLink>{" "}
                      and Qualtrics XM.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="cx-portflio-container">
            <div className="row page-wrap">
              <div className="col-5">
                <div className="title">
                  We provide high quality and cost effective services
                </div>
                <p>
                  Virtuos goes beyond measurement or assessment of CX.Digital
                  Maturity (Indicial) and turn customer feedback into something
                  you act on. Listen to customers at every stage of their
                  journey through web, mobile app, chat and SMS, and clearly
                  understand how to improve CX across all the horizons.
                </p>
              </div>
              <div className="col-5">
                <div className="progress-bar-container">
                  <div className="bar-wrapper">
                    <div className="labels">
                      {" "}
                      <span>Customer Voice (VOC)</span> <span>97%</span>
                    </div>
                    <div className="bar">
                      {" "}
                      <span className="blue" />{" "}
                    </div>
                  </div>
                  <div className="bar-wrapper">
                    <div className="labels">
                      {" "}
                      <span>CX.Digital Maturity</span> <span>96%</span>{" "}
                    </div>
                    <div className="bar">
                      {" "}
                      <span className="yellow" />{" "}
                    </div>
                  </div>
                  <div className="bar-wrapper">
                    <div className="labels">
                      {" "}
                      <span>Design Thinking</span> <span>95%</span>
                    </div>
                    <div className="bar">
                      {" "}
                      <span className="orange" />{" "}
                    </div>
                  </div>
                  <div className="bar-wrapper">
                    <div className="labels">
                      {" "}
                      <span>Continuous Improvement</span> <span>99%</span>
                    </div>
                    <div className="bar">
                      {" "}
                      <span className="green" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="overview-container">
            <div className="row page-wrap">
              <div className="col-6 content-wrapper">
                <div className="col-3 bg-img"></div>
                <div className="col-9 content-block">
                  <p>
                    <strong>
                      C.Digital is a breakthrough in implementing “CRM” across
                      the enterprise as a digital transformation initiative.
                    </strong>
                  </p>
                  <p>
                    C.Digital Design Mastermind Architecture has three elements
                    — a) human-centered Customer Digital Design b) CRM Digital
                    Engineering using AI and cutting edge technologies and, c)
                    Business 4.0 readiness with "Continuousness."
                  </p>
                  <p>
                    C.Digital Practice is founded on an agile, cost-effective,
                    and flexible model using AI, cloud, and crowd.
                  </p>
                  {/*						<NavLink to="#"><strong>Download C.Digital Overview</strong> <i class="fa fa-long-arrow-right"></i></NavLink>*/}
                </div>
              </div>
              <div className="col-5">
                <span className="title">
                  Digital Design is at the core of C.Digital Practice
                  <span className="quote">
                    <img
                      src={`/img/svg/icons/quote-icon.svg`}
                      alt="C.Digital Practice"
                    />
                  </span>
                </span>
                <p>
                  Virtuos uses a C.Digital Model of Digital Transformation and
                  PLUS Methodology — the Vivid Customer Experience Model using
                  the CXNow framework to execute consistently across
                  departments. This includes CX Discovery, CX Audit to journey
                  mapping, human-centric design thinking, and “Continuous Next”
                  programs.
                </p>
                {/*						<p>Signature</p>*/}
                <p>
                  <strong>Venky Vijay Reddi</strong>
                  <br />
                  Founder and ExperienCEO
                </p>
              </div>
            </div>
          </section>
          <section className="clients-container" style={{ overflow: "hidden" }}>
            <div className="row page-wrap">
              <div className="col-12">
                <ul
                  className="bxsliders"
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/svg/flipkart.svg`}
                      alt="Flipkart - Virtuos Client"
                      style={{ width: "7rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/svg/standard_chartered.svg`}
                      alt="Standard Chartered - Virtuos Client"
                      style={{ width: "7rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/svg/paytm-logo.svg`}
                      alt="Paytm - Virtuos Client"
                      style={{ width: "6rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/svg/giftcart-logo.svg`}
                      alt="Giftcart - Virtuos Client"
                      style={{ width: "7rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/svg/make-my-trip-logo.svg`}
                      alt="Make my trip - Virtuos Client"
                      style={{ width: "7rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/indiafirst-logo.jpg`}
                      alt="Indiafirst - Virtuos Client"
                      style={{ width: "6rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/tvscs-logo.png`}
                      alt="TVS Credit - Virtuos Client"
                      style={{ width: "6rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/svg/icons/fabindia-logo.svg`}
                      alt="Fabindia - Virtuos Client"
                      style={{ width: "7rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/hike-logo.png`}
                      alt="Hike - Virtuos Client"
                      style={{ width: "7rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/hdfc-life-logo.jpg`}
                      alt="HDFC Life - Virtuos Client"
                      style={{ width: "7rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/myntra-logo.png`}
                      alt="Myntra - Virtuos Client"
                      style={{ width: "7rem", height: "auto" }}
                    />
                  </li>
                  <li style={{ marginRight: "6em" }}>
                    <img
                      src={`/img/svg/icons/securitas-logo.svg`}
                      alt="Securitas - Virtuos Client"
                      style={{ width: "6rem", height: "auto" }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="testi-container">
            <div className="row page-wrap">
              <div class="col-12 header" style={{ margin: "-5rem" }}>
                <span class="subtitle">What People Say</span>
                <span class="title">Testimonials</span>
              </div>
            </div>
            <div className="row page-wrap" style={{ margin: "    11px auto" }}>
              <div className="col-4 testi-wrapper">
                <figure>
                  <img
                    src={`/img/svg/flipkart.svg`}
                    alt="Flipkart.com"
                  />
                </figure>
                <p style={{color:"#666"}}>
                  We chose RightNow, because of exceptional Consulting Approach
                  that Venky and Virtuos offered for Flipkart.
                </p>
                <span className="name">VP-Technology</span>
                <span  style={{color:"#666"}}>Flipkart.com</span>
              </div>
              <div className="col-4 testi-wrapper">
                <figure>
                  <img
                    src={`/img/standard-chartered-bank-logo.png`}
                    alt="Standard Chartered Bank"
                  />
                </figure>
                <p style={{color:"#666"}}>
                  With KANA solutions, we are able to meet the challenge of
                  providing the best experience for our customers.
                </p>
                <span className="name">Head of Technology</span>
                <span style={{color:"#666"}}>Standard Chartered Bank</span>
              </div>
              <div className="col-4 testi-wrapper">
                <figure>
                  <img
                    src={`/img/svg/make-my-trip-logo.svg`}
                    alt="MakeMyTrip.com"
                  />
                </figure>
                <p style={{color:"#666"}}>
                  RightNow CRM implemented by Virtuos has helped us in managing
                  our Customer Experiences better.
                </p>
                <span className="name">Chief Technology Officer</span>
                <span  style={{color:"#666"}}> MakeMyTrip.com</span>
              </div>
            </div>
          </section>
          <section className="tabs-container">
            <div className="row page-wrap tabs-wrapper" id="qual-tab">
              <div className="col-12 tab">
                <NavLink
                  rel="tab1"
                  className={`tablinks ${
                    activeTab === "tab1" ? "activee" : "unactivee"
                  }`}
                  onClick={() => handleClick("tab1")}
                  id="defaultOpen"
                >
                  <img
                    src={`/img/svg/icons/vision-icon.svg`}
                    alt=""
                  />
                  <br />
                  01. Vision Connect
                </NavLink>
                <NavLink
                  rel="tab2"
                  className={`tablinks ${
                    activeTab === "tab2" ? "activee" : "unactivee"
                  }`}
                  onClick={() => handleClick("tab2")}
                  id="defaultOpen"
                >
                  <img
                    src={`/img/svg/icons/tech-insight-icon.svg`}
                    alt=""
                  />
                  <br />
                  02. Tech Insights
                </NavLink>
                <NavLink
                  rel="tab3"
                  className={`tablinks ${
                    activeTab === "tab3" ? "activee" : "unactivee"
                  }`}
                  onClick={() => handleClick("tab3")}
                  id="defaultOpen"
                >
                  <img
                    src={`/img/svg/icons/pace-layer-icon-black.svg`}
                    alt=""
                  />
                  <br />
                  03. Pace Layered Strategy
                </NavLink>
                <NavLink
                  rel="tab4"
                  className={`tablinks ${
                    activeTab === "tab4" ? "activee" : "unactivee"
                  }`}
                  onClick={() => handleClick("tab4")}
                  id="defaultOpen"
                >
                  <img
                    src={`/img/svg/icons/heart-icon.svg`}
                    alt=""
                  />
                  <br />
                  04. Happiests Culture
                </NavLink>
              </div>
              <div className="row page-wrap">
                <div
                  id="tab1"
                  style={{ display: activeTab === "tab1" ? "block" : "none" }}
                  className="col-12 tabcontent"
                >
                  <div className="box1">
                    <figure>
                      <img
                        src={`/img/cdigital-tab1-img.jpg`}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="box2">
                    <p>
                      <strong>01. VISION CONNECT</strong>
                    </p>
                    <p>
                      Set operational objectives, establish a connect with the
                      cross-functional team, and understand CX initiatives to
                      manage current state (CXOpia).
                    </p>
                  </div>
                  <div className="box3">
                    <p>
                      <strong>You are in safe hands</strong>
                    </p>
                    <p>
                      Virtuos has implemented over 200 CX projects across
                      diverse verticals and technology domains with high
                      Customer Success rate.
                    </p>
                    <NavLink
                      to={`/cxnow/cxprimer`}
                      className="btn btn-black"
                    >
                      Schedule CX Primer
                    </NavLink>
                  </div>
                </div>
                <div
                  style={{ display: activeTab === "tab2" ? "block" : "none" }}
                  className="col-12 tabcontent"
                  id="tab2"
                >
                  <div className="box1">
                    <figure>
                      <img
                        src={`/img/cdigital-tab2-img.jpg`}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="box2">
                    <p>
                      <strong>02. TECH INSIGHTS</strong>
                    </p>
                    <p>
                      Insight into the technologies that help customers to
                      evaluate, measure, and act on customer perception,
                      sentiment, and experiences.
                    </p>
                  </div>
                  <div className="box3">
                    <p>
                      <strong>Our Technology Portfolio</strong>
                    </p>
                    <p>
                      Virtuos has certified professionals on multiple CRM
                      Technologies such as Salesforce, Oracle, Microsoft, and
                      Creatio (formerly BPM’Online).
                    </p>
                    <NavLink
                      to={`/consultare/technology-services`}
                      className="btn btn-black"
                    >
                      See our Portfolio
                    </NavLink>
                  </div>
                </div>
                <div
                  style={{ display: activeTab === "tab3" ? "block" : "none" }}
                  className="col-12 tabcontent"
                  id="tab3"
                >
                  <div className="box1">
                    <figure>
                      <img
                        src={`/img/cdigital-tab3-img.jpg`}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="box2">
                    <p>
                      <strong>03. PACE LAYERED STRATEGY</strong>
                    </p>
                    <p>
                      It is a framework of "Pace Layers" with the precise
                      definition of strategic goals, and how these goals are
                      achieved through a process of iteration.
                    </p>
                  </div>
                  <div className="box3">
                    <p>
                      <strong>Differentiation Strategy</strong>
                    </p>
                    <p>
                      Systems of records and processes focus on standardization.
                      Using our NOW framework, we structure various
                      differentiation techniques.
                    </p>
                    <NavLink
                      to={`/consultare/pace-layered`}
                      className="btn btn-black"
                    >
                      See PLUS methodology
                    </NavLink>
                  </div>
                </div>
                <div
                  style={{ display: activeTab === "tab4" ? "block" : "none" }}
                  className="col-12 tabcontent"
                  id="tab4"
                >
                  <div className="box1">
                    <figure>
                      <img
                        src={`/img/cdigital-tab4-img.jpg`}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="box2">
                    <p>
                      <strong>04. HAPPIESTS CULTURE</strong>
                    </p>
                    <p>
                      It’s not just a happy working environment; it’s employees’
                      own space — where they are obsessed to deliver happiness
                      to customers.
                    </p>
                  </div>
                  <div className="box3">
                    <p>
                      <strong>Happiests Building Blocks</strong>
                    </p>
                    <p>
                      Our unique h-a-p-p-i-e-s-t-s building blocks define the
                      characteristics of our culture. These nine styles fit into
                      integrated culture framework.
                    </p>
                    <NavLink
                      to={`/careers/happiests-culture`}
                      className="btn btn-black"
                    >
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="dthink-container">
            <div className="row page-wrap">
              <div className="col-3">
                <span className="title " style={{fontWeight:"300"}}>
                  We combine CX Strategy and Design Thinking
                </span>
                <p style={{color:"#666"}}>
                  At Virtuos, we bring together domain expertise, design
                  thinking, journey mapping, and utilize our Innovation On
                  (formerly Innovation Open) lab to ideate and formulate
                  solutions.
                </p>
              </div>
              <NavLink
                to={`/cx/strategy-design`}
                className="col-2"
              >
                <span className="icon">
                  <img
                    src={`/img/svg/icons/cx-logo.svg`}
                    alt="CX Strategy & Design"
                  />
                </span>
                <p>CX Strategy &amp; Design</p>
              </NavLink>
              <NavLink
                to={`/io`}
                className="col-2"
              >
                <span className="icon">
                  <img
                    src={`/img/svg/icons/io-logo.svg`}
                    alt="Innovation On"
                  />
                </span>
                <p>Innovation On (iO)</p>
              </NavLink>
              <NavLink
                to={`/business4-0/`}
                className="col-2"
              >
                <span className="icon">
                  <img
                    src={`/img/business4-icon.png`}
                    alt="Business 4.0"
                  />
                </span>
                <p>Business 4.0</p>
              </NavLink>
              <NavLink
                to={`/corporate/team/`}
                className="col-2"
              >
                <span className="icon">
                  <img
                    src={`/img/svg/icons/virtuos-heart.svg`}
                    alt="Virtuoso Team DNA"
                  />
                </span>
                <p>Virtuoso Team DNA</p>
              </NavLink>
            </div>
          </section>
          <section className="leadership">
            <div className="row page-wrap">
              <div className="col-12 header">
                <h4>THOUGHT LEADERSHIP</h4>
                <h2>Resources from our Library on Customer Experience (CX)</h2>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-4 item">
                <figure>
                  <NavLink
                    to={`/c-digital/xlv/`}
                  >
                    <img
                      src={`/img/customer-id-exp-id-img.jpg`}
                      alt="Customer ID and Experience ID"
                    />
                  </NavLink>
                </figure>
                <div className="content">
                  <NavLink
                    to={`/c-digital/xlv/`}
                    className="title"

                  >
                    Introducing Customer ID and Experience ID
                  </NavLink>
                  <p style={{fontSize:"16px"}}>
                    Building on the new Customer Engagement Value by building
                    the best practices for Customer Lifetime Value (CLV) and
                    introducing Experience Lifetime Value (XLV)...
                  </p>
                </div>
              </div>
              <div className="col-4 item">
                <figure>
                  <NavLink
                    to={`/c-digital/customer-wide/`}
                  >
                    <img
                      src={`/img/cdigital-img.jpg`}
                      alt="C.Digital"
                    />
                  </NavLink>
                </figure>
                <div className="content">
                  <NavLink
                    to={`/c-digital/customer-wide/`}
                    className="title"
                  >
                    C.Digital Where "C" Means Customer
                  </NavLink>
                  <p style={{fontSize:"16px"}}>
                    As consumers become more digital, four attitudes are
                    changing the way they expect to interact with organizations.
                    Take these attitudes into account...
                  </p>
                </div>
              </div>
              <div className="col-4 item">
                <figure>
                  <NavLink
                    to={`/c-digital/customer360/`}
                  >
                    <img
                      src={`/img/customer-360-img.jpg`}
                      alt="Customer360"
                    />
                  </NavLink>
                </figure>
                <div className="content">
                  <NavLink
                    to={`/c-digital/customer360/`}
                    className="title"
                  >
                    Do you have a Customer360 Program?
                  </NavLink>
                  <p style={{fontSize:"16px"}}>
                    Learn how we design, construct and deliver Qualtrics XM as
                    part of Customer360 Program. See some of the examples from
                    the Industry verticals here...
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/*?php include '../footer-consultare.php'; ?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Qualtrics;
