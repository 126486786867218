import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Electronics_Company_Case_Study = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };

    useEffect(() => {
        document.title = "Virtuos Customer Success Stories | Electronics giant CX case study – Virtuos Digital";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/electronics-company-header-img.jpg`}
                                    alt="Electronics Company Casestudy"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/svg/confidential-logo.svg`}
                                        alt="Confidential Icon"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        Customer Engagement Management Solution for a leading consumer
                                        electronics company
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <section className="breadcrumb-kanaban">
                            <div className="page-wrap breadcrumb-wrapper">
                                <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                    <li>
                                        <NavLink to={`/corporate/`}>About Virtuos</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/customers/`} className="active">
                                            Customer Success
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={`/customers#success-stories`}
                                            className="smooth"
                                        >
                                            CUSTOMER SUCCESS STORIES
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/succeedant/`} className="smooth">
                                            SUCCEEDANT CSM
                                        </NavLink>
                                    </li>

                                </ul>
                                <ul className="more-hidden-tabs">
                                    <li
                                        className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <i className="fal fa-plus" />  More
                                        {showExtraLinks && (
                                            <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                                <li>
                                                    <NavLink to={`/corporate/experience-edge/`}>
                                                        EXPERIENCE EDGE
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`/insights/`}>
                                                        INSIGHTS AND RESOURCES
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                <h3 className="title">About the Company</h3>
                                <p>
                                    A leading global provider of better-life solutions with its
                                    established philosophy of entrepreneurial spirit, the company is not
                                    confined by the existing boundaries of home appliance. Rather, they
                                    relentlessly strive for innovations and changes, hoping to lead the
                                    future life styles and improve environment performance with all-new
                                    concepts, technologies, products, and services in our unique way.
                                </p>
                                <p>
                                    The company initially only had one communication channel, the
                                    telephone, but expanded into eight, including Wechat, online chatting,
                                    E-mail, and other social media to provide better service. Virtuos
                                    helped them in this expansion.
                                </p>
                                <h3 className="title">Highlights</h3>
                                <div className="highlights-wrapper">
                                    <div className="row">
                                        <div className="col-4 item">
                                            <span className="header">
                                                TAT <i className="fas fa-arrow-alt-down red" />
                                            </span>
                                            <p>Reduction in Turn-Around Time in responding to queries</p>
                                        </div>
                                        <div className="col-4 item">
                                            <span className="header">
                                                C-SAT <i className="fas fa-arrow-alt-up green" />
                                            </span>
                                            <p>Increased customer satisfaction level</p>
                                        </div>
                                        <div className="col-4 item">
                                            <span className="header">
                                                Agent Training Time <i className="fas fa-arrow-alt-down red" />
                                            </span>
                                            <p>Reduction in efforts spent on Agent Training</p>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="title">Modules Used</h3>
                                <ul>
                                    <li>Incident Management</li>
                                    <li>SLAs and Escalation Management</li>
                                    <li>Guided Assistance for Agents</li>
                                    <li>Knowledge Management</li>
                                    <li>Social Listening and sentiment analysis</li>
                                    <li>Social Customer Service</li>
                                    <li>Competitor analysis on Social</li>
                                    <li>Live Agent Assistance</li>
                                    <li>Customer Portal</li>
                                    <li>Surveys</li>
                                </ul>
                                <h3 className="title">Challenges faced by the Company</h3>
                                <div className="challenge-wrapper">
                                    <div className="row items-container offport-down">
                                        <div className="col-3 item">
                                            Improve customer service for Sales and Post Sales
                                        </div>
                                        <div className="col-3 item">
                                            Consistency of agent response to inquiries
                                        </div>
                                        <div className="col-3 item">
                                            Agent efficiency, activity rate per employee, and considerable TAT
                                        </div>
                                        <div className="col-3 item">
                                            Capturing actionable feedback from customers
                                        </div>
                                    </div>
                                    <div className="row items-container offport-down">
                                        <div className="col-3 item">
                                            Consolidated single agent view of Customer activity
                                        </div>
                                        <div className="col-3 item">
                                            Ability for customers to self-serve inquiries
                                        </div>
                                    </div>
                                </div>
                                <h3 className="title">Virtuos Approach</h3>
                                <p>
                                    Virtuos implemented Customer engagement management solution for the
                                    company for efficient management of various processes, with an aim to
                                    improve customer experiences. The solution implemented by Virtuos in
                                    effective monitoring of large volume of service requests and
                                    complaints from multiple channels including phone, emails, chats, and
                                    social channels. Keeping the business challenge of Agent training and
                                    efficiency in perspective, Virtuos implemented “Guided Assistance”
                                    feature which guides the agents step-by-step to achieve an outcome.
                                </p>
                                <p>
                                    As a part of effective escalation mechanism, Virtuos integrated the
                                    solution with an SMS gateway, to send escalation notifications to
                                    relevant stakeholders on SMS for quick access. Virtuos leveraged the
                                    technology for providing an end-to-end social relationship management
                                    solution, thus extending the customer service to social channel.
                                    Robust social listening tools were implemented for customer sentiment
                                    and competitor analysis.
                                </p>
                                <p>
                                    Comprehensive reports were also created for the organization to have a
                                    broad view of chat channel, with readily available reports like report
                                    for average percentage of Chat surveys initiated and completed, thus
                                    assisting in better performance tracking.
                                </p>
                                <figure className="blog-content-image offport-down">
                                    <img
                                        src={`/img/consumer-electronics-img.jpg`}
                                        alt="Electronics Company Casestudy"
                                    />
                                    <span>
                                        Image Source: <small>Shutterstock</small>{" "}
                                    </span>
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="customer-success-section">
                        <h2 className="section-main-heading">
                            Other <span className="highlight">Case Studies</span>
                        </h2>
                        <div className="clients-wrapper">
                            <ul className="row page-wrap offport-down">
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/myntra-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/myntra-casestudy-img.jpg`}
                                                alt="Myntra Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/myntra-logo.png`}
                                                alt="Myntra Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p>Customer Engagement Center Applications</p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/indiafirst-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/indiafirst-casestudy-img.jpg`}
                                                alt="Indiafirst Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/indiafirst-logo.jpg`}
                                                alt="Indiafirst Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p>Ensure answer consistency across channels and departments</p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/entertainment-company-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/entertainment-company-casestudy-img.jpg`}
                                                alt="Entertainment Company Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/confidential-logo.svg`}
                                                alt="Confidential Icon"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p>Reducing Customer Effort and Increasing Staff Productivity</p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/tvs-credit-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/tvsc-casestudy-img.jpg`}
                                                alt="TVS Credit Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/tvscs-logo.png`}
                                                alt="TVS Credit"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p>
                                            Customer Experience Transformation Project using Oracle platform
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Electronics_Company_Case_Study
