import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import ReCaptcha from "react-google-recaptcha";
// import emailjs from "@emailjs/browser";
// import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
const Career_Job = () => {
  const [file, setFile] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState("");
  const [fileError, setFileError] = useState(false);
  const allowedExtensions = ["jpg", "jpeg", "png", "pdf", "doc", "docx"];
  const validateImageFile = (file) => {
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && validateImageFile(selectedFile)) {
      // File is valid, perform necessary actions
      // ...
      // setFile(e.target.files[0]);
      setFile(e.target.files[0]);
      setFileError(false);
      console.log("File is valid");
    } else {
      // Invalid file, display error message or handle accordingly
      // ...
      setFile(null);
      setFileError(true);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      console.log("Invalid file");
      // return false;
    }
  };
  const handleRecaptchaChange = (value) => {
    console.log("Recaptcha");
    setRecaptchaValue(value);
    setCaptchaError("Verified");
  };

  const [phoneError, setPhoneError] = useState(false);

  const [userData, setuserData] = useState({
    webURL: `/careers/jobs`,
    formType: "Job_new",
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const navigate = useNavigate();
  //we are storign data in states
  const handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setuserData({ ...userData, [name]: value });
    console.log("inside handlechange");
    // console.log(userData.name)
  };

  // send data to bacckend
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaError) {
      setCaptchaError("Verification pending");
      return;
    }
    const { webURL, formType, name, email, phone, message, jobFile } = userData;
    if (!userData.phone) {
      setPhoneError(true);
      // return;
    } else {
      setPhoneError(false);
      console.log("insidde contcat form");
      console.log("filename", file);
      const formData = new FormData();
      formData.append("webURL", webURL);
      formData.append("type", formType);
      formData.append("full_name", name);
      formData.append("email", email);
      formData.append("mobile", phone);
      formData.append("message", userData.message);
      formData.append("jobfile", file);

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/db/job`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      console.log("response : ", res);

      // const data = await res.json();

      if (!res) {
        console.log("message not send");
      } else {
        // alert( "message send" );
        setuserData({ ...userData, message: "" });
        navigate("/thankyou"); // Navigate to "/thankyou" route
      }
    }

    //////////////////EMAIL JS//////////////////////

    /*emailjs
     .send(
       process.env.REACT_APP_SERVICE_ID,
       process.env.REACT_APP_TEMPLATE_ID,
       {
         from_name: userData.name,
         to_name: process.env.REACT_APP_TO_NAME,
         from_email: userData.email,
         to_email: process.env.REACT_APP_TO_EMAIL,
         message: userData.message,
       },
       process.env.REACT_APP_PUBLIC_KEY
     )
     .then(
       () => {

         // alert("Thank you. I will get back to you as soon as possible.");

         setuserData({
           name: "",
           email: "",
           phone: "",
           message: ""
         });

       },
       (error) => {

         console.error(error);

         // alert("Ahh, something went wrong. Please try again.");
       }
     );*/
    //////////////////EMAIL JS//////////////////////
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [message, setMessage] = useState("");
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleChangeMessage = (event) => {
    const value = event.target.value;
    setuserData((prevUserData) => ({
      ...prevUserData,
      message: value,
    }));
  };

  const remainingChars = 200 - userData.message.length;

  const [showExtraLinks, setShowExtraLinks] = useState(false);

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };

  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };
  useEffect(() => {
    document.title =
      "Career Opportunities in CRM, CX, Engineering, and Development – Virtuos Careers";
  }, []);
  return (
    <div className="careers-job-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-10">
                  <figure>
                    <img
                      src={`/img/svg/people-img.svg`}
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/careers/`}>
                    CAREERS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/careers/jobs/`}
                    className="active"
                  >
                    CURRENT JOBS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/careers/faq/`}
                  >
                    FAQs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/careers/opportunities/`}
                  >
                    JOB OPPORTUNITIES
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/careers/experience-job/`}
                  >
                    EXPERIENCEJOB
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/careers/culture/`}
                  >
                    CULTURE &amp; VALUES
                  </NavLink>
                </li>
              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${
                    showExtraLinks ? "active" : ""
                  } inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" /> More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink
                          to={`/careers/life-at-virtuos/`}
                        >
                          LIFE AT VIRTUOS
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/careers/training-development/`}
                        >
                          TRAINING and Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/careers/happiests-culture/`}
                        >
                          HAPPIESTS CULTURE
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/careers/employee-benefits/`}
                        >
                          EMPLOYEE BENEFITS
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/careers/360Outlook/`}
                        >
                          360<sup>0</sup> OUTLOOK
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/careers/virtuos-alumni/`}
                        >
                          VIRTUOS ALUMNI CLUB
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          {/* Careers Job Listing */}
          <section className="page-wrap job-openings-section">
            <h3 className="section-heading">Development &amp; Engineering</h3>
            <div className="row">
              <div className="col-4 item">
                <NavLink
                  to={`/careers/jobs/.net-web-developer`}
                  className="job-item-wrapper"
                >
                  <figure>
                    <img
                      src={`/img/svg/microsoft.net-career-img.svg`}
                      alt=".Net Developer Job"
                    />
                  </figure>
                  <div className="job-info">
                    <div className="jd-wrapper">
                      <span>3-5 Years</span>
                      <strong className="title">.Net Developer</strong>
                      <span>Gurgaon</span>
                    </div>
                    <div className="career-tags">
                      <ul className="row">
                        <li>.Net</li>
                        <li>ASP.Net</li>
                        <li>HTML</li>
                        <li>XML</li>
                        <li>CSS</li>
                      </ul>
                    </div>
                    <span className="static-text">
                      Click to view full job detail
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-4 item">
                <NavLink
                  to={`/careers/jobs/drupal-developer`}
                  className="job-item-wrapper"
                >
                  <figure>
                    <img
                      src={`/img/svg/drupal-career-img.svg`}
                      alt="Drupal Developer Job"
                    />
                  </figure>
                  <div className="job-info">
                    <div className="jd-wrapper">
                      <span>3-5 Years</span>
                      <strong className="title">Drupal Developer</strong>
                      <span>Gurgaon</span>
                    </div>
                    <div className="career-tags">
                      <ul className="row">
                        <li>PHP</li>
                        <li>API</li>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>JS Libraries</li>
                        <li>jQuery</li>
                      </ul>
                    </div>
                    <span className="static-text">
                      Click to view full job detail
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-4 item">
                <NavLink
                  to={`/careers/jobs/magento-developer`}
                  className="job-item-wrapper"
                >
                  <figure>
                    <img
                      src={`/img/svg/magento-career-img.svg`}
                      alt="Magnto Developer Job"
                    />
                  </figure>
                  <div className="job-info">
                    <div className="jd-wrapper">
                      <span>2+ years</span>
                      <strong className="title">Magento Developer</strong>
                      <span>Gurgaon</span>
                    </div>
                    <div className="career-tags">
                      <ul className="row">
                        <li>PHP</li>
                        <li>Zend</li>
                        <li>MySQL</li>
                        <li>Magento</li>
                        <li>XML</li>
                      </ul>
                    </div>
                    <span className="static-text">
                      Click to view full job detail
                    </span>
                  </div>
                </NavLink>
              </div>
            </div>
            <h3 className="section-heading">Creativity &amp; Designing</h3>
            <div className="row offport-down">
              <div className="col-4 item">
                <NavLink
                  to={`/careers/jobs/web-graphic-designer`}
                  className="job-item-wrapper"
                >
                  <figure>
                    <img
                      src={`/img/svg/graphic-design-career-img.svg`}
                      alt="Web & Graphics Designer"
                    />
                  </figure>
                  <div className="job-info">
                    <div className="jd-wrapper">
                      <span>4-8 Years</span>
                      <strong className="title">
                        Web &amp; Graphics Designer
                      </strong>
                      <span>Gurgaon</span>
                    </div>
                    <div className="career-tags">
                      <ul className="row">
                        <li>UX</li>
                        <li>Wireframe</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>Coral Draw</li>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>JQuery</li>
                      </ul>
                    </div>
                    <span className="static-text">
                      Click to view full job detail
                    </span>
                  </div>
                </NavLink>
              </div>
            </div>
            <h3 className="section-heading">Business Development</h3>
            <div className="row offport-down">
              <div className="col-4 item">
                <NavLink
                  to={`/careers/jobs/business-development-executive`}
                  className="job-item-wrapper"
                >
                  <figure>
                    <img
                      src={`/img/svg/business-development-executive-career-img.svg`}
                      alt="Business Development Executive Job"
                    />
                  </figure>
                  <div className="job-info">
                    <div className="jd-wrapper">
                      <span>1-3 Years</span>
                      <strong className="title">
                        Business Development Executive
                      </strong>
                      <span>Gurgaon</span>
                    </div>
                    <div className="career-tags">
                      <ul className="row">
                        <li>Sales</li>
                        <li>B2B</li>
                        <li>B2C</li>
                        <li>CRM</li>
                        <li>Sales Strategy</li>
                        <li>Business Acumen</li>
                        <li>Client relationships</li>
                      </ul>
                    </div>
                    <span className="static-text">
                      Click to view full job detail
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-4 item">
                <NavLink
                  to={`/careers/jobs/business-head`}
                  className="job-item-wrapper"
                >
                  <figure>
                    <img
                      src={`/img/svg/business-head-career-img.svg`}
                      alt="Business Head Job"
                    />
                  </figure>
                  <div className="job-info">
                    <div className="jd-wrapper">
                      <span>12+ Years</span>
                      <strong className="title">Business Head</strong>
                      <span>Gurgaon</span>
                    </div>
                    <div className="career-tags">
                      <ul className="row">
                        <li>Sales</li>
                        <li>B2B</li>
                        <li>B2C</li>
                        <li>CRM</li>
                        <li>Sales Strategy</li>
                        <li>Business Acumen</li>
                        <li>Client relationships</li>
                      </ul>
                    </div>
                    <span className="static-text">
                      Click to view full job detail
                    </span>
                  </div>
                </NavLink>
              </div>
            </div>
            <h3 className="section-heading">Consulting</h3>
            <div className="row offport-down">
              <div className="col-4 item">
                <NavLink
                  to={`/careers/jobs/crm-lead-consultant`}
                  className="job-item-wrapper"
                >
                  <figure>
                    <img
                      src={`/img/svg/crm-lead-consultant-career-img.svg`}
                      alt="CRM Lead Consultant Job"
                    />
                  </figure>
                  <div className="job-info">
                    <div className="jd-wrapper">
                      <span>7+ Years</span>
                      <strong className="title">CRM Lead Consultant</strong>
                      <span>Gurgaon</span>
                    </div>
                    <div className="career-tags">
                      <ul className="row">
                        <li>CRM</li>
                        <li>Salesforce</li>
                        <li>Sales Cloud</li>
                        <li>Service Cloud</li>
                        <li>Marketing Cloud</li>
                        <li>Force.com</li>
                        <li>VisualForce</li>
                        <li>CPQ</li>
                      </ul>
                    </div>
                    <span className="static-text">
                      Click to view full job detail
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-4 item">
                <NavLink
                  to={`/careers/jobs/crm-solution-consultant`}
                  className="job-item-wrapper"
                >
                  <figure>
                    <img
                      src={`/img/svg/crm-solution-consultant-career-img.svg`}
                      alt="CRM Solution Consultant Job"
                    />
                  </figure>
                  <div className="job-info">
                    <div className="jd-wrapper">
                      <span>2-7 Years</span>
                      <strong className="title">
                        CRM/RightNow Solution Consultant
                      </strong>
                      <span>Gurgaon</span>
                    </div>
                    <div className="career-tags">
                      <ul className="row">
                        <li>CRM</li>
                        <li>RFP</li>
                        <li>MS Dynamics</li>
                        <li>SAP</li>
                        <li>KANA</li>
                        <li>Oracle</li>
                        <li>Presales</li>
                        <li>Sales</li>
                        <li>Solution Sales</li>
                      </ul>
                    </div>
                    <span className="static-text">
                      Click to view full job detail
                    </span>
                  </div>
                </NavLink>
              </div>
            </div>
          </section>
          <section>
            <div className="row page-wrap">
              <NavLink
                to={`/careers/faq/`}
                className="col-12 faq-heading"
              >
                <figure>
                  <img
                    src={`/img/svg/faq-img.svg`}
                    alt="FAQs"
                  />
                </figure>
                <h2>
                  Before Applying
                  <br />
                  Please check FAQ
                </h2>
              </NavLink>
            </div>
          </section>
          <section className="grey-bg job-form-wrapper">
            <h2 className="section-main-heading">
              Didn't find the <span className="highlight">position</span> you
              are looking for?
            </h2>
            <span className="section-desc">
              Drop your CV. If any vacancy comes up we'll get in touch with you.
            </span>
            <div className="page-wrap form-wrapper">
              <form
                // action=""
                method="POST"
                id="Job_new"
                // encType="multipart/form-data"
              >
                <div className="row offport-down">
                  <div className="col-6 left-wrapper">
                    <div className="input-wrapper hidden-fields">
                      <input type="text" name="pageURL" className="pageURL" />
                      <input
                        type="text"
                        name="formType"
                        id="formType"
                        defaultValue="Job_new"
                      />
                      <input
                        type="text"
                        name="location"
                        className="userLocation"
                      />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="">Full Name</label>
                      <input
                        type="text"
                        name="name"
                        maxLength={100}
                        value={userData.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="">Email</label>
                      <input
                        type="text"
                        name="email"
                        value={userData.email}
                        onChange={handleInput}
                        required
                      />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="">Mobile</label>
                      <input
                        type="text"
                        name="phone"
                        value={userData.phone}
                        onChange={handleInput}
                        required
                      />
                      {phoneError && (
                        <p
                          className="required-message"
                          style={{ fontSize: "12px", color: "red" }}
                        >
                          *Mobile is required
                        </p>
                      )}
                    </div>
                    <div className="input-wrapper careerjob">
                      {/*<label cf-label="" for="cv-file" class="file-attachment-text"> <i class="fas fa-cloud-upload"></i> Upload/Drop your CV</label>*/}
                      <input
                        type="file"
                        id="jobFile"
                        name="jobFile"
                        required
                        style={{ display: "block !important" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                      {fileError && (
                        <p
                          className="required-message"
                          style={{ fontSize: "12px", color: "red" }}
                        >
                          *Invalid File
                        </p>
                      )}
                    </div>
                    <div className="input-wrapper hidden-fields ">
                      <input type="text" name="fileUpload" id="fileResult" />
                    </div>
                  </div>
                  <div className="col-6 right-wrapper">
                    <div className="input-wrapper">
                      <label htmlFor="">Message (If any)</label>
                      <textarea
                        required
                        name="message"
                        cols={30}
                        rows={3}
                        value={userData.message}
                        placeholder="Optional"
                        onChange={handleChangeMessage}
                        onKeyDown={(event) => {
                          if (
                            event.target.value.length >= 200 &&
                            event.key !== "Backspace"
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onKeyUp={(event) => {
                          if (event.target.value.length > 200) {
                            const truncatedValue = event.target.value.slice(
                              0,
                              200
                            );
                            setuserData((prevUserData) => ({
                              ...prevUserData,
                              message: truncatedValue,
                            }));
                          }
                        }}
                      />
                      <span style={{ fontSize: "12px" }}>
                        Remaining characters: {remainingChars}
                      </span>
                      <input type="file" name="mobile" required="" />
                    </div>
                    <div className="input-wrapper">
                      <ReCaptcha
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={handleRecaptchaChange}
                      />
                    </div>
                    {captchaError && (
                        <p
                          className="required-message"
                          style={{ fontSize: "12px", color: "red" }}
                        >
                          {captchaError}
                        </p>
                      )}

                  </div>
                </div>
                <div className="btn-wrapper">
                  <input
                    className="btn btn-secondary"
                    type="submit"
                    defaultValue="Submit"
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            </div>
          </section>
          {/*?php include '../../footer.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Career_Job;
