import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
const Customer_Experience_Meet_2012 = () => {
    const CustomPrevArrow = ({ onClick }) => (
        <div className="prev-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
    );
    const CustomNextArrow = ({ onClick }) => (
        <div className="next-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    useEffect(() => {
        document.title = "2012 Customer Experience Meet – First of its kind 'customer' focussed summit held jointly by Virtuos Solutions and Oracle RightNow – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/customer-experience-meet-2012-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 12 May, 2012</span>
                                <h2 className="article-heading">
                                    2012 Customer Experience Meet — First of its kind "customer" focussed
                                    summit held jointly by Virtuos Solutions and Oracle RightNow
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p className="italic">
                                    CX Meet with the central theme of "Changing the Channel '' attended by
                                    leaders in the vertical e-commerce, Travel, BFSI, and Automobile, with
                                    Keynote Speakers from Virtuos, Oracle RightNow, and Frost and
                                    Sullivan.
                                </p>
                                <p>
                                    New Delhi, May 12th, 2012 - Virtuos Solutions announced today that it
                                    successfully hosted the first-ever Virtuos 2012 Customer Experience
                                    Meet with Oracle RightNow — The leader in Customer Experience (CX)
                                    Solutions. The event was held at the prestigious luxury five star
                                    hotel Leela Kempinski Gurgaon India.
                                </p>
                                <p>
                                    The CX Meet (
                                    <NavLink to="http://www.cxmeet.com" target="_blank" className="link">
                                        www.cxmeet.com
                                    </NavLink>
                                    ) focused on how brands can create compelling value propositions,
                                    develop, and execute customer experience strategies using Oracle
                                    RightNow CX, the customer experience suite and learn from Oracle
                                    RightNow's best practice guidance and expertise of serving over 2000
                                    global customers. The event was attended by CXO level executives from
                                    companies including MakeMytrip.com and Flipkart.com, who were also
                                    Panel members and have successfully deployed Oracle RightNow CX suite.
                                </p>
                                <p>
                                    The event began with a keynote address by Virtuos Principal Consultant
                                    &amp; CEO, Venky Vijay, on "The Future of Customer Experience",
                                    followed by a speech from KeyNote Speaker: Ted Bray, Regional VP
                                    Indirect Channels APAC Oracle RightNow. Ted Bray covered "The Customer
                                    Experience (CX) Value Equation," and Mr. Manoj Menon, Partner and
                                    Managing Director from Frost &amp; Sullivan Singapore, addressed "Mega
                                    Trends 2020 Implications for Businesses".
                                </p>
                                <div className="photo-slider-wrapper">
                                    <Slider {...settings} className="bxslider photo-slider">
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/cx-meet-2012-pic-01.jpg`}
                                                    alt="Keynote by Venky Vijay"
                                                />
                                            </figure>
                                            <span>Keynote by Venky Vijay</span>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/cx-meet-2012-pic-02.jpg`}
                                                    alt="Keynote by Ted Bray"
                                                />
                                            </figure>
                                            <span>Keynote by Ted Bray</span>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/cx-meet-2012-pic-03.jpg`}
                                                    alt="Venky, Rajnish Kapur and Ted on the Panel"
                                                />
                                            </figure>
                                            <span>Venky, Rajnish Kapur and Ted on the Panel</span>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/cx-meet-2012-pic-04.jpg`}
                                                    alt="Manoj Menon Frost & Sullivan"
                                                />
                                            </figure>
                                            <span>Manoj Menon Frost &amp; Sullivan</span>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/cx-meet-2012-pic-05.jpg`}
                                                    alt="Venky and Ted on the panel with Flipkart team"
                                                />
                                            </figure>
                                            <span>Venky and Ted on the panel with Flipkart team</span>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/cx-meet-2012-pic-06.jpg`}
                                                    alt="Ruben Martin on Changing the Channel"
                                                />
                                            </figure>
                                            <span>Ruben Martin on Changing the Channel</span>
                                        </div>
                                    </Slider>
                                </div>
                                <br />
                                <p>
                                    <span className="underline">
                                        Mr. Rajnish Kapur, Chief Innovation &amp; Customer Experience
                                        Officer, MakeMyTrip.com, and Mr. Rathinamurthy Product Manager -
                                        CRM, Flipkart.com
                                    </span>
                                    , attended the meeting panelists. Both of them have shared their
                                    experiences on how the implementation of Oracle RightNow CX has
                                    enabled their companies to take customer interaction and support to
                                    the next level, thus improving customer satisfaction and retention.
                                    The event brought us to its theme of "Great customer experience" as it
                                    covered various aspects of how organizations today can delight their
                                    customers by "Changing the Channel" of customer interaction.
                                </p>
                                <p>
                                    <span className="underline">
                                        Mr. Venky Vijay Principal Consultant &amp; CEO, Virtuos Solutions
                                    </span>
                                    , stated, "Customer is the key to the survival and prosperity of any
                                    business. Traditional channels of customer service are no longer
                                    sufficient to provide great customer experience and delight. To
                                    provide superior service delivery and experience, companies need to
                                    think out of the box and invest in solutions to provide Omnichannel
                                    customer service. We at Virtuos Solutions, along with Oracle RightNow,
                                    are committed to helping organizations provide the best-in-class
                                    customer experience. The Customer Experience meet is a testimonial of
                                    just that."
                                </p>
                                <p>
                                    <span className="underline">
                                        Mr. Ted Bray, Vice President, Oracle RightNow
                                    </span>
                                    , stated, "Delivering superior customer experience is one of the only
                                    sources of sustainable differentiation. The best companies in the
                                    world know this, and they focus on it. They know that delivering great
                                    service yields more profitability, new customer acquisitions, an
                                    increase in customer retention, and more sales to existing customers.
                                    I hope those who attended the CXMeet had to gain in-depth insight into
                                    how they can deliver better customer experience."
                                </p>
                                <p>
                                    Ruben Martin, Oracle RighNow's Director of Solutions has covered the
                                    theme of the event — Changing the Channel.
                                </p>
                                <p>
                                    Full video and transcript of the event is available at{" "}
                                    <NavLink to="http://www.cxsummit.com" target="_blank" className="link">
                                        www.cxsummit.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About RightNow Technologies</h3>
                                <p>
                                    RightNow Technologies, Inc. (Nasdaq: RNOW) provides customer
                                    relationship management software and services. The Company offers
                                    solutions that use self-learning knowledge-base to deliver customer
                                    self-service, email management, live interaction, issue tracking, and
                                    customer satisfaction measurement capabilities.
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">07/12/2000</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            136 Enterprise Blvd Bozeman, <br />
                                            MT 59718 United States
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Phone</span>
                                        <span className="txt">1-406-522-4200</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink
                                                to="http://www.rightnow.com"
                                                target="_blank"
                                                className="link"
                                            >
                                                www.rightnow.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">1000-2000</span>
                                    </div>
                                </div>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, and
                                    Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has built a strong RightNow Practice for CRM Service Cloud by
                                    creating marquee clients like MakeMyTrip, Fundtech amongst others.
                                    Virtuos will continue to strengthen its business with RightNow and
                                    Oracle by becoming the latter’s partner.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />

                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Customer_Experience_Meet_2012