import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Securitas_Case_Study = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Virtuos Customer Success Stories | Securitas CLM case study – Virtuos Digital";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/securitas-header-img.jpg`}
                                    alt="Securitas"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/svg/icons/securitas-logo.svg`}
                                        alt="Securitas Logo"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        Virtuos implements Agiloft Contract Lifecycle Management (CLM) at
                                        Securitas
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <section className="breadcrumb-kanaban">
                            <div className="page-wrap breadcrumb-wrapper">
                                <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                    <li>
                                        <NavLink to={`/corporate/`}>About Virtuos</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/customers/`} className="active">
                                            Customer Success
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={`/customers#success-stories`}
                                            className="smooth"
                                        >
                                            CUSTOMER SUCCESS STORIES
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/succeedant/`} className="smooth">
                                            SUCCEEDANT CSM
                                        </NavLink>
                                    </li>

                                </ul>
                                <ul className="more-hidden-tabs">
                                    <li
                                        className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <i className="fal fa-plus" />  More
                                        {showExtraLinks && (
                                            <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                                <li>
                                                    <NavLink to={`/corporate/experience-edge/`}>
                                                        EXPERIENCE EDGE
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`/insights/`}>
                                                        INSIGHTS AND RESOURCES
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                <h3 className="title">About Securitas</h3>
                                <p>
                                    Securitas India is one of the leading security solutions and
                                    background verification services provider, empowering over 45,000
                                    trained professionals across the country. As a trusted partner for
                                    protective services, they deliver service excellence by connecting
                                    people, technology &amp; knowledge and by leveraging on our national,
                                    regional, and local presence.
                                </p>
                                <p>
                                    Securitas goal is to enhance security by accelerating the design,
                                    development, and deployment of specifically trained workforce ensuring
                                    prevention and protection against security threats. Securitas provides
                                    and implements specific solutions that meet or exceed industry
                                    standards.
                                </p>
                                <p>
                                    With their three guiding principles "Integrity, Vigilance,
                                    Helpfulness" Securitas has protected homes, workplaces and society.
                                </p>
                                <h3 className="title">Challenges</h3>
                                <ul>
                                    <li>Lack of visibility of Contract details with the management</li>
                                    <li>Tracking of approvals by the management for a contract</li>
                                    <li>Unable to track the expiry of contract in advance</li>
                                    <li>
                                        Legal team unable to get information on the contracts that are
                                        pending for approvals/clearances
                                    </li>
                                    <li>
                                        Sales team unaware of the status of the request of their contract
                                    </li>
                                    <li>Maintenance of contracts in physical format</li>
                                    <li>Unable to track changes in a contract in form of amendments</li>
                                </ul>
                                <h3 className="title">Need</h3>
                                <ul>
                                    <li>
                                        Securitas wanted an easy access to be provided to their Sales team
                                        through Portal where they can submit the fresh request for a
                                        contract and also view the status of the contracts
                                    </li>
                                    <li>
                                        Legal team wanted to measure the performance of their team and
                                        understand the number of contracts that are coming in, the team
                                        working on them individually and the status of each contract
                                    </li>
                                    <li>
                                        Legal team wanted an easy process of setting up the various contract
                                        parameters in the system for getting approvals from various
                                        stakeholders and have control of assigning them based on certain
                                        parameters
                                    </li>
                                    <li>
                                        Securitas wanted a single platform on which all the interactions
                                        related to the contract is captured across the teams
                                    </li>
                                    <li>
                                        Securitas wanted to do away with the dependency of having to look
                                        for information from physical contracts
                                    </li>
                                    <li>
                                        Securitas wanted to do away with the manual process and have an
                                        automated system which works even if a team member is not resent
                                    </li>
                                    <li>
                                        Securitas wanted to schedule reports of various teams performance
                                        with regards to their approvals, active contracts and various other
                                        parameters
                                    </li>
                                </ul>
                                <h3 className="title">Win</h3>
                                <ul>
                                    <li>
                                        Securitas chose Agiloft Contract Management solution after a lot of
                                        deliberations with the team and also evaluating Agiloft’s
                                        competitors
                                    </li>
                                    <li>
                                        Agiloft was chosen due to its simple interface and wide range of
                                        features for managing diverse contracts
                                    </li>
                                    <li>
                                        Agiloft being a low code solution reducing the implementation time
                                        significantly. Another benefit of Low Code Platform like Agiloft is
                                        to reduce dependency on the customization through development team
                                    </li>
                                </ul>
                                <h3 className="title">Partner</h3>
                                <ul>
                                    <li>
                                        Virtuos is a Gold Partner for Agiloft, has rich experience in
                                        implementing complex solutions and our team has delivered and
                                        surpassed customer’s expectations always
                                    </li>
                                    <li>
                                        Securitas gave a good reference of Virtuos for bagging the deals
                                        with other customers
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="customer-success-section">
                        <h2 className="section-main-heading">
                            Other <span className="highlight">Case Studies</span>
                        </h2>
                        <div className="clients-wrapper">
                            <ul className="row page-wrap offport-down">
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/standard-chartered-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/standard-chartered-casestudy-img.jpg`}
                                                alt="Standard Chartered Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/standard_chartered.svg`}
                                                alt="Standard Chartered Bank Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">CX and Contact Center Automation</p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/tvs-credit-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/tvsc-casestudy-img.jpg`}
                                                alt="TVS Credit Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/tvscs-logo.png`}
                                                alt="TVS Credit"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer Experience Transformation Project using Oracle platform
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/makemytrip-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/makemytrip-casestudy-img.jpg`}
                                                alt="Make My Trip Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/make-my-trip-logo.svg`}
                                                alt="Make My Trip Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            MakeMyTrip.com halved avg. call handling time and increased
                                            revenue with CX Cloud
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/electronics-company-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/electronics-company-casestudy-img.jpg`}
                                                alt="Electronics Company Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/confidential-logo.svg`}
                                                alt="Confidential Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer engagement on phone, email, chat and social channels
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Securitas_Case_Study