import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';
import Footer from '../../components/Footer';

const Virtuos_Completes_A_Wonderful_7_Years = () => {
    useEffect(() => {
        document.title = "Virtuos Completes Wonderful 7 Years – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-7th-anniversary-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 18 January, 2015</span>
                                <h2 className="article-heading">
                                    Virtuos completes a wonderful 7 years. Thank you Virtuoso for your
                                    great contribution
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos has grown year - on - year with impressive double digit
                                    performance acquiring more than 100 leading brands as the customers
                                    across the APAC region.
                                </p>
                                <p>
                                    Today on its seventh anniversary Virtuos celebrates its success with
                                    its employees and senior management.
                                </p>
                                <p>
                                    "I thank three people who have contributed to the success of Virtuos
                                    immensely. Said Venky Vijay Reddi, the CEO of Virtuos. First being
                                    Shaloo Reddi, EVP- HR for creating a transparent and trusted HR System
                                    and policies. Secondly Amarinder Singh for his loyalty, honesty,
                                    integrity and continuous performance assembling the pieces together.
                                    Thirdly Kristina Hermanns for her domain expertise and leadership to
                                    bond the people."
                                </p>
                                <p>
                                    Virtuos announced the road trip for all the employees in April this
                                    year to an exotic location — Chail, Himachal, India. All the expenses
                                    including food, accommodation for two nights &amp; three days and
                                    transportation will be borne by the company.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-7th-anniversary-pic.jpg`}
                                        alt="Virtuos celebrate its 7th Anniversary"
                                    />
                                </figure>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely — Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas, and executing them with Virtuoso teams. Virtuos was
                                    founded in 2008 by cultivating an ambitious plan to become a
                                    multi-billion dollar company and a game changer by 2020. Thanks to the
                                    hard work and commitment of our team we continue to grow each year.
                                    With new innovative business ideas, delivering incredible
                                    transformation services and building powerful engagement platforms for
                                    the future we are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink to="http://www.fb.com/virtuos" target="_blank" className="link">
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Completes_A_Wonderful_7_Years