import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

const Nexorama_strategy = () => {
  useEffect(() => {
    document.title =
      "Virtuos Nexorama Strategy to Diversify Into New Areas – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/nexorama-strategy-img-big.jpg`}
                  alt="VIRTUOS UNVEILS NEXORAMA STRATEGY TO DIVERSIFY INTO NEW AREAS"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram, 1 November 2022</span>
                <h2 className="article-heading">
                  VIRTUOS UNVEILS NEXORAMA STRATEGY TO DIVERSIFY INTO NEW AREAS
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos Nexorama (
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    www.nexorama.com
                  </NavLink>
                  ) is part of our POST-COVID strategy to build Experience
                  Economy business models by embracing Composable Thinking,
                  Composable Architecture and Composable Technologies.
                </p>
                <p>
                  Nexorama will be leveraging both low-code no-code technologies
                  and composable products to build next generation utility tools
                  for modern companies, startups and midsized companies to
                  accelerate their operations quickly and cost effectively.
                </p>
                <p>
                  <strong>Uncodify</strong> — It's building a marketplace
                  leveraging LowCode NoCode Technology Platforms from its
                  partners like Microsoft, Creatio, Agiloft, Asana amongst
                  others. UnCodify will be using assets and components from
                  other resources to build and deliver superior automation for
                  organizations.
                </p>
                <p>
                  <strong>Audacis</strong> — next generation digital marketing
                  agency operates in areas like "branding", "domaining", logos,
                  Search Engine Optimization (SEO), Presentations, Campaigns,
                  Digital &amp; Social Publishing, Social Media Monitoring,
                  Marketing, Content Management, Websites/Blogs, etc. Audacis
                  will compose and curate various services for the companies
                  that want to scale their operations quickly.
                </p>

                <p>
                  <strong>Expedience</strong> — The Talent Advisory and
                  Professional Employer Organization (PEO) Services Platform
                  helps companies scale quickly and expand globally by using an
                  integrated talent system. Companies can use our Expedience
                  Service As A Subscription and pay as they go without bearing
                  the costs of keeping employees on their rolls for longer
                  periods.
                </p>
                <p>
                  Several Nexorama Composable Products/Services or Platforms
                  will be able to complement and augment our existing businesses
                  at Virtuos Vaves, Virtuos Veracis, Virtuos Vivacis and Virtuos
                  Vedam.
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Microsoft Dynamics, Creatio Low Code No Code
                  Platform &amp; CRM Applications; Agiloft Contract Lifecycle
                  Management (CLM) amongst other Composable Products &amp;
                  Services. Virtuos Consulting services include Four Fabulous
                  business units namely — Virtuos Vivacis, Virtuos Veracis,
                  Virtuos Vaves, and Virtuos Vedam.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/xonomy/`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates, incubates and invests in the next-generation
                  businesses harnessing Artificial Intelligence (AI), Cloud,
                  ECommerce, Blockchain and Digital technologies. At Virtuos
                  Xonomy, we have invested in several companies that include
                  Giftcart — Gifting Experiences Commerce Company; Indic — the
                  Portfolio of Experience Commerce Brands; Sweven — Salesforce
                  CRM Mastermind services; and Tekcorp — the Technology
                  Infrastructure.
                </p>
                <h3 className="title">Virtuos Experience Cloud</h3>
                <p>
                  Virtuos Experience Cloud Business is building new products
                  integrating our Customer Experience (CX), Employee Experience
                  (EX), and Everything Experience (XX).
                </p>
                <h3 className="title">Virtuos Nexorama</h3>
                <p>
                  Virtuos Nexorama is our brand new direction to build
                  Composable Products and Services in the Experience Economy. We
                  are building an integrated and agile learning platform —
                  Wundero; Carving out a new business model building a
                  marketplace for LowCode NoCode Technologies by composing a new
                  business UnCodify.com. Virtuos will be launching Expedience —
                  The Talent advisory and sourcing platform and Audacis, a new
                  digital marketing services agency.
                </p>
                <p>
                  For more information visit{" "}
                  <NavLink
                    to="http://www.virtuos.com"
                    target="_blank"
                    className="link"
                  >
                    www.virtuos.com
                  </NavLink>
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Nexorama_strategy;
