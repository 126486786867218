import { useState, useEffect, useRef, useMemo, useReducer } from "react";
// import baseUrl from "../../utils/baseUrl";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
// import exportIcon from "/img/csv_export.png";
// import pdfIcon from "/img/PDF_Icon.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import Image from "next/image";
import ExportOptionsModal from "./PDFModal";
// import ExportOptionsCsvModal from "./CSVModal";
import ReCaptcha from "react-google-recaptcha";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import "./index.css";
import { Button } from "@mui/material";

// import { usePagination, useSortBy, useTable } from "react-table";

// const columns = [
//   {
//     name: "Name",
//     selector: (row) => row.full_name,
//     sortable: true,
//     filter: "text",
//   },
//   {
//     name: "Email",
//     selector: (row) => row.email,
//     sortable: true,
//     filter: "text",
//   },
//   {
//     name: "Phone",
//     selector: (row) => row.mobile,
//     sortable: true,
//     filter: "text",
//   },
//   {
//     name: "Subject",
//     selector: (row) => row.subject,
//     sortable: true,
//     filter: "text",
//   },
//   {
//     name: "Message",
//     selector: (row) => row.message,
//     sortable: true,
//     filter: "text",
//   },
//   {
//     name: "Date",
//     selector: (row) => row.created,
//     sortable: true,
//     filter: "text",
//     cell: (row) => row?.created.slice(0, 10),
//   },
//   {
//     name: "Resume",
//     selector: (row) => row.attached_file,
//     sortable: true,
//     filter: "text",
//     cell: (row) => (
//       <div>
//         {row.attached_file && (
//           <div>
//             {/* <button
//               className="btn btn-primary"
//               onClick={(e) => {
//                 e.preventDefault();
//                 handleView(row.attached_file);
//               }}
//             >
//               View
//             </button> */}

//             <button
//               className="btn"
//               onClick={() => handleDownload(row.attached_file)}
//             >
//               Download
//             </button>
//           </div>
//         )}
//       </div>
//     ),
//   },
//   {
//     name: "Type",
//     selector: (row) => row.type,
//     sortable: true,
//     filter: "text",
//   },
//   {
//     name: "Page",
//     selector: (row) => row.webURL,
//     sortable: true,
//     filter: "text",
//   },
// ];

// type Person = {
//   firstName: string
//   lastName: string
//   age: number
//   visits: number
//   status: string
//   progress: number
// }

const defaultSampleData = [
  {
    firstName: "tanner",
    lastName: "linsley",
    age: 24,
    visits: 100,
    status: "In Relationship",
    progress: 50,
  },
  {
    firstName: "tandy",
    lastName: "miller",
    age: 40,
    visits: 40,
    status: "Single",
    progress: 80,
  },
  {
    firstName: "joe",
    lastName: "dirte",
    age: 45,
    visits: 20,
    status: "Complicated",
    progress: 10,
  },
];

const defaultColumns = [
  // {
  //   header: "Name",
  //   footer: (props) => props.column.id,
  //   columns: [
  //     {
  //       accessorKey: "firstName",
  //       cell: (info) => info.getValue(),
  //       footer: (props) => props.column.id,
  //     },
  //     {
  //       accessorFn: (row) => row.lastName,
  //       id: "lastName",
  //       cell: (info) => info.getValue(),
  //       header: () => <span>Last Name</span>,
  //       footer: (props) => props.column.id,
  //     },
  //   ],
  // },
  // {
  //   header: "Info",
  //   footer: (props) => props.column.id,
  //   columns: [
  //     {
  //       accessorKey: "age",
  //       header: () => "Age",
  //       footer: (props) => props.column.id,
  //     },
  //     {
  //       header: "More Info",
  //       columns: [
  //         {
  //           accessorKey: "visits",
  //           header: () => <span>Visits</span>,
  //           footer: (props) => props.column.id,
  //         },
  //         {
  //           accessorKey: "status",
  //           header: "Status",
  //           footer: (props) => props.column.id,
  //         },
  //         {
  //           accessorKey: "progress",
  //           header: "Profile Progress",
  //           footer: (props) => props.column.id,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    id: "name",
    header: "Name",
    accessorFn: (row) => row.full_name,
    footer: (props) => props.column.id,
    // columns: [
    //   {
    //     accessorKey: "firstName",
    //     cell: (info) => info.getValue(),
    //     footer: (props) => props.column.id,
    //   },
    //   {
    //     accessorFn: (row) => row.lastName,
    //     id: "lastName",
    //     cell: (info) => info.getValue(),
    //     header: () => <span>Last Name</span>,
    //     footer: (props) => props.column.id,
    //   },
    // ],
  },
  {
    id: "email",
    header: "Email",
    accessorFn: (row) => row.email,
    footer: (props) => props.column.id,
    // columns: [
    //   {
    //     accessorKey: "age",
    //     header: () => "Age",
    //     footer: (props) => props.column.id,
    //   },
    //   {
    //     header: "More Info",
    //     columns: [
    //       {
    //         accessorKey: "visits",
    //         header: () => <span>Visits</span>,
    //         footer: (props) => props.column.id,
    //       },
    //       {
    //         accessorKey: "status",
    //         header: "Status",
    //         footer: (props) => props.column.id,
    //       },
    //       {
    //         accessorKey: "progress",
    //         header: "Profile Progress",
    //         footer: (props) => props.column.id,
    //       },
    //     ],
    //   },
    // ],
  },
  {
    id: "phone",
    header: "Phone",
    cell: (info) => info.getValue(),
    accessorKey: "mobile",
    footer: (props) => props.column.id,
  },
  {
    id: "subject",
    header: "Subject",
    cell: (info) => info.getValue(),
    accessorFn: (row) => row.subject,
    footer: (props) => props.column.id,
  },
  {
    id: "message",
    header: "Message",
    cell: (info) => info.getValue(),
    accessorFn: (row) => row.message,
    footer: (props) => props.column.id,
  },
  {
    id: "date",
    header: "Date",
    cell: (info) => info.getValue(),
    accessorFn: (row) => row.created.slice(0, 10),
    footer: (props) => props.column.id,
  },
  {
    id: "resume",
    header: "Resume",
    cell: (info) => (
      <a href={`${process.env.REACT_APP_API_URL}/uploads/${info.getValue()}`} target="_blank" rel="noopener noreferrer">
        View Resume
      </a>
    ),
    accessorFn: (row) => row.attached_file,
    footer: (props) => props.column.id,
  },
  {
    id: "type",
    header: "Type",
    cell: (info) => info.getValue(),
    accessorFn: (row) => row.type,
    footer: (props) => props.column.id,
  },
  {
    id: "page",
    header: "Page",
    cell: (info) => info.getValue(),
    accessorFn: (row) => row.webURL,
    footer: (props) => props.column.id,
  },
];

const handleView = (filename) => {
  window.open(
    `${process.env.REACT_APP_API_URL}/db/files/${filename}`,
    "_blank"
  );
};

const handleDownload = (filename) => {
  const link = document.createElement("a");
  link.href = `${process.env.REACT_APP_API_URL}/db/files/${filename}`;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function LeadTable() {
  // Define the columns based on your data structure
  const tableColumns = useMemo(
    () => [
      {
        Header: "Domain",
        accessor: "word",
      },
      {
        Header: "Buy Now Price",
        accessor: "buyNowPrice",
      },
      {
        Header: "Floor Price",
        accessor: "floorPrice",
      },
      {
        Header: "Minimum Offer",
        accessor: "minimumOffer",
      },
      {
        Header: "Action",
        // Cell: ({ row }) => {
        //   console.log(row);
        //   return row.original.isVerified ? (
        //     <span style={{ display: "flex", alignItems: "center" }}>
        //       <FaCheckCircle
        //         size={24}
        //         color="green"
        //         style={{ marginRight: "4px" }}
        //       />
        //       Verified Domain
        //     </span>
        //   ) : (
        //     <button
        //       className="beta-marker right-4 top-4 rounded-2xl bg-red-600 px-3 py-1 font-semibold text-white"
        //       onClick={() => handleCreateTxtRecord(row)}
        //     >
        //       Verify
        //     </button>
        //   );
        // },
      },
    ],
    []
  );

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(true);
  const handleRecaptchaChange = (value) => {
    console.log("Recaptcha");
    setRecaptchaValue(value);
    setCaptchaError(false);
  };

  const [data, setData] = useState([]); // Initialize as an empty array
  const [searchText, setSearchText] = useState("");
  const [dataCSV, setDataCSV] = useState(null); // Initialize as an empty array

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isExportModalCSVOpen, setIsExportModalCSVOpen] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("all");
  const [exportType, setExportType] = useState(null);

  const [validate, setValidate] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const csvLinkRef = useRef();

  // const data = React.useMemo(() => domains, [domains]);

  const [defaultData, setDefaultData] = useState(() => [...defaultSampleData]);
  const [columns] = useState(() => [...defaultColumns]);
  const [columnVisibility, setColumnVisibility] = useState({
    message: false,
  });
  const [columnFilters, setColumnFilters] = useState([]);

  const rerender = useReducer(() => ({}), {})[1];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnFilters,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
    // initialState: {
    //   pagination: {
    //     pageIndex: 0,
    //     pageSize: 20,
    //   },
    // },/
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/db`);
        const result = await response.json();
        // console.log("Result");
        console.log(result);
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  // console.log(data);
  const filteredData = data?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );
  // console.log(filteredData);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleLogin = () => {
    // Stronger username and password validation
    const strongUsernameRegex = /^[a-zA-Z0-9]{4,}$/; // Username must be at least 4 characters long and can only contain letters and numbers
    const strongPasswordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/; // Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number

    if (captchaError) {
      return;
    }

    if (
      !strongUsernameRegex.test(username) ||
      !strongPasswordRegex.test(password)
    ) {
      alert(
        "Invalid username or password. Please ensure your username is at least 4 characters long and contains only letters and numbers, and your password is at least 8 characters long and contains at least one uppercase letter, one lowercase letter, and one number."
      );
      return;
    }

    // Check if the entered username and password are correct
    // console.log(`${process.env.REACT_APP_CARR_PORT_USR_NM}`)
    // console.log(`${process.env.REACT_APP_CARR_PORT_USR_PASS}`)
    if (
      username === `${process.env.REACT_APP_CARR_PORT_USR_NM}` &&
      password === `${process.env.REACT_APP_CARR_PORT_USR_PASS}`
    ) {
      setValidate(false); // Set validate to false if the credentials are correct
    } else {
      alert("Invalid username or password");
    }
  };

  // const csvData = filteredData?.map((item) => ({
  //   Name: item.username,
  //   Email: item.email,
  //   Phone: item.phone,
  //   Subject: item.subject,
  //   Message: item.message,
  //   Date: item.created_at,
  // }));

  const handleExport = (option) => {
    // console.log(option);
    setSelectedExportOption(option);
    // closeExportModal();
    // console.log(option);
    if (exportType === "PDF") {
      exportToPDF(option);
    }
    if (exportType === "CSV") {
      exportToCSV(option);
    }
  };

  const exportToPDF = (option) => {
    // console.log(option);
    setIsExportModalOpen(true);
    let filteredDataToExport = [];
    if (option === "lastMonth") {
      const lastMonth = new Date();
      // console.log(lastMonth.getMonth());
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      // console.log(lastMonth);
      filteredDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastMonth
      );
    } else if (option === "last3Months") {
      const lastThreeMonths = new Date();
      lastThreeMonths.setMonth(lastThreeMonths.getMonth() - 3);
      filteredDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastThreeMonths
      );
    } else if (option === "last6Months") {
      const lastSixMonths = new Date();
      lastSixMonths.setMonth(lastSixMonths.getMonth() - 6);
      filteredDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastSixMonths
      );
    } else if (option === "all") {
      filteredDataToExport = filteredData;
    }

    // console.log("FilteredDatatoExport ", filteredDataToExport);
    const bodyData = filteredDataToExport.map((item) =>
      columns.map((column) => {
        // Check if the selector is a function and call it with the row data
        const value =
          typeof column.selector === "function"
            ? column.selector(item)
            : item[column.selector];

        // console.log(`Mapping for ${column.name}: `, value); // Debugging
        return value !== undefined && value !== null ? value : "N/A";
      })
    );

    const pdf = new jsPDF();
    pdf.text("Leads Data", 10, 10);
    pdf.autoTable({
      head: [columns.map((column) => column.name)],
      body: bodyData,
    });
    pdf.save("leads.pdf");
  };
  const exportToCSV = (option) => {
    // console.log(option);
    setIsExportModalCSVOpen(true);
    let filteredCSVDataToExport = [];
    if (option === "lastMonth") {
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      filteredCSVDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastMonth
      );
    } else if (option === "last3Months") {
      const lastThreeMonths = new Date();
      lastThreeMonths.setMonth(lastThreeMonths.getMonth() - 3);
      filteredCSVDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastThreeMonths
      );
    } else if (option === "last6Months") {
      const lastSixMonths = new Date();
      lastSixMonths.setMonth(lastSixMonths.getMonth() - 6);
      filteredCSVDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastSixMonths
      );
    } else if (option === "all") {
      filteredCSVDataToExport = filteredData;
    }
    // console.log(filteredCSVDataToExport);
    const csvData = filteredCSVDataToExport?.map((item) => ({
      Name: item.fullname,
      Email: item.email,
      Phone: item.mobile,
      Subject: item.subject,
      Message: item.message,
      Date: item.created,
    }));
    // console.log(csvData);
    setDataCSV(csvData);
  };
  // console.log(dataCSV);
  const openPDFExportModal = () => {
    setExportType("PDF");
    setIsExportModalOpen(true);
  };

  const openCSVExportModal = () => {
    setExportType("CSV");
    setIsExportModalOpen(true);
  };
  //   const openExportModal = (type) => {
  //     setExportType(type);
  //     setIsExportModalOpen(true);
  //   };

  const closeExportModal = () => {
    setIsExportModalOpen(false);
    setIsExportModalCSVOpen(false);
  };

  useEffect(() => {
    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  }, [dataCSV]);

  if (!defaultData || defaultData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {validate ? (
        <div
          className=""
          style={{ textAlign: "center", maxWidth: "300px", margin: "auto" }}
        >
          <h1>Login</h1>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{ margin: "10px", padding: "5px" }}
          />
          <br />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ margin: "10px", padding: "5px" }}
          />
          <br />
          <div className="input-wrapper">
            <ReCaptcha
              sitekey={process.env.REACT_APP_RECAPTCHA}
              onChange={handleRecaptchaChange}
            />
            {captchaError && (
              <p
                className="required-message"
                style={{ fontSize: "12px", color: "red" }}
              >
                * Please select CAPTCHA
              </p>
            )}
          </div>
          <br />
          <button
            onClick={handleLogin}
            style={{
              margin: "10px",
              padding: "5px",
              backgroundColor: "blue",
              color: "white",
              border: "none",
            }}
          >
            Login
          </button>
        </div>
      ) : (
        <div className="leads_table">
          <div className="search-bar-leads">
            <h1>Leads</h1>
            {data.length > 0 && (
              <div className="input_field">
                <div className="export_button">
                  <p className="export_text">Export : &nbsp;</p>
                  <img
                    onClick={openCSVExportModal}
                    src="/img/csv_export.png"
                    height={30}
                    width={30}
                    alt=""
                  />
                  <p className="export_text">&nbsp; &nbsp;</p>

                  {dataCSV && (
                    <CSVLink
                      data={dataCSV}
                      filename={"leads.csv"}
                      ref={csvLinkRef}
                    ></CSVLink>
                  )}

                  <img
                    onClick={openPDFExportModal}
                    src="/img/PDF_Icon.png"
                    height={30}
                    width={30}
                    alt=""
                  />
                </div>
                {/* <input
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleSearch}
                /> */}
              </div>
            )}
          </div>

          {/* <DataTable
            columns={columns}
            data={filteredData}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            dense
            // className="data-table-container"
          /> */}
          {isExportModalOpen && (
            <ExportOptionsModal
              onClose={closeExportModal}
              onExport={handleExport}
              exportType={exportType}
            />
          )}

          {/* <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
            <table
              className="w-full"
              variant="simple"
              color="gray-500"
              mb="24px"
            >
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, columnIndex) => (
                      <th
                        className="border-b border-gray-200 pb-[10px] pr-8 text-start dark:!border-navy-700"
                        {...column.getHeaderProps(column.getSortByToggleProps())} // Updated line
                        key={columnIndex}
                      >
                        <div>
                          {column.render("Header")}
                          <span className="text-xs">
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " ▼"
                                : " ▲"
                              : " ▲▼"}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          className="pb-[16px] pt-[14px] sm:text-[14px]"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {rows.length === 0 && (
              <tr className="flex h-32  w-full items-center justify-center font-semibold text-red-600">
                No data found
              </tr>
            )}
          </div>
          <div className="mt-8 flex items-center justify-between pb-4">
            <div className="flex items-center justify-between gap-1 md:mt-8 md:pb-4">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="flex cursor-pointer items-center rounded-lg bg-[#01afef] px-4 py-2 text-sm font-medium text-white"
              >
                Previous
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="flex cursor-pointer items-center rounded-lg bg-[#01afef] px-4 py-2 text-sm font-medium text-white"
              >
                Next 
              </button>
            </div>
            <div>
              Page <strong>{pageIndex + 1}</strong> of{" "}
              <strong>{pageOptions.length}</strong>
            </div>
          </div> */}

          {/* {isExportModalCSVOpen && (
            <ExportOptionsCsvModal
              onClose={closeExportModal}
              onExport={handleExport}
            />
          )} */}
          <div>
            <div className="table-container">
              <div className="column-toggle">
                <div className="toggle-columns">
                  <label>
                    <input
                      {...{
                        type: "checkbox",
                        checked: table.getIsAllColumnsVisible(),
                        onChange: table.getToggleAllColumnsVisibilityHandler(),
                      }}
                    />
                    {"     "}
                    Toggle All
                  </label>
                </div>
                {table.getAllLeafColumns().map((column) => {
                  return (
                    <div className="toggle-columns" key={column.id}>
                      <label>
                        <input
                          {...{
                            type: "checkbox",
                            checked: column.getIsVisible(),
                            onChange: column.getToggleVisibilityHandler(),
                          }}
                        />{" "}
                        {column.id}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  margin: "0 1rem",
                }}
              >
                <div className="mt-4 flex items-center">
                  <strong>Filter By Type: &nbsp;</strong>
                  <select
                    value={columnFilters[0]?.value || ""}
                    name="type-filter"
                    onChange={(e) => {
                      setColumnFilters(
                        e.target.value
                          ? [
                              {
                                id: "type",
                                value: e.target.value,
                              },
                            ]
                          : []
                      );
                    }}
                    className="rounded-md border px-3 py-2 transition duration-300 focus:border-blue-500 focus:outline-none"
                  >
                    <option key={"1"} value="">
                      All
                    </option>
                    {/* {pageTypes.map((pageType, index) => (
                      <option key={index} value={pageType.value}>
                        {pageType.text}
                      </option>
                    ))} */}
                    <option key="2" value="Job_new">
                      Jobs
                    </option>
                    <option key={"3"} value={"C_Write_to_us"}>
                      Contact Us
                    </option>
                    <option key={"4"} value={"Analytics_1"}>
                      Analytics_1
                    </option>
                    <option key={"6"} value={"BPM_1"}>
                      BPM
                    </option>
                    <option key={"7"} value={"CX_1"}>
                      CX_1
                    </option>
                    <option key={"8"} value={"C_Innov_Process"}>
                      C_Innov_Process
                    </option>
                    <option key={"9"} value={"C_vendrship"}>
                      C_vendrship
                    </option>
                    <option key={"10"} value={"D_Agiloft"}>
                      D_Agiloft
                    </option>
                    <option key={"11"} value={"E_Agnitor"}>
                      E_Agnitor
                    </option>
                    <option key={"12"} value={"E_Alliances"}>
                      E_Alliances
                    </option>
                    <option key={"13"} value={"E_App_Group"}>
                      E_App_Group
                    </option>
                    <option key={"14"} value={"E_Aury"}>
                      E_Aury
                    </option>
                    <option key={"15"} value={"E_Campus_yes"}>
                      E_Campus_yes
                    </option>
                    <option key={"16"} value={"E_Creatio"}>
                      E_Creatio
                    </option>
                    <option key={"17"} value={"E_CRM_CX"}>
                      E_CRM_CX
                    </option>
                    <option key={"18"} value={"E_CXNow"}>
                      E_CXNow
                    </option>
                    <option key={"19"} value={"E_CX_Continuum"}>
                      E_CX_Continuum
                    </option>
                    <option key={"20"} value={"E_Digital"}>
                      E_Digital
                    </option>
                    <option key={"21"} value={"E_Engg_Service"}>
                      E_Engg_Service
                    </option>
                    <option key={"13"} value={"E_Exonomy"}>
                      E_Exonomy
                    </option>
                  </select>
                </div>
                <div className="pagination">
                  <button
                    onClick={() => table.firstPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<<"}
                  </button>
                  <button
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<"}
                  </button>
                  <button
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {">"}
                  </button>
                  <button
                    onClick={() => table.lastPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {">>"}
                  </button>
                  <select
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <table>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table?.getRowModel()?.rows?.map((row) => {
                    // console.log(row);
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          // console.log(cell.column.columnDef.cell)
                          return (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.footer,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
              <div className="pagination">
                <button
                  onClick={() => table.firstPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<<"}
                </button>
                <button
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<"}
                </button>
                <button
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">"}
                </button>
                <button
                  onClick={() => table.lastPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">>"}
                </button>
                <select
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              {/* <button onClick={() => rerender()} className="border p-2">
                Rerender
              </button>
              <div className="h-4" />
              <pre>
                {JSON.stringify(table.getState().columnVisibility, null, 2)}
              </pre> */}
            </div>
            <style jsx>{`
              .table-container {
                max-width: 100%;
                overflow-x: auto;
              }

              .column-toggle {
                margin: 0 1rem;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
              }

              .toggle-all {
                margin-bottom: 0.5rem;
              }

              .toggle-columns {
                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;
              }

              .toggle-columns label {
                display: flex;
                align-items: center;
                margin-right: 1rem;
              }

              table {
                width: 100%;
                border-collapse: collapse;
                font-size: 14px;
              }

              th,
              td {
                padding: 0.5rem;
                text-align: left;
                border-bottom: 1px solid #e2e8f0;
              }

              th {
                background-color: #f7fafc;
                font-weight: bold;
              }

              tr:nth-child(even) {
                background-color: #f7fafc;
              }

              .pagination {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 1rem;
                flex-wrap: wrap;
                max-width: fit-content;
                margin: 10px;
                margin-left: auto;
              }

              .pagination-buttons {
                display: flex;
                gap: 0.25rem;
              }

              .pagination-info {
                margin: 0 1rem;
              }

              button {
                padding: 0.5rem;
                margin: 0 0.25rem;
                background-color: #e2e8f0;
                border: none;
                border-radius: 4px;
                cursor: pointer;
              }

              button:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }

              select {
                padding: 0.5rem;
                margin-left: 0.5rem;
              }

              @media (max-width: 640px) {
                table {
                  font-size: 12px;
                }

                th,
                td {
                  padding: 0.25rem;
                }

                .pagination {
                  font-size: 12px;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 0.5rem;
                }

                .pagination-buttons {
                  order: 1;
                }

                .pagination-info {
                  order: 2;
                  margin: 0;
                }

                button {
                  padding: 0.25rem;
                }

                select {
                  padding: 0.25rem;
                }
              }
            `}</style>
          </div>
        </div>
      )}
    </div>
  );
}
