import React, { useEffect, useState } from 'react'

import { NavLink } from "react-router-dom";
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Nav from '../../../components/Nav';
import ApplyForJob from './Forms/ApplyForJob';
import CommonApplyForJob from './CommonApplyForJob';
import { NavigateNext } from '@mui/icons-material';

const LeadConsultant = () => {
    useEffect(() => {
        document.title = "Career Opportunities | Microsoft .Net Developer – Virtuos Jobs";
    }, []);
    return (
        <div className="careers-job-detail-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    {/* Careers Job Detail */}
                    <section className="job-detail-wrapper">
                        <div className="row page-wrap">
                        <div class="col-9 job-details">
						<div class="job-header">
							<div class="profile-illustration">
								<figure>
									<img src={`/img/svg/crm-lead-consultant-career-img.svg`} alt="CRM Lead Consultant Job"/>
								</figure>
							</div>
							<div class="profile-detail">
								<h2 class="section-heading">CRM Lead Consultant</h2>
								<ul class="job-attributes">
									<li class="">
										<strong>Location:</strong>
										<span>Gurgaon</span>
									</li>
									<li class="">
										<strong>Experience:</strong>
										<span>7-12 Years</span>
									</li>
									<li class="">
										<strong>No. of Positions:</strong>
										<span>2</span>
									</li>
									<li class="">
										<strong>Qualification:</strong>
										<span>BE/BTech/ME/MTech/MS/MCA</span>
									</li>
								</ul>
							</div>
						</div>
						<p>We are looking for mid to senior specialists who can work on key projects to deliver top tier solutions from design and configuration to integration and testing.</p>
						<h3 class="title">Job Description</h3>
						<ul class="list">
							<li>Take the lead on projects, solving some of the most exciting problems for our clients</li>
							<li>Build lasting relationships with executive clients and cross-functional teams</li>
							<li>Lead on design, the implementation of technology, and creative business solutions for iconic brands</li>
							<li>You’ll be responsible for business requirements, functional design, process design (including scenario design, flow mapping), prototyping, testing, training, defining support procedures</li>
							<li>Collaborate with amazing digital experts across disciplines to innovate and create great solutions</li>
						</ul>

						<h3 class="title">Skill Set</h3>
						<ul class="list">
							<li>Experience in functional or technical roles on at least three full-lifecycle Salesforce implementations, with strong expertise in at least two of the following areas/modules:
								<ul class="list">
									<li>Sales Cloud</li>
									<li>Service Cloud</li>
									<li>Marketing Cloud</li>
									<li>Force.com</li>
								</ul>
							</li>
							<li>Strong consulting and/or CRM implementation experience</li>
							<li>Experience designing and implementing technology-enabled business solutions in client-facing and team leadership roles (e.g. technical lead, functional lead, workstream lead, project manager)</li>
							<li>Experience defining systems strategy, developing systems requirements, designing, and prototyping, testing, training, defining support procedures, and implementing practical business solutions under multiple deadlines</li>
							<li><strong>An advanced Salesforce certification - Sales / Service cloud, Advanced Developer</strong></li>
							<li>Experience with system configuration using workflows, approval processes, custom objects, validation rules, formulas, process builder, flows, and dataloader</li>
							<li>Understanding of Salesforce architecture and knowledge of Salesforce ecosystem such as CPQ and Order Management solutions</li>
							<li>Relational databases and data modelling</li>
							<li>Experience delivering projects using agile Scrum based methodology</li>
							<li>Experience contributing to RFP responses and other sales activities
								<ul>
									<li>Development experience in Apex, VisualForce, Force.com or Java/J2EE</li>
									<li>Data migration between SFDC Sandboxes and Production Orgs using Change Sets, Eclipse or Ant</li>
									<li>Good knowledge of Web Development (Ajax, JavaScript, SOAP, HTTP, JS Remoting, Angular JS, CSS Bootstrap)</li>
									<li>Experience with version-controlled environments/interfaces (e.g. Github, Sourcetree)</li>
									<li>Experience integrating Force.com development with modern deployment and release processes (e.g. Agile; Continuous Integration, Jenkins)</li>
									<li>Experience implementing CPQ technologies (Big Machines, Apttus, Cameleon)</li>
								</ul>
							</li>
						</ul>
						
						<h3 class="title">Advantages of Working@Virtuos</h3>
						<ul class="list">
							<li>Virtuos has deep expertise in CRM and Customer Experience Consulting - It’s likely to become largest Applications Business valued at US$45B per year. Candidates will gain significant exposure from our best practices, top-notch alliances, and direct hands-on interaction with leading brands</li>
							<li>The candidates will have exposure to the cutting edge solutions in broad based verticals</li>
							<li>The role also accords a deep understanding of Customer's business processes, and technology adaptation providing an ample room for candidate's overall growth</li>
							<li>Global exposure and working on best business practices with MNC and Enterprise/Corporate Customers in diverse verticals</li>
						</ul>
						
					</div>
                            <div className="col-3 job-application-wrapper sticky-content">
                             <CommonApplyForJob/>
                            </div>
                        </div>
                    </section>
                   
                    {/*?php include '../../footer.php';?*/}
                    <Footer/>
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default LeadConsultant
