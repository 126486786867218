import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Signs_Up_With_Maximizer = () => {
    useEffect(() => {
        document.title = "Virtuos formally signs Partnership with Maximizer as India’s lead Business Partner – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-signs-up-maximizer-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">New Delhi, 11 Feb 2008</span>
                                <h2 className="article-heading">
                                    Virtuos formally signs Partnership with Maximizer as India’s lead
                                    Business Partner
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos becomes Maximizer Business Partner and launches Maximizer CRM
                                    10 in four variants. Maximizer UK (Max.co.uk) and Virtuos Solutions
                                    Private Ltd. (virtuos.com) have announced the partnership to launch
                                    the most innovative CRM Solutions that can work on-premise, on-private
                                    cloud, and on-public cloud.
                                </p>
                                <p>
                                    CRM 10 Series in four variants marks the birth of new-age CRM that is
                                    suited for cloud and mobility supporting various browsers and mobile
                                    screens. For banks, financial services, and Telecom Organizations,
                                    Virtuos has also created Private Cloud CRM by providing services such
                                    as Installation, Data Migration, and Third-party software integration.
                                </p>
                                <p>
                                    “Maximizer has proven expertise in the CRM Industry by delivering its
                                    leading-edge software to more than 100,000 companies worldwide, and
                                    Virtuos can harness the use cases of CRM worldwide to automate
                                    processes for many companies”. Said Venky Vijay Reddi, CEO of Virtuos
                                    Solutions Private Ltd. Maximizer CRM Version 10 comes with new
                                    functionalities that can attract both Small &amp; Medium-sized
                                    Businesses and large &amp; mature organizations”.
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/maximizer-virtuos-ge-healthcare-logo.jpg`}
                                        alt="Maximizer, Virtuos, GE Healthcare"
                                    />
                                </figure>
                                <br />
                                <p>
                                    One of the most significant implementations of Maximizer CRM by
                                    Virtuos is at GE Healthcare, spanning more than a dozen sales offices
                                    across India. Virtuos has implemented Sales and Service Modules by
                                    tightly integrating with the customers’ real-time Inventory management
                                    system. The application has helped more than 100 Sales and Service
                                    users to forecast sales, plan the manufacturing and imports of
                                    medical/healthcare products, and execute orders.
                                </p>
                                <p>
                                    Maximizer has begun using NFR licenses internally for more than ten
                                    users covering Sales, Service, and Marketing. Because of its simple to
                                    use, highly scalable architecture, and low implementation effort,
                                    significant organizations have started implementing Maximizer CRM.
                                    With the future roadmap to include several functionalities and
                                    advanced analytics, Virtuos is confident that Maximizer is one of the
                                    leading brands in the Indian market.
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd., a company registered with the Indian
                                    Companies Act, is a startup in the space of the CRM Cloud Computing
                                    business. As a company, Virtuos is born in the cloud by forging
                                    alliances with leading companies such as Maximizer and KANA.
                                </p>
                                <p>
                                    The company is headquartered in New Delhi with its corporate office in
                                    the prestigious Okhla Industrial Area Phase I at a prominent building.
                                    Virtuos has delivered a world-class Maximizer Integrated CRM Platform
                                    for Fortune 50 Company — GE Healthcare which serves thousands of B2B
                                    customers across India. A state of the art application fully
                                    implemented by Virtuos Consulting team has delivered significant ROI
                                    in a short span.
                                </p>
                                <p>
                                    For more information on Virtuos Consulting Services and Case studies
                                    on CRM Success stories visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Signs_Up_With_Maximizer