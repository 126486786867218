import React, { useState } from "react";

const ExportOptionsModal = ({ onClose, onExport, exportType }) => {
  // console.log(exportType);
  const [selectedOption, setSelectedOption] = useState("lastMonth");
  // console.log(onExport);
  const handleExport = () => {
    onExport(selectedOption);
    onClose();
  };
  // console.log(selectedOption);

  return (
    <div className="custom-modal">
      <div className="modal-content">
        <div className="modal-body modal_radio">
          <h2>Select Export Option</h2>
          <label>
            <input
              type="radio"
              value="lastMonth"
              checked={selectedOption === "lastMonth"}
              onChange={() => setSelectedOption("lastMonth")}
            />
            Last Month
          </label>
          <label>
            <input
              type="radio"
              value="last3Months"
              checked={selectedOption === "last3Months"}
              onChange={() => setSelectedOption("last3Months")}
            />
            Last 3 Months
          </label>
          <label>
            <input
              type="radio"
              value="last6Months"
              checked={selectedOption === "last6Months"}
              onChange={() => setSelectedOption("last6Months")}
            />
            Last 6 Months
          </label>
          <label>
            <input
              type="radio"
              value="all"
              checked={selectedOption === "all"}
              onChange={() => setSelectedOption("all")}
            />
            All
          </label>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={onClose}>
            Close
          </button>
          {exportType === "PDF" ? (
            <button
              type="button"
              className="btn export_btn"
              onClick={handleExport}
            >
              Export
            </button>
          ) : (
            <button
              type="button"
              className="btn export_btn_csv"
              onClick={handleExport}
            >
              Export
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExportOptionsModal;
