import React from "react";

import ComingSoon from "../ComingSoon/ComingSoon";

const Hyper_Automation = () => {
  return (
    <div className="hyper_automation">
      <ComingSoon />
    </div>
  );
};

export default Hyper_Automation;
