import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

const Experienceo = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (index) => {
    if (activeItem === index) {
      setActiveItem(null);
    } else {
      setActiveItem(index);
    }
  };

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };
  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };
  useEffect(() => {
    document.title = "The rise of ExperienCEO – Virtuos Xonomy";
  }, []);
  return (
    <div className="experienceo-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="header-container">
            <div className="row page-wrap">
              <div className="col-6">
                <h2 style={{ lineHeight: ".5", fontWeight: "bold" }}>
                  THE RISE OF EXPERIEN
                  <span className="pink" style={{ fontWeight: "bold" }}>
                    CEO
                  </span>
                </h2>
                <p>
                  When CX and EX are in symphony, the traditional CEOs need to
                  look beyond “Chief Executive” to “Chief Experience”.
                </p>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/xonomy/`}>Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/experienceo/`} className="active">
                    EXPERIENCEO
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud/`}>EXPERIENCE CLOUD</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-crowd/`}>EXPERIENCE CROWD</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-crowd#dati`} className="smooth">
                    DATI DATA CROWD
                  </NavLink>
                </li>
              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${
                    showExtraLinks ? "active" : ""
                  } inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" /> More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/io/`}>INNOVATION ON</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/business4-0/`}>
                          BUSINESS 4.0 STRATEGY
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/aury/`}>AURY PLATFORM</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/ventures/`}>VENTURES</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/`}>VIRTUOS VUCA</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/agnitor/`}>Agnitor</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/budh/`}>BUDH XONOMY</NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="ceo-job-container">
            <h2 className="section-main-heading">
              THERE’S A PROBLEM WITH{" "}
              <span className="highlight">THE CEO’S JOB TITLE</span>
            </h2>
            <span className="section-desc">
              The title Chief Executive Officer (CEO) has been in use for a few
              decades to a century, and in today’s context, it is something of a
              misnomer. If a CEO is about executing, controlling, overseeing the
              “business” through his executive force (C-Suite like CFO, COO,
              CMO, CCO, or CHRO), the role in today’s xonomy is nearly
              redundant.
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <h3>ExperienCEO born out of Xonomy</h3>
              </div>
              <div className="col-3 left-block">
                <figure>
                  <img
                    src={`/img/svg/experienceo-exonomy-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
              <div className="col-8-half right-block">
                <ul>
                  <li>Economic Offering</li>
                  <li>Economy</li>
                  <li>Economic Function</li>
                  <li>Nature of Offering</li>
                  <li>Key Attribute</li>
                  <li>Method of Supply</li>
                  <li>Seller</li>
                  <li>Buyer</li>
                  <li>Factors of Demand</li>
                </ul>
                <ul>
                  <li>Commodities</li>
                  <li>Agrarian</li>
                  <li>Extract</li>
                  <li>Fungible</li>
                  <li>Natural</li>
                  <li>Stored in Bulk</li>
                  <li>Trader</li>
                  <li>Market</li>
                  <li>Characterstics</li>
                </ul>
                <ul>
                  <li>Goods</li>
                  <li>Industrial</li>
                  <li>Make</li>
                  <li>Tangible</li>
                  <li>Standardized</li>
                  <li>Inventory</li>
                  <li>Manufacturer</li>
                  <li>User</li>
                  <li>Features</li>
                </ul>
                <ul>
                  <li>Services</li>
                  <li>Deliver</li>
                  <li>Intangible</li>
                  <li>Customized</li>
                  <li>Delivered on demand</li>
                  <li>Provider</li>
                  <li>Client</li>
                  <li>Benefits</li>
                </ul>
                <ul>
                  <li>Experiences</li>
                  <li>Experience</li>
                  <li>Stage</li>
                  <li>Memorable</li>
                  <li>Personal</li>
                  <li>Revealed in a duration</li>
                  <li>Stager</li>
                  <li>Guest</li>
                  <li>Sensations</li>
                </ul>
                <div className="note">Source: Experience Economy Book</div>
              </div>
            </div>
          </section>
          <section className="emp-exp">
            <h2 className="section-main-heading">
              EMPLOYEE EXPERIENCE{" "}
              <span className="highlight">MATTERS FOR CX</span>
            </h2>
            <span className="section-desc">
              Employee Experience (EX) is completely different from Employee
              Well-being. CX and EX are inextricably linked together and both
              highly interdependent for the organization’s success.
            </span>
            <div className="row page-wrap block1">
              <div className="col-5-half txt">
                <p>
                  <strong>
                    Employee experience is defined by work complexity — how hard
                    it is to get work done in your organization — and behavioral
                    norms around collaboration, creativity, and empowerment.
                  </strong>
                </p>
              </div>
              <div className="col-5-half diagram">
                <div className="title">
                  Impact of employee engagement on customer experience (versus
                  other factors)
                </div>
                <figure>
                  <img
                    src={`/img/svg/employee-engagement-diagram.svg`}
                    alt="Employee Engagement"
                  />
                </figure>
                <div className="note">
                  Base: All respondents, n=209 <br />
                  Q. How would you rate the impact of employee engagement on
                  customer experience? Scale of 1 to 5 <br />
                  Source: Gartner
                </div>
              </div>
            </div>
            <div className="row page-wrap block2">
              <div className="title">
                Enhancing Employee Engagement will improve the Customer
                Experience
              </div>
              <div className="col-6 left-block">
                <figure>
                  <img src={`/img/svg/employee-engagement-graph.svg`} alt="" />
                </figure>
              </div>
              <div className="col-4 right-block">
                <figure>
                  <img src={`/img/depressed-nurse-img.jpg`} alt="Nurse" />
                </figure>
                <span className="txt">
                  "In cardiac care units where nurses' moods were depressed,
                  patient death rates were four times higher than in comparable
                  units."
                </span>
              </div>
            </div>
            <div className="row page-wrap block3">
              <div className="col-6">
                <h3 className="apph3">
                  KPIs’ Tracked by CEOs show the Employee Experience (EX) is
                  important for Customer Success.
                </h3>
                <p>
                  As per{" "}
                  <NavLink
                    to="https://hbr.org/search?term=denise%20lee%20yohn&search_type=search-all"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    Denise Lee Yohn
                  </NavLink>
                  , a leading authority on positioning great brands and building
                  exceptional organizations Customer experience and employee
                  experience are now two of the driving forces of business.
                  Independently, each function leads to valuable relationships —
                  with customers and employees — but when CX and EX are managed
                  together, they create a unique, sustainable competitive
                  advantage.
                </p>
              </div>
              <div className="col-5">
                <figure>
                  <img src={`/img/svg/customer-success-chart.svg`} alt="" />
                </figure>
              </div>
            </div>
          </section>
          <section className="deal-container">
            <h2 className="section-main-heading">
              EVERY CEO NEEDS TO DEAL WITH{" "}
              <span className="highlight bold-class">MULTI-EXPERIENCES</span>
            </h2>
            <span className="section-desc">
              At Virtuos, CEO’s designation has been renamed to “ExperienCEO™”,
              a word Virtuos has coined for the first time.
            </span>
            <span className="section-desc">
              ExperienCEO™ has the ownership of three Experiences namely
              Customer Experience (CX), Employee Experience (EX), and Everything
              Experience (XX).
            </span>
            <div className="row page-wrap">
              <div className="col-12 diagram">
                <figure
                  className="wow fadeInUp animated"
                  data-wow-duration="500ms"
                  data-wow-delay="500ms"
                >
                  <img
                    src={`/img/svg/business-focus-diagram.svg`}
                    alt="Business Focus Diagram"
                  />
                </figure>
                <div
                  className="left wow fadeInLeft animated"
                  data-wow-duration="500ms"
                  data-wow-delay="500ms"
                >
                  <NavLink to={`/xx-digital/`}>
                    <img
                      src={`/img/svg/icons/xx-digital-top-logo.svg`}
                      alt="XX.Digital"
                    />
                  </NavLink>
                </div>
                <div
                  className="right wow fadeInRight animated"
                  data-wow-duration="500ms"
                  data-wow-delay="500ms"
                >
                  <NavLink to={`/cx-digital/`}>
                    <img
                      src={`/img/svg/icons/cx-digital-top-logo.svg`}
                      alt="CX.Digital"
                    />
                  </NavLink>
                </div>
                <div
                  className="bottom wow fadeInDown animated"
                  data-wow-duration="500ms"
                  data-wow-delay="500ms"
                >
                  <NavLink to={`/ex-digital/`}>
                    <img
                      src={`/img/svg/icons/ex-digital-top-logo.svg`}
                      alt="EX.Digital"
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="vision-container light-blue-bg">
            <h2 className="section-main-heading">
              <span className="highlight bold-class">VIRTUOS VISION:</span> RID
              THE WORLD OF BAD EXPERIENCES
            </h2>
            <span className="section-desc">
              By creating modern People Experience Management (XM), Virtuos
              plans to hold Employees directly responsible for Customer
              Experience. In this journey, Virtuos is also creating enterprise
              Virtuosoship™, All Agile™, and Invincible360™ Frameworks.
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <NavLink to={`/business/`} className="btn btn-secondary">
                  EXPLORE MORE <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
            </div>
          </section>
          {/*?php include '../footer-consultare.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Experienceo;
