import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../components/Navbar';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

const Oracle_nestitute = () => {
    const [activeTab, setActiveTab] = useState('tab1');

    const handleClick = (tabId) => {
        console.log("here tab is", tabId)
        setActiveTab(tabId);
    };

    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Virtuos and Oracle NetSuite | The ERP Practice – Virtuos Vivacis";
    }, []);
    return (
        <div className="oracle-netsuite-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <section className="header-container">
                        <div className="right-fixed-logo">
                            <img
                                src={`/img/svg/vivacis-oracle-netsuite-logo.svg`}
                                alt="Vivacis Oracle Netsuite logo"
                            />
                        </div>
                        <div className="header-bg" />
                        <div className="row page-wrap">
                            <div className="col-4 ">
                                <h1 style={{fontWeight:"700"}}>Virtuos Vivacis Consulting For Oracle NetSuite</h1>
                                <span>The World’s #1 CLOUD ERP</span>
                            </div>
                        </div>
                    </section>
                    <section className="breadcrumb-kanaban">
                        <div className="page-wrap breadcrumb-wrapper">
                            <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                <li>
                                    <NavLink to={`/alliances/`}>Alliances</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/oracle-netsuite/`} className="active">
                                        Oracle Netsuite
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/agiloft/`}>Agiloft</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/creatio/`}>Creatio</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/vivacis#automation-anywhere`}>
                                        Automation Anywhere
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/microsoft/`}>Microsoft</NavLink>
                                </li>

                            </ul>
                            <ul className="more-hidden-tabs">
                                <li
                                    className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <i className="fal fa-plus" />  More
                                    {showExtraLinks && (
                                        <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                            <li>
                                                <NavLink to={`/oracle/`}>Oracle</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/salesforce`}>Salesforce</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/alliances/specialized/`}>
                                                    SPECIALIZED PARTNERS
                                                </NavLink>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="erp-practice">
                        <h2 className="section-main-heading">
                            VIRTUOS VIVACIS <span className="highlight">ERP PRACTICE</span>
                        </h2>
                        <span className="section-desc">
                            ERP helps organizations deliver better Customer Experience (CX) with the
                            help of integrated billing, Financial systems, Order Management, and
                            Production Management.
                        </span>
                        <span className="section-desc italic">
                            <strong>
                                Today’s organizations need an “Organization 360” approach to better
                                achieve the “Customer 360” view. A Cloud ERP like NetSuite is a critical
                                success factor in delivering Customer Experience.” Venky Vijay Reddi,
                                ExperienCEO, Virtuos Digital.
                            </strong>
                        </span>
                        <span className="sub-heading">ERP AREAS OF PRACTICE</span>
                        <div className="row page-wrap">
                            <div className="col-3-half item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-financial-management-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>Financial Management</h3>
                                    <p>
                                        Combine robust financial management with built-in business
                                        intelligence to drive smarter, quicker decision-making.
                                    </p>
                                </div>
                            </div>
                            <div className="col-3-half item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-financial-planning-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>Financial Planning</h3>
                                    <p>
                                        Shorten cycle times, engage business users and enrich your planning
                                        process with an intuitive planning, budgeting, and forecasting
                                        solution.
                                    </p>
                                </div>
                            </div>
                            <div className="col-3-half item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-order-management-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>Order Management</h3>
                                    <p>
                                        Accelerate the order-to-cash process by tying sales, finance, and
                                        fulfillment to pricing, sales order management, and returns
                                        management.
                                    </p>
                                </div>
                            </div>
                            <div className="col-3-half item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-production-management-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>Production Management</h3>
                                    <p>
                                        Get your products to market quickly and efficiently by leveraging
                                        real-time visibility into production management processes.
                                    </p>
                                </div>
                            </div>
                            <div className="col-3-half item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-supplychain-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>Supply Chain Management</h3>
                                    <p>
                                        Define, execute and support supply chain/distribution management
                                        plans from a single, collaborative platform.
                                    </p>
                                </div>
                            </div>
                            <div className="col-3-half item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-warehouse-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>Warehouse and Fulfillment</h3>
                                    <p>
                                        Manage end-to-end inventory and inbound/outbound logistics in real
                                        time while minimizing total cost of ownership.
                                    </p>
                                </div>
                            </div>
                            <div className="col-3-half item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-procurement-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>Procurement</h3>
                                    <p>
                                        Improve the accuracy of procure-to-pay processes and optimize for
                                        cost-certainty.
                                    </p>
                                </div>
                            </div>
                            <div className="col-3-half item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-integrated-edge-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>Integrated Edge</h3>
                                    <p>
                                        Improve Customer Experience by delivering integration and enforcing
                                        integrity across your ecosystems.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12">
                                <NavLink to="javascript:void(0);">
                                    Explore More <i className="fa fa-long-arrow-right" />
                                </NavLink>
                            </div>
                        </div>
                    </section>
                    <section className="key-benefits">
                        <h2 className="section-main-heading">
                            Key Benefits of <span className="highlight">NetSuite</span>
                        </h2>
                        <div className="row page-wrap">
                            <div className="col-4 item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-erp-range-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>A BROAD RANGE OF ROBUST ERP</h3>
                                    <p>
                                        Streamline business processes and free up the time and resources
                                        needed to accelerate growth, drive innovation, and remain
                                        competitive.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-business-intelligence-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>BUILT-IN BUSINESS INTELLIGENCE</h3>
                                    <p>
                                        Combine data with visual analytics to generate meaningful and
                                        actionable business insights.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/oracle-netsuite-growth-icon.svg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content">
                                    <h3>DESIGNED TO SUPPORT GROWTH</h3>
                                    <p>
                                        Easily add and customize functionality as your company grows by
                                        leveraging a highly scalable business management system.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="suite-success">
                        <figure className="bg">
                            <img src={`/img/suite-success-bg.jpg`} alt="" />
                        </figure>
                        <div className="row page-wrap">
                            <div className="col-5">
                                <h2>SuiteSuccess for Oracle NetSuite Planning and Budgeting</h2>
                                <p>
                                    SuiteSuccess for Oracle NetSuite Planning and Budgeting enables
                                    organizations of all sizes to quickly adopt a world-class planning and
                                    budgeting application offering market-leading functionality across
                                    lines of businesses.
                                </p>
                            </div>
                            <div className="col-6">
                                <ul>
                                    <li>
                                        <img
                                            src={`/img/svg/icons/vivacis-logo.svg`}
                                            alt=""
                                        />
                                    </li>
                                    <li>+</li>
                                    <li>
                                        <img
                                            src={`/img/svg/oracle-netsuite-logo-white.svg`}
                                            alt=""
                                        />
                                    </li>
                                    <li>=</li>
                                    <li>
                                        <img
                                            src={`/img/svg/icons/virtuos-heart.svg`}
                                            alt=""
                                            style={{ width: 60 }}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="netsuite-container light-blue-bg" id="oracle-netsuite">
                        <div className="logos">
                            <ul>
                                <li>
                                    <img
                                        src={`/img/svg/vivacis-logo.svg`}
                                        alt="Vivacis logo"
                                    />
                                </li>
                                <li>+</li>
                                <li>
                                    <img
                                        src={`/img/svg/oracle-netsuite-logo.svg`}
                                        alt="Oracle Netsuite logo"
                                    />
                                </li>
                                <li>=</li>
                                <li>
                                    <img
                                        src={`/img/svg/icons/virtuos-heart.svg`}
                                        alt="Virtus Heart Icon"
                                        style={{ width: 50 }}
                                    />
                                </li>
                            </ul>
                        </div>
                        <span className="section-desc">
                            VIVACIS BRINGS FASTER AND QUICKER IMPLEMENTATION CYCLES TO THE MOST
                            DEPLOYED CLOUD ERP — ORACLE NETSUITE
                        </span>
                        <div className="row page-wrap erp">
                            <NavLink
                                to={`/oracle-netsuite#erpfirst`}
                                target="_blank"
                                className="col-4 item"
                            >
                                <figure>
                                    <img src={`/img/erp-first-img.jpg`} alt="" />
                                </figure>
                                <div className="content-block one">
                                    ERP FIRST <br />
                                    THE DIGITAL FIRST STRATEGY
                                </div>
                            </NavLink>
                            <NavLink
                                to={`/oracle-netsuite#cloudfirst`}
                                target="_blank"
                                className="col-4 item"
                            >
                                <figure>
                                    <img src={`/img/cloud-first-img.jpg`} alt="" />
                                </figure>
                                <div className="content-block two">
                                    CLOUD FIRST <br />
                                    THE WORLD’S NO.1 CLOUD ERP
                                </div>
                            </NavLink>
                            <NavLink
                                to={`/oracle-netsuite#erpnow`}
                                className="col-4 item"
                            >
                                <figure>
                                    <img src={`/img/erp-now-img.jpg`} alt="" />
                                </figure>
                                <div className="content-block three">
                                    ERP NOW <br />
                                    MAKE YOUR NEW OR WOW
                                </div>
                            </NavLink>
                        </div>
                        <div className="row page-wrap industry">
                            <div className="col-3 item">
                                <div className="left-block">
                                    <figure>
                                        <img
                                            src={`/img/svg/icons/digital-advertisement-icon.svg`}
                                            alt="Digital Advertisement Icon"
                                        />
                                    </figure>
                                </div>
                                <div className="right-block">
                                    <h3>Advertising &amp; Digital Agencies</h3>
                                    <p>
                                        Whether implementing for the first time or upgrading to the cloud,
                                        it’s a snap.
                                    </p>
                                    <NavLink
                                        to={`/industry#consumer-goods`}
                                        target="_blank"
                                        className="link"
                                    >
                                        More info <i className="fa fa-long-arrow-right" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-3 item">
                                <div className="left-block">
                                    <figure>
                                        <img
                                            src={`/img/svg/icons/hitech-consulting-icon.svg`}
                                            alt="High-Tech and Consulting Icon"
                                        />
                                    </figure>
                                </div>
                                <div className="right-block">
                                    <h3>High-Tech and Consulting</h3>
                                    <p>
                                        Whether you are in B2B or B2C with products or services, we have an
                                        implementation package.
                                    </p>
                                    <NavLink
                                        to={`/industry#hi-tech`}
                                        target="_blank"
                                        className="link"
                                    >
                                        More info <i className="fa fa-long-arrow-right" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-3 item">
                                <div className="left-block">
                                    <figure>
                                        <img
                                            src={`/img/svg/icons/heathcare-beauty-icon.svg`}
                                            alt="Healthcare and Beauty logo"
                                        />
                                    </figure>
                                </div>
                                <div className="right-block">
                                    <h3>Healthcare and Beauty</h3>
                                    <p>
                                        Thrive with resilient approaches from Vivacis to better care for
                                        your patients and shine new faces.
                                    </p>
                                    <NavLink
                                        to={`/industry#healthcare`}
                                        target="_blank"
                                        className="link"
                                    >
                                        More info <i className="fa fa-long-arrow-right" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-3 item">
                                <div className="left-block">
                                    <figure>
                                        <img
                                            src={`/img/svg/icons/travel-hospitality-icon.svg`}
                                            alt="Travel & Hospitality"
                                        />
                                    </figure>
                                </div>
                                <div className="right-block">
                                    <h3>Travel &amp; Hospitality</h3>
                                    <p>
                                        Three verticals with emerging industry 4.0 trends Driven by our
                                        Business 4.0 Paradigm shift.
                                    </p>
                                    <NavLink
                                        to={`/industry#travel`}
                                        target="_blank"
                                        className="link"
                                    >
                                        More info <i className="fa fa-long-arrow-right" />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="customer-success">
                        <h2 className="section-main-heading">
                            BUSINESS 4.0 ACUMEN FOR{" "}
                            <span className="highlight">CUSTOMER SUCCESS</span>
                        </h2>
                        <div className="row">
                            <div className="col-3-half item">
                                <div className="number">01</div>
                                <div className="content">
                                    <h3>Strategy, Design and Architecture</h3>
                                    <p>
                                        Pace-layered consultative approach to understand the objectives and
                                        nuances of the client's situation, then bring ERP best practices and
                                        technologies.
                                    </p>
                                </div>
                            </div>
                            <div className="col-3-half item">
                                <div className="number">02</div>
                                <div className="content">
                                    <h3>Business 4.0 Consulting Skills</h3>
                                    <p>
                                        Harnessing Industry 4.0 and Globalization 4.0 best practices and
                                        business frameworks to deliver "autonomous" and "continuous next"
                                        outcomes.
                                    </p>
                                </div>
                            </div>
                            <div className="col-3-half item">
                                <div className="number">03</div>
                                <div className="content">
                                    <h3>Flexibility and Customer Success</h3>
                                    <p>
                                        Our strengths lie in a flexible blend of business, creativity, and
                                        technology skills, and the very client-centric culture. The C-SAT
                                        score on average is greater than 98%.
                                    </p>
                                </div>
                            </div>
                            <div className="col-3-half item">
                                <div className="number">04</div>
                                <div className="content">
                                    <h3>Project Management and Execution</h3>
                                    <p>
                                        We maintain Digital Dexterity standards to ensure the quality of
                                        communication, transparency in project status, and high visibility
                                        in the progression.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="tabs-container">
                        <div className="row page-wrap tabs-wrapper">
                            <div className="col-12 tab">
                                <NavLink
                                    className={`tablinks ${activeTab === 'tab1' ? 'activetab' : ''}`}
                                    onClick={() => handleClick('tab1')}
                                    id="defaultOpen"

                                >
                                    <img
                                        src={`/img/svg/icons/vision-icon.svg`}
                                        alt=""
                                    />
                                    <br />
                                    01. Vision Connect
                                </NavLink>
                                <NavLink
                                    className={`tablinks ${activeTab === 'tab2' ? 'activetab' : ''}`}
                                    onClick={() => handleClick('tab2')}
                                >
                                    <img
                                        src={`/img/svg/icons/tech-insight-icon.svg`}
                                        alt=""
                                    />
                                    <br />
                                    02. Tech Insights
                                </NavLink>
                                <NavLink
                                    className={`tablinks ${activeTab === 'tab3' ? 'activetab' : ''}`}
                                    onClick={() => handleClick('tab3')}
                                >
                                    <img
                                        src={`/img/svg/icons/pace-layer-icon-black.svg`}
                                        alt=""
                                    />
                                    <br />
                                    03. Pace Layered Strategy
                                </NavLink>
                                <NavLink
                                    className={`tablinks ${activeTab === 'tab4' ? 'activetab' : ''}`}
                                    onClick={() => handleClick('tab4')}
                                >
                                    <img
                                        src={`/img/svg/icons/heart-icon.svg`}
                                        alt=""
                                    />
                                    <br />
                                    04. Happiests Culture
                                </NavLink>
                            </div>
                            <div className="row page-wrap">
                                <div className="col-12 tabcontent" id="tab1" style={{ display: activeTab === 'tab1' ? 'block' : 'none' }}>
                                    <div className="box1">
                                        <figure>
                                            <img
                                                src={`/img/cdigital-tab1-img.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                    </div>
                                    <div className="box2">
                                        <p>
                                            <strong>01. VISION CONNECT</strong>
                                        </p>
                                        <p>
                                            Set operational objectives, establish a connect with the
                                            cross-functional team, and understand CX initiatives to manage
                                            current state (CXOpia).
                                        </p>
                                    </div>
                                    <div className="box3">
                                        <p>
                                            <strong>You are in safe hands</strong>
                                        </p>
                                        <p>
                                            Virtuos has implemented over 200 CX projects across diverse
                                            verticals and technology domains with high Customer Success rate.
                                        </p>
                                        <NavLink
                                            to={`/cxnow/cxprimer`}
                                            className="btn btn-black"
                                        >
                                            Schedule CX Primer
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-12 tabcontent" id="tab2" style={{ display: activeTab === 'tab2' ? 'block' : 'none' }}>
                                    <div className="box1">
                                        <figure>
                                            <img
                                                src={`/img/cdigital-tab2-img.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                    </div>
                                    <div className="box2">
                                        <p>
                                            <strong>02. TECH INSIGHTS</strong>
                                        </p>
                                        <p>
                                            Insight into the technologies that help customers to evaluate,
                                            measure, and act on customer perception, sentiment, and
                                            experiences.
                                        </p>
                                    </div>
                                    <div className="box3">
                                        <p>
                                            <strong>Our Technology Portfolio</strong>
                                        </p>
                                        <p>
                                            Virtuos has certified professionals on multiple CRM Technologies
                                            such as Salesforce, Oracle, Microsof,t and Creatio (formerly
                                            BPM’Online).
                                        </p>
                                        <NavLink
                                            to={`/consultare/technology-services`}
                                            className="btn btn-black"
                                        >
                                            See our Portfolio
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-12 tabcontent" id="tab3" style={{ display: activeTab === 'tab3' ? 'block' : 'none' }}>
                                    <div className="box1">
                                        <figure>
                                            <img
                                                src={`/img/cdigital-tab3-img.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                    </div>
                                    <div className="box2">
                                        <p>
                                            <strong>03. PACE LAYERED STRATEGY</strong>
                                        </p>
                                        <p>
                                            It is a framework of "Pace Layers" with the precise definition of
                                            strategic goals, and how these goals are achieved through a
                                            process of iteration.
                                        </p>
                                    </div>
                                    <div className="box3">
                                        <p>
                                            <strong>Differentiation Strategy</strong>
                                        </p>
                                        <p>
                                            Systems of records and processes focus on standardization. Using
                                            our NOW framework, we structure various differentiation
                                            techniques.
                                        </p>
                                        <NavLink
                                            to={`/consultare/pace-layered`}
                                            className="btn btn-black"
                                        >
                                            See PLUS methodology
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-12 tabcontent" id="tab4" style={{ display: activeTab === 'tab4' ? 'block' : 'none' }}>
                                    <div className="box1">
                                        <figure>
                                            <img
                                                src={`/img/cdigital-tab4-img.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                    </div>
                                    <div className="box2">
                                        <p>
                                            <strong>04. HAPPIESTS CULTURE</strong>
                                        </p>
                                        <p>
                                            It’s not just a happy working environment; it’s employees’ own
                                            space — where they are obsessed to deliver happiness to customers.
                                        </p>
                                    </div>
                                    <div className="box3">
                                        <p>
                                            <strong>Happiests Building Blocks</strong>
                                        </p>
                                        <p>
                                            Our unique h-a-p-p-i-e-s-t-s building blocks define the
                                            characteristics of our culture. These nine styles fit into
                                            integrated culture framework.
                                        </p>
                                        <NavLink
                                            to={`/careers/happiests-culture`}
                                            className="btn btn-black"
                                        >
                                            Learn More
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="erp-cx-container">
                        <div className="row page-wrap">
                            <div className="col-12 item">
                                <figure>
                                    <img
                                        src={`/img/oracle-netsuite-erp-breaker-bg.jpg`}
                                        alt=""
                                    />
                                </figure>
                                <div className="content-container">
                                    <span className="title">
                                        ERP brings Integrity and Integration of Departments delivering
                                        Better CX
                                    </span>
                                    <p>
                                        Virtuos has been a leading partner of Oracle for CX Practice. As
                                        part of our integrated experiences strategy – Customer Experience
                                        (CX), Employee Experience and Everything Experience (XX), It’s
                                        important to have trusted, highly reliable, and always available ERP
                                        to empower your customers.
                                    </p>
                                    {/* <NavLink to="javascript:void(0);" className="btn btn-white">Download</NavLink> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="digital-transformation">
                        <div className="row page-wrap">
                            <div className="col-3">
                                <span className="title">
                                    Virtuos Vivacis brings Digital Transformation At the speed of thought
                                </span>
                                <p>
                                    Integrating Customer and Employee Experiences for driving Operations
                                    Excellence.
                                </p>
                                <NavLink to={`/contact/`}>Contact Us</NavLink>
                            </div>
                            <span className="col-2 icon">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/strategy-icon1.svg`}
                                        alt="Strategy"
                                    />
                                </figure>
                                <p>Strategy</p>
                            </span>
                            <span className="col-2 icon">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/technology-icon1.svg`}
                                        alt="Technology"
                                    />
                                </figure>
                                <p>Technology</p>
                            </span>
                            <span className="col-2 icon">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/transform-icon.svg`}
                                        alt="Transformation"
                                    />
                                </figure>
                                <p>Transformation</p>
                            </span>
                            <span className="col-2 icon">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/optimization-icon.svg`}
                                        alt="Optimization "
                                    />
                                </figure>
                                <p>Optimization</p>
                            </span>
                        </div>
                    </section>
                    <section className="resources">
                        <h2 className="section-main-heading">Resources</h2>
                        <div className="row page-wrap">
                            <div className="col-3 item">
                                <h3>NETSUITE RESEARCH</h3>
                                <NavLink
                                    to="https://www.netsuite.com/portal/resource/collateral.shtml"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <figure>
                                        <img
                                            src={`/img/oracle-netsuite-resources-img1.jpg`}
                                            alt=""
                                        />
                                        <div className="icon">
                                            <img
                                                src={`/img/svg/icons/research-paper-icon.svg`}
                                                alt=""
                                            />
                                        </div>
                                    </figure>
                                    <p>
                                        <strong>Download research papers</strong> to learn more about
                                        NetSuite's CRM/ERP solution.
                                    </p>
                                </NavLink>
                            </div>
                            <div className="col-3 item">
                                <h3>NETSUITE ROLE BASED DEMO</h3>
                                <NavLink
                                    to="https://www.netsuite.com/portal/resource/demo.shtml"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <figure>
                                        <img
                                            src={`/img/oracle-netsuite-resources-img2.jpg`}
                                            alt=""
                                        />
                                        <div className="icon">
                                            <img
                                                src={`/img/svg/icons/video-play-icon-white.svg`}
                                                alt=""
                                            />
                                        </div>
                                    </figure>
                                    <p>
                                        <strong>Watch this demo</strong> highlighting how NetSuite
                                        streamlines your most critical business processes.
                                    </p>
                                </NavLink>
                            </div>
                            <div className="col-3 item">
                                <h3>NETSUITE DATASHEETS</h3>
                                <NavLink
                                    to="https://www.netsuite.com/portal/resource/datasheets.shtml"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <figure>
                                        <img
                                            src={`/img/oracle-netsuite-datasheet-img.jpg`}
                                            alt=""
                                        />
                                        <div className="icon">
                                            <img
                                                src={`/img/svg/icons/datasheet-icon.svg`}
                                                alt=""
                                            />
                                        </div>
                                    </figure>
                                    <p>
                                        <strong>Download data sheets</strong> to learn more about the
                                        world’s leading Cloud ERP — NetSuite's solutions.
                                    </p>
                                </NavLink>
                            </div>
                            <div className="col-3 item">
                                <h3>DOWNLOAD VIVACIS STORY</h3>
                                <NavLink
                                    to={`/downloads/vivacis-brochure.pdf`}
                                    target="_blank"
                                >
                                    <figure>
                                        <img
                                            src={`/img/oracle-netsuite-vivacis-img.jpg`}
                                            alt=""
                                        />
                                        <div className="icon">
                                            <img
                                                src={`/img/svg/icons/pdf-icon-white.svg`}
                                                alt=""
                                            />
                                        </div>
                                    </figure>
                                    <p>
                                        <strong>How Vivacis is transforming</strong> the Digital Automation
                                        story for your brand with CRM, CLM, DPA, and ERP Solutions.
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </section>
                    {/*?php include '../footer-consultare.php';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Oracle_nestitute
