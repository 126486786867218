import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Consultare_And_Oracle_Mou = () => {
    const stickyStyle = {
        position: 'sticky',
        top: 0, // Adjust the top position as needed
    };

    useEffect(() => {
        document.title = "Virtuos Consultare and Oracle Consulting Services (OCS) sign MOU – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-oracle-signs-mou-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    Sydney, Bengaluru and Gurgaon - 5 March, 2013
                                </span>
                                <h2 className="article-heading">
                                    Virtuos Consultare and Oracle Consulting Services (OCS) sign MOU
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Consultare becomes the Implementation and Professional
                                    Services Provider to Oracle Consulting Services (OCS) as the latter's
                                    important &amp; key Implementation Provider for Oracle RightNow CX
                                    Cloud Service Practice.
                                </p>
                                <p>
                                    OCS and Virtuos Consultare will jointly position Oracle RightNow
                                    Services across the APAC region. "We are truly impressed with The
                                    trust and confidence that OCS had shown in Virtuos Consultare
                                    capabilities," quoted by Amarinder Singh, CRM Technology Consultant
                                    practicing Oracle RightNow.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/oracle-consulting-virtuos-consultare-logo.jpg`}
                                        alt="Oracle Consulting, Virtuos Consultare"
                                    />
                                </figure>
                                <br />
                                <p>
                                    Virtuos Consultare has built Oracle RightNow RapidPro Implementation
                                    Services with innovative plans and offerings to suit both Small and
                                    Mid-market customers. Download{" "}
                                    <NavLink
                                        to={`/downloads/Virtuos-Consultare-RapidPro.pdf`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Oracle RightNow RapidPro Catalogue
                                    </NavLink>{" "}
                                    here.
                                </p>
                                <p>
                                    Oracle Consulting Services generates US$1Billion+ Revenue of high-end
                                    Professional Services to global clients who insist on Oracle's
                                    involvement in the projects. Oracle Consulting Services (OCS)
                                    implemented some of the most sophisticated technologies before they
                                    are generally available to partners. With the advent of early adoption
                                    of RightNow Skills (even before Oracle acquired RightNow
                                    Technologies), Virtuos has deep expertise in implementing and
                                    customizing the Oracle RightNow CX Suite across the industries.
                                </p>
                                <h2 className="title">About Oracle Consulting Services (OCS)</h2>
                                <p>
                                    Oracle consulting services are designed to help each business reach
                                    its goals by preparing the planning and implementation practices for
                                    systems management. OCS (Oracle Consulting Services) is the largest
                                    SaaS service organization in EMEA with a dedicated and extended
                                    (nearshore/offshore) team of over 300 highly-skilled Oracle
                                    consultants experienced in Oracle SaaS applications.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink
                                        to="http://www.oracle.com/consulting/"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.oracle.com/consulting
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Consultare Services (VCS)</h3>
                                <p>
                                    Virtuos Consultare Services for Oracle are available for the entire
                                    suite of Oracle CX SaaS Applications, namely Sales, Service, and
                                    Marketing. VCS has also built connectors, accelerators, and
                                    integrations with dozens of applications to bring additional and quick
                                    functionality to Oracle CX Stack.
                                </p>
                                <p>
                                    Virtuos Consultare also integrates CX with Digital fusion of
                                    technologies, and delivers high performance consulting services with
                                    superior digital transformation models by offering CX Strategy,
                                    Design, Technologies, and CX Optimization. Virtuos Consultare also
                                    provides CX Continuum using state of the art CXDesk Managed Services.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has implemented CRM and Customer Experience Projects to some
                                    of the largest global brands. Our customers include: MakeMyTrip,
                                    Flipkart, Cleartrip, BookMyShow, FundTech, Canon Singapore, National
                                    University of Singapore (NUS), Singapore Institute of Management
                                    (SIM), NZPost, Telstra, Myntra, Standard Chartered Bank,
                                    GE-Healthcare, Sutherland Global, Party Gaming amongst several others.
                                </p>
                                <p>
                                    Virtuos has forayed into high performance Consulting Services
                                    integrating CX and Digital through Consultare division. For more
                                    information visit us at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content" >
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Consultare_And_Oracle_Mou