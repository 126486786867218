import React, { useEffect ,useState} from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import { scroller } from "react-scroll"
import EnquireNow from './Forms/EnquireNow';

const O_Continnum = () => {


  useEffect(() => {
    document.title = "Customer Experience Continuum Services | Business 3.0/4.0 Operations - Virtuos Consultare";
    const hash = window.location.hash;
    if (hash === "#cx-continuum-section") {
      scrollToUncodifySection();
    }
   
  }, []);

  const scrollToUncodifySection = () => {
    scroller.scrollTo("cx-continuum-section", {
        duration: 1000,
        delay: 100,
        smooth: true,
        offset: -50, // Adjust this offset if needed
    });
};
const scrollToSection = (sectionId) => {
  setTimeout(() => {
    scroller.scrollTo(sectionId, {
      duration: 1000,
      smooth: true,
      offset: -50, // Adjust this offset if needed
    });
  }, 100); // Delay in milliseconds
};

 
  return (
    <div className="cx-continuum-page landing-page">
      <div className='canvas'>
        <Navbar />
        <main className="main-wrapper">
          {/* CX Continuum */}
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/svg/cx-continuum-bg-banner.svg`}
                alt="Customer Experience Model"
              />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-4 item-wrapper">
                  <figure>
                    <img
                      src={`/img/svg/icons/cx-continuum-logo.svg`}
                      alt="CX Continuum"
                    />
                  </figure>
                  <h3 className="section-heading">CX As A "Continuous Next" Service</h3>
                </div>
                <div className="col-6">
                  <figure>
                    <img
                      src={`/img/svg/cx-continuum-banner-img.svg`}
                      alt="Customer Experience Management Tools"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/o-digital/`}>O.Digital</NavLink>
                </li>
                <li>
                  <NavLink to={`/o-digital/cx-continuum`}>
                    CX Continuum
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/o-digital/cx-continuum/#cx-continuum-section`}
                    className="smooth"
                  >
                    247CX
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/cxdesk/`}>CXDesk</NavLink>
                </li>
                <li>
                  <NavLink to={`/o-digital/digital-asap/`}>
                    Digital ASAP
                  </NavLink>
                </li>
                {/*						<li><NavLink to={`/o-digital/uxwheel/`}>UX Wheel</NavLink></li>*/}
              </ul>

            </div>
          </section>
          <section className="business3-tab-section tab-content-wrapper">
            {/* CX Continuum */}
            <div id="cx-continuum-section">
              <div className="light-blue-bg consultare-services">
                <div className="page-wrap">
                  <h2 className="section-main-heading">
                    <span className="highlight">CX</span> CONTINUUM
                  </h2>
                  <span className="section-desc">
                    Customer Experience Continuum As A Service brings unparalleled edge
                    for our customers to keep harvesting on Acquisition, Retention and
                    Efficiency strategies using our Business 3.0/4.0 Operational
                    Excellence Models.{" "}
                  </span>
                  <div className="row offport-down">
                    <div className="col-6">
                      <NavLink 
                       to="#cust-aquisition" 
                       onClick={() => scrollToSection("cust-aquisition")}
                      className="item">
                        <div className="item-wrapper">
                          <span className="icon">
                            <figure>
                              <img
                                src={`/img/svg/24x7-cx-logo.svg`}
                                alt="24x7 CX Logo"
                              />
                            </figure>
                          </span>
                          <h3 className="section-heading title">24/7 CX Outcomes</h3>
                          <p>
                            Design and operationalize customer intent and interests
                            enabled personalization to deliver gratifying CX and
                            competitive differentiation resulting in measurable impacts.{" "}
                          </p>
                        </div>
                        <div className="invisible-wrapper">
                          <span>Learn More</span>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-6">
                      <NavLink to={`/cxdesk`} className="item">
                        <div className="item-wrapper">
                          <span className="icon">
                            <figure>
                              <img
                                src={`/img/svg/icons/cxdesk-logo.svg`}
                                alt="CXDesk Logo"
                              />
                            </figure>
                          </span>
                          <h3 className="section-heading title">
                            CXdesk Managed Services
                          </h3>
                          <p>
                            Customer Experience (CX) Continuum framework has Dexterity,
                            Expertise, Skills, and Knowledge (DESK) to reap the full
                            potential of CRM technology investments.
                          </p>
                        </div>
                        <div className="invisible-wrapper">
                          <span>Learn More</span>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="advantages-of-aury ecosystem-section">
                <div className="page-wrap">
                  <div className="row">
                    <div className="col-5 offport-left">
                      <figure>
                        <img
                          src={`/img/svg/custmer-aquisition-img.svg`}
                          alt="Custmer Acquisition"
                        />
                      </figure>
                    </div>
                    <div className="col-6 points offport-right" id="cust-aquisition">
                      <h3 className="section-main-heading">
                        Customer <span className="highlight">Aquisition</span>
                      </h3>
                      <ul>
                        <li className="row">
                          <span className="icon">
                            <i className="fas fa-comments" />
                          </span>
                          <div className="content-wrapper">
                            <h3>Predictive Chat</h3>
                            <p>
                              Customers who use live chat are 3x more likely to buy and
                              develop deep levels of trust with the brand. By creating
                              experiences on micro-moments throughout the customer
                              journey, the sales conversions go up by 300%, and cost of
                              engagement goes down.
                            </p>
                            <p>
                              <img
                                src={`/img/svg/predictive-chat-img.svg`}
                                alt="Predictive Chat"
                              />
                            </p>
                          </div>
                        </li>
                        <li className="row">
                          <span className="icon">
                            <i className="fas fa-cogs" />
                          </span>
                          <div className="content-wrapper">
                            <h3>Hyper Personalization</h3>
                            <p>
                              Integrated content, optimization, and personalization
                              enable new levels of engagement across various touch
                              points during the customer encounter with multiple
                              channels. AI and Machine learning (ML) guided
                              recommendations like what content to share helps you cut
                              through the noise.
                            </p>
                          </div>
                        </li>
                        <li className="row">
                          <span className="icon">
                            <i className="fal fa-search" />
                          </span>
                          <div className="content-wrapper">
                            <h3>Intelligent Search Syndication</h3>
                            <p>
                              Intelligent search bidding and syndicating the content to
                              right customers at the right time always requires smarter
                              prediction capabilities driven by AI and Machine Learning
                              (ML) technologies. We truly understand that your goals are
                              not just reduced CPA alone but differentiated ROI.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="advantages-of-aury ecosystem-section">
                <div className="page-wrap">
                  <div className="row">
                    <div className="col-6 points offport-left">
                      <h3 className="section-main-heading">
                        Customer <span className="highlight">Engagement</span>
                      </h3>
                      <ul>
                        <li className="row">
                          <span className="icon">
                            <img
                              src={`/img/svg/icons/cjourney-logo-icon.svg`}
                              alt="Customer Journey Map"
                            />
                          </span>
                          <div className="content-wrapper">
                            <h3>Customer Journey Analytics</h3>
                            <p>
                              Brands fail to understand and assimilate millions of
                              customer journeys across platforms, ecosystems and
                              engagement channels. Gain invaluable insights on the fly
                              by creating customer personas and building real-time
                              customer journeys through our visually rich, AI powered
                              CJourney tools.
                            </p>
                          </div>
                        </li>
                        <li className="row">
                          <span className="icon">
                            <img
                              src={`/img/svg/icons/live-share-icon.svg`}
                              alt="Live Share"
                            />
                          </span>
                          <div className="content-wrapper">
                            <h3>Live Share</h3>
                            <p>
                              Use new share mediums to engage real-time with millennials
                              and tech-savvy customers who want to multitask using
                              voice, messaging and browser or app on mobile. Building on
                              Mobile First vision, our Live Share helps customers
                              on-the-go experience new levels of maturity in interaction
                              engagement.
                            </p>
                          </div>
                        </li>
                        <li className="row">
                          <span className="icon">
                            <img
                              src={`/img/svg/icons/aury-logo-icon.svg`}
                              alt="Aury Logo"
                            />
                          </span>
                          <div className="content-wrapper">
                            <h3>
                              AURY<sup>®</sup> Chatbot
                            </h3>
                            <p>
                              Built on the foundation of Natural Language Processing
                              (NLP), Artificial Intelligence and Machine Learning, AURY
                              <sup>®</sup> is our brand new chatbot for the digital
                              world. It brings conversations to life, intuitively
                              engages and transparently connects with digital platforms
                              delivering immersive experiences.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-5 offport-right">
                      <figure>
                        <img
                          src={`/img/svg/customer-engagement-img.svg`}
                          alt="Customer Engagement Strategy"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="full-width-container">
            <div className="row cx-desk-section">
              <figure className="banner">
                <img
                  src={`/img/cx-continuum-breaker-img.jpg`}
                  alt="CX Continuum As a Service"
                />
              </figure>
              <div className="content offport-down">
                <span className="icon">
                  <img
                    src={`/img/svg/CXDesk-logo.svg`}
                    alt="CXDesk Logo"
                  />
                </span>
                : CX Continuum As A Service.
              </div>
            </div>
          </section>
          <section className="related-offerings">
            <h2 className="section-main-heading">
              DIGITAL ASAP <span className="highlight">Services</span>
            </h2>
            <div className="page-wrap row offport-down">
              <div className="col-6 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <img
                      src={`/img/svg/icons/content-voice-logo.svg`}
                      alt="ContentVoice Logo"
                    />
                  </span>
                  <div className="content-wrap">
                    <h3 className="section-heading">ContentVoice</h3>
                    <p>
                      Content is king. Content is voice too. It’s loud and audible
                      everywhere. ContentVoice is now video, audio, images, text
                      encapsulated to capture the voice of customers in real time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <span className="icon adjust-height">
                    <img
                      src={`/img/svg/icons/experience-cart-logo.svg`}
                      alt="Experience Cart Logo"
                    />
                  </span>
                  <div className="content-wrap">
                    <h3 className="section-heading">ExperienceCart</h3>
                    <p>
                      Cart abandonment is a complex problem faced by marketers. In our
                      survey, we found the no.1 reason for cart abandonment is not
                      sticker price or technical glitches but "micro-moments."
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <img
                      src={`/img/svg/icons/1campaign-logo.svg`}
                      alt="1Campaign Logo"
                    />
                  </span>
                  <div className="content-wrap">
                    <h3 className="section-heading">Multi-channel Marketing</h3>
                    <p>
                      Orchestrate customer engagements and marketing content to
                      individuals and segments across multiple channels. Channels may
                      include websites, email, mobile, text, and social.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <img
                      src={`/img/svg/icons/ssentiment-logo.svg`}
                      alt="Social Sentiment Monitoring"
                    />
                  </span>
                  <div className="content-wrap">
                    <h3 className="section-heading">Social Sentiment Monitoring</h3>
                    <p>
                      Social Sentiment monitoring may include techniques such as
                      sentiment analysis, keyword search-type analytics,
                      natural-language processing, social networking, and scoring.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <span className="icon adjust-height">
                    <img
                      src={`/img/svg/icons/brandien-b-icon.svg`}
                      alt="Branding Services"
                    />
                  </span>
                  <div className="content-wrap">
                    <h3 className="section-heading">Branding Services</h3>
                    <p>
                      Brandien offers brand architecture and naming services besides
                      asset monetization. A good brand will have emotional connect with
                      customers significantly boosting CX, brand advocacy, and loyalty.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <div className="content-wrap">
                    <div className="logo">
                      <img
                        src={`/img/svg/icons/digitalasap-logo.svg`}
                        alt="DigitalASAP"
                      />
                    </div>
                    <NavLink
                      to={`/o-digital/digital-asap/`}
                      className="btn btn-primary"
                    >
                      Explore More <i className="fa fa-long-arrow-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="grey-bg cf-form-wrapper">
            <div className="page-wrap ">
              <h2 className="section-main-heading">
                ENGAGE WITH US TO CREATE{" "}
                <span className="highlight">DIFFERENTIATION</span>
              </h2>
              <span className="section-desc">
                Our Customer Experience Continuum offers synergistic value to transform
                your offerings and leapfrog the competition in a whole new way.
              </span>
              <EnquireNow/>
            </div>
          </section>
          <Footer />
        </main>

      </div>
      <div className='off-canvas'>
        <Nav />
      </div>
    </div>
  )
}

export default O_Continnum