import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

const Virtuos_Attends_Dreamforce_2019 = () => {
  useEffect(() => {
    document.title =
      "Virtuos attends Dreamforce 2019 and Gartner Event in Las Vegas – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-attends-dreamforce-2019-img-big.jpg`}
                  alt="Virtuos attends Dreamforce 2019 and Gartner Summit in Las Vegas"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">
                  San Francisco and Las Vegas, 5 December 2019
                </span>
                <h2 className="article-heading">
                  Virtuos attends Dreamforce 2019 and Gartner Summit in Las
                  Vegas
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Venky Vijay Reddi, the CEO of Virtuos, attended Dreamforce
                  2019 between November 19 to 22nd, 2019, and met several key
                  Salesforce executives on{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour's
                  </NavLink>{" "}
                  future launch, a Salesforce platform product strategy.{" "}
                </p>
                <h3 className="title">Dreamforce 2019</h3>
                <p>
                  Key highlights and the facts &amp; figures of the event are
                  listed{" "}
                  <NavLink
                    to="https://www.salesforce.com/company/news-press/stories/2019/12/Dreamforce-By-Numbers/"
                    rel="noreferrer"
                    target="_blank"
                    className="link"
                  >
                    here
                  </NavLink>
                  . Some of them sessions attended by Venky include:
                </p>
                <ul>
                  <li>
                    News and roadmap of Clicksoftware (after the acquisition by
                    Salesforce).
                  </li>
                  <li>Einstein Can Now Talk To You.</li>
                  <li>
                    Someone Is Actually Using <strong>Salesforce</strong>{" "}
                    Blockchain.
                  </li>
                  <li>
                    <strong>Salesforce</strong> Is Bringing Together The Full
                    Customer 360.
                  </li>
                  <li>
                    <strong>Salesforce</strong> Have New Partnerships With…
                    Everyone.
                  </li>
                  <li>
                    <strong>Salesforce</strong> Is Taking On The Call Coaching
                    Market.
                  </li>
                </ul>
                <p>
                  Venky Vijay Reddi also met Sunil Jose, India Head for
                  Salesforce at the APAC welcome show, and had lunch with his
                  good friend — YS Li at the Amber Restaurant.
                </p>
                <figure className="box-shadow">
                  <img
                    src={`/img/dreamforce-2019-pic-01.jpg`}
                    alt="Dreamforce 2019"
                  />
                </figure>
                <br />
                <p>
                  “The model of the operating system at Salesforce is to partner
                  with every company and deliver the best the customer needs.”
                  Said Venky Vijay Reddi, the CEO of Virtuos. “
                  <NavLink
                    to="https://www.salesforce.com/company/news-press/press-releases/2019/11/191911-Salesforce-Customer360/"
                    rel="noreferrer"
                    target="_blank"
                    className="link"
                  >
                    Customer’s 360th
                  </NavLink>{" "}
                  Truth — A single source of the truth for every customer.”{" "}
                </p>
                <h3 className="title">
                  Gartner Application Strategies and Solutions Summit 2019
                </h3>
                <p>
                  Venky Vijay Reddi attended Gartner Summit for Application
                  Strategies, and Solutions second time. The event was held at
                  the beautiful Caesars Palace, Las Vegas, between December 2
                  and 5th 2019. The key takeaways from the event are{" "}
                  <NavLink
                    to="https://emtemp.gcom.cloud/ngw/eventassets/en/conferences/apps23i/documents/gartner-applications-emea-summit-overview-infographic-2020.pdf"
                    rel="noreferrer"
                    target="_blank"
                    className="link"
                  >
                    here
                  </NavLink>
                  .
                </p>
                <p>
                  On the first day, Venky attended and participated in the CX
                  Design thinking session with a group of professionals. The
                  team created a pilot on creating better Customer Experience
                  during the air travel.
                </p>
                <figure className="box-shadow">
                  <img
                    src={`/img/dreamforce-2019-pic-02.jpg`}
                    alt="Dreamforce 2019"
                  />
                </figure>
                <br />
                <p>
                  During the following three days, Venky attended many sessions
                  and discussed with the Gartner Analysts on CX Strategies. One
                  of the interesting insights from the Gartner Analyst was to
                  create a better delinked ABCDE Strategy for Employee
                  Experience. Venky also met key executives at Creatio (Formerly
                  BPM'Online).
                </p>
                <p>
                  "This was after Venky's feedback and recommendation to change
                  the company's name, we have acted on it. Said Alex Donchuk,
                  Global Partner Director at Creatio. "We have changed our
                  company's name from BPM’Online to Creatio."
                </p>
                <p>
                  Venky and Alex (Creatio) met and discussed the business
                  strategy for expanding Virtuos into professional service space
                  and penetrating newer markets.
                </p>
                <p>
                  Virtuos and Creatio (Formerly BPM'Online) have been partners
                  for two years, creating several customers in India. Venky also
                  visited Oracle's booth after attending Oracle's session on
                  Digital Chatbot to meet the members.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. (Formerly Virtuos Solutions Private Ltd.)
                  is a leading Customer Experience (CX) &amp; Digital
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/cxdesk/`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Newly created Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/xonomy/ai/`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce, and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io/`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Attends_Dreamforce_2019;
