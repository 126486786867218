import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_To_Enter_In_Ecommerce = () => {
    useEffect(() => {
        document.title = "Virtuos, ready to make a mark in the e-commerce sector with Giftcart.com – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-to-enter-in-ecommerce-with-giftcart-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 25 August, 2011</span>
                                <h2 className="article-heading">
                                    Virtuos, ready to make a mark in the e-commerce sector with
                                    Giftcart.com
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos, through its Tekcorp VC Portfolio, is planning to invest
                                    almost US$1 Million over the next 2-3 years in Giftcart.com, an
                                    in-house brand at Virtuos.
                                </p>
                                <p>
                                    Giftcart.com will be incorporated later next year once the Platform is
                                    fully developed and ready to launch.
                                </p>
                                <p>
                                    Gifting is an experience we all cherish as it has a surprise element.
                                    Traditional ways of gifting have undergone a sea change with the
                                    advent of new digital technologies and platforms.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/giftcart-logo-news-img.jpg`}
                                        alt="Giftcart.com logo"
                                    />
                                </figure>
                                <br />
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, and
                                    Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based in
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company is inspired by the book — Virtuoso Teams and has entered
                                    the burgeoning CRM space by addressing three essential needs of
                                    growing businesses — Sales, Customer Service, and Marketing. Virtuos
                                    has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos Ventures will be investing in new and modern organizations
                                    with a sound business model. Virtuos currently has been exploring to
                                    invest in new brand assets such as VYO.com, which it recently acquired
                                    at whopping valuation, and plans to infuse funds into giftcart.com,
                                    which will establish its niche in the gifting experience commerce
                                    business.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink to="http://www.giftcart.com" target="_blank" className="link">
                                        www.giftcart.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_To_Enter_In_Ecommerce