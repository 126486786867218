import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Participates_In_Dreamforce_2015 = () => {
    useEffect(() => {
        document.title = "Virtuos Participates in Dreamforce Yet Another Time in San Francisco – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-participates-in-dreamforce-2015-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">San Francisco - 18 September, 2015</span>
                                <h2 className="article-heading">
                                    Virtuos participates in Dreamforce yet another time in San Francisco
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Venky Vijay Reddi attends Dreamforce 2015 in San Francisco, meeting
                                    the partners, customers, and prospects and exploring new technologies
                                    while learning about modern CRM Technologies.
                                </p>
                                <p>
                                    “Salesforce is the first company to go on the cloud with its
                                    Cloud-based CRM Offerings, and today with more than 40% market share,
                                    Salesforce has become a gold standard for any company that plans to
                                    implement CRM.” Said Venky Vijay Reddi. “There are many other equally
                                    good CRM Softwares from Microsoft, Oracle, SAP, but Salesforce has
                                    been leading in this space while others are simply catching up.”
                                </p>
                                <p>
                                    The most exciting fact about Dreamforce is that it’s not a Software or
                                    Cloud Conference. It’s a festival for the people who want to return
                                    every year to take away some essential learnings, insights, and
                                    inspiration and return to their work with renewed fashion. Many
                                    organizations consider sending their employees to Dreamforce to expose
                                    so many technologies that are central to CRM. The days are gone when
                                    we thought of CRM to be Sales, Service, and Marketing. Today, the size
                                    of CRM is pegged at US$40B annual business in licensing and about
                                    US$200B in Services revenue. CRM is one of the fastest-growing and
                                    prominent software businesses on the cloud.
                                </p>
                                <h2 className="title">Key highlights of Dreamforce 2015:</h2>
                                <ul>
                                    <li>
                                        Keynote by Marc Benioff along with the guests namely Travis Kalanic
                                        of Uber.
                                    </li>
                                    <li>Fireside Chat with Satya Nadella, Microsoft</li>
                                    <li>Conversation with John Collison of Stripe</li>
                                    <li>
                                        Story from Hampton Creek, world’s fastest growing food company
                                    </li>
                                    <li>The second machine age by Dr. Andrew McAfee</li>
                                </ul>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Participates_In_Dreamforce_2015