import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Co_Sponsors_Top_Indian_Banks_2008 = () => {
    useEffect(() => {
        document.title = "Virtuos co-sponsors India's Top Banks-2008 in association with Dun - Bradstreet – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-co-sponsors-top-indian-banks-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Mumbai, 11 March 2008</span>
                                <h2 className="article-heading">
                                    Virtuos co-sponsors India's Top Banks-2008 in association with Dun
                                    &amp; Bradstreet
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos has co-sponsored "India's Top Banks- 2009" in association with
                                    Dun &amp; Bradstreet and other sponsors in the commercial capital of
                                    India — Mumbai. Virtuos Key Partner: KANA represented by Gary Winder,
                                    Regional Director KANA, and customers like Standard Chartered Bank
                                    also felicitated the event. Gary Winder sat on the panel of experts
                                    talking of the Digital Automation for the Indian Banks. Virtuos thanks
                                    Gary and the entire KANA leadership team for especially flying to
                                    attend the Banking Summit co-sponsored by Virtuos Solutions.
                                </p>
                                <p>
                                    Interestingly the Standard Chartered Bank was awarded as the Most
                                    Profitable MNC Bank in the Private Sector. On the sidelines, Venky
                                    Vijay Reddi, the CEO of Virtuos, and his colleague — Priyanka Sood,
                                    Product Marketing Analyst have interacted with Gary Winder and Amit
                                    Ovelekar, Manager - IT from Standard Chartered Bank.
                                </p>
                                <p>
                                    Virtuos has launched a new program —{" "}
                                    <strong>Banking Done Right</strong> (
                                    <NavLink
                                        to="http://www.bankingdoneright.com"
                                        target="_blank"
                                        className="link"
                                    >
                                        Bankingdoneright.com
                                    </NavLink>
                                    ), using KANA Software and Virtuos Consulting Services framework. Dun
                                    &amp; Bradstreet has also aired Virtuos TV Commercials on CNBC Network
                                    and Other channels. Virtuos brand name became very popular in the
                                    circle of over 400 top-notch banking executives who attended the
                                    event.
                                </p>
                                <p>
                                    "I am truly excited to see that the Virtuos logo was placed
                                    prominently on Indian Banks - 2008 Directory, which was circulated to
                                    thousands of D&amp;B's customers and banking professionals in India."
                                    Said Venky Vijay Reddi, CEO of Virtuos. "Further, Virtuos has also
                                    published a{" "}
                                    <NavLink
                                        to={`/customers/standard-chartered-case-study`}
                                        target="_blank"
                                    >
                                        case study
                                    </NavLink>{" "}
                                    and advertisement of Standard Chartered's KANA Success Story with
                                    special permission from the bank's leadership."
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd., a company registered with the Indian
                                    Companies Act, is a startup in the space of the CRM Cloud Computing
                                    business. As a company, Virtuos is born in the cloud by forging
                                    alliances with leading companies such as KANA Software and Maximizer.
                                </p>
                                <p>
                                    The company is headquartered in New Delhi with its corporate office in
                                    the prestigious Okhla Industrial Area Phase I at a prominent building.
                                    Virtuos has delivered a world-class KANA Platform for a leading Bank
                                    like Standard Chartered Bank which serves several million customers
                                    across India. A state of the art application fully implemented by
                                    Virtuos Consulting team has delivered significant ROI in a short span.
                                </p>
                                <p>
                                    For more information on Virtuos Consulting Services and Case studies
                                    on CRM Success stories visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to="http://www.bankingdoneright.com"
                                        target="_blank"
                                        className="link"
                                    >
                                        Bankingdoneright.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Co_Sponsors_Top_Indian_Banks_2008