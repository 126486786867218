import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

const Virtuos_Exonomy_Invests_In_Experience = () => {
  useEffect(() => {
    document.title =
      "Virtuos Xonomy invests in Sweven — Salesforce Partner – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-exonomy-invests-in-experience-economy-company-img-big.jpg`}
                  alt="Virtuos Xonomy invests in the Experience Economy Company"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram, 20 October 2021</span>
                <h2 className="article-heading">
                  Virtuos Xonomy invests in the Experience Economy Company
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos Xonomy invests in Sweven Digital Private Ltd., a
                  Salesforce-focused CRM Mastermind Consulting Company that will
                  initially operate from San Francisco, USA, and India before
                  expanding into other markets. Virtuos exits Salesforce
                  Business to transfer its knowledge and best practices into a
                  new Company that will independently operate as a separate
                  entity.
                </p>
                <p>
                  Sweven has procured its branding and digital assets from
                  Vedam, a division of Virtuos, for a whopping US$25,000 to
                  create a distinct and unique identity for Sweven, including
                  the
                  <NavLink
                    to="http://www.sweven.com"
                    target="_blank"
                    className="link"
                  >
                    sweven.com
                  </NavLink>{" "}
                  domain. Sweven means "vision or dream", and the meaning
                  perfectly fits the purpose of launching a visionary and dream
                  company in the Salesforce Ecosystem. Salesforce Ecosystem is
                  growing in double digits every year, contributing to more than
                  a 1 Trillion dollar Economy.
                </p>
                <p>
                  Salesforce is an iconic brand and one of the fastest-growing
                  software companies worldwide. In the next three to five years,
                  Sweven will become one of the top 100 Salesforce partners
                  globally, offering an "Industry-specific Experience CRM Stack"
                  said Venky Vijay Reddi, the founder of Virtuos Digital, which
                  invested in Sweven. Venky Vijay and Virtuos teams have visited
                  more than eight times personally to attend Dreamforce to
                  witness the Innovation in action. For them, it was a festival
                  every year celebrated with more than 150,000 attendees.
                </p>
                <p>
                  Sweven will hire Salesforce Practitioners by building products
                  and services. The company will officially begin operations in
                  the year 2022.
                </p>
                <h3 className="title">About Sweven</h3>
                <p>
                  Sweven is a brand new company with 1000+ Man years of CRM and
                  Customer Experience Proficiency. We branched out to craft a
                  new story of Sweveners — The dreamers and doers. Sweven will
                  be addressing and capitalizing on the growing Experience
                  Digital Economy currently estimated to be a US$1 Trillion
                  Opportunity. Sweven will also tap another US$1 Trillion
                  Experience Economy delivering Industry-specific specialized
                  CRM Platforms powered by Public Cloud.
                </p>
                <p>
                  Headquartered in the NCR (India) region, Sweven will be hiring
                  professionals across regions such as USA, UK, Europe,
                  Australia and other parts of India.
                </p>
                <p>
                  The Action-packed Sweven Team accelerates the pivot to growth
                  from design right through to execution, working on clear and
                  transparent targets our customers have in mind. The core team
                  exhibits a "how might we" mindset, combined with a track
                  record of solving the most demanding challenges with ease and
                  codifying success quickly.
                </p>
                <p>
                  For more information, visit{" "}
                  <NavLink
                    to="http://www.sweven.com"
                    target="_blank"
                    className="link"
                  >
                    www.sweven.com
                  </NavLink>
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. is a leading Experience Consultancy and
                  Digital Transformist with its global operations across US, UK,
                  Europe, Middle East and India. Virtuos has four important
                  business operations, namely{" "}
                  <NavLink to={`/consultare/`} target="_blank" className="link">
                    Consultare;
                  </NavLink>
                  <NavLink to={`/xonomy/`} target="_blank" className="link">
                    Xonomy
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to={`/experience-cloud/`}
                    target="_blank"
                    className="link"
                  >
                    Experience Cloud
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Microsoft Dynamics, Creatio Low Code No Code
                  Platform &amp; CRM Applications; Agiloft Contract Lifecycle
                  Management (CLM) amongst other Composable Products &amp;
                  Services. Virtuos Consulting services include Five Fabulous
                  business units namely — Virtuos Vivacis, Virtuos Veracis,
                  Virtuos Vaves, Virtuos Vedam, and Virtuos Vardei.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/xonomy/`} target="_blank" className="link">
                    Xonomy
                  </NavLink>
                  ideates, incubates and invests in the next-generation
                  businesses harnessing
                  <NavLink to={`/xonomy/ai/`} target="_blank" className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  ,Cloud, ECommerce, Blockchain and Digital technologies. At
                  Virtuos Xonomy, we manage new ventures that include VYO — the
                  Metaverse Experiences; Giftcart — Gifting Experiences Commerce
                  Company; Indic — the Portfolio of Experience Commerce Brands;
                  Sweven — Salesforce CRM Mastermind services; and Tekcorp — the
                  Technology Infrastructure and upcoming Tek.shop eCommerce
                  Operations.
                </p>
                <h3 className="title">Virtuos Experience Cloud</h3>
                <p>
                  Virtuos Experience Cloud Business is creating a brand new ABCD
                  Strategy to launch modern Experience Applications stack. We
                  are building AURY, a state of the art AI based Chatbot and
                  AR/VR Systems; Brandcart.com — Experience Commerce Cloud;
                  Cloudle — the lowcode no code platform; and Definite — the
                  Digital Experience Platform (DXP) with integrated Digital
                  Marketing Suite.
                </p>
                <p>
                  For more information visit{" "}
                  <NavLink
                    to="http://www.virtuos.com"
                    target="_blank"
                    className="link"
                  >
                    www.virtuos.com
                  </NavLink>{" "}
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Exonomy_Invests_In_Experience;
