import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Gartner_Global_Marketing_Summit_2015 = () => {
    useEffect(() => {
        document.title = "Virtuos CEO Attends Gartner's Global Marketing Summit in San Diego – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/gartner-global-marketing-summit-2015-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 7 May, 2015</span>
                                <h2 className="article-heading">
                                    Virtuos CEO attends Gartner’s Global Marketing Summit in San Diego
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Gartner has for the first time launched a highly focused Marketing
                                    Summit by inviting more than 1000 Global Marketing Leaders.
                                </p>
                                <p>
                                    “By attending Gartner Marketing Summit, you’re joining a community of
                                    trailblazers, thought leaders and industry experts pushing the bounds
                                    of marketing. This marketing conference is the one place you can
                                    access research-backed sessions, get expert advice on your specific
                                    challenges and interact with colleagues — all in one place, over three
                                    days.” Said Gartner Chair for Marketing Summit.
                                </p>
                                <p>
                                    “Virtuos has allocated an annual budget of over US$200,000 for Gartner
                                    conferences, Advisory services and research services.” Said Venky
                                    Vijay Reddi. “I have attended more than twenty conferences in the last
                                    seven years, and I am thrilled to join the marketing leaders in San
                                    Diego.”
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/gartner-digital-marketing-conference-pic.jpg`}
                                        alt="Gartner Digital Marketing Conference 2015"
                                    />
                                </figure>
                                <br />
                                <p>
                                    <strong>What:</strong> Gartner Digital Marketing Conference 2015{" "}
                                    <br />
                                    <strong>When:</strong> May 5-7, 2015
                                    <br />
                                    <strong>Where:</strong> Manchester Grand Hyatt San Diego, CA
                                </p>
                                <h2 className="title">Keynotes:</h2>
                                <ul>
                                    <li>
                                        <strong>Guest Keynote:</strong> Using Behavioral Economics to Hack
                                        Human Behavior - Dan Ariely, Professor of Psychology &amp;
                                        Behavioral Economics Duke University
                                    </li>
                                    <li>
                                        <strong>Guest Keynote:</strong> UNthink — Rediscover Your Creative
                                        Genius - Erik Wahl, Graffiti Artist, Author and Entrepreneur
                                    </li>
                                    <li>
                                        <strong>Gartner Keynote:</strong> Accelerating Your Digital Journey
                                    </li>
                                </ul>
                                <p>
                                    Gartner for Marketing Leaders Summit is a destination for marketing
                                    excellence, strategy and innovation. It helps organizations like
                                    Virtuos to:
                                </p>
                                <ul>
                                    <li>
                                        Drive your marketing vision, communicate strategy and lead with
                                        confidence.
                                    </li>
                                    <li>
                                        Gain critical insights to navigate global and local market changes
                                        and socioeconomic shifts.
                                    </li>
                                    <li>
                                        Deliver an exceptional customer experience across all channels and
                                        touchpoints.
                                    </li>
                                    <li>
                                        Understand data driven marketing and analytics to achieve your goals
                                        and measure success.
                                    </li>
                                    <li>
                                        Evaluate the vast marketing technology trends and identify the
                                        solutions that support your goals.
                                    </li>
                                    <li>
                                        Identify platforms and tools to influence B2B buying, engagement and
                                        growth.
                                    </li>
                                </ul>
                                <h2 className="title">About Clicksoftware</h2>
                                <p>
                                    ClickSoftware offers automated mobile workforce management and service
                                    optimization solutions for enterprise and small businesses, both for
                                    mobile and in-house resources. They have over 700 employees in offices
                                    in North America, EMEA, and APAC. Gartner has acclaimed Clicksoftware
                                    in its Magic Quadrant report as the market leader in Field Service
                                    Automation. Clicksoftware is being used by most of the Fortune 1000
                                    companies worldwide, ranging from utility companies, telecom,
                                    high-tech companies to Govt. Clicksoftware also has a licensing
                                    agreement with SAP and Salesforce to cater to a large number of
                                    customers. For more information visit{" "}
                                    <NavLink
                                        to="http://www.clicksoftware.com"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.clicksoftware.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media, and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                {/* <div className="twitter-feeds">
                                    <NavLink
                                        className="twitter-timeline"
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        data-tweet-limit={5}
                                        data-height={500}
                                        data-chrome="nofooter noborder"
                                    >
                                        Virtuos on Twitter
                                    </NavLink>
                                </div> */}
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />

                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Gartner_Global_Marketing_Summit_2015