import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import ConnectWithUs from './Forms/ConnectWithUs';

const Cxdatum = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "CX Datum, Data is the new age fuel for CX – Virtuos Digital";
    }, []);
    return (
        <div className="cjourney-page landing-page cxdatum-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <section className="top-hero-product-banner image-title-layout">
                        <figure className="bg-image-wrapper">
                            <img
                                src={`/img/svg/cxdatum-header-bg.svg`}
                                alt="CXDatum Header "
                            />
                        </figure>
                        <div className="product-hero-wrapper">
                            <div className="row page-wrap">
                                <div className="col-4 item-wrapper">
                                    <figure>
                                        <img
                                            src={`/img/svg/icons/cxdatum-logo.svg`}
                                            alt="CXDatum"
                                        />
                                    </figure>
                                    <h3 className="section-heading">
                                        Data is vital to improve CX and Customer 360 Insights
                                    </h3>
                                </div>
                                <div className="col-8 header-img">
                                    <figure></figure>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="breadcrumb-kanaban">
                        <div className="page-wrap breadcrumb-wrapper">
                            <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                <li>
                                    <NavLink to={`/cxnow/cxprimer/`}>CX PRIMER</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxdatum`} className="active">
                                        CX DATUM
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxopia/`}>CXOPIA</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxpyramid/`}>CX PYRAMID</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxprism`}>CX PRISM</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/`}>CXNow Program</NavLink>
                                </li>

                            </ul>
                            <ul className="more-hidden-tabs">
                                <li
                                    className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <i className="fal fa-plus" />  More
                                    {showExtraLinks && (
                                        <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                            <li>
                                                <NavLink to={`/cxnow/cxaudit/`}>CX AUDIT</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/customer-journey/`}>
                                                    CUSTOMER JOURNEY
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/customer-voice/`}>
                                                    CUSTOMER VOICE
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxunity/`}>CX Unity</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxdesk/`}>CXDesk</NavLink>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="cxdatum-container">
                        <h2 className="section-main-heading">
                            DATA IS THE <span className="highlight">NEW AGE FUEL FOR CX</span>
                        </h2>
                        <span className="section-desc">
                            CX Primer lays the foundation for enabling 360-degree customer (C360)
                            insights with the CX Datum initiative.
                        </span>
                        <span className="section-desc">
                            At Virtuos, we take an outcome-centric approach to the definition of
                            requirements by quantifying how customer data is expected to improve the
                            customer experience (CX) and deliver business benefits. Another method is
                            to strike the optimal balance between the two vectors of C360 complexity,
                            namely the required scope of customer data and the governance needed to
                            fulfill a request for C360 insight.
                        </span>
                        <span className="section-desc">
                            The explicit goal of undertaking the CX Datum initiative is to see the
                            structure of data driving C360 insights by understanding the disparate
                            sources of data, the flow, the integration points, and finally, how the
                            Data is deciphered for a purpose.
                        </span>
                    </section>
                    <section className="cxdata-container light-blue-bg">
                        <div className="row page-wrap">
                            <div className="col-7">
                                <h2>Reliance on Data to Improve CX</h2>
                                <figure>
                                    <img
                                        src={`/img/svg/data-improve-cx-diagram-img.svg`}
                                        alt=""
                                    />
                                </figure>
                            </div>
                            <div className="col-5">
                                <h2>Data-Centric CX Improvements</h2>
                                <figure>
                                    <img
                                        src={`/img/svg/data-centric-cx-diagram-img.svg`}
                                        alt=""
                                    />
                                </figure>
                            </div>
                        </div>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <span className="note">Source: Gartner</span>
                            </div>
                        </div>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <p>
                                    There’s an improved reliance on data to improve Customer Experience
                                    (CX) more than ever. The following diagram (left side) from the
                                    Gartner CIO Survey, October 2019, shows the percentage of respondents
                                    rating the extent of scaling at 5, 6, or 7. Data-centric CX
                                    improvements from Gartner’s CDO Circle Poll 2019 reveal the key
                                    obstacles to value creation from Data.
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="genesis-container">
                        <h2 className="section-main-heading">
                            CUSTOMER DATA <span className="highlight">GENESIS</span>
                        </h2>
                        <span className="section-desc">
                            Customer Data is the cornerstone of any business strategy — a collection
                            of data from various sources to manage the data qualitatively across the
                            organization.
                        </span>
                        <span className="section-desc">
                            During our CX Datum initiative as a part of{" "}
                            <NavLink to={`/cxnow/cxprimer/`} className="link">
                                CX Primer
                            </NavLink>
                            , Virtuos Consultants can find the sources of customer data and how the
                            data is utilized within and outside of the organization.
                        </span>
                        <div className="advantages-of-aury ecosystem-section">
                            <div className="page-wrap row">
                                <div className="col-6 points offport-left">
                                    <ul>
                                        <li className="row">
                                            <div className="content-wrapper">
                                                <h3 className="title">Customer Data Type</h3>
                                                <p>
                                                    Organizations maintain vast customer data throughout the
                                                    myriad of data points. For ease of purpose, the data is
                                                    further pruned by the category - personal data, engagement
                                                    data, behavioural data, and attitudinal data. The linkages
                                                    amongst these data types are also ascertained based on data
                                                    sources. Our workshop covers some of the best practices by
                                                    knowing how marketers use demographic data, psychographic
                                                    data, and content assets.
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 offport-right">
                                    <figure className="adjust-height">
                                        <img
                                            src={`/img/customer-data-type-diagram-img.jpg`}
                                            alt="Customer Data Type"
                                        />
                                    </figure>
                                </div>
                            </div>
                            <div className="page-wrap row">
                                <div className="col-6 offport-left">
                                    <figure className="adjust-height">
                                        <img
                                            src={`/img/svg/customer-data-source-diagram-img.svg`}
                                            alt="Customer Data Sources"
                                        />
                                    </figure>
                                </div>
                                <div className="col-6 points offport-right">
                                    <ul>
                                        <li className="row">
                                            <div className="content-wrapper">
                                                <h3 className="title">Customer Data Sources</h3>
                                                <p>
                                                    Five essential sources of data collection include the
                                                    company’s website (or e-commerce portal), non-commerce
                                                    transactions, campaigns, surveys, social media. The data could
                                                    be stored in a variety of systems, namely Web Analytics, CRM,
                                                    Marketing, ERP, Supply chain, HR, and Financial packages.
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="page-wrap row">
                                <div className="col-6 points offport-left">
                                    <ul>
                                        <li className="row">
                                            <div className="content-wrapper">
                                                <h3 className="title">Customer Data Flows</h3>
                                                <p>
                                                    How data is related and linked to each of operations, namely
                                                    website to CRM, CRM to campaigns, transactions to surveys, or
                                                    ERP. Data flows are also determined by the permissions and
                                                    privileges in compliance with the privacy and governance
                                                    policies of the organization and the customers.
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 offport-right">
                                    <figure className="adjust-height">
                                        <img
                                            src={`/img/svg/customer-data-flows-diagram-img.svg`}
                                            alt="Customer Data Flows"
                                        />
                                    </figure>
                                </div>
                            </div>
                            <div className="page-wrap row">
                                <div className="col-6 offport-left">
                                    <figure className="adjust-height">
                                        <img
                                            src={`/img/validation-analysis-img.jpg`}
                                            alt="Validation and Analytics"
                                        />
                                    </figure>
                                </div>
                                <div className="col-6 points offport-right">
                                    <ul>
                                        <li className="row">
                                            <div className="content-wrapper">
                                                <h3 className="title">Validation and Analysis</h3>
                                                <p>
                                                    What are the cycles of validation to verify that the data is
                                                    accurate, usable, and optimized for customer 360 insights? The
                                                    CX program requires a governance model for the data
                                                    classification, association rule mining, clustering, outlier
                                                    detection, regression analysis, and prediction.
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="breaker-container">
                        <figure className="bg-banner">
                            <img src={`/img/rome-breaker-img.jpg`} alt="Rome" />
                        </figure>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <p>
                                    "Rome wasn't built in a day. You need to understand how it comes
                                    together brick-by-brick; how each of the components slots together -
                                    or doesn't!"
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="dexp-container">
                        <h2 className="section-main-heading">
                            CUSTOMER DATA DRIVING <span className="highlight">EXPERIENCE FLOWS</span>
                        </h2>
                        <span className="section-desc">
                            How do you deliver relevant experiences without the foundation of
                            personalization data?
                        </span>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <p>
                                    Our Data teams work with the customer's CDO or Data Experts to
                                    understand the data relationships across four digital dimensions and
                                    by industry and region. Our CXNow advanced services comprise of:
                                </p>
                                <p>
                                    a) benchmark digital performance relative to competitors and
                                    understand strengths and weaknesses;
                                </p>
                                <p></p>
                                <p>
                                    b) identify opportunities to improve digital’s business impact and
                                    prioritize capital allocation;
                                </p>
                                <p>
                                    c) educate teams on best practices and help them stay abreast of
                                    digital developments and disruptions, and
                                </p>
                                <p>
                                    d) measure performance over time to hold teams and vendors
                                    accountable.
                                </p>
                            </div>
                        </div>
                        <div className="row page-wrap">
                            <div className="col-4 item">
                                <div className="header">
                                    <span className="icon">
                                        <img
                                            src={`/img/svg/icons/website-icon.svg`}
                                            alt=""
                                        />
                                    </span>
                                    Site &amp; E-Commerce
                                </div>
                                <ul>
                                    <li>E-Commerce and Account</li>
                                    <li>E-Tailer Visibility and Merchandising</li>
                                    <li>Search &amp; Navigation</li>
                                    <li>
                                        Content &amp; Product Pages: Merchandising, Reviews, Guided Selling
                                    </li>
                                    <li>Customer Service</li>
                                    <li>Technology: Responsiveness and Innovation</li>
                                </ul>
                            </div>
                            <div className="col-4 item">
                                <div className="header">
                                    <span className="icon">
                                        <img
                                            src={`/img/svg/icons/digital-marketing-icon.svg`}
                                            alt=""
                                        />
                                    </span>
                                    Digital Marketing
                                </div>
                                <ul>
                                    <li>Site Traffic &amp; Web Authority</li>
                                    <li>Paid &amp; Organic Brand Search</li>
                                    <li>Paid &amp; Organic Category Search</li>
                                    <li>Desktop Display Advertising Quality and Efficiency</li>
                                    <li>Email Marketing Segmentation and Customization</li>
                                    <li>Earned Media</li>
                                </ul>
                            </div>
                            <div className="col-4 item">
                                <div className="header">
                                    <span className="icon">
                                        <img
                                            src={`/img/svg/icons/social-media-icon.svg`}
                                            alt=""
                                        />
                                    </span>
                                    Social Media
                                </div>
                                <ul>
                                    <li>Reach, Frequency, Engagement, and Content</li>
                                    <li>Promoted and Organic Visibility</li>
                                    <li>Facebook</li>
                                    <li>Instagram</li>
                                    <li>YouTube</li>
                                    <li>Snapchat</li>
                                    <li>Twitter</li>
                                    <li>Emerging &amp; Regional Platforms</li>
                                </ul>
                            </div>
                            <div className="col-4 item">
                                <div className="header">
                                    <span className="icon">
                                        <img
                                            src={`/img/svg/icons/mobile-icon.svg`}
                                            alt=""
                                        />
                                    </span>
                                    Mobile
                                </div>
                                <ul>
                                    <li>Smartphone Experience and Consistency</li>
                                    <li>Organic Search Visibility</li>
                                    <li>Paid Search Effectiveness</li>
                                    <li>Mobile Content &amp; Innovation</li>
                                    <li>Mobile Apps</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="da-container light-blue-bg">
                        <h2 className="section-main-heading">
                            Align <span className="highlight">D&amp;A Strategy</span> With Business
                            Strategy
                        </h2>
                        <span className="section-desc">
                            How do organizations deliver the highest value using data and analytics
                            (D&amp;A)?
                        </span>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <p>
                                    One of the most important tasks that a CDO or other data and analytics
                                    leaders must complete is to turn the demand for data management and
                                    business analytics into actionable strategies to deliver business
                                    value. Virtuos strategy compass as part of CXNow Transformation
                                    Services will guide leaders in the first step of this process — that
                                    is, developing a comprehensive data and analytics strategy.
                                </p>
                                <p>
                                    During the CX Datum initiative, our Consultants will assess, and
                                    garner information on the D&amp;A alignment with the business strategy
                                    on four factors:
                                </p>
                                <ul>
                                    <li>Demonstrable business value while investing in innovation</li>
                                    <li>
                                        How organization plays by the rules by using data and analytics
                                        governance
                                    </li>
                                    <li>
                                        Identification of the technology requirements that fit an
                                        organization's needs
                                    </li>
                                    <li>
                                        The business strategy with an organizational approach that fits
                                        within the organization’s culture
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <figure>
                                    <img
                                        src={`/img/svg/d&a-diagram-img.svg`}
                                        alt="D&A Diagram"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="cf-form-wrapper">
                        <div className="page-wrap ">
                            <h2 className="section-main-heading">
                                <span className="highlight">CXDATUM</span>, AN INTEGRAL PART OF{" "}
                                <span className="highlight">CX PRIMER</span>
                            </h2>
                            <span className="section-desc">
                                Engage with Virtuos Consultants to book a FREE CX Primer workshop to
                                provide smarter insights. For more advanced CX Datum Services engage
                                with CXNow Digital Transformation Consultants.
                            </span>
                            {/* <NavLink
                                rel="enquiry-form"
                                className="btn btn-secondary popup-form-trigger offport-zoomin"
                            >
                                Connect with Us
                            </NavLink> */}
                          < ConnectWithUs/>
                           
                        </div>
                    </section>
                    {/*?php include '../../footer-consultare.php';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Cxdatum
