import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Enveils_A_Major_Rebranding = () => {
    useEffect(() => {
        document.title = "Virtuos Unveils A Major Rebranding Effort That Paves the Way For Creating More Than A Customer Experience – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-rebranding-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 5 February, 2015</span>
                                <h2 className="article-heading">
                                    VIRTUOS UNVEILS A MAJOR REBRANDING EFFORT THAT PAVES THE WAY FOR
                                    CREATING MORE THAN JUST CUSTOMER EXPERIENCE
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Corporation is proud to unveil today its new logo and tagline
                                    — customer @ heart. The redesigned logo reflects fresh innovative
                                    business ideas, incredible transformation services and powerful
                                    engagement platforms.
                                </p>
                                <p>
                                    The brand-new logo comes at an opportune time as it is an incredibly
                                    exciting year for Virtuos, with the company entering the next phase of
                                    growth and massive transformation. This year, Virtuos is trending into
                                    two distinct and diverse operations — one into “Digital Business”
                                    through .iO (Innovation Open) and the other into “Consulting Services”
                                    through Consultare, their Professional Services Division.
                                </p>
                                <ul>
                                    <li>
                                        Virtuos will no longer be a just CRM/CX Consulting Company — it’s
                                        going to be a “Digital Business”.
                                    </li>
                                    <li>
                                        Virtuos Consultare will embrace ABCD of Transformation — Apps; Big
                                        Data &amp; Analytics; Cloud and Digital.
                                    </li>
                                    <li>
                                        Virtuos Digital Business will venture into the newest Internet of
                                        Things with a different set of operations — .iO Labs.
                                    </li>
                                </ul>
                                <p>
                                    Reflecting Virtuos’ founding principles — Virtuosity, Veracity and
                                    Velocity; the new tagline and logo demonstrate clearly and boldly who
                                    Virtuos is, creating connections to the real world, by sensing the
                                    context of its customers.{" "}
                                </p>
                                <p>
                                    “I felt our brand of yesterday was starting to hold back our ability
                                    to go mainstream, and limiting our ideas of what we could become,"
                                    Venky Vijay Reddi, CEO of Virtuos says. "This new branding changes the
                                    whole identity and representation of the company, with us now creating
                                    our products additionally to deliver high-performance consulting
                                    services."
                                </p>
                                <h2 className="title">The Story Behind the Design:</h2>
                                <p>
                                    Designed in-house by Yippee Media — a division of Virtuos, the new
                                    visual identity has three components — the word mark, pictogram and
                                    tagline.
                                </p>
                                <p>
                                    The word mark (Letters) remains unchanged — they have been carved
                                    using its favorite font and the word mark symbolizes perfection and
                                    coherence in authentic blue which stands for something — a big idea, a
                                    strategic position, a defined set of values, a voice that stands
                                    apart.
                                </p>
                                <p>
                                    The heart-shaped V pictogram (symbol) is a vibrant new take on the old
                                    logo. The color hue is inspired by the community it comprises: highly
                                    creative people from all kinds of backgrounds come together, inspire
                                    each other and collaboratively work from their heart to develop a
                                    vision of the future. The new logo, evocative of a human heart,
                                    carries a special meaning of delivering true customer value by
                                    delivering professional services using a co-extend methodology.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-rebranding-logo.jpg`}
                                        alt="Virtuos New Logo"
                                    />
                                </figure>
                                <div className="button">
                                    <NavLink
                                        to="https://www.1888pressrelease.com/virtuos-corporation-unveils-a-major-rebranding-effort-that-p-pr-554722.html"
                                        target="_blank"
                                    >
                                        Read Press Release
                                    </NavLink>
                                </div>

                                <p>
                                    The joined quadrants that were previously separated to represent the
                                    unity of ideologies and goals. The vibrant color palette chosen evokes
                                    a sense of vivacity and vigor.
                                </p>
                                <p>
                                    The tag line — customer @ heart underscores Virtuos commitment to
                                    serving both consumers and customers globally. It identifies the core
                                    motto of the company and highlights Virtuos’ endeavor of putting the
                                    customer at the heart of its business through an array of innovative
                                    Apps, Strategies &amp; Ideas, the Internet of Things and
                                    transformational consulting services.
                                </p>
                                <p>
                                    In the upcoming months Virtuos will replace the old logo with the new
                                    logo on all of its online and offline presence, collaterals,
                                    presentations, etc. This logo might also feature on Virtuos' products
                                    and the merchandise it offers.
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Founded in January of 2008 and based in San Jose US with regional
                                    presence in India, Virtuos is redefining the way brands can be
                                    transformed across web, social and commerce. With a leading edge
                                    customer experience consulting practice, and dozens of iconic brands
                                    in their client list; Virtuos is embarking on major expansion into
                                    digital business by launching the world’s first Definite.com
                                    ExperienceCloud and an apps platform marketplace “Cloudle.com” as well
                                    as the “Internet of Things”. As part of its global expansion, the
                                    company is hiring four senior strategic leaders to head the .iO Labs
                                    and architect new digital products; lead ExperienceCloud Business;
                                    design Solution Consulting Services and deliver high-performance
                                    Consultare Services.
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Enveils_A_Major_Rebranding