import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Team_Attends_Cx_Io_Gartner_Marketing_Summit = () => {
    useEffect(() => {
        document.title = "Virtuos Team Attends Oracle Modern CX in Las Vegas, Gartner Summit in San Diego – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-team-attends-summit-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 12 May, 2017</span>
                                <h2 className="article-heading">
                                    Virtuos Team attends Modern CX, Gartner I&amp;O, and Gartner Marketing
                                    Summit
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    <NavLink
                                        to="https://go.oracle.com/LP=52042?elqTrack=true"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Oracle Modern CX
                                    </NavLink>{" "}
                                    is an annual event held every year in one of the US locations to
                                    showcase the latest suite of Oracle CX products — Sales, Marketing,
                                    Service, and E-commerce. The event was held at Las Vegas between April
                                    25 - 27, 2017, at Mandalay Bay Hotel.
                                </p>
                                <p>
                                    During the conference, Venky Vijay Reddi, CEO accompanied by Amarinder
                                    Singh, Director CX Services, has met a few of the partners, prospects
                                    to showcase Virtuos Global Consultare Practice with CXDesk continuum
                                    services.
                                </p>
                                <p>
                                    On the sidelines, Virtuos has also met YS Lee from Oracle, Ted Bray
                                    from Merkle, and Theodore (Trad) Travis from Gartner separately. One
                                    of the keynotes from Mark Hurd, CEO of Virtuos and Gene Phifer,
                                    Distinguished VP of Gartner.
                                </p>
                                <p>
                                    Gene Phifer spoke on the integrated CX and EX, which Virtuos spoke way
                                    back in 2015 at the Oracle APAC Annual Summit.
                                </p>
                                <p>
                                    Oracle Key Executive (Ex- RightNow Technologies VP), Brian J. Curran
                                    has also showcased The Innovation Journey Map, which has become the
                                    foundation for Virtuos’s Innovation Open (iO) to transform customers
                                    with CX Design Thinking foundation.
                                </p>
                                <div className="video-wrapper offport-down">
                                    <div className="embed-container">
                                        <iframe
                                            src="https://www.youtube.com/embed/KFdgoGum9k8"
                                            frameBorder={0}
                                            allowFullScreen=""
                                        />
                                    </div>
                                    <span>Video Source: Youtube</span>
                                </div>
                                <br />
                                <p>
                                    Virtuos CEO attended Gartner’s Global IT Operations, Strategies &amp;
                                    Solutions Summit held at Hilton Orlando, Florida, between May 8th and
                                    10th, 2017.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-team-attends-modern-cx-pic.jpg`}
                                        alt="Virtuos team attends Oracle Modern CX annual event"
                                    />
                                </figure>
                                <h2 className="title">Some of the key highlights of I&amp;O Event:</h2>
                                <br />
                                <p>
                                    <strong>Guest Keynote:</strong> IT Operations 2020 — Breaking All the
                                    Rules…How Should You React?
                                </p>
                                <ul>
                                    <li>
                                        Theresa Payton, Former White House Chief Information Officer,
                                        Author, and Cyber Security Expert.
                                    </li>
                                </ul>
                                <p>
                                    <strong>Gartner Keynote:</strong> Digital Business Platforms: The
                                    I&amp;O Perspective — Gartner analysts Milind Govekar &amp; Hank
                                    Marquis.
                                </p>
                                <p>
                                    <strong>Gartner Keynote:</strong> The Journey to the Mix: Private
                                    Cloud, Public Cloud, and Edge - Tom Bittman, Gartner Vice President
                                    &amp; Distinguished Analyst.
                                </p>
                                <p>
                                    <strong>Guest Keynote:</strong> Take Your IT Operations Leadership to
                                    the Next Level with Emotional Intelligence - if You Think They are
                                    Soft Skills, Think Again! — Dr. Mitchel Adler, Psy.D, CGP
                                </p>
                                <p>
                                    <strong>Guest Keynote:</strong> Funny Business — Bob Hirschfeld,
                                    Cyber-Satirist
                                </p>
                                <p>
                                    Venky Vijay later attended Gartner’s most awaited Digital Marketing
                                    Summit for the third time earning the “Alumni” status. The event was
                                    hosted at the San Diego Marriott Marquis &amp; Marina.
                                </p>
                                <p>
                                    <strong>
                                        Some of the key highlights of Gartner Summit for Marketers include:
                                    </strong>
                                </p>
                                <ul>
                                    <li>
                                        Keynote from{" "}
                                        <NavLink
                                            to="https://joshlinkner.com"
                                            rel="noreferrer"
                                            target="_blank"
                                            className="link"
                                        >
                                            Josh Linkner
                                        </NavLink>{" "}
                                        - Tech Entrepreneur, Hyper-Growth Leader &amp; Bestselling Author.
                                        Josh also has presented his book to all the attendees.
                                    </li>
                                    <li>
                                        Keynote from{" "}
                                        <NavLink
                                            to="http://www.platonphoto.com"
                                            rel="noreferrer"
                                            target="_blank"
                                            className="link"
                                        >
                                            Platon
                                        </NavLink>{" "}
                                        - World-Renowned, Award-Winning Photographer, The New Yorker, and
                                        Master Storyteller.
                                    </li>
                                </ul>
                                <p>
                                    “The three-day show covered over 150 Sessions and dozens of keynotes.
                                    I rate Gartner Summit for Marketers as the no.1 Marketing Conference
                                    worldwide showcasing the most contemporary innovations on Modern
                                    Digital Marketing.” Said Venky Vijay Reddi, CEO of Virtuos. “The
                                    Summit offers invaluable insight, guidance, and access to the pool of
                                    talented Global Marketing Practitioners.”{" "}
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media, and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Team_Attends_Cx_Io_Gartner_Marketing_Summit