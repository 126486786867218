import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import EnqueryForm from "./Form/EnqueryForm";

const Exonomy_Aura = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };
  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };

  useEffect(() => {
    document.title =
      "Virtuos Experience Cloud | Experience Economy Business – Virtuos Xonomy";
  }, []);
  return (
    <div className="aury-page landing-page rigour-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/svg/aury-header-bg.svg`}
                alt="AI Conversational Systems"
              />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-6 item-wrapper">
                  <div className="top">
                    <figure id="margin1">
                      <img
                        src={`/img/svg/icons/aury-logo.svg`}
                        alt="Aury Logo"
                      />
                    </figure>
                    <div className="heading">CHATBOT</div>
                  </div>
                  <div className="bottom">
                    <h3 className="section-heading">
                      <strong>AI</strong> Conversational Systems
                    </h3>
                    <span>
                      Patented NLP and machine learning (ML) Technologies.
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/aury-chatbot-header-img.svg`}
                      alt="Aury Chatbot"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/xonomy/`}>Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/xonomy/aury/`} className="active">
                    AURY PLATFORM
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud/`}>EXPERIENCE CLOUD</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-crowd/`}>EXPERIENCE CROWD</NavLink>
                </li>
                <li>
                  <NavLink to={`/io/`}>INNOVATION ON</NavLink>
                </li>
              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${
                    showExtraLinks ? "active" : ""
                  } inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" /> More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/business4-0/`}>
                          BUSINESS 4.0 STRATEGY
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/ai/`}>AI</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/agnitor/`}>AGNITOR</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/budh/`}>BUDH XONOMY</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/`}>VIRTUOS VUCA</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/zidd/`}>ZIDD E-LEARNING</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/ventures/`}>VENTURES</NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/experience-crowd#dati`}
                          className="smooth"
                        >
                          DATI DATA CROWD
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="ai-wrapper">
            <h2 className="section-main-heading">
              <span className="highlight">AURY:</span> Scaling the Human With
              Augmented Intelligence
            </h2>
            <span className="section-desc">
              Aury is for increasing productivity and empowering employees in
              Customer Service, IT Helpdesk, and Human Resources (HR).
            </span>
            <span className="section-desc">
              Aury is a new purpose-built digital chatbot serving a myriad of
              channels. We have built the Aury framework to augment humans with
              artificial intelligence (AI), Machine Learning (ML), and Natural
              Language Processing (NL) technologies.
            </span>
            <span className="section-desc">
              Aury is for reaching customers and employees in new contexts
              offering 24x7 non-stop channels that’s intuitive, conversational
              and sophisticated.
            </span>
            <div className="row page-wrap reach-container">
              <div className="col-3 item">
                <h3 className="font-weight600">
                  Pass <br /> the Baton
                </h3>
                <figure id="margin1">
                  <img
                    src={`/img/svg/icons/link-icon.svg`}
                    alt="Pass the Baton Icon"
                  />
                </figure>
                <p>Break down the process into tasks</p>
              </div>
              <div className="col-3 item">
                <h3 className="font-weight600">
                  Go the <br /> Last Mile
                </h3>
                <figure id="margin1">
                  <img
                    src={`/img/svg/icons/flag-icon.svg`}
                    alt="Go the Last Mile Icon"
                  />
                </figure>
                <p>Human in the Loop</p>
              </div>
              <div className="col-3 item">
                <h3 className="font-weight600">
                  Take Me to <br />
                  the Next Level
                </h3>
                <figure id="margin1">
                  <img
                    src={`/img/svg/icons/stairs-icon.svg`}
                    alt="Take Me to the Next Level Icon"
                  />
                </figure>
                <p>Train Me. Show me Possibilities</p>
              </div>
              <div className="col-3 item">
                <h3 className="font-weight600">Symbiosis</h3>
                <figure id="margin1">
                  <img
                    src={`/img/svg/icons/user-connection.svg`}
                    alt="Symbiosis"
                  />
                </figure>
                <p>One is not possible without the other</p>
              </div>
            </div>
          </section>
          <section className="purpose-container">
            <h2 className="section-main-heading">
              AURY, <span className="highlight">BUILT FOR PURPOSE</span>
            </h2>
            <span className="section-desc">
              We work with leading brands like Microsoft, Oracle, Google to
              offer top-notch chatbot deployment options.
            </span>
            <span className="section-desc">
              Most of the chatbots available today are based on the proprietary
              or fixed technologies that do not meet the dynamic and
              transcendent needs of tomorrow. Aury offers broadly three types of
              chatbot implementation choices are as under:
            </span>
            <div className="row page-wrap choice-container">
              <div className="col-4 items-container">
                <h3 className="font-weight600">SaaS-like</h3>
                <ul className="items">
                  <li>
                    <figure id="margin1">
                      <img
                        src={`/img/svg/icons/defined-integrations-icon.svg`}
                        alt="Defined Integrations Icon"
                      />
                    </figure>
                    <p>Defined Integrations</p>
                  </li>
                  <li>
                    <figure id="margin1">
                      <img
                        src={`/img/svg/icons/configuration-gui-icon.svg`}
                        alt="Configuration GUI Icon"
                      />
                    </figure>
                    <p>Configuration GUI</p>
                  </li>
                  <li>
                    <figure id="margin1">
                      <img
                        src={`/img/svg/icons/vertical-capabilities-icon.svg`}
                        alt="Vertical Capabilities Icon"
                      />
                    </figure>
                    <p>Vertical Capabilities</p>
                  </li>
                </ul>
              </div>
              <div className="col-4 items-container">
                <h3 className="font-weight600">PaaS-like</h3>
                <ul className="items">
                  <li>
                    <figure id="margin1" style={{ width: "50%" }}>
                      <img
                        src={`/img/svg/icons/integration-options-icon.svg`}
                        alt="Integration Options Icon"
                      />
                    </figure>
                    <p>Integration Options</p>
                  </li>
                  <li>
                    <figure id="margin1">
                      <img
                        src={`/img/svg/icons/configuration-admin-icon.svg`}
                        alt="Configuration Admin Icon"
                      />
                    </figure>
                    <p>Configuration Admin</p>
                  </li>
                  <li>
                    <figure id="margin1">
                      <img
                        src={`/img/svg/icons/advanced-capabilities-icon.svg`}
                        alt="Advanced Capabilities Icon"
                      />
                    </figure>
                    <p>Advanced Capabilities</p>
                  </li>
                </ul>
              </div>
              <div className="col-4 items-container">
                <h3 className="font-weight600">CRM Extension</h3>
                <ul className="items">
                  <li>
                    <figure id="margin1">
                      <img
                        src={`/img/svg/icons/crm-functionality-icon1.svg`}
                        alt="CRM Functionality Icon"
                      />
                    </figure>
                    <p>CRM Functionality</p>
                  </li>
                  <li>
                    <figure id="margin1">
                      <img
                        src={`/img/svg/icons/data-icon.svg`}
                        alt="Data Icon"
                      />
                    </figure>
                    <p>Data</p>
                  </li>
                  <li>
                    <figure id="margin1">
                      <img
                        src={`/img/svg/icons/integrated-admin-icon.svg`}
                        alt="Integrated Admin Icon"
                      />
                    </figure>
                    <p>Integrated Admin</p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="channel-container">
            <h2 className="section-main-heading">NEW CHANNELS</h2>
            <span className="section-desc">
              The broad channels of conversational mediums include chatbot for
              messaging, Virtual Personal Assistants (VPA), Alexa or Google
              Voice, or Cortana powered devices.
            </span>
            <span className="section-desc">
              Implementation of a chatbot is more critical than technology. At
              Virtuos, we work with customers by building on a chatbot strategy
              capturing the significant touch-points of both customer and
              employee journeys by starting small.
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <h3>How do we go with our Chatbot Strategy?</h3>
                <ul>
                  <li>Pick up a specialized use-case</li>
                  <li>Expose to limited Audience</li>
                  <li>Consider specialist users as the best option</li>
                  <li>Prioritize if it’s informational OR transactional</li>
                  <li>Define and scope to limit the need for integration</li>
                  <li>Gauge user experience analytics and improve</li>
                </ul>
              </div>
            </div>
            <div className="row page-wrap chat-strategy">
              <div className="col-3">
                <figure id="margin1">
                  <img src={`/img/gc-conversation-screen.jpg`} alt="" />
                </figure>
                <p>
                  <strong>Messaging</strong>
                </p>
              </div>
              <div className="col-3">
                <figure id="margin1">
                  <img src={`/img/lunch-with-venky-screen.jpg`} alt="" />
                </figure>
                <p>
                  <strong>Virtual Personal Assistants (VPA)</strong>
                </p>
              </div>
              <div className="col-3">
                <figure id="margin1">
                  <img src={`/img/alexa-speaker-img.jpg`} alt="" />
                </figure>
                <p>
                  <strong>Voice Enabled Speakers</strong>
                </p>
              </div>
              <div className="col-3">
                <figure id="margin1">
                  <img src={`/img/cortana-screen.jpg`} alt="" />
                </figure>
                <p>
                  <strong>New Appliances</strong>
                </p>
              </div>
            </div>
          </section>
          <section className="poll-container grey-bg">
            <h2 className="section-main-heading">
              DEPLOYING <span className="highlight">AURY CHATBOT</span>
            </h2>
            <span className="section-desc">
              At Aury, we offer configuration, admin tools, accelerator,
              operational services. We also offer flexibility, customization,
              operational control, and integration options.
            </span>
            <span className="section-desc">
              <strong>Have you deployed a Chatbot or Virtual Assistant?</strong>
            </span>
            <div className="row page-wrap poll-block">
              <div className="col-4">
                <label>
                  <input
                    type="radio"
                    name="PollInput"
                    defaultValue="No"
                    id="Poll_0"
                    className="popup-form-trigger"
                    rel="enquiry-form"
                  />
                  No
                </label>
                <label>
                  <input
                    type="radio"
                    name="PollInput"
                    defaultValue="We are planning to"
                    id="Poll_2"
                    className="popup-form-trigger"
                    rel="enquiry-form"
                  />
                  We are planning to
                </label>
                <label>
                  <input
                    type="radio"
                    name="PollInput"
                    defaultValue="We have done one or several Proof-of-concepts"
                    id="Poll_3"
                    className="popup-form-trigger"
                    rel="enquiry-form"
                  />
                  We have done one or several Proof-of-concepts
                </label>
                <label>
                  <input
                    type="radio"
                    name="PollInput"
                    defaultValue="We have deployed in Production"
                    id="Poll_4"
                    className="popup-form-trigger"
                    rel="enquiry-form"
                  />
                  We have deployed in Production
                </label>
              </div>
            </div>
            <span className="section-desc">
              <strong>What kind of Conversation is Needed?</strong>
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <figure id="margin1">
                  <img
                    src={`/img/svg/conversation-diagram-img.svg`}
                    alt="Conversation Diagram"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="ai-robot-container">
            <h2 className="section-main-heading">
              Manage the Human Relationship to{" "}
              <span className="highlight">AI and Robots</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12 items-container">
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/technology-tool-icon.svg`}
                      alt="Technlogy as tool icon"
                    />
                  </figure>
                  <h3>
                    Technology
                    <br />
                    as tool
                  </h3>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img src={`/img/svg/icons/arrow-icon.svg`} alt="" />
                  </figure>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/technology-subordinate-icon.svg`}
                      alt="Technology as a subordinate icon"
                    />
                  </figure>
                  <h3>
                    Technology as a<br />
                    subordinate
                  </h3>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img src={`/img/svg/icons/arrow-icon.svg`} alt="" />
                  </figure>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/technology-social-icon.svg`}
                      alt="Technology as a social peer icon"
                    />
                  </figure>
                  <h3>
                    Technology as a<br />
                    social peer
                  </h3>
                </div>
              </div>
            </div>
            <div className="row page-wrap content-block">
              <div className="col-5 item">
                <h3>Customer Issues</h3>
                <ul>
                  <li>Trust, safety</li>
                  <li>Attitude to "things" and "intelligence"</li>
                  <li>Weaker personal relationships</li>
                </ul>
              </div>
              <div className="col-5 item">
                <h3>Employee Issues</h3>
                <ul>
                  <li>
                    New working practices and skills, deskilling of some roles
                  </li>
                  <li>
                    Change in field workforce role from "heroes" to "replacers"
                  </li>
                  <li>Opaque systems and recommendations</li>
                </ul>
              </div>
            </div>
          </section>
          <section className="breaker-container">
            <div className="row">
              <div className="col-2 content-block">
                <h3>Language Support is not a checkbox!</h3>
                <p>
                  Dialects, Misspellings, Slang, Vocabulary for Domain,
                  Translation and Feature Compatibility all matter.
                </p>
              </div>
              <div className="col-4 img-block">
                <figure id="margin1">
                  <img
                    src={`/img/language-support-img.jpg`}
                    alt="Language Support"
                  />
                </figure>
              </div>
              <div className="col-4 img-block">
                <figure id="margin1">
                  <img
                    src={`/img/scalability-img.jpg`}
                    alt="Scalability Matters"
                  />
                </figure>
              </div>
              <div className="col-2 content-block">
                <h3>Scalability Matters</h3>
                <p>Intents, Users, Locales, Languages, Use Cases...</p>
              </div>
            </div>
          </section>
          <section className="ai-robot-container">
            <h2 className="section-main-heading">
              Aury Chatbot{" "}
              <span className="highlight">Professional Services</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <h3>
                  Configuration, Admin Tools, Accelerator, Operational Services{" "}
                </h3>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12 items-container">
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/config-icon.svg`}
                      alt="Config icon"
                    />
                  </figure>
                  <p>
                    <strong>Config</strong>
                  </p>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/admin-icon.svg`}
                      alt="Admin icon"
                    />
                  </figure>
                  <p>
                    <strong>Admin</strong>
                  </p>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/accelerator-icon.svg`}
                      alt="Accelerator icon"
                    />
                  </figure>
                  <p>
                    <strong>Accelerator</strong>
                  </p>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/analytics-icon.svg`}
                      alt="Analytics icon"
                    />
                  </figure>
                  <p>
                    <strong>Analytics</strong>
                  </p>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/support-icon.svg`}
                      alt="Support icon"
                    />
                  </figure>
                  <p>
                    <strong>Support</strong>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="ai-robot-container grey-bg">
            <h2 className="section-main-heading">
              Aury <span className="highlight">Chatbot Framework</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <h3>
                  Flexibility, Custmization, Operational Control, Integration
                  Options
                </h3>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12 items-container">
                <div className="item">
                  <figure id="margin1">
                    <img src={`/img/svg/icons/nlp-icon.svg`} alt="NLP icon" />
                  </figure>
                  <p>
                    <strong>NLP</strong>
                  </p>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img src={`/img/svg/icons/data-icon.svg`} alt="Data icon" />
                  </figure>
                  <p>
                    <strong>Data</strong>
                  </p>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/conversation-icon.svg`}
                      alt="Conversation icon"
                    />
                  </figure>
                  <p>
                    <strong>Conversation</strong>
                  </p>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/logic-icon.svg`}
                      alt="Logic icon"
                    />
                  </figure>
                  <p>
                    <strong>Logic</strong>
                  </p>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/integration-icon.svg`}
                      alt="Integration icon"
                    />
                  </figure>
                  <p>
                    <strong>Integration</strong>
                  </p>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/multilanguage-icon.svg`}
                      alt="Multilanguage icon"
                    />
                  </figure>
                  <p>
                    <strong>Multilanguage</strong>
                  </p>
                </div>
                <div className="item">
                  <figure id="margin1">
                    <img
                      src={`/img/svg/icons/channels-icon.svg`}
                      alt="Channels icon"
                    />
                  </figure>
                  <p>
                    <strong>Channels</strong>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="cf-form-wrapper">
            <div className="page-wrap ">
              <h2 className="section-main-heading">
                CONSIDER{" "}
                <span className="highlight">MULTI-EXPERIENCE CHATBOT</span>
              </h2>
              <span className="section-desc">
                We deliver intuitive User Experience (UX) for making a dialogue
                with personality and cultural nuance.
              </span>
              <span className="section-desc">
                We work with the organizations by aligning Chatbot use cases
                with business metrics, organizing AURY like their product – for
                continuous improvement, and with a permanent team that measures
                and improves.
              </span>

              <EnqueryForm />
            </div>
          </section>
          {/*?php include '../../footer-xonomy.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Exonomy_Aura;
