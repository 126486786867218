import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Cx_Audit = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "CX Audit | Customer Experience Audit Services – Virtuos Digital";
    }, []);
    return (
        <div className="cxaudit-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <section className="top-hero-product-banner image-title-layout">
                        <div className="product-hero-wrapper">
                            <div className="row page-wrap">
                                <div className="col-12">
                                    <figure>
                                        <img
                                            src={`/img/svg/front-big-screen-data-analysis.svg`}
                                            alt="CX Audit and Metrics"
                                        />
                                    </figure>
                                    <figure className="right-fixed-logo">
                                        <img
                                            src={`/img/svg/cx-audit-logo.svg`}
                                            alt="CX Audit Logo"
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="breadcrumb-kanaban">
                        <div className="page-wrap breadcrumb-wrapper">
                            <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                <li>
                                    <NavLink to={`/cxnow/`}>CXNow Program</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxaudit/`} className="active">
                                        CX Audit
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxprism/`}>CX PRISM</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/customer-journey/`}>
                                        Customer Journey
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/customer-voice/`}>
                                        CUSTOMER VOICE
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxunity/`}>CX UNITY</NavLink>
                                </li>
                               
                            </ul>
                            <ul className="more-hidden-tabs">
                                <li
                                    className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <i className="fal fa-plus" />  More
                                    {showExtraLinks && (
                                        <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                          <li>
                                    <NavLink to={`/cxnow/cxprimer/`}>CX PRIMER</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxopia/`}>CXOPIA</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxpyramid/`}>CX PYRAMID</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxdatum/`}>CX DATUM</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxdesk/`}>CXDesk</NavLink>
                                </li>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="cxaudit-wrapper">
                        <h2 className="section-main-heading">
                            CX <span className="highlight bold-class" >AUDIT AND METRICS</span>
                        </h2>
                        <span className="section-desc">
                            CX Audit is one of the seven building blocks of CXNow – The Customer
                            Experience Transformation Program at Virtuos. CX Audit is built on PLUS —
                            Pace Layered Umbrella Strategy Foundation.
                        </span>
                    </section>
                    <div className="xd-process">
                        <span className="section-desc">
                            Measuring the Customer Experience Successfully Requires Three Conditions
                            to Be Met.
                        </span>
                        <div className="page-wrap row process-number offport-up">
                            <div className="col-4 item">
                                <span>1</span>
                            </div>
                            <div className="col-4 item">
                                <span>2</span>
                            </div>
                            <div className="col-4 item">
                                <span>3</span>
                            </div>
                        </div>
                        <div className="page-wrap row process-items offport-down">
                            <div className="col-4 item">
                                <p>Make managing down as important as managing up</p>
                            </div>
                            <div className="col-4 item">
                                <p>Be inclusive of metrics from all parts of the organization</p>
                            </div>
                            <div className="col-4 item">
                                <p>Look at the emotional, not just the rational</p>
                            </div>
                        </div>
                    </div>
                    <section className="advantages-of-aury ecosystem-section">
                        <div className="page-wrap row gen">
                            <div className="col-6 offport-left">
                                <figure className="illus">
                                    <img
                                        src={`/img/svg/cx-matrics-img.svg`}
                                        alt="CX Metrics"
                                    />
                                </figure>
                            </div>
                            <div className="col-6 points offport-right">
                                <h3 className="section-main-heading">
                                    CX <span className="highlight">Metrics</span>
                                </h3>
                                <span className="section-desc">What’s Commonly Measured for CX</span>
                                <p className="app-p">
                                    Customer Satisfaction (C-SAT) is still the no.1 most common metric —
                                    currently, 57% of surveyed companies (source: Gartner as in the
                                    figure) used customer satisfaction scores to measure customer
                                    experience. When it comes to Net Promoter Score (NPS) — most often
                                    C-Level executives are measured for CX performance is used by 31% of
                                    large companies of size $3B+ annual revenue.
                                </p>
                                <p className="app-p">
                                    About 23% of companies mostly mid to small-sized companies (Under
                                    $500M revenue) track metrics for customer repeat orders. About 20% of
                                    the companies — More likely to be large companies track CX metrics for
                                    brand reputation and about 21% of them for Customer Life-Time Value
                                    (CLTV).
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="advantages-of-aury ecosystem-section">
                        <div className="page-wrap row gen">
                            <div className="col-6 points offport-left">
                                <h3 className="section-main-heading">
                                    Managing <span className="highlight">Up</span>
                                </h3>
                                <span className="section-desc">
                                    The Pros and Cons of Net Promoter Score
                                </span>
                                <p className="app-p">
                                    Net Promoter Score = "Percentage of customers who would recommend a
                                    company (promoters) minus the percentage that would urge friends to
                                    stay away (detractors)"
                                </p>
                                <div className="row">
                                    <div className="col-6">
                                        <ul>
                                            <li>Challenges:</li>
                                            <li>Achieving consistency - Consistency</li>
                                            <li>Suitability at touchpoints - Causation</li>
                                            <li>Closing the loop - Anonymity</li>
                                            <li>Getting buy-in - Integrity</li>
                                            <li>No financial link proven - ROI</li>
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <ul>
                                            <li>Strengths:</li>
                                            <li>Higher response rates - Simplicity</li>
                                            <li>Executive understanding - Frequency</li>
                                            <li>Benchmark-able - Comparable</li>
                                            <li>15- year old track record - History</li>
                                            <li>Captures relationship - Actionable</li>
                                        </ul>
                                    </div>
                                </div>
                                <p>
                                    <em>
                                        NPS is invented by Satmetrix (now part of NICE). Virtuos consultants
                                        are certified on NPS.
                                    </em>
                                </p>
                            </div>
                            <div className="col-6 illus offport-right">
                                <figure>
                                    <img
                                        src={`/img/svg/net-promoter-score-img.svg`}
                                        alt="Pros and Cons of Net Promoter Score"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="digital-human-wrapper">
                        <div className="page-wrap row">
                            <div className="col-3 offport-left">
                                <figure>
                                    <img
                                        src={`/img/svg/cx-audit-logo-white.svg`}
                                        alt="CX Audit Logo"
                                    />
                                </figure>
                            </div>
                            <div className="col-6 offport-down">
                                CX Audit of your brand is very critical. Initiate CX Primer for free
                                with our consultants.
                            </div>
                            <div className="col-3 offport-right">
                                <NavLink className="btn btn-primary" to={`/contact/`}>
                                    Contact Us
                                </NavLink>
                            </div>
                        </div>
                    </section>
                    <section className="nps-wrapper">
                        <div className="page-wrap">
                            <h2 className="section-main-heading">
                                Innovation Beyond <span className="highlight">NPS</span>
                            </h2>
                            <span className="section-desc">
                                It’s increasingly seen “NPS” is most talked about CX metric, but in
                                reality, there are many other measures such as CES, WoMI, BAI to audit
                                your customer experience.
                            </span>
                            <div className="two-col-section">
                                <div className="row ces">
                                    <div className="col-6 illus offport-left">
                                        <figure>
                                            <img
                                                src={`/img/svg/customer-effort-score-img.svg`}
                                                alt="Customer Effort Score"
                                            />
                                        </figure>
                                    </div>
                                    <div className="col-6 item-wrap offport-right">
                                        <div className="content-wrap">
                                            <h3 className="section-heading">CES</h3>
                                            <p>
                                                Customer Effort Score (CES) from CEB (Now part of Gartner) is
                                                measured by asking a single question scored on a scale from 1
                                                (very low effort) to 5 (very high effort):
                                            </p>
                                            <p>
                                                <em>
                                                    – "How much effort did you personally have to put forth to
                                                    handle your request?"
                                                </em>
                                            </p>
                                            <p>
                                                CEB claim CES is a better predictor of loyalty than CSAT or NPS.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="two-col-section">
                                <div className="row womi">
                                    <div className="col-6 item-wrap offport-left">
                                        <div className="content-wrap">
                                            <h3 className="section-heading">WoMI</h3>
                                            <p>
                                                Word of Mouth Index (WoMI) from ForeSee is measured by asking
                                                both about willingness to recommend and a second question:
                                            </p>
                                            <p>
                                                <em>
                                                    – "How likely are you to discourage others from doing business
                                                    with this company?"
                                                </em>
                                            </p>
                                            <p>
                                                WoMI research claims that on average, NPS overstates detractors
                                                for the most prominent brands by 780%.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-6 illus offport-right">
                                        <figure>
                                            <img
                                                src={`/img/svg/word-of-mouth-index-img.svg`}
                                                alt="Word of Mouth Index"
                                            />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="two-col-section">
                                <div className="row ces">
                                    <div className="col-6 illus offport-left">
                                        <figure>
                                            <img
                                                src={`/img/svg/brand-advocacy-index-img.svg`}
                                                alt="Brand Advocacy Index (BAI)"
                                            />
                                        </figure>
                                    </div>
                                    <div className="col-6 item-wrap offport-right">
                                        <div className="content-wrap">
                                            <h3 className="section-heading">BAI</h3>
                                            <p>
                                                Brand Advocacy Index (BAI) from Boston Consulting Group is
                                                measured using a weighted calculation of spontaneous and
                                                nonspontaneous advocates, neutrals, nonspontaneous and
                                                spontaneous critics of a brand.
                                            </p>
                                            <p>
                                                BCG claims there is a strong positive correlation between BAI
                                                and top-line growth — double that of other measures of advocacy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="customer-voice-wrapper">
                        <div className="page-wrap row item-container offport-down">
                            <div className="col-5-half item">
                                <div className="col-5">
                                    <figure>
                                        <img
                                            src={`/img/good-cx-img.jpg`}
                                            alt="Good Customer Experience"
                                        />
                                    </figure>
                                </div>
                                <div className="col-7">
                                    <h4>Good CX</h4>
                                    <p>
                                        How the Customer Measures You: Facets of a Good Customer Experience
                                        (CX)
                                    </p>
                                </div>
                            </div>
                            <div className="col-5-half item">
                                <div className="col-5">
                                    <figure>
                                        <img
                                            src={`/img/bad-cx-img.jpg`}
                                            alt="Bad Customer Experience"
                                        />
                                    </figure>
                                </div>
                                <div className="col-7">
                                    <h4>Bad CX</h4>
                                    <p>
                                        How the Customer Measures You: Facets of a Bad Customer Experience
                                        (CX)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="next-steps-wrapper offport-down">
                        <div className="page-wrap">
                            <h2 className="section-heading">
                                <span className="highlight">RECOMMENDATIONS</span>
                            </h2>
                            <div className="row">
                                <div className="col-4 item app-p">
                                    <p>
                                        <i className="far fa-long-arrow-right" /> Avoid focusing only on one
                                        top-level customer experience metric.
                                    </p>
                                    <p>
                                        <i className="far fa-long-arrow-right" /> Create a hierarchy of
                                        customer experience metrics in a dashboard.
                                    </p>
                                </div>
                                <div className="col-4 item app-p">
                                    <p>
                                        <i className="far fa-long-arrow-right" /> Work with the strengths of
                                        NPS and try to minimize the downsides;
                                    </p>
                                    <p>
                                        <i className="far fa-long-arrow-right" /> Don't ignore the most
                                        neglected categories: Quality and employee engagement.
                                    </p>
                                </div>
                                <div className="col-4 item app-p">
                                    <p>
                                        <i className="far fa-long-arrow-right" /> Watch metrics across the
                                        whole organization, not just those tracked in one or two
                                        departments.
                                    </p>
                                    <p>
                                        <i className="far fa-long-arrow-right" /> Beware of only looking at
                                        rational metrics — also keep an eye on how customer emotions are
                                        being measured.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*?php include '../../footer-consultare.php';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Cx_Audit
