import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Opens_New_Office_In_Mumbai = () => {
    useEffect(() => {
        document.title = "Virtuos Solutions opens a new office in Mumbai, the Commercial Capital of India – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-opens-new-office-in-mumbai-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Mumbai, 06 June 2008</span>
                                <h2 className="article-heading">
                                    Virtuos Solutions opens a new office in Mumbai, the Commercial Capital
                                    of India
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Solutions expands with one more Office to cater to the
                                    Mumbai-WEST region closer to BPOs, BFSI, and ITES Customers in India.
                                    Strategically located at Malad West, the new branch office will be
                                    providing space to more than 15 employees and serve the needs of our
                                    expanding list of customers in the Mumbai region.
                                </p>
                                <p>
                                    The company has invested about Rs. One million in this Office with
                                    premium furniture from a leading brand–like and fully-fitted Air
                                    Conditioners from Hitachi. The company also has a four-seater meeting
                                    room, Pantry with Water dispenser, Coffee/Tea machine, and attached
                                    Toilet. Besides, the company has installed Panasonic EPABX, bringing
                                    telephony automation for the employees to make and receive calls from
                                    the customers. Other equipment includes HP Multifunction Printer, Fire
                                    alarm safety equipment, and ambient lighting offering ample space for
                                    storing files, stationery, and other office automation consumables.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/mumbai-office-pics.jpg`}
                                        alt="Virtuos Solutions, Mumbai Office"
                                    />
                                </figure>
                                <br />
                                <p>
                                    The office has a small lounge with a sofa set for the employees to
                                    relax and rejuvenate during the break. The office is situated at the
                                    prestigious complex surrounded by commercial buildings and financial
                                    services and BPO companies.
                                </p>
                                <p>
                                    “With the frequent growing visits to our strategic customers and
                                    prospects in Mumbai, it’s essential to develop Mumbai as an
                                    independent market catering to large Enterprise Customers, including
                                    Leading Financial Services companies, Business Process Outsourcing
                                    (BPO) companies, and industrial conglomerates. Said Venky Vijay Reddi
                                    Mumbai businesses also contribute 40% of India’s total economy in
                                    terms of sheer size and growth”.
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based out
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book — Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three important needs
                                    of growing businesses — Sales, Customer Service, and Marketing.
                                    Virtuos has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    )
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">18/01/2008</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            Tekcorp Office Building, <br />F Block Okhla Industrial Area -
                                            Phase 1, <br />
                                            New Delhi 110020 India
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Branch</span>
                                        <span className="txt">Hyderabad</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                                www.virtuos.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">10-20</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Opens_New_Office_In_Mumbai