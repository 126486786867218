import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';

const slides = [
  {
    title: 'Qualtrics Customer XM',
    description: "Hear every customer's voice, fix every broken experience, and increase customer loyalty and spend.",
    imageUrl: `/img/qualtrics-slider-img-01.jpg`,
  },
  {
    title: 'Qualtrics XM Services',
    description: 'Expert designed programs. White-glove implementation and management.',
    imageUrl: `/img/qualtrics-slider-img-02.jpg`,
  },
  {
    title: 'Virtuos CXNow Program',
    description: 'Deliver integrated CX Program for Qualtrics. Top-notch CX Continuum for every vertical.',
    imageUrl: `/img/qualtrics-slider-img-03.jpg`,
  },
];

const CustomPrevArrow = ({ onClick }) => (
  <div className="prev-arrow" onClick={onClick}>
    <FontAwesomeIcon icon={faChevronLeft} />
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div className="next-arrow" onClick={onClick}>
    <FontAwesomeIcon icon={faChevronRight} />
  </div>
);

const QualtricsSlider = () => {
  const textStyle = {
    textAlign: "left",
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <div className="slider-slide" key={index}>
          <div className="slider-slide-inner" style={textStyle}>
            <div className="slider-content">
              <div className="slider-block">
                <div className="slider-title">{slide.title}</div>
                <p className="slider-description">{slide.description}</p>
              </div>
            </div>
            <div className="slider-image-container">
              <img src={slide.imageUrl} alt={slide.title} className="slider-image" />
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default QualtricsSlider;
