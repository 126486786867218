import React, { useEffect, useState } from 'react'

import { NavLink } from "react-router-dom";
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Nav from '../../../components/Nav';
import ApplyForJob from './Forms/ApplyForJob';
import CommonApplyForJob from './CommonApplyForJob';
import { NavigateNext } from '@mui/icons-material';

const Graphic = () => {
    useEffect(() => {
        document.title = "Career Opportunities | Microsoft .Net Developer – Virtuos Jobs";
    }, []);
    return (
        <div className="careers-job-detail-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    {/* Careers Job Detail */}
                    <section className="job-detail-wrapper">
                        <div className="row page-wrap">
                        <div class="col-9 job-details">
						<div class="job-header">
							<div class="profile-illustration">
								<figure>
									<img src={`/img/svg/graphic-design-career-img.svg`} alt="Web & Graphics Designer Job"/>
								</figure>
							</div>
							<div class="profile-detail">
								<h2 class="section-heading">Web &amp; Graphics Designer</h2>
								<ul class="job-attributes">
									<li class="">
										<strong>Location:</strong>
										<span>Gurgaon</span>
									</li>
									<li class="">
										<strong>Experience:</strong>
										<span>4-8 Years</span>
									</li>
									<li class="">
										<strong>No. of Positions:</strong>
										<span>2</span>
									</li>
									<li class="">
										<strong>Qualification:</strong>
										<span>BE/BTech/ME/MTech/MS/MCA</span>
									</li>
								</ul>
							</div>
						</div>
						
						<h3 class="title">Job Description</h3>
						<p>We are looking for a talented Web &amp; Graphics Designer to create amazing user experiences.  The ideal candidate should have an eye for clean and artful web design. They should also have superior user interface design skills.</p>
						<p>The successful candidate will be able to translate high-level requirements into interaction flows and artifacts. They will be able to transform them into beautiful, intuitive, and functional designs.</p>

						<h3 class="title">Responsibilities</h3>
						<ul class="list">
							<li>Create wireframes, storyboards, user flows, process flows, and site maps to communicate interaction and design ideas</li>
							<li>Testing and improving the design of the website</li>
							<li>Establishing design guidelines, standards, and best practices</li>
							<li>Maintaining the appearance of websites by enforcing content standards</li>
							<li>Designing visual imagery for websites and ensuring that they are in line with branding for clients</li>
							<li>Working with different content management systems</li>
							<li>Communicating design ideas using user flows, process flows, site maps, and wireframes</li>
							<li>Incorporating functionalities and features into websites</li>
							<li>Designing sample pages including colors and fonts</li>
							<li>Preparing design plans and presenting the website structure</li>
						</ul>
						
						<h3 class="title">Desired Skills, Experience &amp; Abilities</h3>
						<ul class="list">
							<li>Proficiency in graphic design software including Coral draw, Adobe Photoshop, Adobe Illustrator, and other visual design tools</li>
							<li>Proficiency in front-end development web programming languages such as HTML and CSS, JQuery, and JavaScript</li>
							<li>Experience working in an Agile/Scrum development process</li>
							<li>Good understanding of content management systems</li>
							<li>Good understanding of search engine optimization principles</li>
							<li>Proficient understanding of cross-browser compatibility issues</li>
							<li>Excellent visual design skills</li>
							<li>Up-to-date experience with international web protocols, standards, and technologies</li>
							<li>Creative and open to new ideas</li>
							<li>Adaptable and willing to learn new techniques</li>
							<li>Excellent communication skills</li>
						</ul>
						
						<h3 class="title">Advantages of Working@Virtuos</h3>
						<ul class="list">
							<li>Virtuos has deep expertise in CRM and Customer Experience Consulting - It’s likely to become largest Applications Business valued at US$45B per year. Candidates will gain significant exposure from our best practices, top-notch alliances, and direct hands-on interaction with leading brands</li>
							<li>The candidates will have exposure to the cutting edge solutions in broad based verticals</li>
							<li>The role also accords a deep understanding of Customer's business processes, and technology adaptation providing an ample room for candidate's overall growth</li>
							<li>Global exposure and working on best business practices with MNC and Enterprise/Corporate Customers in diverse verticals</li>
						</ul>
						
					</div>
                            <div className="col-3 job-application-wrapper sticky-content">
                             <CommonApplyForJob/>
                            </div>
                        </div>
                    </section>
                   
                    {/*?php include '../../footer.php';?*/}
                    <Footer/>
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Graphic
