import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

const Virtuos_Attends_Bpmonline = () => {
  useEffect(() => {
    document.title =
      "Virtuos Team on Tour Attending Global Summits – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-attends-bpmonline-summit-img-2018-big.jpg`}
                  alt=""
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">
                  Boston, San Diego, London, 17 May 2018
                </span>
                <h2 className="article-heading">
                  Virtuos attends BPM’Online Global Partner Summit Accelerate
                  2018: Gartner Digital Marketing Summit and Gartner CX Strategy
                  Summit
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos has signed a partnership with BPM’Online, a leading
                  Midsized CRM Player catering to over 100,000 organizations
                  worldwide. BPM’Online has invited Virtuos to attend its Annual
                  Summit for Partners (Accelerate) at Boston, MA.
                </p>
                <p>
                  Virtuos Team received a warm reception from the Senior
                  Management at BPM’Online and their keen support to Virtuos for
                  expanding principal business.
                </p>
                <p>
                  “After I met with the CEO of BPM’Online at the event, we were
                  able to clinch a deal with NIIT, a leading provider of
                  Information Services in India.” Said Venky Vijay Reddi. “I am
                  finding BPM’Online executive management to be dynamic and
                  agile with quick turn-around.”
                </p>
                <p>
                  Virtuos Team attended Gartner Digital Marketing Summit/XPO for
                  the fourth time. The event was held between May 15 -17, 2018,
                  at Grand Hyatt San Diego, attracting more than 1800
                  Professionals from all over the world.
                </p>
                <p>
                  The conference agenda featured 4 comprehensive tracks taking a
                  deep dive into a broad range of topics focused on the most
                  pressing issues for marketing leaders today:
                </p>
                <ul>
                  <li>Connecting Vision, Strategy and Execution</li>
                  <li>
                    Beyond Knowing the Customer: Using Insight to Drive
                    Engagement
                  </li>
                  <li>
                    Made to Measure: The Power of Metrics to Improve Marketing
                  </li>
                  <li>Secrets of Marketing Mavericks and Masters</li>
                </ul>
                <h3 className="title">Keynotes</h3>
                <ul>
                  <li>
                    Guest Keynote: Smart Influence: How You Affect the
                    Decisions, Desires and Opinions of Others: Tali Sharot,
                    Author, and Professor, Cognitive Neuroscience
                  </li>
                  <li>
                    Guest Keynote: Embrace the Shake: Transforming Limitations
                    into Opportunities: Phil Hansen, Artist, and Author
                  </li>
                </ul>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Corporation is a leading Customer Experience
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos Consultare delivers industry-specific counsel and the
                  integrated, end-to-end solutions that support its customers'
                  strategic, operational, and financial goals. Virtuos is an
                  Experience consultancy that provides superior customer moments
                  and implements leading CRM and Customer Experience
                  Applications of its Alliance Partners. Other services include
                  Our Industry 4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/cxdesk/`} className="link">
                    CX Desk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Consulting Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program covers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  On the 10<sup>th</sup> Anniversary, Virtuos created a new
                  division — Virtuos Xonomy. Virtuos Xonomy ideates and
                  incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/xonomy/ai/`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Attends_Bpmonline;
