import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import Subscribe from './Forms/Subscribe';
import SubscribeNow from './SubscribeNow';

const Venky_Vijay_Speaks_To_The_Press = () => {
    useEffect(() => {
        document.title = "Virtuos | Venky Vijay speaks to the press at the Oracle Cloud Event in Bengaluru";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/oracle-cloud-event-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Bengaluru - 25 July, 2012</span>
                                <h2 className="article-heading">
                                    Venky Vijay speaks to the press at the Oracle Cloud Event in Bengaluru
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Oracle hosted a Cloud Event in Bengaluru on July 25th 2012 by inviting
                                    both the press &amp; media and key customers showcasing the modern
                                    stack of Oracle Cloud Applications and Platforms.
                                </p>
                                <p>
                                    Oracle has invited Venky Vijay Reddi, the CEO of Virtuos Solutions to
                                    address the customers and the media on some of the success stories
                                    Virtuos has built being a pioneer and leader in the space of Oracle CX
                                    Cloud.
                                </p>
                                <p>
                                    Giving more insights on the recent adaptation of Flipkart to Oracle’s
                                    cloud based platform to raise the customer experience Venky Vijay,
                                    Principal Consultant, Virtuos says that now Flipkart have got the
                                    ability to have a 360 degree view communication which was not possible
                                    before and this has raised their customer portfolio by leaps and
                                    bounds.
                                </p>
                                <p>
                                    In addition to speed, agility, and business efficiency, Oracle's cloud
                                    applications can provide organisations with a range of unique social
                                    networking-related features, including the ability for employees to
                                    collaborate with one another and reach out to customers on public
                                    social sites such as Facebook or LinkedIn, to personalize end-user
                                    engagements and boost business opportunities.
                                </p>
                                <h3 className="title">Virtuos in the News:</h3>
                                <div className="row journal">
                                    <div className="col-12 item">
                                        <NavLink
                                            to="https://www.siliconindia.com/news/enterpriseit/Oracle-Cloud-Applications-Sees-Huge-Momentum-in-India-nid-124554-cid-7.html"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={`/img/silicon-india-logo.png`}
                                                alt="siliconindia.com"
                                            />
                                        </NavLink>
                                    </div>
                                </div>
                                <h3 className="title">About Oracle India</h3>
                                <p>
                                    Oracle India is a leading Technology and Applications Software Company
                                    with leadership in ERP, Database, CRM, HCM amongst other products and
                                    services. In India it has clients like 3M, WNS, Standard Chartered
                                    Bank, Siemens, Havells, HelpAge India, M3M India, MacMillan India,
                                    Reliance Capital, Borosil, Aurobindo Pharma, Pride Hotels, Kotak
                                    Securities, Nuthatch Nutricare Technologies, BPTP who have deployed
                                    Oracle CRM applications on the cloud. This is helping them achieve
                                    operational excellence, reduce cost, and enhance their business
                                    processes substantially.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting, and Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has built a strong RightNow Practice for CRM Service Cloud by
                                    creating marquee clients like MakeMyTrip, Fundtech, Flipkart,
                                    BookMyShow amongst others. Virtuos will continue to strengthen its
                                    Oracle CX Practice by extending the portfolio of services and
                                    geographies.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Venky_Vijay_Speaks_To_The_Press