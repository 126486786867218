import "./style/mobile.css";
import "./style/desktop.css";
import "./style/style.css";
import "./style/main.css";
import "./style/animate.css";
import "./style/normalize.css";
import "./style/tablet.css";
import "./style/style-main.min.css";
import React, { useEffect } from "react";

import AllRoutes from "./AllRoutes";
import "./App.css";

import { Outlet } from 'react-router-dom';

function App() {
  return (
    <>
      <link
        rel="stylesheet"
        type="text/css"
        media="all"
        href={`${process.env.REACT_APP_BASE_URL}/style/style.css?version=955`}
      />
      {/* <ScriptComponent />
      <LinkStyleComponent /> */}
      {/* <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P2NBPXS"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript> */}
      <Outlet /> {/* This will render the matched child route */}
      <AllRoutes />
    </>
  );
}
export default App;
