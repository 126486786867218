import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Joins_Sap_Partneredge_Program = () => {
    useEffect(() => {
        document.title = "Virtuos Joins SAP PartnerEdge Program and becomes Authorized Resellers for Successfactors (An SAP Company) – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-joins-sap-partneredge-program-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    Gurgaon and Bengaluru - 27 August, 2013
                                </span>
                                <h2 className="article-heading">
                                    Virtuos Joins SAP PartnerEdge Program and becomes Authorized Resellers
                                    for Successfactors (An SAP Company)
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos, a leading CX and Digital Transformation Consultancy with
                                    India operations, is proud to announce its new partnership with global
                                    business software leader SAP AG <strong>(NYSE: SAP)</strong>, joining
                                    the SAP® PartnerEdge™ program as an Authorized Reseller. Through a
                                    rigorous evaluation process, Virtuos Solutions was identified as a
                                    company fulfilling all strict requirements for becoming a SAP
                                    PartnerEdge Program member.
                                </p>
                                <p>
                                    “This is a big step with Virtuos' ambitious plans to expand from
                                    Customer Experience into Employee Experience and HCM Management.”
                                    Venky Vijay Reddi, CEO of Virtuos Solutions, says, “We will provide
                                    stand-alone cloud-based Human Capital Management and support our
                                    prestigious clients in their search for talent, from recruitment to
                                    retirement."
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/successfactors-virtuos-logo.jpg`}
                                        alt="SAP SuccessFactors, Virtuos"
                                    />
                                </figure>
                                <br />
                                <p>
                                    As the global competition for talent continues, organizations are
                                    constantly seeking solutions that will deliver a sustainable
                                    competitive advantage. The SuccessFactors BizX suite drives real
                                    business results by ensuring organizational alignment, optimizing
                                    people performance, and building competitive advantage with greater
                                    workforce insights.
                                </p>
                                <p>
                                    Virtuos, as an SAP PartnerEdge partner, has access to an innovative
                                    and market-leading partner program, one that provides powerful
                                    business-enabling resources, benefits, and expertise. By working with
                                    SAP, we can develop, market, sell, and deliver the most comprehensive
                                    suite of HCM software solutions available.
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely - Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas and executing them with Virtuoso teams. Virtuos was founded
                                    in 2008 by cultivating an ambitious plan to become a multi-billion
                                    dollar company and a game changer by 2020. Thanks to the hard work and
                                    commitment of our team we continue to grow each year. With new
                                    innovative business ideas, delivering incredible transformation
                                    services and building powerful engagement platforms for the future we
                                    are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink
                                        to="http://www.fb.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Joins_Sap_Partneredge_Program