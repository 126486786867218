import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import Customer_Engagement_Form from './Forms/CustomerEngagementForm';

const Customer_Engagement = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };

  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };

  useEffect(() => {
    document.title = "Real-time Customer Engagement Hub. The C-Digital Mastermind – Virtuos Digital";

  }, []);
  return (
    <div className="landing-page ceh-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5 item-wrapper">
                  <h3 className="section-heading">
                    Real-time Customer Engagement Hub — The C-Digital Mastermind
                    Consulting
                  </h3>
                </div>
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/svg/ceh-header-img.svg`}
                      alt="CEH"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/c-digital/`}>C.DIGITAL</NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/c-digital/customer360/ceh/`}
                    className="active"
                  >
                    CUSTOMER ENGAGEMENT HUB
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/`}>
                    CUSTOMER 360
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/ci/`}>
                    CONTINUOUS INTELLIGENCE
                  </NavLink>
                </li>

              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" />  More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/c-digital/xlv/`}>EXPERIENCE ID</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/c-digital/customer-wide/`}>
                          CUSTOMER WIDE
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/c-digital/digital-dragons/`}>
                          DIGITAL DRAGONS
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="key-issues">
            <h2 className="section-main-heading">
              Customer 360 <span className="highlight">Key Issues</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="items-container">
                  <NavLink
                    to={`/c-digital/customer360/#keyissue`}
                    className="item"
                  >
                    You may need a customer engagement hub.
                    <span className="number">1</span>
                  </NavLink>
                  <NavLink
                    to={`/c-digital/customer360/ci/`}
                    className="item"
                  >
                    You may need real-time continuous intelligence in your customer
                    engagement hub.
                    <span className="number">2</span>
                  </NavLink>
                  <NavLink to="javascript:void(0);" className="item active">
                    Where to start to build your real-time customer engagement hub?
                    <span className="number">3</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="start">
            <div className="row page-wrap items-container">
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/start-icon-01.svg`}
                    alt=""
                  />
                </figure>
                <p>You can’t buy it from the market.</p>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/start-icon-02.svg`}
                    alt=""
                  />
                </figure>
                <p>Vendors are not partners.</p>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/start-icon-03.svg`}
                    alt=""
                  />
                </figure>
                <p>No one owns it.</p>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/start-icon-04.svg`}
                    alt=""
                  />
                </figure>
                <p>The ones who want it cannot make decisions.</p>
              </div>
            </div>
          </section>
          <section className="lead-hub light-blue-bg">
            <div className="row page-wrap">
              <div className="col-6">
                <h3>You Lead the Hub</h3>
                <ul>
                  <li>Make a business case</li>
                  <li>Take stock</li>
                  <li>Link the context, break the silos</li>
                  <li>Focus on high-value moments</li>
                  <li>Improve personalization and real-time capabilities</li>
                  <li>Buy if you can, build if you must</li>
                </ul>
              </div>
              <div className="col-4">
                <figure>
                  <img src={`/img/lead-hub-img.jpg`} alt="" />
                </figure>
              </div>
            </div>
          </section>
          <section className="business-ceh">
            <h2 className="section-main-heading">
              Making a <span className="highlight">Business Case</span> for a CEH
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <ul>
                  <li>
                    <i className="fa fa-angle-double-right" /> Who has the project
                    ownership?
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> Who has the operational
                    ownership?
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> Who has the operational
                    ownership?
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> Risk of inaction: How
                    much longer can tolerate the inefficiency of not having a CEH and
                    all the advantages it confers?
                  </li>
                </ul>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-2 item">
                <figure>
                  <img
                    src={`/img/head-cx-pic.jpg`}
                    alt="Head of CX"
                  />
                </figure>
                <h3>Head of Customer Experience</h3>
              </div>
              <div className="col-2 item">
                <figure>
                  <img src={`/img/cio-pic.jpg`} alt="CIO" />
                </figure>
                <h3>CIO</h3>
              </div>
              <div className="col-2 item">
                <figure>
                  <img
                    src={`/img/head-digital-pic.jpg`}
                    alt="Head of Digital"
                  />
                </figure>
                <h3>Head of Digital</h3>
              </div>
              <div className="col-2 item">
                <figure>
                  <img src={`/img/cmo-pic.jpg`} alt="CMO" />
                </figure>
                <h3>CMO</h3>
              </div>
              <div className="col-2 item">
                <figure>
                  <img
                    src={`/img/head-service-pic.jpg`}
                    alt="Head of Service"
                  />
                </figure>
                <h3>Head of Service</h3>
              </div>
              <div className="col-2 item">
                <figure>
                  <img
                    src={`/img/head-sales-pic.jpg`}
                    alt="Head of Sales"
                  />
                </figure>
                <h3>Head of Sales</h3>
              </div>
            </div>
          </section>
          <section className="stock grey-bg">
            <h2 className="section-main-heading">
              <span className="highlight">Take Stock</span> of As-Is CEH Components
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <ul>
                  <li>
                    <i className="fa fa-angle-double-right" /> Many decision makers
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> Many purchases
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> A wide variety of vendors
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> Discover a complete view
                    of what types of technologies are in use in the organization, who
                    all are using them, and supporting which types of functions or
                    customer touchpoints
                  </li>
                </ul>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                  <tbody>
                    <tr>
                      <td>Technology Components</td>
                      <td>Products</td>
                      <td>Divisions</td>
                      <td>Functions</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section className="technology">
            <h2 className="section-main-heading">
              Examples of Common <span className="highlight">Technologies Used</span>
              <br />
              as Part of Customer Engagement Hub
            </h2>
            <div className="row">
              <div className="col-12">
                <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                  <tbody>
                    <tr>
                      <td>Email, Web, Mobile Campaign Management</td>
                      <td>Web Content Management</td>
                      <td>Customer Engagement Center</td>
                      <td>Digital Commerce Platform</td>
                      <td>Web/Mobile Customer Self-Service</td>
                      <td>Business Process Management</td>
                      <td>Contact Center Infrastructure/CCaaS</td>
                      <td>Voice of Customer Solutions</td>
                      <td>Master Data Management</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                  <tbody>
                    <tr>
                      <td>Web and Mobile App Analytics</td>
                      <td>Customer Interaction Analytics</td>
                      <td>Journey Analytics</td>
                      <td>Messaging/Social Customer Service</td>
                      <td>Digital Personalization Engine</td>
                      <td>Hybrid Integration</td>
                      <td>Customer Identity and Access Management</td>
                      <td>Real-Time, Unified Customer Data Capture, and Storage</td>
                      <td>Streaming Analytics/Event Streaming Engine</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section className="link-context light-blue-bg">
            <h2 className="section-main-heading">
              Link the Context, <span className="highlight">Break the Silos</span>
            </h2>
            <div className="row page-wrap">
              <ul className="col-6">
                <li>
                  Link the customer context between the buying cycle and the owning
                  cycle:
                </li>
                <ol>
                  <li>Marketing and customer service</li>
                  <li>Marketing and sales</li>
                  <li>Customer service and sales</li>
                </ol>
              </ul>
              <ul className="col-6">
                <li>Break the silos:</li>
                <ol>
                  <li>
                    The independent social marketing and customer service organizations
                  </li>
                  <li>The customer service team and field service team</li>
                  <li>Companies and their dealers and distribution channels</li>
                  <li>
                    Companies and their outsourced service centers for product repair
                    and return
                  </li>
                  <li>Business domains and IT teams</li>
                </ol>
              </ul>
            </div>
            <div className="row page-wrap">
              <figure>
                <img
                  src={`/img/svg/customer-journey-mapping-diagram.svg`}
                  alt=""
                />
              </figure>
            </div>
          </section>
          <section className="focus-container">
            <h2 className="section-main-heading">
              Get Started by Focusing on{" "}
              <span className="highlight">High-Value Moments</span>
            </h2>
            <span className="section-desc">
              Introducing CX MOMENT™ as part of our Customer Journey Mapping initiative
              by applying C.Digital and Business 4.0 paradigms.
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <ul>
                  <li>
                    <i className="fa fa-angle-double-right" /> Inventory customer
                    touchpoints across the entire CX life cycle
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> Pick two to three moments
                    that will have the greatest impact
                  </li>
                  <li>
                    <i className="fa fa-angle-double-right" /> Understand high and low
                    points in the customer journey to find opportunities for continuous
                    intelligence
                  </li>
                </ul>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                  <tbody>
                    <tr>
                      <td colSpan={2}>Get</td>
                      <td>Use</td>
                      <td>Pay</td>
                      <td colSpan={3}>Serve</td>
                    </tr>
                    <tr>
                      <td>
                        <h3>Onboarding</h3>
                        <ul>
                          <li>Order confirmation</li>
                          <li>Tracking number</li>
                          <li>What to expect</li>
                        </ul>
                      </td>
                      <td>
                        <h3>Activation</h3>
                        <ul>
                          <li>Portal signup</li>
                          <li>Account setup confirmation</li>
                          <li>"How to video"</li>
                        </ul>
                      </td>
                      <td>
                        <h3>Product Education</h3>
                        <ul>
                          <li>FAQs</li>
                          <li>User tips</li>
                        </ul>
                      </td>
                      <td>
                        <h3>Bill Pay</h3>
                        <ul>
                          <li>1st bill explained</li>
                          <li>Autopay</li>
                          <li>Paperless signup</li>
                          <li>Credit card preauth</li>
                        </ul>
                      </td>
                      <td>
                        <h3>Tech Support</h3>
                        <ul>
                          <li>Appt confirmation</li>
                          <li>Resolution confirmation</li>
                        </ul>
                      </td>
                      <td>
                        <h3>Upgrade/Change</h3>
                        <ul>
                          <li>One-click confirm</li>
                        </ul>
                      </td>
                      <td>
                        <h3>Wrap Up/Renewal</h3>
                        <ul>
                          <li>Renewal reminder</li>
                          <li>Survey</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section className="develop-roadmap">
            <h2 className="section-main-heading">
              Develop Roadmap to{" "}
              <span className="highlight">Improve Personalization</span> <br />
              and Real-Time Capabilities
            </h2>
            <span className="section-desc">
              By 2022, more than 10% of customer engagement hub architectures will
              include real-time event streaming and streaming analytics.
            </span>
            <div className="row page-wrap">
              <div className="col-12 items-container">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/develp-roadmap-icon1.svg`}
                      alt=""
                    />
                  </figure>
                  <h3>Proactive</h3>
                </div>
                <div className="item animate-arrow-wrapper">
                  <span className="animate-arrows-horizontal animated-arrows">
                    <i className="fal fa-angle-right arrow" />
                    <i className="fal fa-angle-right arrow" />
                    <i className="fal fa-angle-right arrow" />
                  </span>
                </div>
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/develp-roadmap-icon2.svg`}
                      alt=""
                    />
                  </figure>
                  <h3>Individualized</h3>
                </div>
                <div className="item animate-arrow-wrapper">
                  <span className="animate-arrows-horizontal animated-arrows">
                    <i className="fal fa-angle-right arrow" />
                    <i className="fal fa-angle-right arrow" />
                    <i className="fal fa-angle-right arrow" />
                  </span>
                </div>
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/develp-roadmap-icon2.svg`}
                      alt=""
                    />
                  </figure>
                  <h3>Real-Time</h3>
                </div>
              </div>
            </div>
          </section>
          <section className="enable-technology">
            <h2 className="section-main-heading">
              Technologies Enabling Real-Time Continuous <br />
              Intelligence for <span className="highlight">Customer Experience</span>
            </h2>
            <div className="row">
              <div className="col-2 content">Buy if you can, build if you must</div>
              <div className="col-4 table">
                <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                  <tbody>
                    <tr>
                      <td>Hybrid Integration</td>
                      <td>Customer Identity and Access Management</td>
                      <td>Real-Time, Unified Customer Data Capture, and Storage</td>
                      <td>Streaming Analytics/Event Streaming Engine</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-2 content">
                Invest in enterprise or startup technologies
              </div>
              <div className="col-4 table">
                <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                  <tbody>
                    <tr>
                      <td>Enterprise</td>
                      <td>Startups</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Expand existing investments</li>
                          <li>Leverage prebuilt integrations</li>
                          <li>Greenfield</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Speed and innovation</li>
                          <li>Narrow use cases</li>
                          <li>Midsize enterprise</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section className="recommendation">
            <h2 className="section-main-heading">Recommendations</h2>
            <div className="row page-wrap">
              <div className="col-12">
                <ul>
                  <li>
                    <span>1</span>Do not start by looking at the IT systems. Identify
                    customer journey moments delivering the highest value for customers
                    and your business.
                  </li>
                  <li>
                    <span>2</span>Treat customer engagement hub as a multiyear
                    technology and process initiative.
                  </li>
                  <li>
                    <span>3</span>Find executive sponsorship and owner of the project
                    and break organization barriers.
                  </li>
                  <li>
                    <span>4</span>Question, assess, and then match the speed of response
                    to the specific requirements of the business activity by determining
                    the right time for every decision.
                  </li>
                  <li>
                    <span>5</span>Acquire or develop the skills necessary to implement
                    real-time continuous intelligence.
                  </li>
                  <li>
                    <span>6</span>Prepare for extensive system integration of products
                    from multiple vendors to achieve business value.
                  </li>
                </ul>
              </div>
            </div>
            <Customer_Engagement_Form title={"Write To Us"} />

          </section>
          {/*?php include '../../../footer-consultare.php';?*/}
          <Footer />
        </main>

      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  )
}

export default Customer_Engagement
