import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Customer_Voice = () => {
    const [activePhase, setActivePhase] = useState('phase-1');

    const handleClick = (event) => {
      const relValue = event.currentTarget.getAttribute('rel');
      setActivePhase(relValue);
    };
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Customer Voice | Voice of the Customer Program | CX.Digital Transformation – Virtuos Digital";
    }, []);
    return (
        <div className="cvoice-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <section className="top-hero-product-banner image-title-layout">
                        <figure className="bg-image-wrapper">
                            <img
                                src={`/img/svg/customervoice-header-bg.svg`}
                                alt="Voice of the Customer Program"
                            />
                        </figure>
                        <div className="product-hero-wrapper">
                            <div className="row page-wrap">
                                <div className="col-5 item-wrapper">
                                    <h3 className="section-heading">Voice of the Customer Program</h3>
                                    <NavLink to="#">CustomerVoice.com</NavLink>
                                </div>
                                <div className="col-7">
                                    <figure>
                                        <img
                                            src={`/img/svg/customervoice-header-img.svg`}
                                            alt="Voice of the Customer Program"
                                        />
                                    </figure>
                                </div>
                                <div className="right-fixed-logo">
                                    <img
                                        src={`/img/svg/customer-voice-logo.svg`}
                                        alt="Customer Voice Logo"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="breadcrumb-kanaban">
                        <div className="page-wrap breadcrumb-wrapper">
                            <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                <li>
                                    <NavLink to={`/cxnow`}>CXNow Program</NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        className="active"
                                    >
                                        CUSTOMER VOICE
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxaudit/`}>CX Audit</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxprism/`}>CX PRISM</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxunity/`}>CX UNITY</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/customer-journey/`}>
                                        Customer Journey
                                    </NavLink>
                                </li>

                            </ul>
                            <ul className="more-hidden-tabs">
                                <li
                                    className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <i className="fal fa-plus" />  More
                                    {showExtraLinks && (
                                        <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                            <li>
                                                <NavLink to={`/cxnow/cxprimer/`}>CX PRIMER</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxopia/`}>CXOPIA</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxpyramid/`}>CX PYRAMID</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxdatum/`}>CX DATUM</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxdesk/`}>CXDesk</NavLink>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="cjourney-wrapper">
                        <h2 className="section-main-heading">
                            Customer <span className="highlight">Voice</span>
                        </h2>
                        <span className="section-desc">
                            A crucial stepping stone on the journey to Customer Experience Excellence.
                            Create an emotional connection with your customers building digital
                            business moments and tracking the voice of the customer. CustomerVoice
                            <sup>TM</sup> is part of Virtuos CXNow<sup>TM</sup> — the program designed
                            to transform your customer’s experiences continuously.
                        </span>
                        <span className="section-desc">
                            CX leaders identify Voice of the Customer (VoC) as their most preferred
                            and outcome driven technology to invest in Customer Experience (CX)
                            transformation.
                        </span>
                    </section>
                    <section className="advantages-of-aury ecosystem-section">
                        <div className="page-wrap">
                            <div className="two-col-section">
                                <div className="row data">
                                    <div className="col-6 illus offport-left">
                                        <figure>
                                            <img
                                                src={`/img/svg/lots-of-data-img.svg`}
                                                alt="Customer Data"
                                            />
                                        </figure>
                                    </div>
                                    <div className="col-6 item-wrap offport-right">
                                        <div className="content-wrap">
                                            <h3 className="section-heading">Lots of data about customers</h3>
                                            <ul>
                                                <li>
                                                    <strong>Descriptive:</strong> Demographic, life stage,
                                                    location
                                                </li>
                                                <li>
                                                    <strong>Relationship:</strong> Interactions, transactions,
                                                    operational
                                                </li>
                                                <li>
                                                    <strong>Social:</strong> Family, friends, corporate
                                                </li>
                                                <li>
                                                    <strong>Satisfaction:</strong> Underlying, immediate
                                                </li>
                                                <li>
                                                    <strong>Attitudes:</strong> Values, decision process
                                                </li>
                                                <li>
                                                    <strong>Needs and wants:</strong> Strategic, temporary,
                                                    real-time
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="customer-voice-wrapper light-blue-bg">
                        <div className="page-wrap row item-container">
                            <div className="col-5-half item offport-down">
                                <div className="col-5">
                                    <figure>
                                        <img
                                            src={`/img/cxaudit-audit-voc-technologies-img.jpg`}
                                            alt="CX Audit, Audit VoC Technologies"
                                        />
                                    </figure>
                                </div>
                                <div className="col-7">
                                    <h4>Before CX Audit, Audit VoC Technologies</h4>
                                    <p className="app-p">
                                        Find out the format of data and who owns the technology to access
                                        data technically and politically. What are the best practices in the
                                        age of{" "}
                                        <NavLink
                                            to="https://www.eugdpr.org"
                                            rel="noreferrer"
                                            target="_blank"
                                            className="link"
                                        >
                                            GDPR
                                        </NavLink>{" "}
                                        privacy environment?
                                    </p>
                                </div>
                            </div>
                            <div className="col-5-half item offport-down">
                                <div className="col-5">
                                    <figure>
                                        <img
                                            src={`/img/consolidate-voc-img.jpg`}
                                            alt="Direct, Indirect and Inferred Customer Voice"
                                        />
                                    </figure>
                                </div>
                                <div className="col-7">
                                    <h4>Consolidate Voice of the Customer</h4>
                                    <p className="app-p"> 
                                        Direct, Indirect and Inferred — Customer Voice sources. Collect
                                        feedback from clients (B2B) or customers (B2C) on sales, support
                                        execution, and Customer Satisfaction (C-SAT).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="next-steps-wrapper">
                        <div className="page-wrap row offport-down">
                            <div className="col-3 item-wrapper">
                                <div className="item">
                                    <h4>WHAT</h4>
                                    <ul>
                                        <li>1. Map the journey</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-1 animate-arrow-wrapper">
                                <span className="animate-arrows-horizontal animated-arrows">
                                    <i className="fal fa-angle-right arrow" />
                                    <i className="fal fa-angle-right arrow" />
                                    <i className="fal fa-angle-right arrow" />
                                </span>
                            </div>
                            <div className="col-3 item-wrapper">
                                <div className="item">
                                    <h4>HOW</h4>
                                    <ul>
                                        <li>2. Identify channels &amp; timeline</li>
                                        <li>3. Test &amp; Iteration</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-1 animate-arrow-wrapper">
                                <span className="animate-arrows-horizontal animated-arrows">
                                    <i className="fal fa-angle-right arrow" />
                                    <i className="fal fa-angle-right arrow" />
                                    <i className="fal fa-angle-right arrow" />
                                </span>
                            </div>
                            <div className="col-3 item-wrapper">
                                <div className="item">
                                    <h4>NOW</h4>
                                    <ul>
                                        <li>4. Reporting</li>
                                        <li>5. Goal Setting</li>
                                        <li>6. Take Action</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="phase-1">
                        <h2 className="section-main-heading">
                            Develop Metrics and Measure{" "}
                            <span className="highlight">Impact of VoC</span>
                        </h2>
                        <span className="section-desc">
                            <strong>Phase 1: Anecdotal</strong>
                        </span>
                        <div className="page-wrap row develop offport-down app-p">
                            <div className="col-4 item-wrap">
                                <div className="content-wrap">
                                    <figure>
                                        <img
                                            src={`/img/svg/icons/performance-icon.svg`}
                                            alt="VoC - Performance"
                                        />
                                    </figure>
                                    <h3 className="section-heading">Performance</h3>
                                    <p>
                                        <strong>Key Stakeholder Satisfaction:</strong> Approval ratings from
                                        internal stakeholders.
                                    </p>
                                    <p>
                                        <strong>Activity:</strong> Number of people using VoC data, number
                                        of reports and analyses, etc.{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 item-wrap">
                                <div className="content-wrap">
                                    <figure>
                                        <img
                                            src={`/img/svg/icons/value-icon.svg`}
                                            alt="VoC - Value"
                                        />
                                    </figure>
                                    <h3 className="section-heading">Value</h3>
                                    <p>
                                        <strong>Individual Instances:</strong> Dollar or customer impact of
                                        unique projects using VoC data.
                                    </p>
                                    <p>
                                        <strong>Aggregate Impact:</strong> The overall performance of NPS,
                                        customer satisfaction, and other metrics.
                                    </p>
                                    <p>
                                        <strong>ROI Correlation:</strong> Relationship of customer
                                        satisfaction scores to financial and business results.{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 item-wrap">
                                <div className="content-wrap">
                                    <figure>
                                        <img
                                            src={`/img/svg/icons/safety-icon.svg`}
                                            alt="VoC - Safety"
                                        />
                                    </figure>
                                    <h3 className="section-heading">Safety</h3>
                                    <p>
                                        <strong>Number of Issues Discovered:</strong> Many PR, security or
                                        other issues revealed.
                                    </p>
                                    <p>
                                        <strong>Number of Customers Retained:</strong> Number and value of
                                        customers with issues resolved by closing the loop.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="digital-process-section grey-bg">
                        <h2 className="section-main-heading">
                            Develop Metrics and Measure{" "}
                            <span className="highlight">Impact of VoC</span>
                        </h2>
                        <span className="section-desc">
                            <strong>Phase 2: Analytical</strong>
                        </span>
                        <div className="row page-wrap digital-program-wrapper">
                            {/* Clickable Tabs */}
                            <div className="col-2 digital-process-tabs-wrapper vertical-clickable-tabs">
                                <ul>
                                    <li rel="phase-1" 
                                    className={`item blue ${activePhase === 'phase-1' ? 'active' : ''}`} onClick={handleClick}
                                    >
                                        <h3>1-4</h3>
                                        <span>Steps</span>
                                    </li>
                                    <li rel="phase-2" className={`item blue ${activePhase === 'phase-2' ? 'active' : ''}`} onClick={handleClick}>
                                        <h3>5-8</h3>
                                        <span>Steps</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-10 vertical-tab-content-wrapper digital-process-content-wrapper">
                                {/* Phase 1 */}
                                <div id="phase-1" 
                                 className={`vertical-tab-content  blue ${activePhase  === 'phase-1' ? 'active' : ''}`}
                                
                               >
                                    <div className="row content-block">
                                        <div className="col-12 item">
                                            <span>1</span>Organize Stakeholders and Focus on Benefits
                                        </div>
                                        <div className="col-12 item">
                                            <span>2</span>Select the Metrics
                                        </div>
                                        <div className="col-12 item">
                                            <span>3</span>Baseline Existing Performance
                                        </div>
                                        <div className="col-12 item">
                                            <span>4</span>Describe the Capabilities of the Solution
                                        </div>
                                    </div>
                                </div>
                                {/* Phase 2 */}
                                <div id="phase-2" 
                                 className={`vertical-tab-content blue  ${activePhase  === 'phase-2' ? 'active' : ''}`}
                                >
                                    <div className="row content-block">
                                        <div className="col-12 item">
                                            <span>5</span>Negotiate the Business Outcomes
                                        </div>
                                        <div className="col-12 item">
                                            <span>6</span>Monetize the Business Outcomes
                                        </div>
                                        <div className="col-12 item">
                                            <span>7</span>Develop the TCO
                                        </div>
                                        <div className="col-12 item">
                                            <span>8</span>Calculate the Hard-Currency ROI
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="best-practice-wrapper">
                        <h2 className="section-main-heading">
                            Data Architecture <span className="highlight">Best Practice</span>
                        </h2>
                        <span className="section-desc">
                            <strong>Align With Customer MDM Strategy</strong>
                        </span>
                        <div className="page-wrap offport-down">
                            <figure>
                                <img
                                    src={`/img/svg/mdm-strategy-img.svg`}
                                    alt="Customer MDM Strategy by Gartner"
                                />
                            </figure>
                            <span className="source">Source: Gartner</span>
                        </div>
                    </section>
                    <section className="direct-feedback-wrapper">
                        <h2 className="section-main-heading">
                            Direct Feedback — Choose the Channel <br /> That{" "}
                            <span className="highlight">Works for Your Customers!</span>
                        </h2>
                        <span className="section-desc">
                            Don’t destroy the experiences by giving feedbacks so outdated, or so
                            cumbersome that people hate to give feedbacks. Make sure surveys are
                            personalized, brief, contextually multichannel, engaging, interactive, and
                            actioned.
                        </span>
                    </section>
                    <section className="indirect-feedback-wrapper">
                        <div className="page-wrap">
                            <div className="two-col-section">
                                <div className="row indirect">
                                    <div className="col-6 illus offport-left">
                                        <figure>
                                            <img
                                                src={`/img/indirect-feedback-img.jpg`}
                                                alt="Indirect Feedback"
                                            />
                                        </figure>
                                    </div>
                                    <div className="col-6 item-wrap offport-right">
                                        <div className="content-wrap">
                                            <h3 className="section-heading">
                                                Indirect Feedback — Coping With an Explosion of Availability
                                                Across Phone, Email, SMS, Chat, Social
                                            </h3>
                                            <p>
                                                A tweet, chat or email, sentiments through call recording, or
                                                agent notes. This may include social mentions from other sites.
                                                Selection of appropriate speech and text analytics technologies
                                                is critical. Ownership does not need to be tied to data
                                                location.
                                            </p>
                                            <div className="row">
                                                <div className="col-6">
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-angle-double-right" /> Detection rate
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-angle-double-right" /> Scalability
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-angle-double-right" /> Cost
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-angle-double-right" /> Sentiment
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-6">
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-angle-double-right" /> Classification
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-angle-double-right" /> Multiple
                                                            threads
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-angle-double-right" /> Real time
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-angle-double-right" /> Actions
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="inferred-feedback-wrapper">
                        <div className="page-wrap">
                            <div className="two-col-section">
                                <div className="row inferred">
                                    <div className="col-6 item-wrap offport-left">
                                        <div className="content-wrap">
                                            <h3 className="section-heading">
                                                Inferred Feedback — Ascertaining What Your Customers Would Say
                                                If You Asked Them
                                            </h3>
                                            <p>
                                                Most implicit feedbacks observed from web behavior, contact
                                                center, store, etc. This also includes purchase history and even
                                                mouse movements.
                                            </p>
                                            <ul>
                                                <li>
                                                    <i className="fal fa-angle-double-right" /> All Interaction
                                                    Channels Relevant
                                                </li>
                                                <li>
                                                    <i className="fal fa-angle-double-right" /> Usually Already
                                                    Collecting for Operational Reasons
                                                </li>
                                                <li>
                                                    <i className="fal fa-angle-double-right" /> Normally Data
                                                    Siloed
                                                </li>
                                                <li>
                                                    <i className="fal fa-angle-double-right" /> Focus on Linkages
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-6 illus offport-right">
                                        <figure>
                                            <img
                                                src={`/img/inferred-feedback-img.jpg`}
                                                alt="Inferred Feedback"
                                            />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="digital-human-wrapper">
                        <div className="page-wrap row">
                            <div className="col-3 offport-left">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/CustVoice-logo.svg`}
                                        alt="Customer Voice Emblem"
                                    />
                                </figure>
                            </div>
                            <div className="col-6 title offport-down">
                                Leading VoC Program for transforming digital experiences.
                            </div>
                            <div className="col-3 offport-right">
                                <NavLink className="btn btn-primary" to={`/contact`}>
                                    Contact Us
                                </NavLink>
                            </div>
                        </div>
                    </section>
                    <section className="rich-veins-wrapper">
                        <div className="page-wrap">
                            <div className="two-col-section">
                                <div className="row rich-vein offport-down">
                                    <div className="col-5 illus">
                                        <figure>
                                            <img
                                                src={`/img/rich-veins-of-insight-img.jpg`}
                                                alt="Rich Veins of Insight"
                                            />
                                        </figure>
                                    </div>
                                    <div className="col-7 item-wrap">
                                        <div className="content-wrap">
                                            <h3 className="section-heading">
                                                Where Are the Rich Veins of Insight That Are Currently Being
                                                Untapped?
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i className="fal fa-angle-double-right" /> Pilot the most
                                                    promising VoC feedback sources based on volume vs. quality
                                                </li>
                                                <li>
                                                    <i className="fal fa-angle-double-right" /> Plan for a
                                                    multi-phased investment strategy spanning several years{" "}
                                                </li>
                                                <li>
                                                    <i className="fal fa-angle-double-right" /> Justify investment
                                                    by combining departmental operational value with VoC value
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="advantages-of-aury two-col-fig-content-wrapper">
                        <h2 className="section-main-heading">
                            How can we <span className="highlight">Help You</span>
                        </h2>
                        <div className="page-wrap row">
                            <div className="col-5 offport-left">
                                <figure>
                                    <img
                                        src={`/img/book-cx-primer-img.jpg`}
                                        alt="Kickstart CX Primer"
                                    />
                                </figure>
                            </div>
                            <div className="col-7 points offport-right">
                                <ul>
                                    <li className="row">
                                        <span className="icon">
                                            <i className="fas fa-circle" />
                                        </span>
                                        <div className="content-wrapper">
                                            <p>
                                                <em>Kickstart</em> CX Primer to audit existing VoC technologies
                                                across the organization
                                            </p>
                                        </div>
                                    </li>
                                    <li className="row">
                                        <span className="icon">
                                            <i className="fas fa-circle" />
                                        </span>
                                        <div className="content-wrapper">
                                            <p>
                                                <em>Define</em> the intended scope of VoC, its relationship to
                                                CXM and
                                                <em>build</em> the associated business case
                                            </p>
                                        </div>
                                    </li>
                                    <li className="row">
                                        <span className="icon">
                                            <i className="fas fa-circle" />
                                        </span>
                                        <div className="content-wrapper">
                                            <p>
                                                <em>Determine</em> the most appropriate data model and relevant
                                                analytical techniques
                                            </p>
                                        </div>
                                    </li>
                                    <li className="row">
                                        <span className="icon">
                                            <i className="fas fa-circle" />
                                        </span>
                                        <div className="content-wrapper">
                                            <p>
                                                <em>Start</em> pilots for the "most promising" VoC technologies
                                            </p>
                                        </div>
                                    </li>
                                    <li className="row">
                                        <span className="icon">
                                            <i className="fas fa-circle" />
                                        </span>
                                        <div className="content-wrapper">
                                            <p>
                                                <em>Assign</em> metrics to measure the impact of VoC and{" "}
                                                <em>create</em> a mechanism for the distribution of insight
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    {/*?php include '../../footer-consultare.php';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Customer_Voice
