import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Hdfc_Life_Insurance_Case_Study = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Virtuos Customer Success Stories | HDFC Life Automation case study – Virtuos Digital";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/hdfc-life-header-img.jpg`}
                                    alt="HDFC Life Insurance"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/hdfc-life-logo.jpg`}
                                        alt="HDFC Life Logo"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        Digital Process Automation Solution for a leading Reinsurance
                                        Company
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <section className="breadcrumb-kanaban">
                            <div className="page-wrap breadcrumb-wrapper">
                                <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                    <li>
                                        <NavLink to={`/corporate/`}>About Virtuos</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/customers/`} className="active">
                                            Customer Success
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={`/customers#success-stories`}
                                            className="smooth"
                                        >
                                            CUSTOMER SUCCESS STORIES
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/succeedant/`} className="smooth">
                                            SUCCEEDANT CSM
                                        </NavLink>
                                    </li>

                                </ul>
                                <ul className="more-hidden-tabs">
                                    <li
                                        className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <i className="fal fa-plus" />  More
                                        {showExtraLinks && (
                                            <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                                <li>
                                                    <NavLink to={`/corporate/experience-edge/`}>
                                                        EXPERIENCE EDGE
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`/insights/`}>
                                                        INSIGHTS AND RESOURCES
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                <h3 className="title">About HDFC Life Insurance</h3>
                                <p>
                                    The company offers reinsurance capacity to life insurance companies in
                                    the UAE, Bahrain, Oman and Jordan and is now in the process of
                                    expanding into other jurisdictions globally. The company offers
                                    Automatic Treaty and Facultative reinsurance that maximizes the
                                    underwriting capacity of their cedents.
                                </p>
                                <h3 className="title">Highlights</h3>
                                <div className="highlights-wrapper">
                                    <div className="row">
                                        <div className="col-4 item">
                                            <span className="header">6 Weeks</span>
                                            <p>Complete Implementation (with UAT)</p>
                                        </div>
                                        <div className="col-4 item">
                                            <span className="header">0</span>
                                            <p>Hours of Coding Effort</p>
                                        </div>
                                        <div className="col-4 item">
                                            <span className="header">100%</span>
                                            <p>Percentage of “No-code” Implementation</p>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="title">Modules Used</h3>
                                <ul>
                                    <li>Business Process Automation</li>
                                    <li>Dynamic Case Management</li>
                                    <li>Business Rules engine</li>
                                    <li>Bulk data import and export</li>
                                    <li>Analytics and Reportings</li>
                                </ul>
                                <h3 className="title">Challenges faced by the Company</h3>
                                <div className="challenge-wrapper offport-down">
                                    <div className="row items-container">
                                        <div className="col-3 item">
                                            Reinsurance Quotes managed on excel
                                        </div>
                                        <div className="col-3 item">
                                            Lack of visibility of quotes status
                                        </div>
                                        <div className="col-3 item">
                                            No reporting and analytics on approval and rejection rates
                                        </div>
                                    </div>
                                    <div className="row items-container">
                                        <div className="col-3 item">
                                            Lack of a single view of all Quotes, with statuses, for each
                                            Cedent
                                        </div>
                                        <div className="col-3 item">
                                            Manual benefit entries and manual calculations
                                        </div>
                                    </div>
                                </div>
                                <h3 className="title">Virtuos Approach</h3>
                                <p>
                                    Virtuos implemented Creatio Sales Team for the company for their Quote
                                    Management process in order to digitalize the whole process and make
                                    it seamless and more efficient. The solution implemented by Virtuos
                                    helped the company in effective monitoring of Quote requests submitted
                                    by their various Business Development team members for underwriting.
                                    The auto-routing functionality for assigning Quote requests to
                                    multiple teams, like Actuary, Risk, and Underwriting, helped the
                                    company in reducing time lag in manual assignment.
                                </p>
                                <p>
                                    The views created helped the company in better portfolio risk
                                    management, seamless underwriting and getting faster inter-team
                                    approvals.
                                </p>
                                <figure className="blog-content-image offport-down">
                                    <img
                                        src={`/img/hdfc-life-case-study-screenshot1.jpg`}
                                        alt="HDFC Life Insurance"
                                    />
                                </figure>
                                <figure className="blog-content-image offport-down">
                                    <img
                                        src={`/img/hdfc-life-case-study-screenshot2.jpg`}
                                        alt="HDFC Life Insurance"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="customer-success-section">
                        <h2 className="section-main-heading">
                            Other <span className="highlight">Case Studies</span>
                        </h2>
                        <div className="clients-wrapper">
                            <ul className="row page-wrap offport-down">
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/standard-chartered-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/standard-chartered-casestudy-img.jpg`}
                                                alt="Standard Chartered Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/standard_chartered.svg`}
                                                alt="Standard Chartered Bank Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">CX and Contact Center Automation</p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/tvs-credit-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/tvsc-casestudy-img.jpg`}
                                                alt="TVS Credit Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/tvscs-logo.png`}
                                                alt="TVS Credit"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer Experience Transformation Project using Oracle platform
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/makemytrip-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/makemytrip-casestudy-img.jpg`}
                                                alt="Make My Trip Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/make-my-trip-logo.svg`}
                                                alt="Make My Trip Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            MakeMyTrip.com halved avg. call handling time and increased
                                            revenue with CX Cloud
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/electronics-company-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/electronics-company-casestudy-img.jpg`}
                                                alt="Electronics Company Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/confidential-logo.svg`}
                                                alt="Confidential Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer engagement on phone, email, chat, and social channels
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Hdfc_Life_Insurance_Case_Study