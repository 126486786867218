import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Signs_Up_With_Gartner_India = () => {
    useEffect(() => {
        document.title = "Virtuos signs with Gartner India to receive Gartner Analyst, Advisory and Research Services – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-signs-with-gartner-india-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 8 April, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos signs with Gartner India to receive Gartner Analyst, Advisory
                                    and Research Services
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Gartner will provide Analyst, Advisory and Research Services to
                                    Virtuos for the latter to map out its global expansion strategy and
                                    position differentiated services across the globe. By subscribing to
                                    the services from Gartner Virtuos will be able to guide its customers
                                    and employees to focus on strategic areas and take prudent decisions
                                    based on the analysis, research, and experience drawn from Gartner.
                                </p>
                                <p>
                                    As part of the advisory and research services, Virtuos will have
                                    access to the following Gartner services.
                                </p>
                                <ul>
                                    <li>Core IT Research</li>
                                    <li>Segment specific - IT Key Metrics Data</li>
                                    <li>IT Leaders Toolkits</li>
                                    <li>Segment specific - Dataquest Market Insights</li>
                                    <li>Marketing Essentials</li>
                                    <li>Emerging marketing reports</li>
                                    <li>Emerging Technology Reports</li>
                                </ul>
                                <p>
                                    Besides Virtuos will interact with Analysts, and also receive Web site
                                    entitlements, event/summit participation tickets, and analytics
                                    entitlements.
                                </p>
                                <p>
                                    With more than US$50,000 investment in subscription of Gartner
                                    services per year, and additional engagement of manpower that
                                    typically cost US$200,000 per year, on analysing Gartner research, and
                                    attending Gartner events. Virtuos provides exemplary consulting
                                    services to its customers when it comes to best business practice or
                                    customer success management.
                                </p>
                                <p>
                                    Gartner is a leading research and advisory organization for enterprise
                                    IT Service providers, technology companies, and end users of
                                    technology. Virtuos Leadership has been attending Gartner events such
                                    as Gartner Symposium, and Other Summits such as Gartner Analytics,
                                    Gartner Applications during the last couple of years.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/gartner-consultare-logo-news.jpg`}
                                        alt="Gartner, Virtuos Consultare"
                                    />
                                </figure>
                                <br />
                                <p>
                                    “A commitment to invest in Gartner Services has come from our
                                    initiative to enter into high performance consulting services through
                                    our newly acquired business — Consultare.” Said Venky Vijay Reddi.
                                    “Gartner Advisory and Analyst Services along with the access to its
                                    research and analytics gives Virtuos an edge over small and medium
                                    sized consulting companies.”
                                </p>
                                <h2 className="title">About Gartner</h2>
                                <p>
                                    Founded in 1979, Gartner is the leading research and advisory company.
                                    Gartner has expanded well beyond its flagship technology research to
                                    provide senior leaders across the enterprise with the indispensable
                                    business insights, advice, and tools they need to achieve their
                                    mission-critical priorities and build tomorrow's organizations.
                                </p>
                                <p>
                                    Together with its clients, Gartner fuels the future of business so
                                    that a more successful world takes shape. Gartner has nearly 10,000
                                    associates serving in over 100 countries offering 10,000+ enterprise
                                    customers with more than US$1 Billion annual revenue.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="www.gartner.com" target="_blank" className="link">
                                        www.gartner.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Consultare</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting, and Internet Startups.{" "}
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has implemented CRM and Customer Experience Projects to some
                                    of the largest global brands. Our customers include: MakeMyTrip,
                                    Flipkart, Cleartrip, BookMyShow, FundTech, Canon Singapore, National
                                    University of Singapore (NUS), Singapore Institute of Management
                                    (SIM), NZPost, Telstra, Myntra, Standard Chartered Bank,
                                    GE-Healthcare, Sutherland Global, Party Gaming amongst several others.
                                </p>
                                <p>
                                    Virtuos has forayed into high performance Consulting Services
                                    integrating CX and Digital through Consultare division. For more
                                    information visit us at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Signs_Up_With_Gartner_India