import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
const Expereince_ID = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };

  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };

  useEffect(() => {
    document.title = "C.Digital Mastermind for CX – Customer ID and Experience ID – Virtuos Digital";

  }, []);
  return (
    <div className="landing-page xlv-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img src={`/img/xlv-header-img.jpg`} alt="" />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5 item-wrapper">
                  <h3 className="section-heading">
                    INTRODUCING <br />
                    CUSTOMER ID AND EXPERIENCE ID
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/c-digital/`}>C.DIGITAL</NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/xlv/`} className="active">
                    EXPERIENCE ID
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/`}>
                    CUSTOMER 360
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/ci/`}>
                    CONTINUOUS INTELLIGENCE
                  </NavLink>
                </li>

              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" />  More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/c-digital/customer360/ceh/`}>
                          CUSTOMER ENGAGEMENT HUB
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/c-digital/customer-wide/`}>
                          CUSTOMER WIDE
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/c-digital/digital-dragons/`}>
                          DIGITAL DRAGONS
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="mastermind">
            <div className="row page-wrap">
              <div className="col-5 left-block">
                <h2 className="bold-class">
                  C.Digital Mastermind for <span className="highlight">CX</span>
                </h2>
                <p className="app-p">
                  Building on the new Customer Engagement Value by building the best
                  practices for Customer Lifetime Value (CLV) and introducing Experience
                  Lifetime Value (XLV).
                </p>
              </div>
              <div className="col-4 right-block">
                <h3>
                  <span className="btm-border">What</span> We Offer
                </h3>
                <ul>
                  <li>
                    <span className="icon">
                      <img
                        src={`/img/svg/icons/strategy-icon1.svg`}
                        alt=""
                      />
                    </span>
                    <NavLink
                      to={`/c-digital/customer-wide/`}
                      className="txt"
                    >
                      Customerwide Strategy
                    </NavLink>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={`/img/svg/icons/customer-360-practice-icon.svg`}
                        alt=""
                      />
                    </span>
                    <NavLink
                      to={`/c-digital/customer360/`}
                      className="txt"
                    >
                      Customer 360 Practices
                    </NavLink>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={`/img/svg/icons/continuous-intelligence-icon.svg`}
                        alt=""
                      />
                    </span>
                    <NavLink
                      to={`/c-digital/customer360/ci`}
                      className="txt"
                    >
                      Continuous Intelligence
                    </NavLink>
                  </li>
                  <li>
                    <span className="icon">
                      <img
                        src={`/img/svg/icons/lifetime-value-icon.svg`}
                        alt=""
                      />
                    </span>
                    <NavLink
                      to={`/c-digital/xlv#life-value`}
                      className="txt"
                    >
                      Experience Lifetime Value
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="cev-container">
            <h2 className="section-main-heading">
              CUSTOMER ENGAGEMENT VALUE <span className="highlight">(CEV)</span>
            </h2>
            <span className="section-desc">
              C.Digital Mastermind for Customer Experience Best Practices. Comparing
              Customer Lifetime Value (CLV) and Experience Lifetime Value (XLV)
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/cev-balance-diagram.svg`}
                    alt="CEV diagram"
                  />
                </figure>
                <div className="note">
                  Experience ID and Experience Lifetime Value (XLV) are the trademarks
                  of Virtuos.
                </div>
              </div>
            </div>
            <div className="row page-wrap value smooth" id="life-value">
              <div className="col-5-half item offport-left">
                <figure>
                  <img
                    src={`/img/customer-lifetime-value-img.jpg`}
                    alt=""
                  />
                </figure>
                <h3 className="bold-class">CUSTOMER LIFETIME VALUE</h3>
                <p>
                  <strong className="apph2">
                    Customer Lifetime Value (CLV) can be measured in the following way:
                  </strong>
                </p>
                <ul>
                  <li>Identify the touchpoints where the customer creates the value</li>
                  <li>Integrate records to create the customer journey</li>
                  <li>Measure revenue at each touchpoint</li>
                  <li>Add together over the lifetime of that customer</li>
                </ul>
              </div>
              <div className="col-5-half item offport-right">
                <figure>
                  <img
                    src={`/img/experience-lifetime-value-img.jpg`}
                    alt=""
                  />
                </figure>
                <h3 className="bold-class">EXPERIENCE LIFETIME VALUE</h3>
                <p>
                  <strong className="apph2">
                    Experience Lifetime Value (XLV) on the contrary can be measured as
                    under:
                  </strong>
                </p>
                <ul>
                  <li>Identify the self-service channels and experience gain</li>
                  <li>Track CX (customer advocacy or reviews) that has impact</li>
                  <li>Measure CSAT at each touchpoint</li>
                  <li>Add together over the lifetime of the experience journey</li>
                </ul>
              </div>
              <div className="col-12 quote">
                <h2 className="section-main-heading">
                  WHAT IS EXPERIENCE LIFETIME VALUE
                </h2>
                <blockquote>
                  The “Experience Lifetime Value (XLV)” — also known as “Customer
                  Engagement Value (CEV)” is the ability to combine and orchestrate
                  business decisions, technology capabilities, and organizational policy
                  and structures to clearly and measurably drive customer satisfaction,
                  loyalty, and advocacy.
                </blockquote>
              </div>
            </div>
          </section>
          <section className="two-col-section">
            <div className="row page-wrap offport-down">
              <div className="col-4 item">
                <figure>
                  <img src={`/img/svg/xlv-img-01.svg`} alt="" />
                </figure>
                <p className="app-p">
                  Executive leaders driving XLV / CEV outperform peers in financial
                  measures.
                </p>
              </div>
              <div className="col-4 item">
                <figure>
                  <img src={`/img/svg/xlv-img-02.svg`} alt="" />
                </figure>
                <p className="app-p">
                  High XLV / CEV capability serves as a “barrier to entry” for the
                  competition.
                </p>
              </div>
              <div className="col-4 item">
                <figure>
                  <img src={`/img/svg/xlv-img-03.svg`} alt="" />
                </figure>
                <p className="app-p">
                  New competitors are likely to be technology-driven with customer
                  engagement as their key differentiator.
                </p>
              </div>
            </div>
          </section>
          <section className="cust-container">
            <div className="row page-wrap">
              <div className="col-4 item">
                <div className="img">
                  <img
                    src={`/img/cust-expectation-img.jpg`}
                    alt=""
                  />
                </div>
                <div className="content">
                  <h3>Customer Expectations</h3>
                  <p className="app-p">
                    Understanding it or even anticipating and staying ahead of it —
                    continuously.
                  </p>
                </div>
              </div>
              <div className="col-4 item">
                <div className="img">
                  <img
                    src={`/img/cust-centricity-img.jpg`}
                    alt=""
                  />
                </div>
                <div className="content">
                  <h3>Customer Centricity</h3>
                  <p className="app-p">
                    Driving the enterprise's strategy and decision making around these
                    expectations and...
                  </p>
                </div>
              </div>
              <div className="col-4 item">
                <div className="img">
                  <img
                    src={`/img/cust-experience-img.jpg`}
                    alt=""
                  />
                </div>
                <div className="content">
                  <h3>Customer Experience (CX)</h3>
                  <p className="app-p">Delivering a superior CX as a competitive differentiator.</p>
                </div>
              </div>
              <div className="col-12">
                <NavLink
                  to={`/c-digital/customer360/ci/`}

                  className="btn btn-secondary"
                >
                  LEARN CUSTOMER 360 KEY ISSUES <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
            </div>
          </section>
          <section className="financial-measurement light-blue-bg">
            <h2 className="section-main-heading">
              XLV LEADERS OUTPERFORM IN{" "}
              <span className="highlight">FINANCIAL MEASURES</span>
            </h2>
            <span className="section-desc">
              Understanding and meeting (or exceeding) the expectations of the customer
              will be the difference between enterprise success and failure in a
              digital-business-driven world.
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/financial-measurement-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="hub-cev">
            <h2 className="section-main-heading">
              A Customer Engagement Hub <span className="highlight">Drives XLV</span>
            </h2>
            <span className="section-desc">
              A "Customer Engagement Hub" is a technology and process initiative that
              supports personalised, contextual customer engagement, whether through a
              human, an artificial agent or sensors, across all interaction channels.
            </span>
            <div className="row page-wrap">
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/icons/across-systems-icon1.svg`}
                    alt=""
                  />
                </figure>
                <h3>Across Systems</h3>
                <p>
                  is a architectural framework that ties multiple systems together to
                  optimally engage the customer.
                </p>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/icons/across-channels-icon1.svg`}
                    alt=""
                  />
                </figure>
                <h3>Across Channels</h3>
                <p>
                  allows personalised, contextual customer engagement, whether through a
                  human, an artificial agent or sensors, across all interaction
                  channels.
                </p>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/icons/across-departments-icon.svg`}
                    alt=""
                  />
                </figure>
                <h3>Across Departments</h3>
                <p>
                  reaches and connects all departments, allowing, for example, the
                  synchronization of marketing, sales, and customer service processes.
                </p>
              </div>
            </div>
          </section>
          <section className="breaker-container">
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/customer-experience-id-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="board-actions">
            <h2 className="section-main-heading">
              BOARD ACTIONS FOR{" "}
              <span className="highlight">ENHANCED CUSTOMER ENGAGEMENT</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-9 left-block">
                <ul>
                  <li>
                    Assign a board member as the “evangelist” responsible for XLV.
                  </li>
                  <li>
                    Ensure executive management is focused on driving cultural
                    transformation across enterprise silos with a coordinated focus on
                    the customer.
                  </li>
                  <li>
                    Task executive management with evaluating the success of XLV
                    initiatives and investments by measuring increases in customer
                    retention, revenue/customer, and profitability/customer.
                  </li>
                  <li>
                    Establish a quarterly reporting mechanism to the board on XLV
                    improvements (e.g., using NPS as a measure).
                  </li>
                  <li>
                    Make XLV improvement a key performance measure for top management.
                  </li>
                </ul>
              </div>
              <div className="col-3 right-block">
                <span className="title1">
                  Bolster your competitive advantages with Virtuos
                </span>
                <span className="title2">
                  Be a mastermind, <br />a Virtuoso!
                </span>
                <NavLink to={`/contact/`} >
                  Contact Us
                </NavLink>
              </div>
            </div>
          </section>
          <section className="close-statement">
            <div className="row page-wrap">
              <div className="col-12 item">
                Customer engagement is the next competitive battlefield in a digital
                world, where competition is just a click away!
              </div>
            </div>
          </section>
          {/*?php include '../../footer-consultare.php';?*/}
          <Footer />
        </main>

      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  )
}

export default Expereince_ID
