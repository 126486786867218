import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Incorporated_In_Hyderabad = () => {
    useEffect(() => {
        document.title = "Virtuos is Incorprated with Registered Office in Hyderabad – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-incorporated-in-hyderabad-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    Hyderabad, Andhra Pradesh, 18 Jan 2008
                                </span>
                                <h2 className="article-heading">
                                    Virtuos is Incorporated with Registered Office in Hyderabad
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Solutions Private Ltd. has been incorporated in India taking
                                    over the business (Not the company) from Virtuosol Consulting Group
                                    (of Tekcorp combine). Tekcorp Group transfers key customers — Standard
                                    Chartered and GE Healthcare and key alliances — Kana Software and
                                    Maximizer Software, amongst several other prospects and customers.{" "}
                                </p>
                                <h3 className="title">BACKGROUND</h3>
                                <p>
                                    Tekcorp, a cherished leader in IT Computing, System Integration, and
                                    Automation, has diversified into CRM Business in early 2006 by
                                    launching a new division – Virtuosol Consulting Group. It has built
                                    its first Pilot with Religare Group by putting more than 720 Man days
                                    of hard work in developing solutions for Religare using “Soffront” CRM
                                    Stack and bagged its first Rs.10 Million deal. Virtuosol has also
                                    bagged several other small deals worth Rs.3 to 4 Million contracts
                                    from a few of the customers, such as Silicon Comnet, to implement
                                    “Soffront” CRM and Sage ERP. Unfortunately the product did not meet
                                    the complex expectations of the customers with regards to scalability
                                    and portability into cloud environments.
                                </p>
                                <p>
                                    "We founded "Virtuosol" on the principles of{" "}
                                    <NavLink
                                        to="https://www.amazon.com/Virtuoso-Teams-extraordinary-stories-teams/dp/0273721836"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Virtuoso Teams
                                    </NavLink>{" "}
                                    — a book written by our Mentors Andy Boynton and Bill Fischer.
                                    Unfortunately, in the first year, we made the biggest blunder in our
                                    lifetime by aligning with a Partner by positioning their solution to a
                                    few customers. One of the customers being — Religare that has no ethos
                                    and value leadership," said Venky Vijay Reddi. "As a result, we have
                                    lost our hard-earned money at the very precious startup period of
                                    operations. After becoming the scapegoat of disintermediation and
                                    deceit, We have blacklisted "Soffront," a software company and
                                    Religare Group and took a decision not to deal with them, and the
                                    deceitful employees of these companies in our lifetime."
                                </p>
                                <h3 className="title">NEW COMPANY. NEW VISION. NEW LOGO</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a new company with a brand new
                                    vision and values.
                                </p>
                                <blockquote>
                                    Surprise and delight the customers by stretching their expectations;
                                    appeal to the sophisticated; and defy the established market
                                    knowledge.
                                </blockquote>
                                <p>
                                    Virtuos Solutions Private Ltd. sports a new domain — virtuos.com, a
                                    new dot.com domain. Virtuos.com domain name was obtained for US$
                                    four-figure price from a US Company, which stopped using this name.
                                </p>
                                <p>
                                    This was the most ecstatic moment in life — third time, first when I
                                    married my beautiful wife, second time when I became father, and third
                                    time, when I was able to acquire a great domain — virtuos.com. Said
                                    Venky Vijay Reddi, CEO. “I have always been a big fan of branding and
                                    marketing since childhood, and “domain naming” has been my biggest
                                    hobby, and I still remember the kind of hard work in obtaining
                                    “virtuos.com” from the previous domain owners”.
                                </p>
                                <p>
                                    Virtuos acquired the expensive domain name to fuel its future dreams
                                    of establishing “VIRTUOS” as a world-class brand name in the space of
                                    Customer Relationship Management and Enterprise Software.
                                </p>
                                <p>
                                    Virtuos has borrowed a few important principles from{" "}
                                    <strong>T-Rules</strong>.
                                </p>
                                <ul>
                                    <li>
                                        Partner with the world's leading brands that have established a
                                        culture of trust.
                                    </li>
                                    <li>
                                        Recruit employees after thorough due diligence and background
                                        verification.
                                    </li>
                                    <li>
                                        Keep the people who are dependable and trustworthy over those who
                                        are talented.
                                    </li>
                                    <li>
                                        Choose technologies and products that have referenceable customers.
                                    </li>
                                    <li>
                                        Keep learning essential lessons, and keep referring to these to
                                        avoid making mistakes.
                                    </li>
                                </ul>
                                <h3 className="title">Virtuos.com with its first public website</h3>
                                <p>
                                    The CEO himself has developed the website from scratch and hosted with
                                    1and1.com. The website has rich content and case studies related to
                                    CRM and some of the content has been inspired from the book "the
                                    Virtuoso teams" to reflect Virtuos vision and mission for the future.
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/virtuos-website-2008-screenshot.jpg`}
                                        alt="Virtuos.com website screenshot"
                                    />
                                </figure>
                                <br />
                                <p>
                                    "We are fortunate to have Amarinder Singh as the employee no.1 in
                                    Virtuos to supervise our CRM Implementation Practice along with a team
                                    of other employees. We also thank the esteemed Partner members — Dilip
                                    Tailor, Vivek Thomas, Gary Winder, for their excellent co-operation
                                    during the implementation of these projects". Said Venky Vijay Reddi.
                                    “Special thanks go to Balu Subramaniam, Murali and Amit Ovelekar from
                                    the Standard Chartered Bank team for their trust in a new company like
                                    ours. We thank Saurabh Pachnanda (Former employee of Virtuosol
                                    Consulting Group) for having done a fantastic job of leading through
                                    the Solution Sales by interacting with the Standard Chartered Team and
                                    GE Healthcare”.
                                </p>
                                <div className="row former-emp">
                                    <div className="col-5-half">
                                        <figure className="border">
                                            <img
                                                src={`/img/kana+virtuos-scb-img.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                    </div>
                                    <div className="col-5-half">
                                        <figure className="border">
                                            <img
                                                src={`/img/maximizer-virtuos-ge-heathcare-img.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                    </div>
                                </div>
                                <p>
                                    “Last year both Dilip Tailor and Gary Winder (from KANA Software UK
                                    and KANA Software Hongkong) personally visited Virtuosol Consulting
                                    Group Office in Delhi to spend some time with our teams.” Later they
                                    confirmed their interest in pursuing a relationship with Virtuos and
                                    expanding the Indian market. Said Venky Vijay Reddi. “Further early
                                    January 2008, We were pleased with the visit of Vivek Thomas, Vice
                                    President of Maximizer Canada, to discuss the opportunities with
                                    Virtuos”.
                                </p>
                                <div className="row former-emp">
                                    <div className="col-12">
                                        <figure className="border">
                                            <img
                                                src={`/img/virtuos-incorporated-hyderabad-team-img.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                    </div>
                                </div>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd., a company registered with the Indian
                                    Companies Act, is a startup in the space of the CRM Cloud Computing
                                    business. As a company, Virtuos is born in the cloud by forging
                                    alliances with leading companies such as KANA Software and Maximizer.
                                    Virtuos has become a pioneer in CRM Cloud computing by migrating the
                                    CRM to the cloud for the first time in India, thanks to the deep
                                    expertise in this area as legacy practice from Tekcorp.
                                </p>
                                <p>
                                    The company's website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>{" "}
                                    with its corporate office in New Delhi, India's capital. Virtuos
                                    operates from a shared office premises with "Tekcorp combine" at the
                                    prestigious location — Okhla Industrial Area Phase 1 New Delhi in a
                                    building with more than 10,000 SQ FT space.
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Incorporated_In_Hyderabad