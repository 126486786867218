import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
const drawerWidth = 340;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function Sidebar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [nestedOpen1, setNestedOpen1] = useState(false);
  const handleNestedDrawerOpen1 = () => {
    setNestedOpen1(true);
  };
  const handleNestedDrawerClose1 = () => {
    setNestedOpen1(false);
    setOpen(true);
  };
  const [nestedOpen2, setNestedOpen2] = useState(false);
  const handleNestedDrawerOpen2 = () => {
    setNestedOpen2(true);
  };
  const handleNestedDrawerClose2 = () => {
    setOpen(true);
    setNestedOpen2(false);
  };
  const [nestedOpen3, setNestedOpen3] = useState(false);
  const handleNestedDrawerOpen3 = () => {
    setNestedOpen3(true);
  };
  const handleNestedDrawerClose3 = () => {
    setOpen(true);
    setNestedOpen3(false);
  };
  const [nestedOpen4, setNestedOpen4] = useState(false);
  const handleNestedDrawerOpen4 = () => {
    setNestedOpen4(true);
  };
  const handleNestedDrawerClose4 = () => {
    setOpen(true);
    setNestedOpen4(false);
  };
  const [nestedOpen5, setNestedOpen5] = useState(false);
  const handleNestedDrawerOpen5 = () => {
    setNestedOpen5(true);
  };
  const handleNestedDrawerClose5 = () => {
    setOpen(true);
    setNestedOpen5(false);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          sx={{ ...(open && { display: "none" }) }}
        >
          <div className="hamburger-lines">
            <span className="line"></span>
            <span className="line2"></span>
            <span className="line"></span>
          </div>
          {/* <MenuIcon style={{ fontSize: "2.2rem" }} /> */}
        </IconButton>
      </Toolbar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton style={{ color: "red" }} onClick={handleDrawerClose}>
            <span
              style={{
                border: "2px solid black",
                width: "2.6rem",
                marginRight: "5px",
              }}
            >
              <CloseIcon />
            </span>
            <h6 style={{ color: "red" }}>THE DIGITAL CENTURY BUSINESS</h6>
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/consultare/">
                <ListItemText primary="CONSULTARE" />
              </Link>
              <ChevronRightIcon
                style={{ right: "0", position: "absolute" }}
                onClick={handleNestedDrawerOpen1}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/xonomy/">
                <ListItemText primary="XONOMY" />
              </Link>
              <ChevronRightIcon
                style={{ right: "0", position: "absolute" }}
                onClick={handleNestedDrawerOpen2}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/business/">
                <ListItemText primary="THE BUSINESS(9)" />
              </Link>
              <ChevronRightIcon
                style={{ right: "0", position: "absolute" }}
                onClick={handleNestedDrawerOpen3}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/corporate/">
                <ListItemText primary="THE COMPANY" />
              </Link>
              <ChevronRightIcon
                style={{ right: "0", position: "absolute" }}
                onClick={handleNestedDrawerOpen4}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/alliances/">
                <ListItemText primary="ALLIANCES" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/news/">
                <ListItemText primary="NEWS & EVENTS" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/">
                <ListItemText primary="CAREERS" />
              </Link>
              <ChevronRightIcon
                style={{ right: "0", position: "absolute" }}
                onClick={handleNestedDrawerOpen5}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/contact/">
                {" "}
                <ListItemText primary="CONTACT" />
              </Link>
            </ListItemButton>
          </ListItem>
        </List>

        <Divider />
      </Drawer>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={nestedOpen1}
      >
        <DrawerHeader>
          <IconButton onClick={handleNestedDrawerClose1}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/crm-digital/">
                <ListItemText primary="CRM DIGITAL" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/cx/">
                <ListItemText primary="CUSTOMER EXPERIENCE" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/ex/">
                <ListItemText primary="EMPLOYEE EXPERIENCE" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/it/">
                <ListItemText primary="ENTERPRISE IT SERVICES" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/alliances/">
                <ListItemText primary="KEY PARTNERS" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={nestedOpen2}
      >
        <DrawerHeader>
          <IconButton onClick={handleNestedDrawerClose2}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/experience-cloud/">
                <ListItemText primary="EXPERIENCE CLOUD" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/experience-crowd/">
                <ListItemText primary="EXPERIENCE CROWD" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="https://vedam.com/">
                <ListItemText primary="DIGITAL EXPERIENCE (DX) FIRST" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="http://www.virtuez.com/">
                <ListItemText primary="VIRTUEZ XONOMY" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/experience-new/">
                <ListItemText primary="EXPERIENCENEW IN THE XONOMY" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={nestedOpen3}
      >
        <DrawerHeader>
          <IconButton onClick={handleNestedDrawerClose3}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/business/">
                <ListItemText primary="BUSINESS(9) BY NEEDS" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/solutions/">
                <ListItemText primary="SOLUTIONS BY FUNCTION" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/industry/">
                <ListItemText primary="INDUSTRIES" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/business4-0/">
                <ListItemText primary="INNOVATION INSIGHT & STRATEGY" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/alliances/">
                <ListItemText primary="PARTNER SOLUTIONS" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={nestedOpen4}
      >
        <DrawerHeader>
          <IconButton onClick={handleNestedDrawerClose4}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/corporate/">
                <ListItemText primary="ABOUT VIRTUOS" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/business/">
                <ListItemText primary="OUR BUSINESS" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/customers/">
                <ListItemText primary="CUSTOMER SUCCESS" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/">
                <ListItemText primary="CAREERS" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/news/">
                <ListItemText primary="NEWS AND EVENTS" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={nestedOpen5}
      >
        <DrawerHeader>
          <IconButton onClick={handleNestedDrawerClose5}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/opportunities/">
                <ListItemText primary="JOB OPPORTUNITY" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/culture/">
                <ListItemText primary="CULTURE" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/life-at-virtuos/">
                <ListItemText primary="LIFE @ VIRTUOS" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/experience-job/">
                <ListItemText primary="EXPERIENCE JOB" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/training-development/">
                <ListItemText primary="TRAINING & DEVELOPMENT" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/happiests-culture/">
                <ListItemText primary="HAPPIEST CULTURE" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/employee-benefits/">
                <ListItemText primary="EMPLOYEE BENEFITS" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/virtuos-alumni/">
                <ListItemText primary="VIRTUOS ALUMNI" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <Link to="/careers/360Outlook/">
                <ListItemText primary="360 DEGREE OUTLOOK" />
              </Link>
              <ChevronRightIcon style={{ right: "0", position: "absolute" }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
