import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import Form1 from "./Forms/Form1";

const Expereince_Cloud_Aury = () => {
  const [activeItem, setActiveItem] = useState(1);
  const handleItemClick = (index) => {
    if (activeItem === index) {
      setActiveItem(null);
    } else {
      setActiveItem(index);
    }
  };
  useEffect(() => {
    document.title =
      "Experience Cloud | Digital | Artificial Intelligence - Virtuos Xonomy";
  }, []);
  return (
    <div class="exp-cloud-page landing-page aury-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5 item-wrapper">
                  <figure>
                    <img src={`/img/svg/icons/aury-logo.svg`} alt="Aury" />
                  </figure>
                  <h3 className="section-heading">Aury Chatbot</h3>
                  <span>The new AI powered Engagement</span>
                </div>
                <div className="col-7">
                  <figure>
                    <img src={`/img/svg/expc-aury-header-img.svg`} alt="Aury" />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/xonomy`}>Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud`}>Experience Cloud</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud/enforce-okrx/`}>
                    Enforce OKRX
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud/definite-dxp/`}>
                    Definite DXP
                  </NavLink>
                </li>
              </ul>
            </div>
          </section>
          <section className="exp-business">
            <h2 className="section-main-heading">
              What is <span className="highlight">Experience Cloud?</span>
            </h2>
            <span className="section-desc">
              Virtuos Experience Cloud is an integrated ecosystem of hubs,
              platforms and suites connected on a common thread to deliver
              personalized, superior and contextual experiences to customers,
              employees, and other constituents. It completely replaces siloed
              "x" clouds like Sales Cloud, Marketing Cloud, Service Cloud, etc.
              focussing on "experiences" instead of channels, departments, and
              functions.
            </span>
            <div className="row page-wrap offport-down">
              <div className="col-3 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <i className="fas fa-cloud" />
                  </span>
                  <h3>It’s Single Cloud</h3>
                  <p>
                    {" "}
                    No confusion about your engagement channels or departments
                    you want to talk to. With Experience Cloud, you are in sync
                    with whole company.
                  </p>
                </div>
              </div>
              <div className="col-3 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <i className="fab fa-slideshare" />
                  </span>
                  <h3>It’s All Experience</h3>
                  <p>
                    It focuses on delivering experiences harnessing omnicloud to
                    make it easy for people to understand people better —
                    eliminating the "strain."
                  </p>
                </div>
              </div>
              <div className="col-3 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <i className="fas fa-thumbs-up" />
                  </span>
                  <h3>It’s Digital Business</h3>
                  <p>
                    It is the creation of new business designs by blurring the
                    digital and physical worlds — creating new digital human
                    ecosystems for doing good.
                  </p>
                </div>
              </div>
              <div className="col-3 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <i className="fab fa-wpexplorer" />
                  </span>
                  <h3>It’s futuristic</h3>
                  <p>
                    Gone are the days when you have to spend on marketing cloud
                    or sales cloud which aren’t enough to capture the
                    imagination of nextgen customers.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="cx-extra-features exp-cloud-services">
            <div className="row">
              <div className="col-3 item-wrapper pink">
                <figure>
                  <img
                    src={`/img/artificial-intelligence-bg.jpg`}
                    alt="AI - Artificial Intelligence"
                  />
                </figure>
                <div className="item">
                  <h3 className="section-heading">Artificial Intelligence</h3>
                  <p>
                    Virtuos Experience Cloud leverages Artificial Intelligence
                    and Analytics to deliver highly personalized customer
                    journeys.
                  </p>
                </div>
              </div>
              <div className="col-3 item-wrapper blue">
                <figure>
                  <img
                    src={`/img/customer-experience-img.jpg`}
                    alt="Customer Experience"
                  />
                </figure>
                <div className="item">
                  <h3 className="section-heading">Customer Experience</h3>
                  <p>
                    Virtuos Experience Cloud is for customers who want brands to
                    understand, know and personalize signature experiences.
                  </p>
                </div>
              </div>
              <div className="col-3 item-wrapper purple">
                <figure>
                  <img
                    src={`/img/employee-engagement-img.jpg`}
                    alt="Employee Engagement"
                  />
                </figure>
                <div className="item">
                  <h3 className="section-heading">Employee Engagement</h3>
                  <p>
                    Virtuos Experience Cloud is for employees who are the
                    custodians of "experiences", and they want to be empowered
                    to serve customers.
                  </p>
                </div>
              </div>
              <div className="col-3 item-wrapper green">
                <figure>
                  <img
                    src={`/img/digital-marketing-img.jpg`}
                    alt="Digital Marketing"
                  />
                </figure>
                <div className="item">
                  <h3 className="section-heading">Digital Marketing</h3>
                  <p>
                    Virtuos Experience Cloud is for marketing that wants to
                    tailor signature experiences for customers, employees, and
                    other constituents.{" "}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="two-col-full-section accordian-section">
            <div className="row page-wrap">
              <div className="col-6 content-wrapper offport-left">
                <div className="accordian-wrapper">
                  <h2 className="section-heading">
                    Beyond Consumerization. A New Cloud.
                  </h2>
                  <p>
                    Experience Cloud is built for Multi-channel, Multi-device,{" "}
                    <NavLink to={`/right-apps/#consumer-app`} className="link">
                      Progressive Web Apps (PWA)
                    </NavLink>{" "}
                    ecosystem with agile, event-driven architecture,
                    microservices and RESTful APIs. Experience Cloud provides
                    consistency without sacrificing functionality, and it’s
                    designed for the future where a new ecosystem of platforms,
                    hubs integrate as one "human ecosystem."
                  </p>
                  <div className={`item ${activeItem === 1 ? "active" : ""}`}>
                    <div className="title" onClick={() => handleItemClick(1)}>
                      <h3>Maintain a 360-Degree View</h3>
                    </div>
                    <div className="accordian-content">
                      <p>
                        Capture every interaction from every touchpoint.
                        Maintain a customer master record and analytics to mine
                        for trends and insights. Instill required and expected
                        data privacy (The biggest priority for the 21st century
                        customer) with higher standards of opt-in, opt-out.
                      </p>
                    </div>
                  </div>
                  <div className={`item ${activeItem === 2 ? "active" : ""}`}>
                    <div className="title" onClick={() => handleItemClick(2)}>
                      <h3>Contextual Experiences</h3>
                    </div>
                    <div className="accordian-content">
                      <p>
                        Target customers and Audience Segments with Contextual
                        Experiences by building personalization foundation and
                        data model to bridge process silos, and facilitate{" "}
                        <NavLink
                          to={`/cxnow/customer-journey`}
                          className="link"
                        >
                          customer journeys
                        </NavLink>
                        . This will include all channels and connected
                        departments.
                      </p>
                    </div>
                  </div>
                  <div className={`item ${activeItem === 3 ? "active" : ""}`}>
                    <div className="title" onClick={() => handleItemClick(3)}>
                      <h3>Modern Cloud</h3>
                    </div>
                    <div className="accordian-content">
                      <p>
                        Experience Cloud is contemporary and evolving cloud
                        supporting modern web development such as{" "}
                        <NavLink
                          to={`/consultare/engineering-services/#mean`}
                          className="link"
                        >
                          MEAN technologies
                        </NavLink>{" "}
                        and JavaScript with the clear purpose to bridge silos at
                        three key touchpoints: user experience, process, and
                        data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 illus offport-right">
                <figure>
                  <img
                    src={`/img/svg/dxp-orchestration-img.svg`}
                    alt="Experience Economy"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="customer-voice-wrapper light-blue-bg">
            <h2 className="section-main-heading">
              This is <span className="highlight">Experience Economy</span>
            </h2>
            <span className="section-desc">
              Transcend from selling products or services to experiences —
              because there are no things as Experience Businesses. There are
              only businesses whose experience components are greater or less
              than those other businesses. Everybody is in experience making.
            </span>
            <div className="page-wrap row item-container">
              <div className="col-5-half item offport-left">
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/product-experience-img.jpg`}
                      alt="Customer Experience"
                    />
                  </figure>
                </div>
                <div className="col-7">
                  <h4>
                    Is it a product or <br />
                    experience?{" "}
                  </h4>
                  <p>
                    Coffee beans as commodity is 1 to 2 cents, as goods 5 to 25
                    cents a cup, but experiences are sold at 2 to 5 dollars.
                  </p>
                </div>
              </div>
              <div className="col-5-half item offport-right">
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/experience-integrated-img.jpg`}
                      alt="Experiences integration"
                    />
                  </figure>
                </div>
                <div className="col-7">
                  <h4>Experiences must be integrated</h4>
                  <p>
                    Marketing, operations, HR, IT departments must be
                    collaborated and integrated to serve the customer. CX is not
                    collective.
                  </p>
                </div>
              </div>
              <div className="col-5-half item offport-left">
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/industry-4.0-readiness-img.jpg`}
                      alt="Industry 4.0 Readiness"
                    />
                  </figure>
                </div>
                <div className="col-7">
                  <h4>Industry 4.0 Readiness</h4>
                  <p>
                    Experience Economy (Xonomy) is part of Industry 4.0 vision
                    seamlessly collaborating with other ecosystems and
                    optimizing resources.
                  </p>
                </div>
              </div>
              <div className="col-5-half item offport-right">
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/beyond-engagement-img.jpg`}
                      alt="Customer Engagement Hub"
                    />
                  </figure>
                </div>
                <div className="col-7">
                  <h4>Beyond Engagement</h4>
                  <p>
                    Experience Cloud goes one step further from Customer
                    Engagement Hub (CEH) which will be implemented by 60% of
                    large companies by 2020.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="cf-form-wrapper">
            <div className="page-wrap ">
              <h2 className="section-main-heading">
                Experience Cloud is a brand new vision <br /> at{" "}
                <span className="highlight">Virtuos Xonomy</span>
              </h2>
              <span className="section-desc">
                We are building <strong>Aury</strong> — The AI based chatbot,{" "}
                <NavLink
                  to="http://www.definite.com"
                  rel="nofollow"
                  target="_blank"
                  className="link"
                >
                  Definite.com
                </NavLink>{" "}
                Experience Cloud for digital, <br /> and at{" "}
                <NavLink
                  to="http://www.enforce.com"
                  rel="nofollow"
                  target="_blank"
                  className="link"
                >
                  Enforce
                </NavLink>
                , we are creating{" "}
                <NavLink to={`/careers/experience-job`} className="link">
                  ExperienceJobs
                </NavLink>
                .
              </span>
              <Form1 />
            </div>
          </section>
          {/*?php include '../../footer.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Expereince_Cloud_Aury;
