import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "./images/seismicity-logo.png";
import bgImage from "./images/bg.jpg";
import './seis.css'
const Seismicity = () => {
  useEffect(() => {
    document.title =
      "Governance, Risk, and Compliance (GRC) Business – Virtuos Seismicity";
  }, []);

  return (
    <div
    id="seis"
      className="main-area-wrapper"
      style={{ backgroundImage: `url(${bgImage})`, filter:"brightness(0.5)" }}
    >
      <div className="main-areas center-text">
        <div
          className="headers"
          style={{
            width: "25%",
            textAlign: "center",
            position: "absolute",
            top: "35%",
            left: "10%",
          }}
        >
          <figure className="logos" id="seis-logo">
            <img src={logo} style={{ width: "100%" }} alt="Seismicity" />
          </figure>
          <h2
          id="h22"
            style={{
              fontSize: "3em",
              lineHeight: 1.2,
              fontWeight: 500,
              marginTop: "30px",
              color: "#fff",
              textTransform: "uppercase",
            }}
          >
            Launching soon
          </h2>
        </div>

        <div
        id="its"
          className="footers"
          style={{
            width: "25%",
            textAlign: "center",
            position: "absolute",
            bottom: "50px",
            left: "15%",
            color: "#fff",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span  style={{ marginRight: "10px" }}>It’s Virtuos Venture &nbsp; {" "}|</span>
          <NavLink 
            to="mailto:info@virtuos.com"
            target="_blank"
            style={{ color: "#fff" }}
          >
          contact us
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Seismicity;
