import React, { useEffect, useState } from 'react'

import { NavLink } from "react-router-dom";
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Nav from '../../../components/Nav';
import ApplyForJob from './Forms/ApplyForJob';
import CommonApplyForJob from './CommonApplyForJob';

const NetDeveloper = () => {
    useEffect(() => {
        document.title = "Career Opportunities | Microsoft .Net Developer – Virtuos Jobs";
    }, []);
    return (
        <div className='class="careers-job-detail-page landing-page"'>
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    {/* Careers Job Detail */}
                    <section className="job-detail-wrapper">
                        <div className="row page-wrap">
                            <div className="col-9 job-details">
                                <div className="job-header">
                                    <div className="profile-illustration">
                                        <figure>
                                            <img  src={`/img/svg/microsoft.net-career-img.svg`}
                                              
                                                alt="Microsoft .Net Developer Job"
                                            />
                                        </figure>
                                    </div>
                                    <div className="profile-detail">
                                        <h2 className="section-heading">.NET Web Developer</h2>
                                        <ul className="job-attributes">
                                            <li className="">
                                                <strong>Location:</strong>
                                                <span>Gurgaon</span>
                                            </li>
                                            <li className="">
                                                <strong>Experience:</strong>
                                                <span>3-5 Years</span>
                                            </li>
                                            <li className="">
                                                <strong>No. of Positions:</strong>
                                                <span>2</span>
                                            </li>
                                            <li className="">
                                                <strong>Qualification:</strong>
                                                <span>BE/BTech/ME/MTech/MS/MCA</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h3 className="title">Job Description</h3>
                                <ul className="list">
                                    <li>
                                        Develop and maintain high-quality software products that expand the
                                        team while supporting the strategic vision of the corporation. The
                                        main tools used will consist of Microsoft .NET Framework, SQL and
                                        integrated system components
                                    </li>
                                    <li>
                                        Support and comply with documented software process and procedure
                                        requirements spanning all phases of software product life cycle
                                    </li>
                                    <li>
                                        Participate with other team members to design user-friendly
                                        interfaces; develop high-quality, object-oriented business objects;
                                        and conduct research and evaluation of software-related technologies
                                        and products
                                    </li>
                                    <li>
                                        Collaborate with and mentor other team members, and assist with code
                                        reviews
                                    </li>
                                    <li>
                                        Manage their own work time and priorities in accordance with
                                        development deadlines, budgets, best practices, and a
                                        quality/delivery methodology, such as Agile Development, that
                                        includes development standards
                                    </li>
                                </ul>
                                <h3 className="title">Responsibilities</h3>
                                <ul className="list">
                                    <li>Design and develop multi-tiered web applications</li>
                                    <li>
                                        Design and develop APIs for integrations with various internal and
                                        external applications
                                    </li>
                                    <li>
                                        All aspects of database development: modeling, stored procedures,
                                        performance tuning, etc.
                                    </li>
                                    <li>
                                        Provide level 3 support for your application, working closely with
                                        product, and account management teams
                                    </li>
                                    <li>
                                        Collaborate with product owners on business requirements, define
                                        technical solutions, and plan sprints to deliver value to customers
                                        in a timely fashion
                                    </li>
                                    <li>
                                        Actively research and participate in discussions on software
                                        architecture, development processes, and other standards
                                    </li>
                                </ul>
                                <h3 className="title">Desired Skills, Experience &amp; Abilities</h3>
                                <ul className="list">
                                    <li>
                                        Experience in analysis, design, programming, testing, implementing
                                        and supporting web based applications in Microsoft/Windows platform.
                                        (ASP.NET, framework 3.5 or higher)
                                    </li>
                                    <li>
                                        Strong front-end development experience (JavaScript, jQuery, Ajax,
                                        HTML5, CSS, Bootstrap)
                                    </li>
                                    <li>Experience working in an Agile development environment</li>
                                    <li>Web Services (WCF, REST)</li>
                                    <li>Data access using ADO.NET, Entity Framework</li>
                                    <li>Advanced knowledge of OOP/OOD, design patterns, and DDD</li>
                                    <li>
                                        Knowledge of 3rd party front-end UI libraries (Telerik/KendoUI,
                                        open-source libraries)
                                    </li>
                                    <li>
                                        Experience with the Microsoft .NET programming language using the
                                        MVC 4.x Framework
                                    </li>
                                    <li>Experience with the Bootstrap and Jquery Frameworks</li>
                                    <li>
                                        Experience writing Structured Query Language (SQL) and stored
                                        procedures
                                    </li>
                                    <li>
                                        Experience in performing analysis and requirements gathering with
                                        customers
                                    </li>
                                    <li>
                                        Knowledge of both web-based and client server based systems
                                        architecture.
                                    </li>
                                    <li>
                                        Knowledge/training in object oriented analysis, design and
                                        programming experience
                                    </li>
                                    <li>
                                        Knowledge of application and database development and access methods
                                    </li>
                                    <li>
                                        Ability to prepare detailed written instructions and documentation
                                    </li>
                                    <li>
                                        Ability to effectively make oral and written reports and
                                        presentations and prepare clear and concise documentation
                                    </li>
                                    <li>
                                        Ability to establish and maintain effective relationships with
                                        clients and matrix support teams
                                    </li>
                                    <li>
                                        Ability to resolve complex problems in a timely manner and seeks
                                        optimum solutions
                                    </li>
                                    <li>
                                        Ability to communicate technical terminology at a level appropriate
                                        to the audience
                                    </li>
                                    <li>
                                        Effective at creating highly complex assignments in a high stress
                                        work environment
                                    </li>
                                    <li>Ability to troubleshoot application issues under pressure</li>
                                    <li>
                                        Experience with Microsoft Team Foundation Server and GIT
                                        repositories
                                    </li>
                                    <li>
                                        Experience in DevOps and implementing Continuous Integration and
                                        Delivery (CI/CD)
                                    </li>
                                </ul>
                                <h3 className="title">Advantages of Working@Virtuos</h3>
                                <ul className="list">
                                    <li>
                                        Virtuos has deep expertise in CRM and Customer Experience Consulting
                                        - It’s likely to become largest Applications Business valued at
                                        US$45B per year. Candidates will gain significant exposure from our
                                        best practices, top-notch alliances, and direct hands-on interaction
                                        with leading brands
                                    </li>
                                    <li>
                                        The candidates will have exposure to the cutting edge solutions in
                                        broad based verticals
                                    </li>
                                    <li>
                                        The role also accords a deep understanding of Customer's business
                                        processes, and technology adaptation providing an ample room for
                                        candidate's overall growth
                                    </li>
                                    <li>
                                        Global exposure and working on best business practices with MNC and
                                        Enterprise/Corporate Customers in diverse verticals
                                    </li>
                                </ul>
                            </div>
                            <div className="col-3 job-application-wrapper sticky-content">
                             <CommonApplyForJob/>
                            </div>
                        </div>
                    </section>
                 
                    {/*?php include '../../footer.php';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default NetDeveloper
