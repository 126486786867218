import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import Enquire from "./Forms/Enquire";
import backgroundImage from "../../img/svg/digital-header-bg.svg";

const Digital = () => {
  const [activeItem, setActiveItem] = useState(1);

  const handleItemClick = (index) => {
    if (activeItem === index) {
      setActiveItem(null);
    } else {
      setActiveItem(index);
    }
  };
  const [activePhase, setActivePhase] = useState('phase-1');

  const handleClick = (event) => {
    const relValue = event.currentTarget.getAttribute('rel');
    setActivePhase(relValue);
      if (relValue === "phase-1") {
    setHoveredItem("step-1");
  } else if (relValue === "phase-2" || relValue === "phase-3") {
    setHoveredItem(relValue === "phase-2" ? "step-5" : "step-10");
  }
  };


  const [hoveredItem, setHoveredItem] = useState("step-1");

  const handleMouseEnters = (rel) => {
    setHoveredItem(rel);
  };  

  const [showExtraLinks, setShowExtraLinks] = useState(false);
  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };
  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };
  useEffect(() => {
    document.title = "Digital Transformation Services | Digital Strategies | CX.Digital  – Virtuos Digital";
  }, []);

  return (
    <div className="digital-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          {/* <div id="particle-canvas"></div> */}
          <section className="digital-header-img digital-bg top-hero-product-banner" style={{backgroundImage: `url(${backgroundImage}), linear-gradient(90deg, #b6fbff, #83a4d4)`, backgroundPosition: 'right',backgroundRepeat: "no-repeat",backgroundSize: "cover"}}>
            <div className="row">
              <div className="col-4-half content-wrapper">
                <div className="content-wrap">
                  <h1 style={{fontWeight:"500",lineHeight:"1",fontSize:"2.5em"}}>
                    At Virtuos <strong style={{fontWeight:"500",lineHeight:"1"}}>Vedam</strong>, we deliver Digital Marketing
                    Strategies.
                  </h1>
                </div>
              </div>
              <figure className="right-fixed-logo">
                <img
                  src={`/img/svg/icons/vedam-logo.svg`}
                  alt="Vedam"
                />
              </figure>
              <figure className="left-fixed-bg">
                <img
                  src={`/img/svg/digital-header-right-bg.svg`}
                  alt="CX.Digital"
                />
              </figure>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/cx-digital/`}>CX.Digital</NavLink>
                </li>
                <li>
                  <NavLink to={`/digital/`} className="active">
                    DIGITAL STRATEGY
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/cx/strategy-design/`}>
                    CX STRATEGY &amp; DESIGN
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/cxnow/`}>CXNOW TRANSFORMATION</NavLink>
                </li>

              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" />  More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/indicial/`}>
                          INDICIAL DIGITAL MATURITY
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/cxnow/cxprimer/`}>CX PRIMER</NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="tab-content-wrapper">
            {/* Digital Transformation */}
            <div id="digital-section" className="tab-content active animate">
              <div className="light-blue-bg digital-process-section">
                <h2 className="section-main-heading">
                  VEDAM DELIVERS <span className="color-highlight">WOW CX</span> WITH
                  DIGITAL MARKETING
                </h2>
                <span className="section-desc">
                  Customer Experience makes or breaks organizations in the digital age.
                  Fail to execute, and results can be dire. But succeed, and it will be
                  a marvelous feat. Here are the three phases of roadmap application
                  leaders focused on CX and Digital that should support customer-centric
                  strategies. Virtuos Vedam is a strategic business unit of Virtuos to
                  deliver leading-edge Digital Marketing strategies.
                </span>
                <div className="row page-wrap digital-program-wrapper">
                  {/* Clickable Tabs */}
                  <div className="col-3 digital-process-tabs-wrapper vertical-clickable-tabs offport-left">
                    <ul>
                      <li rel="phase-1"
                        className={`item blue  ${activePhase === 'phase-1' ? 'active' : ''}`} onClick={handleClick}
                      >
                        <h3>1-4</h3>
                        <span>Phase 1</span>
                        <i>Condition stakeholders for change</i>
                      </li>
                      <li rel="phase-2"
                        className={`item pink  ${activePhase === 'phase-2' ? 'active' : ''}`} onClick={handleClick}
                      >
                        <h3>5-9</h3>
                        <span>Phase 2</span>
                        <i>Contextualize the change to your business</i>
                      </li>
                      <li rel="phase-3"
                        className={`item green  ${activePhase === 'phase-3' ? 'active' : ''}`} onClick={handleClick}
                      >
                        <h3>10-13</h3>
                        <span>Phase 3</span>
                        <i>Translate the vision into action</i>
                      </li>
                    </ul>
                  </div>
                  <div className="col-9 vertical-tab-content-wrapper digital-process-content-wrapper offport-right">
                    {/* Phase 1 */}
                    <div id="phase-1"
                      className={`vertical-tab-content blue  ${activePhase === 'phase-1' ? 'active' : ''}`}
                    >
                      {/* Hoverable Tabs */}
                      <div className="hoverable-tabs steps-wrapper">
                        <ul className="row">
                          <li rel="step-1" className={`item ${hoveredItem === 'step-1' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-1')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/1.svg`}
                                  alt="Digital Strategy - Scan the environment"
                                />
                              </span>
                              <h4>
                                <strong>1:</strong> Scan the environment
                              </h4>
                            </div>
                          </li>
                          <li rel="step-2" className={`item ${hoveredItem === 'step-2' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-2')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/2.svg`}
                                  alt="Digital Strategy - Identify punctuated shifts"
                                />
                              </span>
                              <h4>
                                <strong>2:</strong> Identify punctuated shifts
                              </h4>
                            </div>
                          </li>
                          <li rel="step-3" className={`item ${hoveredItem === 'step-3' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-3')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/3.svg`}
                                  alt="Digital Strategy - Identify high-performing exemplars"
                                />
                              </span>
                              <h4>
                                <strong>3:</strong> Identify high-performing exemplars
                              </h4>
                            </div>
                          </li>
                          <li rel="step-4" className={`item ${hoveredItem === 'step-4' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-4')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/4.svg`}
                                  alt="Digital Strategy - Craft a visual narrative"
                                />
                              </span>
                              <h4>
                                <strong>4:</strong> Craft a visual narrative
                              </h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="hoverable-content-wrapper digital-steps-wrapper">
                        {/* Step 1 */}
                        <div id="step-1" className={`hoverable-tab-content ${
                        hoveredItem === 'step-1' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-01-img.jpg`}
                                alt="Digital Strategy - Scan the environment"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">1</span>
                                <h3>SCAN THE ENVIRONMENT</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  The world is changing around you, but some of your
                                  stakeholders may not see it. Start with a broad sweep
                                  of your environment, considering social,
                                  technological, economic, environmental, and political
                                  factors, and then narrow to your customers,
                                  competitors, and contesters.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p className="source">Source: Gartner</p>
                        </div>
                        <div id="step-2" className={`hoverable-tab-content ${
                        hoveredItem === 'step-2' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-02-img.jpg`}
                                alt="Digital Strategy - Identify punctuated shifts"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">2</span>
                                <h3>IDENTIFY PUNCTUATED SHIFTS</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  Identify which opportunities and threats are most
                                  relevant to your organization. Then, note the contrast
                                  between what "was" or "is" and what "will be" so
                                  stakeholders see the trajectory of change.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                        <div id="step-3" className={`hoverable-tab-content ${
                        hoveredItem === 'step-3' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-03-img.jpg`}
                                alt="Digital Strategy - Identify high-performing exemplars"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">3</span>
                                <h3>IDENTIFY HIGH-PERFORMING EXEMPLARS</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  Home in on solid examples to help stakeholders make
                                  the connection between these abstract ideas and what’s
                                  being put into practice. Which competitors or industry
                                  stand-outs are recognized for their innovations or
                                  best practices? How do these examples apply directly
                                  to your business?
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                        <div id="step-4" className={`hoverable-tab-content ${
                        hoveredItem === 'step-4' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-04-img.jpg`}
                                alt="Digital Strategy - Craft a visual narrative"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">4</span>
                                <h3>CRAFT A VISUAL NARRATIVE</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  Once you’ve selected the most realistic examples for
                                  your context, build your narrative. Use bold, iconic
                                  images to tell a highly visual story that engages your
                                  stakeholders emotionally, as well as logically.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                      </div>
                    </div>
                    {/* Phase 2 */}
                    <div id="phase-2"
                      className={`vertical-tab-content pink  ${activePhase === 'phase-2' ? 'active' : ''}`}
                    >
                      {/* Hoverable Tabs */}
                      <div className="hoverable-tabs steps-wrapper">
                        <ul className="row">
                          <li rel="step-5" className={`item ${hoveredItem === 'step-5' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-5')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/5.svg`}
                                  alt="Digital Strategy - Evaluate business performance"
                                />
                              </span>
                              <h4>
                                <strong>5:</strong> Evaluate business performance
                              </h4>
                            </div>
                          </li>
                          <li rel="step-6" className={`item ${hoveredItem === 'step-6' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-6')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/6.svg`}
                                  alt="Digital Strategy - Channel your customer"
                                />
                              </span>
                              <h4>
                                <strong>6:</strong> Channel your customer
                              </h4>
                            </div>
                          </li>
                          <li rel="step-7" className={`item ${hoveredItem === 'step-7' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-7')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/7.svg`}
                                  alt="Digital Srategy - Explore job to be done"
                                />
                              </span>
                              <h4>
                                <strong>7:</strong> Explore jobs to be done
                              </h4>
                            </div>
                          </li>
                          <li rel="step-8" className={`item ${hoveredItem === 'step-8' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-8')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/8.svg`}
                                  alt="Digital Strategy - Define a 'to-be' vision"
                                />
                              </span>
                              <h4>
                                <strong>8:</strong> Define a "to-be" vision
                              </h4>
                            </div>
                          </li>
                          <li rel="step-9" className={`item ${hoveredItem === 'step-9' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-9')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/9.svg`}
                                  alt="Digital Strategy - "
                                />
                              </span>
                              <h4>
                                <strong>9:</strong> Evaluate the "as-is" reality
                              </h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="hoverable-content-wrapper digital-steps-wrapper">
                        {/* Step 1 */}
                        <div id="step-5" className={`hoverable-tab-content ${
                        hoveredItem === 'step-5' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-05-img.jpg`}
                                alt="Digital Strategy - Evaluate business performance"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">5</span>
                                <h3>EVALUATE BUSINESS PERFORMANCE</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  To gain traction on broader strategic change, you may
                                  first need to tackle imperatives with nearer-term
                                  consequences. Are negative trends or patterns
                                  affecting company performance? If so, focus here
                                  before you ask for broader change.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                        <div id="step-6" className={`hoverable-tab-content ${
                        hoveredItem === 'step-6' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-06-img.jpg`}
                                alt="Digital Strategy - Channel your customer"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">6</span>
                                <h3>CHANNEL YOUR CUSTOMER</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  Nothing is quite as influential as the voice of the
                                  customer. Surface these insights, in the form of
                                  direct customer feedback and other qualitative
                                  approaches that allow you to uncover latent customer
                                  needs.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                        <div id="step-7" className={`hoverable-tab-content ${
                        hoveredItem === 'step-7' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-07-img.jpg`}
                                alt="Digital Strategy - Explore job to be done"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">7</span>
                                <h3>EXPLORE JOBS TO BE DONE</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  Harvard Business School professor and author Clayton
                                  Christensen’s “jobs to be done” methodology can help
                                  you better understand the real reason your customers
                                  buy your products or services. Look beyond
                                  conventional applications to identify potential new
                                  use cases and revenue streams.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                        <div id="step-8" className={`hoverable-tab-content ${
                        hoveredItem === 'step-8' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-08-img.jpg`}
                                alt="Digital Strategy - Define a 'to-be' vision"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">8</span>
                                <h3>DEFINE A “TO-BE” VISION</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  In steps 1 — 7, you began to create a clear vision for
                                  change. Now, it’s time to bring your future-state
                                  vision to life. Define the big, bold end state as the
                                  galvanizing vision for change.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                        <div id="step-9" className={`hoverable-tab-content ${
                        hoveredItem === 'step-9' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-09-img.jpg`}
                                alt="Digital Strategy - Evaluate the 'as-is' reality"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">9</span>
                                <h3>EVALUATE THE “AS-IS” REALITY</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  Once you’ve defined the “to-be,” take a close look at
                                  the “as-is.” Identify current capabilities, and then
                                  assess the relative maturity against the scope of the
                                  vision. Do a gap analysis to identify where you need
                                  to lift capability maturity.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                      </div>
                    </div>
                    {/* Phase 3 */}
                    <div id="phase-3"
                      className={`vertical-tab-content green  ${activePhase === 'phase-3' ? 'active' : ''}`}
                    >
                      {/* Hoverable Tabs */}
                      <div className="hoverable-tabs steps-wrapper">
                        <ul className="row">
                          <li rel="step-10" className={`item ${hoveredItem === 'step-10' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-10')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/10.svg`}
                                  alt="Digital Strategy - Identify a beachhead"
                                />
                              </span>
                              <h4>
                                <strong>10:</strong> Identify a beachhead
                              </h4>
                            </div>
                          </li>
                          <li rel="step-11" className={`item ${hoveredItem === 'step-11' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-11')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/11.svg`}
                                  alt="Digital Strategy - Align Stakeholders"
                                />
                              </span>
                              <h4>
                                <strong>11:</strong> Align stakeholders
                              </h4>
                            </div>
                          </li>
                          <li rel="step-12" className={`item ${hoveredItem === 'step-12' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-12')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/12.svg`}
                                  alt="Digital Strategy - Define cross-functional work plan"
                                />
                              </span>
                              <h4>
                                <strong>12:</strong> Define cross-functional work plan
                              </h4>
                            </div>
                          </li>
                          <li rel="step-13" className={`item ${hoveredItem === 'step-13' ? 'active' : ''}`}
                        onMouseEnter={() => handleMouseEnters('step-13')}>
                            <div className="item-wrap">
                              <span className="icon">
                                <img
                                  src={`/img/svg/icons/digital-process/13.svg`}
                                  alt="Digital Strategy - Secure and merchandise early wins"
                                />
                              </span>
                              <h4>
                                <strong>13:</strong> Secure and merchandise early wins
                              </h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="hoverable-content-wrapper digital-steps-wrapper">
                        {/* Step 1 */}
                        <div id="step-10" className={`hoverable-tab-content ${
                        hoveredItem === 'step-10' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-10-img.jpg`}
                                alt="Digital Strategy - Identify a beachhead"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">10</span>
                                <h3>IDENTIFY A BEACHHEAD</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  Every transformation requires an entry point. Choose
                                  yours where your current capability and greatest
                                  opportunity meet. By identifying the critical customer
                                  need you are best able to solve, you can create
                                  immediate impact, which in turn can generate
                                  enthusiasm and momentum to propel your vision forward.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                        <div id="step-11" className={`hoverable-tab-content ${
                        hoveredItem === 'step-11' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-11-img.jpg`}
                                alt="Digital Strategy - Align Stakeholders"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">11</span>
                                <h3>ALIGN STAKEHOLDERS</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  Identify the stakeholders you need to bring along in
                                  order of priority — from executives and functional
                                  leaders to informal influencers. Once you have their
                                  backing, activate the change mandate with the right
                                  incentives.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                        <div id="step-12" className={`hoverable-tab-content ${
                        hoveredItem === 'step-12' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-12-img.jpg`}
                                alt="Digital Strategy - Define cross-functional work plan"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">12</span>
                                <h3>DEFINE CROSS-FUNCTIONAL WORK PLAN</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  Executing across silos is one of the more challenging
                                  aspects of change leadership — but it’s also the place
                                  where you can gain real traction. Translate projects,
                                  plans and priorities into a cross-functional work plan
                                  to ensure every department and team member clearly
                                  understands the part they play in realizing the
                                  vision.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                        <div id="step-13" className={`hoverable-tab-content ${
                        hoveredItem === 'step-13' ? 'active' : ''
                    }`}>
                          <div className="content-wrapper">
                            <figure>
                              <img
                                src={`/img/step-13-img.jpg`}
                                alt="Digital Strategy - Secure and merchandise early wins"
                              />
                            </figure>
                            <div className="content-wrap">
                              <div className="title-wrapper">
                                <span className="step-index">13</span>
                                <h3>SECURE AND MERCHANDISE EARLY WINS</h3>
                              </div>
                              <div className="text-wrapper">
                                <p>
                                  Change doesn’t happen by itself. Effective change
                                  leaders secure their victories by organizing every
                                  effort around the stated goal, and then actively
                                  promoting each win to build support, political
                                  capital, and the funding to expand. Build momentum
                                  with your small successes, and you’ll ensure your
                                  digital vision becomes a reality.
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>Source: Gartner</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="digital-services-section">
            <div className="rgt-bg">
              <img src={`/img/svg/turbine-bg.svg`} alt="" />
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                height="288.19"
                width={250}
                id="solar-wind-combi"
              >
                <defs id="defs4">
                  <style
                    id="style2"
                    dangerouslySetInnerHTML={{
                      __html:
                        "\n                            .cls-1 {\n                                fill: #cdc3b9;\n                                mix-blend-mode: multiply\n                            }\n\n                            .cls-1,\n                            .cls-10,\n                            .cls-11,\n                            .cls-2,\n                            .cls-3,\n                            .cls-4,\n                            .cls-8 {\n                                fill-rule: evenodd\n                            }\n\n                            .cls-2 {\n                                fill: #cdd7dc\n                            }\n\n                            .cls-3 {\n                                fill: #b2bbc0\n                            }\n\n                            .cls-4 {\n                                fill: #c3cacd\n                            }\n\n                            .cls-11,\n                            .cls-5,\n                            .cls-8 {\n                                fill: #fff\n                            }\n\n                            .cls-10 {\n                                fill: #26478a\n                            }\n\n                            .cls-11 {\n                                opacity: .2\n                            }\n                            "
                    }}
                  />
                </defs>
                <path
                  className="cls-2"
                  d="M 83.27,87.136 84.25,210.3 a 17.318,17.318 0 0 1 -13.29,0 L 76.47,87.46 Z"
                  id="path8"
                  style={{ fill: "#cdd7dc", fillRule: "evenodd" }}
                />
                <path
                  className="cls-3"
                  d="M 86.19,88.433 H 72.26 V 75.787 c 0,0 1.09,-4.216 6.8,-4.216 5.71,0 7.13,3.891 7.13,3.891 z"
                  id="path10"
                  style={{ fill: "#b2bbc0", fillRule: "evenodd" }}
                />
                <g id="g3790" transform="rotate(280.995 79.62 80.062)">
                  <path
                    style={{ fill: "#c3cacd", fillRule: "evenodd" }}
                    id="path12"
                    d="m 84.19,84.417 0.79,2.125 c 0,0 6.74,2.127 10.83,2.257 0,0 16.62,55.631 16.93,60.751 0.18,2.78 -1.81,2.78 -2.38,1.94 -0.57,-0.84 -29.62,-66.126 -29.62,-66.126 z"
                    className="cls-4"
                  />
                  <path
                    style={{ fill: "#c3cacd", fillRule: "evenodd" }}
                    d="m 74.18,80.162 -2.23,-0.373 c 0,0 -5.45,4.924 -7.61,8.408 0,0 -56.19,-13.56 -60.79,-15.849 -2.49,-1.24 -1.49,-2.963 -0.48,-3.039 1.01,-0.076 72.02,7.387 72.02,7.387 z"
                    className="cls-4"
                    data-name="Shape 526 copy"
                    id="Shape_526_copy"
                  />
                  <path
                    style={{ fill: "#c3cacd", fillRule: "evenodd" }}
                    d="m 82.28,74.452 1.45,-1.747 c 0,0 -2.03,-7.341 -3.95,-10.962 0,0 40.5,-41.6 44.79,-44.423 2.33,-1.527 3.31,0.2 2.87,1.115 C 127,19.35 84.82,76.979 84.82,76.979 Z"
                    className="cls-4"
                    data-name="Shape 526 copy 2"
                    id="Shape_526_copy_2"
                  />
                  <NavLink
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    from="0 79.620003 80.061996"
                    to="360 79.620003 80.061996"
                    dur="10s"
                    repeatCount="indefinite"
                  />
                </g>
                <circle
                  className="cls-5"
                  cx="79.620003"
                  cy="80.061996"
                  r={6}
                  id="circle16"
                  style={{ fill: "#ffffff" }}
                />
                <path
                  className="cls-2"
                  d="m 151.77,98.863 1.15,146.587 a 20.626,20.626 0 0 1 -15.81,0 l 6.56,-146.2 z"
                  id="path18"
                  style={{ fill: "#cdd7dc", fillRule: "evenodd" }}
                />
                <path
                  className="cls-3"
                  d="M 155.24,100.407 H 138.66 V 85.355 c 0,0 1.3,-5.017 8.09,-5.017 6.79,0 8.49,4.631 8.49,4.631 z"
                  id="path20"
                  style={{ fill: "#b2bbc0", fillRule: "evenodd" }}
                />
                <g id="g3795" transform="rotate(144.279 147.42 90.453)">
                  <path
                    style={{ fill: "#b2bbc0", fillRule: "evenodd" }}
                    id="path22"
                    d="m 142.2,92.646 -2.66,0.42 c 0,0 -4.09,7.35 -5.22,12.1 0,0 -68.75,6.062 -74.8,5.2 -3.28,-0.464 -2.79,-2.785 -1.68,-3.251 1.11,-0.466 84.09,-18.723 84.09,-18.723 z"
                    className="cls-3"
                  />
                  <path
                    style={{ fill: "#b2bbc0", fillRule: "evenodd" }}
                    d="m 149.56,81.991 0.97,-2.522 c 0,0 -4.43,-7.539 -7.97,-10.891 0,0 29.26,-62.341 33.03,-67.159 2.04,-2.608 3.8,-1.031 3.65,0.167 -0.15,1.198 -25.87,82.29 -25.87,82.29 z"
                    className="cls-3"
                    data-name="Shape 526 copy"
                    id="Shape_526_copy-2"
                  />
                  <path
                    style={{ fill: "#b2bbc0", fillRule: "evenodd" }}
                    d="m 154.26,92.816 1.69,2.108 c 0,0 9.03,-0.612 13.71,-1.988 0,0 38.72,57.254 40.98,62.944 1.22,3.08 -1.03,3.81 -1.99,3.08 -0.96,-0.73 -57.94,-63.788 -57.94,-63.788 z"
                    className="cls-3"
                    data-name="Shape 526 copy 2"
                    id="Shape_526_copy_2-2"
                  />
                  <NavLink
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    from="0 147.42 90.453003"
                    to="360 147.42 90.453003"
                    dur="7s"
                    repeatCount="indefinite"
                  />
                </g>
                <circle
                  className="cls-5"
                  cx="147.42"
                  cy="90.453003"
                  r="7.1399999"
                  id="circle26"
                  style={{ fill: "#ffffff" }}
                />
              </svg> */}
            </div>
            <div className="row page-wrap">
              <div className="col-4 item-wrapper offport-down">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/cx-strategy-design-img.svg`}
                      alt="CX Strategy & Design"
                    />
                  </figure>
                  <div className="content-wrapper">
                    <h3>CX Strategy &amp; Design</h3>
                    <p>
                      At Virtuos we ideate, design and execute a broad set of CX
                      strategies that lead to increased customer satisfaction, loyalty,
                      and advocacy. Learn how our ten year CX Practice delivers value to
                      your organization.
                    </p>
                    <NavLink
                      to={`/cx/strategy-design/`}

                    >
                      More <i className="fa fa-long-arrow-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-4 item-wrapper offport-down">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/demandgen-sales-enablement-img.svg`}
                      alt="DemandGen and Sales"
                    />
                  </figure>
                  <div className="content-wrapper">
                    <h3>DemandGen and Sales</h3>
                    <p>
                      We apply insights driven Accounts Based Marketing strategies to
                      follow the best practices using Harvest, Acquire, Retain,
                      Efficiency (HARE) methodology. We transform your Sales model to
                      grow its pipe and differentiate themselves.
                    </p>
                    <NavLink to="http://www.weprospect.com" target="_blank">
                      More <i className="fa fa-long-arrow-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-4 item-wrapper offport-down">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/martech-emerging-trends-img.svg`}
                      alt="Martech and New Trends"
                    />
                  </figure>
                  <div className="content-wrapper">
                    <h3>Martech and New Trends</h3>
                    <p>
                      Our Consulting teams help you choose the right marketing mix of
                      technologies and present Experience Canvas with a roadmap of
                      solutions that solve your marketing conundrum. We advise you on
                      emerging trends with deep research networks.
                    </p>
                    <NavLink to="http://www.vedam.com" target="_blank">
                      More <i className="fa fa-long-arrow-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-4 item-wrapper offport-down">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/multi-channel-marketing-img.svg`}
                      alt="Multi-channel Marketing"
                    />
                  </figure>
                  <div className="content-wrapper">
                    <h3>Multi-channel Marketing</h3>
                    <p>
                      Segment your customers by understanding their persona, and analyze
                      their vivid behaviors to align with multi-channel marketing and
                      advertising efforts. We help marketing leaders demonstrate a
                      direct impact on bottom-line performance.
                    </p>
                    <NavLink to="http://www.vedam.com" target="_blank">
                      More <i className="fa fa-long-arrow-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-4 item-wrapper offport-down">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/smart-innovation-insights-img.jpg`}
                      alt="Smart Innovation Insights"
                    />
                  </figure>
                  <div className="content-wrapper">
                    <h3>Smart Innovation Insights</h3>
                    <p>
                      Deliver breakthrough performance by clearly defining your Customer
                      Experience vision and how you can lead the change by inspiring
                      your employees across departments. We engage with you to help in
                      refining and reinforcing Experiencejob<sup>TM</sup>
                      culture.
                    </p>
                    <NavLink to={`/io/`} >
                      More <i className="fa fa-long-arrow-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-4 item-wrapper offport-down">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/powervoice-influencer-marketing-img.svg`}
                      alt="PowerVoice Influencer Marketing"
                    />
                  </figure>
                  <div className="content-wrapper">
                    <h3>PowerVoice™ Influencer Marketing</h3>
                    <p>
                      Virtuos Vedam is developing a new PowerVoice™ Influencer Marketing
                      Model for the brand ambassadors to connect to millions of their
                      fans, and delivering a new Customer Trust Value proposition.
                    </p>
                    <NavLink to="http://www.powervoice.com" target="_blank">
                      More <i className="fa fa-long-arrow-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-breaker" id="marketing">
            <figure>
              <img
                src={`/img/voc.jpg`}
                alt="Virtuos Vedam Digital Marketing Portfolio"
              />
            </figure>
            <div className="content-wrapper">
              <h2>
                {/* Digital Marketing Services */} Virtuos Vedam Digital Marketing
                Portfolio
              </h2>
            </div>
          </section>
          <section className="two-col-full-section accordian-section">
            <div className="row">
              <div className="col-6 illus offport-left">
                <figure>
                  <img
                    src={`/img/digital-marketing-portfolio-img.jpg`}
                    alt="Virtuos Vedam Digital Marketing Portfolio"
                  />
                </figure>
              </div>
              <div className="col-6 content-wrapper offport-right">
                <div className="accordian-wrapper">
                  <div className={`item ${activeItem === 1 ? 'active' : ''}`}>
                    <div className="title" onClick={() => handleItemClick(1)}>
                      <i className="far fa-shopping-cart" />
                      <h3>BUYING</h3>
                    </div>
                    <div className="accordian-content">
                      <div className="row content-block">
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="fas fa-hand-holding-water" />
                          </span>
                          <p className="heading">NEED</p>
                          <p className="txt">Analytics, Customer Insight, VoC</p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="fas fa-search" />
                          </span>
                          <p className="heading">DISCOVER</p>
                          <p className="txt">
                            Ad (Demo, Psycho, Lookalike, Geo), Search, Content, Mobile,
                            Influencer, Social, Multichannel
                          </p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="fas fa-eye" />
                          </span>
                          <p className="heading">AWARE</p>
                          <p className="txt">
                            Ad (Remarketing, Custom Audience), Content, Social, Mobile,
                            Email, Multichannel
                          </p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="far fa-clipboard-check" />
                          </span>
                          <p className="heading">EVALUATE</p>
                          <p className="txt">Ad, SEM, Content, Influencer, Product</p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="fas fa-check-square" />
                          </span>
                          <p className="heading">SELECT</p>
                          <p className="txt">Commerce, Product, Web</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`item ${activeItem === 2 ? 'active' : ''}`}>
                    <div className="title" onClick={() => handleItemClick(2)}>
                      <i className="fas fa-hands-heart" />
                      <h3>OWNING</h3>
                    </div>
                    <div className="accordian-content">
                      <div className="row content-block">
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="far fa-shopping-cart" />
                          </span>
                          <p className="heading">PURCHASE</p>
                          <p className="txt">
                            Payment, Payment Gateway, Taxation, Communication, Trust
                          </p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="fas fa-hand-holding-heart" />
                          </span>
                          <p className="heading">RECEIVE</p>
                          <p className="txt">
                            Distribution, Shipping, Support, Product, Logistics
                          </p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="far fa-clipboard-list" />
                          </span>
                          <p className="heading">ONBOARD</p>
                          <p className="txt">
                            Packaging, Product, Social, Support, Email, Mobile, Loyalty,
                            Support
                          </p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="fas fa-american-sign-language-interpreting" />
                          </span>
                          <p className="heading">USE</p>
                          <p className="txt">
                            Product, Loyalty, Support, Email, Content, Social, Mobile
                          </p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="far fa-compress" />
                          </span>
                          <p className="heading">DEEPEN</p>
                          <p className="txt">
                            Loyalty, Support, Email, Content, Social, Mobile
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`item ${activeItem === 3 ? 'active' : ''}`}>
                    <div className="title" onClick={() => handleItemClick(3)}>
                      <i className="fas fa-heart" />
                      <h3>ADVOCACY</h3>
                    </div>
                    <div className="accordian-content">
                      <div className="row content-block">
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="fas fa-dollar-sign" />
                          </span>
                          <p className="heading">RATE</p>
                          <p className="txt">Email, Product, Social, Web</p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="fas fa-share-alt" />
                          </span>
                          <p className="heading">SHARE</p>
                          <p className="txt">Social, Influencer, Product, Content</p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="fas fa-thumbs-up" />
                          </span>
                          <p className="heading">RECOMMEND</p>
                          <p className="txt">Product, Social, Content, Influencer</p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="far fa-hand-holding-box" />
                          </span>
                          <p className="heading">DEMONSTRATE</p>
                          <p className="txt">Product, Social, Content, Influencer</p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="far fa-compress" />
                          </span>
                          <p className="heading">DEEPEN</p>
                          <p className="txt">Product, Social, Content, Influencer</p>
                        </div>
                        <div className="col-4 item-block">
                          <span className="icon">
                            <i className="far fa-heart" />
                          </span>
                          <p className="heading">LOVE</p>
                          <p className="txt">Referral, Participate, Product, Content</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="full-width-container">
            <div className="our-products indicial">
              <div className="row page-wrap">
                <div className="col-2 offport-left">
                  <figure className="illus">
                  </figure>
                </div>
                <div className="col-8 info offport-down">
                  <span>Assess CX.Digital Maturity With Virtuos Indicial</span>
                </div>
                <div className="col-2 offport-right">
                  <NavLink
                    to={`/indicial`}
                    className="btn btn-primary"
                  >
                    Yes, I want to Explore
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <div className="page-wrap cf-form-wrapper">
            <h3 className="section-main-heading">
              Let’s connect to build{" "}
              <span className="highlight">Experience Nation</span>
            </h3>
            <span className="section-desc">
              Virtuos is your strategic{" "}
              <NavLink to={`/cx-digital`} className="link">
                CX.Digital
              </NavLink>{" "}
              Integrated partner in building your Marketing Technology Roadmap for the
              future.
            </span>
            <Enquire />

          </div>
          {/*?php include '../footer-consultare.php';?*/}
          <Footer />
        </main>

      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  )
}

export default Digital