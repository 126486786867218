import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Launches_Innovation_Open = () => {
    useEffect(() => {
        document.title = "Virtuos launches 'Innovation Open' (io) as part of new vision 2020 – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-launches-innovation-open-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 23 June, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos launches "Innovation Open"(io) as part of new vision 2020
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos, an idea hunter and business innovation leader reached new
                                    heights with tremendous growth helping next generation companies
                                    across the world — be it PartyGaming in Gibraltar, EMEA; NUS in
                                    Singapore; NZPost in NewZealand; or MakeMyTrip, Flipkart, Myntra, etc.
                                    in India.
                                </p>
                                <p>
                                    Virtuos with its new Virtuos.io vision has set a goal to become a
                                    Billion dollar company by 2025.
                                </p>
                                <p>
                                    Over the last four to five years since inception in 2008, Virtuos had
                                    built digital assets investing in new ideas and people. Virtuos.io is
                                    one of the four fabulous divisions besides Virtuos Consultare; Virtuos
                                    Cloud; and Yippee Media under the umbrella of Virtuos Corporation.
                                    Virtuos.io will be our incubation center hunting more ideas and
                                    maintaining innovation labs in six key areas of newest hot
                                    specializations.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-business-3.0-frame.jpg`}
                                        alt="Virtuos Business 3.0"
                                    />
                                </figure>
                                <br />
                                <p>
                                    <NavLink to="http://www.definite.com" target="_blank" className="link">
                                        <strong className="underline">Definite.com</strong>
                                    </NavLink>{" "}
                                    — The popular digital asset will now become our Marketing Engagement
                                    Platform. The domain name valued more than US$500k will be used to
                                    launch the most disruptive concepts of digital, social, and web
                                    marketing with a superior approach of engaging with customers.
                                </p>
                                <p className="italic">
                                    "Definite.com will hold the future for Virtuos which has always
                                    launched new concepts and ideas to the marketplace, and with the
                                    breathtaking approach &amp; catchy name, Virtuos will shine in the
                                    enterprise software innovation space" - says Venky Vijay, CEO of
                                    Virtuos.
                                </p>
                                <p>
                                    <NavLink to="http://www.vyo.com" target="_blank" className="link">
                                        <strong className="underline">VYO.com</strong>
                                    </NavLink>{" "}
                                    — The three lettered premium name valued at US$1 Million will be our
                                    new Product solving daily routines with Digital Twin Technology. VYO
                                    also offers Voice Your Opinion Service.
                                </p>
                                <p className="italic">
                                    "The Social Media Opinions business is surpassing US$1Billion annually
                                    and Virtuos wants to position VYO.com with new disruptive ideas and
                                    execution" - says Venky Vijay, CEO of Virtuos.
                                </p>
                                <p>
                                    <NavLink to="http://www.rigour.com" target="_blank" className="link">
                                        <strong className="underline">Rigour.com</strong>
                                    </NavLink>{" "}
                                    — As the name suggests, Rigour.com will be our newest Sales Engagement
                                    Cloud enabling salesforce with new leads using social mobile cloud.
                                </p>
                                <p className="italic">
                                    "Traditional Sales force management applications provide automation
                                    around leads, opportunities, territory, etc., but our Rigour.com will
                                    provide sales discipline nurturing leadgen engine using market
                                    intelligence, analytics and social mobile cloud" - says Venky Vijay,
                                    CEO of Virtuos.
                                </p>
                                <p>
                                    <NavLink to="http://customer.me" target="_blank" className="link">
                                        <strong className="underline">Customer.me</strong>
                                    </NavLink>{" "}
                                    — Our yet another most ambitious project to deliver an exceptional
                                    connected customer engagement platform with powerful community,
                                    socialweb self-help, Knowledge management and channel mix of email,
                                    chat, click2talk, callback and other engagements. This will be the
                                    most sophisticated platform freely available for customers to sign and
                                    engage with global brands. With tight integration of other engagement
                                    platforms such as Definite.com, VYO.com, etc.,{" "}
                                    <strong>Customer.me</strong> will become the most handy yet powerful
                                    portable tool for customers on their browsers, tablets, social network
                                    and mobile.
                                </p>
                                <p className="italic">
                                    "The connected customer experience is the hot topic right now, and the
                                    engagement model with empowered customers will change the equation.
                                    The customer aka consumer with hundreds of products and services from
                                    several brands is always perplexed with no proactive service
                                    engagement, and that's where Customer.me will become an army swiss
                                    knife for every one." - says Venky Vijay, CEO Virtuos.
                                </p>
                                <p>
                                    <NavLink to="http://www.giftcart.com" target="_blank" className="link">
                                        <strong className="underline">Giftcart.com</strong>
                                    </NavLink>{" "}
                                    — - where Virtuos is a major shareholder will transform itself to
                                    become Gifting Experiences Engagement Platform. Virtuos will raise
                                    additional funds this year to expand the scope and horizons of
                                    Giftcart.com operations to more geographies and build engagement
                                    platforms using our transformational technologies.
                                </p>
                                <p className="italic">
                                    "Virtuos invested nearly US$ 500k directly and through the Tekcorp
                                    route in the last 18 months in Giftcart.com to build an ecommerce
                                    platform, content, maintain staf,f and infrastructure. Virtuos
                                    management will invest another US$500k in this year to develop a
                                    powerful engagement platform and develop B2B model for Giftcart.com" -
                                    says Venky Vijay, CEO, Virtuos.
                                </p>
                                <p>
                                    Giftcart.com will become a one stop shop for all gifting ideas and
                                    with powerful engagement platforms available on browser, mobile,
                                    social media, and tablets, Giftcart.com will become a new destination
                                    in the burgeoning e-commerce space.
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services, and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely — Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas, and executing them with Virtuoso teams. Virtuos was
                                    founded in 2008 by cultivating an ambitious plan to become a
                                    multi-billion dollar company and a game changer by 2020. Thanks to the
                                    hard work and commitment of our team we continue to grow each year.
                                    With new innovative business ideas, delivering incredible
                                    transformation services and building powerful engagement platforms for
                                    the future we are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink
                                        to="http://www.fb.com/virtuos"
                                        target="_blank"
                                        className="link"
                                        rel="noreferrer"
                                    >
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Launches_Innovation_Open