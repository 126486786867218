import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PartnershipIcon from "./PartnershipIcon";

const Logowrapper = () => {
  const location = useLocation();
  const endpoint = location.pathname.slice(1);

  return (
    <>
      <div className="logo-wrapper">
        {/* <NavLink to="${process.env.REACT_APP_BASE_URL}" className="brand-logo"> */}
        <NavLink to={`/`} className="brand-logo">
          <span className="logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 428.3 73.9"
            >
              <defs>
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      "\n\t\t\t\t\t\t.virtuos-1 {\n\t\t\t\t\t\t\tfill: #00a1fe;\n\t\t\t\t\t\t}\n\n\t\t\t\t\t\t.virtuos-2 {\n\t\t\t\t\t\t\tfill: #00a2fe;\n\t\t\t\t\t\t}\n\n\t\t\t\t\t\t.virtuos-3 {\n\t\t\t\t\t\t\tfill: url(#virtuos-logo);\n\t\t\t\t\t\t}\n\n\t\t\t\t\t\t.virtuos-4 {\n\t\t\t\t\t\t\tfill: url(#virtuos-logo-2);\n\t\t\t\t\t\t}\n\n\t\t\t\t\t\t.virtuos-5 {\n\t\t\t\t\t\t\tfill: url(#virtuos-logo-3);\n\t\t\t\t\t\t}\n\n\t\t\t\t\t\t.virtuos-6 {\n\t\t\t\t\t\t\tfill: url(#virtuos-logo-4);\n\t\t\t\t\t\t}\n\t\t\t\t\t",
                  }}
                />
                <linearGradient
                  id="virtuos-logo"
                  x1="55.96"
                  y1="99.89"
                  x2="108.89"
                  y2="8.57"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset={0} stopColor="#ee0278" stopOpacity="0.9" />
                  <stop offset={1} stopColor="#e72429" stopOpacity="0.9" />
                </linearGradient>
                <linearGradient
                  id="virtuos-logo-2"
                  x1="33.03"
                  y1="-5.75"
                  x2={70}
                  y2="80.11"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset={0} stopColor="#279200" stopOpacity="0.9" />
                  <stop offset={1} stopColor="#f8ec1d" stopOpacity="0.9" />
                </linearGradient>
                <linearGradient
                  id="virtuos-logo-3"
                  x1="-0.8"
                  y1="14.57"
                  x2="68.56"
                  y2="91.34"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset={0} stopColor="#00a1fe" stopOpacity="0.9" />
                  <stop offset={1} stopColor="#83c5f1" stopOpacity="0.9" />
                </linearGradient>
                <linearGradient
                  id="virtuos-logo-4"
                  x1="57.37"
                  y1="74.87"
                  x2="64.67"
                  y2="-2.28"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset={0} stopColor="#f8ec1d" stopOpacity="0.9" />
                  <stop offset={1} stopColor="#e72429" stopOpacity="0.9" />
                </linearGradient>
              </defs>
              <title>Virtuos Digital Ltd.</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path
                    className="virtuos-1"
                    d="M92.3,17.8v-4.5c0-1.2,0.4-1.4,1.5-1.4c4.7,0.1,8.4,2.2,11.8,5.3c4.6,4.2,7.7,9.5,10.5,14.9
			c2.2,4.3,4.2,8.7,6.3,13c1.1,2.6,3.3,4.6,5.9,5.5c3.7,1.1,7.6-0.8,9.8-4.8c1.7-3.1,3.2-6.3,4.8-9.5c2.9-5.6,5.9-11.1,10-16
			c3.8-4.4,8.3-7.8,14.4-8.3c1.7-0.1,2.2,0.2,2.2,2v7.6c0.1,0.8-0.3,1.5-1,1.8l-0.4,0.1h-0.1c-4.6,1.4-7.9,4.6-10.4,8.5
			s-4.2,8.2-6.2,12.4c-1.7,3.9-4,7.6-6.8,10.8c-8.6,9.1-21.1,8.7-29.1-0.9c-2.8-3.3-4.4-7.3-6-11.1c-1.4-3.5-2.9-7-4.7-10.4
			c-2.5-4.4-6-7.7-11.1-8.9c-0.9-0.1-1.5-0.9-1.3-1.8L92.3,17.8z"
                  />
                  <path
                    className="virtuos-1"
                    d="M320,39.7c-1.5,12.2-10.6,20.7-22.9,22c-12.1,1.1-23.3-6.6-26.6-18.3c-0.6-2.3-0.9-4.7-0.9-7.1
			c0-7.5,0-15-0.1-22.5c0-1.7,0.5-1.9,2-1.6c4.9,0.9,8.4,5.1,8.5,10.1c0.1,4.8,0.1,9.6,0.1,14.3c0.1,8.8,6.5,15.3,14.9,15.2
			c8.2-0.1,14.6-6.8,14.6-15.4V22.4c0-5,3.5-9.3,8.4-10.3c1.5-0.3,2.2-0.2,2.2,1.6c-0.1,4.1,0,8.3,0,12.4
			C320.2,26.1,320.5,35.2,320,39.7z"
                  />
                  <path
                    className="virtuos-1"
                    d="M379,61.8h-7c-1.2,0-1.6-0.2-1.4-1.5c0.7-4.5,4.4-7.9,8.9-8.3c1.3-0.1,2.7-0.1,4.1-0.2
			c5.9-0.4,10.5-5.3,10.6-11.2c0.1-3.7-0.2-7.4,0.1-11c1.1-10.2,9.1-17.3,19.6-17.6c4.3-0.1,8.6,0,12.9-0.1c1.2,0,1.5,0.4,1.5,1.6
			v6.8c0,1.3-0.1,1.7-1.5,1.7c-3.5-0.1-7.1,0-10.6,0c-6.4-0.1-11.7,5.1-11.7,11.5c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3
			c-0.1,4.1,0.4,8.3-0.3,12.3c-1.8,8.9-9.4,15.5-18.5,15.8C383.4,61.8,381.2,61.7,379,61.8L379,61.8z"
                  />
                  <path
                    className="virtuos-1"
                    d="M230.3,1.8c0-1.7,0.6-1.9,2-1.7c4.4,0.8,7.7,4.3,8.5,8.7c0.1,1.1,0.2,2.2,0.2,3.3c0,8.3,0,16.6,0.1,24.9
			c0,8.7,6.3,14.9,15.1,14.9c2.2-0.1,4.4,0,6.6,0.2c3.9,0.8,7,3.9,7.9,7.8c0.2,1.3,0,1.9-1.5,1.8c-5.8-0.1-11.5,0.3-17.4-0.3
			c-11.3-1.4-21-11.7-21.3-23C230.3,32.4,230.5,8,230.3,1.8z"
                  />
                  <path
                    className="virtuos-1"
                    d="M187.2,33c1.4-11.6,11.4-20.2,23.1-20.9c5.1-0.2,10.2,0,15.3-0.1c1.5,0,1.4,0.8,1.2,1.7
			c-0.8,4.5-4.5,7.8-9.1,8.1c-2.4,0.1-4.8,0-7.1,0.2c-7.6,0.8-13.3,7.2-13.2,14.8v15.1c-0.2,4.9-3.8,9-8.6,9.8c-1.4,0.2-2,0-1.9-1.6
			C187,55.9,186.6,37.9,187.2,33z"
                  />
                  <path
                    className="virtuos-1"
                    d="M183.4,60.5c0,1.2-0.3,1.4-1.4,1.3c-4.6-0.6-8.3-4.3-9-8.9c-0.1-0.9-0.2-1.8-0.2-2.7c-0.1-12.2,0-24.3,0-36.5
			c0-1.5,0.2-1.9,1.7-1.6c5,0.8,8.7,5.1,8.9,10.2C183.5,28.5,183.4,54,183.4,60.5z"
                  />
                  <path
                    className="virtuos-1"
                    d="M260.4,11.9c1.1,0,1.5,0.3,1.3,1.5c-0.7,4.3-4,7.6-8.3,8.3c-1.5,0.1-3,0.1-4.5,0c-2.5-0.2-4.5-2.4-4.4-4.9
			c0.1-2.5,2-4.5,4.5-4.8c1-0.1,2.1,0,3.1,0S258.6,11.9,260.4,11.9z"
                  />
                  <path
                    className="virtuos-1"
                    d="M350.3,11.9c-14.4-0.6-26.1,11-26.5,24C323.3,51,336,61.7,348.1,61.8c14.6,0.2,26.1-10.4,26.4-24.7
			C374.7,23.9,363.5,12.5,350.3,11.9z M349,51.9c-8.3,0-15-6.6-15.1-14.9s6.6-15,14.9-15.1s15,6.6,15.1,14.9c0,0,0,0,0,0v0.1
			c0.1,8.2-6.5,14.9-14.7,15c0,0,0,0,0,0H349z"
                  />
                  <path
                    className="virtuos-1"
                    d="M406.9,58.6v-6.5h2.9c0.4,0,0.9,0,1.3,0.2c0.3,0.1,0.6,0.3,0.7,0.6c0.2,0.3,0.3,0.6,0.3,1
			c0,0.4-0.2,0.9-0.5,1.2c-0.4,0.3-0.9,0.6-1.4,0.6c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.3,0.5,0.6,0.7,0.9l1.1,1.8h-1.1l-0.9-1.4
			c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.2-0.3-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2,0-0.3,0-0.5,0h-1v2.9L406.9,58.6L406.9,58.6z
			 M407.8,55h1.8c0.3,0,0.6,0,0.9-0.1c0.5-0.2,0.8-0.7,0.6-1.3c-0.1-0.2-0.2-0.3-0.3-0.4c-0.3-0.2-0.7-0.3-1.1-0.3h-2L407.8,55
			L407.8,55z"
                  />
                  <path
                    className="virtuos-1"
                    d="M415.6,55.4h-0.4c0,3-2.4,5.5-5.4,5.5c-1.5,0-2.9-0.6-3.9-1.6c-2.2-2.1-2.2-5.6,0-7.8c1-1,2.4-1.6,3.9-1.6
			c1.4,0,2.8,0.6,3.9,1.6c1,1,1.6,2.4,1.6,3.9h0.8c0-3.5-2.8-6.3-6.3-6.3s-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3l0,0
			c3.5,0,6.3-2.8,6.3-6.3v0L415.6,55.4z"
                  />
                  <g className="brand-heart">
                    <path
                      className="virtuos-3"
                      d="M81.6,11.8c-0.2-0.5-0.4-1-0.7-1.5v-0.1c-0.2-0.4-0.4-0.7-0.6-1.1L80.2,9c-0.2-0.3-0.4-0.7-0.6-1l-0.1-0.2
				C79.2,7.6,79,7.3,78.7,7l-0.1-0.2c-0.3-0.3-0.5-0.6-0.7-0.9l-0.1-0.1c-0.3-0.3-0.5-0.5-0.8-0.8l-0.2-0.1
				c-0.3-0.2-0.6-0.5-0.8-0.7l-0.3-0.2c-0.3-0.2-0.5-0.4-0.8-0.6l-0.5-0.3l-0.6-0.4c-0.3-0.2-0.5-0.3-0.8-0.5l-0.3-0.2
				c-0.5-0.2-1-0.5-1.5-0.6c0.8,3.1,1.1,6.3,0.9,9.5c-0.4,7.1-2.4,14-5.8,20.3c-2.1,3.9-4.6,7.6-7.5,11.1
				c-5.1,6.1-10.9,11.5-17.4,16.1c0,0-4.8,3.8-10.8,7.7c3.5,2.8,7.1,5.4,10.8,7.9c3.7-2.4,7.3-5.1,10.8-7.9c5-4,9.7-8.4,14.1-13.1
				c3-3.2,5.7-6.7,8.1-10.3c4.9-7.4,8.4-15.7,8.4-24.4C82.9,16.1,82.4,13.9,81.6,11.8z"
                    />
                    <path
                      className="virtuos-4"
                      d="M73.8,41.6c-2.3-3.6-4.8-7.1-7.5-10.4l0,0C56.5,19,38.2,0.2,18.1,0.2c-2.2,0-4.4,0.4-6.5,1.2
				c-0.5,0.2-1,0.4-1.5,0.6L9.9,2.2C9.6,2.4,9.4,2.5,9.1,2.7L8.5,3.1L8,3.4C7.8,3.6,7.5,3.8,7.2,4L7,4.2C6.7,4.4,6.4,4.6,6.1,4.9
				L6,5C5.7,5.3,5.4,5.6,5.1,5.8L5,6C4.7,6.3,4.5,6.5,4.3,6.9L4.1,7C3.9,7.3,3.7,7.6,3.4,7.9L3.3,8.1c-0.2,0.3-0.4,0.6-0.6,1
				L2.6,9.2c-0.2,0.4-0.4,0.7-0.6,1.1v0.1c-0.2,0.5-0.5,1-0.7,1.5c3.1-0.8,6.3-1.1,9.5-0.9c10.8,0.5,29.1,5.6,47.5,30.7l0.6,0.7l0,0
				c1.2,1.6,4.4,5.8,7.5,10.7c3-3.2,5.7-6.7,8.1-10.3C74.1,42,73.8,41.6,73.8,41.6z"
                    />
                    <path
                      className="virtuos-5"
                      d="M41.4,58.5C35,53.8,29.1,48.4,24,42.3c-2.9-3.4-5.4-7.1-7.5-11.1C13.2,25,11.2,18.1,10.8,11
				c-0.2-3.2,0.1-6.4,0.9-9.5c-0.5,0.2-1,0.4-1.5,0.6L9.9,2.2C9.6,2.4,9.4,2.5,9.1,2.7L8.5,3.1L8,3.4C7.8,3.6,7.5,3.8,7.2,4L7,4.2
				C6.7,4.4,6.4,4.6,6.1,4.9L6,5C5.7,5.3,5.4,5.6,5.1,5.8L5,6C4.7,6.3,4.5,6.5,4.3,6.9L4.1,7C3.9,7.3,3.7,7.6,3.4,7.9L3.3,8.1
				c-0.2,0.3-0.4,0.6-0.6,1L2.6,9.2c-0.2,0.4-0.4,0.7-0.6,1.1v0.1c-0.2,0.5-0.5,1-0.7,1.5C0.4,13.9,0,16.1,0,18.3
				c0,8.6,3.5,16.9,8.4,24.4c2.4,3.6,5.2,7.1,8.1,10.3c4.4,4.7,9.1,9.1,14.1,13.1c3.5,2.8,7.1,5.4,10.8,7.9
				c3.7-2.4,7.4-5.1,10.8-7.9C46.2,62.3,41.4,58.5,41.4,58.5z"
                    />
                    <path
                      className="virtuos-6"
                      d="M80.9,10.3L80.9,10.3c-0.2-0.4-0.4-0.8-0.6-1.2L80.2,9c-0.2-0.3-0.4-0.7-0.6-1l-0.1-0.2
				C79.2,7.6,79,7.3,78.7,7l-0.1-0.1c-0.3-0.3-0.5-0.6-0.7-0.9l-0.1-0.1c-0.3-0.3-0.5-0.5-0.8-0.8l-0.2-0.1
				c-0.3-0.2-0.6-0.5-0.8-0.7l-0.3-0.2c-0.3-0.2-0.5-0.4-0.8-0.6l-0.5-0.3l-0.6-0.4c-0.3-0.2-0.5-0.3-0.8-0.5l-0.3-0.2
				c-0.5-0.2-1-0.5-1.5-0.6c-2.1-0.8-4.3-1.2-6.5-1.2C44.7,0.2,26.4,19,16.6,31.2l0,0c-2.7,3.3-5.2,6.8-7.5,10.4c0,0-0.2,0.4-0.6,1
				c2.4,3.6,5.2,7.1,8.1,10.3c3.1-4.9,6.2-9.1,7.5-10.7l0,0l0.6-0.7c18.3-25,36.7-30.2,47.5-30.7c3.2-0.2,6.4,0.1,9.5,0.9
				C81.4,11.3,81.2,10.8,80.9,10.3z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <h1 className="visuallyhidden">Virtuos</h1>
        </NavLink>

        {endpoint === "talentare/" && !endpoint.includes("news") && (
          <>
            <NavLink className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/consultare/`} className="page-title alliance-logo">
              <img src={`/img/svg/consultare-logo.svg`} alt="Consultare" />
            </NavLink>
          </>
        )}

        {/* for the buisness */}
        {(endpoint === "business" ||
          endpoint === "business/" ||
          endpoint === "industry/" ||
          endpoint === "invincible") && (
          <NavLink to={`/business`} className="page-title">
            The Business
          </NavLink>
        )}
        {(endpoint === "solutions" || endpoint === "solutions/") && (
          <NavLink to={`/business`} className="page-title">
            Business
          </NavLink>
        )}
        {(endpoint === "news/" || endpoint === "org/") && (
          <NavLink to={`/corporate`} className="page-title">
            The Company
          </NavLink>
        )}

        {/* for the agiloft */}
        {(endpoint === "agiloft" || endpoint === "agiloft/") && (
          <>
            <NavLink href={`/alliances`} className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink href={`/agiloft/`} className="page-title alliance-logo">
              <img src={`/img/svg/icons/agiloft-logo.svg`} alt="Agiloft" />
            </NavLink>
          </>
        )}
        {/* for the salesforce */}
        {(endpoint === "_salesforce" || endpoint === "_salesforce/") && (
          <>
            <NavLink to={`/alliances`} className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/salesforce/`} className="page-title alliance-logo">
              <img
                src={`/img/svg/salesforce-logo.svg`}
                alt="Salesforce"
                style={{ width: 60 }}
              />
            </NavLink>
          </>
        )}

        {(endpoint.includes("corporate") ||
          endpoint === "insights" ||
          endpoint === "contact" ||
          endpoint === "branding" ||
          endpoint === "investors" ||
          endpoint === "customers" ||
          endpoint === "news" ||
          endpoint === "news/events/" ||
          endpoint === "customers" ||
          endpoint.includes("customers/")) && (
          <NavLink to={`/corporate`} className="page-title">
            The Company
          </NavLink>
        )}
        {(endpoint === "creatio" || endpoint === "creatio/") && (
          <>
            <NavLink to={`/alliances`} className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/creatio/`} className="page-title alliance-logo">
              <img src={`/img/svg/icons/creatio-logo.svg`} alt="Creatio" />
            </NavLink>
          </>
        )}
        {(endpoint === "oracle/" ||
          endpoint === "oracle" ||
          endpoint === "oracle/oracle-service-cloud/" ||
          endpoint === "oracle/oracle-service-cloud") && (
          <>
            <NavLink to={`/alliances`} className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/oracle/`} className="page-title alliance-logo">
              <img src={`/img/svg/oracle-logo.svg`} alt="Oracle" />
            </NavLink>
          </>
        )}
        {(endpoint === "zidd/" || endpoint === "zidd") && (
          <>
            <p class="page-title">
              <img
                src={`/img/svg/zidd-logo.svg`}
                style={{ width: "69%" }}
                alt="Zidd"
                title="Zidd"
              />
            </p>
          </>
        )}
        {endpoint.includes("alliances") && (
          <>
            <NavLink to={`/alliances/`}>
              <span class="page-title">Alliances</span>
            </NavLink>
          </>
        )}
        {(endpoint === "microsoft/" || endpoint === "microsoft") && (
          <>
            <NavLink to={`/alliances`} className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/microsoft/`} className="page-title alliance-logo">
              <img src={`/img/svg/icons/microsoft-logo.svg`} alt="Microsoft" />
            </NavLink>
          </>
        )}
        {(endpoint === "business4-0/" ||
          endpoint === "business4-0" ||
          endpoint === "business4-0/agilenterprise/" ||
          endpoint === "business4-0/agilenterprise") && (
          <>
            <NavLink to={`/business4-0/`}>
              <span className="page-title">Business 4.0</span>
            </NavLink>
          </>
        )}
        {endpoint.includes("careers") && (
          <>
            <NavLink to={`/business4-0/`}>
              <span className="page-title">
                <span id="career-arrow">{`<`}</span>Careers
              </span>
            </NavLink>
          </>
        )}
        {endpoint.includes("crm-digital") && (
          <>
            <NavLink to={`/crm-digital/`}>
              <span className="page-title">CRM Digital</span>
            </NavLink>
          </>
        )}
        {(endpoint.includes("community/") ||
          endpoint === "community/" ||
          endpoint === "community") && (
          <>
            <NavLink to={`/business4-0/`}>
              <span className="page-title">Community</span>
            </NavLink>
          </>
        )}
        {endpoint.includes("webinars") && (
          <>
            <NavLink to={`/business4-0/`}>
              <span className="page-title">Webinars</span>
            </NavLink>
          </>
        )}
        {(endpoint === "succeedant" || endpoint === "succeedant/") && (
          <>
            <NavLink to={`/business4-0/`}>
              <span
                className="page-title"
                style={{
                  width: "fit-content",
                }}
              >
                SUCCESS STORIES
              </span>
            </NavLink>
          </>
        )}
        {endpoint.includes("c-digital") && (
          <>
            <NavLink to={`/c-digital/`}>
              <span className="page-title">C.Digital</span>
            </NavLink>
          </>
        )}
        {!endpoint.includes("news") && endpoint.includes("vivacis") && (
          <>
            <h1 className="visuallyhidden">Virtuos</h1>
            <NavLink className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <span className="page-title alliance-logo">
              <img src={`/img/svg/icons/vivacis-logo.svg`} alt="Vivacis" />
            </span>
          </>
        )}
        {(endpoint === "cxdesk/" ||
          endpoint === "cxdesk" ||
          endpoint === "cxdesk/unified-desk/" ||
          endpoint === "cxdesk/unified-desk") && (
          <>
            <NavLink className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/cxdesk/`} className="page-title alliance-logo">
              <img src={`/img/svg/icons/cxdesk-logo.svg`} alt="CXDesk" />
            </NavLink>
          </>
        )}
        {endpoint.includes("rainbowcx") && (
          <>
            <NavLink className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/rainbowcx/`} className="page-title alliance-logo">
              <img
                src={`/img/svg/icons/rainbowcx-logo.svg`}
                alt="Rainbow CX"
                style={{ width: 70 }}
              />
            </NavLink>
          </>
        )}
        {/* {(endpoint === 'cxnow/' || endpoint === 'cxnow/cxpyramid/') && (
          <>
            <NavLink className="partnership-icon">
        
            </NavLink>
            <NavLink to={`/cxnow/`} className="page-title alliance-logo">
              <img src={`/img/svg/cxnow-logo.svg`} alt="CXNow" />
            </NavLink>
          </>



        )} */}
        {endpoint.includes("cxnow") && (
          <>
            <NavLink className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/cxnow/`} className="page-title alliance-logo">
              <img src={`/img/svg/cxnow-logo.svg`} alt="CXNow" />
            </NavLink>
          </>
        )}

        {endpoint.includes("rigour") && (
          <>
            <NavLink className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/consultare/`} className="page-title alliance-logo">
              <img src={`/img/svg/icons/rigour-logo.svg`} alt="Rigour" />
            </NavLink>
          </>
        )}

        {/* for xx digital logo */}
        {(endpoint === "cloud/" ||
          endpoint === "cloud" ||
          endpoint === "xx-digital/" ||
          endpoint === "xx-digital") && (
          <NavLink
            to={`/xx-digital/`}
            className="page-title"
            style={{ width: "8rem" }}
          >
            <img
              src={`/img/svg/icons/xx-digital-logo-emblem-tm.svg`}
              alt="XX.Digital"
              title="XX.Digital"
            />
            <p />
          </NavLink>
        )}
        {(endpoint === "digital" ||
          endpoint === "indicial" ||
          endpoint === "digital/") && (
          <NavLink to={`/cx-digital`} className="page-title">
            <img
              src={`/img/svg/icons/cx-digital-logo-emblem-tm.svg`}
              alt="CX.Digital"
              title="CX.Digital"
            />
          </NavLink>
        )}
        {endpoint === "cx-digital/" && (
          <NavLink
            to={`/cx-digital/`}
            className="page-title"
            style={{ width: "120px" }}
          >
            <img
              src={`/img/svg/icons/cx-digital-logo-emblem-tm.svg`}
              alt="CX.Digital"
              title="CX.Digital"
            />
          </NavLink>
        )}
        {endpoint === "ex-digital/" && (
          <NavLink
            to={`/ex-digital/`}
            className="page-title"
            style={{ width: "120px" }}
          >
            <img
              src={`/img/svg/icons/ex-digital-logo-emblem-tm.svg`}
              alt="EX.Digital"
              title="EX.Digital"
            />
          </NavLink>
        )}
        {(endpoint === "digital-new" ||
          endpoint === "digital-new/oracle-service-cloud") && (
          <>
            <NavLink className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/digital-new/`} className="page-title alliance-logo">
              <img src={`/img/svg/digital-new-logo.svg`} alt="Digital New" />
            </NavLink>
          </>
        )}

        {endpoint.includes("covid") && (
          <NavLink to={`/vuca/`}>
            <span class="page-title">VUCA</span>
          </NavLink>
        )}
        {endpoint.includes("terms-of-use") && (
          <NavLink to={`/terms-of-use//`}>
            <span class="page-title">TERMS OF USE</span>
          </NavLink>
        )}

        {endpoint.includes("virtuosoship") && (
          <NavLink to={`/virtuosoship/`}>
            <span class="page-title">Virtuosoship</span>
          </NavLink>
        )}
        {endpoint.includes("vlocis") && (
          <NavLink to={`/vlocis/`} className="page-title">
            <img src={`/img/svg/vlocis-logo.svg`} alt="Vlocis" title="Vlocis" />
            <p />
          </NavLink>
        )}
        {endpoint.includes("privacy-policy/") && (
          <p className="page-title">PRIVACY POLICY</p>
        )}
        {(endpoint === "io/" ||
          endpoint === "io" ||
          endpoint === "experience-cloud/" ||
          endpoint === "experience-cloud" ||
          endpoint === "xonomy/aury/" ||
          endpoint === "xonomy/aury" ||
          endpoint === "experience-cloud/aury/" ||
          endpoint === "experience-cloud/aury" ||
          endpoint === "experience-crowd/" ||
          endpoint === "experience-crowd" ||
          endpoint === "xonomy/" ||
          endpoint === "xonomy" ||
          endpoint === "experienceo/" ||
          endpoint === "experienceo" ||
          endpoint === "experience-cloud/" ||
          endpoint === "experience-cloud" ||
          endpoint === "xonomy/budh/" ||
          endpoint === "xonomy/budh" ||
          endpoint === "xonomy/ai/" ||
          endpoint === "xonomy/ai" ||
          endpoint === "xonomy/ai/self-service/" ||
          endpoint === "xonomy/ai/self-service" ||
          endpoint === "io/design-thinking" ||
          endpoint === "io/design-thinking/" ||
          endpoint === "io/lab/" ||
          endpoint === "io/lab" ||
          endpoint === "io/virtuos/" ||
          endpoint === "io/virtuos" ||
          endpoint === "ventures" ||
          endpoint === "ventures/" ||
          endpoint === "experience-new/" ||
          endpoint === "experience-new") && (
          <>
            <NavLink className="partnership-icon">
              <PartnershipIcon />
            </NavLink>
            <NavLink to={`/xonomy/`} className="page-title alliance-logo">
              <img src={`/img/svg/exonomy-logo.svg`} alt="exonomy" />
            </NavLink>
          </>
        )}

        {/* for consulatre logo add herer */}
        {(endpoint.includes("right-apps") ||
          endpoint === "cx/cx-marries-digital/" ||
          endpoint === "cx/cx-marries-digital" ||
          endpoint === "ex/strategy-design/" ||
          endpoint === "ex/strategy-design" ||
          endpoint === "bpm-cx" ||
          endpoint === "o-digital/" ||
          endpoint === "o-digital" ||
          endpoint === "bpm-cx/" ||
          endpoint === "w-digital/" ||
          endpoint === "w-digital" ||
          endpoint === "crayonz/" ||
          endpoint === "crayonz" ||
          endpoint === "it/" ||
          endpoint === "cx/") &&
          !endpoint.includes("news") && (
            <>
              <NavLink className="partnership-icon">
                <PartnershipIcon />
              </NavLink>
              <NavLink to={`/consultare/`} className="page-title alliance-logo">
                <img src={`/img/svg/consultare-logo.svg`} alt="Consultare" />
              </NavLink>
            </>
          )}
        {(endpoint.includes("consultare") || endpoint === "consultare/") &&
          !endpoint.includes("news") && (
            <>
              <NavLink className="partnership-icon">
                <PartnershipIcon />
              </NavLink>
              <NavLink to={`/consultare/`} className="page-title alliance-logo">
                <img src={`/img/svg/consultare-logo.svg`} alt="Consultare" />
              </NavLink>
            </>
          )}
      </div>
    </>
  );
};

export default Logowrapper;
