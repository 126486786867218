import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Team_Attends_Salesforce_Conference_2016 = () => {
    useEffect(() => {
        document.title = "Virtuos Team Attends Salesforce Conference – Dreamforce 2016 in San Francisco – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-team-attends-salesforce-conference-dreamforce-2016-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">San Francisco - 7 October, 2016</span>
                                <h2 className="article-heading">
                                    Virtuos team attends Salesforce Conference — Dreamforce 2016 in San
                                    Francisco
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Dreamforce has representation from over 100 leading software and
                                    technology vendors with their products and services. Salesforce.org
                                    and nonprofit and higher education institutions worldwide have also
                                    joined in San Francisco from October 4-7 for four incredible,
                                    high-energy days of inspiration, imagination, and innovation!
                                </p>
                                <p>
                                    <strong>Some of the keyhights of the event include:</strong>
                                </p>
                                <ul>
                                    <li>
                                        Keynote by Marc Benioff and select invitees addressing 140,000+
                                        participants Fireside chat with Melinda Gates
                                    </li>
                                    <li>
                                        Dreampitch competition with Mark Cuban and will.i.am amongst others
                                    </li>
                                    <li>Forbes Philanthropy Shop</li>
                                    <li>Trust and growth in the 21st century</li>
                                    <li>
                                        There have been many customer blazers interacting with Marc Benioff,
                                        and they are: Bernard J. Tyson (Kaiser Permanente), Jean-Pascal
                                        Tricoire (Schneider Electric), and Kim Hammonds (Deutsche Bank)
                                    </li>
                                </ul>
                                <p>
                                    Dreamforce ‘16 was the world’s largest gathering of customer
                                    trailblazers. Attendees came from all over to experience the world’s
                                    most anticipated technology conference. The best thing about
                                    Dreamforce is that it does not start when the registration kiosks
                                    open, nor does it stop when the doors close. Dreamforce through
                                    Salesforce live is available year-round to educate, inspire, and
                                    motivate.
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media, and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div >
    )
}

export default Virtuos_Team_Attends_Salesforce_Conference_2016