import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { scroller } from "react-scroll";
import LetsDiscuss from "./Forms/LetsDiscuss";
const Virtuos = () => {
  const scrollToSection = (sectionId) => {
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 1000,
        smooth: true,
        offset: -50, // Adjust this offset if needed
      });
    }, 100); // Delay in milliseconds
  };
  useEffect(() => {
    document.title =
      "Innovation On | Incubation | Ideas | New Ventures - Virtuos";
  }, []);
  return (
    <div className="iov-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner">
            <div className="product-hero-wrapper two-image-wrapper">
              <div className="row">
                <div className="col-3">
                  <div className="image-wrapper">
                    <div className="bx-slider photo-slider">
                      <div>
                        <figure>
                          <img
                            src={`/img/svg/innovation-open-slider-img-01.svg`}
                            alt="Innovation On Team"
                          />
                        </figure>
                        <span>Hello! We are iO Team</span>
                      </div>
                      <div>
                        <figure>
                          <img
                            src={`/img/svg/innovation-open-slider-img-02.svg`}
                            alt="Innovation On Idea"
                          />
                        </figure>
                        <span>We ideate and Incubate!</span>
                      </div>
                      <div>
                        <figure>
                          <img
                            src={`/img/svg/innovation-open-slider-img-03.svg`}
                            alt="Artificial Intelligence"
                          />
                        </figure>
                        <span>We work on Artificial Intelligence</span>
                      </div>
                      <div>
                        <figure>
                          <img
                            src={`/img/svg/innovation-open-slider-img-04.svg`}
                            alt="Investment in Startups"
                          />
                        </figure>
                        <span>We invest in our own Startups</span>
                      </div>
                      <div>
                        <figure>
                          <img
                            src={`/img/svg/innovation-open-slider-img-05.svg`}
                            alt="Innovation On"
                          />
                        </figure>
                        <span>Time Is Now: New Or Wow</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 item-wrapper right-wrapper">
                  <figure>
                    <img
                      src={`/img/svg/io-header-img.svg`}
                      alt="Virtuos Innovation On"
                    />
                  </figure>
                </div>
                <figure className="right-fixed-logo">
                  <img
                    src={`/img/svg/icons/io-logo.svg`}
                    alt="Virtuos Innovation On"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/xonomy`}>Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/xonomy/ai/`}>Artificial Intelligence</NavLink>
                </li>
                <li>
                  <NavLink to={`/xonomy/budh`}>Budh Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud`}>Experience Cloud</NavLink>
                </li>
                <li>
                  <NavLink to={`/io`}>Innovation On</NavLink>
                </li>
                <li>
                  <NavLink to={`/xonomy/aury`}>
                    Aury<sub>®</sub> AI Platform
                  </NavLink>
                </li>
              </ul>
            </div>
          </section>
          <section className="exp-economy-section">
            <h2 className="section-main-heading">
              Virtuos <span className="highlight">Innovation On</span>
            </h2>
            <span className="section-desc">
              At Virtuos Xonomy, Virtuos.iO is our Innovation On (formerly
              Innovation Open) Lab — a guiding force comprising of board
              members, senior management, partners and community that's involved
              in research, hackathon X, workshops, ideation, and incubation of
              new ventures. Virtuos Smart Innovation Insight (VSII) and Virtuos
              Ventures unit are part of Virtuos.iO
            </span>
            <div className="page-wrap row">
              <div className="col-7 left-section">
                <div className="economy-products row offport-left">
                  <NavLink
                    to="#idea"
                    onClick={() => scrollToSection("idea")}
                    className="col-6"
                  >
                    <div className="icon">
                      <img
                        src={`/img/svg/icons/idea-icon.svg`}
                        alt="Idea Icon"
                      />
                    </div>
                    <h3>
                      Ideas <i className="fal fa-long-arrow-right" />
                    </h3>
                    <p>
                      New ideas reinvigorate long standing status quo thinking.
                      Using our IDEA framework, we bring new ideas to clients
                      and harness innovation in whole new way in the digital
                      century business.
                    </p>
                  </NavLink>
                  <NavLink
                    to="#innovation"
                    onClick={() => scrollToSection("innovation")}
                    className="col-6"
                  >
                    <div className="icon">
                      <img
                        src={`/img/svg/icons/innovation-icon.svg`}
                        alt="Innovation Icon"
                      />
                    </div>
                    <h3>
                      Innovation <i className="fal fa-long-arrow-right" />
                    </h3>
                    <p>
                      Innovation is critical to long-term success driving
                      organizations to rethink their strategy beyond cloud first
                      or mobile first to digital only. Virtuos.iO helps you
                      achieve your innovation goals.
                    </p>
                  </NavLink>
                  <NavLink to="#incubation" className="col-6">
                    <div className="icon">
                      <img
                        src={`/img/svg/icons/incubation-icon.svg`}
                        alt="IncubationIncubation Icon"
                      />
                    </div>
                    <h3>
                      Incubation <i className="fal fa-long-arrow-right" />
                    </h3>
                    <p>
                      Bootstrapped incubation on the next edge ideas to create a
                      better tomorrow. VYO — the Digital Twin Marketing Design,
                      Budh — the sustainable business or Giftcart — gifting
                      experiences expert.
                    </p>
                  </NavLink>
                  <NavLink to={`/corporate/team`} className="col-6">
                    <div className="icon">
                      <img
                        src={`/img/svg/icons/people-icon.svg`}
                        alt="People Icon"
                      />
                    </div>
                    <h3>
                      People <i className="fal fa-long-arrow-right" />
                    </h3>
                    <p>
                      As the name suggests, Innovation Open is open for smart,
                      talented professionals with great ideas. They can join as
                      Entrepreneur-In-Residence (EIR) Program by writing to
                      ceo@virtuos.com
                    </p>
                  </NavLink>
                </div>
              </div>
              <div className="col-5 right-section offport-right">
                <figure>
                  <img
                    src={`/img/marc-benioff-img.jpg`}
                    alt="Marc Benioff Photo"
                  />
                  <span className="quote">
                    "If you want to be an innovator, you have to cultivate
                    beginner’s mind."
                  </span>
                  <span className="name">- Marc Benioff</span>
                </figure>
              </div>
            </div>
          </section>
          <section className="blogs-section thinking-new" id="innovation">
            <h2 className="section-main-heading">
              <span className="highlight">INNOVATION ON (IO)</span> Framework
            </h2>
            <span className="section-desc">
              In the digital century business, transformation is the new normal
              and innovation is essential to success. The Innovation On (iO) Lab
              with four building blocks such as Design Thinking; Intrapreneurial
              You — The Lean Startup Approach; Virtuosoship eDexterity and
              Mastermind Edge. Our customers can use this innovation framework
              to design innovation roadmaps that deliver high-value business
              outcomes.
            </span>
            <div className="row page-wrap">
              <span className="col-3 article-wrapper inview">
                <figure>
                  <img
                    src="https://www.virtuos.com/img/io-design-thinking-img.jpg"
                    alt="Design Thinking"
                  />
                </figure>
                <div className="article">
                  <h3 className="section-heading">Design Thinking</h3>
                  <p className="">
                    Design Thinking is customer-centric digital innovation
                    methodology that constantly challenges the status quo in
                    pursuit of thinking creatively, promoting early idea
                    generation and prototyping — that’s mostly collaborative,
                    playful and iterative.
                  </p>
                  <a href="https://www.virtuos.com/cxnow./cxprism/">
                    Explore more <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </span>
              <span className="col-3 article-wrapper inview">
                <figure>
                  <img
                    src="https://www.virtuos.com/img/svg/intrapreneurial-you-img.svg"
                    alt="Intrapreneurial You"
                  />
                </figure>
                <div className="article">
                  <h3 className="section-heading">Intrapreneurial You</h3>
                  <p className="">
                    Every member in Virtuos is an Intrapreneur owning the
                    innovation goals of the company. What innovation
                    capabilities our intrapreneurs do need and how can they
                    develop, acquire and improve new skills, and what’s the
                    desired innovation model.
                  </p>
                  <a href="javascript:void(0);">
                    Download the Presentation{" "}
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </span>
              <span className="col-3 article-wrapper inview">
                <figure>
                  <img
                    src="https://www.virtuos.com/img/svg/virtuosoship-edexterity-img.svg"
                    alt="Virtuosoship eDexterity"
                  />
                </figure>
                <div className="article">
                  <h3 className="section-heading">Virtuosoship eDexterity</h3>
                  <p className="">
                    Virtuosoship is the enterprise wide employee’s digital
                    dexterity program to create a team of Virtuosos — the elite
                    experts and develop strategies and programs for learning
                    skills, heuristic approaches, I-D-E-A Framework, and
                    Performance Improvement.
                  </p>
                  <a href="https://www.virtuos.com/virtuosoship/">
                    Explore more <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </span>
              <span className="col-3 article-wrapper inview">
                <figure>
                  <img
                    src="https://www.virtuos.com/img/svg/mastermind-edge-img.svg"
                    alt="MasterMind Edge"
                  />
                </figure>
                <div className="article">
                  <h3 className="section-heading">MasterMind Edge</h3>
                  <p className="">
                    Customer (C.Digital); Operational (O.Digital) and WorkPlace
                    (W.Digital) technologies are harnessed using our ABC
                    Strategy - AIccelerate; Business 4.0 and CustomerWide to
                    deliver Mastermind edge for our customers across the modern
                    industries.
                  </p>
                  <a href="https://www.virtuos.com/corporate/experience-edge/">
                    Explore Experience Edge{" "}
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </span>
            </div>
          </section>
          <section className="exp-business order" id="incubation">
            <h2 className="section-main-heading">
              Virtuos <span className="highlight">Portfolio Brands</span>
            </h2>
            <span className="section-desc">
              These portfolio brands are managed by leadership teams to
              transform them into next edge businesses with highly
              differentiated offerings and value proposition. These brands
              reflect our vision for the future in the Experience Economy
              (Xonomy).
            </span>
            <div className="page-wrap row">
              <div className="col-4 item offport-down">
                <h3 className="section-heading">
                  Aury<sub>®</sub> Chatbot
                </h3>
                <p>
                  It’s a Digital Chatbot. Artificial Intelligence powers it.
                  Aury is also your latest smart home surveillance device, and
                  it’s also AR and VR opportunity.
                </p>
                <figure>
                  <img
                    src={`/img/io-aury-img.jpg`}
                    alt="Aury - Digital Chatbot"
                  />
                </figure>
              </div>
              <div className="col-4 item offport-down">
                <figure>
                  <img
                    src={`/img/io-budh-img.jpg`}
                    alt="Innovation On - Budh"
                  />
                </figure>
                <h3 className="section-heading">
                  Budh<sub>®</sub>
                </h3>
                <p>
                  At Budh, we envision a new tomorrow with sustainable and
                  renewable business, AI driven Smart farming and Urbanization
                  with Smart Cities.
                </p>
              </div>
              <div className="col-4 item offport-down">
                <h3 className="section-heading">
                  Cloudle<sub>®</sub>
                </h3>
                <p>
                  <NavLink
                    to="http://www.cloudle.com"
                    target="_blank"
                    className="link"
                  >
                    Cloudle
                  </NavLink>{" "}
                  will be the LOW-CODE-NO-CODE (LCNC) Platform offering
                  flexibility, simplicity, and extensibility for building
                  applications easily and effortlessly.
                </p>
                <figure>
                  <img
                    src={`/img/io-cloudle-img.jpg`}
                    alt="Innovation On - Cloudle"
                  />
                </figure>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-4 item offport-down">
                <h3 className="section-heading">
                  Definite<sup>TM</sup>
                </h3>
                <p>
                  It’s Digital Marketing Experience Cloud<sup>TM</sup> and It’s
                  going to be Digital Experience Platform (DXP) leveraging AI
                  and modern microservices, APIs and{" "}
                  <NavLink
                    to={`/consultare/engineering-services/#mean`}
                    className="link"
                  >
                    MEAN
                  </NavLink>{" "}
                  stack.
                </p>
                <figure>
                  <img
                    src={`/img/io-definite-img.jpg`}
                    alt="Innovation On - Definite"
                  />
                </figure>
              </div>
              <div className="col-4 item offport-down">
                <figure>
                  <img
                    src={`/img/io-enforce-img.jpg`}
                    alt="Innovation On - Enforce"
                  />
                </figure>
                <h3 className="section-heading">
                  Enforce<sup>TM</sup>
                </h3>
                <p>
                  It’s Digital Employee Experience Cloud<sup>TM</sup> creating
                  ExperienceJobs<sup>TM</sup> and transforming the future of
                  work, and workplace in Artificial Intelligence driven Economy.
                </p>
              </div>
              <div className="col-4 item offport-down">
                <h3 className="section-heading">
                  Flurr<sup>TM</sup> Omni Collaboration
                </h3>
                <p>
                  AI powered rich conversational platform and powerful set of
                  robotics converge to offer a unique Experiential
                  Physical-Digital-Assistant (xPDA) that monitors your
                  operations 24x7, and helps employees, customers, and
                  management on the go.
                </p>
                <figure>
                  <img
                    src={`/img/io-flurr-img.jpg`}
                    alt="Flurr Omni Collaboration"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="full-width-container" id="idea">
            <div className="row io-i3-section">
              <figure className="banner">
                <img src={`/img/io-i3-breaker-img.jpg`} alt="Innovation On" />
              </figure>
              <div className="content offport-down">
                <span className="icon">
                  <img src={`/img/svg/icons/io-logo.svg`} alt="IO Logo" />
                </span>
                Ideas. Incubation. Innovation.
              </div>
            </div>
          </section>
          <section className="our-ideas-section grey-bg">
            <h2 className="section-main-heading">
              Our <span className="highlight">Ideas</span>
            </h2>
            <span className="section-desc">
              Everything begins with an idea. At Virtuos.iO we reimagine
              technologies with the most creative, time tested, and
              groundbreaking ideas that change the world.
            </span>
            <div className="page-wrap row offport-down">
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/customer-now-logo.svg`}
                    alt="Customer Now logo"
                  />
                </figure>
              </div>
              <div className="col-3 item">
                <figure>
                  <img src={`/img/svg/giftcart-logo.svg`} alt="Giftcart Logo" />
                </figure>
              </div>
              <div className="col-3 item">
                <figure>
                  <img src={`/img/svg/VVO-logo.svg`} alt="VVO Logo" />
                </figure>
              </div>
              <div className="col-3 item">
                <figure>
                  <img src={`/img/svg/vyo-logo.svg`} alt="VYO Logo" />
                </figure>
              </div>
            </div>
          </section>
          {/* AR Section */}
          <section className="full-width-container">
            <div className="ar-wrapper aury-section" id="aury">
              <figure className="banner">
                <img
                  src={`/img/aury-breaker-img.jpg`}
                  alt="AURY - The Net generation Augmented Reality (AR)"
                />
              </figure>
              <div className="row page-wrap">
                <div className="col-4 offport-left">
                  <img src={`/img/svg/icons/aury-logo.svg`} alt="Aury Logo" />
                </div>
                <div className="col-6 left-content offport-right">
                  <h3 className="section-heading">
                    Aury<sub>®</sub> : The Next Generation Augmented Reality
                    (AR)
                  </h3>
                  <p>
                    AR to create personal, interactive experiences to online
                    shoppers. Join us to discuss the concept to bring new ideas
                    to life.{" "}
                  </p>
                  <LetsDiscuss />
                </div>
              </div>
            </div>
          </section>
          <section className="blogs-section thinking-new" id="innovation">
            <h2 className="section-main-heading">
              <span className="highlight">Innovation</span> Insight
            </h2>
            <div className="row page-wrap">
              <span className="col-4 article-wrapper inview">
                <figure>
                  <img
                    src="https://www.virtuos.com/img/vyo-the-digital-twin-img.jpg"
                    alt="Vyo - The Digital Twin"
                  />
                </figure>
                <div className="article">
                  <h3 className="section-heading">
                    Vyo, the Digital Twin <br />
                    (aka alt ego)
                  </h3>
                  <p className="">
                    VYO Digital twin (pronounced as "Yo!) analyzes and simulates
                    real-world conditions, responds to changes, improves
                    operational excellence while adding value. For
                    organizations, and for Individuals, it creates (like{" "}
                    <a
                      href="https://en.wikipedia.org/wiki/Alter_ego"
                      rel="nofollow"
                      target="_blank"
                      className="link"
                    >
                      Alter ego
                    </a>
                    ) new opportunities with AI-based analysis practically
                    acting as your next best version.
                  </p>
                </div>
              </span>
              <span className="col-4 article-wrapper inview">
                <figure>
                  <img
                    src="https://www.virtuos.com/img/definite-the-digital-experiencecloud-img.jpg"
                    alt="Definite - The Digital Experience Cloud"
                  />
                </figure>
                <div className="article">
                  <h3 className="section-heading">
                    Definite, The Digital Experience Cloud
                  </h3>
                  <p className="">
                    Definite will be a Digital Experience Platform (DXP) that
                    supports modern web development and integrated applications
                    such as CRM, ERP, SCM, HCM, and several others. The
                    traditional websites will be history paving the way to new
                    DXP that’s built on the Experience Architecture.
                  </p>
                </div>
              </span>
              <span className="col-4 article-wrapper inview">
                <figure>
                  <img
                    src="https://www.virtuos.com/img/enforce-employee-experiencecloud-img.jpg"
                    alt="Enforce - Employee Experience Cloud"
                  />
                </figure>
                <div className="article">
                  <h3 className="section-heading">
                    Enforce, Employee Experience Cloud
                  </h3>
                  <p className="">
                    We are witnessing significant disruption in how future
                    workforce responds to changes. About 80% of millennials will
                    drive the business and economy in the next 5-10 years.
                    Millennials need a new kind of engagement platform that’s
                    less work, more productivity and even more privacy with
                    minimal complexity.
                  </p>
                </div>
              </span>
            </div>
          </section>

          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos;
