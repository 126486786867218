import React, { useEffect, useState } from 'react'

import { NavLink } from "react-router-dom";
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Nav from '../../../components/Nav';
import ApplyForJob from './Forms/ApplyForJob';
import CommonApplyForJob from './CommonApplyForJob';
import { NavigateNext } from '@mui/icons-material';

const Drupal = () => {
    useEffect(() => {
        document.title = "Career Opportunities | Microsoft .Net Developer – Virtuos Jobs";
    }, []);
    return (
        <div className="careers-job-detail-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    {/* Careers Job Detail */}
                    <section className="job-detail-wrapper">
                        <div className="row page-wrap">
                    	<div class="col-9 job-details">
						<div class="job-header">
							<div class="profile-illustration">
								<figure>
									<img src={`/img/svg/drupal-career-img.svg`} alt="Drupal Developer Job"/>
								</figure>
							</div>
							<div class="profile-detail">
								<h2 class="section-heading">Drupal Developer</h2>
								<ul class="job-attributes">
									<li class="">
										<strong>Location:</strong>
										<span>Gurgaon</span>
									</li>
									<li class="">
										<strong>Experience:</strong>
										<span>3-5 Years</span>
									</li>
									<li class="">
										<strong>No. of Positions:</strong>
										<span>2</span>
									</li>
									<li class="">
										<strong>Qualification:</strong>
										<span>BE/BTech/ME/MTech/MS/MCA</span>
									</li>
								</ul>
							</div>
						</div>
						
						<h3 class="title">Job Description</h3>
						<p>We are looking for a Drupal Developer responsible for front-end development and theming. This position requires a combination of programming skills (PHP, HTML, CSS, JS, etc.) and aesthetics (understanding element arrangements on the screen, the color, and font choices, and so on). The best candidates have a strong understanding of industry trends and content management systems. Experience with responsive and adaptive design is strongly preferred. In addition, an understanding of the entire web development process, including design, development and deployment, with some back-end knowledge, is preferred.</p>

						<h3 class="title">Responsibilities</h3>
						<ul class="list">
							<li>Build and maintain Drupal Web sites and Drupal-based applications</li>
							<li>Evaluate/configure/install contributed modules as well as develop custom modules</li>
							<li>Complete front-end development of websites and digital applications</li>
								<ul class="list">
									<li>Coding applications that call internal backend services/data and update the front-end</li>
									<li>Create UI that integrate with various APIs (Google, Twitter, Facebook, etc.)</li>
								</ul>
							<li>Testing, maintenance and troubleshooting of existing company sites</li>
							<li>Contributing ideas and efforts towards internal projects and working as part of a team to find solutions on various problems</li>
							<li>Troubleshoot site errors and CSS issues and implement fixes</li>
							<li>Implements various analytic tags and tracking codes (Omniture, Google Analytics) along with developing custom tagging</li>
							<li>Researches and learns new web technologies, APIs, and programming languages and JS libraries as projects dictate</li>
							<li>Performs Cross-browser/device testing.</li>
							<li>Assist in performing routine content updates using various Content Management Systems</li>
							<li>Perform web production tasks such as video encoding, asset management and editing/exporting various graphical elements</li>
						</ul>
						
						<h3 class="title">Desired Skills, Experience &amp; Abilities</h3>
						<ul class="list">
							<li>Minimum 2+ years of Drupal hands-on development experience</li>
							<li>Proficient in the maintenance and administration of Drupal modules and sites</li>
							<li>Experience integrating open source and third-party applications into existing systems</li>
							<li>Familiarity with source control systems such as Subversion, etc</li>
							<li>Experience building user interfaces for websites and/or web applications</li>
							<li>Extremely fluent with HTML5/XHTML, Javascript, and CSS</li>
							<li>Experience working with PHP, JavaScript, and jQuery</li>
							<li>Ability to write JavaScript with and without the use of frameworks such as jQuery</li>
							<li>Experience designing and developing responsive design websites</li>
							<li>Comfortable working with debugging tools like Firebug, Chrome inspector, etc.</li>
							<li>Ability to understand CSS changes to have consistent style across platforms and browsers</li>
							<li>Ability to convert comprehensive layout and wireframes into working HTML pages</li>
							<li>Experience developing web solutions in Drupal and PHP implementations in a LAMP environment</li>
							<li>Ability to architect enterprise Drupal solutions including data, display, and information architecture</li>
							<li>Advanced site-building experience including deep familiarity with Drupal modules such as CTools, Views, and Panels</li>
							<li>Experience creating custom Drupal modules</li>
							<li>Experience building responsive websites using Drupal CMS, HTML/HTML5, CSS/CSS3, and JavaScript/jQuery</li>
							<li>Knowledge of PHP, PHP files and theme functions, and knowledge of the Drupal theme layer</li>
							<li>Through understanding of the Software Development Lifecycle (e.g. Requirements, Design, Development, Testing) and exposure to Agile or iterative SDLCs</li>
						</ul>
						
						<h3 class="title">Advantages of Working@Virtuos</h3>
						<ul class="list">
							<li>Virtuos has deep expertise in CRM and Customer Experience Consulting - It’s likely to become largest Applications Business valued at US$45B per year. Candidates will gain significant exposure from our best practices, top-notch alliances, and direct hands-on interaction with leading brands</li>
							<li>The candidates will have exposure to the cutting edge solutions in broad based verticals</li>
							<li>The role also accords a deep understanding of Customer's business processes, and technology adaptation providing an ample room for candidate's overall growth</li>
							<li>Global exposure and working on best business practices with MNC and Enterprise/Corporate Customers in diverse verticals</li>
						</ul>
						
					</div>
                            <div className="col-3 job-application-wrapper sticky-content">
                             <CommonApplyForJob/>
                            </div>
                        </div>
                    </section>
                   
                    {/*?php include '../../footer.php';?*/}
                    <Footer/>
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Drupal
