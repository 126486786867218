import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { scroller } from "react-scroll";
import EnquireNow from "./Forms/EnquireNow";
const O_Digital_Asap = () => {
  useEffect(() => {
    document.title =
      "Modern Post COVID Digital Operating Model — Veracis Customer Trust – O.Digital – Virtuos Veracis";
    const hash = window.location.hash;
    if (hash === "#bpo") {
      scrollToUncodifySection();
    }
  }, []);

  const scrollToUncodifySection = () => {
    let scroller;
    scroller.scrollTo("bpo", {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -50, // Adjust this offset if needed
    });
  };
  const scrollToSection = (sectionId) => {
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 1000,
        smooth: true,
        offset: -50, // Adjust this offset if needed
      });
    }, 100); // Delay in milliseconds
  };
  return (
    <div className="o-digital-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper business3-page">
          <section className="top-hero-product-banner image-title-layout cx-header-img cx-bg">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/o-digital-header-img.jpg`}
                alt="O.Digital"
              />
            </figure>
            <div className="row page-wrap">
              <div className="col-12 header-block">
                <div className="top-logo">Digital As Soon As Possible</div>
                <div className="col-4 left-logo">
                  <figure>
                    <img
                      src={`/img/svg/icons/digitalasap-logo.svg`}
                      alt="Digital ASAP"
                    />
                  </figure>
                </div>
                <div className="col-4 title">
                  Outthink.
                  <br />
                  Outsmart.
                  <br />
                  Outdo.
                </div>
                <div className="col-4 links-wrapper">
                  <ul className="links">
                    <li>
                      <NavLink
                        to="#bpo"
                        onClick={() => scrollToSection("bpo")}
                        className="smooth"
                      >
                        BPO vs B3O <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/o-digital/cx-continuum`}
                      >
                        CX Continuum <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/o-digital/digital-asap`}
                      >
                        Digital ASAP <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        ExperienceCart <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/o-digital/`}>
                    O.Digital
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/o-digital/cx-continuum`}
                  >
                    CX Continuum
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/o-digital/cx-continuum/#cx-continuum-section`}
                  >
                    247CX
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/cxdesk`}>
                    CXDesk
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/o-digital/digital-asap`} className="active"
                  >
                    Digital ASAP
                  </NavLink>
                </li>
                <li>
                  <NavLink to="javascript:void(0);">UX Wheel</NavLink>
                </li>
                {/*						<li><NavLink to={`/talentare`}>Talentare Management</NavLink></li>*/}
              </ul>
            </div>
          </section>
          <section className="business3-tab-section tab-content-wrapper">
            {/* O.Digital */}
            <div id="o-digital-section">
              <div className="light-blue-bg consultare-services">
                <div className="page-wrap">
                  <h2 className="section-main-heading">
                    Accelerate with{" "}
                    <span className="highlight">Digital ASAP</span>
                  </h2>
                  <span className="section-desc">
                    Operations (O) are digital. Our Digital ASAP Services
                    comprise a variety of tools to help organizations acquire
                    potential customers while improving existing customer
                    relationships.
                  </span>
                  <div className="page-wrap">
                    <div className="two-col-section">
                      <div className="row harmony">
                        <div className="col-6 item-wrap offport-left">
                          <div className="content-wrap">
                            <h3
                              className="section-heading"
                              style={{
                                marginBottom: "1em!important",
                                fontWeight: "bold!important",
                                color: "#3a3939c9!important;",
                              }}
                            >
                              Customer Harmonics
                            </h3>
                            <p className="app-p">
                              We fulfil the functions of{" "}
                              <strong>
                                Harvest; Amplify; Retention and Efficiency
                                (HARE)
                              </strong>{" "}
                              integrating Digital Operations and innovative CX
                              management solutions across touchpoints of{" "}
                              <NavLink
                                // to={`/business3/#flower-of-services`}
                                className="link"
                              >
                                the flower of service
                              </NavLink>
                              . We are not another or any BPO, nor we do what
                              they do in scale or size. We are like a cherry on
                              top of ice cream of processes offered by those
                              Companies. In fact, we make these Process
                              Outsourcing Companies shine with our
                              transformational piece of CX, Digital process
                              Automation and Consulting capabilities.
                            </p>
                            <p>
                              <NavLink
                                to="#bpo"
                                onClick={() => scrollToSection("bpo")}
                                className="link"
                              >
                                <i className="fal fa-long-arrow-right" /> Check
                                out BPO versus B3O
                              </NavLink>
                            </p>
                          </div>
                        </div>
                        <div className="col-6 illus offport-right">
                          <figure>
                            <img
                              src={`/img/svg/customer-harmonics-img.svg`}
                              alt="Customer Harmonics"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row page-wrap services-block">
                    <div className="col-6 item">
                      <span className="icon offport-zoomin">
                        <figure>
                          <img
                            src={`/img/svg/icons/content-voice-logo.svg`}
                            alt="ContentVoice Logo"
                          />
                        </figure>
                      </span>
                      <h3>Content Orchestration</h3>
                      <p className="app-p">
                        Content is king. Content is voice too. It’s loud and
                        audible everywhere. ContentVoice is now video, audio,
                        images, text encapsulated to capture the voice of the
                        customer in real time.
                      </p>
                    </div>
                    <div className="col-6 item">
                      <span className="icon offport-zoomin">
                        <figure>
                          <img
                            src={`/img/svg/icons/experience-cart-logo.svg`}
                            alt="Experience Cart Logo"
                          />
                        </figure>
                      </span>
                      <h3>Experience Cart with AI</h3>
                      <p className="app-p">
                        Cart abandonment is really a complex problem faced by
                        marketers. In our survey, we found no.1 reason for cart
                        abandonment is not sticker price or technical glitches
                        but “micro moments”.
                      </p>
                    </div>
                    <div className="col-6 item">
                      <span className="icon offport-zoomin">
                        <figure>
                          <img
                            src={`/img/svg/icons/1campaign-logo.svg`}
                            alt="1Campaign Logo"
                          />
                        </figure>
                      </span>
                      <h3>Multi-channel Campaigns</h3>
                      <p className="app-p">
                        Orchestrate customer engagements and marketing content
                        to individuals and segments across multiple channels.
                        Channels may include websites, email, mobile, text, and
                        social.
                      </p>
                    </div>
                    <div className="col-6 item">
                      <span className="icon offport-zoomin">
                        <figure>
                          <img
                            src={`/img/svg/icons/ssentiment-logo.svg`}
                            alt="Social Sentiment"
                          />
                        </figure>
                      </span>
                      <h3>Social Sentiment Monitoring</h3>
                      <p className="app-p">
                        Social Sentiment monitoring may include techniques such
                        as sentiment analysis, keyword search-type analytics,
                        natural-language processing, social networking, and
                        scoring.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Talentare */}
            <div id="talentare-section" className="tab-content">
              <div className="light-blue-bg our-consultare-services">
                <div className="page-wrap row">
                  <div className="col-4 left-content">
                    <h2 className="section-heading">
                      What is <span className="highlight">Talentare</span>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Enim nulla aliquet porttitor lacus. Placerat in
                      egestas erat imperdiet sed. Amet facilisis magna etiam
                      tempor orci eu lobortis. Elementum pulvinar etiam non quam
                      lacus suspendisse faucibus interdum. Aliquam nulla
                      facilisi cras fermentum. Feugiat sed lectus vestibulum
                      mattis. At in tellus integer feugiat scelerisque varius
                      morbi. Nullam ac tortor vitae purus faucibus ornare. Eget
                      aliquet nibh praesent tristique. Orci porta non pulvinar
                      neque laoreet suspendisse interdum consectetur. In
                      hendrerit gravida rutrum quisque non tellus orci. Sagittis
                      eu volutpat odio facilisis mauris sit. Porttitor leo a
                      diam sollicitudin tempor id eu nisl. Sed risus ultricies
                      tristique nulla aliquet. Amet risus nullam eget felis eget
                      nunc lobortis mattis aliquam. Donec massa sapien faucibus
                      et molestie ac feugiat sed. Porttitor leo a diam
                      sollicitudin tempor. Purus in mollis nunc sed id semper
                      risus.
                    </p>
                  </div>
                  <div className="col-8 right-content">
                    <ul>
                      <li>
                        <span className="icon">
                          <i className="fas fa-pencil" />
                        </span>
                        <div className="content-wrapper">
                          <h4>
                            <span className="highlight">Talent Sourcing</span>
                          </h4>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Error temporibus dolorem aliquid minima sunt
                            dolor numquam quam perferendis possimus pariatur
                            cupiditate rem magnam soluta, minus sapiente quo.
                            Delectus, ullam magnam. dolor numquam quam
                            perferendis possimus pariatur cupiditate rem magnam
                            soluta, minus sapiente quo. Delectus, ullam magnam.
                          </p>
                        </div>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="fas fa-cogs" />
                        </span>
                        <div className="content-wrapper">
                          <h4>
                            <span className="highlight">
                              Talent Quality Process
                            </span>
                          </h4>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Error temporibus dolorem aliquid minima sunt
                            dolor numquam quam perferendis possimus pariatur
                            cupiditate rem magnam soluta, minus sapiente quo.
                            Delectus, ullam magnam. dolor numquam quam
                            perferendis possimus pariatur cupiditate rem magnam
                            soluta, minus sapiente quo. Delectus, ullam magnam.
                          </p>
                        </div>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="fas fa-object-ungroup" />
                        </span>
                        <div className="content-wrapper">
                          <h4>
                            <span className="highlight">
                              Training &amp; Development
                            </span>
                          </h4>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Error temporibus dolorem aliquid minima sunt
                            dolor numquam quam perferendis possimus pariatur
                            cupiditate rem magnam soluta, minus sapiente quo.
                            Delectus, ullam magnam. dolor numquam quam
                            perferendis possimus pariatur cupiditate rem magnam
                            soluta, minus sapiente quo. Delectus, ullam magnam.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="full-width-container">
            <div className="row product-section">
              <figure className="product-banner">
                <img
                  src={`/img/svg/o.digital-breaker-img.svg`}
                  alt="Digital Transformation Consulting "
                />
              </figure>
              <div className="page-wrap right-content inview">
                <h3 className="section-heading fadeIn">LEAN STARTUP TOOLKIT</h3>
                <p className="fadeIn">
                  If you’ve decided to take the plunge with a Lean Startup —
                  whether a new business or expansion into new geo — you have
                  reached the right place.
                </p>
              </div>
            </div>
          </section>
          <section className="related-offerings">
            <h2 className="section-main-heading">
              GO LEAN WITH <span className="highlight">DIGITAL ASAP</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-6 item-wrapper">
                <div className="item">
                  <span className="icon offport-zoomin">
                    <figure>
                      <img
                        src={`/img/svg/icons/brandien-b-icon.svg`}
                        alt="Digital Branding"
                      />
                    </figure>
                  </span>
                  <div className="content-wrap">
                    <h3 className="section-heading">Digital Branding</h3>
                    <p>
                      Great Ideas must be amplified with the unique and
                      brandable name through Brandien — in the jungle of
                      overcrowded “me too” startups.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <span className="icon offport-zoomin">
                    <figure>
                      <img
                        src={`/img/svg/icons/digital-kickstart-icon.svg`}
                        alt="Digital Kickstarter"
                      />
                    </figure>
                  </span>
                  <div className="content-wrap">
                    <h3 className="section-heading">Digital Kickstarter</h3>
                    <p>
                      Our Digital Kickstarter Pack gets you up and running in
                      weeks with UX Design, Content, Social Profiling, and
                      eCommerce.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <span className="icon offport-zoomin">
                    <figure>
                      <img
                        src={`/img/svg/icons/digital-jumpstart-icon.svg`}
                        alt="Digital Jumpstart"
                      />
                    </figure>
                  </span>
                  <div className="content-wrap">
                    <h3 className="section-heading">Digital Jumpstart</h3>
                    <p>
                      Our Digital Jumpstart Package introduces you to the right
                      technologies, alliances, cloud platforms and complete
                      automation tools.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <span className="icon offport-zoomin">
                    <figure>
                      <img
                        src={`/img/svg/icons/freshcx-icon.svg`}
                        alt="FreshCX"
                      />
                    </figure>
                  </span>
                  <div className="content-wrap">
                    <h3 className="section-heading">Fresh CX</h3>
                    <p>
                      If you are a startup with a focus on blitzscaling your
                      business, ensure that your customer experience does not
                      dilute your marketing efforts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="plans-table" id="bpo">
            <h2 className="section-main-heading">
              TRADITIONAL <span className="highlight">BPO versus B3O</span>
            </h2>
            <span className="section-desc">
              Here is a brief comparison between BPO and B3O — Part of O.Digital
              Operations. B3O means Business Outsourcing through a{" "}
              <em>
                <strong>triumvirate</strong>
              </em>{" "}
              of People, Process, and Technologies.
            </span>
            <div className="page-wrap offport-down">
              <figure>
                <img
                  src={`/img/svg/bpo-b3o.svg`}
                  alt="BPO versus B3O"
                />
              </figure>
            </div>
            <div className="page-wrap row">
              <div className="col-5 item-wrapper offport-left">
                <div className="item red">
                  <h3>Business Process Outsourcing</h3>
                  <span className="price">
                    <span className="value">BPO</span>
                  </span>
                  <ul>
                    <li>
                      <i className="fas fa-check-circle" /> Outsourcing of
                      services
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Have access to CX
                      domain experts
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Large size
                      operational role
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Customer Management
                      objective
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Process Enhancement
                      Technologies/Services
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Labour intensive
                      technology driven
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Improve C-Sat;
                      Reduce Service Cost
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-5 item-wrapper offport-right">
                <div className="item blue">
                  <h3>Triumvirate of Business Operations</h3>
                  <span className="price">
                    <span className="value">B3O</span>
                  </span>
                  <ul>
                    <li>
                      <i className="fas fa-check-circle" /> iShoring of
                      specialized services
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> We are the CX domain
                      experts
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Niche strategic role
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Customer Experience
                      objective
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Process
                      Re-Engineering/Transformation
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Outcome Driven
                      Experience Continuum
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Improve CX Index,
                      Reduce Attrition
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="grey-bg cf-form-wrapper">
            <div className="page-wrap ">
              <h2 className="section-main-heading">
                HOW TO RUN BUSINESS ON{" "}
                <span className="highlight">SHOESTRING BUDGETS</span>?{" "}
              </h2>
              <span className="section-desc">
                Resources cost 80% of your budgets and our O.Digital experts
                help you get started small with big wins. Learn how we do!
              </span>
              <EnquireNow />
            </div>
          </section>
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default O_Digital_Asap;
