import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Teams_Undergo_Maximizer_Crm = () => {
    useEffect(() => {
        document.title = "Virtuos builds expertise in Maximizer Software – Virtuos Teams undergo Maximizer CRM 10 Training – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/maximizer-training-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">New Delhi, 7 April 2008</span>
                                <h2 className="article-heading">
                                    Virtuos builds expertise in Maximizer Software &amp; Virtuos Teams
                                    undergo Maximizer CRM 10 Training
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Teams undergo Maximizer CRM 10 Training at its New Delhi
                                    headquarters covering Sales, Service, and Marketing Modules of a new
                                    version. Armed with implementation expertise on Maximizer CRM version
                                    9, and Online learning of new modules from Maximizer Version 10 with
                                    the help of Technical Guides, Virtuos has impressed Maximizer
                                    Leadership Team.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-team-undergo-maximizer-training-pic.jpg`}
                                        alt="Virtuos Teams undergo Maximizer CRM 10 Training"
                                    />
                                </figure>
                                <br />
                                <p>
                                    The Maximizer Integrated CRM Version 10 has more advanced
                                    functionalities and a set of expansive integrations with quick
                                    two-step migration to Cloud. Some of the features that modern business
                                    leaders will use are listed here:
                                </p>
                                <ul>
                                    <li>
                                        The My Work Day window in Maximizer displays your current and
                                        overdue appointments and Hotlist tasks.
                                    </li>
                                    <li>
                                        The Company Library stores sales and marketing information, as well
                                        as other company collateral for everyone to access.
                                    </li>
                                    <li>
                                        The User-Defined Fields tab displays custom fields, which can be
                                        associated with any selected Address Book entry, opportunity, or
                                        user.
                                    </li>
                                    <li>
                                        The Documents tab displays the documents associated with the
                                        selected Address Book entry or opportunity.
                                    </li>
                                    <li>
                                        The OrderDesk tab is used for creation, processing, and post- order
                                        fulfillment of your customer sales orders and inquiries.
                                    </li>
                                </ul>
                                <figure className="border">
                                    <img
                                        src={`/img/maximizer-10-virtuos-logo.jpg`}
                                        alt="Maximizer CRM 10, Virtuos"
                                    />
                                </figure>
                                <p>
                                    “Not using CRM in my previous companies that I worked for was a big
                                    mistake, and this was one reason why we could not manage Customer
                                    Experience as per customer expectations. Even Employees were clueless
                                    using Microsoft Word, Excel, and Outlook”. Said Venky Vijay Reddi, CEO
                                    of Virtuos Solutions. Use of CRM is not just to manage sales, service,
                                    or marketing; it’s about managing entire customer relationship
                                    lifecycle management”.
                                </p>
                                <p>
                                    Maximizer has also introduced “Entrepreneur Edition to empower small
                                    business leaders and businessmen to use CRM early in their operations
                                    to better serve their customers.{" "}
                                    <NavLink
                                        to="https://callcenterinfo.tmcnet.com/analysis/articles/20988-maximizer-rounds-out-maximizer-crm-10-suite-with.htm"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Learn more...
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd., a company registered with the Indian
                                    Companies Act, is a startup in the space of the CRM Cloud Computing
                                    business. As a company, Virtuos is born in the cloud by forging
                                    alliances with leading companies such as Maximizer and KANA.
                                </p>
                                <p>
                                    The company is headquartered in New Delhi with its corporate office in
                                    the prestigious Okhla Industrial Area Phase I at a prominent building.
                                    Virtuos has delivered a world-class Maximizer Integrated CRM Platform
                                    for Fortune 50 Company — GE Healthcare which serves thousands of B2B
                                    customers across India. A state of the art application fully
                                    implemented by Virtuos Consulting team has delivered significant ROI
                                    in a short span.
                                </p>
                                <p>
                                    For more information on Virtuos Consulting Services and Case studies
                                    on CRM Success stories visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Teams_Undergo_Maximizer_Crm