import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Nav from '../components/Nav';

const Safe_Harbour = () => {
  useEffect(() => {
    document.title = "Website Safe harbor Statement – Virtuos Digital";

  }, []);
  return (
    <div className='client-detail-page landing-page'>
      <div className='canvas'>
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-6">
                  <h3 className="section-heading">
                    Safe Harbour <span className="highlight">Statement</span>
                  </h3>
                </div>
                <div className="col-6">
                  <figure>
                    <img
                      src={`/img/svg/safe-harbour-banner-img.svg`}
                      alt="Safe Harbour Statement - Virtuos"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="legal-section">
            <div className="page-wrap legal-wrapper">
              <div className="legal-doc-wrapper">
                <span className="date">Last Updated: 1 November 2022</span>
                <p>
                  The content on this website is intended to outline our general product
                  and services direction and to share our ideas. Our website’s content
                  may include predictions, estimates or other information that might be
                  considered forward-looking. While these forward-looking statements
                  represent our current judgment on what the future holds, they are
                  subject to risks and uncertainties that could cause actual results to
                  differ materially (<em>significantly</em>). Details shared here are
                  intended for information purposes only, and may not be incorporated
                  into any contract. It is not a commitment to deliver any material,
                  code, or functionality, and should not be relied upon in making
                  purchasing decisions.You are cautioned not to place undue reliance on
                  these forward-looking statements, which reflect our opinions only as
                  of the date the relevant content was posted. Please keep in mind that
                  we are not obligating ourselves to revise or publicly release the
                  results of any revision to these forward-looking statements in light
                  of new information or future events.
                </p>
                <p>
                  The development, release, and timing of any products ot features or
                  functionality or services described herein remains at the sole
                  discretion of Virtuos.
                </p>
              </div>
            </div>
          </section>
          <Footer />
        </main>

      </div>
      <div className='off-canvas'>
        <Nav />
      </div>
    </div>
  )
}

export default Safe_Harbour