import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

const Exonomy_Budh = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/xonomy/budh");
  }, []);
  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };
  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };

  useEffect(() => {
    document.title =
      "Jump-Start Your Innovation Journey | Innovation On – Virtuos Xonomy";
  }, []);
  return (
    <div className="budh-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            {/*?php /*?*/}
            <figure className="bg-image-wrapper">
              <img src={`/img/svg/budh-header-bg.svg`} alt="Budh Xonomy" />
            </figure>
            {/*?php * /?*/}
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-7 item-wrapper">
                  <figure>
                    <img src={`/img/svg/budh-exonomy-logo.svg`} />
                  </figure>
                  <h3>The Goodman is the friend of all living things.</h3>
                  <span>- Mahatma Gandhi</span>
                </div>
                <div className="col-4-half">
                  <figure>
                    <img src={`/img/svg/mahatma-gandhi-illus.svg`} />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/xonomy/`}>Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/xonomy/budh/`} className="active">
                    BUDH XONOMY
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud/`}>EXPERIENCE CLOUD</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-crowd/`}>EXPERIENCE CROWD</NavLink>
                </li>
                <li>
                  <NavLink to={`/io/`}>INNOVATION ON</NavLink>
                </li>
              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${
                    showExtraLinks ? "active" : ""
                  } inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" /> More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/business4-0/`}>
                          BUSINESS 4.0 STRATEGY
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/aury/`}>AURY PLATFORM</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/`}>VIRTUOS VUCA</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/ventures/`}>VENTURES</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/agnitor/`}>AGNITOR</NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/experience-crowd#dati`}
                          className="smooth"
                        >
                          DATI DATA CROWD
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="light-blue-bg two-col-section">
            <h2 className="section-main-heading">
              <span className="highlight">
                Budh<sub>®</sub>
              </span>{" "}
              Xonomy
            </h2>
            <span className="section-desc">
              An exciting opportunity to create new ecosystems — more resource
              efficient and sustainable.
            </span>
            <div className="row page-wrap">
              <div className="col-4 item offport-down">
                <figure>
                  <img
                    src={`/img/renewable-energy-img.jpg`}
                    alt="Renewable Energy"
                  />
                </figure>
                <h3>
                  Renewable <br /> Energy
                </h3>
                <p>
                  Long-term financial viability and sustainability of
                  conventional methods of energy provisioning face huge hurdles.
                  Here’s a great opportunity to innovate on new SmartLED Budh
                  <sub>®</sub> Bulb and other renewable energy business models.
                </p>
              </div>
              <div className="col-4 item offport-down">
                <figure>
                  <img
                    src={`/img/sustainable-development-img.jpg`}
                    alt="Sustainable Development"
                  />
                </figure>
                <h3>
                  Sustainable <br /> Development
                </h3>
                <p>
                  At Budh<sub>®</sub>, our vision is to eradicate poverty,
                  protect the planet and ensure a great living for all current
                  and future generations. We participate and completely align
                  with{" "}
                  <NavLink
                    to="http://www.un.org/sustainabledevelopment/sustainable-development-goals/"
                    rel="nofollow"
                    target="_blank"
                    className="link"
                  >
                    UN’s sustainable goals
                  </NavLink>{" "}
                  in creating a beautiful tomorrow preserving nature and earth.
                </p>
              </div>
              <div className="col-4 item offport-down">
                <figure>
                  <img
                    src={`/img/svg/smart-farming-img.svg`}
                    alt="Smart Farming"
                  />
                </figure>
                <h3>
                  Smart <br /> Farming
                </h3>
                <p>
                  Food and Agriculture are US$5 trillion business growing
                  phenomenally, and the caloric demand is likely to go up by 50%
                  in 2 decades. At Budh
                  <sub>®</sub>, we envision the future of smart and sustainable
                  farming by using technological innovations, AI, and Big data.
                </p>
              </div>
            </div>
          </section>
          <section className="section-breaker">
            <figure>
              <img src={`/img/budh-breaker-01.jpg`} alt="Budh Smart Cities" />
            </figure>
            <div className="content-wrapper offport-up">
              <h2>BUDH SMART CITIES</h2>
            </div>
          </section>
          <section className="page-wrap industrie">
            <div className="row offport-down">
              <div className="col-4 item-wrapper">
                <div className="item">
                  <h3>Smart Buildings</h3>
                  <figure className="illus">
                    <img
                      src={`/img/svg/smart-buildings-img.svg`}
                      alt="Smart Buildings"
                    />
                  </figure>
                  <p>
                    Go green and conceive Smart Building technologies with ideas
                    to save energy reducing carbon emissions, and optimally use
                    water, gas, and other resources. Enormous opportunities for
                    creating an experiential design and improving quality of
                    life.
                  </p>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <div className="item">
                  <h3>Smart Network</h3>
                  <figure className="illus">
                    <img
                      src={`/img/smart-network-img.jpg`}
                      alt="Smart Network"
                    />
                  </figure>
                  <p>
                    By 2020 smart cities will switch from conventional street
                    lamps to LEDs that will have some of the connectivity to
                    monitor the lamp and meter the power. This could be another
                    opportunity to provide citywide WAN to monitor cityscape.
                  </p>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <div className="item">
                  <h3>Smart Transportation</h3>
                  <figure className="illus">
                    <img
                      src={`/img/svg/smart-transportation-img.svg`}
                      alt="Smart Transportation"
                    />
                  </figure>
                  <p>
                    As part of city planning lamp posts will be capitalized to
                    handle traffic congestion, enforce safe driving conditions,
                    and ensure security. With IOT and Augmented Reality
                    technologies, City CIOs can holistically optimize city
                    operations.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="social-breaker">
            <figure>
              <img
                src={`/img/holding-mobile.jpg`}
                alt="Digital Customer Experience"
              />
            </figure>
            <div className="social-breaker-wrapper">
              <div className="page-wrap row">
                <div className="col-4 item-wrapper offport-left">
                  <div className="item">
                    <span className="icon">
                      <i className="fab fa-facebook" />
                    </span>
                    <h3 className="title">Join the revolution on facebook</h3>
                    <span className="url-wrap">/Budhcom</span>
                    <NavLink
                      to="http://facebook.com/Budhcom"
                      rel="nofollow"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Join Now
                    </NavLink>
                  </div>
                </div>
                <div className="col-4 item-wrapper offport-down">
                  <div className="item">
                    <span className="icon">
                      <i className="fab fa-twitter" />
                    </span>
                    <h3 className="title">Follow to stay updated</h3>
                    <span className="url-wrap">@Budh_com</span>
                    <NavLink
                      to="http://twitter.com/Budh_com"
                      rel="nofollow"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Follow us
                    </NavLink>
                  </div>
                </div>
                <div className="col-4 item-wrapper offport-right">
                  <div className="item">
                    <span className="icon">
                      <i className="fab fa-linkedin" />
                    </span>
                    <h3 className="title">Join the business on Linkedin</h3>
                    <span className="url-wrap">/budh</span>
                    <NavLink
                      to="http://linkedin.com/company/budh/"
                      rel="nofollow"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Follow
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*?php include '../../footer-xonomy.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Exonomy_Budh;
