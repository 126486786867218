import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

const Virtuos_Embraces_Business_N_Model = () => {
  useEffect(() => {
    document.title =
      "Virtuos embraces New Digital Business Model – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-embraces-business-n-model-img-big.jpg`}
                  alt="Virtuos embraces Business(9) Model Innovation with Post-COVID Strategy"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram, 20 July 2021</span>
                <h2 className="article-heading">
                  Virtuos embraces Business(9) Model Innovation with Post-COVID
                  Strategy
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos has embarked on a new Post-COVID19 Strategy for
                  creating a business model that could survive for the long
                  haul. COVID-19 will accelerate the speed of innovation by 2X
                  in the next 1-2 years, contributing to higher revenue for
                  eCommerce and Cloud Companies.
                </p>
                <p>
                  Virtuos shifts its strategy towards sustaining and growing the
                  existing businesses and still later wringing efficiency from
                  these. We created three stages whereby business model elements
                  represent an area of focus during the next three years until
                  March 2023. During the next three years beginning July 2020,
                  each journey stage shall be conducive to a specific type of
                  innovation, building particularly interdependencies into the
                  model and is responsive to a particular set of permanence
                  metrics.
                </p>
                <p>
                  <strong>Stage 1 : Creation</strong>
                </p>
                <p>
                  We will be focussing on Value creation by offering unique and
                  differentiated products and services best suited for specific
                  to COVID-19/ Post-COVID19 scenarios. Talented resources will
                  be retained, improving traditional business processes and
                  contributing to profits.
                </p>
                <p>
                  <strong>Stage 2 : Sustaining Innovation</strong>
                </p>
                <p>
                  We are innovating on the new models to help our customers in
                  the wake of the COVID-19 impact. We continue to train and
                  retrain existing resources to better prepare for the future.
                  We will constantly nurture new avenues for increasing revenue
                  during the COVID-19 Period.
                </p>
                <p>
                  <strong>Stage 3 : Efficiency</strong>
                </p>
                <p>
                  The Company will have internal digital transformation
                  initiatives by launching "
                  <NavLink
                    to={`/experience-new/`}
                    target="_blank"
                    className="link"
                  >
                    ExperienceNew
                  </NavLink>
                  " strategy to coach and guide internal teams and introducing
                  Composabl™ practice is founded on Composable Thinking,
                  Composable Architecture and Composable Technologies.
                </p>
                <p>
                  "As part of the strategy Virtuos will be focussing on few key
                  businesses by spinning them as separate units — Venky Vijay
                  Reddi, the ExperienCEO said. Throughout FY21, FY22 and FY23,
                  we will expect to re-engineer our business operations growing
                  the independent strategic businesses organically".
                </p>
                <p>
                  Virtuos Vivacis will be the first business operation from
                  Consultare stable to focus on CRM and Contract Lifecycle
                  Management (CLM) for Small and Midmarket enterprise customers,
                  followed by Oracle CX Business in the FY21.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. is a leading Experience Consultancy and
                  Digital Transformist with its global operations across US, UK,
                  Europe, Middle East and India. Virtuos has four important
                  business operations, namely{" "}
                  <NavLink to={`/consultare/`} target="_blank" className="link">
                    Consultare
                  </NavLink>{" "}
                  and
                  <NavLink to={`/xonomy/`} target="_blank" className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Microsoft Dynamics, Creatio Low Code No Code
                  Platform &amp; CRM Applications; Agiloft Contract Lifecycle
                  Management (CLM) amongst other Composable Products &amp;
                  Services.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/xonomy/`} target="_blank" className="link">
                    Xonomy
                  </NavLink>
                  ideates, incubates and invests in the next-generation
                  businesses harnessing
                  <NavLink to={`/xonomy/ai/`} target="_blank" className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce, Blockchain and Digital technologies. At
                  Virtuos Xonomy, we manage new ventures that include Giftcart —
                  Gifting Experiences Commerce Company and Indic—the Portfolio
                  of Experience Commerce Brands.
                </p>
                <p>
                  For more information visit{" "}
                  <NavLink
                    to="http://www.virtuos.com"
                    target="_blank"
                    className="link"
                  >
                    www.virtuos.com
                  </NavLink>{" "}
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Embraces_Business_N_Model;
