import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import ReCaptcha from "react-google-recaptcha";
// import emailjs from "@emailjs/browser";

const Digital_New = () => {
  const [phoneError, setPhoneError] = useState(false);
  const [userData, setuserData] = useState({
    webURL: `/digital-new`,
    formType: "C_Write_to_us",
    name: "",
    email: "",
    designation: "",
    company: "",
    firstGoal: "",
    secondGoal: "",
    thirdGoal: "",
    timeToBegin: "",
    seekedInformation: "",
  });
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(true);
  const handleRecaptchaChange = (value) => {
    console.log("Recaptcha");
    setRecaptchaValue(value);
    setCaptchaError(false);
  };
  const navigate = useNavigate();
  //we are storign data in states
  const handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setuserData({ ...userData, [name]: value });
    console.log("inside handlechange");
    // console.log(userData.name)
  };
  const handleSubmit = async (e) => {
    console.log("insidde contcat form");
    e.preventDefault();
    const {
      webURL,
      formType,
      name,
      email,
      firstGoal,
      secondGoal,
      thirdGoal,
      timeToBegin,
      seekedInformation,
    } = userData;
    if (captchaError) {
      return;
    }
    const res = await fetch(`${process.env.REACT_APP_API_URL}/db/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
      body: JSON.stringify({
        webURL: webURL,
        type: formType,
        full_name: name,
        email: email,
        firstGoal: firstGoal,
        secondGoal: secondGoal,
        thirdGoal: thirdGoal,
        timeToBegin: timeToBegin,
        seekedInformation: seekedInformation,
      }),
    });
    const data = await res.json();

    if (!data) {
      console.log("message not send");
    } else {
      // alert( "message send" );
      setuserData({ ...userData, message: "" });
      navigate("/thankyou"); // Navigate to "/thankyou" route
    }
    // emailjs
    //   .send(
    //     process.env.REACT_APP_SERVICE_ID,
    //     process.env.REACT_APP_TEMPLATE_ID,
    //     {
    //       from_name: userData.name,
    //       to_name: process.env.REACT_APP_TO_NAME,
    //       from_email: userData.email,
    //       to_email: process.env.REACT_APP_TO_EMAIL,
    //       message: userData.message,
    //     },
    //     process.env.REACT_APP_PUBLIC_KEY
    //   )
    //   .then(
    //     () => {
    //       // alert("Thank you. I will get back to you as soon as possible.");

    //       setuserData({
    //         name: "",
    //         email: "",
    //         designation: "",
    //         company: "",
    //         firstGoal: "",
    //         secondGoal: "",
    //         thirdGoal: "",
    //         timeToBegin: "",
    //         seekedInformation: "",
    //       });
    //     },
    //     (error) => {
    //       console.error(error);

    //       // alert("Ahh, something went wrong. Please try again.");
    //     }
    //   );
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [message, setMessage] = useState("");
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleChangeMessage = (event) => {
    const value = event.target.value;
    setMessage(value);
  };
  const [seekedInformation, setSeekedInformation] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSeekedInformation((prevOptions) => [...prevOptions, value]);
    } else {
      setSeekedInformation((prevOptions) =>
        prevOptions.filter((option) => option !== value)
      );
    }
  };

  const remainingChars = 200 - userData?.message?.length;
  const [isHovered, setIsHovered] = useState(false);
  const buttonStyle = {
    color: isHovered ? "red" : "black",
    fontWeight: "bold",
    transition: "color 0.3s",
  };

  useEffect(() => {
    document.title =
      "Virtuos Digital.New is a Digital Transformation Tool for determining the Maturity in your organization.";
  }, []);
  return (
    <div class="oracle-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="header-container">
            <div className="header">
              <div className="row page-wrap">
                <div className="col-12">
                  <h1>
                    Virtuos Digital.New is a Digital Transformation Tool for
                    determining the Maturity in your organization.
                  </h1>
                </div>
              </div>
            </div>
          </section>
          {/*  */}
          <div className="page-wrap row contact-container">
            <div className="col-12">
              <div className="contact-form-wrapper">
                <h3 className="section-heading">
                  Please fill up the information to receive "Digital ASAP"
                  Tailored Guide for your company
                </h3>
                <form
                  action=""
                  onSubmit={handleSubmit}
                  method="POST"
                  id="C_Write_to_us"
                >
                  <div className="input-wrapper hidden-fields">
                    <input
                      id="pageURL"
                      type="text"
                      name="pageURL"
                      className="pageURL"
                    />
                    <input
                      id="formType"
                      type="text"
                      name="formType"
                      defaultValue="C_Write_to_us"
                    />
                    <input
                      id="location"
                      type="text"
                      name="location"
                      className="userLocation"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Full Name</label>
                    <input
                      type="text"
                      name="name"
                      id="contact-name"
                      value={userData.name}
                      onChange={handleInput}
                      required
                      maxLength={100}
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Designation</label>
                    <input
                      type="text"
                      name="designation"
                      id="designation-name"
                      maxLength={100}
                      value={userData.designation}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Company</label>
                    <input
                      type="text"
                      name="company"
                      id="company-name"
                      maxLength={100}
                      value={userData.company}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={userData.email}
                      onChange={handleInput}
                      required
                      id="contact-email"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="phone">Phone </label>
                    <input
                      type="tel"
                      name="mobile"
                      value={userData.phone}
                      onChange={handleInput}
                      required
                      id="contact-mobile"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="seekedInformation">
                      Where do you seek Transformation?
                    </label>
                    <div className="col-12">
                      <label>
                        <input
                          type="checkbox"
                          name="seekedInformation[]"
                          value="CustomerExperience"
                          checked={seekedInformation.includes(
                            "CustomerExperience"
                          )}
                          onChange={handleCheckboxChange}
                        />
                        Customer Experience
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          name="seekedInformation[]"
                          value="InternetOfThings"
                          checked={seekedInformation.includes(
                            "InternetOfThings"
                          )}
                          onChange={handleCheckboxChange}
                        />
                        Internet Of Things
                      </label>
                    </div>
                    <div className="col-12">
                      <label>
                        <input
                          type="checkbox"
                          name="seekedInformation[]"
                          value="DigitalProcessAutomation"
                          checked={seekedInformation.includes(
                            "DigitalProcessAutomation"
                          )}
                          onChange={handleCheckboxChange}
                        />
                        Digital Process Automation
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          name="seekedInformation[]"
                          value="BusinessModelChange"
                          checked={seekedInformation.includes(
                            "BusinessModelChange"
                          )}
                          onChange={handleCheckboxChange}
                        />
                        Business Model Change
                      </label>
                    </div>
                    <div className="col-12">
                      <label>
                        <input
                          type="checkbox"
                          name="seekedInformation[]"
                          value="TalentManagement"
                          checked={seekedInformation.includes(
                            "TalentManagement"
                          )}
                          onChange={handleCheckboxChange}
                        />
                        Talent Management
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          name="seekedInformation[]"
                          value="FinanceOrSupplyChain"
                          checked={seekedInformation.includes(
                            "FinanceOrSupplyChain"
                          )}
                          onChange={handleCheckboxChange}
                        />
                        Finance or Supply Chain
                      </label>
                    </div>
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="three-goals">
                      What Are The Top Three Goals of Your Company for Digital
                      Transformation
                    </label>

                    <textarea
                      id="first-goal"
                      placeholder="First Goal"
                      name="firstGoal"
                      cols={30}
                      rows={3}
                      value={userData.message}
                      onChange={handleInput}
                      required
                      onKeyDown={(event) => {
                        if (
                          event.target.value.length >= 200 &&
                          event.key !== "Backspace"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onKeyUp={(event) => {
                        if (event.target.value.length > 200) {
                          const truncatedValue = event.target.value.slice(
                            0,
                            200
                          );
                          setuserData((prevUserData) => ({
                            ...prevUserData,
                            message: truncatedValue,
                          }));
                        }
                      }}
                    />
                    {/* <p>Remaining characters: <input readonly type="text" name="countdown" value="100" disabled></p> */}

                    <textarea
                      id="second-goal"
                      placeholder="Second Goal"
                      name="secondGoal"
                      cols={30}
                      rows={3}
                      value={userData.message}
                      onChange={handleInput}
                      required
                      onKeyDown={(event) => {
                        if (
                          event.target.value.length >= 200 &&
                          event.key !== "Backspace"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onKeyUp={(event) => {
                        if (event.target.value.length > 200) {
                          const truncatedValue = event.target.value.slice(
                            0,
                            200
                          );
                          setuserData((prevUserData) => ({
                            ...prevUserData,
                            message: truncatedValue,
                          }));
                        }
                      }}
                    />
                    {/* <p>Remaining characters: <input readonly type="text" name="countdown" value="100" disabled></p> */}

                    <textarea
                      id="third-goal"
                      placeholder="Third Goal"
                      name="thirdGoal"
                      cols={30}
                      rows={3}
                      value={userData.message}
                      onChange={handleInput}
                      required
                      onKeyDown={(event) => {
                        if (
                          event.target.value.length >= 200 &&
                          event.key !== "Backspace"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onKeyUp={(event) => {
                        if (event.target.value.length > 200) {
                          const truncatedValue = event.target.value.slice(
                            0,
                            200
                          );
                          setuserData((prevUserData) => ({
                            ...prevUserData,
                            message: truncatedValue,
                          }));
                        }
                      }}
                    />
                    {/* <p>Remaining characters: <input readonly type="text" name="countdown" value="100" disabled></p> */}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="timetoBegin">
                      How Soon you begin to Launch Digital Transformation
                      Initiatives
                    </label>
                    <select
                      name="timetoBegin"
                      id="timetoBegin"
                      value={userData.timetoBegin}
                      onChange={handleInput}
                      required
                    >
                      <option value="under5">1 to 3 months</option>
                      <option value="5to50">3 to 6 months</option>
                      <option value="50to100">6 to 12 months</option>
                      <option value="100to500">Not sure as of now</option>
                    </select>
                  </div>
                  <div className="input-wrapper">
                    <ReCaptcha
                      sitekey={process.env.REACT_APP_RECAPTCHA}
                      onChange={handleRecaptchaChange}
                    />
                    {captchaError && (
                      <p
                        className="required-message"
                        style={{ fontSize: "12px", color: "red" }}
                      >
                        * Please select CAPTCHA
                      </p>
                    )}
                  </div>
                  <input
                    onclick="createObject(); return false"
                    type="submit"
                    defaultValue="Submit"
                    className="btn btn-secondary"
                    // onClick={handleSubmit}
                  />
                </form>
              </div>
            </div>
            {/* <div class="col-4 offices-wrapper">
                  <div class="address-wrapper">
                      <div class="address">
                          <NavLinkddress>
                              <h2 class="section-heading"><strong class="highlight">India</strong> Headquarters
                              </h2>
                              <strong>Virtuos Digital Ltd.</strong>
                              <p>
                                  308-311 Emaar Digital Greens, <br>
                                  Tower A Golf Course Ext. Road, <br>
                                  Sector 61 Gurgaon - 122102
                              </p>
                              <span><strong>Phone:</strong> +91 (0124) 498 5500</span>
                              <span><strong>Fax:</strong> +91 (0124) 498 5515</span>
                          </NavLinkddress>
                      </div>
                  </div>
                  <div class="address-wrapper">
                      <div class="address">
                          <NavLinkddress>
                              <h2 class="section-heading">Global <strong class="highlight">US</strong>
                                  Headquarters</h2>
                              <strong>Virtuos Corporation</strong>
                              <p>
                                  Suite 203, 2880 Zanker Road <br>
                                  San Jose CA 95134 USA
                              </p>
                          </NavLinkddress>
                      </div>
                  </div>
                  <div class="address-wrapper">
                      <div class="other-offices">
                          <NavLinkddress>
                              <h2 class="section-heading">Other Offices</h2>
                              <ul class="row">
                                  <li>London</li>
                                  <li>Berlin</li>
                                  <li>Mumbai</li>
                                  <li>Bengaluru</li>
                                  <li>Hyderabad</li>
                              </ul>
                          </NavLinkddress>
                      </div>
                  </div>
              </div> */}
          </div>

          {/* <section class="stories-container">
          <figure class="bg">
              <img src="/img/oracle-cx-stories-bg.jpg" alt="">
          </figure>
          <h2 class="section-main-heading">ORACLE CX SUCCESS STORIES</h2>
          <span class="section-desc">Virtuos has delivered more than 100 Projects on Oracle CX &mdash; Mostly on
              Oracle Service Cloud. One of the world’s largest Travel Company &mdash; MakeMyTrip; another largest
              E-Commerce Company &mdash; Flipkart and India’s top 500 Industrial Group &mdash; TVS Credit amongst
              others.</span>
          <div class="row page-wrap">
              <NavLink to="/customers/makemytrip-case-study.php" class="col-4 item">
                  <figure>
                      <img src="/img/makemytrip-casestudy-img.jpg" alt="MakeMyTrip">
                  </figure>
                  <div class="txt">
                      <h3>MakeMyTrip</h3>
                      <span>MakeMyTrip.com halved avg. call handling time and increased revenue with CX
                          Cloud</span>
                  </div>
              </NavLink>
              <NavLink to="/customers/thomascook-case-study.php" class="col-4 item">
                  <figure>
                      <img src="/img/thomascook-casestudy-img.jpg" alt="Thomas Cook">
                  </figure>
                  <div class="txt">
                      <h3>Thomas Cook India</h3>
                      <span>Customer Engagement, Sales and Marketing solution</span>
                  </div>
              </NavLink>
              <NavLink to="/customers/tvs-credit-case-study.php" class="col-4 item">
                  <figure>
                      <img src="/img/tvsc-casestudy-img.jpg" alt="TVS Credit">
                  </figure>
                  <div class="txt">
                      <h3>TVS Credit</h3>
                      <span>Customer Experience Transformation Project using Oracle platform</span>
                  </div>
              </NavLink>
          </div>
      </section> */}
          {/* <section class="customers">
          <div class="row">
              <div class="col-2 left-block">Other Customers</div>
              <div class="col-10 right-block">
                  <ul>
                      <li>
                          <img src="/img/svg/flipkart.svg" alt="Flipkart.com">
                      </li>
                      <li>
                          <img src="/img/myntra-logo.png" alt="Myntra.com">
                      </li>
                      <li>
                          <img src="/img/svg/icons/india-first-logo.svg"
                              alt="India First">
                      </li>
                      <li>
                          <img src="/img/craftsvilla-logo.png" alt="Craftsvilla">
                      </li>
                      <li>
                          <img src="/img/nus-singapore-logo.png"
                              alt="National University of Singapore">
                      </li>
                  </ul>
              </div>
          </div>
      </section> */}
          {/* <section class="offers-container">
          <h2 class="section-main-heading">FABULOUS OFFERINGS FROM <span class="highlight">VIRTUOS</span></h2>
          <span class="section-desc">Virtuos Vaves Oracle CX Practice has newest offerings designed for the
              Post-COVID economy. These products have been acclaimed worldwide for delivering continuous
              intelligence while providing continuous improvement of customer experience.</span>
          <div class="row page-wrap">
              <div class="col-4-half img-block">
                  <figure>
                      <img src="/img/oracle-offering-bg.jpg" alt="">
                  </figure>
                  <div class="top-logo">
                      <img src="/img/svg/icons/oracle-logo-white.svg" alt="Oracle">
                  </div>
                  <div class="bottom-logo">
                      <img src="/img/svg/icons/virtuos-vaves-logo.svg"
                          alt="Virtuos Vaves">
                  </div>
              </div>
              <div class="col-7 items-container">
                  <div class="item">
                      <figure>
                          <img src="/img/svg/icons/cxnow-logo-icon.svg" alt="">
                      </figure>
                      <div class="txt">
                          <h3><NavLink to="/cxnow/" target="_blank">CXNOW Transformation</NavLink>
                          </h3>
                          <p>Customer Experience is not a project &mdash; it’s a program. Virtuos CXNow Program is
                              designed to transform your customer’s experiences on a continuous basis. Some of the
                              leading brands have undergone significant customer driven digital transformation. <NavLink
                                  to="/cxnow/" target="_blank" class="link">Know
                                  more...</NavLink></p>
                      </div>
                  </div>
                  <div class="item">
                      <figure>
                          <img src="/img/svg/icons/cxdesk-icon.svg" alt="">
                      </figure>
                      <div class="txt">
                          <h3><NavLink to="/cxdesk/" target="_blank">CXDESK CX Continuum</NavLink>
                          </h3>
                          <p>CXDesk is a diverse, multi-functional approach to optimize CRM investments through
                              technology upgrades, continuous improvement, and integration delivering operational
                              excellence and reduced TCO. <NavLink to="/cxdesk/"
                                  target="_blank" class="link">Know more...</NavLink></p>
                      </div>
                  </div>
                  <div class="item">
                      <figure>
                          <img src="/img/svg/icons/rainbow-cx-logo-icon.svg" alt="">
                      </figure>
                      <div class="txt">
                          <h3><NavLink to="/rainbowcx/" target="_blank">Rainbow CX
                                  Technologies</NavLink></h3>
                          <p>No single CRM vendor can offer all the technologies required for Customer driven
                              Digital Transformation. Virtuos offers technologies such as accelerators, tools, and
                              extensions; frameworks; and industry leading integrations from our
                              <strong>Relationware</strong> domain practice. <NavLink
                                  to="/rainbowcx/" target="_blank" class="link">Know
                                  more...</NavLink>
                          </p>
                      </div>
                  </div>
                  <div class="item">
                      <figure>
                          <img src="/img/svg/icons/rightapps-logo-icon.svg" alt="">
                      </figure>
                      <div class="txt">
                          <h3><NavLink to="/right-apps/" target="_blank">RightApps for Oracle
                                  CX</NavLink></h3>
                          <p>Progressive web apps are here to disrupt the traditional mobile app ecosystem with
                              intuitive, hyper-responsive, lightweight UX designs. Virtuos has built PWA for
                              Oracle CX Products for Agents to use Tablets, iPhone, and Android phones to assist
                              their customers wherever they are. <NavLink to="/right-apps/"
                                  target="_blank" class="link">Know more...</NavLink></p>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="testi-container">
          <div class="row page-wrap">
              <div class="col-12 header">
                  <span class="subtitle">What People Say</span>
                  <span class="title">Testimonials</span>
              </div>
          </div>
          <div class="row page-wrap">
              <div class="col-4 testi-wrapper">
                  <figure><img src="/img/svg/flipkart.svg" alt="Flipkart.com"></figure>
                  <p>We chose RightNow, because of exceptional Consulting Approach that Venky and Virtuos offered
                      for Flipkart.</p>
                  <span class="name">VP-Technology</span>
                  <span class="title">Flipkart.com</span>
              </div>
              <div class="col-4 testi-wrapper">
                  <figure><img src="/img/tvscs-logo.png" alt="TVS Credit"
                          style="width: 60%;">
                  </figure>
                  <p>With Virtuos Oracle CX Practice, we are able to serve thousands of dealers and extended
                      partners using additional components
                      from Oracle Service Cloud.</p>
                  <span class="name">Head of Technology</span>
                  <span class="title">TVS Credit</span>
              </div>
              <div class="col-4 testi-wrapper">
                  <figure><img src="/img/svg/make-my-trip-logo.svg" alt="MakeMyTrip.com">
                  </figure>
                  <p>RightNow CRM implemented by Virtuos has helped us in managing our Customer Experiences
                      better.</p>
                  <span class="name">Chief Technology Officer</span>
                  <span class="title">MakeMyTrip.com</span>
              </div>
          </div>
      </section> */}
          {/* <section class="tabs-container">
          <div class="row page-wrap tabs-wrapper">
              <div class="col-12 tab">
                  <NavLink to="JavaScript:Void(0);" class="tablinks" onclick="openTabs(event, 'tab1')" id="defaultOpen"><img src="/img/svg/icons/vision-icon.svg" alt=""><br>01. Vision Connect</NavLink>
                  <NavLink to="JavaScript:Void(0);" class="tablinks" onclick="openTabs(event, 'tab2')"><img src="/img/svg/icons/tech-insight-icon.svg" alt=""><br>02. Tech
                      Insights</NavLink>
                  <NavLink to="JavaScript:Void(0);" class="tablinks" onclick="openTabs(event, 'tab3')"><img src="/img/svg/icons/pace-layer-icon-black.svg" alt=""><br>03.
                      Pace Layered Strategy</NavLink>
                  <NavLink to="JavaScript:Void(0);" class="tablinks" onclick="openTabs(event, 'tab4')"><img src="/img/svg/icons/heart-icon.svg" alt=""><br>04. Happiests
                      Culture</NavLink>
              </div>
              <div class="row page-wrap">
                  <div class="col-12 tabcontent" id="tab1">
                      <div class="box1">
                          <figure>
                              <img src="/img/cdigital-tab1-img.jpg" alt="">
                          </figure>
                      </div>
                      <div class="box2">
                          <p><strong>01. VISION CONNECT</strong></p>
                          <p>Set operational objectives, establish a connect with the cross-functional team, and
                              understand CX initiatives to manage current state (CXOpia).</p>
                      </div>
                      <div class="box3">
                          <p><strong>You are in safe hands</strong></p>
                          <p>Virtuos has implemented over 200 CX projects across diverse verticals and technology
                              domains with high Customer Success rate.</p>
                          <NavLink to="/cxnow/cxprimer" class="btn btn-black">Schedule CX
                              Primer</NavLink>
                      </div>
                  </div>
                  <div class="col-12 tabcontent" id="tab2">
                      <div class="box1">
                          <figure>
                              <img src="/img/cdigital-tab2-img.jpg" alt="">
                          </figure>
                      </div>
                      <div class="box2">
                          <p><strong>02. TECH INSIGHTS</strong></p>
                          <p>Insight into the technologies that help customers to evaluate, measure and act on
                              customer perception, sentiment, and experiences.</p>
                      </div>
                      <div class="box3">
                          <p><strong>Our Technology Portfolio</strong></p>
                          <p>Virtuos has certified professionals on multiple CRM Technologies such as Salesforce,
                              Oracle, Microsof,t and Creatio (formerly BPM’Online).</p>
                          <NavLink to="/consultare/technology-services" class="btn btn-black">See our Portfolio</NavLink>
                      </div>
                  </div>
                  <div class="col-12 tabcontent" id="tab3">
                      <div class="box1">
                          <figure>
                              <img src="/img/cdigital-tab3-img.jpg" alt="">
                          </figure>
                      </div>
                      <div class="box2">
                          <p><strong>03. PACE LAYERED STRATEGY</strong></p>
                          <p>It is a framework of "Pace Layers" with the precise definition of strategic goals,
                              and how these goals are achieved through a process of iteration.</p>
                      </div>
                      <div class="box3">
                          <p><strong>Differentiation Strategy</strong></p>
                          <p>Systems of records and processes focus on standardization. Using our NOW framework,
                              we structure various differentiation techniques.</p>
                          <NavLink to="/consultare/pace-layered" class="btn btn-black">See PLUS
                              methodology</NavLink>
                      </div>
                  </div>
                  <div class="col-12 tabcontent" id="tab4">
                      <div class="box1">
                          <figure>
                              <img src="/img/cdigital-tab4-img.jpg" alt="">
                          </figure>
                      </div>
                      <div class="box2">
                          <p><strong>04. HAPPIESTS CULTURE</strong></p>
                          <p>It’s not just a happy working environment; it’s employees’ own space &mdash; where
                              they are obsessed to deliver happiness to customers.</p>
                      </div>
                      <div class="box3">
                          <p><strong>Happiests Building Blocks</strong></p>
                          <p>Our unique h-a-p-p-i-e-s-t-s building blocks define the characteristics of our
                              culture. These nine styles fit into integrated culture framework.</p>
                          <NavLink to="/careers/happiests-culture" class="btn btn-black">Learn
                              More</NavLink>
                      </div>
                  </div>
              </div>
          </div>
      </section> */}
          {/* <section class="industry-container grey-bg">
          <div class="row page-wrap">
              <div class="col-12 header">
                  <span class="subtitle">VIRTUOS + ORACLE SOLUTIONS</span>
                  <span class="title">Modern Industries</span>
              </div>
          </div>
          <div class="row page-wrap">
              <div class="col-4 item">
                  <div class="front">
                      <img src="/img/svg/industry-commerce-img.svg" alt="Retail">
                      <div class="overlay">
                          <div class="content">
                              <p>Virtuos has implemented dozens of CRM Projects for E-Commerce Industry Vertical.</p>
                              <NavLink to="/industry#indus-retail">Go to Industry Solutions <i class="fa fa-long-arrow-right"></i></NavLink>
                          </div>
                      </div>
                  </div>
                  <div class="des">
                      <p>Retail</p>
                      <p>Ecommerce</p>
                  </div>
              </div>
              <div class="col-4 item">
                  <div class="front">
                      <img src="/img/svg/industry-travel-img.svg" alt="Travel &amp; Hospitality">
                      <div class="overlay">
                          <div class="content">
                              <p>Online Travel Companies like MakeMyTrip, Thomas Cook amongst others are our clients.</p>
                              <NavLink to="/industry/#indus-travel">Go to Industry Solutions <i class="fa fa-long-arrow-right"></i></NavLink>
                          </div>
                      </div>
                  </div>
                  <div class="des">
                      <p>Travel &amp; Hospitality</p>
                      <p>ONLINE TRAVEL</p>
                  </div>
              </div>
              <div class="col-4 item">
                  <div class="front">
                      <img src="/img/svg/industry-banking-img.svg" alt="Banking &amp; Financial Services">
                      <div class="overlay">
                          <div class="content">
                              <p>TVS Credit, India First amongst others implemented CRM using Virtuos C.Digital Services.
                              </p>
                              <NavLink to="/industry#indus-banking">Go to Industry Solutions <i class="fa fa-long-arrow-right"></i></NavLink>
                          </div>
                      </div>
                  </div>
                  <div class="des">
                      <p>Banking &amp; Financial Services</p>
                      <p>NON BANKING FINANCE</p>
                  </div>
              </div>
          </div>
      </section> */}
          {/*?php include '../footer-consultare.php'; ?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Digital_New;
