import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Moves_To_A_New_Office_In_Gurgaon = () => {
    useEffect(() => {
        document.title = "Virtuos moves to a new independent office in Gurgaon, the suburb of New Delhi – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-moves-to-gurgaon-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 1 February, 2010</span>
                                <h2 className="article-heading">
                                    Virtuos moves to a new independent office in Gurgaon, the suburb of
                                    New Delhi
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Solutions in India shifts its Corporate office to an
                                    independent and spacious office space in Gurgaon, Haryana, to
                                    accommodate 40 to 50 employees. One of the reasons for moving to
                                    Gurgaon was that Virtuos would be closer to its vital customer —
                                    MakeMyTrip and also cater to many other prospects in Gurgaon.
                                </p>
                                <p>
                                    "Gurgaon has been one of the fastest-growing cities in India, and some
                                    think that Gurgaon is like Rome of Italy with High rise condominiums,
                                    glass buildings, and malls, accompanied by amazing civic
                                    infrastructure". Said Venky Vijay Reddi, CEO of Virtuos. “Gurgaon is
                                    already home to 500 key companies delivering great infrastructure, and
                                    there’s the abundant availability of talent and other resources to
                                    support our growth”.
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/virtuos-gurgaon-office-pics-2010.jpg`}
                                        alt=""
                                    />
                                </figure>
                                <p>
                                    Many of the employees who have been living in Delhi NCR have found
                                    commuting to Gurgaon was a snap as there was better connectivity and
                                    lesser congestion.
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based in
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company is inspired by the book — Virtuoso Teams and has entered
                                    the burgeoning CRM space by addressing three essential needs of
                                    growing businesses — Sales, Customer Service, and Marketing. Virtuos
                                    has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Barely a year-old Company — Virtuos has bagged Asia’s largest Service
                                    Cloud deal by signing a million-dollar contract with MakeMyTrip, a
                                    leading Online Travel Company based out of Delhi/NCR. Virtuos also has
                                    bagged one of the largest CRM Service Automation Contracts from
                                    Sutherland Global and Standard Chartered Bank in alliance with KANA
                                    Software. Virtuos has also implemented one of the most complex
                                    Sales-Service and Marketing integrated Solutions for GE-Healthcare.
                                    The Solution was developed on Maximizer Platform for GE-Healthcare,
                                    part of Fortune 50 Company. For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Moves_To_A_New_Office_In_Gurgaon