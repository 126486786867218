import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";
const Announcing_Strategy = () => {
  useEffect(() => {
    document.title =
      "Announcing 4.0 As A Strategy on 12th Anniversary – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/announcing-4.0-as-a-strategy-img-big.jpg`}
                  alt="Announcing 4.0 As A Strategy on 12th Anniversary"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram, 24 January 2020</span>
                <h2 className="article-heading">
                  Announcing 4.0 As A Strategy on 12th Anniversary
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos Digital announces “4.0 As A Strategy” to spearhead the
                  growth of the company into new domains, geographies, and
                  technologies. The 4.0 Strategy underpins the composition of an
                  organization's fundamental pillars - Company, People,
                  Customers, and the Planet.{" "}
                </p>
                <ul>
                  <li>
                    The "company first" strategy is for driving innovation,
                    culture, growth, and governance.
                  </li>
                  <li>
                    Employees are cherishing great careers with continuous
                    learning, growth, and job satisfaction.
                  </li>
                  <li>
                    Customers are receiving the best value from our products and
                    services.
                  </li>
                  <li>
                    Planet sustainable practices, business processes, and
                    ecosystems are making a difference.
                  </li>
                </ul>
                <p>
                  On our 12th Anniversary, we also celebrated by thanking
                  people, technologies, and things that were hugely responsible
                  for the growth of Virtuos over the last 12 years. A
                  Profit-making and completely debt-free company with a flawless
                  track record has produced 100’s of promising people by giving
                  them a great career. Dependable businesses are measured on how
                  much impact they made to their customers and employees.{" "}
                </p>
                <p>
                  The company took a new <strong>“One Vow”</strong> mission 2020
                  to transform the company by creating digitally dextrous
                  employees, delivering high-performance services and products
                  to customers, and caring for the society and planet in
                  meaningful ways.
                </p>
                <p>
                  We have created the Customer-Obsessed Operating Model To Drive
                  the growth of the company in this decade of 2020.
                </p>
                <p>
                  <img
                    src={`/img/customer-obsessed-operating-model-img.jpg`}
                    className="border"
                  />
                </p>
                <p>
                  <strong>Customer Wide Strategy:</strong> Customer Led business
                  transformation applying the confluence of Business 4.0 -
                  Industry 4.0; Global 4.0; Digital 4.0 and Society 5.0
                </p>
                <p>
                  <strong>Insights Driven Strategy:</strong> Insighteer, a new
                  strategy driver, will be critical to the success of every
                  employee by garnering insights from the industry, customers,
                  and other ecosystems.
                </p>
                <p>
                  <strong>Fast and Agile:</strong> Low-code No-Code by Vivacis
                  and Business Composition by Silsila and new ways of working by
                  using new talent via Agnitor. All new W.Digital Mastermind
                  series to create a new breed of workforce with the highest
                  productivity standards.
                </p>
                <p>
                  <strong>Connected:</strong> Using modern technologies and
                  approaches - both cloud and crowd, we are always connected.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. (Formerly Virtuos Solutions Private Ltd.)
                  is a leading Customer Experience (CX) &amp; Digital
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/cxdesk/`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/xonomy/ai/`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce, and Digital technologies. The{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  under the aegis of Virtuos Xonomy is a think tank to breed and
                  bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.vyo.com"
                    target="_blank"
                    className="link"
                  >
                    VYO.com
                  </NavLink>{" "}
                  (Part of BIGV 4.0 As A Strategy) and a host of unique
                  Experience Cloud and Experience Crowd applications. Virtuos
                  Experience Cloud business comprises a CEDAR strategy —{" "}
                  <NavLink
                    to="http://www.cloudle.com/"
                    target="_blank"
                    className="link"
                  >
                    Cloudle
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  . Virtuos Experience Crowd business will launch its first
                  product CustomerNow crowd in 2020.
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />

          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Announcing_Strategy;
