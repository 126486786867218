import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Attends_Kana_Customer_Summit_In_Uk = () => {
    useEffect(() => {
        document.title = "Virtuos Team attends Kana Customer Summit in UK – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-attends-kana-customer-summit-in-uk-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Coventry, UK - 11 October, 2010</span>
                                <h2 className="article-heading">
                                    Virtuos Team attends Kana Customer Summit in UK
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Team members — Venky Vijay Reddi, CEO, Shaloo Reddi, EVP, and
                                    Kristina Hermanns, Director attended the Virtuos KANA Summit in
                                    Coventry, UK.
                                </p>
                                <p>
                                    The Annual User Summit — KANA Connect was organized by KANA EMEA
                                    Office to network with the customers and partners in EMEA and APAC
                                    regions.
                                </p>
                                <p>
                                    KANA has previewed its future generation Engagement Platform in the
                                    works to offer the most modern set of features such as "Experience
                                    Flows," "Omni Web-Self-Service," and "Powerful integrations" with the
                                    backend applications. A current stack has already been in use at a
                                    leading Financial Service Company.
                                </p>
                                <p>
                                    I am thrilled to see Kristina making it to the Summit as she was away
                                    to Germany on Maternal leave. Said Venky Vijay Reddi, CEO of Virtuos
                                    We have met the team of KANA Channels and interacted with them on the
                                    business outlook and KANA's growth in the region.
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/virtuos-team-attends-kana-customer-summit-in-UK-event-pics.jpg`}
                                        alt="Virtuos Team attends Kana Customer Summit in UK"
                                    />
                                </figure>
                                <p>
                                    KANA has presented its business outlook after being acquired by the
                                    lead Investor KKR, and Kana's new arsenal to acquire new companies in
                                    this space. KANA has acquired Lagan Technologies and showcased its
                                    products for Citizen Experience (CX).
                                </p>
                                <p>
                                    KANA has been serving Fortune 500 Companies and leading brands such as
                                    USPS, Citibank, 1800 Flowers, and more than 1000 other customers. Said
                                    Kristina Hermanns, Director of Solution Engineering. We are privileged
                                    to work with KANA on a multitude of Modules such as Knowledge
                                    Management, WSS, Contact Center, and Response Email Management.{" "}
                                </p>
                                <h3 className="title">About KANA</h3>
                                <p>
                                    KANA is a leading Technology Company with Customer Management Software
                                    Platform to empower brands with Multichannel and cross channel
                                    engagement and redefine the customer service with modern tools.
                                </p>
                                <p>
                                    Mark Gainey founded KANA in 1996 to market a software package designed
                                    to help businesses manage email and Web-based communications. It grew
                                    around this core offering. In 1999, KANA Communications (as it was
                                    then known) acquired Connectify, followed by Business Evolution and
                                    NetDialog. In 2000, KANA made its then-largest acquisition, Silknet
                                    Software. The purchase price was $4.2 billion, even though both
                                    companies were relatively small. Silknet was a new{" "}
                                    <NavLink
                                        to="https://en.wikipedia.org/wiki/Multichannel_marketing"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link"
                                    >
                                        multichannel marketing
                                    </NavLink>{" "}
                                    software company. Industry analysts were generally cool to the
                                    purchase though some said it made sense strategically. In 2001, KANA
                                    merged with BroadBase software. In 2010,{" "}
                                    <NavLink
                                        to="https://en.wikipedia.org/wiki/Accel-KKR"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Accel-KKR
                                    </NavLink>{" "}
                                    acquired KANA's assets and liabilities, and in the same year, KANA
                                    acquired{" "}
                                    <NavLink
                                        to="https://en.wikipedia.org/wiki/Lagan_Technologies"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link"
                                    >
                                        Lagan Technologies
                                    </NavLink>
                                    , a government-to-citizen{" "}
                                    <NavLink
                                        to="https://en.wikipedia.org/wiki/Customer_relationship_management"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        customer relationship management
                                    </NavLink>{" "}
                                    company based in{" "}
                                    <NavLink
                                        to="https://en.wikipedia.org/wiki/Northern_Ireland"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Northern Ireland
                                    </NavLink>
                                    . For more information visit{" "}
                                    <NavLink
                                        to="http://www.kana.com"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link"
                                    >
                                        www.kana.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based in
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book – Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three essential needs
                                    of growing businesses — Sales, Customer Service, and Marketing.
                                    Virtuos has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink
                                        to="http://www.kana.com"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink
                                        to="http://www.maximizer.com"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink
                                        to="http://www.rightnow.com"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has been fiercely growing in the space of CRM, Service Cloud
                                    and Cloud Computing space by delivering the cutting edge solutions to
                                    leading brands like MakeMyTrip, GE-Healthcare, Standard Chartered
                                    Bank, Party Gaming, Fundtech amongst other companies. For more
                                    information visit{" "}
                                    <NavLink to="https://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Attends_Kana_Customer_Summit_In_Uk