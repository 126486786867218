import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import WPAPI from "wpapi";
import ReCaptcha from "react-google-recaptcha";

// import NewsTwitter from "../../components/NewsTwitter";
// import SubscribeNow from "../News/SubscribeNow";
import { Helmet } from "react-helmet";
const BlogDetail = () => {
  const [blogs, setBlogs] = useState([]);
  const [status, setStatus] = useState(" ");
  // const [email, setEmail] = useState();
  // const [content, setContent] = useState();
  const [userData, setuserData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(true);
  const handleRecaptchaChange = (value) => {
    console.log("Recaptcha");
    setRecaptchaValue(value);
    setCaptchaError(false);
  };
  const handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setuserData({ ...userData, [name]: value });
    console.log("inside handlechange");
    // console.log(userData.name)
  };
  // useEffect(() => {
  //     document.title = "BPM CX";
  // }, []);
  // const { id } = useParams();
  // console.log(id);
  // Get the current URL
  const currentUrl = window.location.href;

  // Extract the slug after '/blog/'
  const parts = currentUrl.split("/blog/");
  const id = parts[1] || "";

  // Output the slug
  console.log(id);

  useEffect(() => {
    const wp = new WPAPI({
      endpoint: "https://blog.virtuos.com/wp-json",
    });
    wp.posts()
      .perPage(100) // Adjust the number of posts to retrieve
      .embed() // Include embedded data (e.g., featured images)
      .then((data) => {
        // console.log(data);
        setBlogs(data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = userData;
    console.log(name, email, message, blog.id);
    const wp = new WPAPI({
      endpoint: "https://blog.virtuos.com/wp-json",
      username: "admin_shivam",
      password: "srEiGwciHL6",
    });

    wp.comments()
      .create({
        post: blog.id, // ID of the post to which the comment should be added
        author_name: name,
        author_email: email,
        content: message,
      })
      .then((response) => {
        console.log(userData.name, "-=-=-=-=-");
        console.log("Comment added successfully:", response);
        setStatus("Comment added successfully*");
      })
      .catch((error) => {
        console.error("Error adding comment:", error);
      });
    console.log(userData.name, "=-=-=0-=0=-");
    setuserData({ name: " ", email: " ", message: " " });
    console.log(userData.name);
  };
  const filterblogs = blogs.filter((blog) => blog.slug === id);
  const otherblogs = blogs.filter((blog) => blog.slug !== id);

  //   console.log(filterblogs);
  //   useEffect(() => {
  //     const wp = new WPAPI({
  //       endpoint: "https://blog.virtuos.com/wp-json",
  //     });
  //     wp.posts()
  //       .perPage(10) // Adjust the number of posts to retrieve
  //       .embed() // Include embedded data (e.g., featured images)
  //       .then((data) => {
  //         console.log(data);
  //         // setImages(data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching images:", error);
  //       });
  //   }, []);
  const blog = filterblogs[0];
  console.log(blog);
  function formatDate(dateString) {
    return new Date(dateString).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }
  const remainingChars = 200 - userData.message.length;

  console.log(blog);
  useEffect(() => {
    setTimeout(() => {
      setStatus(" ");
    }, [3000]);
  }, [status]);
  console.log(status);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        {blog ? (
          <main className="main-wrapper" id="news_main">
            <Helmet>
              <title>{blog.yoast_head_json.title}</title>
              <meta
                name="description"
                content={blog.yoast_head_json.description}
              />
              {/* Other meta tags, stylesheets, scripts, etc. */}
            </Helmet>
            {/* News Detail */}
            <section className="main-blogs" id="news">
              <div className="article-wrapper">
                <figure className="article-image">
                  <img
                    src={blog?._embedded["wp:featuredmedia"][0].source_url}
                    alt="VIRTUOS UNVEILS NEXORAMA STRATEGY TO DIVERSIFY INTO NEW AREAS"
                  />
                </figure>
                <div className="page-wrap article-info">
                  <span className="date-posted">
                    {formatDate(blog.date.substring(0, 10))}
                  </span>
                  <h2
                    className="article-heading"
                    dangerouslySetInnerHTML={{
                      __html: blog.title.rendered,
                    }}
                  />
                  <p>
                    Author : {blog.yoast_head_json.author} <br />
                    {blog?._embedded?.replies && (
                      <> Comments: {blog?._embedded?.replies[0].length}</>
                    )}
                  </p>
                  {/* <p>Comments: {comments.length}</p> */}
                  {/* <h2 className="article-heading">{blog.title.rendered}</h2> */}
                </div>
              </div>
              <div className="page-wrap row">
                <div className="col-8 article-content blog-link">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: blog.content.rendered,
                    }}
                  />
                </div>

                <div className="col-4 news-articles-wrapper">
                  <h3
                    className="font-weight600 blue"
                    style={{ marginTop: "2rem", marginLeft: "1rem" }}
                  >
                    More to read...
                  </h3>

                  <div className="">
                    {otherblogs.slice(0, 5).map((blog) => (
                      <div className="row" key={blog.id}>
                        <div className=" filterDiv 21-23">
                          <NavLink
                            to={`/blog/${blog.slug}`}
                            className="news-article"
                          >
                            <figure>
                              <img
                                src={
                                  blog._embedded["wp:featuredmedia"][0]
                                    .source_url
                                }
                                alt="Virtuos unveils Nexorama strategy to diversify into new areas"
                              />
                              <div className="hover-wrapper">
                                <span>
                                  Read Full{" "}
                                  <i className="fal fa-long-arrow-right" />
                                </span>
                              </div>
                            </figure>
                            <div className="title-wrapper">
                              <span className="date-posted">
                                {formatDate(blog.date.substring(0, 10))}
                              </span>
                              <h3
                                className="title"
                                style={{ fontWeight: "bold" }}
                              >
                                {blog.title.rendered}
                              </h3>
                            </div>
                          </NavLink>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className="col-3-half sticky-content"> */}
                {/* <NewsTwitter /> */}
                {/* </div> */}
              </div>
              <div className="page-wrap">
                {blog?._embedded?.replies && (
                  <>
                    <h3 className="font-weight600 ">Comments</h3>

                    {blog?._embedded?.replies[0].map((comment) => (
                      <div style={{ marginTop: "10px" }} key={comment.id}>
                        <div className="custom-flex-start">
                          <img
                            style={{ borderRadius: "50%" }}
                            src={comment.author_avatar_urls[48]}
                            alt="author-img"
                          />
                          <p
                            className=""
                            style={{ marginBottom: "0px", fontWeight: "500" }}
                          >
                            {comment.author_name}
                          </p>
                        </div>

                        <p
                          style={{ marginTop: "5px" }}
                          dangerouslySetInnerHTML={{
                            __html: comment.content.rendered,
                          }}
                        />
                        <p style={{ fontWeight: "500", fontSize: "12px" }}>
                          {formatDate(comment.date.substring(0, 10))}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <form
                onSubmit={handleSubmit}
                className="page-wrap "
                action=""
                method="POST"
              >
                {/* <div> */}
                <h3 className="font-weight600 ">Add your comments</h3>

                <div className="custom-flex-col">
                  <div className="">
                    {/* <div className="input-wrapper hidden-fields">
                      <input type="text" name="pageURL" className="pageURL" />
                      <input
                        type="text"
                        name="formType"
                        id="formType"
                        defaultValue="D_Agiloft"
                      />
                      <input
                        type="text"
                        name="location"
                        className="userLocation"
                      />
                    </div> */}
                    <div className="input-wrapper">
                      <label htmlFor="">Name</label>
                      <input
                        name="name"
                        tabIndex={1}
                        type="text"
                        maxLength={100}
                        value={userData.name}
                        required
                        onChange={handleInput}
                      />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="">Email</label>
                      <input
                        name="email"
                        tabIndex={2}
                        type="email"
                        required
                        value={userData.email}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="input-wrapper">
                      <label htmlFor="">Comment</label>
                      <textarea
                        required
                        name="message"
                        cols={30}
                        rows={3}
                        value={userData.message}
                        onChange={handleInput}
                        // onKeyDown={(event) => {
                        //   if (
                        //     event.target.value.length >= 200 &&
                        //     event.key !== "Backspace"
                        //   ) {
                        //     event.preventDefault();
                        //   }
                        // }}
                        // onKeyUp={(event) => {
                        //   if (event.target.value.length > 200) {
                        //     const truncatedValue = event.target.value.slice(
                        //       0,
                        //       200
                        //     );
                        //     setuserData((prevUserData) => ({
                        //       ...prevUserData,
                        //       message: truncatedValue,
                        //     }));
                        //   }
                        // }}
                      />

                      <span style={{ fontSize: "12px" }}>
                        Remaining characters: {remainingChars}
                      </span>
                    </div>

                    <div className="input-wrapper">
                      <ReCaptcha
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={handleRecaptchaChange}
                      />
                      {captchaError && (
                        <p
                          className="required-message"
                          style={{ fontSize: "12px", color: "red" }}
                        >
                          * Please select CAPTCHA
                        </p>
                      )}
                    </div>
                    <p className="status-blog">{status}</p>
                  </div>
                </div>
                <div className="btn-wrapper">
                  <input
                    className="btn btn-secondary"
                    type="submit"
                    defaultValue="Add Comment"
                    tabIndex={7}
                    value="Add Comment"
                  />
                </div>
                {/* </div> */}
              </form>
              <div className="page-wrap" style={{ marginTop: "3rem" }}>
                <NavLink to={"/blog"}>
                  <button
                    className="btn btn-secondary"
                    type="GO BACK"
                    // defaultValue="Add Comment"
                    // tabIndex={7}
                    // onClick={handleSubmit}
                  >
                    GO BACK
                  </button>
                </NavLink>
              </div>
            </section>
            {/* <SubscribeNow /> */}
            <Footer />
          </main>
        ) : (
          <div className="bold custom-flex" style={{ fontWeight: "bold" }}>
            Wait for the content to load...
          </div>
        )}
      </div>

      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default BlogDetail;
