import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Co_Sponsors_Oracle = () => {
    useEffect(() => {
        document.title = "Virtuos | Virtuos co-sponsors Oracle Applications APAC Partners Meet at Chiang Mai Thailand Aug 1 to 3 2012";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/oracle-applications-apac-partners-meet-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    Chiang Mai, Thailand - 4 August, 2012
                                </span>
                                <h2 className="article-heading">
                                    Virtuos co-sponsors Oracle Applications APAC Partners Meet at Chiang
                                    Mai Thailand Aug 1 to 3 2012
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos, one of the fastest growing Customer Experience Consulting
                                    Leaders, has co-sponsored Oracle Application APAC Partners Meet held
                                    at Chiang Mai Thailand between August 1 and 3, 2012. Application
                                    Partners from more than dozen Asian countries have arrived to
                                    participate in this Annual Event steered by Ms.Yen Yen Tan, Senior
                                    Vice President, Applications at oracle Asia.
                                </p>
                                <p>
                                    The event was attended by Virtuos Team members headed by Venky Vijay
                                    Reddi, the CEO of Virtuos Solutions.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-co-sponsors-oracle-applications-pic.jpg`}
                                        alt=""
                                    />
                                </figure>
                                <br />
                                <p>
                                    “Virtuos has created top-notch logos as RightNow Customers in India ”
                                    Said YS Lee, Director at Oracle CX Business Unit. “We have a long
                                    association of working together in India clinching major deals.”
                                </p>
                                <p>
                                    Virtuos has also discovered the need for launching “CRM Software
                                    Administration As A Service” for its clients to increase the
                                    stickiness and bring cx continuum to the customers’ operations.
                                </p>
                                <p>
                                    “The idea of adding Administration to our CXDesk (
                                    <NavLink to="http://www.cxdesk.com" target="_blank" className="link">
                                        www.cxdesk.com
                                    </NavLink>
                                    ) portfolio has been reinforced by talking to senior leaders at
                                    Oracle.” Said Venky Vijay Reddi, the CEO of Virtuos. “CXDesk has the
                                    potential to become a million dollar business in the next few years.
                                    Virtuos team has also garnered a lot of insights on Applications,
                                    Exalytics and Cloud.”
                                </p>
                                <figure>
                                    <img
                                        src={`/img/oracle-applications-apac-partners-meet-glimpse.jpg`}
                                        alt="Event glimpses"
                                    />
                                </figure>
                                <h3 className="title">About Oracle India</h3>
                                <p>
                                    Oracle Corporation is an American multinational computer technology
                                    corporation headquartered in Redwood Shores, California. Oracle has
                                    acquired RightNow Technologies in 2011 to bring Service Automation on
                                    the cloud. Oracle India is a wholly owned subsidiary of US
                                    Corporation.{" "}
                                </p>
                                <p>
                                    Oracle has clients in India that include: M, WNS, Standard Chartered
                                    Bank, Siemens, Havells, HelpAge India, M3M India, MacMillan India,
                                    Reliance Capital, Borosil, Aurobindo Pharma, Pride Hotels, Kotak
                                    Securities, Nuthatch Nutricare Technologies, BPTP, Flipkart,
                                    Makemytrip, Cleartrip, BookMyshow, Fundtech, Kyazoonga, among others.
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting and Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has built a strong RightNow Practice for CRM Service Cloud by
                                    creating marquee clients like MakeMyTrip, Fundtech, Flipkart,
                                    BookMyShow amongst others. Virtuos will continue to strengthen its
                                    Oracle CX Practice by extending the portfolio of services and
                                    geographies.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Co_Sponsors_Oracle