import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Virtuos_Celebrates_Its_10th_Anniversary = () => {
  const CustomPrevArrow = ({ onClick }) => (
    <div className="prev-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
  const CustomNextArrow = ({ onClick }) => (
    <div className="next-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  useEffect(() => {
    document.title =
      "Virtuos celebrates its 10th Anniversary and Springs Xonomy – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img src={`/img/virtuos-10-anniversary-img.jpg`} alt="" />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurgaon, 18 Jan 2018</span>
                <h2 className="article-heading">
                  Virtuos celebrates its 10th Anniversary and springs Xonomy
                  <sup>TM</sup> for creating new digital business frontiers.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos completes 10 years of most successful CX and CRM
                  business since we originally founded on 18 January 2008 in the
                  current state of Telangana, India.
                </p>
                <p>
                  The company enters a new milestone by launching Xonomy
                  business on its 10th Anniversary. The theme of X Anniversary
                  celebrations was Strategy is Innovation.
                </p>
                <div className="video-wrapper">
                  <div className="embed-container">
                    <iframe
                      src="https://www.youtube.com/embed/S9e7vJJVvh8?autoplay=0&showinfo=0"
                      frameBorder={0}
                      allowFullScreen=""
                    />
                  </div>
                  <span>Video Source: Youtube</span>
                </div>
                <br />
                <p>
                  The company organized One-Day- trip to employees working in
                  Gurgaon office celebrating its success.{" "}
                </p>
                <div className="photo-slider-wrapper">
                  <Slider {...settings} className="bxslider photo-slider">
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuos-10th-anniversary-img-01.jpg`}
                          alt="Virtuos celebrates 10th anniversary"
                        />
                      </figure>
                    </div>
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuos-10th-anniversary-img-02.jpg`}
                          alt="Virtuos celebrates 10th anniversary"
                        />
                      </figure>
                    </div>
                  </Slider>
                </div>
                <br />
                <p>
                  “During the last 10 years we have signed more than 100
                  customers and delivered high impact customer experience
                  solutions” said Venky Vijay Reddi, CEO, Virtuos. We continue
                  to grow, and create new differentiation strategies for our
                  customers, and our current model—ABCD coupled with Pace
                  Layered Umbrella Strategy (PLUS) delivery model will bring
                  robust growth and excitement to our employees, customers, and
                  partners”.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Corporation is a leading Customer Experience
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos Consultare delivers industry-specific counsel and the
                  integrated, end-to-end solutions that support its customers'
                  strategic, operational, and financial goals. Virtuos is an
                  Experience consultancy that provides superior customer moments
                  and implements leading CRM and Customer Experience
                  Applications of its Alliance Partners. Other services include
                  Our Industry 4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/xonomy/ai`} className="link">
                    CX Desk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Consulting Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program covers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  On the 10<sup>th</sup> Anniversary, Virtuos created a new
                  division — Virtuos Xonomy. Virtuos Xonomy ideates and
                  incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/cxdesk`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Celebrates_Its_10th_Anniversary;
