import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_To_Unveil_Its_First_Product_Strategy_Aury = () => {
  useEffect(() => {
    document.title =
      "Virtuos To Unveil it's Product Strategy: AURY Chatbot – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img src={`/img/home-ai-bg-img.jpg`} alt="" />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurgaon, 01 Feb 2018</span>
                <h2 className="article-heading">
                  Virtuos to unveil its first product strategy — Aury{" "}
                  <sub>®</sub> Conversational, AI-rich, pervasive
                  ExperienceCloud<sup>TM</sup> Platform.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos, a leading CRM and CX Consulting company announces its
                  readiness to launch Aury<sub>®</sub> — AI rich conversational
                  pervasive Experience Cloud Platform using patented NLP.
                </p>
                <p>
                  Built on the foundations of AI and NLP, Aury will launch its
                  first version of intuitive Knowledge Management (KM) for the
                  enterprise market.
                </p>
                <p>
                  “Currently in beta, Aury will become a full experience cloud
                  suite in June 2018 serving major customers,” said Venky Vijay
                  Reddi, CEO, Virtuos. Aury will further complement our service
                  offerings from Salesforce, Oracle, Verint and BPMOnline”.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Corporation is a leading Customer Experience
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos Consultare delivers industry-specific counsel and the
                  integrated, end-to-end solutions that support its customers'
                  strategic, operational, and financial goals. Virtuos is an
                  Experience consultancy that provides superior customer moments
                  and implements leading CRM and Customer Experience
                  Applications of its Alliance Partners. Other services include
                  Our Industry 4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/cxdesk/`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Consulting Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program covers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  On the 10<sup>th</sup> Anniversary, Virtuos created a new
                  division — Virtuos Xonomy. Virtuos Xonomy ideates and
                  incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/xonomy/ai/`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce, and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_To_Unveil_Its_First_Product_Strategy_Aury;
