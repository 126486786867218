import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_To_Implement_Maximizer_Crm = () => {
    useEffect(() => {
        document.title = "Virtuos to implement Maximizer CRM at world's largest independent specialty manufacturer – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-to-implement-maximizer-crm-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">New Delhi - 30 Mar, 2009</span>
                                <h2 className="article-heading">
                                    Virtuos to implement Maximizer CRM at world's largest independent
                                    specialty manufacturer
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos has bagged prestigious orders from multiple customers in India
                                    during the last two quarters. One of them being the world's largest
                                    independent specialty manufacturer with global offices.
                                </p>
                                <p>
                                    The Maximizer CRM Suite Version 11.0 implementation includes all the
                                    three modules — Sales, Service, and Marketing with particular emphasis
                                    on multi-channel customer engagement.
                                </p>
                                <p>
                                    Maximizer has been winning accolades from leading publications for its
                                    enriched product portfolio of CRM products.
                                </p>
                                <h3 className="title">About Maximizer Software</h3>
                                <p>
                                    Maximizer Software is a leading provider of simple, accessible,
                                    customer relationship management (CRM) solutions, providing the best
                                    value for small and medium-sized businesses. As a pioneer in the CRM
                                    industry for more than 20 years, Maximizer offers sales, marketing,
                                    and customer service staff and managers access to customer information
                                    through mobile devices, online, or the desktop. Maximizer's solutions
                                    enable organizations to accelerate business growth by streamlining
                                    sales, marketing and customer service processes, while exceeding
                                    client expectations. Maximizer Software has sold over one million
                                    licenses to more than 120,000 customers, ranging in size from
                                    entrepreneurs to multinational organizations, including: Siemens,
                                    Societe Generale, HSBC, TD Securities, Lockheed Martin, Brian Tracy
                                    International, Fisher &amp; Paykel Healthcare, Oxford University
                                    Press, and Cathay Pacific. Maximizer Software is a global business
                                    with offices and over 400 business partners throughout the Americas,
                                    Europe/Middle East/Africa, and Asia Pacific. For more information,
                                    please visit:{" "}
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based out
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book — Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three important needs
                                    of growing businesses — Sales, Customer Service, and Marketing.
                                    Virtuos has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    )
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">18/01/2008</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            Tekcorp Office Building, <br />F Block Okhla Industrial Area -
                                            Phase 1, <br />
                                            New Delhi 110020 India
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Branch</span>
                                        <span className="txt">Hyderabad</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                                www.virtuos.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">10-20</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_To_Implement_Maximizer_Crm