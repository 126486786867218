import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

const Virtuos_Launches_New_Division_Yippee_Media = () => {
  useEffect(() => {
    document.title =
      "Virtuos launches New Division – Yippee Media foraying into Digital Marketing Services – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-digital-markerting-divison-img-big.jpg`}
                  alt=""
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurgaon - 10 May, 2011</span>
                <h2 className="article-heading">
                  Virtuos launches New Division — Yippee Media foraying into
                  Digital Marketing Services
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos transfers its web development teams to a new division
                  — Yippee Media offering web, brand, social media and digital
                  marketing services to complement Virtuos CRM Practice. Headed
                  by experienced Digital Marketing Specialists, Yippee Media
                  will play an important role within Virtuos.
                </p>
                <p>
                  Yippee Media's premeditated input along with immaculate
                  implementation delivers the best solution for clients. Yippee
                  Media’s world class solutions range from trend analysis to
                  creative genesis.
                </p>
                <figure className="box-shadow">
                  <img
                    src={`/img/yippee-media-logo-news-img.jpg`}
                    alt="Yippee Media Logo"
                  />
                </figure>
                <br />
                <p>
                  Equipped with a deft team of online experts Yippee Media team
                  knows how to employ the internet as a mark medium to represent
                  all the disciplines from engagement to creative apprising,
                  from communication planning to final enactment. Yippee’s
                  expertise spans a multitude of services to deliver an
                  extremely impacting solution. Be it a global communication
                  platform or a new category launch of a business in an
                  established and challenging marketplace, Yippee Media has the
                  solution. Yippee recognizes the stride of change to deliver
                  strategies at key touchpoints at the right time and in the
                  right way. Yippee team is committed to innovating ideas based
                  on customer insights and transforming them into real-world
                  communication plans. Yippee Media deliver the following
                  services:
                </p>
                <div className="row">
                  <div className="col-4 yippee-item">
                    <h3>Marketing</h3>
                    <ul>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/ux_marketing/verbb_design.html"
                          target="_blank"
                          className="link"
                        >
                          Verbb Design
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/ux_marketing/ecommerce_web_design.html"
                          target="_blank"
                          className="link"
                        >
                          Ecommerce Web Design
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/ux_marketing/magento_ecommerce.html"
                          target="_blank"
                          className="link"
                        >
                          Magento Ecommerce Platform
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/ux_marketing/ecommerce_web_design.html"
                          target="_blank"
                          className="link"
                        >
                          Web Optimization
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/ux_marketing/emarketing_services.html"
                          target="_blank"
                          className="link"
                        >
                          E-Marketing Services
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/ux_marketing/digital_adverting_ppc.html"
                          target="_blank"
                          className="link"
                        >
                          Digital Advertising &amp; PPC
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/ux_marketing/content_templates.html"
                          target="_blank"
                          className="link"
                        >
                          Content Templates
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="col-4 yippee-item">
                    <h3>SocialWeb</h3>
                    <ul>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/social_web/social_web_consultancy.html"
                          target="_blank"
                          className="link"
                        >
                          SocialWeb Consultancy
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/social_web/platforming.html"
                          target="_blank"
                          className="link"
                        >
                          Platforming
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/social_web/social_media_monitoring.html"
                          target="_blank"
                          className="link"
                        >
                          Monitoring
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/social_web/social_analytics.html"
                          target="_blank"
                          className="link"
                        >
                          Social Analytics
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/social_web/social_media_marketing.html"
                          target="_blank"
                          className="link"
                        >
                          SocialWeb Marketing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/social_web/audit_compliance.html"
                          target="_blank"
                          className="link"
                        >
                          Audit &amp; Compliance
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="col-4 yippee-item">
                    <h3>Branding</h3>
                    <ul>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/brand_bz/brand_engagement.html"
                          target="_blank"
                          className="link"
                        >
                          Brand Engagement
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/brand_bz/brand_name_logo.html"
                          target="_blank"
                          className="link"
                        >
                          Names &amp; Logos
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/brand_bz/domaineering.html"
                          target="_blank"
                          className="link"
                        >
                          Domaineering
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/brand_bz/ownmark_services.html"
                          target="_blank"
                          className="link"
                        >
                          Ownmark Services
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="http://yippeemedia.com/brand_bz/multi_channel_mix.html"
                          target="_blank"
                          className="link"
                        >
                          Multichannel Mix
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <h3 className="title">About Yippee Media</h3>
                <p>
                  Yippee Media is an innovative full-service digital marketing
                  and branding agency based in San Jose, California that creates
                  and establishes brands, builds compelling web sites, and
                  manages a mix of digital internet marketing campaigns,
                  including social media, that make an impact. Yippee Media
                  combines creativity with strategy to imagine new solutions
                  that deliver real business impact. Yippee’s digital marketing
                  services are distinguished in its ability to make your
                  organization stand out to your target audience to help you not
                  only meet but also exceed business goals. Yippee Media can
                  help you differentiate your brand, attract new customers, and
                  deepen your relationship with existing customers to achieve
                  sustainable success for the long-run. For more information
                  visit{" "}
                  <NavLink
                    to="http://www.yippeemedia.com"
                    target="_blank"
                    className="link"
                  >
                    www.yippeemedia.com
                  </NavLink>
                </p>
                <h3 className="title">About Virtuos Solutions</h3>
                <p>
                  Virtuos Solutions Private Ltd. is a leading Customer
                  Relationship Management Services and Cloud Computing
                  Technologies Company based in NewDelhi/NCR with a registered
                  office in Hyderabad, Andhra Pradesh. The Company has been
                  inspired by the book — Virtuoso Teams and has entered the
                  burgeoning CRM space by addressing three essential needs of
                  growing businesses — Sales, Customer Service, and Marketing.
                  Virtuos has forged partnerships with leading Global Players in
                  the CRM Industry namely Kana Software (
                  <NavLink
                    to="http://www.kana.com"
                    target="_blank"
                    className="link"
                  >
                    www.kana.com
                  </NavLink>
                  ), Maximizer (
                  <NavLink
                    to="http://www.maximizer.com"
                    target="_blank"
                    className="link"
                  >
                    www.maximizer.com
                  </NavLink>
                  ) and RightNow Technologies (
                  <NavLink
                    to="http://www.rightnow.com"
                    target="_blank"
                    className="link"
                  >
                    www.rightnow.com
                  </NavLink>
                  ).
                </p>
                <p>
                  Virtuos has been fiercely growing in the space of CRM, Service
                  Cloud, and Cloud Computing space by delivering the cutting
                  edge solutions to leading brands like MakeMyTrip,
                  GE-Healthcare, Standard Chartered Bank, Party Gaming, Fundtech
                  amongst other companies. For more information visit{" "}
                  <NavLink
                    to="http://www.virtuos.com"
                    target="_blank"
                    className="link"
                  >
                    www.virtuos.com
                  </NavLink>
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Launches_New_Division_Yippee_Media;
