import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Holds_Its_First_Experience = () => {
    useEffect(() => {
        document.title = "Virtuos holds its First Experience+ Summit creating ExperienceJob – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-summit-experiencejob-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    Westin Hotel, Gurgaon - 12 December, 2016
                                </span>
                                <h2 className="article-heading">
                                    Virtuos holds its first Experience+ Executive Summit, creating
                                    “ExperienceJob.”
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos and its employee strength have gathered for its Experience+
                                    Summit at a beautiful five Star Luxury Hotel — Westin Gurgaon.
                                </p>
                                <p>
                                    The purpose of the Experience+ Executive Summit is to launch the
                                    “ExperienceJob” framework for the employees with the following Agenda.
                                </p>
                                <figure className="box-shadow" style={{ padding: 30 }}>
                                    <img
                                        src={`/img/svg/experience-job-breaker-img.svg`}
                                        alt="ExperienceJob Framework"
                                    />
                                </figure>
                                <br />
                                <div className="agenda">
                                    <div className="row">
                                        <div className="col-4">
                                            <strong>10.00 AM to 11.30 AM</strong>
                                        </div>
                                        <div className="col-8">
                                            Experience KeyNote — Transformation of Job to ExperienceJob and
                                            Workshop by Venky Vijay Reddi, CEO
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <strong>11.30 AM to 12.00 PM</strong>
                                        </div>
                                        <div className="col-8">
                                            Introduction of Cloudle.com and Its Launch Plan Activities
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <strong>12.00 PM to 12.15 PM</strong>
                                        </div>
                                        <div className="col-8">Coffee break</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <strong>12.15 PM to 12.45 PM</strong>
                                        </div>
                                        <div className="col-8">
                                            ReOrg — What’s Next <br /> How employees can deliver value to the
                                            organization and customers
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <strong>12.45 Onwards</strong>
                                        </div>
                                        <div className="col-8">Buffet Lunch</div>
                                    </div>
                                </div>
                                <br />
                                <p>
                                    After lunch, the Company had a group photograph of Employees based out
                                    of Gurgaon office. We have missed many of the employees due to their
                                    pre-planned leaves.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-team-header-img.jpg`}
                                        alt="Virtuos Team"
                                    />
                                </figure>
                                <br />
                                <p>
                                    “We have designed ExperienceJob with exhaustive research using
                                    collective experience, Gartner Advisory Services, dozens of resources
                                    and books from MIT, Harvard Business School and most notably “
                                    <NavLink
                                        to="https://hbr.org/2016/09/know-your-customers-jobs-to-be-done"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Jobs to be done
                                    </NavLink>
                                    ” theory by Clayton Christensen.” Said Venky Vijay Reddi. “Human
                                    beings take years or decades (sometimes months) to change themselves,
                                    but when they change, the results are simply astonishing.
                                    ExperienceJob will unravel the hidden treasures of every employee and
                                    how she can tap to make an impact.”
                                </p>
                                <div className="video-wrapper offport-down">
                                    <div className="embed-container">
                                        <iframe
                                            src="https://www.youtube.com/embed/OzezRI9KNKY?autoplay=0&showinfo=0"
                                            frameBorder={0}
                                            allowFullScreen=""
                                        />
                                    </div>
                                    <span>Video Source: Youtube</span>
                                </div>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Holds_Its_First_Experience