import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import ConnectWithUs from './Forms/ConnectWithUs';

const CX_Unity = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "CXUnity.com – Pursuit of building composable enterprise – Virtuos Digital";
    }, []);
    return (
        <div className="cjourney-page landing-page cxunity-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <section className="top-hero-product-banner image-title-layout">
                        <figure className="bg-image-wrapper">
                            <img
                                src={`/img/svg/cxunity-header-bg.svg`}
                                alt="CX Unity Header Image"
                            />
                        </figure>
                        <div className="product-hero-wrapper">
                            <div className="row page-wrap">
                                <div className="col-5 item-wrapper">
                                    <figure>
                                        <img
                                            src={`/img/svg/icons/cxunity-logo.svg`}
                                            alt="CX Unity Logo"
                                        />
                                    </figure>
                                    <h3 >
                                        Pursuit of building composable enterprise.
                                    </h3>
                                </div>
                                <div className="col-8 header-img">
                                    <figure></figure>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="breadcrumb-kanaban">
                        <div className="page-wrap breadcrumb-wrapper">
                            <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                <li>
                                    <NavLink to={`/cxnow/`}>CXNow Program</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxunity/`} className="active">
                                        CX UNITY
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxaudit/`}>CX Audit</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxprism/`}>CX PRISM</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/customer-journey/`}>
                                        Customer Journey
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/customer-voice/`}>
                                        CUSTOMER VOICE
                                    </NavLink>
                                </li>
                             
                            </ul>
                            <ul className="more-hidden-tabs">
                                <li
                                    className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <i className="fal fa-plus" />  More
                                    {showExtraLinks && (
                                        <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                            <li>
                                                <NavLink to={`/cxnow/cxprimer/`}>CX PRIMER</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxopia`}>CXOPIA</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxpyramid/`}>CX PYRAMID</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxdatum/`}>CX DATUM</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxdesk/`}>CXDesk</NavLink>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="cxunity-container">
                        <h2 className="section-main-heading">
                            <span className="highlight">CX Unity</span> delivers composition
                        </h2>
                        <span className="section-desc">
                            Fixed or siloed application experiences no longer meet business and
                            customer requirements.
                        </span>
                        <span className="section-desc">
                            Our Rainbow of CX technologies help organizations transcend omnichannel
                            thinking and embrace multi-experience for improved CX. CX Unity (not to be
                            confused as a technology stack) as a consulting practice helps brands
                            build composable fabric for delivering signature CX by combining the
                            different forces with cohesive strategies.
                        </span>
                        <blockquote>
                            The technological and organizational borders between the application
                            development, automation, integration, and governance disciplines will blur
                            and the relevant strategies will merge - Gartner{" "}
                        </blockquote>
                    </section>
                    <section className="rcit-container light-blue-bg">
                        <div className="row page-wrap">
                            <div className="col-4 item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/relate-icon.svg`}
                                        alt="Relate Icon"
                                    />
                                </figure>
                                <h3 className="apph3">Relate</h3>
                                <p className="app-p">
                                    Identify processes, tools, and technologies that are relatable for
                                    Business-IT composition to deliver CX Continuum.
                                </p>
                            </div>
                            <div className="col-4 item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/connect-icon.svg`}
                                        alt="Connect Icon"
                                    />
                                </figure>
                                <h3 className="apph3">Connect</h3>
                                <p className="app-p">
                                    Connect the tools and technologies by identifying the APIs to create
                                    an experience flow using Customer Data Platform (CDP).
                                </p>
                            </div>
                            <div className="col-4 item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/integrate-icon.svg`}
                                        alt="Integrate Icon"
                                    />
                                </figure>
                                <h3 className="apph3">Integrate</h3>
                                <p className="app-p">
                                    Build seamless integration with the related and connected
                                    applications, tools, processes, and technologies across the
                                    organization.
                                </p>
                            </div>
                            <div className="col-4 item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/transform-icon.svg`}
                                        alt="Transform Icon"
                                    />
                                </figure>
                                <h3 className="apph3">Transform</h3>
                                <p className="app-p">
                                    Keep investing in enterprise-grade, multifunction and multi persona
                                    integration to benefit from this vision for the future of
                                    applications.
                                </p>
                            </div>
                        </div>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <h2 className="apph3">Some of the composable enterprise scenarios:</h2>
                                <figure>
                                    <img
                                        src={`/img/svg/composable-enterprise-scenarios-diagram-img.svg`}
                                        alt="Composable Enterprise Scenarios Diagram"
                                    />
                                </figure>
                                <div className="note">Source: Gartner</div>
                            </div>
                        </div>
                    </section>
                    <section className="rw-container">
                        <h2 className="section-main-heading">
                            <span className="highlight">CX Unity Framework</span> behind Relationware
                        </h2>
                        <span className="section-desc">
                            Relationware is Virtuos Hybrid Integration Platform using a myriad of
                            tools, applications, and technologies such as Mulesoft.
                        </span>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <p>
                                    A "light" form of Relationware providing just five elements of the
                                    framework would be enough to support application experience
                                    composition by IT and non-IT constituencies:
                                </p>
                                <ul>
                                    <li>
                                        <i className="fal fa-long-arrow-right" /> Communication styles and
                                        protocols
                                    </li>
                                    <li>
                                        <i className="fal fa-long-arrow-right" /> Service mediation and
                                        event brokering
                                    </li>
                                    <li>
                                        <i className="fal fa-long-arrow-right" /> Core application and data
                                        integration
                                    </li>
                                    <li>
                                        <i className="fal fa-long-arrow-right" /> Orchestration and
                                        composition
                                    </li>
                                    <li>
                                        <i className="fal fa-long-arrow-right" /> Governance and operations
                                    </li>
                                    <li>
                                        <i className="fal fa-long-arrow-right" /> Low-code development
                                        capabilities, as a sixth element, could be a plus
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <figure>
                                    <img
                                        src={`/img/svg/rw-diagram-img.svg`}
                                        alt="CX Unity Relationware Diagram"
                                    />
                                </figure>
                                <div className="note">Source: Inspired from Gartner Frameworks</div>
                            </div>
                        </div>
                    </section>
                    <section className="video-wrapper">
                        <div className="page-wrap row">
                            <div className="col-7 offport-left">
                                <p>
                                    A composable enterprise is an organization that delivers business
                                    outcomes and adapts to the pace of business change. It does this
                                    through the assembly and combination of packaged business capabilities
                                    (PBCs).
                                </p>
                            </div>
                            <div className="col-5 offport-right">
                                <img
                                    src={`/img/svg/cxunity-breaker-img.svg`}
                                    alt=""
                                />
                            </div>
                        </div>
                    </section>
                    <section className="unity-culture-container">
                        <h2 className="section-main-heading">
                            CX Unity <span className="highlight">comes from Culture</span>
                        </h2>
                        <span className="section-desc">
                            Our CulturO™ PRISM model offers case studies on various culture hacks.
                        </span>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <p>
                                    The CX Unity workshop covers discussions about customers often center
                                    around facts and data. We also engage with the organization to see how
                                    they are:
                                </p>
                                <ul>
                                    <li>
                                        <i className="fal fa-long-arrow-right" /> Operationalizing customer
                                        empathy
                                    </li>
                                    <li>
                                        <i className="fal fa-long-arrow-right" /> Hiring and orienting
                                        employees for customer centricity
                                    </li>
                                    <li>
                                        <i className="fal fa-long-arrow-right" /> Harnessing customer data
                                        for insights, and finally
                                    </li>
                                    <li>
                                        <i className="fal fa-long-arrow-right" /> Linking the employee
                                        culture for customer outcomes
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row page-wrap bitems-container">
                            <div className="col-12 items">
                                <div className="item">
                                    <span className="icon">
                                        {" "}
                                        <img
                                            src={`/img/svg/icons/values-heart-icon.svg`}
                                            alt="Values Icon"
                                        />
                                    </span>
                                    <h3>Values</h3>
                                    <ul>
                                        <li>Principles</li>
                                        <li>Priorities</li>
                                        <li>Drivers</li>
                                        <li>Motivators</li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <span className="icon">
                                        {" "}
                                        <img
                                            src={`/img/svg/icons/hand-icon.svg`}
                                            alt="Practices Icon"
                                        />
                                    </span>
                                    <h3>Practices</h3>
                                    <ul>
                                        <li>Activities</li>
                                        <li>Rewards</li>
                                        <li>Routines</li>
                                        <li>Terminology</li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <span className="icon">
                                        {" "}
                                        <img
                                            src={`/img/svg/icons/mind-icon.svg`}
                                            alt="Mindset Icon"
                                        />
                                    </span>
                                    <h3>Mindsets</h3>
                                    <ul>
                                        <li>Thinking</li>
                                        <li>Attitude</li>
                                        <li>Perspectives</li>
                                        <li>Viewpoints</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row page-wrap">
                            <div className="col-12 behavior-txt">
                                <h3>Behaviors</h3>
                            </div>
                        </div>
                        <div className="row page-wrap prism-block">
                            <div className="col-12">
                                <h2 className="apph3">
                                    CulturO PRISM offers a five point framework as part of{" "}
                                    <NavLink to={`/cxnow/`} className="link" style={{fontWeight:"bold"}}>
                                        CXNow
                                    </NavLink>{" "}
                                    Transformation program.
                                </h2>
                            </div>
                            <div className="col-8">
                                <figure>
                                    <img
                                        src={`/img/svg/culturo-prism-diagram-img.svg`}
                                        alt="CulturO Prism Diagram"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="cdp-container grey-bg">
                        <h2 className="section-main-heading">
                            CX Unity implements <span className="highlight">CDP and MDM</span>
                        </h2>
                        <span className="section-desc">
                            CX Unity complements a rainbow of CX Technologies and comprehensive
                            Customer Data Platform (CDP) and Master Data Management (MDM).
                        </span>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <p className="app-p">
                                    Organizations need Customer 360 insights for two mission-critical
                                    priorities: Optimization of the CX and digital transformation. Over
                                    half (56%) of the respondents to the 2018 Gartner CRM Applications
                                    Survey said that MDM of customer data will be very important for their
                                    CRM/CX projects by 2020. The same survey showed that 50% are using
                                    digital analytics or marketing analytics in their CRM/CX projects.
                                </p>
                                <p className="app-p">
                                    Almost three-quarters (72%) of the respondents to another 2019 Gartner
                                    survey said they had fully deployed or were in the process of
                                    deploying a CDP.{" "}
                                </p>
                                <figure>
                                    <img
                                        src={`/img/svg/c360-diagram-img.svg`}
                                        alt="Customer 360 report diagram"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="cf-form-wrapper">
                        <div className="page-wrap ">
                            <h2 className="section-main-heading">
                                You cannot deliver
                                <span className="highlight"> Customer 360 experience</span> without
                                first enabling your Organization 360 for CX
                            </h2>
                            <span className="section-desc">
                                Connect with Virtuos today to deliver CX Unity Transformation services
                                for creating a composable enterprise that can fully exploit and harness
                                the collective forces of CX enablers.
                            </span>
                          <ConnectWithUs/>
                        </div>
                    </section>
                    <Footer />
                    {/*?php include '../../footer-consultare.php';?*/}
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default CX_Unity
