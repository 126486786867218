import React from "react";

import ComingSoon from "../ComingSoon/ComingSoon";

const Crm_studio = () => {
  return (
    <div className="crm-studio">
      <ComingSoon />
    </div>
  );
};

export default Crm_studio;
