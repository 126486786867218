import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Team_Attends_Rightnow_Austraila = () => {
    useEffect(() => {
        document.title = "Virtuos Team attends the RightNow APAC Summit in Australia – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-team-attends-rightnow-apac-summit-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    Gold Coast, Sydney - 7 November, 2011
                                </span>
                                <h2 className="article-heading">
                                    Virtuos Team attends the RightNow APAC Summit in Australia
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Leadership team members headed by Venky Vijay Reddi, the CEO
                                    attended the RightNow APAC summit held at Gold Coast, Sydney.
                                </p>
                                <p>
                                    Venky met the senior leaders at Oracle and discussed the transition
                                    from RightNow to Oracle. During the Partner session, Virtuos also
                                    presented “CRMDomain”, a Demand Gen interactive website. CRMDomain.com
                                    was launched during the summit to showcase RightNow and Maximizer
                                    technologies by offering attractive packages for the small and medium
                                    sized companies.
                                </p>
                                <p>
                                    “We are also excited to have Senior Technical Manager from MakeMyTrip
                                    attending the Summit to better understand the roadmap of RightNow
                                    Technologies CX Suite.” said Venky Vijay Reddi, CEO, Virtuos. The APAC
                                    User Summit has presented hot new technologies such as Virtual Contact
                                    Center, Intent based AI Engine, and more advanced Agent Scripting for
                                    empowering brands to deliver superior customer experience (CX).
                                </p>
                                <p>
                                    RightNow CX Suite (Formerly RightNow CRM) has highlighted the essence
                                    of “Customer Experience” by integrating Sales, Service, Marketing, and
                                    Analytics across channels reducing the silos; by optimizing the
                                    channel mix to reduce the cost of customer service while improving the
                                    efficiency of employees.
                                </p>
                                <p>
                                    After the Summit, Venky CEO and the other members of Virtuos have
                                    joined the party and networked with APAC Partners of RightNow.
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/virtuos-team-attends-rightnow-apac-summit-pic.jpg`}
                                        alt=""
                                    />
                                </figure>
                                <h3 className="title">About RightNow Technologies</h3>
                                <p>
                                    RightNow Technologies, Inc. (Nasdaq: RNOW) provides customer
                                    relationship management software and services. The Company offers
                                    solutions that use self-learning knowledge-base to deliver customer
                                    self-service, email management, live interaction, issue tracking, and
                                    customer satisfaction measurement capabilities.
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">07/12/2000</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            136 Enterprise Blvd Bozeman, <br />
                                            MT 59718 United States
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Phone</span>
                                        <span className="txt">1-406-522-4200</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink
                                                to="http://www.rightnow.com"
                                                target="_blank"
                                                className="link"
                                            >
                                                www.rightnow.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">1000-2000</span>
                                    </div>
                                </div>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, and
                                    Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has built a strong RightNow Practice for CRM Service Cloud by
                                    creating marquee clients like MakeMyTrip, Fundtech amongst others.
                                    Virtuos will continue to strengthen its business with RightNow and
                                    Oracle by becoming the latter’s partner. For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Team_Attends_Rightnow_Austraila