import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Fabindia_Case_Study = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };

    useEffect(() => {
        document.title = "Virtuos Customer Success Stories | Fabindia CLM case study – Virtuos Digital";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/fabindia-header-img.jpg`}
                                    alt="Fabindia"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/svg/icons/fabindia-logo.svg`}
                                        alt="Fabindia Logo"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        Fabindia automates Contracts and Vendors Supply Management with
                                        Agiloft CLM
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <section className="breadcrumb-kanaban">
                            <div className="page-wrap breadcrumb-wrapper">
                                <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                    <li>
                                        <NavLink to={`/corporate/`}>About Virtuos</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/customers/`} className="active">
                                            Customer Success
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={`/customers#success-stories`}
                                            className="smooth"
                                        >
                                            CUSTOMER SUCCESS STORIES
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/succeedant/`} className="smooth">
                                            SUCCEEDANT CSM
                                        </NavLink>
                                    </li>

                                </ul>
                                <ul className="more-hidden-tabs">
                                    <li
                                        className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <i className="fal fa-plus" />  More
                                        {showExtraLinks && (
                                            <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                                <li>
                                                    <NavLink to={`/corporate/experience-edge/`}>
                                                        EXPERIENCE EDGE
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`/insights/`}>
                                                        INSIGHTS AND RESOURCES
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                <h3 className="title">About Fabindia</h3>
                                <p>
                                    Fabindia is India's largest private platform for products that are
                                    made from traditional techniques, skills and hand-based processes.
                                </p>
                                <p>
                                    Fabindia links over 55,000 craft based rural producers to modern urban
                                    markets, thereby creating a base for skilled, sustainable rural
                                    employment, and preserving India's traditional handicrafts in the
                                    process.
                                </p>
                                <p>
                                    Fabindia's products are natural, craft based, contemporary, and
                                    affordable.
                                </p>
                                <h3 className="title">Challenges</h3>
                                <ul>
                                    <li>Lack of visibility of Contract details with the management</li>
                                    <li>Unable to track the expiry of contract in advance</li>
                                    <li>
                                        Legal team unable to get information on the contracts that are
                                        pending for approvals/clearances
                                    </li>
                                    <li>Maintenance of contracts in physical format</li>
                                    <li>Unable to track changes in a contract in form of amendments</li>
                                </ul>
                                <h3 className="title">Need</h3>
                                <ul>
                                    <li>
                                        Legal team wanted to measure the performance of their team and
                                        understand the number of contracts that are coming in, the team
                                        working on them individually and the status of each contract
                                    </li>
                                    <li>
                                        Fabindia wanted a single platform on which all the interactions
                                        related to the contract is captured across the teams
                                    </li>
                                    <li>
                                        Fabindia wants to store documents in multi formats with easy lookups
                                        across the contract lifecycle
                                    </li>
                                    <li>
                                        Fabindia is gradually progressing towards complete automation, and
                                        CLM became one of the first steps for their procurement and
                                        supplychain
                                    </li>
                                    <li>
                                        Fabindia wanted to schedule reports of various teams performance
                                        with regards to their approvals, active contracts, and various other
                                        parameters
                                    </li>
                                </ul>
                                <h3 className="title">Win</h3>
                                <ul>
                                    <li>
                                        Fabindia chose Agiloft Contract Management solution after a lot of
                                        deliberations with the team and also evaluating Agiloft’s
                                        competitors
                                    </li>
                                    <li>
                                        Agiloft was chosen due to its simple interface and wide range of
                                        features for managing diverse contracts
                                    </li>
                                    <li>
                                        Agiloft, a top ranked Gartner Magic Quadrant Leader with an
                                        attractive point
                                    </li>
                                </ul>
                                <h3 className="title">Partner</h3>
                                <ul>
                                    <li>
                                        Virtuos is a Gold Partner for Agiloft, has rich experience in
                                        implementing complex solutions and our team has delivered and
                                        surpassed customer’s expectations
                                    </li>
                                    <li>
                                        Virtuos came up with Industry best practices for Retail Industry and
                                        E-Commerce Industry having implemented dozens of implementations
                                    </li>
                                    <li>
                                        Virtuos offered Vlocis Rapid Implementation Methods for going live
                                        in weeks instead of months
                                    </li>
                                    <li>
                                        Virtuos Consultare team is trained and certified by Agiloft as a
                                        competent Consulting Services Company
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="customer-success-section">
                        <h2 className="section-main-heading">
                            Other <span className="highlight">Case Studies</span>
                        </h2>
                        <div className="clients-wrapper">
                            <ul className="row page-wrap offport-down">
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/standard-chartered-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/standard-chartered-casestudy-img.jpg`}
                                                alt="Standard Chartered Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/standard_chartered.svg`}
                                                alt="Standard Chartered Bank Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">CX and Contact Center Automation</p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/tvs-credit-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/tvsc-casestudy-img.jpg`}
                                                alt="TVS Credit Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/tvscs-logo.png`}
                                                alt="TVS Credit"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer Experience Transformation Project using Oracle platform
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/makemytrip-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/makemytrip-casestudy-img.jpg`}
                                                alt="Make My Trip Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/make-my-trip-logo.svg`}
                                                alt="Make My Trip Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            MakeMyTrip.com halved avg. call handling time and increased
                                            revenue with CX Cloud
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/electronics-company-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/electronics-company-casestudy-img.jpg`}
                                                alt="Electronics Company Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/confidential-logo.svg`}
                                                alt="Confidential Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer engagement on phone, email, chat, and social channels
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Fabindia_Case_Study