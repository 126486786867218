import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Signs_With_Myntra = () => {
    useEffect(() => {
        document.title = "Virtuos signs with Myntra.com for Oracle RightNow CX Service – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-signs-with-myntra.com-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Bangalore - 28 February, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos signs with Myntra.com for Oracle RightNow CX Service
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Myntra.com joins the league with MakeMyTrip, Flipkart.com, Fundtech,
                                    Cleartrip, BookMyShow, and several other Customers to implement a
                                    powerful cloud-based Oracle RightNow Customer Experience Suite. Myntra
                                    chose Virtuos because of the latter's strong commitment, and
                                    industry-specific experience spanning several projects.
                                </p>
                                <p>
                                    Over the next couple of months, Myntra.com will implement and deliver
                                    superior customer experience using Multi-channel customer Service,
                                    including social for its customers.
                                </p>
                                <p>
                                    "Myntra is a leader in the Lifestyle Apparel Category, and Virtuos is
                                    very proud to have Myntra.com as one of its prestigious customers",
                                    says Kristina Hermanns, Director of Solution Consulting. "We are quite
                                    excited to work with the talented team of Myntra."
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/myntra-virtuos-logo-news.jpg`}
                                        alt="Myntra, Virtuos"
                                    />
                                </figure>
                                <br />
                                <p>
                                    “Myntra is a fascinating company with exemplary service standards.
                                    Said Venky Vijay Reddi. “I have personally worked on this deal with
                                    the CTO - Sachin Arora and his senior team members including the CFO
                                    to clinch the deal.”
                                </p>
                                <p>
                                    In the last three years, Virtuos has seen that both MakeMyTrip and
                                    Flipkart cross a few thousands of licenses contributing to more than
                                    US$2 Million revenue year on year. Myntra projects a growth trajectory
                                    similar to Flipkart as the sheer volume of Myntra's fashion business
                                    grows at phenomenal speed.
                                </p>
                                <h2 className="title">About Myntra</h2>
                                <p>
                                    Myntra is an Indian fashion e-commerce company headquartered in
                                    Bengaluru, Karnataka, India. The company was founded in 2007 to sell
                                    personalized gift items. Myntra is a one-stop-shop for all your
                                    fashion and lifestyle needs. As India's largest e-commerce store for
                                    fashion and lifestyle products, Myntra aims to provide a hassle-free
                                    and enjoyable shopping experience to shoppers across the country with
                                    the broadest range of brands and products on its portal.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.myntra.com" target="_blank" className="link">
                                        www.myntra.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Consultare</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting and Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has implemented CRM and Customer Experience Projects to some
                                    of the largest global brands. Our customers include: MakeMyTrip,
                                    Flipkart, Cleartrip, BookMyShow, FundTech, Canon Singapore, National
                                    University of Singapore (NUS), Singapore Institute of Management
                                    (SIM), NZPost, Telstra, Myntra, Standard Chartered Bank,
                                    GE-Healthcare, Sutherland Global, Party Gaming amongst several others.
                                </p>
                                <p>
                                    Virtuos has forayed into high performance Consulting Services
                                    integrating CX and Digital through Consultare division. For more
                                    information visit us at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Signs_With_Myntra