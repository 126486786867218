import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Oracle_Welcomes_Virtuos = () => {
    useEffect(() => {
        document.title = "Oracle welcomes Virtuos into the Oracle Partner Network (OPN) – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/oracle-partner-network-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 29 June, 2012</span>
                                <h2 className="article-heading">
                                    Oracle welcomes Virtuos into the Oracle Partner Network (OPN) and
                                    Virtuos develops CX Strategy with Oracle Ecosystems
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos becomes Gold Certified Oracle Partner joining Oracle Partner
                                    Network (OPN) with specialization in RightNow CX Cloud Service
                                    Solutions. Virtuos can resell, implement and develop new solutions on
                                    Oracle’s RightNow CX Platform across the APAC region. With several
                                    marquee clients under its belt, Virtuos continues to grow and impact
                                    the Oracle Ecosystem.
                                </p>
                                <p>
                                    “We are delighted that Virtuos Solutions is part of the Oracle partner
                                    community. As a member of the OPN, Virtuos Solutions will enjoy a host
                                    of valuable benefits designed for delivering innovative, quality
                                    solutions with Oracle.” Said Venky Vijay Reddi. “RightNow CX Suite
                                    with Oracle’s Ecosystem, we at Virtuos takes a Three-Pronged Approach
                                    To Enhance Customer Experience (CX).”
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/oracle-public-cloud-diagram.jpg`}
                                        alt="Oracle Public Cloud"
                                    />
                                </figure>
                                <br />
                                <p>
                                    Revolutionize customer experience with this remarkable Value Equation
                                    and give your business a complete makeover. Virtuos, along with Oracle
                                    RightNow has redefined the Customer Experience (CX) equation by fusing
                                    Acquisition, Retention, and Efficiency (ARE). This three-pronged
                                    approach is a journey through which businesses can increase their
                                    sales by tapping on potential opportunities (Acquisition) and ensuring
                                    new customers become regular customers through relationship building
                                    (Retention). This in turn streamlines the business process and
                                    improves the overall efficiency, reducing the Operational Expenditure
                                    (OPEX) and increasing Customer Satisfaction (CSAT).
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/rightnow-era-diagram.jpg`}
                                        alt="RightNow"
                                    />
                                </figure>
                                <p>
                                    Businesses today are under constant pressure from their customers, who
                                    expect an exceptional, personalized, and a Multi-Channel Customer
                                    Experience every time they interact with you. According to the CX
                                    Impact Report 2009-2011, compiled by Harris Interactive, outstanding
                                    service is the number one reason for recommending an organization to
                                    family and friends. A shocking yet genuine discovery made by Harris
                                    Interactive is that just one bad experience is enough for a consumer
                                    to happily switch to your competitor.
                                </p>
                                <p>
                                    These facts are daunting, given the alarming rate at which businesses
                                    are matching up to the industry leaders in terms of the quality of
                                    services they offer. To rise above the competition, it is important
                                    for businesses to be proactive in their approach and to focus on
                                    consumer interactions throughout the CX lifecycle, across all touch
                                    points.
                                </p>
                                <p>
                                    Virtuos offers a wide range of products and professional services that
                                    empower businesses with the right tools and technologies that
                                    streamline inter-departmental communication and enable them to address
                                    customers' needs efficiently. RightNow Web, Social, Contact Centre
                                    Experience, and RightNow Engage are some of the technologies that
                                    businesses can leverage on to take CX to a whole new level.
                                </p>
                                <p>
                                    Virtuos has successfully solved business challenges that revolve
                                    around customer experience using these innovative products and
                                    technologies. Some of Virtuos' clients who have benefitted from these
                                    products include BookMyShow.com, Canon, Flipkart.com, MakeMyTrip.com
                                    and KyaZoonga.com
                                </p>
                                <p>
                                    Download the{" "}
                                    <NavLink
                                        to={`/downloads/virtuos-ARE-consulting.pdf`}
                                        target="_blank"
                                        className="link"
                                    >
                                        ARE CONSULTING BROCHURE
                                    </NavLink>{" "}
                                    or more details on the company's three-pronged approach that has
                                    revolutionized CX and changed the way businesses operate.
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, and
                                    Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has built a strong RightNow Practice for CRM Service Cloud by
                                    creating marquee clients like MakeMyTrip, Fundtech amongst others.
                                    Virtuos will continue to strengthen its Oracle CX Practice by
                                    extending the portfolio of services and geographies.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Oracle_Welcomes_Virtuos