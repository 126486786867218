import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import { scroller } from "react-scroll";

const Customerthree = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };

  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };

  useEffect(() => {
    document.title = "Customer 360 Program by C.Digital Masterminds – Virtuos Digital";
    if (window.location.hash.includes('keyissue')) {
      scrollToSection("keyissue");
    }

  }, []);

  const scrollToSection = (sectionId) => {
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 1000,
        smooth: true,
        offset: -50, // Adjust this offset if needed
      });
    }, 100); // Delay in milliseconds
  };
  return (
    <div className="landing-page customer360-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-4 item-wrapper">
                  <h3 className="section-heading">
                    Do You Have a Customer 360 Program in Your Company?
                  </h3>
                </div>
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/svg/customer360-header-img.svg`}
                      alt="Customer 360"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/c-digital/`}>C.DIGITAL</NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/c-digital/customer360/`}
                    className="active"
                  >
                    CUSTOMER 360
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/ci/`}>
                    CONTINUOUS INTELLIGENCE
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/ceh/`}>
                    CUSTOMER ENGAGEMENT HUB
                  </NavLink>
                </li>

              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" />  More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/c-digital/xlv/`}>EXPERIENCE ID</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/c-digital/customer-wide/`}>
                          CUSTOMER WIDE
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/c-digital/digital-dragons/`}>
                          DIGITAL DRAGONS
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="retailer-challenge">
            <h2 className="section-main-heading">
              A <span className="highlight">Fashion Retailer’s</span> Customer 360
              Challenge
            </h2>
            <div className="row page-wrap items-container">
              <div className="col-12 diagram">
                <figure>
                  <img
                    src={`/img/svg/fashion-retailer-diagram.svg`}
                    alt=""
                  />
                </figure>
                <div className="top-content">
                  <h3 className="pink">More Channels</h3>
                  <p>
                    More Global and Regional Channels and Customer’s Channel Preferences
                  </p>
                  <p className="imp">
                    Omnichannel Complexity <i className="fa fa-long-arrow-up" />
                  </p>
                </div>
                <div className="left-content1">
                  <h3 className="green">Expanding Geographies</h3>
                  <p>Expansion in Asia and New Markets</p>
                  <p className="imp">
                    Regional <i className="fa fa-long-arrow-right" /> Global
                  </p>
                </div>
                <div className="left-content2">
                  <h3 className="red">Segments and Loyalty</h3>
                  <p>Moving from Tactical Campaigns to Long-Lived Loyalty Management</p>
                  <p className="imp">
                    Tactical Campaigns <i className="fa fa-long-arrow-right" />{" "}
                    Lifecycle Loyalty
                  </p>
                </div>
                <div className="right-content1">
                  <h3 className="blue">Customer Transitions</h3>
                  <p>
                    Increased Need to Manage Customer Transitions Between Channels,
                    Experiences, Loyalty, Transactions, and Geographies
                  </p>
                </div>
                <div className="right-content2">
                  <h3 className="orange">Store of the Future</h3>
                  <p>
                    Online and Offline Data Capture and Sharing with Partners to Elevate
                    the Shopping Experience
                  </p>
                  <p className="imp">The Future of Omnichannel</p>
                </div>
              </div>
              <div className="col-12">
                <div className="note">
                  Requires a Closed-Loop Platform that Monitors the Customer Lifecycle
                  and Reacts in Real-Time
                </div>
              </div>
            </div>
          </section>
          <section className="airline-challenge light-blue-bg">
            <h2 className="section-main-heading">
              An <span className="highlight">Airline’s</span> Customer 360 Challenge
            </h2>
            <span className="section-desc">
              Interact with guests from the passenger’s point of view
            </span>
            <div className="row page-wrap">
              <div className="col-12 items-container">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/shopping-cart-airline-icon.svg`}
                      alt="Shopping Cart Icon"
                    />
                  </figure>
                  <h3>Closing, Shopping and Pricing</h3>
                  <ul>
                    <li>Right Product, Right Channel</li>
                    <li>Right Time, Right Guest</li>
                  </ul>
                </div>
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/clock-airline-icon.svg`}
                      alt="Clock Icon"
                    />
                  </figure>
                  <h3>Booking, Waiting and Preparing</h3>
                  <ul>
                    <li>Faster Check-In</li>
                  </ul>
                </div>
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/briefcase-airline-icon.svg`}
                      alt="Briefcase Icon"
                    />
                  </figure>
                  <h3>Check-In and Airport Experience</h3>
                  <ul>
                    <li>Last Call</li>
                    <li>Airport Experience With Indoor Location</li>
                  </ul>
                </div>
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/flight-airline-icon.svg`}
                      alt="Flight Icon"
                    />
                  </figure>
                  <h3>In-Flight</h3>
                  <ul>
                    <li>In-Flight Experience</li>
                  </ul>
                </div>
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/taxi-airline-icon.svg`}
                      alt="Taxi Icon"
                    />
                  </figure>
                  <h3>Post Flight</h3>
                  <ul>
                    <li>Technical Service Alert</li>
                    <li>Connecting Flight Information</li>
                    <li>Customer Care, Acquisition, and Retention</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="university-challenge">
            <h2 className="section-main-heading">
              A <span className="highlight">University’s</span> Customer 360 Challenge
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/university-challenge-diagram.svg`}
                    alt="University Challenge Diagram"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="breaker-quote">
            <figure className="bg-banner">
              <img
                src={`/img/strategic-planning-breaker-bg.jpg`}
                alt="Strategic Planning"
              />
            </figure>
            <div className="row page-wrap" id="keyissue">
              <div className="col-8">
                <h2 className="bold-class">Strategic Planning Assumption</h2>
                <p>
                  By 2022, 50% of large organizations will have still failed to unify
                  engagement channels resulting in the continuation of a disjointed and
                  siloed customer experience, lacking context.
                </p>
              </div>
            </div>
          </section>
          <section className="key-issues">
            <h2 className="section-main-heading">
              Customer 360 <span className="highlight">Key Issues</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="items-container">
                  <NavLink to="#keyissue" className="item smooth active" onClick={() => scrollToSection("keyissue")}>
                    You may need a customer engagement hub.
                    <span className="number">1</span>
                  </NavLink>
                  <NavLink
                    to={`/c-digital/customer360/ci/`}
                    className="item"

                  >
                    You may need real-time continuous intelligence in your customer
                    engagement hub.
                    <span className="number">2</span>
                  </NavLink>
                  <NavLink
                    to={`/c-digital/customer360/ceh/`}
                    className="item"
                  >
                    Where to start to build your real-time customer engagement hub?
                    <span className="number">3</span>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/customer-journey-mapping-diagram.svg`}
                    alt="Customer Journey Mapping Diagram"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="engagement-hub">
            <h2 className="section-main-heading">
              You Need a <span className="highlight">Customer Engagement Hub</span>
            </h2>
            <div className="row page-wrap items-container">
              <div className="col-4 item green-bg">
                <figure>
                  <img
                    src={`/img/svg/icons/across-systems-icon.svg`}
                    alt="Across Systems Icon"
                  />
                </figure>
                <h3 className="bold-class">Across Systems</h3>
                <p>
                  is an architectural framework that ties multiple systems together to
                  optimally engage the customer.
                </p>
              </div>
              <div className="col-4 item yellow-bg">
                <figure>
                  <img
                    src={`/img/svg/icons/across-channels-icon.svg`}
                    alt="Across Channels Icon"
                  />
                </figure>
                <h3 className="bold-class">Across Channels</h3>
                <p>
                  allows personalized, contextual customer engagement, whether through a
                  human, artificial agent or sensors, across all interaction channels.
                </p>
              </div>
              <div className="col-4 item blue-bg">
                <figure>
                  <img
                    src={`/img/svg/icons/across-department-icon.svg`}
                    alt="Across Departments"
                  />
                </figure>
                <h3 className="bold-class">Across Departments</h3>
                <p>
                  reaches and connects all departments, allowing, for example, the
                  synchronization of marketing, sales, and customer service processes.
                </p>
              </div>
            </div>
          </section>
          <section className="breaker-container">
            <figure className="bg-banner">
              <img
                src={`/img/customer-engagement-breaker-bg.jpg`}
                alt="Customer Engagement"
              />
            </figure>
            <div className="row page-wrap">
              <div className="col-7">
                <p>
                  A "Customer Engagement Hub" Enables Organizations to Align Their
                  Processes With Customer Intent to Orchestrate a Cross-Channel,
                  Cross-Departmental Customer Experience.
                </p>
              </div>
            </div>
          </section>
          <section className="engagement-hub">
            <h2 className="section-main-heading">
              A <span className="highlight">Customer Engagement Hub</span> Is <br /> an
              Architectural Framework
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure className="diagram">
                  <img
                    src={`/img/svg/customer-engagement-architecture-framework-diagram.svg`}
                    alt="Customer Engagement Architecture Framework Diagram"
                  />
                </figure>
              </div>
            </div>
            <NavLink
              to={`/c-digital/customer360/ci/`}

              className="btn btn-secondary" target='_blank'
            >
              CONTINUOUS INTELLIGENCE <i className="fa fa-long-arrow-right" />
            </NavLink>
          </section>
          {/*?php include '../../footer-consultare.php';?*/}
          <Footer />
        </main>

      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  )
}

export default Customerthree
