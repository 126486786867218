import React, { useEffect, useState } from "react";
import WPAPI from "wpapi";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Nav from "../../components/Nav";
import { NavLink } from "react-router-dom";

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    document.title = "Virtuos Blogs";
  }, []);

  //   useEffect(() => {
  //     const wp = new WPAPI({
  //       endpoint: "https://blog.virtuos.com/wp-json/",
  //     });
  //     wp.posts().get((err, data) => {
  //       if (err) {
  //         // handle error
  //       }
  //       console.log(data);
  //       setBlogs(data);
  //     });
  //   }, []);
  useEffect(() => {
    const wp = new WPAPI({
      endpoint: "https://blog.virtuos.com/wp-json",
    });
    wp.posts()
      .perPage(100) // Adjust the number of posts to retrieve
      .embed() // Include embedded data (e.g., featured images)
      .then((data) => {
        console.log(data);
        setBlogs(data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);
  function formatDate(dateString) {
    return new Date(dateString).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }

  // const data2 = blogs.map((blog)=>{
  //   return
  // })
  //   console.log(blogs[0]?._embedded['wp:featuredmedia'][0].source_url)
  console.log(blogs);

  const extractedData = blogs.map((post) => [
    post.slug,
   
    post.yoast_head_json.title,
    post.yoast_head_json.description,
  ]);

  console.log(extractedData);
  return (
    <div className="bpm-page landing-page">
      <div className="canvas">
        <Navbar />

        <section className="news-events-wrapper">
          <div className="news-events-tabs">
            <br />
          </div>
          <h2 className="section-heading">
            <br />
          </h2>
          {!blogs.length ? (
            <div style={{ height: "30rem" }}>
              <div className="loader-blog"></div>
            </div>
          ) : (
            <div className="page-wrap row">
              <div className="col-8 news-articles-wrapper">
                <div className="row featured-article">
                  {/* <h2 className="section-heading desktop-hide">
                  {" "}
                  Featured
                  <br />
                </h2> */}
                  {blogs.slice(0, 1).map((blog) => (
                    <NavLink
                      to={blog.slug}
                      className="col-12 news-article"
                      key={blog.id}
                    >
                      <figure>
                        <img
                          src={blog._embedded["wp:featuredmedia"][0].source_url}
                          alt="Virtuos to unveil NOVV — NewWe Digital Transformation Practice"
                        />
                        <div className="hover-wrapper">
                          <span>
                            Read Full <i className="fal fa-long-arrow-right" />
                          </span>
                        </div>
                      </figure>
                      <div className="title-wrapper">
                        <span className="date-posted">
                          {formatDate(blog.date.substring(0, 10))}
                        </span>

                        <h3
                          className="article-heading"
                          dangerouslySetInnerHTML={{
                            __html: blog.title.rendered,
                          }}
                        />

                        <p
                          dangerouslySetInnerHTML={{
                            __html: blog.excerpt.rendered,
                          }}
                        />
                        {/* {blog.excerpt.rendered}
                      </p> */}
                      </div>
                    </NavLink>
                  ))}
                </div>
                {/* <div className="small-articles-wrapper" id="business">
                  <div className="section-title">
                    <h2 className="section-heading">More Blogs</h2>
                  </div>

                  <div className="row">
                    {blogs.slice(3).map((blog) => (
                      <div className="col-4 filterDiv 21-23" key={blog.id}>
                        <NavLink to={blog.slug} className="news-article">
                          <figure>
                            <img
                              src={
                                blog._embedded["wp:featuredmedia"][0].source_url
                              }
                              alt="Virtuos unveils Nexorama strategy to diversify into new areas"
                            />
                            <div className="hover-wrapper">
                              <span>
                                Read Full{" "}
                                <i className="fal fa-long-arrow-right" />
                              </span>
                            </div>
                          </figure>
                          <div className="title-wrapper">
                            <span className="date-posted">
                              {formatDate(blog.date.substring(0, 10))}
                            </span>
                            <h3
                              className="title"
                              style={{ fontWeight: "bold" }}
                            >
                              {blog.title.rendered}
                            </h3>
                          </div>
                        </NavLink>
                      </div>
                    ))}
                  </div>
                </div> */}
              </div>
              <div className="col-4 news-articles-wrapper">
                <div className="">
                  {blogs.slice(1, 3).map((blog) => (
                    <div className="row" key={blog.id}>
                      <div className=" filterDiv 21-23">
                        <NavLink to={blog.slug} className="news-article">
                          <figure>
                            <img
                              src={
                                blog._embedded["wp:featuredmedia"][0].source_url
                              }
                              alt="Virtuos unveils Nexorama strategy to diversify into new areas"
                            />
                            <div className="hover-wrapper">
                              <span>
                                Read Full{" "}
                                <i className="fal fa-long-arrow-right" />
                              </span>
                            </div>
                          </figure>
                          <div className="title-wrapper">
                            <span className="date-posted">
                              {formatDate(blog.date.substring(0, 10))}
                            </span>
                            <h3
                              className="title"
                              style={{ fontWeight: "bold" }}
                            >
                              {blog.title.rendered}
                            </h3>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="page-wrap news-articles-wrapper">
            {blogs.length && (
              <div className="small-articles-wrapper" id="business">
                <div className="section-title">
                  <h2 className="section-heading font-weight600">More Blogs</h2>
                </div>

                <div className="row">
                  {blogs.slice(3).map((blog) => (
                    <div className="col-4 filterDiv 21-23" key={blog.id}>
                      <NavLink to={blog.slug} className="news-article">
                        <figure>
                          <img
                            src={
                              blog._embedded["wp:featuredmedia"][0].source_url
                            }
                            alt="Virtuos unveils Nexorama strategy to diversify into new areas"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Read Full{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            {formatDate(blog.date.substring(0, 10))}
                          </span>
                          <h3 className="title" style={{ fontWeight: "bold" }}>
                            {blog.title.rendered}
                          </h3>
                        </div>
                      </NavLink>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* <div className="loader-blog"></div> */}
        </section>
        <Footer />
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
}
