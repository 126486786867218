// Page.js
import React, { useEffect } from 'react';

const Page = ({ title, description, keywords, children }) => {
  useEffect(() => {
    document.title = title;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    }
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.setAttribute("content", keywords);
    }
  }, [title, description, keywords]);

  return <>{children}</>;
};

export default Page;
