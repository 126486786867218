import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Cxprism = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Design Thinking for Customer Experience | CX Prism – Virtuos Digital";
    }, []);
    return (
        <div className="c-digital-page landing-page cxprism-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <section className="top-hero-product-banner image-title-layout">
                        <div className="product-hero-wrapper">
                            <div className="row page-wrap">
                                <div className="col-5 item-wrapper">
                                    <h3 >
                                        Design Thinking <br /> For Customer Experience.
                                    </h3>
                                </div>
                                <div className="col-7">
                                    <figure>
                                        <img
                                            src={`/img/svg/cxprism-ux-process-img.svg`}
                                            alt="Design Thinking For Customer Experience"
                                        />
                                    </figure>
                                    <figure className="right-fixed-logo">
                                        <img
                                            src={`/img/svg/icons/cx-prism-logo.svg`}
                                            alt="CX Prism Logo"
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="breadcrumb-kanaban">
                        <div className="page-wrap breadcrumb-wrapper">
                            <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                <li>
                                    <NavLink to={`/cxnow/`}>CXNow Program</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxprism/`} className="active">
                                        CX PRISM
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxaudit/`}>CX Audit</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/customer-journey/`}>
                                        Customer Journey
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/customer-voice/`}>
                                        CUSTOMER VOICE
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxunity/`}>CX UNITY</NavLink>
                                </li>

                            </ul>
                            <ul className="more-hidden-tabs">
                                <li
                                    className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <i className="fal fa-plus" />  More
                                    {showExtraLinks && (
                                        <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                            <li>
                                                <NavLink to={`/cxnow/cxprimer/`}>CX PRIMER</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxopia/`}>CXOPIA</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxpyramid/`}>CX PYRAMID</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxdatum/`}>CX DATUM</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxdesk/`}>CXDesk</NavLink>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="advantages-of-aury ecosystem-section">
                        <h2 className="section-main-heading">
                            CREATE BUSINESS MOMENTS WITH <span className="highlight">CX Prism</span>
                        </h2>
                        <span className="section-desc">
                            <p>
                                CX Prism Design Thinking is customer-centric digital innovation
                                methodology that constantly challenges the status quo in pursuit of
                                thinking creatively, promoting early idea generation and prototyping —
                                that’s mostly collaborative, playful, and iterative.
                            </p>
                            <p>
                                CX Prism Design Thinking is built on three core foundations when it
                                comes to delivering breakthrough customer experiences.
                            </p>
                        </span>
                        <h3 className="section-main-heading offport-down">
                            <figure className="icon">
                                <img
                                    src={`/img/svg/icons/empathy-eq-icon.svg`}
                                    alt="Empathy (EQ)"
                                />
                            </figure>
                            Empathy (EQ)
                        </h3>
                        <span className="section-desc offport-down">
                            <p>
                                Most organizations do not truly understand their customers or prospects.
                                And many organizations do not invest in CX innovations to digitally
                                design "what their customers want". In fact,{" "}
                                <NavLink
                                    to="https://www.forbes.com/sites/chunkamui/2011/10/17/five-dangerous-lessons-to-learn-from-steve-jobs/#1319adcb3a95"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link"
                                >
                                    people don't know
                                </NavLink>{" "}
                                what they want until you show it to them. Even you need Design Thinking
                                to build commitment to a new{" "}
                                <NavLink
                                    to="https://hbr.org/2017/01/use-design-thinking-to-build-commitment-to-a-new-idea"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link"
                                >
                                    idea
                                </NavLink>
                                .
                            </p>
                        </span>
                        <div className="page-wrap row">
                            <div className="col-6 offport-left">
                                <figure>
                                    <img
                                        src={`/img/bald-person-with-comb.png`}
                                        alt="CX Innovations"
                                    />
                                </figure>
                            </div>
                            <div className="col-6 points offport-right">
                                <ul>
                                    <li className="row">
                                        <div className="content-wrapper">
                                            <p>
                                                Organizations have lots of data but are usually naïve about how
                                                customers
                                                <strong>feel</strong>, what they <strong>value</strong> and what
                                                they
                                                <strong>want</strong> at any point in time.
                                            </p>
                                            <p>
                                                <strong>Descriptive:</strong> Demographic, life stage, location
                                            </p>
                                            <p>
                                                <strong>Relationship:</strong> Interactions, transactions,
                                                operational
                                            </p>
                                            <p>
                                                <strong>Social:</strong> Family, friends, corporate
                                            </p>
                                            <p>
                                                <strong>Satisfaction:</strong> Underlying, immediate
                                            </p>
                                            <p>
                                                <strong>Attitudes:</strong> Values, decision process
                                            </p>
                                            <p>
                                                <strong>Needs and wants:</strong> Strategic, temporary,
                                                real-time
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="section-breaker">
                        <figure>
                            <img
                                src={`/img/design-thinking-video-banner.jpg`}
                                alt="Design Thinking"
                                className="my-paroller"
                                data-paroller-factor="-0.5"
                                data-paroller-type="foreground"
                                data-paroller-direction="vertical"
                            />
                        </figure>
                        <div className="content-wrapper">
                            <h2 className="offport-down">What’s Design Thinking</h2>
                            <span className="item youtube-link" youtubeid="Z6Uro_cAC3E">
                                <i className="fas fa-play-circle offport-zoomout" />
                            </span>
                        </div>
                    </section>
                    <section className="two-col-section">
                        <h3 className="section-main-heading">
                            <figure className="icon">
                                <img
                                    src={`/img/svg/icons/ideation-icon.svg`}
                                    alt="Ideation"
                                />
                            </figure>
                            Ideation
                        </h3>
                        <div className="row page-wrap">
                            <div className="col-6 item offport-left">
                                <h2>Design Thinking for Business Moments</h2>
                                <p>
                                    Design Thinking enables the discovery of disruptive Pathways during
                                    business moments. Understanding various{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        className="link"
                                    >
                                        customer journeys
                                    </NavLink>
                                    , and digital business moments is very critical and important in the
                                    digital age. Identifying these transient business moments and linking
                                    to{" "}
                                    <NavLink
                                        to={`/cx/strategy-design/`}
                                        className="link"
                                    >
                                        CX Strategy and Design
                                    </NavLink>{" "}
                                    is complex, and it requires C-Level engagement.
                                </p>
                            </div>
                            <div className="col-6 item offport-right">
                                <h2>Design Thinking — A Catalyst</h2>
                                <p>
                                    Organizations are heavily involved in execution spending less than 10%
                                    on business modeling. There’s insufficient focus on design, planning,
                                    and collaboration.
                                </p>
                                <p>
                                    Illustrated image shows how CX Prism Design Thinking becomes a
                                    catalyst for the discovery of shining business moments.
                                </p>
                            </div>
                        </div>
                        <figure className="page-wrap full-image-wrapper offport-down">
                            <img
                                src={`/img/svg/cxprism-moments.svg`}
                                alt="Potential experiences for customers"
                            />
                        </figure>
                        <div className="note row page-wrap">
                            <p>
                                A Perspective on potential experiences for customers before HP Instant
                                Ink.{" "}
                            </p>
                            <NavLink
                                to={`/io/design-thinking/`}
                                className="btn btn-secondary"
                            >
                                EXPLORE MORE <i className="fa fa-long-arrow-right" />
                            </NavLink>
                        </div>
                        <div className="page-wrap ux-processes-wrapper">
                            <span className="section-desc">
                                <p>
                                    CX Prism Design Thinking facilitates engagement from stakeholders on
                                    combinatorial strategies — through ideation.
                                </p>
                                <p>
                                    Stakeholder engagement starts with empathy and goals refinement.
                                    Alignment is a primary benefit of prototyping and testing.
                                </p>
                                <figure className="page-wrap full-image-wrapper offport-down">
                                    <img
                                        src={`/img/svg/ux-processes.svg`}
                                        alt="UX Processes"
                                    />
                                </figure>
                            </span>
                        </div>
                    </section>
                    <section className="video-wrapper">
                        <div className="page-wrap row">
                            <div className="col-6 offport-left">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/cx-prism-logo.svg`}
                                        alt="CX Prism Logo"
                                    />
                                </figure>
                                <h3 className='bold-class'>
                                    "This is Experience Economy. At Virtuos, we design your customer
                                    experiences by applying principles of CX Prism."
                                </h3>
                            </div>
                            <div className="col-5 offport-right">
                                <iframe src="https://www.youtube.com/embed/i9dR1_t8Xzw?autoplay=1" />
                            </div>
                        </div>
                    </section>
                    <section className="content-table-wrapper">
                        <div className="page-wrap">
                            <h3 className="section-main-heading">
                                <figure className="icon">
                                    <img
                                        src={`/img/svg/icons/winful-distruption-icon.svg`}
                                        alt="Wilful Disruption"
                                    />
                                </figure>
                                Wilful Disruption
                            </h3>
                            <div className="section-desc">
                                <p>
                                    Disruptive strategies stem from innovation which often centers on
                                    Customer Experience (CX). Positioning provides a proven method for
                                    framing intent, including disruption and CX innovation.
                                </p>
                            </div>
                            <div className="row right-content-wrapper">
                                <div className="col-9 item">
                                    <ul>
                                        <li>
                                            <strong>Customer Experience:</strong> Perceptions and related
                                            feelings across touchpoints and interactions
                                        </li>
                                        <li>
                                            <strong>Disruptor:</strong> Effects disruption
                                        </li>
                                        <li>
                                            <strong>Disruption:</strong> Changes expectations and behaviors in
                                            a culture, market, industry or process
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="page-wrap row table-wrapper">
                            <div className="col-5 table-wrap offport-left">
                                <ul>
                                    <li>
                                        <span className="title">For</span>
                                        <p>Target Customers</p>
                                    </li>
                                    <li>
                                        <span className="title">Who</span>
                                        <p>Statement of Need or Opportunity</p>
                                    </li>
                                    <li>
                                        <span className="title">And want</span>
                                        <p>Statement of Experience Expectations</p>
                                    </li>
                                    <li>
                                        <span className="title">the</span>
                                        <p>Product/Service Name</p>
                                    </li>
                                    <li>
                                        <span className="title">is</span>
                                        <p>Product/Service Category</p>
                                    </li>
                                    <li>
                                        <span className="title">That</span>
                                        <p>Statement of key benefits compelling reasons to buy</p>
                                    </li>
                                    <li>
                                        <span className="title">unlike</span>
                                        <p>Primary Competitive Alternative</p>
                                    </li>
                                    <li>
                                        <span className="title">our product</span>
                                        <p>Statement of Primary Differentiation</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 content-wrap offport-right">
                                <h4 className="section-heading">
                                    Use CX elements to frame disruptive intent and test the impact on
                                    competition or incumbents
                                </h4>
                            </div>
                        </div>
                    </section>
                    <section className="next-steps-wrapper">
                        <div className="page-wrap">
                            <h2 className="section-heading">
                                <span className="highlight">RECOMMENDATIONS</span>
                            </h2>
                            <div className="row offport-down">
                                <div className="col-3 item">
                                    <h4>
                                        Design for Inspiration and <br /> Disruptive outcomes
                                    </h4>
                                    <p>
                                        Create Customer Empathy Maps to uncover opportunities for disruption
                                        or innovation.
                                    </p>
                                </div>
                                <div className="col-3 item">
                                    <h4>
                                        Make CX Foundation for <br /> Go-to-Market
                                    </h4>
                                    <p>
                                        Identify key business moments for innovation and disruption with
                                        differentiation strategy.
                                    </p>
                                </div>
                                <div className="col-3 item">
                                    <h4>
                                        Apply CX Prism <br /> Model
                                    </h4>
                                    <p>
                                        Work with Virtuos consultants to identify strategic moves and
                                        combine it with{" "}
                                        <NavLink to={`/indicial/`} className="link">
                                            Indicial
                                        </NavLink>{" "}
                                        Maturity.
                                    </p>
                                </div>
                                <div className="col-3 item">
                                    <h4>
                                        Let’s pilot first <br /> design
                                    </h4>
                                    <p>
                                        Let’s connect over{" "}
                                        <NavLink to={`/cxnow/cxprimer/`} className="link">
                                            CX Primer
                                        </NavLink>
                                        and work with your cross functional teams on the first pilot.{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="digital-human-wrapper">
                        <div className="page-wrap row">
                            <div className="col-8 offport-left">
                                Identify 2-3 business moments to illuminate disruptive pathways
                            </div>
                            <div className="col-4 offport-right">
                                <NavLink className="btn btn-primary" to={`/contact`}>
                                    Contact Us
                                </NavLink>
                            </div>
                        </div>
                    </section>
                    {/*?php include '../../footer-consultare.php';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Cxprism
