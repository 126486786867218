import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './css/vavesstyles.css';
import virtuosVavesLogo from "./images/virtuos-vaves-logo.png";
import bgImage from "./images/bg.svg";

const Vavess = () => {
  useEffect(() => {
    document.title = 'High-Performance Industry Tailored CRM and CX Consulting – Virtuos Vaves';
  }, []);

  return (
    <div className="main-area-wrapper" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="main-area center-text">
        <div className="headers" style={{position: "absolute"}}>
          <figure className="logo">
            <img src={virtuosVavesLogo} alt="Vaves.com" />
          </figure>
          <h2>
            VIRTUOS ENTERS ITS NEW PHASE WITH ITS YEAR OLD ORACLE PRACTICE BY LAUNCHING VAVES, A NEW INDUSTRY
            TAILORED HIGH-PERFORMANCE CONSULTING.
          </h2>
          <a href="https://www.virtuos.com/oracle" target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
        </div>
        <div className="footer">
          <div className="copy">
            &copy; Virtuos Vaves &mdash; The Brand new Oracle Practice with 9+ years of Oracle Partnership.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vavess;
