import React, { useEffect, useState } from 'react'

import { NavLink } from "react-router-dom";
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Nav from '../../../components/Nav';
import ApplyForJob from './Forms/ApplyForJob';
import CommonApplyForJob from './CommonApplyForJob';
import { NavigateNext } from '@mui/icons-material';

const Magento = () => {
    useEffect(() => {
        document.title = "Career Opportunities | Microsoft .Net Developer – Virtuos Jobs";
    }, []);
    return (
        <div className="careers-job-detail-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    {/* Careers Job Detail */}
                    <section className="job-detail-wrapper">
                        <div className="row page-wrap">
                        <div class="col-9 job-details">
						<div class="job-header">
							<div class="profile-illustration">
								<figure>
									<img src={`/img/svg/magento-career-img.svg`} alt="Magento Developer Job"/>
								</figure>
							</div>
							<div class="profile-detail">
								<h2 class="section-heading">Magento Developer</h2>
								<ul class="job-attributes">
									<li class="">
										<strong>Location:</strong>
										<span>Gurgaon</span>
									</li>
									<li class="">
										<strong>Experience:</strong>
										<span>3-5 Years</span>
									</li>
									<li class="">
										<strong>No. of Positions:</strong>
										<span>2</span>
									</li>
									<li class="">
										<strong>Qualification:</strong>
										<span>BE/BTech/ME/MTech/MS/MCA</span>
									</li>
								</ul>
							</div>
						</div>
						
						<h3 class="title">Job Description</h3>
						<p>We are looking for an experienced full stack Magento Developer with strong front end experience to lead and/or assist in the architecture, design, implementation, and maintenance of Magento eCommerce sites. The requirements include 3+ years of experience developing Magento eCommerce sites and custom Magento extensions and themes. Candidate must be able to work in a team setting as well as an individual setting with strong client-facing communication skills and Magento architecture skills.</p>

						<h3 class="title">Responsibilities</h3>
						<ul class="list">
							<li>Build e-commerce applications, maintaining existing PHP code, optimizing MySQL queries, developing custom PHP solutions, and actively assist in improving the usability, usefulness, and functionality of highly customized consumer and business-to-business Magento systems</li>
							<li>Provide full service web solutions from frontend development to backend programming, and system design and architecture</li>
							<li>Understand and support e-commerce data organization and relationships, including store catalogs (categories, products, attributes), and custom product configurators</li>
							<li>Integrate and support Magento platforms with external systems, such as CMS, CRM, ERP, payment, shipping, etc.</li>
							<li>Implement best practices for responsive design and mobile friendly programming</li>
							<li>Assess and manage operation’s functionality within Magento (taxes, shipping configuration, freight, software syncing, etc.)</li>
							<li>Work with common third-party API's (Google, etc.)</li>
							<li>Analyze, design, code, debug, test, document, deploy, and provide ongoing support for all web applications</li>
							<li>Experience with Agile development methodologies</li>
							<li>Delivering project assignments on time, within budget, and with high quality</li>
						</ul>
						
						<h3 class="title">Desired Skills, Experience &amp; Abilities</h3>
						<ul class="list">
							<li>Minimum of 3-5 years PHP &amp; Zend web development experience</li>
							<li>Minimum of 3 years real-world, e-commerce experience with Magento development</li>
							<li>Firm grasp of Git-based source control</li>
							<li>Competent with PHP object-oriented programming &amp; MVC applications</li>
							<li>Competent with MySQL-based database concepts</li>
							<li>Familiarity with automated deployment strategies</li>
							<li>Expert understanding of Magento's code structure, extension architecture, theming hierarchy, and fallback components</li>
							<li>Expertise in authoring and extending Magento extensions</li>
							<li>Thorough experience in customizing Magento's front-end components using layout XML, blocks, and templates</li>
							<li>Thorough knowledge of vanilla Magento feature set and configuration</li>
							<li>Competent knowledge of e-commerce data organization and relationships</li>
							<li>Store catalog (categories/products/attributes)</li>
							<li>Sales orders (quotes/orders/invoices/shipments/credits)</li>
							<li>Competent in authoring JavaScript and using libraries such as jQuery</li>
							<li>Competent in web services integrations (REST/SOAP)</li>
							<li>Magento Certification is a huge plus</li>
							<li>In-depth knowledge of PCI/PA-DSS compliance issues</li>
							<li>Experience in ERP integrations with Magento is a huge plus</li>
							<li>Experience with caching and scaling techniques</li>
							<li>Experience in multi-store, multi-lingual, or multi-currency e-commerce implementations</li>
							<li>Experience with CSS preprocessors like SASS/LESS</li>
							<li>Experience with Zurb Foundation</li>
							<li>Contributions to the open-source community</li>
						</ul>
						
						<h3 class="title">Advantages of Working@Virtuos</h3>
						<ul class="list">
							<li>Virtuos has deep expertise in CRM and Customer Experience Consulting - It’s likely to become largest Applications Business valued at US$45B per year. Candidates will gain significant exposure from our best practices, top-notch alliances, and direct hands-on interaction with leading brands</li>
							<li>The candidates will have exposure to the cutting edge solutions in broad based verticals</li>
							<li>The role also accords a deep understanding of Customer's business processes, and technology adaptation providing an ample room for candidate's overall growth</li>
							<li>Global exposure and working on best business practices with MNC and Enterprise/Corporate Customers in diverse verticals</li>
						</ul>
						
					</div>
                            <div className="col-3 job-application-wrapper sticky-content">
                             <CommonApplyForJob/>
                            </div>
                        </div>
                    </section>
                   
                    {/*?php include '../../footer.php';?*/}
                    <Footer/>
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Magento
