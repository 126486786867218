import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../components/Navbar';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

const Privacy_Policy = () => {
  useEffect(() => {
    document.title = "Website Privacy Policy Statement – Virtuos Digital";

  }, []);
  return (
    <div className='client-detail-page landing-page'>
      <div className='canvas'>
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-6">
                  <h3 className="section-heading">
                    Privacy <span className="highlight">Policy</span>
                  </h3>
                </div>
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/svg/privacy-policy-banner-img.svg`}
                      alt="Virtuos Privacy Policy"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="legal-section">
            <div className="page-wrap legal-wrapper">
              <div className="legal-doc-wrapper">
                <span className="date">Last Updated: 1 November 2022</span>
                <p>
                  The Virtuos Privacy Policy describes the privacy practices of Virtuos’
                  apps and websites.
                </p>
                <p>
                  This privacy policy describes how Virtuos (also referred to as "we,"
                  "us" or "our") will make use of your information in the context of:
                </p>
                <ul className="level-one">
                  <li>Virtuos websites and other web-based services/applications</li>
                  <li>Virtuos’ marketing, sales, and advertising practices</li>
                </ul>
                <p>
                  If the content or information that you store on Virtuos apps or
                  websites contains personal information of other individuals, you must
                  be legally permitted to share the personal information with Virtuos.
                  Virtuos shall not be responsible for the accuracy or authenticity or
                  portability of your information stored with us.
                </p>
                <h2 className="section-heading">1. Collection of Information</h2>
                <ul className="level-one">
                  <li>
                    Your basic Contact Information is collected when you submit forms
                    (Contact Us, Corporate Event Registration.) on Virtuos website.
                  </li>
                  <li>
                    Your basic Contact Information and Resume is collected when you
                    submit a job application form on Virtuos website.
                  </li>
                  <li>
                    Virtuos uses common Internet technologies, such as cookies and Web
                    beacons, to keep track of interactions with the Company's Web sites
                    and emails.
                  </li>
                </ul>
                <h3 className="normal-heading">
                  Information collected from Virtuos Website
                </h3>
                <ul className="level-one">
                  <li>
                    When you fill up a form, the following data might be requested to be
                    shared with us-
                    <ul className="level-two">
                      <li>Full name</li>
                      <li>Email ID</li>
                      <li>Mobile #</li>
                      <li>Company/Organization</li>
                      <li>Designation</li>
                      <li>Your message content for communication</li>
                      <li>IP Address</li>
                      <li>Type of browser and device</li>
                      <li>
                        Search terms entered into a search engine which lead you to a
                        Virtuos website
                      </li>
                      <li>
                        Use and navigation of websites and apps (collected through
                        cookies and similar technologies, or by Virtuos servers when you
                        are accessing the app or website)
                      </li>
                    </ul>
                  </li>
                </ul>
                <h2 className="section-heading">2. Use of Information</h2>
                <ul className="level-one">
                  <li>
                    We use your personal information to enable you to register with
                    Virtuos and to provide you with our websites and apps, and other
                    products or services that you request.
                  </li>
                  <li>
                    We may collect and store details of how you use our services,
                    including search queries. This information may be used to improve
                    the relevancy of results provided by our services. Except in limited
                    instances to ensure quality of our services over the Internet, such
                    information will not be associated with your IP address.
                  </li>
                  <li>
                    We may also use personal information for internal purposes such as
                    auditing, data analysis, and research to improve Virtuos’s products,
                    services, and customer communications.
                  </li>
                  <li>
                    We may use personal information to send you marketing mailers,
                    unless you have unsubscribed from the same.
                  </li>
                  <li>
                    We may use personal information to respond to your information
                    requests or inquiries.
                  </li>
                  <li>
                    We may use personal information to interact with you on third party
                    social platforms.
                  </li>
                </ul>
                <p>
                  We may transfer your personal information across national borders to
                  other countries in compliance with applicable laws.
                </p>
                <h3 className="normal-heading">Virtuos Emails</h3>
                <p>
                  Emails we send you may include a technology (called a web beacon) that
                  tells Virtuos whether you have received or opened the email, or
                  clicked a link in the email. If you do not want us to collect this
                  information, you can opt out of receiving Virtuos marketing emails.
                  Every marketing email from Virtuos contains instructions on how to opt
                  out of receiving further marketing emails from the Company.
                </p>
                <h2 className="section-heading">3. Sensitive Information</h2>
                <p>
                  We ask that you not send us, and you not share any sensitive Personal
                  Information (e.g., government-issued or financial account numbers,
                  information related to racial or ethnic origin, political opinions,
                  religion or other beliefs, health, genetic, or biometric data,
                  criminal background or trade union membership).
                </p>
                <p>
                  We understand that the security of your personal information is
                  important. We provide reasonable administrative, technical, and
                  physical security controls to protect your personal information.
                  However, despite our efforts, no security controls are 100% effective
                  and Virtuos cannot ensure or warrant the security of your personal
                  information.
                </p>
                <h3 className="normal-heading">Will this privacy policy change?</h3>
                <p>
                  Occasionally, we may change this privacy policy (or other documents)
                  to allow Virtuos to use or share your personal information in a
                  different way. If we do, the links to the policy on our websites
                  (which are generally found in the footer of the website) will indicate
                  that the policy has been changed. For new users, the change will
                  become effective upon posting. For existing users, if the change is
                  significant, it will become effective 30 days after posting. We
                  encourage you to periodically review the Virtuos Privacy Policy for
                  the latest information on our privacy practices.
                </p>
                <h3 className="normal-heading">Published Content</h3>
                <p>
                  Virtuos at its discretion publishes photographs, videos, content,
                  quotes, references, images and, or audio files on its website as part
                  of website content. Such content is either taken from public sources
                  or with the permission of employees, partners, and other associates as
                  required. If any employee or ex-employee or any partner, customer or
                  ex-customer or associate is not interested to have their content or
                  photographs/videos/audio published on our website, they may write to{" "}
                  <NavLink to="mailto:info@virtuos.com" className="link">
                    info@virtuos.com
                  </NavLink>
                  . Virtuos explicitly confirms that it will remove any and all content
                  that potentially may violate your privacy upon receiving notification
                  within 30 business days.
                </p>
              </div>
            </div>
          </section>
          <Footer />
        </main>

      </div>
      <div className='off-canvas'>
        <Nav />
      </div>
    </div>
  )
}

export default Privacy_Policy
