import React from "react";

import ComingSoon from "../ComingSoon/ComingSoon";

const Big_think = () => {
  return (
    <div className="big-think">
      <ComingSoon />
    </div>
  );
};

export default Big_think;
