import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Launches_Consultare_Division = () => {
    useEffect(() => {
        document.title = "Virtuos launches Consultare Division, the high performance Consulting Services Business Unit – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-launches-consultare-division-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 22 December, 2012</span>
                                <h2 className="article-heading">
                                    Virtuos launches Consultare Division, the high performance Consulting
                                    Services Business Unit
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos has launched Consultare division, the high performance
                                    consulting services arm within Virtuos. Virtuos has been delivering
                                    CRM Technology Consulting Services and Solutions to leading brands for
                                    the last four years. From being a CRM Implementation and Professional
                                    Services Company, Virtuos has transformed itself into full-fledged
                                    Consulting Services company by creating a fusion of CX and Digital
                                    mediums.
                                </p>
                                <p>
                                    Consultare domain along with the host of high end consulting advisory
                                    services will become part of Virtuos as a new division. Virtuos
                                    Solutions will be known as Virtuos Consultare henceforth offering a
                                    full spectrum of consulting services in CRM, Customer Experience and
                                    the Cloud.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-consultare-logo-news.jpg`}
                                        alt="Virtuos - Consultare"
                                    />
                                </figure>
                                <br />
                                <p>
                                    Consultare (means "to consult" in Latin) as the name suggests offers
                                    comprehensive Technology &amp; Business Consulting Services.
                                    Professional Services, IT Managed Services, Re-Engineering, Business
                                    Consulting around Customer Experience (CX), Branding &amp; Social
                                    Media and Business Process Management are the focus areas for Virtuos
                                    Consultare. All the Consulting and Professional Services Teams will
                                    now become a part of Virtuos Consultare. Virtuos Consultare will be
                                    creating a new Center Of Excellence during 2013.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.consultare.com" target="_blank" className="link">
                                        www.consultare.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting and Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book –
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses – Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has forayed into high performance Consulting Services
                                    integrating CX and Digital through Consultare division. For more
                                    information visit us at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Launches_Consultare_Division
