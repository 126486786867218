import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_Vivacis_Global_Projects = () => {
  useEffect(() => {
    document.title =
      "Virtuos Vivacis Wins New Projects From Global Customers – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-vivacis-global-projects-img-big.jpg`}
                  alt="VIRTUOS VIVACIS WINS NEW PROJECTS FROM GLOBAL CUSTOMERS"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram, 18 October 2022</span>
                <h2 className="article-heading">
                  VIRTUOS VIVACIS WINS NEW PROJECTS FROM GLOBAL CUSTOMERS
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos Digital, a leading Customer Experience &amp; Digital
                  Transformation Consultancy bags several global projects from
                  leading brands. Virtuos Vivacis, a division of Virtuos
                  Consultare Group, is closely working on Low-Code No-Code
                  Platforms in alliance with leading providers in this space.
                </p>
                <p>
                  CLP, a leading Power Transmission Company based out of Hong
                  Kong has implemented its Business Automation involving
                  Customer Communications and helpdesk using Vivacis
                  Professional Services. Jade Global, a leading Technology
                  Services Company based out of the U.S.A has automated its
                  Contract Lifecycle Management (CLM) by using Vivacis
                  Professional Services along with Agiloft Licensing for CLM.
                  KGISL, one of the top-notch Hightech Companies in IT Services
                  operating in the US and India has automated its Contract
                  Lifecycle Management (CLM) using Vivacis Professional Services
                  and Agiloft Licensing.
                </p>
                <p>
                  Virtuos Vivacis has also bagged two other critical projects
                  from Europe for implementing end-to-end Business Automation
                  leveraging leading edge platforms from our partners. One of
                  the projects involves complete digital transformation for
                  their talent management and recruitment (including
                  Professional Employment Organization Services), and another
                  project includes Travel and Transportation Automation and
                  Incident Management. Virtuos Vivacis is also working on the
                  critical project for one of the leading Companies based out of
                  Australia for implementing CRM Sales across the organization.
                </p>
                <p>
                  Virtuos Vivacis continues to perform at its PEAK with several
                  resources fully certified and geared up for handling global
                  projects that involve digital transformation spanning business
                  automation, CRM, digitalization accelerating service spectrum,
                  process improvement using new set of services, contract
                  lifecycle automation amongst others.
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Microsoft Dynamics, Creatio Low Code No Code
                  Platform &amp; CRM Applications; Agiloft Contract Lifecycle
                  Management (CLM) amongst other Composable Products &amp;
                  Services. Virtuos Consulting services include Four Fabulous
                  business units namely — Virtuos Vivacis, Virtuos Veracis,
                  Virtuos Vaves, and Virtuos Vedam.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/xonomy/`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates, incubates and invests in the next-generation
                  businesses harnessing Artificial Intelligence (AI), Cloud,
                  ECommerce, Blockchain and Digital technologies. At Virtuos
                  Xonomy, we have invested in several companies that include
                  Giftcart — Gifting Experiences Commerce Company; Indic — the
                  Portfolio of Experience Commerce Brands; Sweven — Salesforce
                  CRM Mastermind services; and Tekcorp — the Technology
                  Infrastructure.
                </p>
                <h3 className="title">Virtuos Experience Cloud</h3>
                <p>
                  Virtuos Experience Cloud Business is building new products
                  integrating our Customer Experience (CX), Employee Experience
                  (EX), and Everything Experience (XX).
                </p>
                <h3 className="title">Virtuos Nexorama</h3>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We are building an
                  integrated and agile learning platform — Wundero; Carving out
                  a new business model building a marketplace for LowCode NoCode
                  Technologies by composing a new business: UnCodify.com.
                  Virtuos will be launching Expedience — The Talent advisory and
                  sourcing platform and Audacis, a new digital marketing
                  services agency.
                </p>
                <p>
                  For more information visit{" "}
                  <NavLink
                    to="http://www.virtuos.com"
                    target="_blank"
                    className="link"
                  >
                    www.virtuos.com
                  </NavLink>
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Vivacis_Global_Projects;
