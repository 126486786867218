import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Drawer,
  Button,
  // List,
  // ListItem,
  // ListItemButton,
  // ListItemIcon,
  // ListItemText,
} from "@mui/material";
// import MailIcon from '@mui/icons-material/Mail';
// import emailjs from "@emailjs/browser";
import CloseIcon from "@mui/icons-material/Close";
import ReCaptcha from "react-google-recaptcha";
const ContactUs = () => {
  const [selectedValue, setSelectedValue] = useState(
    "81ec3fac-3042-4cec-9326-3067b085501e"
  );

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    console.log("country selected", event.target.value);
  };

  const [phoneError, setPhoneError] = useState(false);
  const [userData, setuserData] = useState({
    webURL: `/agnitor`,
    formType: "E_Agnitor",
    name: "",
    email: "",
    phone: "",
    city: "",
    country: "",
    org: "",
  });
  const navigate = useNavigate();
  //we are storign data in states
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(true);
  const handleRecaptchaChange = (value) => {
    console.log("Recaptcha");
    setRecaptchaValue(value);
    setCaptchaError(false);
  };
  const handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setuserData({ ...userData, [name]: value });
    console.log("inside handlechange");
    // console.log(userData.name)
  };

  // send data to bacckend

  const handleSubmit = async (e) => {
    console.log("insidde contcat form");
    e.preventDefault();
    const {
      webURL,
      formType,
      name,
      email,
      phone,
      message,
      org,
      city,
      country,
    } = userData;
    userData.country = selectedValue;
    console.log("country", userData.country);

    if (captchaError) {
      return;
    }
    const res = await fetch(`${process.env.REACT_APP_API_URL}/db/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
      body: JSON.stringify({
        webURL: webURL,
        type: formType,
        full_name: name,
        email: email,
        mobile: phone,
        company: org,
        country: selectedValue,
        city: city,
      }),
    });
    const data = await res.json();

    if (!data) {
      console.log("message not send");
    } else {
      // alert( "message send" );
      setuserData({ ...userData, message: "" });
      navigate("/thankyou"); // Navigate to "/thankyou" route
    }
    /*emailjs
     .send(
       process.env.REACT_APP_SERVICE_ID,
       process.env.REACT_APP_TEMPLATE_ID,
       {
         from_name: userData.name,
         to_name: process.env.REACT_APP_TO_NAME,
         from_email: userData.email,
         to_email: process.env.REACT_APP_TO_EMAIL,
         message: userData.message,
       },
       process.env.REACT_APP_PUBLIC_KEY
     )
     .then(
       () => {

         // alert("Thank you. I will get back to you as soon as possible.");

         setuserData({
           name: "",
           email: "",
           phone: "",
           message: ""
         });

       },
       (error) => {

         console.error(error);

         // alert("Ahh, something went wrong. Please try again.");
       }
     );*/
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [message, setMessage] = useState("");
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleChangeMessage = (event) => {
    const value = event.target.value;
    setMessage(value);
  };
  const [isHovered, setIsHovered] = useState(false);
  const buttonStyle = {
    color: isHovered ? "red" : "black",
    fontWeight: "bold",
    transition: "color 0.3s",
  };
  // const remainingChars = 200 - userData.message.length;

  return (
    //     <div>
    //         <NavLink
    //             rel="enquiry-form"
    //             className="btn btn-secondary popup-form-trigger offport-zoomin"
    //         >
    //             Connect with Us
    //         </NavLink>
    //     </div>
    // )
    <div>
      <NavLink
        onClick={toggleDrawer}
        style={{
          border: "1px solid #00a1fe",
          color: "#00a1fe",
          fontWeight: 400,
          padding: "8px 20px",
        }}
      >
        Connect with Us
      </NavLink>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={toggleDrawer}>
        <div
          className="form-wrapper page-wrap"
          style={{ height: "500px" }}
          id="form-mobile-view"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 className="section-heading" style={{ marginTop: "1.5rem" }}>
              Connect with Us
            </h3>
            <Button
              onClick={toggleDrawer}
              style={buttonStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <CloseIcon />
            </Button>
          </div>
          <p>
            Fill the following form if you want to become a member of Agnitor.
          </p>
          <div cf-context="">
            <form
              action=""
              method="POST"
              id="E_Agnitor"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="row">
                <div className="col-6 left-wrapper">
                  <div className="input-wrapper hidden-fields">
                    <input
                      id="pageURL"
                      type="text"
                      name="pageURL"
                      className="pageURL"
                    />
                    <input
                      id="formType"
                      type="text"
                      name="formType"
                      defaultValue="E_Agnitor"
                    />
                    <input
                      id="location"
                      type="text"
                      name="location"
                      className="userLocation"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Full Name</label>
                    <input
                      id="name"
                      name="name"
                      tabIndex={1}
                      type="text"
                      maxLength={100}
                      value={userData.name}
                      required
                      onChange={handleInput}
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Organization</label>
                    <input
                      id="org"
                      name="org"
                      tabIndex={3}
                      type="text"
                      value={userData.org}
                      required
                      onChange={handleInput}
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Country</label>
                    {/* us - e0be1264-f36b-1410-fa98-00155d043204
ind - 81ec3fac-3042-4cec-9326-3067b085501e */}

                    <select
                      id="country"
                      value={selectedValue}
                      onChange={handleSelectChange}
                      name="country"
                    >
                      <option value="81ec3fac-3042-4cec-9326-3067b085501e">
                        India
                      </option>
                      <option value="e0be1264-f36b-1410-fa98-00155d043204">
                        USA
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-6 right-wrapper">
                  <div className="input-wrapper">
                    <label htmlFor="">Email</label>
                    <input
                      id="email"
                      name="email"
                      tabIndex={4}
                      type="email"
                      value={userData.email}
                      required
                      onChange={handleInput}
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Phone No.</label>
                    <input
                      id="phone"
                      name="phone"
                      tabIndex={5}
                      type="tel"
                      value={userData.phone}
                      required
                      onChange={handleInput}
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">City</label>
                    <input
                      id="city"
                      name="city"
                      tabIndex={6}
                      type="text"
                      maxLength={100}
                      value={userData.city}
                      required
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
              <div className="row"></div>
              <div className="row">
                {/* <div className="col-6 left-wrapper">
                  <div className="input-wrapper">
                    <p style={{ margin: "0 0 10px 0", fontSize: "12px" }}>
                      I am interested in becoming a member of Agnitor. Please
                      provide me more information. Please attach your resume,
                      and if you are representing any company, please attach the
                      company’s profile.
                    </p>
                    <input type="file" />
                  </div>
                </div> */}
                <div className="input-wrapper">
                  <ReCaptcha
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    onChange={handleRecaptchaChange}
                  />
                  {captchaError && (
                    <p
                      className="required-message"
                      style={{ fontSize: "12px", color: "red" }}
                    >
                      * Please select CAPTCHA
                    </p>
                  )}
                </div>
              </div>
              <div className="btn-wrapper">
                <input
                  onclick="createObject(); return false"
                  className="btn btn-secondary"
                  type="submit"
                  defaultValue="Send"
                  tabIndex={7}
                />
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default ContactUs;
