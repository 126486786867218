import React from "react";
import { NavLink } from "react-router-dom";
import "./ComingSoon.css";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

const ComingSoon = () => {
  return (
    <div className="cdigital-page landing-page">
      <div className="canvas">
        <Navbar />
        <div className="page-container">
          <div id="coming-soon-page">
            <div className="content">
              <h1 className="header" style={{ textAlign: "center" }}>
                We will be launching this soon.
              </h1>
              {/* <span>Our new product is launching soon!</span> */}
              <img
                src="/img/coming_soon.svg"
                alt="Graphics vector"
                width={"100%"}
              />
              <div
                className="btns"
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NavLink to="/" className="back-link">
                  Go Back to Homepage
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>

      <Footer/>
    </div>
  );
};

export default ComingSoon;
