import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Kgisl_Case_Study = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Customer CLM Success Story for Leading Software Company | Virtuos Digital";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/kgisl-header-img.jpg`}
                                    alt="KGiSL"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/kgisl-logo.png`}
                                        alt="KSiSL Logo"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        KGISL implements Agiloft CLM through Virtuos Vivacis
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <section className="breadcrumb-kanaban">
                            <div className="page-wrap breadcrumb-wrapper">
                                <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                    <li>
                                        <NavLink to={`/corporate/`}>About Virtuos</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/customers/`} className="active">
                                            Customer Success
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={`/customers#success-stories`}
                                            className="smooth"
                                        >
                                            CUSTOMER SUCCESS STORIES
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/succeedant/`} className="smooth">
                                            SUCCEEDANT CSM
                                        </NavLink>
                                    </li>

                                </ul>
                                <ul className="more-hidden-tabs">
                                    <li
                                        className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <i className="fal fa-plus" />  More
                                        {showExtraLinks && (
                                            <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                                <li>
                                                    <NavLink to={`/corporate/experience-edge/`}>
                                                        EXPERIENCE EDGE
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`/insights/`}>
                                                        INSIGHTS AND RESOURCES
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                <h3 className="title">About KGiSL</h3>
                                <p>
                                    KGiSL is a BFSI centric multiproduct Enterprise Software company
                                    focused on Banking, Insurance, Capital Markets, &amp; Wealth
                                    Management segments.
                                </p>
                                <p>
                                    KGiSL utilizes the strengths of cloud, analytics, data science,
                                    machine learning (ML), artificial intelligence (AI), analytics, and
                                    data science to deliver outcomes based on empathy and support
                                    next-generation customer experience.
                                </p>
                                <h3 className="title">Challenges</h3>
                                <ul>
                                    <li>Manual process for tracking contract generation request.</li>
                                    <li>Maintenance of multiple tracking sheets and emails.</li>
                                    <li>Unable to track the expiry of contract in advance.</li>
                                    <li>Tracking redlines and versioning.</li>
                                    <li>No central repository for documents.</li>
                                </ul>
                                <h3 className="title">Need</h3>
                                <ul>
                                    <li>
                                        Need to distribute the work of contract based on domains and
                                        regions.
                                    </li>
                                    <li>Streamlined process and track the delays.</li>
                                    <li>
                                        Capture pre-requisite documents/details on submission of contract
                                        creation request.
                                    </li>
                                    <li>
                                        Capture internal interactions and comments related to the contract.
                                    </li>
                                    <li>
                                        Contract repository with Search functionality based on keywords.
                                    </li>
                                    <li>Dashboard for Business heads for insights on contracts.</li>
                                    <li>
                                        Notification to concern teams before expiration of the contract.
                                    </li>
                                    <li>Predefined Approval Workflow based on domains and regions.</li>
                                    <li>Signing tools integration (DocuSign).</li>
                                </ul>
                                <h3 className="title">Win</h3>
                                <ul>
                                    <li>
                                        We utilized OOTB functionality of contract repository and OCR
                                        search.
                                    </li>
                                    <li>
                                        Created customized workflow to accommodate their business
                                        requirements and hierarchy.
                                    </li>
                                    <li>
                                        Integration with third party tools like MS Teams and Outlook for
                                        Contract approval and creation requests.
                                    </li>
                                    <li>
                                        TAT for Approvals and Implemented custom notifications for
                                        reminders.
                                    </li>
                                </ul>
                                <h3 className="title">Partner</h3>
                                <p>
                                    Virtuos is a Gold Partner for Agiloft, has rich experience in
                                    implementing complex solutions and our team has delivered and
                                    surpassed customer's expectations always.
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="customer-success-section">
                        <h2 className="section-main-heading">
                            Other <span className="highlight">Case Studies</span>
                        </h2>
                        <div className="clients-wrapper">
                            <ul className="row page-wrap offport-down">
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/standard-chartered-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/standard-chartered-casestudy-img.jpg`}
                                                alt="Standard Chartered Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/standard_chartered.svg`}
                                                alt="Standard Chartered Bank Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">CX and Contact Center Automation</p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/tvs-credit-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/tvsc-casestudy-img.jpg`}
                                                alt="TVS Credit Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/tvscs-logo.png`}
                                                alt="TVS Credit"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer Experience Transformation Project using Oracle platform
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/makemytrip-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/makemytrip-casestudy-img.jpg`}
                                                alt="Make My Trip Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/make-my-trip-logo.svg`}
                                                alt="Make My Trip Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            MakeMyTrip.com halved avg. call handling time and increased
                                            revenue with CX Cloud
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/electronics-company-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/electronics-company-casestudy-img.jpg`}
                                                alt="Electronics Company Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/confidential-logo.svg`}
                                                alt="Confidential Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer engagement on phone, email, chat and social channels
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Kgisl_Case_Study