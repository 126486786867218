import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Coronavirus_Impact_On_Business = () => {
  useEffect(() => {
    document.title =
      "Corona Impact on Business; VUCA Continuity Framework – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/coronavirus-impact-on-business-big-img.jpg`}
                  alt="Coronavirus Impact on Business, VUCA Continuity Framework"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram, 20 March 2020</span>
                <h2 className="article-heading">
                  Coronavirus Impact on Business, VUCA Continuity Framework{" "}
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  The Coronavirus has wrecked the buoyancy of the economy in a
                  jolt bringing people into a situation like war affecting
                  businesses across all spheres. In many countries like Italy,
                  Spain, UK, US, the people are forced to lock-up and adjust to
                  new standards of living and cope with the situation. The
                  scariest and fatal pandemic has brought catastrophic changes
                  to how we live, operate, and conduct our work-life in the
                  future.{" "}
                </p>
                <p>
                  In India, we are in the second stage of this vulnerability,
                  with hundreds infected with Coronavirus and thousands flooding
                  the cities to flee to villages for their livelihood. Unless we
                  act decisively to lock down all the cities and towns by
                  isolating and possibly quarantining the affected people, we
                  could potentially see a danger of at least 100 million older
                  adults dying without having the healthcare facilities in a
                  matter of months.
                </p>
                <p>
                  India has one of the imperfect healthcare infrastructures in
                  the world to handle pandemic situations like Coronavirus, and
                  the sheer size of our population will wreck the system to a
                  grinding halt and commotion.
                </p>
                <p>
                  The pandemic will collapse the economy and turn India to pre
                  1947 chaos with people everywhere grappling for food, water,
                  and basic necessities for survival. Most advanced countries in
                  Europe have been braving the brunt of early mistakes and
                  letting the people move quickly without the fear of
                  Coronavirus.
                </p>
                <p>
                  'Overreacting is better than non-reacting' - academics around
                  the world share thoughts on coronavirus.{" "}
                  <NavLink
                    to="https://www.weforum.org/agenda/2020/03/coronavirus-covid19-global-academics-insights-pandemic"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    <strong>Read here</strong>
                  </NavLink>
                </p>
                <p>
                  “Stay locked in the house, if you want to save the elderly
                  people in the country. This is the gift we can give to our
                  people (Baby boomers and X-Generation) who built the nation
                  and worked hard for decades for our well being” says Venky
                  Vijay Reddi, ExperienCEO of Virtuos Digital. “If we don’t act
                  right now at this very hour, the very tomorrow could be fatal
                  for the entire nation, and we can never forgive ourselves.”
                </p>
                <p>
                  Virtuos has asked all of the employees to work from home with
                  immediate effect until the situation is improved. We have
                  offered paid leaves to those employees who do not use
                  computers for office use. We have advised every employee to
                  handle this pandemic situation to their advantage by
                  harnessing their skills, creating new products and services,
                  and helping customers with more technical help, and consulting
                  services.
                </p>
                <p>
                  Virtuos has used this opportunity to beef up its offerings by
                  revamping its product portfolio strategy, building new
                  competencies in CRM and CX Technologies, and researching into
                  other areas of Digital Workplace, IoT, Digital Twin, and
                  Rainbow of CX technologies.
                </p>
                <h3 className="title">VUCA Framework</h3>
                <p>
                  VUCA stands for Vulnerability, Uncertainty, Complexity, and
                  Ambiguity. Turn VUCA to new standards of differentiated “VUCA”
                  - Virtuosity, Ubiquity, Continuity, Adaptability. We also
                  advise our employees and customers on the strategy to handle
                  epidemic disruption, how to address the impact on customer
                  service and support delivery, and how we can serve customers
                  worldwide.
                </p>
                <p>
                  For more information, visit{" "}
                  <NavLink
                    to="http://www.vuca.com"
                    target="_blank"
                    className="link"
                  >
                    www.vuca.com
                  </NavLink>
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. (Formerly Virtuos Solutions Private Ltd.)
                  is a leading Customer Experience (CX) &amp; Digital
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/cxdesk/`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/xonomy/ai/`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce and Digital technologies. The{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  under the aegis of Virtuos Xonomy is a think tank to breed and
                  bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.vyo.com"
                    target="_blank"
                    className="link"
                  >
                    VYO.com
                  </NavLink>{" "}
                  (Part of BIGV 4.0 As A Strategy) and a host of unique
                  Experience Cloud and Experience Crowd applications. Virtuos
                  Experience Cloud business comprises a CEDAR strategy —{" "}
                  <NavLink
                    to="http://www.cloudle.com/"
                    target="_blank"
                    className="link"
                  >
                    Cloudle
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  . Virtuos Experience Crowd business will launch its first
                  product CustomerNow crowd in 2020.
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />

          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Coronavirus_Impact_On_Business;
