import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Virtuos_Solutions_Moves_To_A_New_Office = () => {
    const CustomPrevArrow = ({ onClick }) => (
        <div className="prev-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
    );
    const CustomNextArrow = ({ onClick }) => (
        <div className="next-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    useEffect(() => {
        document.title = "Virtuos Solutions moves to a New Office – a larger space to further expand its operations – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-solutions-moves-to-new-office-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 10 December, 2010</span>
                                <h2 className="article-heading">
                                    Virtuos Solutions moves to a New Office — a larger space to further
                                    expand its operations
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos has many employees needing additional space and parking
                                    facilities for the vehicles. The previous office, unfortunately, has
                                    not been able to meet our needs. With the increased demand for
                                    collaboration with clients, in-house training, and simultaneous
                                    meetings, Virtuos needed an office with a couple of conference rooms,
                                    a dedicated cafeteria, independent rooms for senior executives, and
                                    about 40-50 workstations.
                                </p>
                                <p>
                                    "The Office housed on the entire floor in an independent building will
                                    meet our needs for the next 2-3 years". Said Venky Vijay Reddi, CEO of
                                    Virtuos. Employees need to have their own space, spend some time
                                    during the day at an independent cafeteria, and the current office
                                    will provide these amenities."
                                </p>
                                <div className="photo-slider-wrapper">
                                    <Slider {...settings} className="bxslider photo-slider">
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/virtuos-new-office-2010-img-01.jpg`}
                                                    alt="Virtuos New Office"
                                                />
                                            </figure>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/virtuos-new-office-2010-img-02.jpg`}
                                                    alt="Virtuos New Office"
                                                />
                                            </figure>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/virtuos-new-office-2010-img-03.jpg`}
                                                    alt="Virtuos New Office"
                                                />
                                            </figure>
                                        </div>
                                    </Slider>
                                </div>
                                <br />
                                <p>
                                    "Virtuos has a conference room that can accommodate more than 12
                                    people at a time to conduct meetings and schedule client demo
                                    sessions. Besides, we have the floor where employees can spend some
                                    private time during the breaks. Said Shaloo Reddi, Exec VP HR. It's
                                    crucial to offer facilities and freedom of movement to the employees
                                    to ensure that they can exercise a great work-life balance."
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based in
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book — Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three essential needs
                                    of growing businesses — Sales, Customer Service, and Marketing.
                                    Virtuos has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has been fiercely growing in the space of CRM, Service Cloud
                                    and Cloud Computing space by delivering the cutting edge solutions to
                                    leading brands like MakeMyTrip, GE-Healthcare, Standard Chartered
                                    Bank, Party Gaming, Fundtech amongst other companies. For more
                                    information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Solutions_Moves_To_A_New_Office