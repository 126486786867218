import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_Smartsheet = () => {
  useEffect(() => {
    document.title =
      "Virtuos to unveil NOVV — NewWe Digital Transformation Practice – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/Virtuos_Smartsheet.jpg`}
                  alt="Virtuos to unveil NOVV — NewWe Digital Transformation Practice"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram May 12, 2023</span>
                <h2 className="article-heading">
                  Virtuos Digital and Smartsheet form a partnership to develop
                  new products.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos and Smartsheet form partnership for India and APAC
                  region initially to resell, implement and transform using
                  Smartsheet Portfolio of Products. Smartsheet is a global
                  leader in Work Management and Project Collaboration tools
                  worldwide, and ranked as one of the leaders in Gartner’s Magic
                  Quadrant.
                </p>
                <p>
                  Virtuos Digital will be building composable products using
                  Smartsheet as key technology. We will be rolling out CXSheet
                  in this year which will be around Customer Experience
                  Transformation leveraging Smartsheet’s Agile Platform.
                </p>
                <p>
                  Virtuos Digital’s AllAgile Delivery Framework is powered by
                  Smartsheet and we have been implementing the Agile Processes
                  with the help of Scaled Agile over the past few years.
                  Smartsheet will be the latest edition to empower our
                  employees, customers and partners in a whole new way showing
                  the transformation @ the speed of the thought.
                </p>
                {/* <p>
                  Virtuos NOVV will be officially launched in the first week of
                  April 2023 in India followed by the USA and rest of the
                  geographies.
                </p> */}
                <h3 className="title">About Smartsheet</h3>
                <p>
                  {" "}
                  {/* <NavLink
                    to={`/consultare`}
                    className="link"
                  >
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Microsoft Dynamics, Creatio Low Code No Code
                  Platform &amp; CRM Applications; Agiloft Contract Lifecycle
                  Management (CLM) amongst other Composable Products &amp;
                  Services. Virtuos Consulting services include Four Fabulous
                  business units namely —{" "} */}
                  Smartsheet (NYSE: SMAR) is the enterprise platform for dynamic
                  work. By aligning people and technology so organizations can
                  move faster and drive innovation, Smartsheet enables its
                  millions of users to achieve more. Visit{" "}
                  <NavLink
                    to="http://www.smartsheet.com"
                    target="_blank"
                    className="link"
                  >
                    www.smartsheet.com
                  </NavLink>{" "}
                  to learn more.
                  {/* 
                  <NavLink
                    to="http://www.virtuos.com/vivacis"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Vivacis
                  </NavLink>
                  ,{" "}
                  <NavLink
                    to="http://www.virtuos.com/veracis"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Veracis
                  </NavLink>
                  ,{" "}
                  <NavLink
                    to="http://www.virtuos.com/vaves"
                    trget="_blank"
                    className="link"
                  >
                    Virtuos Vaves
                  </NavLink>
                  , and{" "}
                  <NavLink
                    to="http://www.vedam.com"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Vedam
                  </NavLink>
                  . */}
                </p>

                <h1
                  className="title"
                  style={{ fontSize: "2rem", color: "#01a2fe" }}
                >
                  About Virtuos Group
                </h1>
                <h3 className="title">Virtuos</h3>
                <p>
                  {/* Virtuos{" "}
                  <NavLink
                    to={`/xonomy/`}
                    className="link"
                  >
                    Xonomy
                  </NavLink>{" "} */}
                  Virtuos ideates, incubates and invests in the next-generation
                  businesses harnessing Artificial Intelligence (AI), Cloud,
                  ECommerce, Blockchain and Digital technologies that are
                  integral to the futuristic{" "}
                  <NavLink to="/xonomy" target="_blank" className="link">
                    Experience Economy (Xonomy)
                  </NavLink>
                  . At Virtuos, we have incubated and invested in several
                  companies that include{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart
                  </NavLink>{" "}
                  — Gifting Experiences Commerce Company;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic
                  </NavLink>
                  — the Portfolio of Experience Commerce Brands;{" "}
                  <NavLink
                    to="http://www.sweven.com"
                    target="_blank"
                    className="link"
                  >
                    Sweven
                  </NavLink>{" "}
                  — Salesforce CRM Mastermind services; and{" "}
                  <NavLink
                    to="http://www.tekcorp.com"
                    target="_blank"
                    className="link"
                  >
                    Tekcorp
                  </NavLink>{" "}
                  — the Technology Infrastructure.
                </p>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We have launched
                  Brandcart.com—the brand naming platform for businesses.We will
                  be unleashing an integrated and agile marketing platform
                  Services and Digital Agency— Wundero; Building CRM Studio
                  Suite powered by Composable No Code Platform. In 2024 Virtuos
                  will also be launching Expedience — The Talent advisory and
                  sourcing platform
                </p>
                <p>
                  Virtuos created a brand new{" "}
                  <NavLink
                    to="http://www.aiambition.com/"
                    target="_blank"
                    className="link"
                  >
                    AI-Ambition
                  </NavLink>{" "}
                  to leverage and harness generative AI’s full competitive
                  potential along with other AI technologies. We will be
                  building the right portfolio, ecosystem, deployment model,
                  skills and governance to help enterprise customers create
                  their #AIAmbition.
                </p>
                <h3 className="title">Virtuos Digital </h3>
                <p>
                  {/* <NavLink
                    to="http://www.experiencecloud.com"
                    target="_blank"
                    className="link"
                  >
                    Experience Cloud
                  </NavLink>{" "} */}
                  Virtuos Digital delivers industry-specific counsel and the
                  integrated, end-to-end solutions that support its customers'
                  strategic, operational, and financial goals. Virtuos Digital
                  (VDC) is an Experience consultancy that provides superior
                  customer moments and implements leading CRM and Customer
                  Experience Applications — Oracle CX; Salesforce Platforms,
                  Microsoft Dynamics, Creatio No Code Platform & CRM
                  Applications; Agiloft Contract Lifecycle Management (CLM);
                  Asana Work Management amongst other Composable Products &
                  Services. For more information on Virtuos Digital please visit{" "}
                  <NavLink to="/consultare" target="_blank" className="link">
                    www.virtuos.com/consultare
                  </NavLink>
                </p>
                {/* <h3 className="title">Virtuos Nexorama</h3>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We are building an
                  integrated and agile learning platform — Wundero; Carving out
                  a new business model building a marketplace for LowCode NoCode
                  Technologies by composing a new business UnCodify.com. Virtuos
                  will be launching Expedience — The Talent advisory and
                  sourcing platform and Audacis, a new digital marketing
                  services agency.
                </p> */}
                <div>
                  <p style={{ marginBottom: "0px" }}>
                    Digital Media Department{" "}
                  </p>
                  <NavLink
                    to="http://www.virtuos.com"
                    target="_blank"
                    className="link"
                  >
                    www.virtuos.com
                  </NavLink>
                </div>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Smartsheet;
