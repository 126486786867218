import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import { FoodBank } from '@mui/icons-material';
import Footer from '../../components/Footer';
import Customer_Wide_Form from "./Forms/CustomerWideForm"
const Customer_Wide = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };

  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };

  useEffect(() => {
    document.title = "C.Digital Where C means Customer – Virtuos Digital";

  }, []);
  return (
    <div className="landing-page cwide-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/custmerwide-header-img.jpg`}
                alt=""
              />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5 item-wrapper">
                  <h3 className="section-heading">
                    C.Digital — Where "C" means Customer
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/c-digital/`}>C.DIGITAL</NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/c-digital/customer-wide/`}
                    className="active"
                  >
                    CUSTOMER WIDE
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/`}>
                    CUSTOMER 360
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/ci/`}>
                    CONTINUOUS INTELLIGENCE
                  </NavLink>
                </li>

              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" />  More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                  <NavLink to={`/c-digital/customer360/ceh/`}>
                    CUSTOMER ENGAGEMENT HUB
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/xlv/`}>EXPERIENCE ID</NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/digital-dragons/`}>
                    DIGITAL DRAGONS
                  </NavLink>
                </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="faces-container">
            <h2 className="section-main-heading">
              4 Faces of <span className="highlight">Digital Consumer</span>
            </h2>
            <span className="section-desc">
              As consumers become more digital, four attitudes are changing the way they
              expect to interact with organizations Take these attitudes into account
              when developing and prioritizing digital opportunities.
            </span>
            <div className="row page-wrap">
              <div className="col-5 img-block">
                <figure>
                  <img src={`/img/face1-img.jpg`} alt="" />
                </figure>
                <div className="showcase">
                  <span className="number">86%</span>
                  <span className="txt">
                    of consumers say personalization plays a role in their purchasing
                    decisions.
                  </span>
                </div>
              </div>
              <div className="col-1 number">1</div>
              <div className="col-6 content-block">
                <h2>I’m an individual (not a segment)</h2>
                <p>
                  Personalized messages and offerings will be more effective than mass
                  offerings.
                </p>
                <p className="highlight">
                  <strong>IMPLICATIONS FOR LEADERS:</strong>
                </p>
                <ul>
                  <li>
                    <div className="subtitle">
                      <i className="fa fa-check-circle" /> Do
                    </div>
                    <p>
                      Segment personalization opportunities (e.g., services around
                      products or personalized marketing) by complexity costs and point
                      of diminishing returns.
                    </p>
                  </li>
                  <li>
                    <div className="subtitle">
                      <i className="fa fa-times-circle" /> Don't
                    </div>
                    <p>
                      Aim to personalize everything. A great product or message will
                      still have significant value for all.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row page-wrap right">
              <div className="col-6 content-block">
                <h2>I expect an effortless experience</h2>
                <p>
                  Effortless access to products and service across channels is a key
                  differentiator.
                </p>
                <p className="highlight">
                  <strong>IMPLICATIONS FOR LEADERS:</strong>
                </p>
                <ul>
                  <li>
                    <div className="subtitle">
                      <i className="fa fa-check-circle" /> Do
                    </div>
                    <p>
                      Steer consumers to the lowest-effort and most appropriate channel
                      for their issue or request.
                    </p>
                  </li>
                  <li>
                    <div className="subtitle">
                      <i className="fa fa-times-circle" /> Don't
                    </div>
                    <p>
                      Assume all channels are equally equipped to help consumers with
                      all their requests.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-1 number">2</div>
              <div className="col-5 img-block">
                <figure>
                  <img src={`/img/face2-img.jpg`} alt="" />
                </figure>
                <div className="showcase">
                  <span className="number">96%</span>
                  <span className="txt">
                    of consumers will be disloyal if they need to exert high effort for
                    the outcomes they want.
                  </span>
                </div>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-5 img-block">
                <figure>
                  <img src={`/img/face3-img.jpg`} alt="" />
                </figure>
                <div className="showcase">
                  <span className="number">72%</span>
                  <span className="txt">
                    Marketing initiatives are more likely to perform highly if they
                    share consumers’ values.
                  </span>
                </div>
              </div>
              <div className="col-1 number">3</div>
              <div className="col-6 content-block">
                <h2>What I buy reflects my values and beliefs</h2>
                <p>Concern over ethics will influence purchasing decisions.</p>
                <p className="highlight">
                  <strong>IMPLICATIONS FOR LEADERS:</strong>
                </p>
                <ul>
                  <li>
                    <div className="subtitle">
                      <i className="fa fa-check-circle" /> Do
                    </div>
                    <p>
                      Align compliance and monitoring resources with the areas that pose
                      the greatest risk to the company’s reputation, and be open about
                      your findings.
                    </p>
                  </li>
                  <li>
                    <div className="subtitle">
                      <i className="fa fa-times-circle" /> Don't
                    </div>
                    <p>
                      Take a wait-and-see approach to uncover suppliers’ ethics, or rely
                      on standardized tests to try to uncover risks early.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row page-wrap right">
              <div className="col-6 content-block">
                <h2>My peers help me decide</h2>
                <p>
                  Peer networks are now the most impactful information for consumers.
                </p>
                <p className="highlight">
                  <strong>IMPLICATIONS FOR LEADERS:</strong>
                </p>
                <ul>
                  <li>
                    <div className="subtitle">
                      <i className="fa fa-check-circle" /> Do
                    </div>
                    <p>
                      Identify consumer conversations that are unrelated to the brand to
                      prioritize new conversations to influence, and build IT’s openness
                      to marketing-led digital experiments.
                    </p>
                  </li>
                  <li>
                    <div className="subtitle">
                      <i className="fa fa-times-circle" /> Don't
                    </div>
                    <p>
                      Focus solely on understanding your brand’s share of voice on
                      social channels, or assume that traditional marketing activities
                      go away.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-1 number">4</div>
              <div className="col-5 img-block">
                <figure>
                  <img src={`/img/face4-img.jpg`} alt="" />
                </figure>
                <div className="showcase">
                  <span className="number">88%</span>
                  <span className="txt">
                    of consumers frequently consult a peer review when making a
                    purchase.
                  </span>
                </div>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
              <Customer_Wide_Form/>
              </div>
            </div>
          </section>
          {/*?php include '../../footer-consultare.php';?*/}
        <Footer/>
          <FoodBank />
        </main>

      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  )
}

export default Customer_Wide
