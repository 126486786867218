import React, { useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./cxdigital.css"

const CXDigital = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    document.title = "CX and Digital Integrated Transformation | CX.Digital – Virtuos Digital";

    const handleScroll = (event) => {
      const { documentElement, body } = document;
      const { clientHeight, scrollHeight, scrollTop } = documentElement || body;
      const delta = event.deltaY;

      const scrollThreshold = 0.8; // Adjust this value to control when the slide changes

      if (
        delta > 0 &&
        scrollTop + clientHeight >= scrollHeight * scrollThreshold
      ) {
        // Scrolling down and reached the threshold
        sliderRef.current.slickNext();
      } else if (
        delta < 0 &&
        scrollTop <= clientHeight * (1 - scrollThreshold)
      ) {
        // Scrolling up and reached the threshold
        sliderRef.current.slickPrev();
      }
    };

    window.addEventListener("wheel", handleScroll);

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };

  return (
    <div className="cx-digital-page xx-digital-page sub-home-page listing-page">
      <div className="canvas">
        <Navbar />
      </div>
      <main className="main-wrapper">
        <div className="slides">
          <Slider {...settings} ref={sliderRef}>
            <div className="sliderdiv">
              <Element name="cx-digital">
              <section className="vertical-section" id="cx-digital">
            <div className="full-screen-wrapper">
              <figure>
                <img
                  src={`/img/cx-marries-digital-bg-img.jpg`}
                  alt="CX Marries Digital"
                />
              </figure>
              <div className="center-wrapper">
                <h2 className="section-main-heading" style={{marginBottom:".7em"}}>
                  CX Marries Digital To Become CX.Digital
                </h2>
                <p style={{marginBottom:".7em"}}>
                  Compelling reasons for transforming your Customer Experience vision.
                </p>
                <NavLink
                  to={`/cx/cx-marries-digital/`}
                  className="btn btn-secondary"
                >
                  Explore <i className="fa fa-long-arrow-right" />
                </NavLink>
                <ul className="links-container">
                  <li>
                    <NavLink to={`/cx/strategy-design/`}>Strategy</NavLink>
                  </li>
                  <li>
                    <NavLink to={`/rainbowcx/`}>Technology</NavLink>
                  </li>
                  <li>
                    <NavLink to={`/cxnow/`}>Transformation</NavLink>
                  </li>
                  <li>
                    <NavLink to={`/cxnow/cx-continuum/`}>
                      CX Continuum
                    </NavLink>
                  </li>
                </ul>
                <span className="blinking-down-arrow">
                  Scroll Down
                  <i className="fal fa-angle-down arrow" />
                </span>
              </div>
            </div>
          </section>
              </Element>
            </div>
            <div>
              <Element name="cx-strategy">
              <section className="vertical-section" id="cx-strategy">
            <div className="full-screen-wrapper">
              <figure>
                <img
                  src={`/img/svg/strategy-execution-bg.svg`}
                  alt="CX is Brand"
                />
              </figure>
              <div className="center-wrapper">
                <h2 className="section-main-heading" style={{marginBottom:".7em"}}>
                  Strategy <span className="italic">is</span> Execution. <br /> CX
                  <span className="italic">is</span> Brand.
                </h2>
                <p style={{marginBottom:".7em"}}>
                  At Virtuos, we create and use Moments of Truth to enhance your CX.
                </p>
                <NavLink
                  to={`/cx/strategy-design/`}
                  className="btn btn-secondary"
                >
                  Explore <i className="fa fa-long-arrow-right" />
                </NavLink>
                <span className="blinking-down-arrow">
                  Scroll Down
                  <i className="fal fa-angle-down arrow" />
                </span>
              </div>
            </div>
          </section>
              </Element>
            </div>

            <div>
              <Element name="cxnow">
              <section className="vertical-section" id="cxnow">
            <div className="full-screen-wrapper">
              <figure>
                <img
                  src={`/img/svg/customer-experience-program-img.svg`}
                  alt="Customer Experience"
                />
              </figure>
              <div className="right-logo-block">
                <figure>
                  <img
                    src={`/img/svg/icons/cxnow-logo.svg`}
                    alt="CXNow"
                  />
                </figure>
              </div>
              <div className="center-wrapper">
                <h2 className="section-main-heading" style={{marginBottom:".7em"}}>
                  Customer Experience is not a project — it’s a program
                </h2>
                <p style={{marginBottom:".7em"}}>
                  Introducing CXNow — the state of the art Customer Experience
                  Building blocks.
                </p>
                <NavLink to={`/cxnow`} className="btn btn-secondary">
                  Explore <i className="fa fa-long-arrow-right" />
                </NavLink>
                <span className="blinking-down-arrow">
                  Scroll Down
                  <i className="fal fa-angle-down arrow" />
                </span>
              </div>
            </div>
          </section>
              </Element>
            </div>
            <div>
              <Element name="rainbowcx">
              <section className="vertical-section" id="rainbowcx">
            <div className="full-screen-wrapper">
              <figure>
                <img
                  src={`/img/svg/rainbow-cx-img.svg`}
                  alt="Rainbow CX"
                />
              </figure>
              <div className="right-logo-block">
                <figure>
                  <img
                    src={`/img/svg/icons/rainbowcx-logo.svg`}
                    alt="Rainbow CX"
                  />
                </figure>
              </div>
              <div className="center-wrapper">
                <h2 className="section-main-heading" style={{marginBottom:".7em"}}>
                  Rainbow CX gives Invincible Edge to your Experience Business
                </h2>
                <p style={{marginBottom:".7em"}}>
                  Traditional CRM Tools poorly perform in the modern Experience
                  Economy. You need Rainbow CX For augmenting your Experience Edge.
                </p>
                <NavLink
                  to={`/rainbowcx/`}
                  className="btn btn-secondary"
                >
                  Explore <i className="fa fa-long-arrow-right" />
                </NavLink>
                <span className="blinking-down-arrow">
                  Scroll Down
                  <i className="fal fa-angle-down arrow" />
                </span>
              </div>
            </div>
          </section>
              </Element>
            </div>
            <div>
              <Element name="cxdesk">
              <section className="vertical-section" id="cxdesk">
            <div className="full-screen-wrapper">
              <figure>
                <img
                  src={`/img/svg/cxdesk-management-bg.svg`}
                  alt="CXDESK"
                />
              </figure>
              <div className="center-wrapper">
                <h2 className="section-main-heading" style={{marginBottom:".7em"}}>
                  Accelerate quickly with CXDESK CX Continuum
                </h2>
                <p style={{marginBottom:".7em"}}>
                  CXDESK Services and tools to help organizations retain their
                  existing customers.
                </p>
                <NavLink
                  to={`/cxdesk/`}
                  className="btn btn-secondary"
                >
                  Explore <i className="fa fa-long-arrow-right" />
                </NavLink>
                <span className="blinking-down-arrow">
                  Scroll Down
                  <i className="fal fa-angle-down arrow" />
                </span>
              </div>
            </div>
          </section>
              </Element>
            </div>
            <div>
              <Element name="indicial">
              <section className="vertical-section" id="indicial">
            <div className="full-screen-wrapper">
              <figure>
                <img
                  src={`/img/svg/indicial-bg.svg`}
                  alt="CX and Digital Transformation"
                />
              </figure>
              <div className="right-logo-block">
                <figure>
                  <img
                    src={`/img/svg/icons/indicial-logo-white.svg`}
                    alt="Indicial"
                  />
                </figure>
              </div>
              <div className="center-wrapper">
                <h2 className="section-main-heading" style={{marginBottom:".7em"}}>
                  Indicial — the CX and Digital Maturity
                </h2>
                <p style={{marginBottom:".7em"}}>
                  Integrated CX and Digital Transformation is a discipline that
                  requires an ongoing focus.
                </p>
                <NavLink
                  to={`/indicial/`}
                  className="btn btn-secondary"
                >
                  Explore <i className="fa fa-long-arrow-right" />
                </NavLink>
                <span className="blinking-down-arrow">
                  Scroll Up
                  <i className="fal fa-angle-up arrow" />
                </span>
              </div>
            </div>
          </section>
              </Element>
            </div>
          </Slider>
        </div>
      </main>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default CXDigital;
