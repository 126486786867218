import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

// const photos = [
//   { id: 1, src: "/img/03.jpg", alt: "Photo 1" },
//   { id: 2, src: "/img/02.jpg", alt: "Photo 2" },
//   { id: 3, src: "/img/04.jpg", alt: "Photo 3" },
//   { id: 4, src: "/img/01.jpg", alt: "Photo 4" },
// ];

const Cohering = () => {
  useEffect(() => {
    document.title =
      "Cohering, a suite of AI-Platforming tools designed to build Composite AI by integrating AI techniques.";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  className=""
                  src={`${process.env.REACT_APP_MEDIA_URL}/img/cohering_slider_homepage.jpg`}
                  alt="Virtuos to unveil NOVV — NewWe Digital Transformation Practice"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram June 10, 2024</span>
                <h2 className="article-heading">
                  Virtuos Unveils Cohering—A Revolutionary AI-Platforming
                  Solution Mix
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <h3 className="title">
                  AI Platforming: Bridging AI and Business Value
                </h3>
                <p>
                  Generative AI (GenAI) has significantly heightened
                  organizational awareness of AI's potential. Following a year
                  of intense experimentation, 2024 marks the year of action. AI
                  engineering unites various disciplines across the
                  organization, transforming AI hype into tangible value by
                  operationalizing multiple AI techniques, including GenAI
                  models. This approach emphasizes responsible AI, addressing
                  risk, trust, transparency, ethics, fairness, and
                  accountability.
                </p>
                <p>
                  Virtuos, a leader in digital transformation, is pioneering
                  AI-Ambition for high-growth organizations worldwide by
                  leveraging the most advanced Cohering AI Platforming tools and
                  composable products, collectively referred as the Solution
                  Mix. For these organizations, embracing AI is crucial for
                  accelerating growth and venturing into new digital
                  transformation frontiers at unprecedented speeds.
                </p>
                {/* <p>
                  Virtuos NOVV will be officially launched in the first week of
                  April 2023 in India followed by the USA and rest of the
                  geographies.
                </p> */}
                <h3 className="title">Key Revelations:</h3>
                <ul style={{ marginLeft: "2rem" }}>
                  <li>
                    <span>54% of AI projects never reach completion.</span>
                  </li>
                  <li>
                    <span>
                      It takes an average of 8 months to transition from AI
                      prototype to production.
                    </span>
                  </li>
                  <li>
                    <span>
                      AI model development often lacks structured governance,
                      operating in loosely controlled sandbox environments.
                    </span>
                  </li>
                </ul>
                <p>
                  To address these challenges, it's essential to operationalize
                  AI models at scale with appropriate governance through robust
                  model lifecycle orchestration. Virtuos introduces Cohering, a
                  suite of AI-Platforming tools designed to build Composite AI
                  by integrating different AI techniques:
                </p>
                <ul style={{ marginLeft: "2rem" }}>
                  <li>
                    <span class="font-weight600">Data-Driven AI</span>
                  </li>
                  <li>
                    <span class="font-weight600">Machine Learning</span>
                  </li>
                  <li>
                    <span class="font-weight600">Logic & Tools</span>
                  </li>
                  <li>
                    <span class="font-weight600">Symbolic AI</span>
                  </li>
                </ul>
                <p>
                  Cohering creates a pool of shared assets for system
                  development, such as AI Design Patterns, propelling
                  next-generation businesses to embrace Adaptive AI. This future
                  of Composite AI is built on foundational elements: Learning,
                  Awareness, and Autonomy.
                </p>

                <figure>
                  <img
                    src="/img/cohering_image_Virtuos.png"
                    alt="cohering_image"
                  />
                </figure>
                <h3 className="title">AI Workbook Planning and Strategy</h3>
                <p>
                  Cohering assists organizations in AI Workbook Planning,
                  strengthening four key pillars of AI strategy: Vision,
                  Value-Realization, Risk Management, and Adoption Plans.
                </p>

                <p>
                  Cohering will be commercially available in Q3 of 2024 in
                  select countries, initially focusing on accelerating
                  customer-facing business applications like CRM, Customer
                  Experience, and Contract Lifecycle Management. We are
                  collaborating with over a dozen technology and AI partners to
                  ensure our customers benefit from our extensive experience and
                  insights.
                </p>

                <h1
                  className="title"
                  style={{ fontSize: "2rem", color: "#01a2fe" }}
                >
                  {" "}
                  Virtuos Group
                </h1>
                <h3 className="title">Virtuos</h3>
                <p>
                  {/* Virtuos{" "}
                  <NavLink
                    to={`/xonomy/`}
                    className="link"
                  >
                    Xonomy
                  </NavLink>{" "} */}
                  Virtuos ideates, incubates and invests in the next-generation
                  businesses harnessing Artificial Intelligence (AI), Cloud,
                  ECommerce, Blockchain and Digital technologies that are
                  integral to the futuristic{" "}
                  <NavLink to="/xonomy" target="_blank" className="link">
                    Experience Economy (Xonomy)
                  </NavLink>
                  . At Virtuos, we have incubated and invested in several
                  companies that include{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart
                  </NavLink>{" "}
                  — Gifting Experiences Commerce Company;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic
                  </NavLink>
                  — the Portfolio of Experience Commerce Brands;{" "}
                  <NavLink
                    to="http://www.sweven.com"
                    target="_blank"
                    className="link"
                  >
                    Sweven
                  </NavLink>{" "}
                  — Salesforce CRM Mastermind services; and{" "}
                  <NavLink
                    to="http://www.tekcorp.com"
                    target="_blank"
                    className="link"
                  >
                    Tekcorp
                  </NavLink>{" "}
                  — the Technology Infrastructure.
                </p>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We have launched
                  Brandcart.com—the brand naming platform for businesses.We will
                  be unleashing an integrated and agile marketing platform
                  Services and Digital Agency— Wundero; Building CRM Studio
                  Suite powered by Composable No Code Platform. In 2024 Virtuos
                  will also be launching Expedience — The Talent advisory and
                  sourcing platform
                </p>
                <p>
                  Virtuos created a brand new{" "}
                  <NavLink
                    to="http://www.aiambition.com/"
                    target="_blank"
                    className="link"
                  >
                    AI-Ambition
                  </NavLink>{" "}
                  to leverage and harness generative AI’s full competitive
                  potential along with other AI technologies. We will be
                  building the right portfolio, ecosystem, deployment model,
                  skills and governance to help enterprise customers create
                  their #AIAmbition.
                </p>
                <h3 className="title">Virtuos Digital</h3>
                <p>
                  {/* <NavLink
                    to="http://www.experiencecloud.com"
                    target="_blank"
                    className="link"
                  >
                    Experience Cloud
                  </NavLink>{" "} */}
                  Virtuos Digital (vdc.com) delivers industry-specific counsel
                  and the integrated, end-to-end solutions that support its
                  customers' strategic, operational, and financial goals.
                  Virtuos Digital (VDC) is an Experience consultancy that
                  provides superior customer moments and implements leading CRM
                  and Customer Experience Applications — Oracle CX; Salesforce
                  Platforms, Microsoft Dynamics, Creatio No Code Platform & CRM
                  Applications; Agiloft Contract Lifecycle Management (CLM);
                  Asana Work Management amongst other Composable Products &
                  Services. For more information on Virtuos Digital please visit{" "}
                  <NavLink
                    to="http://www.vdc.com"
                    target="_blank"
                    className="link"
                  >
                    www.vdc.com
                  </NavLink>
                </p>
                {/* <h3 className="title">Virtuos Nexorama</h3>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We are building an
                  integrated and agile learning platform — Wundero; Carving out
                  a new business model building a marketplace for LowCode NoCode
                  Technologies by composing a new business UnCodify.com. Virtuos
                  will be launching Expedience — The Talent advisory and
                  sourcing platform and Audacis, a new digital marketing
                  services agency.
                </p> */}
                <div>
                  <p style={{ marginBottom: "0px" }}>
                    For more information visit{" "}
                    <NavLink
                      to="http://www.virtuos.com"
                      target="_blank"
                      className="link"
                    >
                      www.virtuos.com
                    </NavLink>
                  </p>
                </div>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Cohering;
