import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Becomes_Premium_Partner = () => {
    useEffect(() => {
        document.title = "Virtuos becomes a Certified Premium Partner for RightNow Technologies – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-premium-partner-rightnow-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">New Delhi - 27 Oct, 2008</span>
                                <h2 className="article-heading">
                                    Virtuos becomes a Certified Premium Partner for RightNow Technologies
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Since being a reseller partner in April 2008, Virtuos has transformed
                                    itself into a Certified Premium Partner of RightNow Technologies to
                                    implement the most complex projects worldwide.
                                </p>
                                <p>
                                    Virtuos agreement with RightNow Technologies includes reselling of
                                    RightNow Cloud CRM Software, services, support, and implementing CRM
                                    solutions for companies that are in the region of APAC.
                                </p>
                                <p>
                                    "The Technical team required exhaustive training on various modules of
                                    RightNow Suite, and for this, we are pleased with the visit of Abbas
                                    Ali and Yong Sheng Li (Jun) for India visit to spend some time with
                                    Virtuos." Said Venky Vijay Reddi, CEO of Virtuos. "We have a strong
                                    pipeline in store for positioning RightNow Customer Service Modules at
                                    Retail, E-Commerce, and Travel verticals".
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-certified-premium-partner-for-rightnow-pic.jpg`}
                                        alt="Virtuos becomes a Certified Premium Partner for RightNow Technologies"
                                    />
                                </figure>
                                <br />
                                <p>
                                    Both Virtuos and RightNow worked on a business plan by identifying the
                                    right pursuits, go-to-market strategies, and marketing &amp; promotion
                                    plans. Virtuos has also access to NFR Licenses (unlimited users) of
                                    RightNow Suite to use and showcase the solution to prospects
                                    internally.
                                </p>
                                <p>
                                    I am ecstatic to see that Virtuos teams had picked up the essentials
                                    to get started even before our training program began, and it made my
                                    job easier. Said Abbas Ali, Head of Technical Services, RightNow APAC.
                                    Further, the team is fully charged up to experiment with many new
                                    things on the RightNow Platform.
                                </p>
                                <p>
                                    I see a great moment and clear go-to-market strategy from Venky, and
                                    his sharp insight on what clients to target can make a big difference.
                                    Said Yong Sheng Li (Jun). I am confident to introduce our existing
                                    global customers in India to Virtuos once the latter equips with the
                                    implementation and delivery teams.
                                </p>
                                <p>
                                    Both Virtuos and RightNow Teams have spent the next four to five days
                                    in the office exploring new modules, and new releases of RightNow.
                                    Simultaneously both the sales and technical training were conducted to
                                    the teams.
                                </p>
                                <h3 className="title">About RightNow Technologies</h3>
                                <p>
                                    RightNow Technologies, Inc. (Nasdaq: RNOW) provides customer
                                    relationship management software and services. The Company offers
                                    solutions that use self-learning knowledge-base to deliver customer
                                    self-service, email management, live interaction, issue tracking, and
                                    customer satisfaction measurement capabilities.
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">07/12/2000</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            136 Enterprise Blvd Bozeman, <br />
                                            MT 59718 United States
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Phone</span>
                                        <span className="txt">1-406-522-4200</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink
                                                to="http://www.rightnow.com"
                                                target="_blank"
                                                className="link"
                                            >
                                                www.rightnow.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">1000-2000</span>
                                    </div>
                                </div>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based out
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book — Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three important needs
                                    of growing businesses — Sales, Customer Service and Marketing. Virtuos
                                    has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    )
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">18/01/2008</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            Tekcorp Office Building, <br />F Block Okhla Industrial Area -
                                            Phase 1, <br />
                                            New Delhi 110020 India
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Branch</span>
                                        <span className="txt">Hyderabad</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                                www.virtuos.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">10-20</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Becomes_Premium_Partner