import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Verint_Acquires_Kana_Software = () => {
    useEffect(() => {
        document.title = "Verint Acquires KANA Software – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/verint-acquires-kana-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Sunnyvale, USA - 6 January, 2014</span>
                                <h2 className="article-heading">
                                    Verint acquires KANA Software from KKR Investment company
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Verint, a leading Customer Engagement Company with over US$ one
                                    billion annual revenue has acquired KANA, a KKR Invested privately
                                    held Customer Experience Software Company.
                                </p>
                                <p>
                                    All the KANA customers served by Virtuos will continue to be supported
                                    by Verint without any disruption. Verint and Virtuos will be
                                    partnering to cater to the customers as per the agreement with KANA
                                    Software. Said Venky Vijay Reddi, CEO of Virtuos. Verint with its vast
                                    Customer Experience (CX) Leadership and managing over 10,000 customers
                                    worldwide It would be a great opportunity for all the existing and
                                    future KANA Software customers.
                                </p>
                                <p>
                                    “We believe that acquiring KANA would allow Verint to offer a holistic
                                    customer engagement strategy powered by Actionable Intelligence,
                                    providing significant value for our customers and partners. Our
                                    complementary solutions represent a comprehensive offering to help
                                    optimize customer engagement. We have been impressed with KANA and
                                    share a common vision for our respective markets, including a firm
                                    commitment to our customers,” says Dan Bodner, CEO, Verint. “We also
                                    believe there is a strong cultural fit between the two companies and a
                                    foundation for a successful future together, for both our current and
                                    prospective customers, as well as our employees. We look forward to
                                    completing the transaction and welcoming KANA into the Verint family.”
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/kana-verint-logo.jpg`}
                                        alt="Kana Software, Verint"
                                    />
                                </figure>
                                <br />
                                <p>
                                    KANA would bring its approximately 900 global customers to the greater
                                    Verint organization, which already has the benefit of more than 10,000
                                    customers worldwide. The combined customer base, which includes some
                                    shared customers, can benefit from a broader, complementary solution
                                    set from a single global provider.
                                </p>
                                <p>
                                    “Our management team is excited about the opportunity to join a global
                                    technology leader like Verint, and we have every expectation that our
                                    customers will be excited as well, especially as they learn more about
                                    how Verint and KANA can provide a lens into who is doing what, when,
                                    how, and why in day-to-day engagements with their customers,” says
                                    Mark Duffell, CEO, KANA.
                                </p>
                                <div className="button">
                                    <NavLink
                                        to="https://www.verint.com/press-room/2014-press-releases/verint-to-acquire-kana-software-transforming-the-way-organizations-engage-with-their-customers/"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Read Press Release
                                    </NavLink>
                                </div>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely - Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas and executing them with Virtuoso teams. Virtuos was founded
                                    in 2008 by cultivating an ambitious plan to become a multi-billion
                                    dollar company and a game changer by 2020. Thanks to the hard work and
                                    commitment of our team we continue to grow each year. With new
                                    innovative business ideas, delivering incredible transformation
                                    services and building powerful engagement platforms for the future we
                                    are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink to="http://www.fb.com/virtuos" target="_blank" className="link">
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Verint_Acquires_Kana_Software