import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../components/Navbar";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { scroller } from "react-scroll";
const Expereince_Crowd = () => {
  const scrollOnClick = () => {
    const element = document.getElementById("dati");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [showExtraLinks, setShowExtraLinks] = useState(false);
  const scrollToSection = (sectionId) => {
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 1000,
        smooth: true,
        offset: -50, // Adjust this offset if needed
      });
    }, 100); // Delay in milliseconds
  };

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };
  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };

  useEffect(() => {
    document.title =
      "Virtuos Experience Crowd | Experience Economy Business – Virtuos Xonomy";
  }, []);
  return (
    <div className="exp-crowd-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-6 item-wrapper">
                  <figure>
                    <img
                      src={`/img/svg/icons/experience-crowd-logo-white.svg`}
                      alt="Experience Crowd Logo"
                    />
                  </figure>
                  <h3>
                    Mass collaboration is the future of competitive advantage in
                    business
                  </h3>
                </div>
                <div className="col-6">
                  <figure>
                    <img
                      src={`/img/svg/experience-crowd-header-img.svg`}
                      alt="Experience Crowd"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/xonomy/`}>Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-crowd/`} className="active">
                    EXPERIENCE CROWD
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/agnitor/`}>AGNITOR</NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/experience-crowd#dati`}
                    className="smooth"
                    id="dati-link" // Add an id to the NavLink
                    onClick={scrollOnClick} // Add the click event handler
                  >
                    DATI DATA CROWD
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud/`}>EXPERIENCE CLOUD</NavLink>
                </li>
              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${
                    showExtraLinks ? "active" : ""
                  } inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" /> More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/io/`}>INNOVATION ON</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/business4-0/`}>
                          BUSINESS 4.0 STRATEGY
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/aury/`}>AURY PLATFORM</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/ai/`}>AI</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/budh/`}>BUDH XONOMY</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/`}>VIRTUOS VUCA</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/zidd/`}>ZIDD E-LEARNING</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/ventures/`}>VENTURES</NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="exp-business">
            <h2 className="section-main-heading">
              ENTER <span className="highlight">THE EXPERIENCE BUSINESS</span>
            </h2>
            <span className="section-desc">
              Experience Crowd is a new word coined by Virtuos. Before defining
              Experience Crowd, let’s define Experience Cloud — Again Virtuos
              was the first company to coin this word way back in 2008 when we
              first founded our company.
            </span>
            <span className="section-desc">
              Today thousands of companies use “Experience Cloud” as their
              product type extension.
            </span>
            <div className="row page-wrap">
              <div className="col-6 left-block">
                <h3 className="font-weight600">WHAT IS EXPERIENCE CLOUD?</h3>
                <p>
                  <strong>Virtuos Experience Cloud</strong> is an integrated
                  ecosystem of hubs, platforms and suites connected on a common
                  thread to deliver personalized, superior, and contextual
                  <strong>experiences</strong> to customers, employees, and
                  other constituents. It completely replaces siloed "x" clouds
                  like Sales <strong>Cloud</strong>, Marketing
                  <strong>Cloud</strong>, Service <strong>Cloud</strong>, etc.
                </p>
                <NavLink
                  to={`/experience-cloud/`}
                  className="btn btn-secondary"
                >
                  Learn More <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
              <div className="col-5 right-block">
                <figure>
                  <img
                    src={`/img/svg/experience-cloud-img.svg`}
                    alt="Experience Cloud"
                  />
                </figure>
                <div className="logo">
                  <img
                    src={`/img/svg/icons/experience-cloud-logo-white.svg`}
                    alt="Experience Cloud logo"
                  />
                </div>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-5 right-block">
                <figure>
                  <img
                    src={`/img/svg/experience-crowd-img.svg`}
                    alt="Experience Crowd"
                  />
                </figure>
                <div className="logo">
                  <img
                    src={`/img/svg/icons/experience-crowd-logo-white.svg`}
                    alt="Experience Crowd logo"
                  />
                </div>
              </div>
              <div className="col-6 left-block">
                <h3 className="font-weight600">WHAT IS EXPERIENCE CROWD?</h3>
                <p>
                  Virtuos Experience Crowd is an open ecosystem of hubs,
                  platforms, and suites connected on a common thread to deliver
                  dynamic and contextual experiences to the community. The
                  experiences are designed for and by the broad, distributed set
                  of contributors using the Web, social collaboration
                  techniques, AI, and other democratized systems. Experience
                  Crowd concepts have been borrowed from Crowdsourcing.
                </p>
              </div>
            </div>
          </section>
          <section className="cloud-crowd grey-bg">
            <h2 className="section-main-heading">
              CLOUD <span className="highlight">VS</span> CROWD
            </h2>
            <span className="section-desc">
              Public and Private Clouds are fully controlled by the respective
              bodies who are owning and running the cloud the cloud operations —
              data center, bandwidth, security etc. On the contrary, "Crowd"
              will not become the “crowd” if it will be fully controlled just
              like Cloud.
            </span>
            <span className="section-desc">
              "Crowd" is independently dependent on multiple factors.
            </span>
            <div className="row page-wrap">
              <div className="col-6">
                <p>
                  Crowd is partly controlled by respectives bodies who host the
                  crowd on a secure cloud. Partly “crowd” is largely managed by
                  the contributors just like wikipedia. Crowd and it’s meanings
                  have been borrowed from Sociologist Herbert Blumer (1969)
                  developed a popular typology of crowds based on their purpose
                  and dynamics. The four types he distinguished are casual
                  crowds, conventional crowds, expressive crowds, and acting
                  crowds. A fifth type, protest crowds, has also been
                  distinguished by other scholars.
                </p>
              </div>
              <div className="col-5 author">
                <figure>
                  <img src={`/img/blumer-pic.jpg`} alt="Mr. Blumer" />
                </figure>
                <div className="name">Mr. Herbert Blumer</div>
              </div>
            </div>
          </section>
          <section className="real-crowd">
            <h2 className="section-main-heading">
              TYPES OF (REAL) <span className="highlight">CROWDS</span>
            </h2>
            <span className="section-desc">
              The Real Crowd is a large number of people who gather together
              with a common short-term or long-term purpose. Experience Crowd
              has taken its roots from the following six types of distinctive
              (real) crowds and combined with the meaning of{" "}
              <NavLink
                to="https://www.gartner.com/en/information-technology/glossary/crowdsourcing"
                rel="noreferrer"
                className="link"
              >
                Crowdsourcing
              </NavLink>
              .
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img src={`/img/svg/exp-crowd-diagram.svg`} alt="" />
                </figure>
              </div>
            </div>
            <div className="row page-wrap items-container">
              <div className="col-4 item">
                <h3 className="font-weight600">CASUAL</h3>
                <p>
                  A <span className="italic">casual crowd</span> is a collection
                  of people who happen to be in the same place at the same time.
                </p>
              </div>
              <div className="col-4 item">
                <h3 className="font-weight600">CONVENTIONAL</h3>
                <p>
                  A <span className="italic">conventional crowd</span> is a
                  collection of people who gather for a specific purpose.
                </p>
              </div>
              <div className="col-4 item">
                <h3 className="font-weight600">EXPRESSIVE</h3>
                <p>
                  An <span className="italic">expressive crowd</span> is a
                  collection of people who gather primarily to be excited and to
                  express one or more emotions.
                </p>
              </div>
              <div className="col-4 item">
                <h3 className="font-weight600">ACTIVIST (ACTING)</h3>
                <p>
                  As its name implies, an{" "}
                  <span className="italic">acting crowd</span> goes one
                  important step beyond an expressive crowd by behaving in
                  violent or other destructive behavior such as looting.
                </p>
              </div>
              <div className="col-4 item">
                <h3 className="font-weight600">PROTEST</h3>
                <p>
                  As its name again implies, a{" "}
                  <span className="italic">protest crowd</span> is a collection
                  of people who gather to protest a political, social, cultural,
                  or economic issue.
                </p>
              </div>
              <div className="col-4 item">
                <h3 className="font-weight600">RIOTS</h3>
                <p>
                  A <strong>riot</strong> is a relatively spontaneous outburst
                  of violence by a large group of people. The term{" "}
                  <span className="italic">riot</span> sounds very negative, and
                  some scholars have used terms like{" "}
                  <span className="italic">urban revolt</span> or{" "}
                  <span className="italic">urban uprising</span>.
                </p>
              </div>
            </div>
          </section>
          <section className="breaker-container">
            <div className="row">
              <div className="col-4 left">
                <figure>
                  <img src={`/img/exp-crowd-breaker-lft-img.jpg`} alt="" />
                </figure>
                <div className="content-block">
                  <div className="logo">
                    <img
                      src={`/img/svg/icons/agnitor-logo-white.svg`}
                      alt="Agnitor logo"
                    />
                  </div>
                  <h2 className="font-weight600">
                    AGNITOR, A NEW MODEL FOR CROWDSOURCING
                  </h2>
                  <NavLink to={`/agnitor/`} className="btn btn-secondary">
                    Visit AGNITOR.com <i className="fa fa-long-arrow-right" />
                  </NavLink>
                </div>
              </div>
              <div className="col-4 mid">
                <figure>
                  <img src={`/img/voc.jpg`} alt="" />
                </figure>
                <div className="content-block">
                  <NavLink to="https://www.youtube.com/watch?v=51z4MUG-JC8">
                    <i className="fa fa-play-circle" aria-hidden="true" />
                  </NavLink>
                  <h2 className="font-weight600">
                    YouCaring Director of Engineering on Expertise Needed to
                    Power Largest Crowdfunding Effort Ever.
                  </h2>
                </div>
              </div>
              <div className="col-4 right">
                <figure>
                  <img src={`/img/exp-crowd-breaker-right-img.jpg`} alt="" />
                </figure>
                <div className="content-block">
                  <div className="logo">
                    <img
                      src={`/img/svg/icons/customer-now-logo-white.svg`}
                      alt="CustomerNow logo"
                    />
                  </div>
                  <h2 className="font-weight600">
                    CUSTOMER NOW, DEMOCRATIZING THE EXPERIENCE THROUGH COMMUNITY
                    OF CROWDS.
                  </h2>
                  <NavLink
                    to="#customernow"
                    // to={`/experience-crowd/#customernow`}
                    onClick={() => scrollToSection("customernow")}
                    className="btn btn-secondary"
                  >
                    Visit CustomerNow.com{" "}
                    <i className="fa fa-long-arrow-right" />
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="community-crowd">
            <h2 className="section-main-heading">
              COMMUNITY CROWD <span className="highlight">VS</span> EXPERIENCE
              CROWD
            </h2>
            <span className="section-desc">
              Real crowds need a digital medium to express their feelings,
              emotions, and consume experiences without being controlled by the
              others.
            </span>
            <div className="row page-wrap community">
              <div className="col-6">
                <p>
                  We have dozens of community crowds like Wikipedia, Glassdoor,
                  Quora, Apple communities, DevianArt, Goodreads, etc. For that
                  matter, Google search links you to the thousands of self-help
                  pages and content.
                </p>
              </div>
              <div className="col-6">
                <figure>
                  <img src={`/img/svg/community-crowd-img.svg`} alt="" />
                </figure>
              </div>
            </div>
            <div className="row page-wrap table">
              <div className="col-7">
                <div className="row">
                  <div className="col-3 item">
                    <h3>Crowd Testing</h3>
                    <ul>
                      <li>
                        <i className="fa fa-check" />
                        Applause
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Testbirds
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Digivante
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Global App Testing
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Rainforest
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Test IO
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Testlio
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Ubertesters
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        We-Test
                      </li>
                    </ul>
                  </div>
                  <div className="col-3 item">
                    <h3>Security</h3>
                    <ul>
                      <li>
                        <i className="fa fa-check" />
                        Bugcrowd
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Cobalt.io
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        HackerOne
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        SafeHats
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Synack
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        YesWeHack
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Zerocopter
                      </li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                    </ul>
                  </div>
                  <div className="col-3 item">
                    <h3>Data Science</h3>
                    <ul>
                      <li>
                        <i className="fa fa-check" />
                        Playment
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Experfy
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Alegion
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        AWS
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Appen-Figure Eight
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Kaggle
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        TwentyBN
                      </li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                    </ul>
                  </div>
                  <div className="col-3 item">
                    <h3>Multiple Areas</h3>
                    <ul>
                      <li>
                        <i className="fa fa-check" />
                        Topcoder
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Koder
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        CrowdWorx
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Passbrains
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Upwork
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Gigster
                      </li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-4-half">
                <p>
                  The foundation of Community Crowd is OPEN where communities
                  can create FREE accounts and start engaging with the brands
                  and peer groups; solving some of their issues through
                  self-service, knowledge base, peer help like forums or simply
                  consuming content and learning new perspectives.
                </p>
              </div>
            </div>
            <div className="row page-wrap foundation">
              <div className="col-6">
                <figure>
                  <img src={`/img/svg/exp-crowd-foundation-img.svg`} alt="" />
                </figure>
              </div>
              <div className="col-6">
                <p>
                  The foundation of the Experience Crowd is OPEN where a
                  “select” community of people — professionals, customers,
                  employees (with a linkage) can open an account (accounts need
                  to be verified by the brands) to participate and harness the
                  benefits of crowdsourcing.
                </p>
                <p>
                  We are building on ExperienceCrowd Platform to empower the
                  large brands with democratized Customer Experience (CX)
                  Platform — Customer Now; Expertise Crowd Network of Global
                  professionals in a specialized field — Agnitor; Top coders
                  community of software developers — Opencoder; and Data crowd
                  by DATI.
                </p>
              </div>
            </div>
          </section>
          <section className="opencoder-container grey-bg" id="opencoder">
            <div className="logo-header">
              <figure>
                <img
                  src={`/img/svg/icons/open-coder-logo.svg`}
                  alt="Opencoder logo"
                />
              </figure>
              <div className="txt">
                It's an <br /> Experience Crowd
              </div>
            </div>
            <span className="section-desc">
              At Opencoder, we work with the top-notch talent in software
              development, engineering, testing, digital marketing, and
              web-designing amongst other fields to crowdsource their work and
              deliver to the end customers. Opencoder is currently in its final
              stages of launch.
            </span>
            <div className="row page-wrap">
              <div className="col-4">
                <figure>
                  <img src={`/img/svg/opencoder-developers-img.svg`} alt="" />
                </figure>
              </div>
              <div className="col-7 items-container">
                <div className="item">
                  <figure>
                    <img src={`/img/svg/icons/software-developer.svg`} alt="" />
                  </figure>
                  <div className="txt">
                    <h3>Software Developers</h3>
                    <p>
                      Development teams with experience in Java, .net, python,
                      Ruby on rails, and other languages can join Opencoder.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <figure>
                    <img src={`/img/svg/icons/packaged-software.svg`} alt="" />
                  </figure>
                  <div className="txt">
                    <h3>Packaged Software</h3>
                    <p>
                      Packaged software implementation team with expertise in
                      Salesforce, Oracle CX, Magento, Drupal, Moodle etc.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <figure>
                    <img src={`/img/svg/icons/quality-testing.svg`} alt="" />
                  </figure>
                  <div className="txt">
                    <h3>Quality Testing</h3>
                    <p>
                      Crowdsourced testing for business applications, websites,
                      and other software products jobs are in demand.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <figure>
                    <img src={`/img/svg/icons/data-science.svg`} alt="" />
                  </figure>
                  <div className="txt">
                    <h3>New Skills like Data Science</h3>
                    <p>
                      People proficient in AI, Datascience, Machine Learning,
                      and other cutting edge technologies can join to be part of
                      Opencoder.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="customernow-container" id="customernow">
            <div className="logo-header">
              <figure>
                <img
                  src={`/img/svg/icons/customer-now-logo.svg`}
                  alt="CustomerNow logo"
                />
              </figure>
              <div className="txt">
                It's an <br /> Experience Crowd
              </div>
            </div>
            <span className="section-desc">
              CustomerNow is a Web Self-Service Knowledge Management tool for
              the enterprises to implement and integrate with their CRM systems.
            </span>
            <div className="row page-wrap">
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/zero-contract-resolution-img.svg`}
                    alt="Zero Contact Resolution (ZCR)"
                  />
                </figure>
                <h3 className="font-weight600">
                  Zero Contact Resolution (ZCR)
                </h3>
                <p>
                  It’s challenging to manage increased Service calls and email
                  volume when “cost of servicing” outstrips the budgets. Brands
                  can leverage CustomerNow Crowd support by using peers and
                  offer these peers some credits to buy additional services.
                </p>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/customer-idea-board-img.svg`}
                    alt="Customer IDEA Board"
                  />
                </figure>
                <h3 className="font-weight600">Customer IDEA Board</h3>
                <p>
                  Customers regularly give recommendations, feedback, and ideas
                  to improve products or seek new experiences from the Brand.
                  CustomerNow offers the IDEA framework by extending to the
                  crowd.
                </p>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/customer-self-service-img.svg`}
                    alt="Customer Self-Service"
                  />
                </figure>
                <h3 className="font-weight600">Customer Self-Service</h3>
                <p>
                  CustomerNow offers powerful Self-Service, Multi-experience
                  interfaces, and Mobile Ready AI-Self-Search capabilities to
                  solve some of the issues related to product use, warranty,
                  upgrades, support, etc.
                </p>
              </div>
            </div>
            <div className="row page-wrap mobile">
              <div className="col-12">
                <figure>
                  <img src={`/img/svg/mobile-arrows.svg`} alt="" />
                </figure>
                <figure>
                  <img
                    src={`/img/svg/experience-crowd-search-img.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="dati-container light-blue-bg" id="dati">
            <div className="logo-header">
              <figure>
                <img src={`/img/svg/icons/dati-logo.svg`} alt="Dati logo" />
              </figure>
              <div className="txt">
                It's an <br /> Experience Crowd
              </div>
            </div>
            <span className="section-desc">
              Dati — the data crowd and the Insight Crowd Practice, part of
              Virtuos Experience Crowd business.
            </span>
            <div className="row page-wrap">
              <div className="col-5">
                <figure>
                  <img src={`/img/svg/data-crowd-img.svg`} alt="Data Crowd" />
                </figure>
              </div>
              <div className="col-6 content-block">
                <h3 className="font-weight600">DATI: DATA CROWD</h3>
                <span>
                  Provide differentiating data to unlock unique insights.
                </span>
                <p id="margin1">
                  Differentiation lies in the ability to stand out in the crowd.
                  That requires unique insights into customers and business
                  context. To find that nugget, 56% of global data and analytics
                  decision makers are expanding their use of external data as
                  per Forrester Report.
                </p>
                <p>
                  At Virtuos DATI, we deliver differentiated data offerings
                  using “Experience Crowd” extended network with highest
                  compliance of GDPR.
                </p>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-6 content-block">
                <h3 className="font-weight600">DATI: INSIGHT CROWD</h3>
                <span>
                  Deliver insights through both automation and decision support.
                </span>
                <p id="margin1">
                  Recognizing that insights alone won't move the needle,
                  insights service engagement must ensure that decision makers
                  apply insights or embed them in automated processes.
                </p>
                <p>
                  At Virtuos we offer a range of options, from basic dashboards
                  to AI-powered chatbots. Interactive decision support tools
                  ensure that decision makers can evaluate alternative actions.
                  In some cases, full-scale digital twins enable extensive
                  scenario planning.
                </p>
              </div>
              <div className="col-5">
                <figure>
                  <img
                    src={`/img/svg/insight-crowd-img.svg`}
                    alt="Insight Crowd"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="democrate-container">
            <h2 className="section-main-heading">
              DEMOCRATIZING <span className="highlight">THE EXPERIENCE</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-9 left-block">
                <p>
                  With thousands of applications flooding the markets and most
                  of them not having the power of community, crowdsourcing,
                  openness, and further becoming unreachable to millions of
                  businesses, there's a room for the Experience Crowd to take a
                  center stage.
                </p>
                <p>
                  At Virtuos, we want to rid the world of bad experiences by
                  creating a democratized experience ecosystems and open
                  platforms. Experience Crowd is a beginning in that journey.
                </p>
              </div>
              <div className="col-3 right-block">
                <span className="title1">
                  Bolster your competitive advantages with Virtuos
                </span>
                <span className="title2">
                  Be a mastermind, <br />a Virtuoso!
                </span>
                <NavLink to={`/contact/`}>Contact Us</NavLink>
              </div>
            </div>
          </section>
          {/*?php include '../footer-xonomy.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Expereince_Crowd;
