import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Attends_Rightnow_User_Conference = () => {
    useEffect(() => {
        document.title = "Virtuos attends RightNow User Conference in Melbourne, Australia – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-attends-rightnow-user-conference-in-melbourne-australia-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Melbourne - 15 November, 2010</span>
                                <h2 className="article-heading">
                                    Virtuos attends RightNow User Conference in Melbourne, Australia
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos attends RightNow User Conference for the second time to
                                    connect and engage with key customers and partners across the APAC
                                    region.
                                </p>
                                <p>
                                    Amarinder Singh, the founder employee member no #1 of Virtuos, has
                                    attended the User Conference held in Melbourne, Australia.
                                </p>
                                <p>
                                    “I have connected with the senior leadership team at RightNow and Made
                                    many friends across the region.” said Amarinder Singh, Solution
                                    Manager. “The first event since I assumed the role at Virtuos has been
                                    a fascinating experience.”
                                </p>
                                <p>
                                    The three-day conference covered the modern stack of RightNow RightNow
                                    Technologies, such as Intent Engine, addition, and Advanced Analytics.{" "}
                                </p>
                                <p>
                                    Later Amarinder Singh also visited RightNow Office and stayed in
                                    Sydney for about two months to learn new technologies and understand
                                    RightNow in depth.
                                </p>
                                <h3 className="title">About RightNow Technologies</h3>
                                <p>
                                    RightNow Technologies, Inc. (Nasdaq: RNOW) provides customer
                                    relationship management software and services. The Company offers
                                    solutions that use self-learning knowledge-base to deliver customer
                                    self-service, email management, live interaction, issue tracking, and
                                    customer satisfaction measurement capabilities.
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">07/12/2000</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            136 Enterprise Blvd Bozeman, <br />
                                            MT 59718 United States
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Phone</span>
                                        <span className="txt">1-406-522-4200</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink
                                                to="http://www.rightnow.com"
                                                target="_blank"
                                                className="link"
                                            >
                                                www.rightnow.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">1000-2000</span>
                                    </div>
                                </div>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based in
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book — Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three essential needs
                                    of growing businesses — Sales, Customer Service, and Marketing.
                                    Virtuos has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has been fiercely growing in the space of CRM, Service Cloud
                                    and Cloud Computing space by delivering the cutting edge solutions to
                                    leading brands like MakeMyTrip, GE-Healthcare, Standard Chartered
                                    Bank, Party Gaming, Fundtech amongst other companies. For more
                                    information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Attends_Rightnow_User_Conference