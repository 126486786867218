import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_Veracis_Launches_Cxdesk_Expedience = () => {
  useEffect(() => {
    document.title =
      "Virtuos Veracis launches CXDesk 2.0 and Expedience Talent Advisory – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-executed-several-crm-clm-deals-img-big.jpg`}
                  alt="Virtuos Veracis launches CXDesk® and Expedience® Customer Management Operations
"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram, 2 April 2022</span>
                <h2 className="article-heading">
                  Virtuos Veracis launches CXDesk<sub>®</sub> and Expedience
                  <sub>®</sub> Customer Management Operations
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos has executed several CRM and Customer Lifecycle
                  Management (CLM) deals from some of the iconic brands in the
                  APAC and EMEA region. A leading Financial Services Company
                  based out of UAE with its Indian origins has expanded the CRM
                  portfolio to its partner network contributing to new revenue
                  in the FY22.
                </p>
                <p>
                  China-based leading electric power generation company with its
                  headquarters in Hong Kong has received our CRM Professional
                  Services using Low Code No Code Platform. A leading
                  Educational Institute has renewed its Licensing and
                  Professional Services contract this year. Our Customer Success
                  Team has worked hard in retaining almost all the customers in
                  FY22 by ensuring that our customers are hugely successful with
                  our CXDesk Services.
                </p>
                <p>
                  After the tremendous success of launching Virtuos Vivacis,
                  Virtuos Vedam and Virtuos Vaves, now Virtuos launched Veracis
                  Customer Experience Trust Operations. CXDesk Operations will
                  be grouped into this new business unit to offer end to end
                  Customer Management Operations. The CXDesk 2.0 will have
                  additional services such as
                </p>
                <ul>
                  <li>
                    <strong>AXDesk</strong> — Automation Experience Desk
                    offering "Automation As An Experience Service."
                  </li>
                  <li>
                    <strong>BXDesk</strong> — Brand Experience Desk offering
                    brand analytics, SEO, and Marketing Services to customers
                    who implemented CRM Marketing Cloud or Commerce Cloud.
                  </li>
                  <li>
                    <strong>LXDesk</strong> — Learning Experience Desk offers
                    Learning &amp; Development Services to CRM/CLM and Other
                    customers.
                  </li>
                  <li>
                    <strong>PXDesk</strong> — Product Experience Desk to offer
                    Product Development &amp; Engineering Services As A
                    Subscription.
                  </li>
                  <li>
                    <strong>QXDesk</strong> — Quality Assurance (QA) and Testing
                    Services will be provided as a service.
                  </li>
                </ul>
                <p>
                  "Virtuos Veracis will be launching Expedience Advisory
                  Services by including Talentare in June 2022. Expedience is
                  our new business bridging the gaps between talent supply &amp;
                  demand and offering seamless Customer Experience Technologies,
                  Services and Experiences aligned with our purpose—Venky Vijay
                  Reddi, the ExperienCEO said. Expedience will also offer
                  Professional Employer Organization (PEO) services and state of
                  the art W.Digital Workforce Management Services."
                </p>
                <p>
                  Virtuos Veracis will be rolling out FreshCX Customer
                  Management Services in November 2022 and O.Digital Operations.
                  We aim to create Virtuos Veracis as an essential arm of
                  Virtuos, complementing Vaves CXNow Digital Transformation;
                  Vivacis Digital Automation; Vedam Digital Marketing &amp;
                  Commerce; Vardei IT Management.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. is a leading Experience Consultancy and
                  Digital Transformist with its global operations across US, UK,
                  Europe, Middle East and India. Virtuos has four important
                  business operations, namely{" "}
                  <NavLink to={`/consultare/`} target="_blank" className="link">
                    Consultare;
                  </NavLink>
                  <NavLink to={`/xonomy/`} target="_blank" className="link">
                    Xonomy
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to={`/experience-cloud/`}
                    target="_blank"
                    className="link"
                  >
                    Experience Cloud
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Microsoft Dynamics, Creatio Low Code No Code
                  Platform &amp; CRM Applications; Agiloft Contract Lifecycle
                  Management (CLM) amongst other Composable Products &amp;
                  Services. Virtuos Consulting services include Five Fabulous
                  business units namely — Virtuos Vivacis, Virtuos Veracis,
                  Virtuos Vaves, Virtuos Vedam, and Virtuos Vardei.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/xonomy/`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates, incubates and invests in the next-generation
                  businesses harnessing Artificial Intelligence (AI), Cloud,
                  ECommerce, Blockchain and Digital technologies. At Virtuos
                  Xonomy, we manage new ventures that include VYO — the
                  Metaverse Experiences; Giftcart — Gifting Experiences Commerce
                  Company; Indic — the Portfolio of Experience Commerce Brands;
                  Sweven — Salesforce CRM Mastermind services; and Tekcorp — the
                  Technology Infrastructure and upcoming Tek.shop eCommerce
                  Operations.
                </p>
                <h3 className="title">Virtuos Experience Cloud</h3>
                <p>
                  Virtuos Experience Cloud Business is creating a brand new ABCD
                  Strategy to launch modern Experience Applications stack. We
                  are building AURY, a state of the art AI based Chatbot and
                  AR/VR Systems; Brandcart.com — Experience Commerce Cloud;
                  Cloudle — the lowcode no code platform; and Definite — the
                  Digital Experience Platform (DXP) with integrated Digital
                  Marketing Suite.
                </p>
                <p>
                  For more information visit{" "}
                  <NavLink
                    to="http://www.virtuos.com"
                    target="_blank"
                    className="link"
                  >
                    www.virtuos.com
                  </NavLink>{" "}
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Veracis_Launches_Cxdesk_Expedience;
