import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Customer_Journey = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "CJourney | Customer Journey Mapping Services | CX.Digital Transformation – Virtuos Digital";
    }, []);
    return (
        <div className="cjourney-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <section className="top-hero-product-banner image-title-layout">
                        <div className="product-hero-wrapper">
                            <div className="row page-wrap">
                                <div className="col-5 item-wrapper">
                                    <figure>
                                        <img
                                            src={`/img/svg/icons/cjourney-logo.svg`}
                                            alt="C Journey Logo"
                                        />
                                    </figure>
                                    <h3 className="section-heading">
                                        Customer Journey Mapping <br /> Framework.
                                    </h3>
                                </div>
                                <div className="col-7 consumer-cycle">
                                    <figure>
                                        <img
                                            src={`/img/svg/consumer-cycle.svg`}
                                            alt="C Journey Logo"
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="breadcrumb-kanaban">
                        <div className="page-wrap breadcrumb-wrapper">
                            <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                <li>
                                    <NavLink to={`/cxnow/`}>CXNow Program</NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        className="active"
                                    >
                                        Customer Journey
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxaudit/`}>CX Audit</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxprism/`}>CX PRISM</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/customer-voice/`}>
                                        CUSTOMER VOICE
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/cxnow/cxunity/`}>CX UNITY</NavLink>
                                </li>

                            </ul>

                            <ul className="more-hidden-tabs">
                                <li
                                    className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <i className="fal fa-plus" />  More
                                    {showExtraLinks && (
                                        <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                            <li>
                                                <NavLink to={`/cxnow/cxprimer/`}>CX PRIMER</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxopia/`}>CXOPIA</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxpyramid/`}>CX PYRAMID</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxnow/cxdatum/`}>CX DATUM</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/cxdesk/`}>CXDesk</NavLink>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="cjourney-wrapper">
                        <h2 className="section-main-heading">
                            Customer <span className="highlight">Journey Mapping</span>
                        </h2>
                        <span className="section-desc">
                            There's a significant shift in customer engagement towards autonomous AI
                            and human blended Customer Journey. CJourney, part of our flagship{" "}
                            <NavLink to={`/cxnow`} className="link">
                                CXNow
                            </NavLink>{" "}
                            Transformation Program leverages AI conversational rich Self-Service
                            Platforms and a mix of digital technologies to aggrandize the aspirations
                            of the customer throughout the journey.
                        </span>
                    </section>
                    <section className="process-steps-section">
                        <h2 className="section-main-heading">
                            Start with <span className="highlight">"WHO"</span>
                        </h2>
                        <span className="section-desc">
                            Create Personas to Illuminate and Inform the Customer Experience by Using
                            Data from Six Sources.
                        </span>
                        <div className="page-wrap process-steps-wrapper">
                            <div className="row">
                                <div className="col-4 item-wrapper offport-down">
                                    <div className="item">
                                        <div className="image-wrapper">
                                            <figure>
                                                <img
                                                    src={`/img/demographic-img.jpg`}
                                                    alt='Demographic""'
                                                />
                                            </figure>
                                            <strong className="index">01</strong>
                                        </div>
                                        <h3 className="section-heading">Demographic</h3>
                                        <p>
                                            The basic structure of a population based on geography, income,
                                            level of education, and other standard descriptive attributes.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 item-wrapper offport-down">
                                    <div className="item">
                                        <div className="image-wrapper">
                                            <figure>
                                                <img
                                                    src={`/img/psychographic-img.jpg`}
                                                    alt="Psychographic"
                                                />
                                            </figure>
                                            <strong className="index">02</strong>
                                        </div>
                                        <h3 className="section-heading">Psychographic</h3>
                                        <p>
                                            Your customers' values, opinions, interests, aspirations,
                                            attitudes, and lifestyles.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 item-wrapper offport-down">
                                    <div className="item">
                                        <div className="image-wrapper">
                                            <figure>
                                                <img
                                                    src={`/img/ethnographic-img.jpg`}
                                                    alt="Ethnographic"
                                                />
                                            </figure>
                                            <strong className="index">03</strong>
                                        </div>
                                        <h3 className="section-heading">Ethnographic</h3>
                                        <p>
                                            Participatory observation where insights are gleaned by watching
                                            subjects in their daily routines and capturing what isn't
                                            explicitly reported.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 item-wrapper offport-down">
                                    <div className="item">
                                        <div className="image-wrapper">
                                            <figure>
                                                <img
                                                    src={`/img/technographic-img.jpg`}
                                                    alt="Technographic"
                                                />
                                            </figure>
                                            <strong className="index">04</strong>
                                        </div>
                                        <h3 className="section-heading">Technographic</h3>
                                        <p>
                                            How your customers use and approach technology. This includes the
                                            use of digital mobile shifts.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 item-wrapper offport-down">
                                    <div className="item">
                                        <div className="image-wrapper">
                                            <figure>
                                                <img
                                                    src={`/img/transactional-img.jpg`}
                                                    alt="Transactional"
                                                />
                                            </figure>
                                            <strong className="index">05</strong>
                                        </div>
                                        <h3 className="section-heading">Transactional</h3>
                                        <p>
                                            Insights through a historical customer relationship, including
                                            first- and third party purchase histories and post-sale service
                                            records.{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4 item-wrapper offport-down">
                                    <div className="item">
                                        <div className="image-wrapper">
                                            <figure>
                                                <img
                                                    src={`/img/behavioral-img.jpg`}
                                                    alt="Behavioral"
                                                />
                                            </figure>
                                            <strong className="index">06</strong>
                                        </div>
                                        <h3 className="section-heading">Behavioral</h3>
                                        <p>
                                            Data captured passively through engagement with websites, mobile
                                            devices and other media, content, and channels.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="light-blue-bg advantages-of-aury ecosystem-section">
                        <h2 className="section-main-heading">
                            Identify <span className="highlight">"When"</span>
                        </h2>
                        <span className="section-desc">
                            Map Personas to Key Moments in the Customer Journey.
                        </span>
                        <div className="page-wrap row gen">
                            <div className="col-6 offport-left">
                                <figure>
                                    <img
                                        src={`/img/svg/identify-when-img.svg`}
                                        alt="Journey Moments"
                                    />
                                </figure>
                            </div>
                            <div className="col-6 points offport-right">
                                <p>
                                    Discover the journey moments, don’t’ prescribe. Identify pivotal
                                    moments during buy-own-advocate stages (see figure), where brands to
                                    need to understand the behaviors, preferences, media consumption
                                    habits, technology adoption patterns.
                                </p>
                                <p>
                                    Work with Virtuos teams to create CX Primer workshop for detailed
                                    "day-in-the-life" routines of your audiences, and how you can begin
                                    designing a journey map that becomes the backbone of your CX
                                    architecture.{" "}
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="next-steps-wrapper">
                        <h2 className="section-main-heading">
                            Take Inventory of <span className="highlight">"What"</span>
                        </h2>
                        <span className="section-desc">
                            Define the Stories, Services, and Experiences That Engage and Delight.
                        </span>
                        <div className="page-wrap row offport-down">
                            <div className="col-4 item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/customer-journey-stories-icon.svg`}
                                        alt="Stories"
                                    />
                                </figure>
                                <h4>Stories</h4>
                                <p>
                                    The information and content audiences desire in each specific step to
                                    make decisions, gain confidence, and engage with the brand in
                                    contextually relevant ways.
                                </p>
                            </div>
                            <div className="col-4 item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/customer-journey-services-icon.svg`}
                                        alt="Services"
                                    />
                                </figure>
                                <h4>Services</h4>
                                <p>
                                    Capabilities designed to add convenience, reduce friction and
                                    elegantly fulfill needs.
                                </p>
                            </div>
                            <div className="col-4 item">
                                <figure>
                                    <img
                                        src={`/img/svg/icons/customer-journey-experiences-icon.svg`}
                                        alt="Experiences"
                                    />
                                </figure>
                                <h4>Experiences</h4>
                                <p>
                                    How key moments are tightly orchestrated over the longer arc of
                                    engagement to drive loyalty and advocacy.
                                </p>
                            </div>
                        </div>
                        <div className="page-wrap left-border offport-down">
                            <p>
                                Map customer personas to moments to create a CX inventory that becomes
                                the work plan for your{" "}
                                <NavLink to={`/cx/strategy-design`} className="link">
                                    CX Strategy
                                </NavLink>{" "}
                                — the collection of investments you'll consider, justify, and prioritize
                                as part of your CX transformation. Identify problem areas through{" "}
                                <NavLink to={`/cxnow/cxprimer`} className="link">
                                    CX Primer
                                </NavLink>{" "}
                                session, and fix few of the urgent and pressing issues for quick gains
                                to show ROI on your investments to stakeholders.
                            </p>
                        </div>
                    </section>
                    <section className="digital-human-wrapper">
                        <div className="page-wrap row">
                            <div className="col-8 offport-left">
                                How to apply Digital Humanism To CX Design?
                            </div>
                            <div className="col-4 offport-right">
                                <NavLink className="btn btn-primary" to={`/contact`}>
                                    Contact Us
                                </NavLink>
                            </div>
                        </div>
                    </section>
                    <section className="light-blue-bg advantages-of-aury ecosystem-section">
                        <h2 className="section-main-heading">
                            Solve for the <span className="highlight">"How"</span>
                        </h2>
                        <span className="section-desc">
                            Identify Data, People, Processes, and Analytics to Enable and Optimize
                            Experiences
                        </span>
                        <div className="page-wrap row how">
                            <div className="col-6 offport-left">
                                <figure>
                                    <img
                                        src={`/img/svg/how-img.svg`}
                                        alt="Optimize Experiences"
                                    />
                                </figure>
                            </div>
                            <div className="col-6 points offport-right">
                                <p>
                                    Once you've defined the what, you need to address the how, start
                                    mapping the stories, services, and experiences you've designed during
                                    the CX Primer Workshop (See the previous step). Take the help of CX
                                    Prism Design Thinking techniques and our I.D.E.A framework to
                                    orchestrate paths to disrupt, and think differently — this could be a
                                    consideration of digital channels or traditional touchpoints such as
                                    call center or retail point of sale (POS).
                                </p>
                            </div>
                        </div>
                        <div className="page-wrap left-border">
                            <p>
                                Our CJourney Framework provides a composite view of CX strategists,
                                Marketers, and Experience Designers, Industry experts, and learnings
                                from Hackathon X from millennials to offer a cross-functional view of
                                CXOpia — the state of customer experience.
                            </p>
                        </div>
                    </section>
                    <section className="customer-voice-wrapper">
                        <h2 className="section-main-heading">
                            Next Steps in <span className="highlight">Customer Journey</span>
                        </h2>
                        <span className="section-desc">
                            Top recommendations for creating customer journey mapping.
                        </span>
                        <div className="page-wrap row item-container">
                            <div className="col-5-half item offport-left">
                                <div className="col-5">
                                    <figure>
                                        <img
                                            src={`/img/conduct-cx-aware-session-img.jpg`}
                                            alt="Conduct CX Aware Session"
                                        />
                                    </figure>
                                </div>
                                <div className="col-7">
                                    <h4>
                                        <NavLink to={`/cxnow/cxprimer`}>
                                            Conduct "CX Aware" Session{" "}
                                            <i className="fal fa-long-arrow-right" />
                                        </NavLink>
                                    </h4>
                                    <p>
                                        Invite cross-functional teams from Product, Marketing, Operations,
                                        Service departments to conduct{" "}
                                        <NavLink to={`/cxnow/cxprimer`} className="link">
                                            CX Primer
                                        </NavLink>
                                        session followed by{" "}
                                        <NavLink to={`/cxnow/cxaudit`} className="link">
                                            CX Audit
                                        </NavLink>{" "}
                                        to discover grey areas that need attention.
                                    </p>
                                </div>
                            </div>
                            <div className="col-5-half item offport-right">
                                <div className="col-5">
                                    <figure>
                                        <img
                                            src={`/img/continuously-measure-img.jpg`}
                                            alt="Measure CX.Digital Maturity using Indicial"
                                        />
                                    </figure>
                                </div>
                                <div className="col-7">
                                    <h4>
                                        <NavLink to={`/cxdesk`}>
                                            Continuously Measure and Revalidate{" "}
                                            <i className="fal fa-long-arrow-right" />
                                        </NavLink>
                                    </h4>
                                    <p>
                                        Optimize initial Experience Design assumptions by taking CX as a
                                        Program — not a project. If required consult with Virtuos teams to
                                        measure CX.Digital Maturity using{" "}
                                        <NavLink to={`/indicial/`}>Indicial</NavLink>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*?php include '../../footer-consultare.php';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Customer_Journey
