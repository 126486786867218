import React, { useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../src/style/autosliders.css";

const Zidd = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    document.title =
      "The Digital Dexterity Platform for E-Learning New Skills - Virtuos ZIDD";

    const handleScroll = (event) => {
      const { documentElement, body } = document;
      const { clientHeight, scrollHeight, scrollTop } = documentElement || body;
      const delta = event.deltaY;

      const scrollThreshold = 0.8; // Adjust this value to control when the slide changes

      if (
        delta > 0 &&
        scrollTop + clientHeight >= scrollHeight * scrollThreshold
      ) {
        // Scrolling down and reached the threshold
        sliderRef.current.slickNext();
      } else if (
        delta < 0 &&
        scrollTop <= clientHeight * (1 - scrollThreshold)
      ) {
        // Scrolling up and reached the threshold
        sliderRef.current.slickPrev();
      }
    };

    window.addEventListener("wheel", handleScroll);

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };

  return (
    <div className="cx-digital-page xx-digital-page sub-home-page listing-page">
      <div className="canva">
        <Navbar />
      </div>
      <main className="main-wrapper">
        <div className="slides">
          <Slider {...settings} ref={sliderRef}>
            <div className="sliderdiv">
              <Element name="virtuosoship">
                <section className="vertical-section" id="virtuosoship">
                  <div className="full-screen-wrapper">
                    <figure>
                      <img
                        src={`/img/virtuosoship-means-being-virtuoso-img.jpg`}
                        alt="Virtuosoship means being Virtuoso"
                      />
                    </figure>
                    <div className="right-logo-block">
                      <figure>
                        <img
                          src={`/img/svg/icons/virtuosoship-logo-white.svg`}
                          alt="Virtuosoship"
                        />
                      </figure>
                    </div>
                    <div className="center-wrapper">
                      <h2 className="section-main-heading">
                        <span className="italic">
                          Virtuosoship means being{" "}
                          <strong>
                            Virtuoso, Skilled, Heuristic, Innovative
                          </strong>{" "}
                          and <strong>Productive</strong>
                        </span>
                      </h2>
                      <p>
                        Experience Economy is possible by creating Virtuoso
                        Economy that is full of talented and highly skilled
                        people.
                      </p>
                      <NavLink
                        to={`/virtuosoship/`}
                        className="btn btn-secondary"
                      >
                        Explore <i className="fa fa-long-arrow-right" />
                      </NavLink>
                      <span className="blinking-down-arrow">
                        Scroll Down
                        <i className="fal fa-angle-down arrow" />
                      </span>
                    </div>
                  </div>
                </section>
              </Element>
            </div>
            <div>
              <Element name="zidd">
                <section className="vertical-section" id="zidd">
                  <div className="full-screen-wrapper">
                    <figure>
                      <img
                        src={`/img/zidd-digital-dexterity-platform-img.jpg`}
                        alt="Zidd.com : The Digital Dexterity Platform"
                      />
                    </figure>
                    <div className="center-wrapper">
                      <h2 className="section-main-heading">
                        <span className="italic">Zidd.com</span>:{" "}
                        <strong>The Digital Dexterity Platform</strong> for the
                        Life-long learning of New Skills
                      </h2>
                      <p>
                        Skillshift for the future with the coaching from the
                        experts and guided learning path with milestones.
                      </p>
                      <NavLink
                        to="http://www.zidd.com"
                        className="btn btn-secondary"
                      >
                        ZIDD IS LAUNCHING IN NOV 2020
                      </NavLink>
                      <span className="blinking-down-arrow">
                        Scroll Down
                        <i className="fal fa-angle-down arrow" />
                      </span>
                    </div>
                  </div>
                </section>
              </Element>
            </div>
            <div>
              <Element name="traditional">
                <section className="vertical-section" id="traditional">
                  <div className="full-screen-wrapper">
                    <figure>
                      <img
                        src={`/img/traditional-education-job-img.jpg`}
                        alt="Traditional Education and Traditional Jobs are dead"
                      />
                    </figure>
                    <div className="center-wrapper">
                      <h2 className="section-main-heading">
                        <span className="italic">
                          Traditional education and traditional job
                        </span>{" "}
                        are nearly dead.{" "}
                        <strong>You are about to be disrupted</strong>.
                      </h2>
                      <p>
                        There will be a new demand for workforce with new skills
                        on Automation, and Artificial Intelligence (AI).
                      </p>
                      <NavLink
                        to="https://www.virtuos.com/downloads/skill-shift-automation-and-future-of-the-workforce.pdf"
                        target="_blank"
                        className="btn btn-secondary"
                      >
                        Download McKinsey Report
                      </NavLink>
                      <span className="blinking-down-arrow">
                        Scroll Down
                        <i className="fal fa-angle-down arrow" />
                      </span>
                    </div>
                  </div>
                </section>
              </Element>
            </div>
            <div>
              <Element name="experience-jobs">
                <section className="vertical-section" id="experience-jobs">
                  <div className="full-screen-wrapper">
                    <figure>
                      <img
                        src={`/img/experience-jobs-img.jpg`}
                        alt="Experience Jobs"
                      />
                    </figure>
                    <div className="center-wrapper">
                      <h2 className="section-main-heading">
                        Creating <span className="italic">Experience Job</span>{" "}
                        is not an option, it's necessity to thrive in the{" "}
                        <strong>experience era</strong>
                      </h2>
                      <p>
                        Learn design thinking principles and hone your skills to
                        deliver superior customer experience. Learn more about{" "}
                        <NavLink
                          to="http://www.experiencejob.com"
                          target="_blank"
                          className="white-link"
                        >
                          Experiencejob
                        </NavLink>
                        .
                      </p>
                      <span className="blinking-down-arrow">
                        Scroll Up
                        <i className="fal fa-angle-up arrow" />
                      </span>
                    </div>
                  </div>
                </section>
              </Element>
            </div>
          </Slider>
        </div>
      </main>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Zidd;
