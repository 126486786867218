import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { NavLink } from "react-router-dom";
import CountUp from "react-countup";
import Download from "./Form/Download";
const VivacisDpa = () => {
  useEffect(() => {
    document.title =
      "BPM, Digital Process Automation and Customer Experience (CX) – Virtuos Vivacis";
  }, []);
  return (
    <div class="bpm-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/svg/creatio-header-bg.svg`}
                alt="Digital Process Automation (DPA)"
              />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5 item-wrapper">
                  <h3 className="section-heading">
                    Digital Process Automation (DPA)
                  </h3>
                  <p>Powering CRM and Customer Experience</p>
                </div>
                <div className="col-7">
                  <figure>
                    <img
                      src={`/img/svg/creatio-header-img.svg`}
                      alt="CRM and Customer Experience"
                    />
                  </figure>
                </div>
                <div className="right-fixed-logo">
                  <img
                    src={`/img/svg/icons/vivacis-logo.svg`}
                    alt="Vivacis Logo"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/o-digital`}>
                    O.Digital
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/vivacis`}>
                    Vivacis
                  </NavLink>
                </li>
                <li>
                  <NavLink to="javascript:void(0);">DPA</NavLink>
                </li>
                <li>
                  <NavLink to="javascript:void(0);">RPA</NavLink>
                </li>
                <li>
                  <NavLink to="javascript:void(0);">CLM</NavLink>
                </li>
                <li>
                  <NavLink to="javascript:void(0);">
                    CPQ (Quote to Cash)
                  </NavLink>
                </li>
              </ul>
            </div>
          </section>
          <section className="block-4">
            <h2 className="section-main-heading">
              <span className="highlight">
                Business Process Management + CX
              </span>{" "}
              = Your Edge
            </h2>
            <span className="section-desc">
              Identify customer process gaps and what the competition is doing
              with your customer. It's time for BPM Leaders to shift from an
              inside-only view of a process that ignores external customers
              altogether or relegates them to a single undifferentiated swimlane
              in diagrams.
            </span>
            <span className="section-desc">
              <strong>
                Introducing Integrated BPM.CX Process Automation Suite — Creatio
                (formerly BPM'Online)
              </strong>
            </span>
            <div className="row page-wrap">
              <div className="col-3 item-wrapper offport-down">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/bpm.cx-studio-img.jpg`}
                      alt="BPM.CX Studio"
                    />
                  </figure>
                  <h3>BPM.CX Studio</h3>
                  <p>
                    CX Integrated Intelligent business process management, the
                    high productivity application with low-code platform comes
                    with hundreds of CX templates and accelerators.
                  </p>
                </div>
              </div>
              <div className="col-3 item-wrapper offport-down">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/bpm.cx-sales-img.jpg`}
                      alt="BPM.CX Sales"
                    />
                  </figure>
                  <h3>BPM.CX Sales</h3>
                  <p>
                    Insights-driven Sales Process Management pre-configured in
                    CRM Sales Application to empower teams to perform better by
                    delivering better CX during the entire sales cycle.
                  </p>
                </div>
              </div>
              <div className="col-3 item-wrapper offport-down">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/bpm.cx-service-img.jpg`}
                      alt="BPM.CX Service"
                    />
                  </figure>
                  <h3>BPM.CX Service</h3>
                  <p>
                    Business workflows mapped to processes intelligently for
                    delivering seamless customer service — billing, supply-chain
                    to incident management. Your CX is now without silos and
                    bottlenecks.
                  </p>
                </div>
              </div>
              <div className="col-3 item-wrapper offport-down">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/bpm.cx-marketing-img.jpg`}
                      alt="BPM.CX Marketing"
                    />
                  </figure>
                  <h3>BPM.CX Marketing</h3>
                  <p>
                    Transactional Marketing to multi-channel engagement with
                    options to integrate with Social, Community, and content
                    management to accelerate lead to revenue and optimize sales.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="row product-section aury-metrices light-blue-bg">
            <div className="page-wrap right-content inview" style={{marginBottom:"2.5em"}}>
              <div className="count-band">
                <div className="row">
                  <div className="col-4 item">
                    <div className="count-wrapper animate">
                      <i className=" bold-class count">
                        <CountUp
                          end={75}
                          duration={4}
                          style={{ fontSize: "3.5rem" }}
                        />
                      </i>
                      <i className="static-count bold-class">%</i>
                    </div>
                    <span>Low Code</span>
                  </div>
                  <div className="col-4 item">
                    <div className="count-wrapper animate">
                      <i className="count bold-class" count={14}>
                        <CountUp
                          end={14}
                          duration={4}
                          style={{ fontSize: "3.5rem" }}
                        />
                      </i>
                      <i className="static-count bold-class">&nbsp;Days</i>
                    </div>
                    <span>Implementation Cycle</span>
                  </div>
                  <div className="col-4 item">
                    <div className="count-wrapper animate">
                      <i className="static-count bold-class">No.</i>
                      <i className="count bold-class" count={1}>
                        1
                      </i>
                    </div>
                    <span>BPM+CX Process</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="full-width-container">
            <div className="row cx-now-section">
              <figure className="banner">
                <img
                  src={`/img/cloud-based-dynamic-case-management-breaker-img.jpg`}
                  alt="Cloud-Based Dynamic Case Management"
                />
              </figure>
              <div className="content">
                <h3>Cloud-Based Dynamic Case Management</h3>
                <p>
                  Download a complimentary copy of The Forrester Wave
                  <sup>TM</sup> recognizing Creatio (formerly BPM'Online) as a
                  Strong Performer in Case Management Q1, 2018.
                </p>
                <div className="btn-wrapper offport-zoomin">
                  <Download />
                  {/* <NavLink rel="enquiry-form" className="btn btn-primary popup-form-trigger">
                    <i className="fa fa-download" /> &nbsp; Download
                  </NavLink> */}
                </div>
              </div>

            </div>
          </section>
          <section className="block-3">
            <h2 className="section-main-heading">
              Why Business Process Management for{" "}
              <span className="highlight">CX</span>
            </h2>
            <span className="section-desc">
              BPM for CRM or Customer Experience offers excellent insights on
              customer adaptation to business processes and how these underlying
              processes have enhanced transactional experiences and
              interactions. BPM engine pre-built in CX Platform helps
              organizations to understand their customers' intent and how
              various business moments tied to operations.
            </span>
            <div className="row page-wrap offport-down">
              <div className="col-4 item-wrapper">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/dynamic-case-management-img.jpg`}
                      alt="Dynamic Case Management (DCM)"
                    />
                  </figure>
                  <h3>Dynamic Case Management (DCM)</h3>
                  <p>
                    With UI development tooling, case engine foundation,
                    preconfigured templates, and frameworks DCM can abstract
                    complexity through information and task design models.
                    Creatio (Formerly BPM’Online) DCM recognized by Forrester as
                    the leading solution.
                  </p>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/multi-channels-silos-img.jpg`}
                      alt="Multi-channels and Silos"
                    />
                  </figure>
                  <h3>Multi-channels and Silos</h3>
                  <p>
                    Systems silos, process silos, data silos, and departmental
                    silos harm your CX. With BPM.CX integrated Suite,
                    organizations can orchestrate and optimize processes across
                    interactions and channels, as well as between departments
                    and teams.
                  </p>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/customer-journey-bpm-img.jpg`}
                      alt="Customer Journey and BPM"
                    />
                  </figure>
                  <h3>Customer Journey and BPM</h3>
                  <p>
                    Customer journey mapping will be a key technique to enable
                    the needed intimate and varied reconnection of a process to
                    the customer. As per Gartner, 30% of large organizations
                    will improve CX by integrating customer journey maps with
                    business process models.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="cx-extra-features exp-cloud-services">
            <div className="row">
              <div className="col-4 item-wrapper">
                <figure>
                  <img
                    src={`/img/artificial-intelligence-bg.jpg`}
                    alt="Artificial Intelligence"
                  />
                </figure>
                <div className="item offport-down">
                  <h3 className="section-heading">Artificial Intelligence</h3>
                  <p>
                    Virtuos Professional Services team leverages Artificial
                    Intelligence and Analytics from varied ecosystems to deliver
                    highly personalized customer journeys when implementing
                    Creatio (Formerly BPM’Online).
                  </p>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <figure>
                  <img
                    src={`/img/customer-experience-img.jpg`}
                    alt="CX Practice Leadership"
                  />
                </figure>
                <div className="item offport-down">
                  <h3 className="section-heading">CX Practice Leadership</h3>
                  <p>
                    Virtuos has 10+ years of broad expertise in implementing CRM
                    and CX projects for over 100 leading brands worldwide. Our
                    domain expertise in CX helps you accelerate with Creatio
                    (Formerly BPM’Online).
                  </p>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <figure>
                  <img
                    src={`/img/employee-engagement-img.jpg`}
                    alt="CRM Experts"
                  />
                </figure>
                <div className="item offport-down">
                  <h3 className="section-heading">
                    CRM Experts in your Industry
                  </h3>
                  <p>
                    We are certified professionals with blended skills in
                    technical architecture, integration, project management, and
                    solution design. Our delivery models for Creatio (Formerly
                    BPM’Online) implementation are flexible.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="heading-container">
            <h2 className="section-main-heading">
              Why Virtuos for{" "}
              <span className="highlight">
                Creatio (formerly BPM'Online) CRM
              </span>
              ?
            </h2>
            <span className="section-desc">
              Virtuos has demonstrated strengths in CX strategy &amp; vision,
              industry CRM Domain expertise and digital design capabilities
              along with strong Engineering competencies in integration,
              customization, and digital process automation. Our 99% customer
              success rating is a testimony to our game changing CRM
              transformation services competency.
            </span>
            <div className="btn-container offport-zoomin">
              <NavLink
                to={`/consultare/professional-services/`}
                className="btn btn-secondary"
              >
                Learn more
              </NavLink>
            </div>
          </section>
          <section className="full-width-container">
            <div className="row points-section">
              <figure className="banner">
                <img
                  src={`/img/ai-breaker-01-img.jpg`}
                  alt="Artificial Intelligence"
                />
              </figure>
              <div className="row page-wrap">
                <div className="col-4 item-wrapper">
                  <div className="item">
                    <span className="index highlight">01</span>
                    <p>Digitally Dextrous Workforce</p>
                  </div>
                </div>
                <div className="col-4 item-wrapper">
                  <div className="item">
                    <span className="index highlight">02</span>
                    <p>Expertise and Experience</p>
                  </div>
                </div>
                <div className="col-4 item-wrapper">
                  <div className="item">
                    <span className="index highlight">03</span>
                    <p>Skills and Knowledge</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="crm-portfolio-wrapper">
            <h2 className="section-main-heading">
              Virtuos Portfolio of{" "}
              <span className="highlight">CRM Expertise</span>?
            </h2>
            <span className="section-desc">
              Virtuos Consultare Professional Services has broad capabilities to
              implement CRM for Sales, Marketing, Service, Ecommerce, Field
              Service, and other cross-CRM Applications.
            </span>
            <div className="page-wrap row crm-portfolio-block">
              {/*
					<NavLink to="/c-digital/sales" class="col-4 item offport-down">
						<div class="item-block">
							<div class="content-wrap">
								<span class="icon">
									<i class="fas fa-chart-line"></i>
								</span>
								<h2>CRM Sales Cloud</h2>
								<p>Sales Automation; Configure, Price, Quote (CPQ) and Sales Performance to Sales Analytics.</p>
							</div>
						</div>
					</NavLink>
*/}
              <NavLink
                to={`/crm-digital/sales/`}
                className="col-4 item offport-down"
              >
                <div className="item-block">
                  <div className="content-wrap">
                    <span className="icon">
                      <i className="fas fa-chart-line" />
                    </span>
                    <h2>CRM Sales Cloud</h2>
                    <p>
                      Sales Automation; Configure, Price, Quote (CPQ) and Sales
                      Performance to Sales Analytics.
                    </p>
                  </div>
                </div>
              </NavLink>
              <NavLink
                to={`/crm-digital/service/`}
                className="col-4 item offport-down"
              >
                <div className="item-block">
                  <div className="content-wrap">
                    <span className="icon">
                      <i className="fas fa-cogs" />
                    </span>
                    <h2>CRM Service Cloud</h2>
                    <p>
                      Web, Social, and Contact Center for Customer Service, and
                      Knowledge Management to Self-Service.
                    </p>
                  </div>
                </div>
              </NavLink>
              <NavLink
                to={`/crm-digital/marketing/`}
                className="col-4 item offport-down"
              >
                <div className="item-block">
                  <div className="content-wrap">
                    <span className="icon">
                      <i className="fas fa-industry" />
                    </span>
                    <h2>CRM Marketing Cloud</h2>
                    <p>
                      Integrated Multi-channel Marketing, Loyalty, Digital
                      Marketing and Account Based Marketing (ABM).
                    </p>
                  </div>
                </div>
              </NavLink>
              <NavLink
                to={`/crm-digital/commerce/`}
                className="col-4 item offport-down"
              >
                <div className="item-block">
                  <div className="content-wrap">
                    <span className="icon">
                      <i className="fas fa-shopping-cart" />
                    </span>
                    <h2>CRM Ecommerce Cloud</h2>
                    <p>
                      Online selling, Storefront Personalization, Social for
                      Ecommerce and Web Analytics to Product Management.
                    </p>
                  </div>
                </div>
              </NavLink>
              <NavLink
                to={`/crm-digital/service/`}
                className="col-4 item offport-down"
              >
                <div className="item-block">
                  <div className="content-wrap">
                    <span className="icon">
                      <i className="fas fa-chart-pie" />
                    </span>
                    <h2>CRM Field Service Cloud</h2>
                    <p>
                      Contracts, Warranty, Field force optimization, Scheduling
                      and Mobile Service Apps to Fleet Management.
                    </p>
                  </div>
                </div>
              </NavLink>
              <NavLink
                to={`/crm-digital/`}
                className="col-4 item offport-down"
              >
                <div className="item-block">
                  <div className="content-wrap">
                    <span className="icon">
                      <i className="fas fa-cloud" />
                    </span>
                    <h2>Cross CRM Cloud Applications</h2>
                    <p>
                      Cross-Functional Customer Analytics; Voice of the
                      Customer; MDM, and Customer Analytics to EAI.
                    </p>
                  </div>
                </div>
              </NavLink>
            </div>
          </section>
          <section className="connect-wrapper">
            <figure>
              <img
                src={`/img/voc.jpg`}
                alt="Ideas and Experiments"
              />
            </figure>
            <div className="page-wrap row offport-up">
              <h2>WE ARE FULL OF IDEAS AND EXPERIMENTS</h2>
              <p>
                Let’s know how we can partner with you to bring new products,
                services and ecosystems to business and society changing the
                world around us.{" "}
              </p>
            </div>
          </section>
          <section className="page-wrap connect-container offport-down">
            <div className="row">
              <div className="col-4 item">
                <span className="icon office">
                  <i className="fas fa-map-marker-alt" />
                </span>
                <span className="title">Our Office</span>
                <span className="text">Contact us to get prompt response</span>
                <NavLink
                  to={`/contact`}
                  className="btn btn-secondary"
                >
                  Open Map
                </NavLink>
              </div>
              <div className="col-4 item">
                <span className="icon email">
                  <i className="fal fa-envelope" />
                </span>
                <span className="title">Our Email</span>
                <span className="text">Email us to reach you quickly</span>
                <NavLink
                  to="mailto:info@virtuos.com"
                  className="btn btn-secondary"
                >
                  Send Email
                </NavLink>
              </div>
              <div className="col-4 item">
                <span className="icon support">
                  <i className="fas fa-headphones" />
                </span>
                <span className="title">Our Support</span>
                <span className="text"> Go to customer support portal</span>
                <NavLink
                  to="http://virtuos.custhelp.com"
                  rel="nofollow"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Open Incident
                </NavLink>
              </div>
            </div>
          </section>
          {/*?php include '../../footer.php'; ?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default VivacisDpa;
