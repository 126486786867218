import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Implements_Salesforce_At_Paytm = () => {
    useEffect(() => {
        document.title = "Virtuos Implements Salesforce's First CRM Project at PayTM – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-implements-salesforce-at-paytm-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 11 August, 2015</span>
                                <h2 className="article-heading">
                                    Virtuos implements Salesforce's first project at PayTM, a large
                                    payment gateway, and eCommerce company
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos has established itself as a leading CRM Implementation Partner
                                    for E-Commerce and Retail Space in India. During one of the meetings,
                                    Salesforce Partner Lead has approached Virtuos because of the latter’s
                                    deep expertise in implementing Complete CX and CRM Suite for leading
                                    eCommerce/internet companies like Myntra, Flipkart, and Makemytrip.
                                </p>
                                <p>
                                    Virtuos has also signed multiple customer deals in the last few years
                                    and these include Aditya Birla Group, Haier Group, amongst several
                                    others.
                                </p>
                                <p>
                                    “Virtuos has signed a partnership with Salesforce as a registered
                                    partner, but the practice did not kick-off as we were focussing mainly
                                    on Oracle Service Cloud.” Said Kristina Hermanns, Director of Solution
                                    Consulting. “PayTM project will be implemented by the certified
                                    Salesforce team that has more than six years of experience in
                                    Salesforce CRM implementation”.
                                </p>
                                <p>
                                    “Salesforce is an ecosystem, and it needs a clear differentiation
                                    strategy to integrate with organization wide operations.” Said Venky
                                    Vijay Reddi, the CEO of Virtuos. “I myself have been a big fan of
                                    Salesforce, Marc Benioff and the Dreamforce. I go every year to attend
                                    Dreamforce to witness the transformation in the CRM and Industry
                                    landscape.”
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/salesforce-paytm-virtuos-logo.jpg`}
                                        alt="Salesforoce, PayTM, Virtuos"
                                    />
                                </figure>
                                <h2 className="title">About PayTM</h2>
                                <p>
                                    PayTM is India's largest leading payment gateway that offers
                                    comprehensive payment services for customers and merchants. We offer
                                    mobile payment solutions to over 7 million merchants and allow
                                    consumers to make seamless mobile payments from Cards, Bank Accounts
                                    and Digital Credit among others. Visit{" "}
                                    <NavLink
                                        to="https://www.paytm.com"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.paytm.com
                                    </NavLink>{" "}
                                    for more information.
                                </p>
                                <h2 className="title">About Salesforce</h2>
                                <p>
                                    Salesforce.com, inc. is an American cloud-based software company
                                    headquartered in San Francisco, California. It provides customer
                                    relationship management service and also sells a complementary suite
                                    of enterprise applications focused on customer service, marketing
                                    automation, analytics, and application development. Salesforce has
                                    nearly 30% market share in the CRM field and is growing at a double
                                    digit rate year on year. For more information visit{" "}
                                    <NavLink
                                        to="http://www.salesforce.com"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.salesforce.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Implements_Salesforce_At_Paytm