import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

const Virtuos_Celebrates_Its_11th_Anniversary = () => {
  useEffect(() => {
    document.title =
      "Virtuos to celebrate its 11th Anniversary With the Launch of CulturO – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-11-anniversary-img-big.jpg`}
                  alt="Virtuos to celebrate 11th Anniversary"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurgaon, 16 Jan 2019</span>
                <h2 className="article-heading">
                  Virtuos to celebrate 11th Anniversary with the launch of
                  "Culturo" — the critical success factor and a strategic part
                  of digital business.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Culture is increasingly in the news, and it comes up in
                  earnings calls with investors very often to assess the
                  connection between digital strategy and organizational
                  thinking. The C – Level Executives are increasingly shaping
                  the enterprise culture by teaming up with HR to co-develop
                  balanced strategy around diversity and inclusion.
                </p>
                <p>
                  "Gartner's 2018 CIO Survey cited culture as the No. 1
                  inhibitor to scaling digital innovations or executing the
                  transformation," said Venky Vijay Reddi, CEO of Virtuos. "At
                  Virtuos we plan to offer Culturo (Culture of Organization)
                  with complete employee engagement toolkits, frameworks, and
                  next-generation Digital Experience Platforms (DXP) as part of
                  our digital transformation practice".
                </p>
                <p></p>
                <figure>
                  <img
                    src={`/img/digital-transformation-practice-img.jpg`}
                    alt="Virtuos launches 'Culturo'"
                    style={{ width: "100%" }}
                  />
                </figure>
                <p />
                <p>
                  Many digital transformation programs are beginning to stall,
                  employees are experiencing digital fatigue with poor
                  engagement, and culturally misfit approaches, and
                  organizational mindsets. Culturo, a software, and systems
                  driven set of framework can offer digital design thinking to
                  hack your culture and create more engaged employees
                  participating in the digital transformation initiatives. These
                  cultural hacks are small and visceral eliciting an emotional
                  response from employees driving "ContinueNext" phases in
                  organization’s digital business transformation.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. (Formerly Virtuos Solutions Private Ltd.)
                  is a leading Customer Experience (CX) &amp; Digital
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/xonomy/ai`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Newly created Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/cxdesk`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Celebrates_Its_11th_Anniversary;
