import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Clicksoftware_Sign_Partnership = () => {
    useEffect(() => {
        document.title = "Virtuos and Clicksoftware sign partnership to position Field Service Cloud in India– Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-clicksoftware-sign-partnership-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 11 May, 2015</span>
                                <h2 className="article-heading">
                                    Virtuos and Clicksoftware sign partnership to position Click Field
                                    Service Cloud in India
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos, a leading CRM Software Consulting company and Clicksoftware,
                                    the market leader in Field Service Automation have joined hands to
                                    position Clicksoftware Field Service Cloud Applications to Medium and
                                    Large Enterprise customers in India.
                                </p>
                                <p>
                                    Under the agreement, Clicksofware will provide paid training and
                                    certification to Virtuos Consultants and Technical Staff while
                                    introducing Virtuos to some of the existing customers in India.
                                    Virtuos will undertake market development, sales, implementation, and
                                    support for Clicksoftware modules.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-clicksoftware-sign-partnership-img-news.jpg`}
                                        alt="Virtuos, Clicksoftware"
                                    />
                                </figure>
                                <h2 className="title">About Clicksoftware</h2>
                                <p>
                                    ClickSoftware offers automated mobile workforce management and service
                                    optimization solutions for enterprise and small businesses, both for
                                    mobile and in-house resources. They have over 700 employees in offices
                                    in North America, EMEA, and APAC. Gartner has acclaimed Clicksoftware
                                    in its Magic Quadrant report as the market leader in Field Service
                                    Automation. Clicksoftware is being used by most of the Fortune 1000
                                    companies worldwide, ranging from utility companies, telecom,
                                    high-tech companies to Govt. Clicksoftware also has a licensing
                                    agreement with SAP and Salesforce to cater to a large number of
                                    customers. For more information visit{" "}
                                    <NavLink
                                        to="http://www.clicksoftware.com"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.clicksoftware.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media, and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Clicksoftware_Sign_Partnership