import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import bgImage from "./images/bg.jpg";
import viraatalogo from "./images/viraata-logo.svg";
import "./css/viratastyles.css";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaLinkedin,
  FaPinterestSquare,
} from "react-icons/fa";

const Viraata = () => {
  useEffect(() => {
    document.title =
      "Business Solutions and Services for Government, Public Sector, and Public Institutions – Virtuos Viraata";
  }, []);

  return (
    <div
      className="main-area-wrapper"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="main-areas center-text">
        <div className="viraataheader">
          <div className="viraatalogo">
            <img src={viraatalogo} alt="viraata.com" />
          </div>
          <h2>The Colossal Opportunity.</h2>
        </div>
        <div className="viraatafooter">
          <p className="ptag">
            © Viraata, A division of{" "}
            <NavLink
              to="http://www.virtuos.com"
              target="_blank"
              className="navlink"
            >
              Virtuos Digital Ltd
            </NavLink>
            . All rights reserved.
          </p>
          <div className="socialdiv">
            <NavLink
              to="https://www.facebook.com/Virtuos/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FaFacebookSquare className="social-icon" />
            </NavLink>
            <NavLink
              to="https://twitter.com/virtuos"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FaTwitterSquare className="social-icon" />
            </NavLink>
            <NavLink
              to="https://www.linkedin.com/company/virtuosdigital/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FaLinkedin className="social-icon" />
            </NavLink>
            <NavLink
              to="http://pinterest.com/virtuoscorp"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FaPinterestSquare className="social-icon" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viraata;
