import React, { useEffect, useState } from 'react'

import { NavLink } from "react-router-dom";
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Nav from '../../../components/Nav';
import ApplyForJob from './Forms/ApplyForJob';
import CommonApplyForJob from './CommonApplyForJob';
import { NavigateNext } from '@mui/icons-material';

const BusinessHead = () => {
    useEffect(() => {
        document.title = "Career Opportunities | Microsoft .Net Developer – Virtuos Jobs";
    }, []);
    return (
        <div className="careers-job-detail-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    {/* Careers Job Detail */}
                    <section className="job-detail-wrapper">
                        <div className="row page-wrap">
                        <div class="col-9 job-details">
						<div class="job-header">
							<div class="profile-illustration">
								<figure>
									<img src={`/img/svg/business-head-career-img.svg`} alt="Business Head Job"/>
								</figure>
							</div>
							<div class="profile-detail">
								<h2 class="section-heading">Business Head</h2>
								<ul class="job-attributes">
									<li class="">
										<strong>Location:</strong>
										<span>Gurgaon</span>
									</li>
									<li class="">
										<strong>Experience:</strong>
										<span>12+ Years</span>
									</li>
									<li class="">
										<strong>No. of Positions:</strong>
										<span>2</span>
									</li>
									<li class="">
										<strong>Qualification:</strong>
										<span>BE/B.Tech/MBA in Sales/Marketing</span>
									</li>
								</ul>
							</div>
						</div>
						<h3 class="title">Job Description</h3>
						<ul class="list">
							<li>The candidate is responsible for business development of the current solution / Professional service portfolio at Virtuos</li>
							<li>Virtuos takes a very consultative approach for selling high-end solutions so the candidate is expected to work smart on the Presentations of these solutions for clients/prospects at a short notice</li>
							<li>Work in sync with Solution Development Team to understand the client requirement and deliver to client objectives as a part of POC or Demo</li>
							<li>Since the role involves lot of Client Interaction, we would prefer the candidate with sound technology foundation to understand Client objectives and ecosystem to give alternate ways to achieve the desired results</li>
						</ul>

						<h3 class="title">Skill Set</h3>
						<ul class="list">
							<li>Experience/Inclination in IT software Sales (Direct Sales B2B)</li>
							<li>Should have a rich experience in Client Interface with CIOs/CTOs and Sales Heads</li>
							<li>Candidate with CRM industry knowledge would be an added advantage</li>
							<li>Technical bent of mind and astute eagerness in learning more and understanding high end technology solutions like CRM, ERP, and BPM</li>
							<li>Key Account Management skills for building business from existing accounts with cross sell/upsell functions</li>
							<li>Excellent Communication (Verbal and Written) with strong PR skills</li>
							<li>Strong Presentation skills for High End solution like CRM, ERP, BPM &amp; Enterprise Solutions</li>
							<li>Should be able to work in a standalone independent environment or in a group</li>
							<li>Willing to travel extensively and always ready to meet new challenges</li>
							<li>Consultative bent of Mind with aggressive sales aptitude to follow the customer buying process</li>
							<li>High energy and electrifying tendency to pursue sales stages and exceed sales targets</li>
						</ul>
						
						<h3 class="title">Advantages of Working@Virtuos</h3>
						<ul class="list">
							<li>Virtuos has deep expertise in CRM and Customer Experience Consulting - It’s likely to become largest Applications Business valued at US$45B per year. Candidates will gain significant exposure from our best practices, top-notch alliances, and direct hands-on interaction with leading brands</li>
							<li>The candidates will have exposure to the cutting edge solutions in broad based verticals</li>
							<li>The role also accords a deep understanding of Customer's business processes, and technology adaptation providing an ample room for candidate's overall growth</li>
							<li>Global exposure and working on best business practices with MNC and Enterprise/Corporate Customers in diverse verticals</li>
						</ul>
						
					</div>
                            <div className="col-3 job-application-wrapper sticky-content">
                             <CommonApplyForJob/>
                            </div>
                        </div>
                    </section>
                   
                    {/*?php include '../../footer.php';?*/}
                    <Footer/>
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default BusinessHead
