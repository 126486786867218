import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import EnqueryForm from "./Form/EnqueryForm";
import CountUp from "react-countup";
const Exonomy_Ai_Selservice = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/xonomy/ai/self-service");
  }, []);
  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };

  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };
  useEffect(() => {
    document.title =
      "Rainbow CX AI Driven Customer Self-Service – Virtuos Xonomy";
  }, []);
  return (
    <div className="aury-page landing-page rigour-page self-service-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <div className="product-hero-wrapper">
              <div className="left-img">
                <figure>
                  <img
                    src={`/img/svg/cust-self-service-header-left-bg.svg`}
                    alt=""
                  />
                </figure>
              </div>
              <div className="right-img">
                <figure>
                  <img
                    src={`/img/svg/cust-self-service-header-right-bg.svg`}
                    alt=""
                  />
                </figure>
              </div>
              <div className="row page-wrap">
                <div className="col-4 item-wrapper">
                  <div className="bottom">
                    <h3 className="section-heading">
                      Rainbow AI Driven Customer Self-Service
                    </h3>
                  </div>
                </div>
                <div className="col-6">
                  <figure>
                    <img
                      src={`/img/svg/cust-self-service-img.svg`}
                      alt="Self-Service"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/xonomy/`}>Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/xonomy/ai/self-service/`} className="active">
                    AI SELF-SERVICE
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/xonomy/ai/`}>AI</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud/`}>EXPERIENCE CLOUD</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-crowd/`}>EXPERIENCE CROWD</NavLink>
                </li>
              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${
                    showExtraLinks ? "active" : ""
                  } inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" /> More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/io/`}>INNOVATION ON</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/business4-0/`}>
                          BUSINESS 4.0 STRATEGY
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/aury/`}>AURY PLATFORM</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/ventures/`}>VENTURES</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/`}>VIRTUOS VUCA</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/covid/`}>VIRTUOS FOR COVID</NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="ai-wrapper">
            <h2 className="section-main-heading">
              <span className="highlight">Artificial Intelligence</span> in
              Customer Service
            </h2>
            <span className="section-desc">
              AI powered self-service solutions enable your customers to
              proactively find the information they're looking for without
              needing to contact your customer support agents. By drawing on
              your existing knowledge base, our patented natural language
              processing (NLP) and machine learning (ML) Rainbow CX technologies
              dialogue with your customers and guide them towards a successful
              resolution.
            </span>
            <div className="row product-section aury-metrices light-blue-bg">
              <div className="page-wrap right-content inview">
                <div className="count-band">
                  <div className="row">
                    <div className="col-4 item offport-zoomin">
                      <div className="count-wrapper animate">
                        <i className="count bold-class" count={88}>
                          <CountUp
                            end={88}
                            duration={4}
                            style={{ fontSize: "3.5rem" }}
                          />
                        </i>
                        <i className="static-count bold-class">%</i>
                      </div>
                      <span>C-Level Executives</span>
                      <p>
                        perceive that Artificial Intelligence (AI) will bring
                        value to their business.
                      </p>
                    </div>
                    <div className="col-4 item offport-zoomin">
                      <div className="count-wrapper animate">
                        <i className="count bold-class" count={61}>
                          <CountUp
                            end={66}
                            duration={4}
                            style={{ fontSize: "3.5rem" }}
                          />
                        </i>
                        <i className="static-count bold-class">%</i>
                      </div>
                      <span>Customers</span>
                      <p>
                        are unlikely to return to a website that does not meet
                        their customer service expectations.
                      </p>
                    </div>
                    <div className="col-4 item offport-zoomin">
                      <div className="count-wrapper animate">
                        <i className="count bold-class" count={73}>
                          <CountUp
                            end={73}
                            duration={4}
                            style={{ fontSize: "3.5rem" }}
                          />
                        </i>
                        <i className="static-count bold-class">%</i>
                      </div>
                      <span>Customers</span>
                      <p>
                        believe that they should be able to solve most of their
                        product and service related issues, themselves.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="ai-wrapper">
            <h2 className="section-main-heading">
              <span className="highlight">Growing Need</span> of AI in Customer
              Service
            </h2>
            <span className="section-desc">
              According to analyst firm Gartner, by 2020, 20% of companies will
              dedicate workers to monitor and guide neural networks. AI is
              changing the way in which organizations innovate and communicate
              their processes, products, and services and conversational AI
              platforms are one of the most talked-about technologies within the
              AI world. Virtuos realized this opportunity very early and
              invested in AI/Machine Learning technologies for improving
              Customer Experience and reducing operational costs for businesses.{" "}
            </span>
            <div className="advantages-of-aury ecosystem-section">
              <div className="page-wrap">
                <div className="row">
                  <div className="col-5 offport-left">
                    <figure>
                      <img
                        src={`/img/svg/ai-in-self-service-img.svg`}
                        alt="AI in Self-Service"
                      />
                    </figure>
                  </div>
                  <div className="col-6 offport-right">
                    <h3 className="section-heading">
                      How does{" "}
                      <span className="highlight">AI in Self-Service</span>
                      work?
                    </h3>
                    <div className="content-wrapper">
                      <p>
                        AI powers a self-maintaining knowledge base designed for
                        improving CX by harnessing insights driven customer
                        data. It leverages its natural language capabilities for
                        identifying the ‘intent’ of customers, rather than
                        ‘keywords’ and uses a predictive search engine for
                        searching resolution in a proprietary knowledge base.
                        (they can even be cre8tive with their spelling!).
                      </p>
                      <ul>
                        <li>
                          <i className="fal fa-angle-double-right" /> NLP/NLU
                          approached with deep neural semantic models
                        </li>
                        <li>
                          <i className="fal fa-angle-double-right" /> Domain
                          agnostic solution that can be used as it is or
                          specialized at will
                        </li>
                        <li>
                          <i className="fal fa-angle-double-right" /> Applicable
                          to multiple languages
                        </li>
                        <li>
                          <i className="fal fa-angle-double-right" />{" "}
                          Autonomously learns over time
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="related-offerings half-egde-icon-wrapper">
            <h2 className="section-main-heading">
              Related <span className="highlight">Offerings</span>
            </h2>
            <div className="page-wrap row">
              <div className="col-6 item-wrapper">
                <div className="item">
                  <div className="content-wrap">
                    <h3 className="section-heading">Call Deflection</h3>
                    <p>
                      Implementing smart self-service technologies reduces
                      customer service costs as the calls from customers are
                      deflected to self-service channel and customers are able
                      to independently resolve their issues.
                    </p>
                  </div>
                  <span className="icon">
                    <i className="fas fa-phone" />
                  </span>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <div className="content-wrap">
                    <h3 className="section-heading">Customer Analytics</h3>
                    <p>
                      Every question, answer and action taken by your customers
                      is presented to you in real time through your Dashboard.
                      Get a log of all your customers' questions - clustered by
                      themes. Proactively identify missing knowledge that your
                      customers are looking for.
                    </p>
                  </div>
                  <span className="icon">
                    <i className="fas fa-chart-line" />
                  </span>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <div className="content-wrap">
                    <h3 className="section-heading">Increase C-SAT</h3>
                    <p>
                      Increased customer satisfaction levels{" "}
                      <strong>(CSAT)</strong> as your customers are now quickly
                      able to find the answers to their questions, at their own
                      leisure.
                    </p>
                  </div>
                  <span className="icon">
                    <i className="fas fa-users" />
                  </span>
                </div>
              </div>
              <div className="col-6 item-wrapper">
                <div className="item">
                  <div className="content-wrap">
                    <h3 className="section-heading">
                      Machine Learning Velocity
                    </h3>
                    <p>
                      Aury<sub>®</sub> learns fast! Start serving your customers
                      from day-1 and the system starts learning from the word
                      GO! Answer prediction quality is improved before going
                      live.
                    </p>
                  </div>
                  <span className="icon">
                    <i className="fas fa-laptop" />
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="cf-form-wrapper grey-bg">
            <div className="page-wrap ">
              <h2 className="section-main-heading">
                LET’S Create Better{" "}
                <span className="highlight">Customer Experience</span>
              </h2>
              <span className="section-desc">
                Born in cloud to create Customer Experience Foundation for
                leading brands around the world. We can help you with our strong
                Cloud and Digital Technologies.
              </span>
              <EnqueryForm
                para={
                  "Use Cloud to modernize your legacy systems and migrate to most modern application stack. Reach out to us by filling this form."
                }
              />
            </div>
          </section>
          {/*?php include '../../../footer-xonomy.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Exonomy_Ai_Selservice;
