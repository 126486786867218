import React, { useEffect } from 'react'
import Nav from '../components/Nav'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { NavLink } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
const Exp_new = () => {
    const CustomPrevArrow = ({ onClick }) => (
        <div className="prev-arrow" onClick={onClick}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      );
    
      const CustomNextArrow = ({ onClick }) => (
        <div className="next-arrow" onClick={onClick}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      );
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };
    useEffect(() => {
        document.title = "Expedience Advisory and Management Consultancy – Virtuos Digital";
    }, []);

    return (
        <div className="exp-new-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <ul className="header-slider bxSlider">
                    <Slider {...settings}>
                        
                    <li className="top-hero-product-banner image-title-layout">
                            <figure className="bg-image-wrapper">
                                <img
                                    src={`/img/experience-new-slide1-img.jpg`}
                                    alt="Expedience"
                                />
                            </figure>
                            <div className="product-hero-wrapper">
                                <div className="row page-wrap">
                                    <div className="col-5 item-wrapper">
                                        <figure className="logo">
                                            <img
                                                src={`/img/svg/icons/expedience-logo.svg`}
                                                alt="Expedience Logo"
                                            />
                                        </figure>
                                        <h3 className="section-heading">
                                            The Internal Digital Transformation
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="top-hero-product-banner image-title-layout">
                            <figure className="bg-image-wrapper">
                                <img
                                    src={`/img/experience-new-slide2-img.jpg`}
                                    alt="Four New Operations"
                                />
                            </figure>
                            <div className="product-hero-wrapper">
                                <div className="row page-wrap">
                                    <div className="col-4 item-wrapper">
                                        <h3 className="section-heading black">
                                            THE BIRTH OF FOUR FABULOUS OPERATIONS
                                        </h3>
                                        <ul>
                                            <li>
                                                <NavLink to="http://www.vaves.com" target="_blank">
                                                    <img src={`/img/svg/icons/vaves-logo.svg`}  alt="vaves1"/>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/veracis/`} >
                                                    <img
                                                        src={ `/img/svg/icons/veracis-logo.svg`}
                                                        style={{ marginTop: 5 }} alt="vaves11"
                                                    />
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/vivacis/`} >
                                                    <img
                                                        src={`/img/svg/icons/vivacis-logo.svg`}
                                                        style={{ width: 120, marginTop: 10 }} alt="vaves14"
                                                    />{" "}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/vedam/`} >
                                                    <img
                                                        src={`/img/svg/icons/vedam-logo.svg`}
                                                        style={{ width: 120, marginBottom: 10 }} alt="vavesere1"
                                                    />
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </Slider>

                    </ul>
                    <section className="trans-container">
                        <h2 className="section-main-heading">
                            A TRANSFORMATIONAL RESET IS <span className="highlight">IMPERATIVE</span>
                        </h2>
                        <span className="section-desc">
                            Business is the greatest platform for change in society. The businesses
                            must serve the society and communities with greater purpose and COVID-19
                            should become a biggest transformation driver.
                        </span>
                        <div className="row page-wrap">
                            <div className="col-5-half item">
                                <h3 className="red font-weight600">EXPERIENCE NEW...</h3>
                                <figure>
                                    <img
                                        src={`/img/experience-new-img.jpg`}
                                        alt="Experience New" 
                                    />
                                </figure>
                                <p>
                                    <strong>
                                        The cumulative outcomes you drive for the customers by
                                        double-stretching yourselves.
                                    </strong>
                                </p>
                                <ul>
                                    <li>
                                        The Customers, Employees, and The Partners feel the "NEW" Virtuos.
                                    </li>
                                    <li>
                                        The EX and CX in unison delivering XX — Everything Experience
                                        including UX and MX everyday.
                                    </li>
                                </ul>
                            </div>
                            <div className="col-5-half item">
                                <h3 className="highlight">EXPEDIENCE NOW...</h3>
                                <figure>
                                    <img
                                        src={`/img/expedience-now-img.jpg`}
                                        alt="Expedience Now"
                                    />
                                </figure>
                                <p>
                                    <strong>
                                        The Act of today in the moment for the situation we are in.
                                        Expedience means speed and agility at scale.
                                    </strong>
                                </p>
                                <ul>
                                    <li>
                                        The Company becomes a mission to succeed in creating a
                                        “intrapreneurial you”.
                                    </li>
                                    <li>
                                        The EX and CX in unison delivering XX — Everything Experience and
                                        Everyday Experience.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="breaker purple">
                        <div className="row page-wrap">
                            <div className="col-5 ">
                                <h3 className='font-weight600'> We are what we repeatedly do.</h3>
                                <p>- Socrates</p>
                            </div>
                            <div className="col-5">
                                <figure>
                                    <img
                                        src={`/img/socrates-img1.png`}
                                        alt="Socrates"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="human-resi">
                        <h2 className="section-main-heading">
                            Human <span className="highlight">resilience</span>
                        </h2>
                        <span className="section-desc">
                            More than 50 million in-office workers (in India) everyday. Then, almost
                            everybody went home.
                        </span>
                        <div className="row page-wrap">
                            <div className="col-5">
                            <h3 className='font-weight600'>RESILIENCE</h3>
                                <p>/r'zilyens/</p>
                                <ul>
                                    <li>
                                        The capacity to recover quickly from difficulties;{" "}
                                        <strong>toughness</strong>.
                                    </li>
                                    <li>
                                        The ability of a substance or object to{" "}
                                        <strong>spring back into shape</strong>.
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6">
                                <figure>
                                    <img
                                        src={`/img/svg/human-resilience-diagram.svg`}
                                        alt="Human Resilience"
                                    />
                                </figure>
                                <span className="note">
                                    Composition Is to Resilience as Fitness Is to Health.
                                </span>
                            </div>
                        </div>
                    </section>
                    <section className="comp-container">
                        <h2 className="section-main-heading">
                            Everything is <span className="highlight">Composabl™</span>
                        </h2>
                        <span className="section-desc">
                            Just as the human body is a complex organism, an organization is also a
                            complex organism. We can "train" through fitness to build health. We can
                            "train" through composition to build resilience.
                        </span>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <figure>
                                    <img
                                        src={`/img/resilience-diagram.jpg`}
                                        alt="Resilience"
                                    />
                                </figure>
                                <p>And...</p>
                                <p></p>
                            </div>
                        </div>
                        <h2 className="section-main-heading">
                            Everyone is <span className="highlight">Trainable</span>
                        </h2>
                        <span className="section-desc">
                            Unfortunately, it needs "Pandemic" like disruption in ourselves.
                        </span>
                    </section>
                    <section className="breaker green">
                        <div className="row page-wrap">
                            <div className="col-8">
                            <h3 className='font-weight600'>A mindset is just stories you tell about yourself, to yourself.</h3>
                                <p>- Brian Grasso</p>
                            </div>
                            <div className="col-3">
                                <figure>
                                    <img
                                        src={`/img/brian-grasso-img.png`}
                                        alt="Brian Grasso"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="struggle-container">
                        <h2 className="section-main-heading">
                            The Struggle
                            <div className="row page-wrap">
                                <div className="col-4 item">
                                    <figure>
                                        <img
                                            src={`/img/struggle-img.jpg`}
                                            alt="Struggle"
                                        />
                                    </figure>
                                </div>
                                <div className="col-4 item">
                                    <span className="highlight">Inability to</span> believe
                                </div>
                                <div className="col-4 item">
                                    <span className="highlight">Inability to</span> establish discipline
                                </div>
                                <div className="col-4 item">
                                    <span className="highlight">Inability to</span> build on a strong
                                    foundation
                                </div>
                            </div>
                            <div className="row page-wrap support">
                                <div className="col-5">
                                    <h3>Support Your Philosophy Through Composition:</h3>
                                </div>
                                <div className="col-5">
                                    <ul>
                                        <li>Belief</li>
                                        <li>Discipline</li>
                                        <li>Strong Foundation</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row page-wrap belief">
                                <div className="col-12">
                                    <figure>
                                        <img
                                            src={`/img/svg/belief-diagram.svg`}
                                            alt="Belief diagram"
                                        />
                                    </figure>
                                </div>
                            </div>
                        </h2>
                    </section>
                    <section className="breaker purple diagram">
                        <div className="row page-wrap">
                            <div className="col-7">
                            <h3 className='font-weight600'>
                                    The COVID-19 has been a life reset for people, businesses, governments
                                    and the economy. "Workday" has now become WorkLifeDay.
                                </h3>
                            </div>
                            <div className="col-4-half">
                                <figure>
                                    <img
                                        src={`/img/svg/work-life-day-diagram.svg`}
                                        alt="WorkLifeDay Diagram"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="fusion-container">
                        <h2 className="section-main-heading">
                            Everything is <span className="highlight">Composabl™</span>
                        </h2>
                        <span className="section-desc">
                            Just as the human body is a complex organism, an organization is also a
                            complex organism. We can “train” through fitness to build health. We can
                            “train” through composition to build resilience.
                        </span>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <figure>
                                    <img
                                        src={`/img/svg/fushion-team-diagram.svg`}
                                        alt="Fusion Teams Diagram"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="principle-container">
                        <h2 className="section-main-heading">
                            <span className="highlight">Two Principles</span>
                        </h2>
                        <div className="row page-wrap">
                            <div className="col-5-half item">
                            <h3 className='font-weight600'>
                                    <span>1</span> Specificity
                                </h3>
                                <ul>
                                    <li>Tailored to fit your goal</li>
                                    <li>Focus on one moment at a time</li>
                                </ul>
                            </div>
                            <div className="col-5-half item">
                            <h3 className='font-weight600'>
                                    <span>2</span> Rest and Recovery
                                </h3>
                                <ul>
                                    <li>We must let the body recover</li>
                                    <li className="no-point">
                                        Rest and recover does not mean doing nothing
                                    </li>
                                    <li>Take stock while at rest</li>
                                    <li>Plan changes for the next round</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="comp-tech-container">
                        <h2 className="section-main-heading">
                            Composabl™ <span className="highlight">Technologies</span>
                        </h2>
                        <span className="section-desc">
                            Composabl™ is an art and science of Virtuos practice that must be
                            practiced.
                        </span>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <figure>
                                    <img
                                        src={`/img/svg/composable-technologies-diagram.svg`}
                                        alt="Composabl Technologies Diagram"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="use-container">
                        <h2 className="section-main-heading">
                            USE IT, OR <span className="highlight">Lose it</span>
                        </h2>
                        <span className="section-desc">
                            You become what you think and act. At Virtuos, Composabl™ practice is
                            founded on Composable Thinking, Composable Architecture and Composable
                            Technologies.
                        </span>
                        <div className="row page-wrap">
                            <div className="col-12">
                                <figure>
                                    <img
                                        src={`/img/svg/muscle-diagram.svg`}
                                        alt="Muscle Diagram"
                                    />
                                </figure>
                            </div>
                        </div>
                    </section>
                    <section className="breaker green">
                        <div className="row page-wrap">
                            <div className="col-4">
                                <figure>
                                    <img
                                        src={`/img/socrates-img2.png`}
                                        alt="Socrates"
                                    />
                                </figure>
                            </div>
                            <div className="col-6 ">
                            <h3 className='font-weight600'>Excellence, then, is not an act, but a habit</h3>
                                <p>- Socrates</p>
                            </div>
                        </div>
                    </section>
                    <section className="new-abc-container">
                        <div className="row page-wrap">
                            <div className="col-6 item">
                                <h3 className='font-weight600'>Three Practical Things</h3>
                                <ul>
                                    <li>
                                        <span className="highlight">
                                            <strong>Evangelize</strong>
                                        </span>{" "}
                                        composability
                                    </li>
                                    <li>
                                        Promote good{" "}
                                        <span className="highlight">
                                            <strong>habits</strong>
                                        </span>
                                    </li>
                                    <li>
                                        Target the right{" "}
                                        <span className="highlight">
                                            <strong>technologies</strong>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 item">
                                <h3 className='font-weight600'>The New ABC</h3>
                                <ul>
                                    <li>
                                        Be{" "}
                                        <span className="highlight">
                                            <strong>Agile</strong>
                                        </span>
                                    </li>
                                    <li>
                                        Be{" "}
                                        <span className="highlight">
                                            <strong>Business 4.0</strong>
                                        </span>{" "}
                                        Resilient
                                    </li>
                                    <li>
                                        Be{" "}
                                        <span className="highlight">
                                            <strong>Composable</strong>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    {/*?php include '../footer-consultare.php';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Exp_new
