import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Launches_Co_Extend_Delivery_Methodology = () => {
    useEffect(() => {
        document.title = "Virtuos Launches Co-Extend Delivery Methodology – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-launches-co-extend-delivery-methodology-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 28 February, 2014</span>
                                <h2 className="article-heading">
                                    Virtuos launches Co-Extend Delivery Methodology for Consultare
                                    Professional Services
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos, a leader in Customer Experience (CX) and CRM in alliance with
                                    Oracle Corporation and an emerging player in Human Capital &amp;
                                    Business Execution Software in alliance with SAP/Successfactors is
                                    embarking on major expansion.
                                </p>
                                <p>
                                    Virtuos will be delivering its coveted Consultare Professional
                                    Services using Co-Extend Methodology as an option for Companies who
                                    are keen to leverage on our implementation skills and save on
                                    customisation and on-going integration costs.
                                </p>
                                <p>
                                    <strong>What is our Co-Extend Delivery Methodology?</strong>
                                </p>
                                <p>
                                    Virtuos delivers a piece of work independently and train the teams at
                                    the client side to be familiar with the development so that the entire
                                    Professional Services Project work can be shared nicely by:
                                </p>
                                <ul>
                                    <li>Training the teams at the client location</li>
                                    <li>Charging for consulting work based on the value</li>
                                    <li>Creating co-working model to reduce the costs</li>
                                    <li>Speed up the entire project while lowering the costs</li>
                                    <li>
                                        Creating very small dependence on our Services for the future and
                                        on-going basis
                                    </li>
                                    <li>Using Agile Project Methodology</li>
                                </ul>
                                <p>
                                    This is typically not a profitable model for Virtuos, but it can build
                                    a lot of trust with the clients — one way of launching crusade against
                                    the well established players who are always interested in over
                                    charging the customers without sharing a code or training on
                                    customizations.
                                </p>
                                <p className="italic">
                                    According to Gartner, "Not all customers will want to avail themselves
                                    of the co-extend method, but it will nevertheless be a useful
                                    differentiator that highlights something unique about how you work and
                                    deliver value to clients".
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely - Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas and executing them with Virtuoso teams. Virtuos was founded
                                    in 2008 by cultivating an ambitious plan to become a multi-billion
                                    dollar company and a game changer by 2020. Thanks to the hard work and
                                    commitment of our team we continue to grow each year. With new
                                    innovative business ideas, delivering incredible transformation
                                    services and building powerful engagement platforms for the future we
                                    are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink
                                        to="http://www.fb.com/virtuos"
                                        target="_blank"
                                        className="link"
                                        rel="noreferrer"
                                    >
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Launches_Co_Extend_Delivery_Methodology