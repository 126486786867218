import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Marries = () => {
  useEffect(() => {
    document.title = "CX Marries Digital. CX Is Integral To Marketing – Virtuos Digital";
  }, []);
  return (
    <div className="cx-marries-digital-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/svg/cx-marries-digital-img.svg`}
                alt="CX Marries Digital"
              />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5 item-wrapper">
                  <figure>
                    <img
                      src={`/img/svg/icons/cx-digital-logo.svg`}
                      alt="CX.Digital Logo"
                    />
                  </figure>
                  <h3 className="section-heading">CX marries Digital</h3>
                  <p>Virtuos creates an integrated CX.Digital Practice.</p>
                </div>
              </div>
            </div>
          </section>
          <section className="cx-digital-marketing">
            <h2 className="section-main-heading">
              CX IS INTEGRAL TO <span className="highlight bold-class">DIGITAL MARKETING</span>
            </h2>
            <span className="section-desc">
              Customer Experience and Digital Marketing go hand in hand and they are
              practically inseparable.
            </span>
            <span className="section-desc">
              Digital Transformation Projects always have Customer Experience and
              Marketing as one of the core elements. Over the last decade or so,
              Customer Experience (CX) has become a buzzword encompassing all the tenets
              of “experience”. Further CX has distinguished itself from its close cousin
              CRM.
            </span>
            <div className="row page-wrap">
              <div className="col-2-half left-block">
                <ul>
                  <li className="pink">Outside-In</li>
                  <li>Design</li>
                  <li>Voice of Customer</li>
                  <li>Personas and Journeys</li>
                  <li>Customer Insight</li>
                  <li>Culture</li>
                </ul>
              </div>
              <div className="col-4">
                <figure>
                  <img
                    src={`/img/svg/crm-cx-outside-inside-diagram.svg`}
                    alt="CX, CRM"
                  />
                </figure>
              </div>
              <div className="col-3 right-block">
                <ul>
                  <li className="pink">Inside-Out</li>
                  <li>Marketing</li>
                  <li>Sales</li>
                  <li>Digital Commerce</li>
                  <li>Customer Service</li>
                  <li>Field Service</li>
                </ul>
              </div>
            </div>
            <span className="section-desc">
              CRM and CX are critical priorities, but strategies must adapt as the
              organization, its customers and its environment evolve. Failing to get the
              fundamentals right puts the organization at risk.
            </span>
          </section>
          <section className="digital-cx-container">
            <h2 className="section-main-heading">
              WHAT IS DIGITAL AND HOW{" "}
              <span className="highlight bold-class">IT RELATES TO CX</span>
            </h2>
            <span className="section-desc">
              Digital is everywhere as a prefix or suffix for almost any part of the
              technology, transformation or optimization.
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <p>
                  When Virtuos first announced “CX Marries Digital” in 2009, it looked
                  more apt. In the last few years CX and Digital have become integrated
                  and served as one. “CX Marries Digital” is a misnomer today as CX
                  without Digital or Digital without CX ceases to exist in the world of
                  digital transformation. It’s not a marriage but a co-existence.
                </p>
              </div>
              <div className="col-4 item">
                <h3 className="bold-class">Pre-Digital CX Era</h3>
                <figure>
                  <img
                    src={`/img/pre-digital-cx-era-img.jpg`}
                    alt="Pre-Digital CX era"
                  />
                </figure>
              </div>
              <div className="col-4 item">
                <h3 className="bold-class">CX and Digital Era</h3>
                <figure>
                  <img
                    src={`/img/cx-digital-era-img.jpg`}
                    alt="CX and Digital Era"
                  />
                </figure>
              </div>
              <div className="col-4 item">
                <h3 className="bold-class">CX.Digital Era</h3>
                <figure>
                  <img
                    src={`/img/cx.digital-era.jpg`}
                    alt="CX.Digital Era"
                  />
                </figure>
              </div>
              <div className="col-12">
                <p className="app-p">
                  In June 2017, Virtuos announced a combined force of{" "}
                  <NavLink
                    to={`/cx-digital/`}
                    
                    className="link"
                  >
                    CX.Digital
                  </NavLink>
                  as a digital transformation division that is “Customer” Focussed. In
                  2019 we also unveiled an{" "}
                  <NavLink
                    to={`/ex-digital/`}
                   
                    className="link"
                  >
                    EX.Digital
                  </NavLink>
                  Strategy centered around “Employee”. In 2020,{" "}
                  <NavLink
                    to={`/xx-digital/`}
                   
                    className="link"
                  >
                    XX.Digital
                  </NavLink>{" "}
                  became Everything Experience.
                </p>
                <NavLink
                  to={`/cx-digital/`}
                  style={{marginTop:"1em"}}
                  className="btn btn-secondary"
                >
                  Explore CX.Digital <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
            </div>
          </section>
          <section className="evolution-cx grey-bg">
            <h2 className="section-main-heading">
              EVOLUTION OF <span className="highlight bold-class">CUSTOMER EXPERIENCE (CX)</span>
            </h2>
            <span className="section-desc">
              Customer Experience has become a next competitive battlefield. Many
              Organizations see the customer experience as a sustainable source of
              competitive differentiation.
            </span>
            <span className="section-desc">
              Customer Experience (CX) is defined as the customer's perceptions and
              related feelings caused by the one-off and cumulative effect of
              interactions with a supplier's employees, systems, channels or products.
            </span>
            <div className="row page-wrap">
              <div className="col-5-half item">
                <div className="content-container">
                  <figure>
                    <img
                      src={`/img/svg/icons/cx-customer-service-icon.svg`}
                      alt="Customer Service"
                    />
                  </figure>
                  <div className="txt">
                    <h3 className="bold-class">Customer Service</h3>
                    <p>
                      For many decades Customer Service (also known as Post Sales
                      Service) has always been treated as a cost center meeting the
                      obligations of sales and business operations.
                    </p>
                  </div>
                </div>
                <div className="bar-container">
                  <div className="bar-block">
                    <span className="left-bar" style={{ width: "95%" }} />
                    <span className="right-bar" style={{ width: "5%" }} />
                  </div>
                  <div className="bar-value">
                    <span className="left-value">
                      <strong>95%</strong> Service
                    </span>
                    <span className="right-value">
                      <strong>5%</strong> CX
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-5-half item">
                <div className="content-container">
                  <figure>
                    <img
                      src={`/img/svg/icons/cx-marketers-icon.svg`}
                      alt="Marketers’ CX"
                    />
                  </figure>
                  <div className="txt">
                    <h3 className="bold-class">Marketers’ CX</h3>
                    <p>
                      Until 2016 Customer experience ranked low on the list of increased
                      expectation of marketing by senior leadership, spurring
                      reallocation of resources to more immediately impact revenue.
                    </p>
                  </div>
                </div>
                <div className="bar-container">
                  <div className="bar-block">
                    <span className="left-bar" style={{ width: "75%" }} />
                    <span className="right-bar" style={{ width: "25%" }} />
                  </div>
                  <div className="bar-value">
                    <span className="left-value">
                      <strong>75%</strong> Marketing
                    </span>
                    <span className="right-value">
                      <strong>25%</strong> CX
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-5-half item">
                <div className="content-container">
                  <figure>
                    <img
                      src={`/img/svg/icons/cx-differentiation-icon.svg`}
                      alt="CX Differentiation"
                    />
                  </figure>
                  <div className="txt">
                    <h3 className="bold-class">CX Differentiation</h3>
                    <p>
                      Consumers today enjoy abundant choice and transparency. As this
                      erodes traditional product-based competitive advantages, marketers
                      must look to new sources of differentiation like Signature CX.
                    </p>
                  </div>
                </div>
                <div className="bar-container">
                  <div className="bar-block">
                    <span className="left-bar" style={{ width: "40%" }} />
                    <span className="right-bar" style={{ width: "60%" }} />
                  </div>
                  <div className="bar-value">
                    <span className="left-value">
                      <strong>40%</strong> Product
                    </span>
                    <span className="right-value">
                      <strong>60%</strong> Brand CX
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-5-half item">
                <div className="content-container">
                  <figure>
                    <img
                      src={`/img/svg/icons/cx-experience-centric-icon.svg`}
                      alt="Experience Centric"
                    />
                  </figure>
                  <div className="txt">
                    <h3 className="bold-class">Experience Centric</h3>
                    <p>
                      Customer Experience in the future will become a gold standard as{" "}
                      <strong>Customer Trust</strong> offering a greatest
                      differentiation. Powerful brands who embed CX in their DNA will
                      transcend into the “Experience Centric” Model.
                    </p>
                  </div>
                </div>
                <div className="bar-container">
                  <div className="bar-block">
                    <span className="left-bar" style={{ width: "25%" }} />
                    <span className="right-bar" style={{ width: "75%" }} />
                  </div>
                  <div className="bar-value">
                    <span className="left-value">
                      <strong>25%</strong> Customer Centric
                    </span>
                    <span className="right-value">
                      <strong>75%</strong> Experience Centric
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="cx-marketing">
            <h2 className="section-main-heading">
              CX AND <span className="highlight bold-class">MARKETING</span>
            </h2>
            <span className="section-desc">
              Customer experience as the marketing leaders' next battlefield — and what
              this will mean for you.
            </span>
            <span className="section-desc">
              Changes to customer experience, analytics, the expanding influence of
              Amazon and more mean marketers need to effectively balance competing
              demands. The COVID-19 has changed the priorities of CX Leaders and
              Marketing Leaders towards cost savings, resilience, and profitability.
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <blockquote>
                  By 2022, profitability will replace customer experience as the CMO’s
                  No. 1 strategic priority.
                </blockquote>
              </div>
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/cx-hierarchy.svg`}
                    alt="CX Heirarchy"
                  />
                </figure>
              </div>
              <div className="col-12">
                <p className="app-p">
                  CX has become an independent function of Marketing, Customer Service,
                  Sales, Operations, Employee Experience (EX)/HR and Commerce. This
                  simply means every department has an integrated CX component for the
                  brand's bigger vision of creating "experience" as a key
                  differentiator. This also gave the rise to the birth of new kind of a
                  CEO —{" "}
                  <NavLink
                    to={`/experienceo`}
                   
                    className="link"
                  >
                    ExperienCEO
                  </NavLink>
                  .
                </p>
              </div>
            </div>
          </section>
          <section className="cmo-cco light-blue-bg">
            <h2 className="section-main-heading">
              CHIEF MARKETING OFFICER <span className="red bold-class">VS.</span> CHIEF CUSTOMER
              OFFICER
            </h2>
            <span className="section-desc">
              Benchmarking against peers and competitors is an essential priority for
              the CMO when developing strategy and budget.
            </span>
            <span className="section-desc">
              Balancing the needs of individual departments to hit key performance
              indicators (KPIs) with the wider goal of improving the CX is the CCO role.
            </span>
            <div className="row page-wrap">
              <div className="col-5-half box-block">
                <div className="row page-wrap">
                  <div className="col-12 heading">CMO Strategic Imperatives</div>
                  <div className="col-4 item">
                    <figure>
                      <img
                        src={`/img/svg/icons/cmo-strategy-icon.svg`}
                        alt="Marketing Strategy Development"
                      />
                    </figure>
                    <h3>
                      Marketing
                      <br />
                      Strategy
                      <br />
                      Development
                    </h3>
                  </div>
                  <div className="col-4 item">
                    <figure>
                      <img
                        src={`/img/svg/icons/cmo-innovation-icon.svg`}
                        alt="Marketing and Business Innovation Acceleration"
                      />
                    </figure>
                    <h3>
                      Marketing and
                      <br />
                      Business Innovation
                      <br />
                      Acceleration
                    </h3>
                  </div>
                  <div className="col-4 item">
                    <figure>
                      <img
                        src={`/img/svg/icons/cmo-distruption-icon.svg`}
                        alt="Creating and Managing Disruption"
                      />
                    </figure>
                    <h3>
                      Creating and
                      <br />
                      Managing
                      <br />
                      Disruption
                    </h3>
                  </div>
                  <div className="col-4 item">
                    <figure>
                      <img
                        src={`/img/svg/icons/cmo-leaderdship-icon.svg`}
                        alt="Marketing Leadership and Organizational Effectiveness"
                      />
                    </figure>
                    <h3>
                      Marketing Leadership
                      <br />
                      and Organizational
                      <br />
                      Effectiveness
                    </h3>
                  </div>
                  <div className="col-4 item">
                    <figure>
                      <img
                        src={`/img/svg/icons/cmo-change-icon.svg`}
                        alt="Transformation and Change Management"
                      />
                    </figure>
                    <h3>
                      Transformation
                      <br />
                      and Change
                      <br />
                      Management
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-5-half box-block">
                <div className="row page-wrap">
                  <div className="col-12 heading">CCO Strategic Imperatives</div>
                  <div className="col-4 item">
                    <figure>
                      <img
                        src={`/img/svg/icons/cco-strategy-icon.svg`}
                        alt="CX Strategy Development"
                      />
                    </figure>
                    <h3>
                      CX
                      <br />
                      Strategy
                      <br />
                      Development
                    </h3>
                  </div>
                  <div className="col-4 item">
                    <figure>
                      <img
                        src={`/img/svg/icons/cco-customer-voice-icon.svg`}
                        alt="Customer Voice"
                      />
                    </figure>
                    <h3>
                      Voice of Customer
                      <br />
                      And Technology
                      <br />
                      Innovation
                    </h3>
                  </div>
                  <div className="col-4 item">
                    <figure>
                      <img
                        src={`/img/svg/icons/cco-customer-advocacy-icon.svg`}
                        alt="Customer Advocacy"
                      />
                    </figure>
                    <h3>
                      Differentiated value
                      <br />
                      and Customer
                      <br />
                      Advocacy
                    </h3>
                  </div>
                  <div className="col-4 item">
                    <figure>
                      <img
                        src={`/img/svg/icons/cco-cx-leaderdship-foundation-icon.svg`}
                        alt="CX Leadership"
                      />
                    </figure>
                    <h3>
                      CX Leadership
                      <br />
                      And Organizational
                      <br />
                      Foundation
                    </h3>
                  </div>
                  <div className="col-4 item">
                    <figure>
                      <img
                        src={`/img/svg/icons/cco-cx-operation-icon.svg`}
                        alt="CX Operation"
                      />
                    </figure>
                    <h3>
                      CX Continuum
                      <br />
                      and Operational
                      <br />
                      excellence
                    </h3>
                  </div>
                </div>
              </div>
              <span className="section-desc">
                Marketers can still seize control over the entire customer experience
                when they own very little of the operational functions to create it?
              </span>
              <div className="col-6 item1">
                <figure>
                  <img
                    src={`/img/svg/icons/cx-customer-journey-icon.svg`}
                    alt="Customer Journey"
                  />
                </figure>
                <div className="txt">
                  <h3 className="bold-class">Model the customer journey</h3>
                  <p>
                    Map and model the life cycle of a customer relationship, identifying
                    key customer touchpoints and opportunities for data capture and
                    handoffs.
                  </p>
                </div>
              </div>
              <div className="col-6 item1">
                <figure>
                  <img
                    src={`/img/svg/icons/cx-insight-icon.svg`}
                    alt="CX Insights"
                  />
                </figure>
                <div className="txt">
                  <h3 className="bold-class">Democratize CX insights</h3>
                  <p>
                    Move beyond hoarding of customer experience data to a culture of
                    sharing insights to drive operational improvements across the
                    organization.
                  </p>
                </div>
              </div>
              <div className="col-6 item1">
                <figure>
                  <img
                    src={`/img/svg/icons/cx-identify-wins-icon.svg`}
                    alt="Quick Wins"
                  />
                </figure>
                <div className="txt">
                  <h3 className="bold-class">Identify quick wins</h3>
                  <p>
                    Create role-based views of VOC data that filter and deliver
                    actionable CX insights and recommendations to the front-line
                    customer-facing employees.
                  </p>
                </div>
              </div>
              <div className="col-6 item1">
                <figure>
                  <img
                    src={`/img/svg/icons/cx-identify-root-cause-icon.svg`}
                    alt="Identify Root Cause"
                  />
                </figure>
                <div className="txt">
                  <h3 className="bold-class">Identify and correct root causes</h3>
                  <p>
                    Take action to understand patterns in CX insights and how they
                    reveal opportunities for broader strategic and operational
                    improvements.
                  </p>
                </div>
              </div>
              <div className="col-12">
                <NavLink
                  to={`/cx/strategy-design/`}
               
                  className="btn btn-secondary"
                >
                  MORE CX STRATEGIES <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
            </div>
          </section>
          {/*?php include '../../footer-consultare.php'; ?*/}
          <Footer />
        </main>

      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  )
}

export default Marries
