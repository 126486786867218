import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import './Surveystyle.css';
import Rating from '@mui/material/Rating';

const Survey = () => {
  const [surveyData, setSurveyData] = useState({
    webURL: `/survey`,
    formType: "E_Survey",
    fullname: '',
    email: '',
    stars1: '',
    stars2: '',
    stars3: '',
    stars4: '',
    feedback: '',
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSurveyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { webURL, formType, fullname, email, stars1, stars2, stars3, stars4, feedback } = surveyData;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            webURL: webURL,
            type: formType,
            fullname: fullname,
            email: email,
            ques1: stars1,
            ques2: stars2,
            ques3: stars3,
            ques4: stars4,
            ques6: feedback
          }
        ),
      });

      const data = await response.json();
      console.log("data is", data)

      if (!data) {
        console.log("message not sent");
      } else {
        navigate('/thankyou'); // Navigate to "/thankyou" route
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="talentare-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper survey">
          <h4
            className="survey_heading"
            style={{
              textAlign: 'center',
              color: 'black',
              fontSize: '1.5rem',
              marginTop: '2rem',
              fontWeight: '400',
            }}
          >
            Please enter your valuable feedback here
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        placeholder="Enter Fullname"
                        name="fullname"
                        value={surveyData.fullname}
                        onChange={handleChange}
                        required
                        style={{ fontWeight: 400 }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        placeholder="Enter Email"
                        name="email"
                        value={surveyData.email}
                        onChange={handleChange}
                        required
                        style={{ fontWeight: 400, marginLeft: '1rem' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4"></div>
            </div>
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4">
                <div className="row" style={{ marginTop: '20px' }}>
                  <div
                    className="col-12"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <label style={{ fontWeight: '400', color: 'black' }}>
                      1. Does the product/platform meet your business requirement?
                    </label>
                    <div className="stars">
                      <Rating
                        name="stars1"
                        value={surveyData.stars1}
                        style={{ fontSize: '2.6rem', marginLeft: '1rem' }}
                        onChange={(event, newValue) =>
                          setSurveyData((prevData) => ({
                            ...prevData,
                            stars1: newValue,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div
                    className="col-12"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <label style={{ fontWeight: '400', color: 'black' }}>
                      2. Was the presenter able to understand your business requirement?
                    </label>
                    <div className="stars">
                      <Rating
                        name="stars2"
                        style={{ fontSize: '2.6rem', marginLeft: '1rem' }}
                        value={surveyData.stars2}
                        onChange={(event, newValue) =>
                          setSurveyData((prevData) => ({
                            ...prevData,
                            stars2: newValue,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div
                    className="col-12"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <label style={{ fontWeight: '400', color: 'black' }}>
                      3. Were you satisfied with the response and understanding shared by the presenter?
                    </label>
                    <div className="stars">
                      <Rating
                        name="stars3"
                        style={{ fontSize: '2.6rem', marginLeft: '1rem' }}
                        value={surveyData.stars3}
                        onChange={(event, newValue) =>
                          setSurveyData((prevData) => ({
                            ...prevData,
                            stars3: newValue,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div
                    className="col-12"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <label style={{ fontWeight: '400', color: 'black' }}>
                      4. Were you satisfied with the interaction?
                    </label>
                    <div className="stars">
                      <Rating
                        name="stars4"
                        style={{ fontSize: '2.6rem', marginLeft: '1rem' }}
                        value={surveyData.stars4}
                        onChange={(event, newValue) =>
                          setSurveyData((prevData) => ({
                            ...prevData,
                            stars4: newValue,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div
                    className="col-12"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <label style={{ fontWeight: '400', color: 'black' }}>
                      5. What could have been better (Any Feedback)?
                    </label>
                    <textarea
                      maxLength="200"
                      id="message"
                      name="feedback"
                      rows="5"
                      value={surveyData.feedback}
                      onChange={handleChange}
                      placeholder="Write your message in 250 characters"
                    ></textarea>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                  <div
                    className="col-12"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <button type="submit" className="surveybutton">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-4"></div>
            </div>
          </form>
        </main>
        <Footer />
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Survey;
