import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Giftcart_Launches_Its_Beta_Commerce_Site = () => {
    useEffect(() => {
        document.title = "Giftcart.com launches its beta commerce site to the private community and the press – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/giftcart.com-launches-its-beta-website-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    The Leela Kempinski, Gurgaon - 12 May, 2012
                                </span>
                                <h2 className="article-heading">
                                    Giftcart.com launches its beta commerce site to the private community
                                    and the press
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Shaloo Reddi and Kristina Hermanns have founded Giftcart.com, an
                                    ecommerce company into gifting experiences. Giftcart has also
                                    recruited three senior leaders with their educational background –
                                    IIT, Delhi, IIM Ahmedabad, Asian Institute of Management(AIM), and
                                    Manila to supervise the marketing, operations and catalogue
                                    management.
                                </p>
                                <figure>
                                    <img
                                        src={`/img/giftcart-event-glimpse.jpg`}
                                        alt="Event Photos"
                                    />
                                </figure>
                                <br />
                                <p>
                                    Virtuos will be funding over US$ 1 Million investment into Giftcart
                                    operations through Tekcorp Portfolio.
                                </p>
                                <p>
                                    “We are excited to invest in Giftcart, an ecommerce company with a
                                    great vision and potential.” Said Venky Vijay Reddi, Virtuos-Tekcorp
                                    Co-Founder &amp; CEO.” Giftcart will become one-stop-shop for all of
                                    the gifting needs for modern consumers .”
                                </p>
                                <h3 className="title">Virtuos and Tekcorp in the News:</h3>
                                <div className="row journal">
                                    <div className="col-6 item">
                                        <NavLink
                                            to="https://www.vccircle.com/gifting-startup-giftcartcom-receives-1m-seed-capital-tekcorp/"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={`/img/svg/icons/vcc-logo.svg`}
                                                alt="VCC"
                                                style={{ width: "50%" }}
                                            />
                                        </NavLink>
                                    </div>
                                    <div className="col-6 item">
                                        <NavLink
                                            to="https://www.medianama.com/2012/05/223-giftcart-com-raises-1-million-as-seed-capital-from-tekcorps-need/"
                                            rel="noreferrer"
                                            target="_blank"
                                            className="link"
                                        >
                                            <img
                                                src={`/img/medianama-logo.png`}
                                                alt="Medianama"
                                                style={{ width: "60%" }}
                                            />
                                        </NavLink>
                                    </div>
                                </div>
                                <h3 className="title">About Giftcart</h3>
                                <p>
                                    Giftcart is an ecommerce startup funded by Virtuos Solutions through
                                    Tekcorp VC Portfolio of Investors headed by Venky Vijay Reddi. The
                                    company receives US$1Million investment linked to the company's growth
                                    over the next two to three years.
                                </p>
                                <p>
                                    Shaloo Reddi co-founds Giftcart.com along with her colleague Kristina
                                    Hermanns and Giftcart is headquartered in New Delhi.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.giftcart.com" target="_blank" className="link">
                                        www.giftcart.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, and
                                    Internet Startups. Virtuos Ventures incubate and invest in high growth
                                    startup sectors and ideas.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />

                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Giftcart_Launches_Its_Beta_Commerce_Site