import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Attends_Gartner_2016 = () => {
    useEffect(() => {
        document.title = "Virtuos Attends It's Second Gartner Summit for Marketers and Forrester CXNYC – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-attends-gartner-summit-for-marketers-and-forrester-2016-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">San Diego, New York - 22 June, 2016</span>
                                <h2 className="article-heading">
                                    Virtuos attends its second Gartner Summit for Marketers and Forrester
                                    NYC
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Team attends the second Digital Marketing Summit for Marketers
                                    by Gartners in San Diego. The event was held on May 17 for three days
                                    attracting over 1500 attendees from all over the world.
                                </p>
                                <p>
                                    In the opening keynote,{" "}
                                    <NavLink
                                        to="https://www.gartner.com/analyst/15631/Yvonne-Genovese"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Yvonne Genovese
                                    </NavLink>
                                    , group vice president, Gartner for Marketing Leaders, shared that 73%
                                    of marketers either own or share P&amp;L accountability for delivering
                                    business value, putting new pressures on their ability to leverage
                                    data and innovation for growth. “Marketers sit at the epicenter of
                                    growth between the organization and the customer,” she noted.
                                </p>
                                <blockquote>
                                    Marketers sit at the epicenter of growth between the organization and
                                    the customer.
                                </blockquote>
                                <p>
                                    Virtuos team also attended its first #CXNYC in New York, organized by{" "}
                                    <NavLink
                                        to="https://events.forrester.com/ehome/index.php?eventid=185903&"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Forrester
                                    </NavLink>
                                    . It’s the most contemporary event exclusively focussing on Customer
                                    Experience Technologies and Services. About fifty of the leading
                                    vendors have showcased their technologies in the Expo.
                                </p>
                                <p>
                                    “I have been a big fan of George Colony, the founder of Forrester ever
                                    since I have seen some of his presentations and especially the{" "}
                                    <NavLink
                                        to="https://www.amazon.com/Mobile-Mind-Shift-Engineer-Business/dp/0991361008"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link"
                                    >
                                        Mobile Mindshift and his book
                                    </NavLink>{" "}
                                    at the Dreamforce #DF14 conference. Said Venky Vijay Reddi, CEO of
                                    Virtuos. “I have stayed in the USA for the #CXNYC event attending my
                                    US Ops and meeting our Partner 24(7), who happens to be the principal
                                    sponsor for this event.”
                                </p>
                                <p>
                                    In the evening, Virtuos CEO was invited by 247 CEO for dinner with
                                    some of the leading 24(7) customers that include SiriusXM Radio.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-247-meeting-pic.jpg`}
                                        alt="Virtuos meeting 247 team"
                                    />
                                </figure>
                                <h2 className="title">The event highlights include:</h2>
                                <div className="row highlights">
                                    <div className="col-6 item">
                                        <figure>
                                            <img
                                                src={`/img/gartner-summit-2016-icon1.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                        <p>
                                            60+ sessions featuring industry experts and Forrester analysts
                                        </p>
                                    </div>
                                    <div className="col-6 item">
                                        <figure>
                                            <img
                                                src={`/img/gartner-summit-2016-icon2.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                        <p>&gt;80% of attendees are director level and above</p>
                                    </div>
                                    <div className="col-6 item">
                                        <figure>
                                            <img
                                                src={`/img/gartner-summit-2016-icon3.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                        <p>1,100+ CX leaders, innovators, and practitioners</p>
                                    </div>
                                    <div className="col-6 item">
                                        <figure>
                                            <img
                                                src={`/img/gartner-summit-2016-icon4.jpg`}
                                                alt=""
                                            />
                                        </figure>
                                        <p>&gt;65% of attending companies achieve $1B+ in annual revenue</p>
                                    </div>
                                </div>
                                <h2 className="title">About CXNYC</h2>
                                <p>
                                    Forrester holds CX events in various important cities worldwide. Some
                                    of the most popular shows have been CXNYC, CXSF, amongst others. For
                                    more information visit{" "}
                                    <NavLink to="http://www.forrester.com" target="_blank" className="link">
                                        www.forrester.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media, and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Attends_Gartner_2016