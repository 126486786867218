import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Team_Attends_Oracle_2015 = () => {
    useEffect(() => {
        document.title = "Virtuos Team Attends Oracle APAC Partner Summit in Bangkok – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/oracle-apac-partner-summit-2015-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    Bangkok, Thailand - 15 January, 2015
                                </span>
                                <h2 className="article-heading">
                                    Virtuos Team attends Oracle APAC Partner Summit in Bangkok
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Oracle hosted its Global APAC Summit for Partners at Bangkok on
                                    January 12 to 14th 2015. Virtuos team have joined the Partner Summit.
                                </p>
                                <p>
                                    Virtuos CEO, along with Executive VP Shaloo Reddi, Director Solution
                                    Consulting Kristina Hermanns, attend Oracle Partner (APAC) Annual
                                    Summit in Bangkok.
                                </p>
                                <p>
                                    Amongst the few chosen Partners, Venky Vijay Reddi spoke on Virtuos
                                    integrated approach of Customer Experience (CX) and Employee
                                    Experience (EX) as one of the panelists.
                                </p>
                                <p>
                                    “Virtuos has long been a partner of Oracle focussing on Customer
                                    Experience (CX) by enabling organizations with Omni Channel and Cross
                                    Channel communications, providing customers the power of web-self
                                    service, and the departments with access to dashboards, analytics, and
                                    insights.” Said Venky Vijay Reddi. “With the increased shift towards
                                    “Customer 360”, we have helped brands to extend the experience flows
                                    to non-agents and departments like Accounts, Finance, and HR by using
                                    Oracle RightNow Portal Technologies.”
                                </p>
                                <p>
                                    Virtuos Team met several key executives from the APAC region on their
                                    vision for FY 16. The three-day event has given Virtuos teams the
                                    insights towards Product Roadmap for Oracle CXSuite and some of the
                                    new developments in the modern customer journey.
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services, and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely — Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas, and executing them with Virtuoso teams. Virtuos was
                                    founded in 2008 by cultivating an ambitious plan to become a
                                    multi-billion dollar company and a game changer by 2020. Thanks to the
                                    hard work and commitment of our team we continue to grow each year.
                                    With new innovative business ideas, delivering incredible
                                    transformation services and building powerful engagement platforms for
                                    the future we are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink
                                        to="http://www.fb.com/virtuos"
                                        target="_blank"
                                        className="link"
                                        rel="noreferrer"
                                    >
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Team_Attends_Oracle_2015