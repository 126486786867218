import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { NavLink } from "react-router-dom";
import ConnectWithUs from "./Forms/ConnectWithUs";
const Multimix = () => {
  const [activePhase, setActivePhase] = useState("phase-1");

  const handleClick = (event) => {
    const relValue = event.currentTarget.getAttribute("rel");
    setActivePhase(relValue);
  };
  useEffect(() => {
    document.title = "Embrace Multiexperience for Improved CX – RainbowCX";
  }, []);
  return (
    <div className="exp-cloud-page landing-page rainbowcx-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img src={`/img/svg/rainbow-cx-header-bg.svg`} alt="" />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-4 item-wrapper">
                  <h3 className="section-heading">Rainbow CX</h3>
                  <p>
                    Transcend Omnichannel Thinking and Embrace Multiexperience
                    for Improved CX.
                  </p>
                </div>
                <div className="col-6">
                  <figure>
                    <img
                      src={`/img/svg/icons/rainbowcx-logo.svg`}
                      alt="Rainbow CX"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/rainbowcx/`}>RAINBOW CX</NavLink>
                </li>
                <li>
                  <NavLink to={`/rainbowcx/multix/`} className="active">
                    Multiexperience
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/cx/strategy-design/`}>
                    STRATEGY &amp; DESIGN
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/cxnow/`}>CXNOW PROGRAM</NavLink>
                </li>
                <li>
                  <NavLink to={`/cxdesk/`}>CXDESK</NavLink>
                </li>
                <li>
                  <NavLink to={`/indicial/`}>INDICIAL</NavLink>
                </li>
                <li>
                  <NavLink to={`/cxnow/cxprimer/`}>CX PRIMER</NavLink>
                </li>
              </ul>
            </div>
          </section>
          <section className="exp-business">
            <h2 className="section-main-heading">
              Rainbow for{" "}
              <span className="highlight bold-class">Multi-Experience</span>
            </h2>
            <span className="section-desc">
              Technologies for Multi-Experience (MX) based Customer Journeys.
            </span>
            <span className="section-desc">
              Cross-channel and Omnichannel approaches have been around for a
              long time, yet most organizations have not delivered on its
              promise of seamless and a unified customer experience. It’s time
              for brands to embrace MultiX for creating signature CX across
              digital touchpoints and interaction sets.
            </span>
            <div className="section-desc">
              Rainbow CX Practice at Virtuos delivers a few dozens of
              technologies that are stitched together to offer CX Continuum,
              support channel-agnostic design, deliver seamless integration and
              drive CX Innovations.
            </div>
          </section>
          <section className="channels">
            <div className="row page-wrap">
              <div className="col-12 channel-img">
                <figure>
                  <img
                    src={`/img/svg/multichannel-img.svg`}
                    alt="Multichannel"
                  />
                </figure>
                <figure>
                  <img src={`/img/svg/omnichannel-img.svg`} alt="Omnichannel" />
                </figure>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <span className="section-desc">
                  The traditional channels of engagements are not aligned with
                  the dynamic and transient Customer Journeys. Organizations
                  need to shift Investments away from traditional channels and
                  channel-focused teams.
                </span>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12 multi-img">
                <figure>
                  <img
                    src={`/img/svg/multiexperience-img.svg`}
                    alt="Multiexperience"
                  />
                </figure>
                <div className="section-desc">
                  Rainbow CX helps organizations in designing new
                  Multi-Experience (MX) Journeys for customers using Mesh App
                  and Service Architecture (MASA) - the fundamental
                  architectural capabilities that enable the implementation of
                  CX Strategy.
                </div>
              </div>
            </div>
          </section>
          <section className="stack-container">
            <div className="row page-wrap">
              <div className="col-12">
                <h2 className="section-main-heading">
                  Rainbow MX{" "}
                  <span className="highlight bold-class">
                    Technologies Stack
                  </span>
                </h2>
                <div className="section-desc">
                  Modern MX (part of CX Ecosystem) should support the trend of
                  headless, API-centric systems such as commerce and content
                  management platforms. New multi grained services can also be
                  built to fill in the gaps of existing CRM, marketing, and
                  commerce capabilities. The C-Suite leaders must implement MASA
                  to effectively support the growing number of fit-for-purpose
                  app experiences for different customers, and even employees,
                  working with different types of devices, touchpoints, and
                  modalities.
                </div>
                <div className="section-desc">
                  The scope of Multi-Experience (MX) is constantly changing as
                  the organizational processes change and usually delivered
                  across five and more areas.
                </div>
              </div>
            </div>
            <div className="row page-wrap multi-icons offport-down">
              <div className="col-4 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <img
                      src={`/img/svg/icons/touch-mx-icon.svg`}
                      alt="Touch MX"
                    />
                  </span>
                  <div className="item-content">
                    <h3>Touch MX</h3>
                    <p>
                      Sensory models such as touch (haptic feedback), Workplace
                      technologies, Airports, Hospitality, and Retail showrooms
                      with AR, VR, and IOT support touch for MX.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <img
                      src={`/img/svg/icons/gestures-mx-icon.svg`}
                      alt="Gestures MX"
                    />
                  </span>
                  <div className="item-content">
                    <h3>Gestures MX</h3>
                    <p>
                      Gesture control, Gesture recognition or handheld
                      controllers provide hand and body tracking. Digital
                      wearables such as rings, Employee ID badges support
                      gestures.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <img
                      src={`/img/svg/icons/chat-mx-icon.svg`}
                      alt="Chat MX"
                    />
                  </span>
                  <div className="item-content">
                    <h3>Chat MX</h3>
                    <p>
                      Chat with touch and gestures, integrated Knowledge base
                      and AI support the modern applications involving MX.
                      Chatbots also deliver humanized Multi-Experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <img
                      src={`/img/svg/icons/voice-mx-icon.svg`}
                      alt="Voice MX"
                    />
                  </span>
                  <div className="item-content">
                    <h3>Voice MX</h3>
                    <p>
                      Signals from voice and expression to gesture and touch are
                      tracked. Voice MX includes the tone, intensity, and pitch
                      denote the expression and intent of interactions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <img
                      src={`/img/svg/icons/vision-mx-icon.svg`}
                      alt="Vision MX"
                    />
                  </span>
                  <div className="item-content">
                    <h3>Vision MX</h3>
                    <p>
                      Advanced cameras using augmented AI, machine vision, OCR,
                      image recognition, pattern recognition, facial
                      recognition, edge detection and motion detection.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 item-wrapper">
                <div className="item">
                  <span className="icon">
                    <img
                      src={`/img/svg/icons/other-mx-icon.svg`}
                      alt="Other MX"
                    />
                  </span>
                  <div className="item-content">
                    <h3>Other MX</h3>
                    <p>
                      Sensory channels will support advanced capabilities such
                      as emotion detection through facial expression analysis
                      and human health status through olfactory (smell)
                      analysis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="cx-ex-container">
            <div className="row page-wrap">
              <div className="col-12">
                <h2 className="section-main-heading">
                  Rainbow for connected{" "}
                  <span className="highlight bold-class">CX and EX</span>
                </h2>
                <span className="section-desc">
                  The cohesive and seamless connection between Employees and
                  Customers.
                </span>
                <span className="section-desc">
                  Employee experience (EX) can negatively influence operational
                  performance and customer experience (CX) when employees are
                  not personally invested in their jobs and organization. Rarely
                  do we hear of omnichannel thinking being applied to help
                  employees, other than funneling customer interactions to
                  contact center employees.
                </span>
                <figure>
                  <img
                    src={`/img/svg/rainbow-cx-header-img.svg`}
                    alt="CX and EX"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="digital-process-section">
            <h2 className="section-main-heading">
              <span className="highlight bold-class">Rainbow CX</span> by
              Project
            </h2>
            <span className="section-desc">
              <NavLink to={`/cxnow/`} className="link">
                CXNow{" "}
              </NavLink>
              Transformation involves dozens of CX Technologies for various
              disciplines of Experience Continuum. Typically these projects
              include reconfiguring customer processes, creating a single view
              of the customer or developing CXM strategy with a view of creating
              "Multi-Experience Organization". From our experience and Gartner’s
              research, we offer seven types of CX Projects that are prevalent.
            </span>
            <div className="row page-wrap digital-program-wrapper">
              {/* Clickable Tabs */}
              <div className="col-2 digital-process-tabs-wrapper vertical-clickable-tabs">
                <ul>
                  <li
                    rel="phase-1"
                    className={`item blue ${
                      activePhase === "phase-1" ? "active" : ""
                    }`}
                    onClick={handleClick}
                  >
                    <h3>1-4</h3>
                    <span>Types</span>
                  </li>
                  <li
                    rel="phase-2"
                    className={`item blue ${
                      activePhase === "phase-2" ? "active" : ""
                    }`}
                    onClick={handleClick}
                  >
                    <h3>5-7</h3>
                    <span>Types</span>
                  </li>
                </ul>
              </div>
              <div className="col-10 vertical-tab-content-wrapper digital-process-content-wrapper">
                {/* Phase 1 */}

                <div
                  id="phase-1"
                  className={`vertical-tab-content  blue ${
                    activePhase === "phase-1" ? "active" : ""
                  }`}
                >
                  <div className="row content-block">
                    <div className="col-3 item">
                      <h3 className="bold-class">
                        <span className="offport-zoomin bold-class">1</span>
                        Listen, Think, Do
                      </h3>
                      <ul>
                        <li className="header">
                          <strong>Collect Feedback</strong>
                        </li>
                        <li>Multichannel collection</li>
                        <li>Real-time alerts and actions</li>
                        <li>-</li>
                        <li className="header">
                          <strong>Analyze Opinion</strong>
                        </li>
                        <li>Value analysis</li>
                        <li>Market research</li>
                        <li>Segmentation Propensity modeling</li>
                        <li className="header">
                          <strong>Start a Conversation</strong>
                        </li>
                        <li>Expectations setting</li>
                        <li>Capturing intent</li>
                        <li>Manage dialogue</li>
                      </ul>
                    </div>
                    <div className="col-3 item">
                      <h3 className="bold-class">
                        <span className="offport-zoomin bold-class">2</span>From
                        Out to In
                      </h3>
                      <ul>
                        <li className="header">
                          <strong>Find Moments of Truth</strong>
                        </li>
                        <li>Process modeling</li>
                        <li>Identify the weakest link</li>
                        <li>Automate and escalate</li>
                        <li className="header">
                          <strong>Redesign Processes</strong>
                        </li>
                        <li>Quality controls</li>
                        <li>Trading efficiency and experience</li>
                        <li>Segmentation Propensity modeling</li>
                        <li className="header">
                          <strong>Adapt in Real Time</strong>
                        </li>
                        <li>Real-time rerouting</li>
                        <li>Analytical-driven process decisions</li>
                        <li>-</li>
                      </ul>
                    </div>
                    <div className="col-3 item">
                      <h3 className="bold-class">
                        <span className="offport-zoomin bold-class">3</span>Act
                        as One
                      </h3>
                      <ul>
                        <li className="header">
                          <strong>Achieve Consistent Experiences</strong>
                        </li>
                        <li>Single view of customer</li>
                        <li>Recognition</li>
                        <li>-</li>
                        <li className="header">
                          <strong>Share Answers</strong>
                        </li>
                        <li>Knowledge management</li>
                        <li>Skills inventory</li>
                        <li>Better search</li>
                        <li className="header">
                          <strong>Multichannel Availability</strong>
                        </li>
                        <li>Multichannel integration</li>
                        <li>Device-independent interaction</li>
                        <li>-</li>
                      </ul>
                    </div>
                    <div className="col-3 item">
                      <h3 className="bold-class">
                        <span className="offport-zoomin bold-class">4</span>Open
                        Up
                      </h3>
                      <ul>
                        <li className="header">
                          <strong>More Accessible</strong>
                        </li>
                        <li>Self-service</li>
                        <li>Track for customer</li>
                        <li>Add channels</li>
                        <li className="header">
                          <strong>Demonstrate Trust</strong>
                        </li>
                        <li>Honor privacy</li>
                        <li>Share data</li>
                        <li>Use only what you need</li>
                        <li className="header">
                          <strong>Encourage Participation</strong>
                        </li>
                        <li>Review and comparison</li>
                        <li>Communities</li>
                        <li>Social networks</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Phase 2 */}
                <div
                  id="phase-2"
                  className={`vertical-tab-content  blue ${
                    activePhase === "phase-2" ? "active" : ""
                  }`}
                >
                  <div className="row content-block">
                    <div className="col-3 item">
                      <h3 className="bold-class">
                        <span className="offport-zoomin bold-class">5</span>Get
                        Personal
                      </h3>
                      <ul>
                        <li className="header">
                          <strong>Personalize Products</strong>
                        </li>
                        <li>Configure to order</li>
                        <li>Mass customization</li>
                        <li>New product development</li>
                        <li className="header">
                          <strong>Customize Offers</strong>
                        </li>
                        <li>Bundling product/service</li>
                        <li>Personal pricing</li>
                        <li>-</li>
                        <li className="header">
                          <strong>Stripped-Down Simplification</strong>
                        </li>
                        <li>One size fits all</li>
                        <li>Standardization and scale</li>
                        <li>-</li>
                      </ul>
                    </div>
                    <div className="col-3 item">
                      <h3 className="bold-class">
                        <span className="offport-zoomin bold-class">6</span>
                        Alter Attitudes
                      </h3>
                      <ul>
                        <li className="header">
                          <strong>Empower Employees</strong>
                        </li>
                        <li>Education and training</li>
                        <li>Cultural values</li>
                        <li>Ownership of the experience</li>
                        <li className="header">
                          <strong>Recruit Differently</strong>
                        </li>
                        <li>Profiling the personalities</li>
                        <li>Balance teams</li>
                        <li>Recruit to brand</li>
                        <li className="header">
                          <strong>Make Clear the Responsibility</strong>
                        </li>
                        <li>Governance and policing</li>
                        <li>Responsibility</li>
                        <li>Compensation and contracts</li>
                      </ul>
                    </div>
                    <div className="col-3 item">
                      <h3 className="bold-class">
                        <span className="offport-zoomin bold-class">7</span>
                        Design Better
                      </h3>
                      <ul>
                        <li className="header">
                          <strong>Have a Strategy</strong>
                        </li>
                        <li>Executive enlightening</li>
                        <li>Ideal and real experiences</li>
                        <li>Program and project plans</li>
                        <li className="header">
                          <strong>Brand Execution</strong>
                        </li>
                        <li>Values and promise</li>
                        <li>Reputation</li>
                        <li>Communication</li>
                        <li className="header">
                          <strong>Design the Experience</strong>
                        </li>
                        <li>Benchmark usability and empathy</li>
                        <li>Digital design cool</li>
                        <li>-</li>
                      </ul>
                    </div>
                    <div className="col-3 btn-block">
                      <NavLink
                        to={`/consultare/professional-services/`}
                        className="btn btn-primary"
                      >
                        Prof Services
                      </NavLink>
                      <NavLink to={`/contact/`} className="btn btn-primary">
                        Contact Us
                      </NavLink>
                    </div>
                  </div>
                </div>
                <p>Source: Gartner</p>
              </div>
            </div>
          </section>
          <section className="two-col-full-section cloudle">
            <figure className="bg-banner">
              <img src={`/img/svg/rainbowcx-breaker-bg.svg`} alt="Rainbow CX" />
            </figure>
            <div className="page-wrap row cloudle-wrapper">
              <div className="col-3 illus offport-left">
                <figure>
                  <img
                    src={`/img/svg/icons/rainbowcx-logo.svg`}
                    alt="Rainbow CX"
                  />
                </figure>
              </div>
              <div className="col-8 offport-right">
                <div
                  className="logo-ticker-wrapper"
                  style={{ overflow: "hidden" }}
                >
                  <ul className="bxsliders logo-tickers" id="logosliders">
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/adobe-logo.svg`}
                          alt="Adobe Logo"
                          style={{ width: "40%" }}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/microsoft-logo.svg`}
                          alt="Microsoft Logo"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/google-logo.svg`}
                          alt="Google Logo"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/apple-logo-img.svg`}
                          alt="Apple Logo"
                          style={{ width: "40%" }}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/oracle-logo.svg`}
                          alt="Oracle Logo"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img src={`/img/tibco-logo.png`} alt="Tibco Logo" />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/salesforce-logo.svg`}
                          alt="Salesforce Logo"
                          style={{ width: "70%" }}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/ibm-logo.svg`}
                          alt="IBM Logo"
                          style={{ width: "70%" }}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/marketo-logo.svg`}
                          alt="Marketo Logo"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/icons/creatio-logo.svg`}
                          alt="Creatio Logo"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img src={`/img/svg/domo-logo.svg`} alt="DoMo Logo" />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img src={`/img/svg/sap-logo.svg`} alt="SAP Logo" />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/mulesoft-logo.png`}
                          alt="Mulesoft Logo"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/247-inc-logo.svg`}
                          alt="24[7] Logo"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <img
                          src={`/img/svg/verint-logo.svg`}
                          alt="Verint Logo"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="cx-stack-container">
            <div className="row page-wrap">
              <div className="col-12">
                <h2 className="section-main-heading">
                  <span className="highlight bold-class">Rainbow CX</span>{" "}
                  Technologies Stack
                </h2>
                <span className="section-desc">
                  Rainbow CX is all about delivering exemplary multi-experience
                  by working on the vision of Organization 360 and Customer 360
                  with the help of technologies such as accelerators, tools, and
                  extensions; frameworks; and industry leading integrations from
                  our <strong>Relationware</strong> domain practice.
                </span>
              </div>
            </div>
            <div className="page-wrap row items-container">
              <div className="col-5-half item offport-left">
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/crm-cx-portfolio-img.jpg`}
                      alt="CRM and CX Portfolio"
                    />
                  </figure>
                </div>
                <div className="col-7">
                  <h4 className="alt-font">
                    <NavLink to={`/consultare/crm-cx/`}>
                      CRM and CX Portfolio{" "}
                      <i className="fal fa-long-arrow-right" />
                    </NavLink>
                  </h4>
                  <p className="app-p">
                    CRM/CX Technologies and their functionality falls into five
                    main categories: sales, marketing, customer service, field
                    service, and e-commerce, as well as several cross-CRM
                    applications.
                  </p>
                </div>
              </div>
              <div className="col-5-half item offport-right">
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/cx-cookbook-img.jpg`}
                      alt="CX Cookbook by Rainbow"
                    />
                  </figure>
                </div>
                <div className="col-7">
                  <h4>
                    CX Cookbook by Rainbow{" "}
                    <i className="fal fa-long-arrow-right" />
                  </h4>
                  <p className="app-p">
                    Customers look for quick fixes to manage CX Continuum by
                    searching for accelerators, integrations, apps, connectors,
                    and other elements from a variety of sources. Rainbow
                    presents CX CookBook on how to do it quickly.
                  </p>
                </div>
              </div>
              <div className="col-5-half item offport-left">
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/digital-process-img.jpg`}
                      alt="Digital Process Automation"
                    />
                  </figure>
                </div>
                <div className="col-7">
                  <h4 className="alt-font">
                    <NavLink to={`/vivacis/`}>
                      Digital Process Automation{" "}
                      <i className="fal fa-long-arrow-right" />
                    </NavLink>
                  </h4>
                  <p className="app-p">
                    Ensure executive alignment with how{" "}
                    <strong>process automation</strong> supports a common
                    strategic vision of an organization.{" "}
                    <NavLink to={`/vivacis/`} className="link">
                      Vivacis
                    </NavLink>{" "}
                    portfolio includes RPA, CLM, CPQ, Quote-to-Cash, DPA, and
                    ERP Stack.
                  </p>
                </div>
              </div>
              <div className="col-5-half item offport-right">
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/rainbow-freshcx-img.jpg`}
                      alt="Rainbow FreshCX"
                    />
                  </figure>
                </div>
                <div className="col-7">
                  <h4>
                    Rainbow FreshCX <i className="fal fa-long-arrow-right" />
                  </h4>
                  <p className="app-p">
                    Startups and SMB needs a fresh start for delivering CX on
                    shoe-string budgets. FreshCX is a portfolio of few niche and
                    cool CRM technologies handpicked from the most innovative
                    companies.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="cf-form-wrapper grey-bg">
            <div className="page-wrap ">
              <h2 className="section-main-heading">
                RAINBOW DELIVERS{" "}
                <span className="highlight bold-class">DIFFERENTIATED CX </span>
                STRATEGY
              </h2>
              <span className="section-desc">
                Rainbow CX is a collection of smart technologies for CX
                Continuum. Chief Product Officers and Other C-Suite love what we
                are doing to aggrandize and enrich your CX.
              </span>
              {/* <NavLink
                                rel="enquiry-form"
                                className="btn btn-secondary popup-form-trigger offport-zoomin"
                            >
                                Contact Us
                            </NavLink> */}
              <ConnectWithUs />
            </div>
          </section>
          {/*?php include '../../footer-xonomy.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Multimix;
