import React, { useState } from "react";
import { Link } from "react-router-dom";

const QualtricsCards = () => {
  const [activeFilter, setActiveFilter] = useState("all");

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  // const filterItems = [
  //   { label: "All", filter: "all" },
  //   { label: "AI", filter: "ai" },
  //   { label: "Cloud", filter: "cloud" },
  //   { label: "CRM", filter: "crm" },
  //   { label: "CX", filter: "cx" },
  //   { label: "Digital", filter: "digital" },
  // ];

  // const portfolioItems = [
  //   {
  //     category: "ai",
  //     link: `${process.env.REACT_APP_BASE_URL}/creatio/`,
  //     image: `/img/creatio-filter-img.jpg`,
  //     title: "Virtuos Vivacis + Creatio CRM",
  //     subtitle: "See our Customer Success Stories",
  //   },
  //   {
  //     category: "cx",
  //     link: `${process.env.REACT_APP_BASE_URL}/cxnow`,
  //     image: `/img/cxnow-filter-img.jpg`,
  //     title: "Customer Experience Transformation",
  //     subtitle: "CXNOW Program",
  //   },
  //   {
  //     category: "cx",
  //     link: `${process.env.REACT_APP_BASE_URL}/oracle/`,
  //     image: `/img/oracle-filter-img.jpg`,
  //     title: "Virtuos transforms brands with better CX",
  //     subtitle: "Oracle CX Practice",
  //   },
  //   {
  //     category: "cx",
  //     link: `${process.env.REACT_APP_BASE_URL}/cxdesk`,
  //     image: `/img/svg/cxdesk-filter-img.svg`,
  //     title: "CX Continuum and Operations",
  //     subtitle: "CXDESK",
  //   },
  //   {
  //     category: "cx",
  //     link: `${process.env.REACT_APP_BASE_URL}/agiloft/`,
  //     image: `/img/agiloft-filter-img.jpg`,
  //     title: "Virtuous Vivacis + Agiloft CLM",
  //     subtitle: "See our Customer Success Stories",
  //   },
  //   {
  //     category: "digital",
  //     link: `${process.env.REACT_APP_BASE_URL}/c-digital/`,
  //     image: `/img/svg/cdigital-filter-img.svg`,
  //     title: "CRM Mastermind Strategy",
  //     subtitle: "C.Digital",
  //   },
  //   {
  //     category: "digital",
  //     link: `${process.env.REACT_APP_BASE_URL}/w-digital/`,
  //     image: `/img/svg/wdigital-filter-img.svg`,
  //     title: "Digital Workplace Practice",
  //     subtitle: "W.Digital",
  //   },
  //   {
  //     category: "digital",
  //     link: `${process.env.REACT_APP_BASE_URL}/business4-0/`,
  //     image: `/img/svg/business-4-filter-img.svg`,
  //     title: "Post COVID-19 Business Strategy",
  //     subtitle: "Business 4.0",
  //   },
  //   {
  //     category: "digital",
  //     link: "http://www.vedam.com",
  //     target: "_blank",
  //     image: `/img/vedam-filter-img.jpg`,
  //     title: "Digital Marketing Best Practices",
  //     subtitle: "VEDAM.COM",
  //   },
  //   {
  //     category: "crm",
  //     link: "http://www.sweven.com",
  //     image: `/img/sweven-filter-img.jpg`,
  //     alt: "Sweven, Salesforce CRM",
  //     title: "Sweven is a strategic BU of Virtuos",
  //     subtitle: "Salesforce CRM",
  //   },
  //   {
  //     category: "crm",
  //     link: `${process.env.REACT_APP_BASE_URL}/crm-digital/`,
  //     image: `/img/crm-digital-filter-img.jpg`,
  //     alt: "CRM Digital",
  //     title: "Sales, Service, Marketing, and ECommerce",
  //     subtitle: "CRM Digital",
  //   },
  //   {
  //     category: "crm",
  //     link: `${process.env.REACT_APP_BASE_URL}/microsoft`,
  //     image: `/img/microsoft-filter-img.jpg`,
  //     alt: "Microsoft",
  //     title: "Virtuos offers CRM and PowerApps",
  //     subtitle: "Microsoft Practice",
  //   },
  //   {
  //     category: "cloud",
  //     link: "http://www.sweven.com",
  //     image: `/img/sweven-filter-img.jpg`,
  //     alt: "Sweven, Salesforce CRM",
  //     title: "Sweven is a strategic BU of Virtuos",
  //     subtitle: "Salesforce CRM",
  //   },
  //   {
  //     category: "cloud",
  //     link: `${process.env.REACT_APP_BASE_URL}/oracle/`,
  //     image: `/img/oracle-filter-img.jpg`,
  //     title: "Virtuos transforms brands with better CX",
  //     subtitle: "Oracle CX Practice",
  //   },
  // ];

  const filterItems = [
        { label: "All", filter: "all" },
        { label: "PEOPLEXM", filter: "people" },
        { label: "PLATFORM", filter: "platform" },
        { label: "DESIGN", filter: "design" },
        { label: "PRODUCT", filter: "product" },
        { label: "ANALYTICS", filter: "analytics" },
      ];

      const portfolioItems = [
        {
          category: "people",
          link: `/creatio/`,
          image: `/img/customer-xm-img.jpg`,
          title: "CustomerXM",
          subtitle: "Customer lifetime value",
        },
        {
          category: "people",
          link: `/cxnow`,
          image: `/img/employee-xm-img.jpg`,
          title: "EmployeeXM",
          subtitle: "Attract and retain talent",
        },
        {
          category: "design",
          link: `/oracle/`,
          image: `/img/brand-xm-img.jpg`,
          title: "BrandXM",
          subtitle: "Improve awareness and perception",
        },
        {
          category: "product",
          link: `/cxdesk`,
          image: `/img/product-xm-img.jpg`,
          title: "ProductXM",
          subtitle: "Improve product market fit",
        },
        {
          category: "design",
          link: `/agiloft/`,
          image: `/img/design-xm-img.jpg`,
          title: "DesignXM",
          subtitle: "Uncover breakthrough insights",
        },
        {
          category: "platform",
          link: `/c-digital/`,
          image: `/img/core-xm-img.jpg`,
          title: "CoreXM",
          subtitle: "Run world-class research",
        },
        {
          category: "analytics",
          link: `/w-digital/`,
          image: `/img/xmdscvr-img.jpg`,
          title: "XMdscvr",
          subtitle: "Advanced conversation analytics",
        },
        {
          category: "platform",
          link: `/business4-0/`,
          image: `/img/xm-platform-img.jpg`,
          title: "XM Platform",
          subtitle: "Design the experiences people want next",
        },
        {
          category: "digital",
          link: "http://www.vedam.com",
          target: "_blank",
          image: `/img/vedam-filter-img.jpg`,
          title: "Digital Marketing Best Practices",
          subtitle: "VEDAM.COM",
        },
        {
          category: "crm",
          link: "http://www.sweven.com",
          image: `/img/sweven-filter-img.jpg`,
          alt: "Sweven, Salesforce CRM",
          title: "Sweven is a strategic BU of Virtuos",
          subtitle: "Salesforce CRM",
        },
        {
          category: "crm",
          link: `/crm-digital/`,
          image: `/img/crm-digital-filter-img.jpg`,
          alt: "CRM Digital",
          title: "Sales, Service, Marketing, and ECommerce",
          subtitle: "CRM Digital",
        },
        {
          category: "crm",
          link: `/microsoft`,
          image: `/img/microsoft-filter-img.jpg`,
          alt: "Microsoft",
          title: "Virtuos offers CRM and PowerApps",
          subtitle: "Microsoft Practice",
        },
        {
          category: "cloud",
          link: "http://www.sweven.com",
          image: `/img/sweven-filter-img.jpg`,
          alt: "Sweven, Salesforce CRM",
          title: "Sweven is a strategic BU of Virtuos",
          subtitle: "Salesforce CRM",
        },
        {
          category: "cloud",
          link: `/oracle/`,
          image: `/img/oracle-filter-img.jpg`,
          title: "Virtuos transforms brands with better CX",
          subtitle: "Oracle CX Practice",
        },
      ];


    let filteredItems = portfolioItems;

  if (activeFilter !== "all") {
    filteredItems = portfolioItems.filter((item) => item.category === activeFilter);
  } else {
    const uniqueItems = [];
    const uniqueKeys = [];

    filteredItems.forEach((item) => {
      const itemKey = `${item.alt}-${item.title}-${item.subtitle}`;

      if (!uniqueKeys.includes(itemKey)) {
        uniqueItems.push(item);
        uniqueKeys.push(itemKey);
      }
    });

    filteredItems = uniqueItems;
  }



  return (
    <section className="filter-container">
      <ul id="filters" className="clearfix">
        {filterItems.map((item) => (
          <li key={item.filter}>
            <span
              className={`filter ${activeFilter === item.filter ? "active" : ""
                }`}
              data-filter={`.${item.filter}`}
              onClick={() => handleFilterClick(item.filter)}
            >
              {item.label}
            </span>
          </li>
        ))}
      </ul>

      <div id="portfoliolist_s">
        {filteredItems.map((item, index) => (
          <div
            key={index}
            className={`portfolio ${item.category}`}
            data-cat={item.category}
          >
            <Link to={item.link}>
              <div className="portfolio-wrapper">
                <img src={item.image} alt={item.title} />
                <div className="label">
                  <div className="label-text">
                    <span className="text-title">{item.title}</span>
                    <span className="text-category">{item.subtitle}</span>
                  </div>
                  <div className="label-bg"></div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default QualtricsCards;