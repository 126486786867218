import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';


const Careers_FAq = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };

  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };
  useEffect(() => {
    document.title = "Frquently Asked Questions (FAQ) on Careers – Virtuos Careers";

  }, []);
  return (
    <div className='class="faq-page landing-page"'>
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-12">
                  <figure>
                    <img
                      src={`/img/svg/faq-header-img.svg`}
                      alt="FAQs Banner"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/careers/`}>CAREERS</NavLink>
                </li>
                <li>
                  <NavLink to={`/careers/faq/`} className="active">
                    FAQs
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/careers/jobs/`}>CURRENT JOBS</NavLink>
                </li>
                <li>
                  <NavLink to={`/careers/opportunities/`}>
                    JOB OPPORTUNITIES
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/careers/experience-job/`}>
                    EXPERIENCEJOB
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/careers/culture/`}>
                    CULTURE &amp; VALUES
                  </NavLink>
                </li>

              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" />  More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/careers/life-at-virtuos/`}>
                          LIFE AT VIRTUOS
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/careers/training-development/`}>
                          TRAINING and Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/careers/happiests-culture/`}>
                          HAPPIESTS CULTURE
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/careers/employee-benefits/`}>
                          EMPLOYEE BENEFITS
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/careers/360Outlook/`}>
                          360<sup>0</sup> OUTLOOK
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/careers/virtuos-alumni/`}>
                          VIRTUOS ALUMNI CLUB
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          {/* FAQ */}
          <section className="faq-section">
            <div className="page-wrap faq-wrapper">
              <div className="qa-item">
                <h3 className="section-heading">
                  <strong>Q:</strong> How do I apply?
                </h3>
                <p>
                  <strong>A:</strong> It's very easy! First, search for a job in{" "}
                  <NavLink to={`/careers/jobs/`} className="link">
                    section
                  </NavLink>
                  . Once you find the role you are interested, click the blue "Apply for
                  this Job" button next to the title of the role at the top-right corner
                  of the page. Submit your details and there you go!
                </p>
              </div>
              <div className="qa-item">
                <h3 className="section-heading">
                  <strong>Q:</strong> What if I don't have a formal resume or CV to
                  enter into the form?
                </h3>
                <p>
                  <strong>A:</strong> It is always better when you have an updated
                  resume/CV, but you can certainly apply even if you don’t have one.
                  Where a résumé or CV is requested, simply upload a brief description
                  of your background, experience (with dates of employment), educational
                  achievements, and skills. This information is necessary to evaluate
                  candidates.
                </p>
              </div>
              <div className="qa-item">
                <h3 className="section-heading">
                  <strong>Q:</strong> Should I send samples of my work (writing, code,
                  designs, etc.) with my resume or CV?
                </h3>
                <p>
                  <strong>A:</strong> This is will not be necessary while applying for a
                  job. It will be great to see your work when we meet face to face.
                </p>
              </div>
              <div className="qa-item">
                <h3 className="section-heading">
                  <strong>Q:</strong> I wanted to apply for a position that I saw here,
                  but now it's not listed. May I still apply?
                </h3>
                <p>
                  <strong>A:</strong> If a job isn't up on our site, then we're not
                  taking applications for it at this time. But we encourage you to
                  bookmark our Careers page and check for a relevant position again.
                </p>
              </div>
              <div className="qa-item">
                <h3 className="section-heading">
                  <strong>Q:</strong> I don’t have a status of my application?
                </h3>
                <p>
                  <strong>A:</strong> We work in a dynamic environment and often get too
                  many applications. We make it a point to personally go through all the
                  details shared in the resume/CV, which sometimes takes a lot of time.
                  Don’t worry, if we have a position matching your profile, we will try
                  and reach out to you within 5 working days.
                </p>
              </div>
              <div className="qa-item">
                <h3 className="section-heading">
                  <strong>Q:</strong> What is your interview process?
                </h3>
                <p>
                  <strong>A:</strong> It depends. For most positions, we begin the
                  process with a phone interview. If there's interest on both sides, we
                  invite the candidate for an on-site interview, typically with the
                  hiring manager. We may also ask you to do a presentation for us or
                  write a small code for us, depending on your profile!
                </p>
              </div>
              <div className="qa-item">
                <h3 className="section-heading">
                  <strong>Q:</strong> How long do you take to make a decision after an
                  interview?
                </h3>
                <p>
                  <strong>A:</strong> Once we've finished interviewing all of the
                  selected candidates and completed reference and background checks, we
                  try to make a final decision within a few business days.
                </p>
              </div>
              <div className="qa-item">
                <h3 className="section-heading">
                  <strong>Q:</strong> Can I apply for more than one job?
                </h3>
                <p>
                  <strong>A:</strong> Yes, you can apply for as many jobs as you want.
                </p>
              </div>
            </div>
          </section>
          <section className="grey-bg contact-career-section">
            <h3 className="section-main-heading">
              Still have any <span className="highlight">questions?</span>
            </h3>
            <span className="section-desc">
              Reach out to us today with all your queries regarding open positions at
              Virtuos.
            </span>
            <NavLink
              to={`/contact`}
              className="btn btn-secondary offport-zoomin"
            >
              Contact Us
            </NavLink>
          </section>
          {/*?php include '../../footer.php';?*/}
          <Footer />
        </main>

      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  )
}

export default Careers_FAq
