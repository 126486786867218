import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

const Virtuos_Launches_New_Edition_Of_Website = () => {
  useEffect(() => {
    document.title =
      "Virtuos Launches New Website 3.1 of Website – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img src={`/img/hand-pointing-www.jpg`} alt="" />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurgaon, 15 May 2018</span>
                <h2 className="article-heading">
                  Virtuos launches new edition (3.1) of website capturing its
                  vision for the future ahead of its full DXP next year
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos, a leading Customer Experience Transformation Services
                  and Consulting company has unveiled its latest website
                  (www.virtuos.com). The version 3.1 is a significant departure
                  from the previous designs incorporating more than 100 web
                  pages, thousands of images, and qualitative content capturing
                  our vision for the future ahead of Digital Experience Platform
                  (DXP) next year.
                </p>
                <p>
                  “Website plays a pivotal role in the minds of customers,
                  employees, and other constituents speaking company’s vision
                  and its strategy for the future,” said Venky Vijay Reddi, CEO,
                  Virtuos. “We have been able to convey our strategy behind the
                  creation of four important operations namely
                  Applications.Group, Business 4.0 Operations, Customer
                  Experience (CX), and Digital Transformation under Consultare
                  Division.”
                </p>
                <p>
                  Virtuos also ported a lot of content on its visionary
                  Experience Economy (Xonomy) initiative, a futuristic AI led
                  products, and services business.
                </p>
                <p>
                  The website 3.1 is still our work in progress bringing more
                  information to our users, customers, and constituents
                  worldwide. In a few weeks, we will be launching a separate
                  website for each of the regions where we operate — Americas,
                  EMEA, and APAC.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Corporation is a leading Customer Experience
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos Consultare delivers industry-specific counsel and the
                  integrated, end-to-end solutions that support its customers'
                  strategic, operational, and financial goals. Virtuos is an
                  Experience consultancy that provides superior customer moments
                  and implements leading CRM and Customer Experience
                  Applications of its Alliance Partners. Other services include
                  Our Industry 4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/cxdesk/`} className="link">
                    CX Desk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Consulting Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program covers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  On the 10<sup>th</sup> Anniversary, Virtuos created a new
                  division — Virtuos Xonomy. Virtuos Xonomy ideates and
                  incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/xonomy/ai/`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce, and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Launches_New_Edition_Of_Website;
