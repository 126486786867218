import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Drawer,
  Button,
  // List,
  // ListItem,
  // ListItemButton,
  // ListItemIcon,
  // ListItemText,
} from "@mui/material";
import ReCaptcha from "react-google-recaptcha";
// import emailjs from "@emailjs/browser";
import CloseIcon from "@mui/icons-material/Close";

const Subscribe = () => {
  const [emailError, setEmailError] = useState(false);
  const [userData, setuserData] = useState({
    webURL: `/news`,
    formType: "S_News",
    name: "",
    email: "",
  });
  const navigate = useNavigate();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(true);
  const handleRecaptchaChange = (value) => {
    console.log("Recaptcha");
    setRecaptchaValue(value);
    setCaptchaError(false);
  };
  //we are storign data in states
  const handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setuserData({ ...userData, [name]: value });
    console.log("inside handlechange");
    // console.log(userData.name)
  };
  const handleSubmit = async (e) => {
    console.log("insidde contcat form");
    e.preventDefault();
    const { webURL, formType, name, email } = userData;
    if (captchaError) {
      return;
    }
    const res = await fetch(`${process.env.REACT_APP_API_URL}/db/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
      body: JSON.stringify({
        webURL: webURL,
        type: formType,
        full_name: name,
        email: email,
      }),
    });
    const data = await res.json();

    if (!data) {
      console.log("message not send");
    } else {
      // alert( "message send" );
      setuserData({ ...userData, message: "" });
      navigate("/thankyou"); // Navigate to "/thankyou" route
    }

    //////////////////EMAIL JS//////////////////////

    /*emailjs
     .send(
       process.env.REACT_APP_SERVICE_ID,
       process.env.REACT_APP_TEMPLATE_ID,
       {
         from_name: userData.name,
         to_name: process.env.REACT_APP_TO_NAME,
         from_email: userData.email,
         to_email: process.env.REACT_APP_TO_EMAIL,
         message: userData.message,
       },
       process.env.REACT_APP_PUBLIC_KEY
     )
     .then(
       () => {

         // alert("Thank you. I will get back to you as soon as possible.");

         setuserData({
           name: "",
           email: "",
           phone: "",
           message: ""
         });

       },
       (error) => {

         console.error(error);

         // alert("Ahh, something went wrong. Please try again.");
       }
     );*/
    //////////////////EMAIL JS//////////////////////
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [message, setMessage] = useState("");
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleChangeMessage = (event) => {
    const value = event.target.value;
    setMessage(value);
  };

  const remainingChars = 200 - message.length;
  const [isHovered, setIsHovered] = useState(false);
  const buttonStyle = {
    color: isHovered ? "red" : "black",
    fontWeight: "bold",
    transition: "color 0.3s",
  };

  const containerStyles = {
    border: "1px solid #00a1fe",
    color: "#00a1fe",
    fontWeight: 400,
    padding: "14px 44px",
  };

  const containerHoverStyles = {
    background: "#00a1fe",
    color: "#fff",
    cursor: "pointer",
  };

  const [isHoveredButton, setIsHoveredButton] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHoveredButton(true);
  };

  const handleMouseLeave = () => {
    setIsHoveredButton(false);
  };

  const containerStyle = isHoveredButton
    ? { ...containerStyles, ...containerHoverStyles }
    : containerStyles;
  return (
    //     <div>
    //         <NavLink
    //             rel="enquiry-form"
    //             className="btn btn-secondary popup-form-trigger offport-zoomin"
    //         >
    //             Connect with Us
    //         </NavLink>
    //     </div>
    // )
    <div>
      {/* <NavLink onClick={toggleDrawer} style={{ border: '1px solid  #00a1fe', color: ' #00a1fe', fontWeight: 400, padding: '8px 20px' }}> */}
      <NavLink
        onClick={toggleDrawer}
        style={containerStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        Subscribe Now
      </NavLink>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={toggleDrawer}>
        <div
          className="form-wrapper page-wrap"
          style={{ height: "300px" }}
          id="crm-bottom-box"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 className="section-heading" style={{ marginTop: "1.5rem" }}>
              Subscribe Form
            </h3>
            <Button
              onClick={toggleDrawer}
              style={buttonStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <CloseIcon />
            </Button>
          </div>

          <div cf-context="">
            <form
              onSubmit={handleSubmit}
              action=""
              method="POST"
              id="E_Alliances"
            >
              <div className="row" id="gap1">
                <div className="col-6 left-wrapper">
                  <div className="input-wrapper hidden-fields">
                    <input type="text" name="pageURL" className="pageURL" />
                    <input
                      type="text"
                      name="formType"
                      id="formType"
                      defaultValue="E_Alliances"
                    />
                    <input
                      type="text"
                      name="location"
                      s
                      className="userLocation"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Full Name</label>
                    <input
                      name="name"
                      tabIndex={1}
                      type="text"
                      maxlenght={100}
                      value={userData.name}
                      onChange={handleInput}
                      required
                    />
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="">Email</label>
                    <input
                      name="email"
                      tabIndex={2}
                      value={userData.email}
                      onChange={handleInput}
                      required
                      type="email"
                    />
                  </div>
                </div>
                <div className="col-6 right-wrapper">
                  {/* <div className="input-wrapper"> */}
                  <ReCaptcha
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    onChange={handleRecaptchaChange}
                  />
                </div>
                {captchaError && (
                  <p
                    className="required-message"
                    style={{ fontSize: "12px", color: "red" }}
                  >
                    * Please select CAPTCHA
                  </p>
                )}
                {/* </div> */}
              </div>
              <div className="btn-wrapper">
                <input
                  className="btn btn-secondary"
                  type="submit"
                  value="SUBSCRIBE NOW"
                  tabIndex={7}
                />
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Subscribe;
