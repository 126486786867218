import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../../components/Navbar";
import Nav from "../../../components/Nav";
import Footer from "../../../components/Footer";
import NewsTwitter from "../../../components/NewsTwitter";
// import "./index.css"
const Events = () => {
  useEffect(() => {
    document.title =
      "Latest Events from Business and Industy – Virtuos News &amp; Events";
  }, []);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const stickyTabs = document.getElementById("sticky-news");
  //     const section = document.getElementById("sticky-content");
  //     const sectionBottom = document.getElementById("innovation2");
  //     const scrollY = window.scrollY;
  //     const sectionTop = section.offsetTop;
  //     const sectionBottomheight = sectionTop + sectionBottom.offsetHeight + sectionBottom.offsetHeight - 200;
  //     console.log(sectionBottomheight);
  //     const threshold = 100; // Adjust this value to determine when to change position
  
  //     if (scrollY >= sectionTop - threshold && scrollY <= sectionBottomheight - threshold) {
  //       stickyTabs.style.position = "fixed";
  //       stickyTabs.style.top = "10em";
  //       stickyTabs.style.marginLeft = "50em";
  //     } else {
  //       stickyTabs.style.position = "sticky";
  //       stickyTabs.style.top = "initial";
  //       stickyTabs.style.marginLeft = "initial";
  //     }
  //   };
  
  //   window.addEventListener("scroll", handleScroll);
  
  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          <section className="main-header-img">
            <figure>
              <img
                src={`/img/news-events-header-img.jpg`}
                alt="Virtuos News and Event"
              />
            </figure>
          </section>
          {/* Case Study */}
          <section className="news-events-wrapper">
            <div className="page-wrap row">
              <div className="col-8 news-articles-wrapper">
                <div className="news-events-tabs">
                  <ul className="page-wrap row">
                    <li className="col-3">
                      <NavLink to={`/news/`}>
                        NEWS
                      </NavLink>
                    </li>
                    <li className="col-3 active">
                      <NavLink
                        to={`/news/events/`}
                      >
                        EVENTS
                      </NavLink>
                    </li>
                    {/*<li class="col-3"><NavLink to={`/news/webinars/`}>WEBINARS</NavLink>
								</li>*/}
                  </ul>
                </div>
                <span className="subtitle">
                  Here are the some of the events where Virtuos is a delegate or
                  a sponsor. Experience Nation and CX Summit are the two events
                  promoted by Virtuos. For Sponsorship opportunities to these
                  events please write to{" "}
                  <NavLink
                    to="mailto:sponsor@virtuos.com"
                    target="_blank"
                    className="link"
                  >
                    sponsor@virtuos.com
                  </NavLink>
                </span>
                <h2 className="section-heading apph2">Featured</h2>
                <div className="row featured-article">
                  <NavLink
                    to="https://www.salesforce.com/dreamforce/"
                    rel="noreferrer"
                    target="_blank"
                    className="col-12 news-article"
                  >
                    <figure>
                      <img
                        src={`/img/salesforce-dreamforce-2019-img.jpg`}
                        alt="Salesforce Dreamforce 2019, San Francisco, USA"
                      />
                      <div className="hover-wrapper">
                        <span>
                          Know More <i className="fal fa-long-arrow-right" />
                        </span>
                      </div>
                    </figure>
                    <div className="title-wrapper">
                      <span className="date-posted">
                        19-22<sup>th</sup> Nov, 2019
                      </span>
                      <h3 className="title" style={{ fontWeight: "bold" }}>
                        Salesforce Dreamforce 2019, San Francisco, USA
                      </h3>
                      <p>
                        Dreamforce has grown into so much more than a conference
                        — it’s an experience like no other. From inspiring
                        keynotes and sessions to visionary thinking to
                        discovering the future of technology to actually
                        changing the world, it’s a truly empowering four days
                        and an event you won’t want to miss.
                      </p>
                    </div>
                  </NavLink>
                </div>
                <div className="small-articles-wrapper" id="innovation">
                  <div className="section-title">
                    <h2 className="section-heading apph2">Upcoming Events</h2>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <NavLink
                        to="https://www.gartner.com/en/conferences/na/applications-us"
                        rel="noreferrer"
                        target="_blank"
                        className="news-article"
                      >
                        <figure>
                          <img
                            src={`/img/gartner-application-strategies-solutions-summit-img.jpg`}
                            alt="Gartner Application Strategies & Solutions Summit, Las Vegas, NV"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            3<sup>rd</sup>-5<sup>th</sup> Dec, 2019
                          </span>
                          <h3 className="title">
                            Gartner Application Strategies &amp; Solutions
                            Summit, Las Vegas, NV
                          </h3>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-4">
                      <NavLink
                        to="https://www.gartner.com/en/conferences/na/marketing-symposium-us"
                        rel="noreferrer"
                        target="_blank"
                        className="news-article"
                      >
                        <figure>
                          <img
                            src={`/img/gartner-marketing-symposium-img.jpg`}
                            alt="Gartner Marketing Symposium XPO, San Diego"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            1<sup>st</sup>-3<sup>rd</sup> Jun, 2020
                          </span>
                          <h3 className="title">
                            Gartner Marketing Symposium XPO, San Diego
                          </h3>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-4">
                      <NavLink
                        to="https://www.gartner.com/en/conferences/emea/customer-experience-uk"
                        rel="noreferrer"
                        target="_blank"
                        className="news-article"
                      >
                        <figure>
                          <img
                            src={`/img/gartner-customer-experience-technology-summit-img.jpg`}
                            alt="Gartner Customer Experience & Technology Summit, London, UK"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            8<sup>th</sup>-9<sup>th</sup> Jun, 2020
                          </span>
                          <h3 className="title">
                            Gartner Customer Experience &amp; Technology Summit,
                            London, UK
                          </h3>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="small-articles-wrapper" id="innovation">
                  <div className="section-title">
                    <h2 className="section-heading apph2">Previous Events</h2>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <NavLink
                        to="https://www.gartner.com/en/conferences/apac/symposium-india"
                        rel="noreferrer"
                        target="_blank"
                        className="news-article"
                      >
                        <figure>
                          <img
                            src={`/img/gartner-it-symposium-img.jpg`}
                            alt="Gartner IT Symposium/Xpo™ 2019, Goa, India"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            11-14<sup>th</sup> Nov, 2019
                          </span>
                          <h3 className="title">
                            Gartner IT Symposium/Xpo™ 2019, Goa, India
                          </h3>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-4">
                      <NavLink
                        to="https://events.fastcompany.com/innovationfestival19"
                        rel="noreferrer"
                        target="_blank"
                        className="news-article"
                      >
                        <figure>
                          <img
                            src={`/img/fast-company-innovation-festival-img.jpg`}
                            alt="FastCompany Innovation Festival 2019, New York City (USA)"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            4-8<sup>th</sup> Nov, 2019
                          </span>
                          <h3 className="title">
                            FastCompany Innovation Festival 2019, New York City
                            (USA)
                          </h3>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-4">
                      <span className="news-article">
                        <figure>
                          <img
                            src={`/img/bpm-online-accelerate-2019-img.jpg`}
                            alt="Creatio (Formerly BPM’Online) Accelerate 2019, Orlando (USA)"
                          />
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            2<sup>nd</sup>-3<sup>rd</sup> May, 2019
                          </span>
                          <h3 className="title">
                            Creatio (Formerly BPM’Online) Accelerate 2019,
                            Orlando (USA)
                          </h3>
                        </div>
                      </span>
                    </div>
                    <div className="col-4">
                      <span className="news-article">
                        <figure>
                          <img
                            src={`/img/gartner-marketing-symposium-img.jpg`}
                            alt="Gartner Marketing Symposium XPO, San Diego"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            29<sup>th</sup>April-1<sup>st</sup> May, 2019
                          </span>
                          <h3 className="title">
                            Gartner Marketing Symposium XPO, San Diego
                          </h3>
                        </div>
                      </span>
                    </div>
                    <div className="col-4">
                      <span className="news-article">
                        <figure>
                          <img
                            src={`/img/gartner-application-strategies-solutions-summit-img.jpg`}
                            alt="Gartner Application Strategies & Solutions Summit, Las Vegas, NV"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            27<sup>th</sup>-29<sup>th</sup> Nov, 2018
                          </span>
                          <h3 className="title">
                            Gartner Application Strategies &amp; Solutions
                            Summit, Las Vegas, NV
                          </h3>
                        </div>
                      </span>
                    </div>
                    <div className="col-4">
                      <span className="news-article">
                        <figure>
                          <img
                            src={`/img/gartner-it-symposium-img.jpg`}
                            alt="Gartner Symposium, Goa, India"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            13<sup>rd</sup>-16<sup>th</sup> Nov, 2018
                          </span>
                          <h3 className="title">
                            Gartner Symposium, Goa, India
                          </h3>
                        </div>
                      </span>
                    </div>
                    <div className="col-4">
                      <NavLink
                        to="https://www.gartner.com/events/emea/customer-experience?utm_source=google&utm_medium=cpc&utm_campaign=evt_emea_2018_crm19i&gclid=EAIaIQobChMItI3wldy82gIViQsrCh1JrAgFEAAYBCAAEgK5SvD_BwE#top"
                        rel="noreferrer"
                        target="_blank"
                        className="news-article"
                      >
                        <figure>
                          <img
                            src={`/img/gartner-customer-experience-technologies-summit-img.jpg`}
                            alt="Gartner Customer Experience and Technologies Summit, London, U.K."
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            24-25<sup>th</sup> May, 2018
                          </span>
                          <h3 className="title">
                            Gartner Customer Experience and Technologies Summit,
                            London, U.K.
                          </h3>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-4">
                      <NavLink
                        to="https://www.gartner.com/events/na/digital-marketing"
                        rel="noreferrer"
                        target="_blank"
                        className="news-article"
                      >
                        <figure>
                          <img
                            src={`/img/gartner-digital-marketing-conference-img.jpg`}
                            alt="Gartner Digital Marketing Conference 2018, San Diego, CA"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            15-17<sup>th</sup> May, 2018
                          </span>
                          <h3 className="title">
                            Gartner Digital Marketing Conference 2018, San
                            Diego, CA
                          </h3>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-4">
                      <span className="news-article">
                        <figure>
                          <img
                            src={`/img/bpm-online-accelerate-2019-img.jpg`}
                            alt="Creatio (Formerly BPM’Online) Accelerate 2018, Boston, USA"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            3-4<sup>th</sup> May, 2018
                          </span>
                          <h3 className="title">
                            Creatio (Formerly BPM’Online) Accelerate 2018,
                            Boston, USA
                          </h3>
                        </div>
                      </span>
                    </div>
                    <div className="col-4">
                      <NavLink
                        to={`/news/events/virtuos-cx-summit-2016`}
                        className="news-article"
                      >
                        <figure>
                          <img
                            src={`/img/virtuos-cx-summit-img.jpg`}
                            alt="Virtuos CX Summit"
                          />
                          <div className="hover-wrapper">
                            <span>
                              Know More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </div>
                        </figure>
                        <div className="title-wrapper">
                          <span className="date-posted">
                            Being Planned, Stay Tuned!
                          </span>
                          <h3 className="title">
                            Virtuos CX Summit - Meet experts and learn new ways
                            to applying design thinking principles to transform
                            CX
                          </h3>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-3-half sticky-content"
                id="sticky-news"
                style={{ position: "sticky", top: 0 }}
              >
                <div className="twitter-feeds">
                  <NewsTwitter />
                </div>
              </div>
            </div>
          </section>
          <Footer/>
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Events;
