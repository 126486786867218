import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import { FoodBank } from "@mui/icons-material";
import Footer from "../../components/Footer";
import { NavLink } from "react-router-dom";

const Oracle_Service_Cloud = () => {
  useEffect(() => {
    document.title =
      "CRM and Customer Experience | Oracle Service Cloud – Virtuos Digital";
  }, []);

  return (
    <div
      className="solution-page product-page listing-page orc-page"
      id="all-p-h3"
    >
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="about-wrapper">
            <figure className="laptop-block">
              <img
                src={`/img/oracle-service-cloud-laptop-img.png`}
                alt="Oracle Service Cloud"
              />
            </figure>
            <div className="page-wrap row">
              <div className="col-6">&nbsp;</div>
              <div className="col-6">
                <h1 style={{ marginTop: "2rem" }}>
                  <strong className="highlight" style={{color:"#00a1fe"}}>Oracle</strong> Service Cloud
                </h1>
                <span className="desc">
                  Oracle Service Cloud is a market leader and best suited for
                  sizeable B2C complex Customer Experience Projects.
                </span>
                <p>
                  Virtuos is the numero uno in the implementation of Oracle
                  Service Cloud transforming more than 100 brands across Omni
                  channels in five countries. We have 10+ years of deep
                  expertise and experience edge in Retail, E-commerce, and
                  Travel verticals. Now our consulting services are designed and
                  delivered on a framework of Pace layers to meet your strategic
                  goals.
                </p>
                <NavLink
                  to={`/consultare/pace-layered/`}
                  className="btn btn-secondary"
                >
                  Go PLUS
                </NavLink>
              </div>
            </div>
          </section>
          <section className="solutions-wrapper grey-bg">
            <h2 className="section-main-heading">
              The Core of{" "}
              <span className="highlight">Oracle Service Cloud</span>
            </h2>
            <span className="section-desc">
              Customer experience and CRM implementation services continue to be
              in high demand with the advent of digital business transformation.
              CRM functionality falls into five main categories: sales,
              marketing, customer service, field service and e-commerce, as well
              as several cross-CRM applications.
            </span>
            <div className="page-wrap row solutions-container">
              <div className="col-3 item">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/email-icon.png`}
                      alt="Email Management"
                    />
                  </figure>
                </span>
                <h3>Email Management</h3>
                <p>
                  Configure and customize powerful email management solution
                  with intelligent filtering and experience routing mechanism.
                </p>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/chat-icon.png`}
                      alt="Chat"
                    />
                  </figure>
                </span>
                <h3>Chat</h3>
                <p>
                  Implement Chat Cloud Service with agent-enabled tools like
                  Chat Conferencing, Transferring, Integrated KM, and real-time
                  Analytics.
                </p>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/cti-icon.png`}
                      alt="CTI"
                    />
                  </figure>
                </span>
                <h3>CTI</h3>
                <p>
                  Use Platform to integrate Call Center telephony systems with
                  Agent Desktop providing agents single-screen-view to operate
                  on.
                </p>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/knowledge-management-icon.png`}
                      alt="Knowledge Management"
                    />
                  </figure>
                </span>
                <h3>Knowledge Management</h3>
                <p>
                  Design Self-learning knowledge-base with smart scoring and
                  omnichannel access enabling agents and customers a consistent
                  source of knowledge.
                </p>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/customer-portal-icon.png`}
                      alt="Customer Portal"
                    />
                  </figure>
                </span>
                <h3>Customer Portal</h3>
                <p>
                  Build Self-service portal which allows customers to access
                  knowledge, service requests, chat and communities.
                </p>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/workflow-icon.png`}
                      alt="Desktop Workflows"
                    />
                  </figure>
                </span>
                <h3>Desktop Workflows</h3>
                <p>
                  Construct drag-and-drop experience designer which guides
                  Agents through a consistent Servicing Process to minimize
                  chances of error.
                </p>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/agent-scripting-icon.png`}
                      alt="Agent Scripting"
                    />
                  </figure>
                </span>
                <h3>Agent Scripting</h3>
                <p>
                  Implement interactive scripting tool explicitly designed for
                  Call Center Agents to service customers with the right
                  information at the right moment.
                </p>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/guided-assistance-icon.png`}
                      alt="Guided Assistance"
                    />
                  </figure>
                </span>
                <h3>Guided Assistance</h3>
                <p>
                  Develop a step-by-step troubleshooting tool which effectively
                  allows Agents as well as Customers to diagnose product/service
                  related problems.
                </p>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/opportunity-management-icon.png`}
                      alt="Opportunity Management"
                    />
                  </figure>
                </span>
                <h3>Opportunity Management</h3>
                <p>
                  Configure Opportunity Tracking that comes with multi-stage
                  strategy support for sales agents and dozens of standard
                  pipeline reports.
                </p>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/campaign-con.png`}
                      alt="Outreach/Campaigns"
                    />
                  </figure>
                </span>
                <h3>Outreach/Campaigns</h3>
                <p>
                  Implement modules and components of Campaign Management along
                  with email-tracking tools designed to plan better audience
                  segmentation.
                </p>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/survey-icon.png`}
                      alt="Feedback/Surveys"
                    />
                  </figure>
                </span>
                <h3>Feedback/Surveys</h3>
                <p>
                  Design surveys that come bundled with Standard and Syndicated
                  Widgets which allows you to capture customer feedback from any
                  channel.
                </p>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/report-icon.png`}
                      alt="Reporting and Analysis"
                    />
                  </figure>
                </span>
                <h3>Reporting and Analysis</h3>
                <p>
                  OSvC is packed with hundreds of standard reports and even
                  allows building highly customized reports powered by an
                  Operational and a Replication Database.
                </p>
              </div>
              <div className="row col-12">
                <div className="col-8 offport-left">
                  <figure>
                    <img
                      src={`/img/solutions-mobile-img.png`}
                      alt="Solution Screenshot"
                    />
                  </figure>
                </div>
                <div className="col-4 offport-right">
                  <div className="feature-block">
                    <span className="icon">
                      <figure>
                        <img
                          src={`/img/svg/icons/fast-loading-icon.svg`}
                          alt="Fast Loading Optimized"
                        />
                      </figure>
                    </span>
                    <span className="txt" style={{ color: "#666" }}>
                      <strong>Fast Loading</strong> <br /> Optimized
                    </span>
                  </div>
                  <div className="feature-block">
                    <span className="icon">
                      <figure>
                        <img
                          src={`/img/svg/icons/responsive-icon.svg`}
                          alt="Responsive Design"
                        />
                      </figure>
                    </span>
                    <span className="txt" style={{ color: "#666" }}>
                      <strong>Responsive</strong> <br /> Design
                    </span>
                  </div>
                  <div className="feature-block">
                    <span className="icon">
                      <figure>
                        <img
                          src={`/img/svg/icons/pixel-perfect.svg`}
                          alt="Pixel Perfect"
                        />
                      </figure>
                    </span>
                    <span className="txt" style={{ color: "#666" }}>
                      <strong>Pixel</strong> <br /> Perfect
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="full-width-container">
            <div className="our-products travel">
              <div className="row page-wrap">
                <div className="col-3 offport-left">
                  <figure className="illus">
                    <img
                      src={`/img/svg/icons/travel-icon.svg`}
                      alt="B2C Travel Giants"
                    />
                  </figure>
                </div>
                <div className="col-3 info offport-down">
                  <span>
                    Why India’s leading B2C Travel Giants trust Oracle Service
                    Cloud?
                  </span>
                </div>
                <div className="col-3 offport-right">
                  <NavLink
                    to={`/customers`}
                    className="btn btn-primary"
                  >
                    I want to Explore
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="cx-services-wrapper">
            <h2 className="section-main-heading">
              CX <span className="highlight">CONSULTING</span> SERVICES
            </h2>
            <span className="section-desc">
              Virtuos approaches the market from both a business consulting
              approach, as well as with a technology-enabled transformation
              perspective with clearly identified value drivers. Virtuos also
              focuses on complex front office transformations belonging to
              multichannel CX, digital design, and mobility across diverse
              industries.
            </span>
            <div className="row services-container">
              <div className="col-12">
                <svg width={200} height={70}>
                  <path d="M 0,0 Q 65,5 70,70 Q 75,5 140,0" />
                </svg>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/svg/icons/professional-services-icon.svg`}
                      alt="Professional Services"
                    />
                  </figure>
                </span>
                <h3>Professional Services</h3>
                <p>
                  We deliver innovative solutions for better business insight,
                  adaptive business processes, and superior ownership
                  experience. We have strengths in CX strategy &amp; vision,
                  industry CRM Domain expertise, and digital design capabilities
                  along with strong Engineering competencies in integration,
                  customization, and digital process automation.
                </p>
                <NavLink
                  to={`/consultare/professional-services`}
                  className="btn btn-primary offport-zoomin"
                >
                  Learn More
                </NavLink>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/svg/icons/customer-engagement-icon-white.svg`}
                      alt="Customer Engagement Hub"
                    />
                  </figure>
                </span>
                <h3>Customer Engagement Hub</h3>
                <p>
                  Customer Engagement Hub (CEH) enables organizations to align
                  their processes with customer intent to orchestrate a seamless
                  customer experience. We apply Relationware Integration “lens”
                  to envision your agile and always evolving CEH to handle
                  changing business demands.
                </p>
                <NavLink
                  to={`/consultare/crm-cx`}
                  className="btn btn-primary offport-zoomin"
                >
                  Learn More
                </NavLink>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/svg/icons/cx-transformation-icon.svg`}
                      alt="CX Transformation"
                    />
                  </figure>
                </span>
                <h3>CX Transformation</h3>
                <p>
                  Industry’s first and integrated CX.Digital Transformation
                  comprises of our niche CX strategy and design; demand
                  generation and sales enablement; branding and value
                  proposition; content and multi-channel marketing. Our unique
                  7C Model creates a structure to understand your customer
                  insights and help you build a customer-centric culture.
                </p>
                <NavLink
                  to={`/cx/strategy-design`}
                  className="btn btn-primary offport-zoomin"
                >
                  Learn More
                </NavLink>
              </div>
              <div className="col-3 item offport-down">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/svg/icons/cxdesk-icon-white.svg`}
                      alt="CXDesk Services"
                    />
                  </figure>
                </span>
                <h3>CXDesk Services</h3>
                <p>
                  We support CX Applications, Technologies &amp; Integrations in
                  bimodal IT environment delivering superior user experience and
                  higher adoption levels. We use most modern Software Admin,
                  Audit, QA, and Project management tools to offer professional,
                  timely, and qualitative CX Continuum services.
                </p>
                <NavLink
                  to={`/cxdesk`}
                  className="btn btn-primary offport-zoomin"
                >
                  Learn More
                </NavLink>
              </div>
            </div>
          </section>
          <section className="talk-video-breaker">
          <video autoPlay="autoplay" loop="loop" preload="">
            <source
              src={`/img/videos/lets-talk.mp4`}
              type="video/mp4"
            />
          </video>
            <div className="row page-wrap content-block">
              <p style={{ color: "white" }}>
                The right team for your CX transformation
              </p>
              <h2 style={{ fontWeight: "bold" }}>Let's talk CX with experts</h2>
              <NavLink
                to={`/careers`}
                className="btn-container offport-zoomin"
              >
                <span>Work with Us</span>
              </NavLink>
            </div>
          </section>
          <section className="experts-wrapper">
            <h2 className="section-main-heading">
              Meet the head of Oracle{" "}
              <span className="highlight">CX Delivery</span>{" "}
            </h2>
            <span className="section-desc">
              Amarinder Singh (Ams), a fellow &amp; founder member of Virtuos
              Digital has supervised and managed over 100+ CX Projects across
              India, ANZ, APAC, and Europe. Ams also trained hundreds of CX
              Professionals through Springboard and Launchpad programs.
            </span>
            <div className="experts-container">
              <div className="row page-wrap offport-down">
                <div className="col-3 item">
                  <span className="pic">
                    <figure>
                      <img
                        src={`/img/amarinder-pic.jpg`}
                        alt="Amarinder Singh - Head of CX Services"
                      />
                    </figure>
                  </span>
                  <span className="name">Amarinder Singh</span>
                  <span className="post">Head of CX Services</span>
                  <span className="des">
                    CX Delivery champion having executed more than 50+ CX
                    projects under his belt.{" "}
                    <NavLink
                      to={`/consultare/experience-design/`}
                      className="link"
                    >
                      Experience Design Thinking
                    </NavLink>{" "}
                    is his asset.
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="connect-wrapper">
            <figure>
              <img
                src={`/img/voc.jpg`}
                alt="Connect To Cxify"
              />
            </figure>
            <div className="page-wrap row offport-up">
              <h2>Let's connect to cxify</h2>
              <p style={{ color: "white" }}>
                We are very excited to transform your customer experience across
                brand, digital, and commerce.
              </p>
            </div>
          </section>
          <section className="page-wrap connect-container">
            <div className="row">
              <div className="col-4 item offport-down">
                <span className="icon office">
                  <i className="fas fa-map-marker-alt" />
                </span>
                <span className="title">Our Office</span>
                <span className="text">Contact us to get prompt response</span>
                <NavLink
                  to={`/contact`}
                  className="btn btn-secondary"
                >
                  Open Map
                </NavLink>
              </div>
              <div className="col-4 item offport-down">
                <span className="icon email">
                  <i className="fal fa-envelope" />
                </span>
                <span className="title">Our Email</span>
                <span className="text">Email us to reach you quickly</span>
                <NavLink
                  to="mailto:info@virtuos.com"
                  className="btn btn-secondary"
                >
                  Send Email
                </NavLink>
              </div>
              <div className="col-4 item offport-down">
                <span className="icon support">
                  <i className="fas fa-headphones" />
                </span>
                <span className="title">Our Support</span>
                <span className="text"> Go to customer support portal</span>
                <NavLink
                  to="http://virtuos.custhelp.com"
                  className="btn btn-secondary"
                  rel="nofollow"
                  target="_blank"
                >
                  Open Incident
                </NavLink>
              </div>
            </div>
          </section>
          {/*?php include '../../footer.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Oracle_Service_Cloud;
