import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Virtuos_Creates_New_Blueprint = () => {
  const CustomPrevArrow = ({ onClick }) => (
    <div className="prev-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
  const CustomNextArrow = ({ onClick }) => (
    <div className="next-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  useEffect(() => {
    document.title =
      "Virtuos Creates New Blueprint for FY20 Growth By Building Company Wide Virtuosoship – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-creates-new-blueprint-for-fy20-growth-img-big.jpg`}
                  alt="Virtuos creates new blueprint for FY20 Growth"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurgaon, 02 April 2019</span>
                <h2 className="article-heading">
                  Virtuos creates new blueprint for FY20 Growth by building
                  Companywide VIRTUOSOSHIP to accelerate systems and speed
                  business.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Inspired by the power of Virtuoso teams, we launched Virtuos —
                  A company to play by a different set of rules than other
                  (traditional) <strong>organizations</strong> do.
                </p>
                <p>
                  <strong>Virtuoso means:</strong> Being Elite and Expert in a
                  specialized field. We have coined a new word — Virtuosoship —
                  It means the State of <strong>Being Virtuoso</strong>. Since
                  January 18, 2008, Virtuos transformed businesses of more than
                  100 leading brands to leverage differentiated Customer
                  Experience.
                </p>
                <h3 className="sub-title">VIRTUOSOSHIP MEANS:</h3>
                <div className="photo-slider-wrapper">
                  <Slider {...settings} className="bxslider photo-slider">
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuosoship-slider-img-01.jpg`}
                          alt="Virtuosoship"
                        />
                      </figure>
                    </div>
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuosoship-slider-img-02.jpg`}
                          alt="Virtuosoship"
                        />
                      </figure>
                    </div>
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuosoship-slider-img-03.jpg`}
                          alt="Virtuosoship"
                        />
                      </figure>
                    </div>
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuosoship-slider-img-04.jpg`}
                          alt="Virtuosoship"
                        />
                      </figure>
                    </div>
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuosoship-slider-img-05.jpg`}
                          alt="Virtuosoship"
                        />
                      </figure>
                    </div>
                  </Slider>
                </div>
                <br />
                <h3 className="sub-title">BEING VIRTUOSO WITH:</h3>
                <p>
                  <strong>SKILLS:</strong> Soft skills; Technical Skills,
                  Digital Skills, and Professional Skills <br />
                  <strong>HEURISTIC:</strong> Hands-on, Experimental, Fail Fast,
                  High Risk taking ability and Practical <br />
                  <strong>INNOVATIVE:</strong> Beginner’s mind, fresh and
                  smartest thinking <br />
                  <strong>PRODUCTIVE:</strong> High-Performance Targets and
                  Pursuit of excellence
                </p>
                <p>
                  Success Mantra hinges upon clear vision and strategy — How we
                  look at our customers, what's our value proposition, and how
                  do we differentiate ourselves?{" "}
                </p>
                <ul>
                  <li>
                    What kind of learning and growth an employee has with
                    skills, and developing expertise to remain at the cutting
                    edge
                  </li>
                  <li>
                    How innovative is an Employee to bring Internal Process and
                    design value drivers?
                  </li>
                  <li>How do we look to our stakeholders? And,</li>
                  <li>What contribution an employee has made</li>
                </ul>
                <p>
                  Under the new FLAIR (Financial, Learning, Alignment, Internal
                  Growth, and Results from Customer Satisfaction) Band, new
                  weightage (also known as "maulya") as per employee role,
                  however, no single element is compromised to make up for
                  another strategy.
                </p>
                <h3 className="sub-title">Accelerate.Systems</h3>
                <p>
                  Automation everywhere to accelerate systems, templates, way of
                  functioning to deliver value to the customers. These systems
                  include OKRX — Objectives, Key Results, Experiences; 100% CRM
                  Adoption; and 100% Digital processing of Accounting and HR
                  Systems. Besides, we also follow Morning Huddles, InTune,
                  InTouch, and Connect regularly to bring transparency.
                </p>
                <p>
                  <img
                    src="../img/accelerate.systems-news-img.jpg"
                    className="border"
                  />
                </p>
                <h3 className="sub-title">Speed.Business</h3>
                <p>
                  Speed and Agility are critical to success. We have launched
                  Blitz scale operations to bring a sense of urgency in
                  everything we do for our customer acquisition, retention, and
                  efficiency processes.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. (Formerly Virtuos Solutions Private Ltd.)
                  is a leading Customer Experience (CX) &amp; Digital
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/xonomy/ai`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Newly created Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/cxdesk`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Creates_New_Blueprint;
