import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import { FoodBank } from '@mui/icons-material';
import Footer from '../../components/Footer';
// import Customer_Engagement_Form from './Forms/Customer_Engagement_Form';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Customer_Engagement_Form from './Forms/CustomerEngagementForm';

const Customerthree_CI = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,


  };

  const [showExtraLinks, setShowExtraLinks] = useState(false);

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };

  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };

  useEffect(() => {
    document.title = "Customer 360 Continuous Intelligence by C.Digital Mastermind – Virtuos Digital";

  }, []);
  return (
    <div className="landing-page ci-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5 item-wrapper">
                  <h3 className="section-heading">CONTINUOUSNESS.</h3>
                  <p>That comes with Customer Intelligence</p>
                </div>
                <div className="col-6">
                  <figure>
                    <img
                      src={`/img/svg/ci-header-img.svg`}
                      alt="CI"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to="/c-digital/">C.DIGITAL</NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/c-digital/customer360/ci/`}
                    className="active"
                  >
                    CONTINUOUS INTELLIGENCE
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/`}>
                    CUSTOMER 360
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/ceh/`}>
                    CUSTOMER ENGAGEMENT HUB
                  </NavLink>
                </li>

              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" />  More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/c-digital/xlv/`}>EXPERIENCE ID</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/c-digital/customer-wide/`}>
                          CUSTOMER WIDE
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/c-digital/digital-dragons/`}>
                          DIGITAL DRAGONS
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="key-issues">
            <h2 className="section-main-heading">
              Customer 360 <span className="highlight">Key Issues</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="items-container">
                  <NavLink
                    to={`/c-digital/customer360/#keyissue`}
                    className="item"
                  >
                    You may need a customer engagement hub.
                    <span className="number">1</span>
                  </NavLink>
                  <NavLink
                    to={`/c-digital/customer360/ci/`}
                    className="item active"
                  >
                    You may need real-time continuous intelligence in your customer
                    engagement hub.
                    <span className="number">2</span>
                  </NavLink>
                  <NavLink
                    to={`/c-digital/customer360/ceh/`}
                    className="item"
                  >
                    Where to start to build your real-time customer engagement hub?
                    <span className="number">3</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="virgin-train">
            <figure className="bg-banner">
              <img
                src={`/img/train-enquiry-dashbard-bg.jpg`}
                alt="Train Enquiry Dashboard"
              />
            </figure>
            <div className="row page-wrap items-container">
              <h2 className="section-main-heading">Virgin Trains’ Customer Moment</h2>
              <div className="col-4">
                <div className="solution">
                  <h3>The solution</h3>
                  <span>
                    Your train to Manchester has arrived! We’re just giving it a quick
                    spruce and we’ll text shortly.
                  </span>
                  <span>
                    Your train to Manchester is boarding on Platform 2. You’re in coach
                    A, seats 12, and 13.
                  </span>
                </div>
                <div className="result">
                  <h3>The Result</h3>
                  <div className="items-container">
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/svg/icons/ticket-icon.svg`}
                          alt="Ticket Icon"
                        />
                      </figure>
                      <p>
                        Increased Digital <br />
                        Ticket Sales
                      </p>
                    </div>
                    <div className="item">
                      <figure>28%</figure>
                      <p>
                        Net Promoter <br />
                        Score boost
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-7">
                <figure>
                  <img
                    src={`/img/virgin-train-alert-img.png`}
                    alt="Virgin Train Alert"
                  />
                </figure>
                <div className="note1">
                  <h3>Branding</h3>
                  <p>Brand name, logo and colors.</p>
                </div>
                <div className="note2">
                  <h3>True Metrics</h3>
                  <p>Client based Delivery and Reciepts to track and measure </p>
                </div>
                <div className="note3">
                  <h3>Suggested Reply</h3>
                  <p>
                    Choose a suggested reply like "Change Seat" to navigate to further
                    functionality.
                  </p>
                </div>
                <div className="note4">
                  <h3>Verified Sender</h3>
                  <p>Security and Peace of Mind for Consumers.</p>
                </div>
                <div className="note5">
                  <h3>Rich Media</h3>
                  <p>
                    Pictures, Videos, GIF sharing. Dynamic Content for tickets, Loyalty
                    Cards, Coupons etc.
                  </p>
                </div>
                <div className="note6">
                  <h3>Suggested Action</h3>
                  <p>
                    Select an Action button like "Contact Virgin" to initiate a phone
                    call.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="cust-moment light-blue-bg">
            <div className="row">
              <div className="col-6">
                <div className="row items">
                  <h2 className="section-main-heading">
                    <span className="highlight">Starbucks’</span> Customer Moment
                  </h2>
                  <div className="item">
                    <div className="col-5">
                      <h3 className="apph3">Anticipate customer needs:</h3>
                      <ul>
                        <li>Close to a store</li>
                        <li>In line</li>
                        <li>Ordering with app</li>
                        <li>Waiting for their order</li>
                        <li>Consuming in store</li>
                      </ul>
                    </div>
                    <div className="col-7">
                      <figure>
                        <img
                          src={`/img/starbuck-customer-moment-img.jpg`}
                          alt="Starbuck Customer Moment"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row items">
                  <h2 className="section-main-heading">
                    <span className="highlight">SNICKERS’</span> "Hungry" Moment
                  </h2>
                  <div className="item">
                    <div className="col-5">
                      <h3 className="apph3">Reaching new demographics based on "hangry" moments:</h3>
                      <ul>
                        <li>Consumers followed SNICKERS on Twitter</li>
                        <li>Tied price of a SNICKERS bar to the consumer sentiment</li>
                        <li>Price dropped when people got "angry"</li>
                      </ul>
                    </div>
                    <div className="col-7">
                      <figure>
                        <img
                          src={`/img/snickers-customer-moment-img.jpg`}
                          alt="Snickers Customer Moment"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="customer-moment">
            <h2 className="section-main-heading">
              Customer Moments Are Becoming Smaller <br /> and They Demand{" "}
              <span className="highlight">"Hyperpersonalization"</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-3">
                <ul>
                  <li>From</li>
                  <li>Customer journey</li>
                  <li>One to many</li>
                  <li>Personalization</li>
                  <li>Self-service</li>
                  <li>Omnichannel</li>
                </ul>
              </div>
              <div className="col-1 animate-arrow-wrapper">
                <ul>
                  <li />
                  <li>
                    <span className="animate-arrows-horizontal animated-arrows">
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                    </span>
                  </li>
                  <li>
                    <span className="animate-arrows-horizontal animated-arrows">
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                    </span>
                  </li>
                  <li>
                    <span className="animate-arrows-horizontal animated-arrows">
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                    </span>
                  </li>
                  <li>
                    <span className="animate-arrows-horizontal animated-arrows">
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                    </span>
                  </li>
                  <li>
                    <span className="animate-arrows-horizontal animated-arrows">
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-2">
                <ul>
                  <li />
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/customer-moment-icon1.svg`}
                        alt=""
                      />
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/customer-moment-icon2.svg`}
                        alt=""
                      />
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/customer-moment-icon3.svg`}
                        alt=""
                      />
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/customer-moment-icon4.svg`}
                        alt=""
                      />
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/customer-moment-icon5.svg`}
                        alt=""
                      />
                    </figure>
                  </li>
                </ul>
              </div>
              <div className="col-1 animate-arrow-wrapper">
                <ul>
                  <li />
                  <li>
                    <span className="animate-arrows-horizontal animated-arrows">
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                    </span>
                  </li>
                  <li>
                    <span className="animate-arrows-horizontal animated-arrows">
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                    </span>
                  </li>
                  <li>
                    <span className="animate-arrows-horizontal animated-arrows">
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                    </span>
                  </li>
                  <li>
                    <span className="animate-arrows-horizontal animated-arrows">
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                    </span>
                  </li>
                  <li>
                    <span className="animate-arrows-horizontal animated-arrows">
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                      <i className="fal fa-angle-right arrow" />
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-3">
                <ul>
                  <li>To</li>
                  <li>Customer moments</li>
                  <li>1:1</li>
                  <li>Hyperpersonalization</li>
                  <li>Guided service</li>
                  <li>Postchannel experience</li>
                </ul>
              </div>
            </div>
          </section>
          <section className="imp-cust-data grey-bg">
            <h2 className="section-main-heading">
              The Importance of Short-Term and Real-Time <br />
              <span className="highlight">Customer Data in Personalization</span>
            </h2>
            <div className="row page-wrap items-container">
              <div className="col-3 item">
                <ul>
                  <li>Persona and Segment</li>
                  <li>
                    <strong>Persona:</strong> 32-year-old-health-conscious woman living
                    in California with her family.
                  </li>
                  <li>
                    <strong>Segment:</strong> 25 − 35; Female; California resident;
                    loyal customer; discerning shopper; app user.
                  </li>
                </ul>
              </div>
              <div className="col-4 item mid">
                <figure>
                  <img
                    src={`/img/svg/icons/user-icon.svg`}
                    alt="User Icon"
                  />
                </figure>
                <h2>SARAH, 32</h2>
                <h3>Potential Shopper Journeys</h3>
                <p>
                  <strong>Monday:</strong> Sarah opens the retailer’s app in search of
                  this year’s back-to-school deals for her children.
                </p>
                <p>
                  <strong>Thursday:</strong> After browsing options online, Sarah stops
                  by the store to buy treats for her cat.
                </p>
                <p>
                  <strong>Saturday:</strong> Sarah buys a gift online for her husband,
                  then visits the store to pick it up the following day.
                </p>
              </div>
              <div className="col-3 item">
                <ul>
                  <li>Long-Term Behavior Insights</li>
                  <li>Transaction History</li>
                  <li>Past Brand Interactions</li>
                  <li>Previous Review and Questions</li>
                </ul>
                <ul>
                  <li>Short-Term Behavior Triggers</li>
                  <li>Current Online Browsing Patterns</li>
                  <li>In-Store Endpoint Interactions</li>
                  <li>Cart Items</li>
                  <li>Loyalty/Reward Point Balance</li>
                </ul>
                <ul>
                  <li>Environmental Considerations</li>
                  <li>Current Location</li>
                  <li>Local Time</li>
                  <li>Area Weather</li>
                </ul>
              </div>
            </div>
          </section>
          <section className="ci-exp">
            <h2 className="section-main-heading">
              Continuous Intelligence Is Required to <br />
              Deliver <span className="highlight">Continuous Experience</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/continuous-intelligence-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="edge-app light-blue-bg">
            <h2 className="section-main-heading">
              Leading Edge Applications Have Used <br />
              <span className="highlight">Continuous Intelligence</span> for Years
            </h2>
            <div className="row page-wrap">
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/edge-app-icon1.svg`}
                    alt=""
                  />
                </figure>
                <h3 className="bold-class">Mobile device navigation systems</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/edge-app-icon2.svg`}
                    alt=""
                  />
                </figure>
                <h3 className="bold-class">More than 80% of equity trades</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/edge-app-icon3.svg`}
                    alt=""
                  />
                </figure>
                <h3 className="bold-class">Google pop-up ads</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/edge-app-icon4.svg`}
                    alt=""
                  />
                </figure>
                <h3 className="bold-class">Airlines’ and railroads’ enterprise nervous systems</h3>
              </div>
            </div>
          </section>
          <section className="ci-mainstream">
            <h2 className="section-main-heading">
              <span className="highlight">Continuous Intelligence</span>: Why Going
              Mainstream Now?
            </h2>
            <div className="row page-wrap">
              <div className="col-12 items-container">
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/ci-icon-01.svg`}
                      alt=""
                    />
                  </figure>
                  <p>
                    Low cost, high performance, CPUs, GPUs, memory, storage, networks,
                    cloud computing, and mobile devices
                  </p>
                </div>
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/ci-icon-02.svg`}
                      alt=""
                    />
                  </figure>
                  <p>
                    Internet of Things, inexpensive sensor data and social media data
                  </p>
                </div>
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/ci-icon-03.svg`}
                      alt=""
                    />
                  </figure>
                  <p>
                    Widely available ML, AI, stream analytics, and time-series DBMS
                    software
                  </p>
                </div>
                <div className="item">
                  <figure>
                    <img
                      src={`/img/svg/icons/ci-icon-04.svg`}
                      alt=""
                    />
                  </figure>
                  <ul>
                    <li>Accelerating pace of business</li>
                    <li>Increased customer expectations</li>
                    <li>Worldwide competition</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="ci-analytics grey-bg">
            <h2 className="section-main-heading">
              Continuous Intelligence Enables Smarter Business Decisions <br />
              Through{" "}
              <span className="highlight">Real-Time Data and Advanced Analytics</span>
            </h2>
            <div className="row page-wrap items-container">
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/icons/ci-analytics-icon-01.svg`}
                    alt=""
                  />
                </figure>
                <p className="bold-class">Always on for situation awareness</p>
                <span className="number">1</span>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/icons/ci-analytics-icon-02.svg`}
                    alt=""
                  />
                </figure>
                <p className="bold-class">Prescribes action</p>
                <span className="number">2</span>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/icons/ci-analytics-icon-03.svg`}
                    alt=""
                  />
                </figure>
                <p className="bold-class">Proactive</p>
                <span className="number">3</span>
              </div>
            </div>
          </section>
          <section className="increase-capability">
            <h2 className="section-main-heading">
              <span className="highlight">Increase Capabilities</span> for Event-Driven,
              <br />
              Real-Time Situational Awareness
            </h2>
            <div className="row page-wrap">
              <div className="col-12 diagram">
                <figure>
                  <img
                    src={`/img/svg/increase-capability-diagram.svg`}
                    alt=""
                  />
                </figure>
                <span className="note-left">
                  <ul>
                    <li>Event visibility and context over customer journey</li>
                  </ul>
                </span>
                <span className="note-right">
                  <ul>
                    <li>Automate, act, and achieve customer experience</li>
                    <li>Unified messaging across inbound and outbound channels</li>
                    <li>Human interaction suggestions based on intelligence</li>
                  </ul>
                </span>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12 note-bottom">
                <ul>
                  <li>
                    Timely insight of cross-channel engagement efficiency and
                    effectiveness
                  </li>
                  <li>
                    Prescriptive analytics that prescribe the best course of action
                    (automated or human-driven)
                  </li>
                  <li>Event-driven recommendations</li>
                  <li>
                    Increased ability of executing CRM and customer experience
                    objectives
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="ci-cust-engage-hub">
            <h2 className="section-main-heading">
              Drive Continuous Intelligence Into{" "}
              <span className="highlight">Customer Engagement Hub</span>
            </h2>
            <div className="row page-wrap diagram">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/ci-customer-engagement-hub-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="cust-moment light-blue-bg">
            <div className="row page-wrap">
              <Slider {...settings} className="slider-container bxslider">
                <div className="col-12 item">
                  <h2 className="section-main-heading">
                    Decisions Based on{" "}
                    <span className="highlight">Continuous Intelligence</span> Are More
                    Effective
                  </h2>
                  <figure className="diagram">
                    <img
                      src={`/img/svg/ci-ecommerce-diagram.svg`}
                      alt=""
                    />
                  </figure>
                </div>
                <div className="col-12 item">
                  <h2 className="section-main-heading">
                    Integrated Continuous Intelligence Makes{" "}
                    <span className="highlight">360-Degree Customer Views</span> Into
                    360-Degree Real-Time Views
                  </h2>
                  <figure className="diagram">
                    <img
                      src={`/img/svg/ci-360-diagram.svg`}
                      alt=""
                    />
                  </figure>
                </div>
              </Slider>
            </div>
          </section>
          <section className="breaker-quote">
            <figure className="bg-banner">
              <img
                src={`/img/strategic-planning-breaker-bg.jpg`}
                alt="Strategic Planning"
              />
            </figure>
            <div className="row page-wrap" id="keyissue">
              <div className="col-8">
                <h2 className="bold-class">Strategic Planning Assumption</h2>
                <p className="bold-class">
                  By 2022, more than half of major new business systems will incorporate
                  continuous intelligence that uses real-time context data to improve
                  decisions.
                </p>
              </div>
            </div>
          </section>
          <section className="realtime-ceh">
            <h2 className="section-main-heading">
              How Does a <span className="highlight">Real-Time CEH</span> Look Like for
              a<span className="highlight">Bank</span>?
            </h2>
            <div className="roow page-wrap">
              <div className="col-12">
                <figure className="bg-banner">
                  <img
                    src={`/img/svg/ceh-for-bank-diagram.svg`}
                    alt="CEH for Bank Diagram"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="realtime-ceh">
            <h2 className="section-main-heading">
              How Does a <span className="highlight">Real-Time CEH</span> Look Like for
              a<span className="highlight">Retailer</span>?
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure className="bg-banner">
                  <img
                    src={`/img/svg/ceh-for-retailer-diagram.svg`}
                    alt="CEH for Retailer Diagram"
                  />
                </figure>
              </div>
            </div>
            <div style={{ marginTop: '26px' }}>
              {/* <Customer_Engagement_Form title={"Connect With Us"} /> */}
              {/* Form content */}
            </div>
          </section>
          {/*?php include '../../../footer-consultare.php';?*/}
          <Footer />
        </main>

      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div >
  )
}

export default Customerthree_CI
