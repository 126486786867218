import React from "react";

import ComingSoon from "../ComingSoon/ComingSoon";

const Wundero = () => {
  return (
    <div className="wundero">
      <ComingSoon />
    </div>
  );
};

export default Wundero;
