import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Virtuos_Moves_To_New_Office_edg = () => {
  const CustomPrevArrow = ({ onClick }) => (
    <div className="prev-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
  const CustomNextArrow = ({ onClick }) => (
    <div className="next-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  useEffect(() => {
    document.title =
      "Virtuos Digital Moves to Brand New Office at Emaar Digital Greens in Gurugram – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/emaar-digital-greens-img-big.jpg`}
                  alt="Virtuos - Emaar Digital Greens"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurgaon, 1 Nov 2018</span>
                <h2 className="article-heading">
                  Virtuos moves to brand new office at Emaar Digital Greens
                  located in the prestigious Golf Course Extension Road,
                  Gurugram.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos has moved to brand new office custom built as per the
                  needs of modern digital workplace offering spacious/open
                  workspaces for employees, Innovation On (formerly Innovation
                  Open) lounge for creativity, conference rooms, cafeteria,
                  spacious cabins, and executive spaces for key employees
                  include C-Suite.
                </p>
                <p>
                  Virtuos India head office is now in the prestigious Emaar
                  Digital Greens Tower (A), a 12-acre campus offering vast
                  greenery surrounded by the posh Golf Course Road locale and
                  amenities. With Metro, and other commuting options nearby,
                  Emaar Digital Greens offers elegant, and most creative
                  workspaces for digital transformation consulting companies
                  like Virtuos.
                </p>
                <div className="photo-slider-wrapper">
                  <Slider {...settings} className="bxslider photo-slider">
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuos-new-office-img-01.jpg`}
                          alt="Virtuos - Emaar Digital Greens"
                        />
                      </figure>
                    </div>
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuos-new-office-img-02.jpg`}
                          alt="Virtuos - Emaar Digital Greens"
                        />
                      </figure>
                    </div>
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuos-new-office-img-03.jpg`}
                          alt="Virtuos - Emaar Digital Greens"
                        />
                      </figure>
                    </div>
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuos-new-office-img-04.jpg`}
                          alt="Virtuos - Emaar Digital Greens"
                        />
                      </figure>
                    </div>
                    <div className="item">
                      <figure>
                        <img
                          src={`/img/virtuos-new-office-img-05.jpg`}
                          alt="Virtuos - Emaar Digital Greens"
                        />
                      </figure>
                    </div>
                  </Slider>
                </div>
                <br />
                <p>
                  “Digital fatigue is slowing down productivity in employees
                  worldwide, and they seek new ways of working that includes
                  excellent work-life balance, creative workspaces for close
                  collaboration, and most modern digital workplace tools,” said
                  Shaloo Reddi, VP-HR &amp; Strategy at Virtuos. “We have
                  invested nearly ₹2 million in brand new conference room
                  interactive boards, and other new office communication
                  equipment (in addition to fittings, and interiors) in the
                  newly furnished office to offer employees great ways of
                  collaborating and connecting with our customers worldwide.”
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. (Formerly Virtuos Solutions Private Ltd.)
                  is a leading Customer Experience (CX) &amp; Digital
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/xonomy/ai`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Newly created Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/cxdesk`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce, and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Cloudle</h3>
                <p>
                  Virtuos unveils Cloudle, a new Strategic Business Unit (SBU)
                  to focus on Cloud Applications, Business Solutions, and
                  Consulting Services catering to Small &amp; Medium Businesses
                  (SMB) worldwide. Cloudle with its 12 member team will soon be
                  expanded to more than 100 consultants operating from Gurugram,
                  and Hyderabad to serve customers on a 24x7x365 basis offering
                  Digital Software Subscriptions, Business Applications, and
                  Cloud-based software. Cloudle plans to develop an Accelerated
                  Insight Sales Model in alliance with leading information
                  services companies such as Dun &amp; Bradstreet, LinkedIn,
                  Hubspot, amongst others.
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Moves_To_New_Office_edg;
