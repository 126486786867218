import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Team_Attends_Oracle_San_Francisco = () => {
    useEffect(() => {
        document.title = "Virtuos Team attends Oracle Open World held at Moscone Center, San Francisco – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-team-attends-oow-2013-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">San Francisco - 26 September, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos Team attends Oracle Open World held at Moscone Center, San
                                    Francisco
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos, a gold certified Partner and Leader in CX Practice across has
                                    witnessed increase in the attendance to Oracle Open World this year.
                                    Virtuos team toured the entire event, and attended keynote sessions
                                    and interacted with key executives of Oracle.
                                </p>
                                <h2 className="title">Here are some of the highlights:</h2>
                                <br />
                                <p>
                                    <strong>
                                        Oracle is serious about the cloud as it launches ten services
                                    </strong>
                                    <br />
                                    Oracle launches 10 cloud services, as well as a cloud marketplace, to
                                    prove its commitment to the cloud.
                                </p>
                                <p>
                                    <strong>
                                        Businesses need to have constant digital conversations with the CIO
                                    </strong>
                                    <br />
                                    Companies which have strong digital conversations across their
                                    business are more likely to be successful in sales, innovation, and
                                    growth.
                                </p>
                                <p>
                                    <strong>Oracle launches in-memory database search </strong>
                                    <br /> Oracle has launched an in-memory version of its database which
                                    promises 100-times faster query processing.
                                </p>
                                <p>
                                    <strong>
                                        Incorporating big data lends competitive edge, says Intel
                                    </strong>
                                    <br />
                                    Only 15% of firms are fully exploiting big data, Doug Fisher, general
                                    manager of software and services at Intel, told Oracle OpenWorld 2013.
                                </p>
                                <p>
                                    <strong>European update - Oracle extends cloud</strong> <br /> Oracle
                                    is continuing to drive forward its cloud operations in Europe.
                                </p>
                                <p>
                                    <strong>
                                        Oracle UK Channel Boss - Partners must address legacy estates
                                    </strong>{" "}
                                    <br /> Oracle's new UK channel VP Will O'Brien has nailed his colours
                                    to the mast, urging resellers to expand their expertise.
                                </p>
                                <p>
                                    <strong>Oracle Wins Cup - Larry Ellison misses the keynote</strong>{" "}
                                    <br /> Larry Ellison was busy with the American Cup, and misses the
                                    important key note. Keynote was given by Thomas Kurien who reports to
                                    Larry. This has disappointed many partners and customers who were
                                    eagerly waiting to watch Keynote from Larry Ellison.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/oracle-open-world-2013-pics.jpg`}
                                        alt="Oracle Open World 2013"
                                    />
                                </figure>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services, and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely — Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas, and executing them with Virtuoso teams. Virtuos was
                                    founded in 2008 by cultivating an ambitious plan to become a
                                    multi-billion dollar company and a game changer by 2020. Thanks to the
                                    hard work and commitment of our team we continue to grow each year.
                                    With new innovative business ideas, delivering incredible
                                    transformation services and building powerful engagement platforms for
                                    the future we are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink
                                        to="http://www.fb.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Team_Attends_Oracle_San_Francisco