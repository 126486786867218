import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_USA_Trip = () => {
  useEffect(() => {
    document.title =
      "Virtuos to unveil NOVV — NewWe Digital Transformation Practice – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/dreamforce2023_Virtuos.jpg`}
                  alt="Virtuos to unveil NOVV — NewWe Digital Transformation Practice"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">San Francisco 11 Oct 2023</span>
                <h2 className="article-heading">
                  Virtuos Digital Teams tour USA to attend Dreamforce 2023 and
                  Engage 2023.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos Digital Teams attend Dreamforce 2023—one of the
                  largest tech events focussed on CRM, Customer Experience and
                  Digital Transformation hosted by Salesforce and its partners.
                </p>
                <p>
                  “It was my 10th in-person attendance to Dreamforce since
                  2011—Venky Vijay Reddi, ExperienCEO of Virtuos said. And it’s
                  always inspiring and refreshing to know the real latest trends
                  that are transforming how businesses operate in the digital
                  environment.”
                </p>
                <p>
                  “It’s my first Dreamforce and it is eye-catching and I could
                  not cover 5% as the scale and magnitude is so big —Amarinder
                  Singh, Senior Director said. And I am still catching up to
                  watch the missed sessions.”
                </p>
                <p>
                  Virtuos has recently partnered with Smartsheet, a leading Work
                  Management Platform based out of Bellevue, and the teams have
                  attended Smartsheet Global Conference—Engage2023 in Seattle.
                </p>
                <p>
                  Speaking about the Engage 2023, Venky Vijay Reddi said—It was
                  one of the brilliantly delivered Events with nearly 100%
                  attendance by many Smartsheet enthusiasts.
                </p>
                <p>
                  Further in their tour, Virtuos Teams also visited Creatio
                  headquarters in Boston and met the key Senior Management Teams
                  to discuss bigger strategies for our joint partnership.
                </p>
                {/* <p>
                  Virtuos NOVV will be officially launched in the first week of
                  April 2023 in India followed by the USA and rest of the
                  geographies.
                </p> */}
                {/* <h3 className="title">Two Businesses Within One Company.</h3>
                <p> */}{" "}
                {/* <NavLink
                    to={`/consultare`}
                    className="link"
                  >
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Microsoft Dynamics, Creatio Low Code No Code
                  Platform &amp; CRM Applications; Agiloft Contract Lifecycle
                  Management (CLM) amongst other Composable Products &amp;
                  Services. Virtuos Consulting services include Four Fabulous
                  business units namely —{" "} */}
                {/* Just like every mountain has a valley, every coin has two
                  sides and every human needs another companion, we created a
                  double edged business by unveiling Digital Twins. */}
                {/* 
                  <NavLink
                    to="http://www.virtuos.com/vivacis"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Vivacis
                  </NavLink>
                  ,{" "}
                  <NavLink
                    to="http://www.virtuos.com/veracis"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Veracis
                  </NavLink>
                  ,{" "}
                  <NavLink
                    to="http://www.virtuos.com/vaves"
                    trget="_blank"
                    className="link"
                  >
                    Virtuos Vaves
                  </NavLink>
                  , and{" "}
                  <NavLink
                    to="http://www.vedam.com"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Vedam
                  </NavLink>
                  . */}
                {/* </p>
                <p>
                  Virtuos Digital—the incumbent business of Virtuos Consulting
                  and Transformation Services including CRM, Customer
                  Experience, CLM, Work Management services, subscriptions and
                  platform engineering will be transferred to Virtuos Digital
                  which has a new identity and new website —vdc.com. The three
                  letter precious domain —vdc.com worth more than half-a-million
                  dollars is our latest acquisition to power Virtuos Digital
                  business.
                </p>
                <p>
                  Virtuos (virtuos.com) will be focussing on new ventures,
                  investments, innovating new products and portfolios that are
                  integral to Experience Economy. Current portfolio of ventures
                  namely Giftcart, Indic, Sweven and Tekcorp and Nexorama
                  composable products include Brandcart.com which is in its
                  beta; Wundero—an agile e-learning platform scheduled for
                  launch before Christmas 2023; Expedience— the talent advisory
                  and talent management platform will be unveiled on January
                  18th, 2024 at the Annual Day.
                </p>{" "}
                <p>
                  Combined Operations: Both the operations are part of a single
                  company and they continue to operate in the same premises with
                  a shared talent pool until each business grows to significant
                  size in revenue.
                </p>{" "}
                <p>
                  The grand Invincible vision will be unveiled on January 18,
                  2024 in Shimla India. Stay tuned for more updates.
                </p> */}
                <h1
                  className="title"
                  style={{ fontSize: "2rem", color: "#01a2fe" }}
                >
                  About Virtuos Group
                </h1>
                <h3 className="title">Virtuos</h3>
                <p>
                  {/* Virtuos{" "}
                  <NavLink
                    to={`/xonomy/`}
                    className="link"
                  >
                    Xonomy
                  </NavLink>{" "} */}
                  Virtuos ideates, incubates and invests in the next-generation
                  businesses harnessing Artificial Intelligence (AI), Cloud,
                  ECommerce, Blockchain and Digital technologies that are
                  integral to the futuristic{" "}
                  <NavLink to="/xonomy" target="_blank" className="link">
                    Experience Economy (Xonomy)
                  </NavLink>
                  . At Virtuos, we have incubated and invested in several
                  companies that include{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart
                  </NavLink>{" "}
                  — Gifting Experiences Commerce Company;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic
                  </NavLink>
                  — the Portfolio of Experience Commerce Brands;{" "}
                  <NavLink
                    to="http://www.sweven.com"
                    target="_blank"
                    className="link"
                  >
                    Sweven
                  </NavLink>{" "}
                  — Salesforce CRM Mastermind services; and{" "}
                  <NavLink
                    to="http://www.tekcorp.com"
                    target="_blank"
                    className="link"
                  >
                    Tekcorp
                  </NavLink>{" "}
                  — the Technology Infrastructure.
                </p>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We have launched
                  Brandcart.com—the brand naming platform for businesses.We will
                  be unleashing an integrated and agile marketing platform
                  Services and Digital Agency— Wundero; Building CRM Studio
                  Suite powered by Composable No Code Platform. In 2024 Virtuos
                  will also be launching Expedience — The Talent advisory and
                  sourcing platform
                </p>
                <p>
                  Virtuos created a brand new{" "}
                  <NavLink
                    to="http://www.aiambition.com/"
                    target="_blank"
                    className="link"
                  >
                    AI-Ambition
                  </NavLink>{" "}
                  to leverage and harness generative AI’s full competitive
                  potential along with other AI technologies. We will be
                  building the right portfolio, ecosystem, deployment model,
                  skills and governance to help enterprise customers create
                  their #AIAmbition.
                </p>
                <h3 className="title">Virtuos Digital (VDC)</h3>
                <p>
                  {/* <NavLink
                    to="http://www.experiencecloud.com"
                    target="_blank"
                    className="link"
                  >
                    Experience Cloud
                  </NavLink>{" "} */}
                  Virtuos Digital delivers industry-specific counsel and the
                  integrated, end-to-end solutions that support its customers'
                  strategic, operational, and financial goals. Virtuos Digital
                  (VDC) is an Experience consultancy that provides superior
                  customer moments and implements leading CRM and Customer
                  Experience Applications — Oracle CX; Salesforce Platforms,
                  Microsoft Dynamics, Creatio No Code Platform & CRM
                  Applications; Agiloft Contract Lifecycle Management (CLM);
                  Asana Work Management amongst other Composable Products &
                  Services. For more information on Virtuos Digital please visit{" "}
                  <NavLink to="/consultare" target="_blank" className="link">
                    www.virtuos.com/consultare
                  </NavLink>
                </p>
                {/* <h3 className="title">Virtuos Nexorama</h3>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We are building an
                  integrated and agile learning platform — Wundero; Carving out
                  a new business model building a marketplace for LowCode NoCode
                  Technologies by composing a new business UnCodify.com. Virtuos
                  will be launching Expedience — The Talent advisory and
                  sourcing platform and Audacis, a new digital marketing
                  services agency.
                </p> */}
                <div>
                  <p style={{ marginBottom: "0px" }}>
                    Digital Media Department{" "}
                  </p>
                  <NavLink
                    to="http://www.virtuos.com"
                    target="_blank"
                    className="link"
                  >
                    www.virtuos.com
                  </NavLink>
                </div>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_USA_Trip;
