import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Snaps_Thomas_Cook_India = () => {
    useEffect(() => {
        document.title = "Virtuos snaps Thomas Cook India as its new customer - a major win over the stiff competition – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-snaps-thomas-cook-india-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Mumbai - 20 May, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos snaps Thomas Cook India as its new customer- a major win over
                                    the stiff competition
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Consultare added a new logo to its customer list by offering
                                    comprehensive Customer Experience (CX) solutions on the cloud from
                                    Oracle RightNow stable to Thomas Cook India Ltd.
                                </p>
                                <p>
                                    "We have been doing the pre-sale activities with Thomas Cook India
                                    since 2009. In the last six months, the battle turned too aggressive,
                                    and finally, Virtuos had won against the fierce competition from
                                    Microsoft Dynamics and Salesforce.com. We are glad that Thomas Cook
                                    chose us because of our travel-related domain expertise coupled with
                                    Oracle RightNow's capability to deliver business value," says Venky
                                    Vijay Reddi, CEO- Virtuos.
                                </p>
                                <p>
                                    Thomas Cook has signed a three-year non-cancellable contract for
                                    Oracle RightNow CX Suite Sales, Service, and Marketing modules.
                                    Additional subscriptions include Chat, Knowledge Management, Web
                                    Self-Service (WSS) sessions. Virtuos Consultare team headed by
                                    Kristina Hermanns will be implementing Modular functionalities to
                                    cover hundreds of Thomas Cook FEX Counters and Showcase centers across
                                    the country. Besides, Oracle CX Suite will cater to many lines of
                                    Business (LOB) such as E-Commerce, Inbound Travel, International
                                    Travel, MICE, Forex, etc. The implementation cycle is expected to be
                                    more than six months.{" "}
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-consultare-oracle-thomascook-logos.jpg`}
                                        alt="Virtuos, Consultare, Oracle, Thomas Cook India"
                                    />
                                </figure>
                                <br />
                                <p>
                                    Virtuos Consultare, a division of Virtuos, is a leading Customer
                                    Experience (CX) and Cloud CRM player in the country. Our customers
                                    include Standard Chartered Bank, Bwin/Party Gaming, GE Healthcare,
                                    MakeMyTrip, Flipkart.com, Canon, NZ Post, NUS Singapore, and
                                    Myntra.com.
                                </p>
                                <h2 className="title">About Thomas Cook India</h2>
                                <p>
                                    Thomas Cook India (www.thomascook.in) is a leading B2B and B2C Travel
                                    Company offering Business &amp; leisure holidays, Domestic &amp;
                                    International ticketing, Foreign Exchange, Insurance, MICE amongst
                                    other host of services.
                                </p>
                                <h3 className="title">About Virtuos Consultare</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting and Internet Startups.{" "}
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has implemented CRM and Customer Experience Projects to some
                                    of the largest global brands. Our customers include: MakeMyTrip,
                                    Flipkart, Cleartrip, BookMyShow, FundTech, Canon Singapore, National
                                    University of Singapore (NUS), Singapore Institute of Management
                                    (SIM), NZPost, Telstra, Myntra, Standard Chartered Bank,
                                    GE-Healthcare, Sutherland Global, Party Gaming amongst several others.
                                </p>
                                <p>
                                    Virtuos has forayed into high performance Consulting Services
                                    integrating CX and Digital through Consultare division. For more
                                    information visit us at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Snaps_Thomas_Cook_India