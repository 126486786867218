import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Signs_Partnership_With_Rightnow = () => {
    useEffect(() => {
        document.title = "Virtuos signs partnership with RightNow Technologies - A New Milestone in the history of Virtuos – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-signs-partnership-with-rightnow-technologies-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">New Delhi, 18 April 2008</span>
                                <h2 className="article-heading">
                                    Virtuos signs partnership with RightNow Technologies — A New Milestone
                                    in the history of Virtuos
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos signs partnership agreement with RightNow Technologies, Inc.,
                                    a Nasdaq listed leader in Business-To-Consumer CRM Solutions with over
                                    1800 customers worldwide. Virtuos and RightNow will initially work
                                    towards implementing cloud-based RightNow complete Suite to Industry
                                    Verticals such as Airlines &amp; Travel, Consumer Electronics, and
                                    Retail.
                                </p>
                                <p>
                                    Speaking on this significant milestone in a brief history of fewer
                                    than 90 days, Venky Vijay Reddi, CEO, said that “Virtuos has made
                                    significant progress in attracting the Global league of partners such
                                    as KANA, Maximizer, and now RightNow, a leader in the Cloud-based CRM
                                    for Customer Service. Being born in the cloud, Virtuos has constantly
                                    been looking for partners that can give us leverage our Cloud
                                    Expertise”.
                                </p>
                                <p>
                                    In league with Virtuoso ground breaking rules Virtuos once again has
                                    stuck to its principles to forge partnership with the only world
                                    leaders. With the new partnership of RightNow Technologies, Virtuos is
                                    able to scale its operations across APAC and deliver “Customer
                                    Experience and Customer Service” Solutions with integrated Marketing,
                                    Sales, Feedback, Knowledge Management, Chat, Web-Self Service and
                                    Quote -to-Order Modules.
                                </p>
                                <p>
                                    Virtuos believes in the power of the cloud and how it could help
                                    organizations to adapt to CRM quickly. Cloud can bring scalability,
                                    quick innovation, adaptability, and resilient availability for
                                    employees and customers to collaborate in real-time anywhere across
                                    the channels. RightNow Technologies becomes a vital alliance partner
                                    for Virtuos in its progression to succeed and become a dominant market
                                    leader in the Indian market.
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/rightnow-virtuos-img.jpg`}
                                        alt="RightNow Technologies, Virtuos"
                                    />
                                </figure>
                                <p>
                                    Both Yong Sheng Lee (Jun) and Venky Vijay Reddi have been in
                                    discussion to launch RightNow Technologies CRM for over two years;
                                    however, a decision to enter the Indian market was not seen positively
                                    by the US Headquarters. After seeing our runaway success in a short
                                    span of three months, RightNow has approached Virtuos to resume talks
                                    and initiate the practice in India.
                                </p>
                                <h3 className="title">About RightNow Technologies</h3>
                                <p>
                                    RightNow Technologies, Inc. (Nasdaq: RNOW) provides customer
                                    relationship management software and services. The Company offers
                                    solutions that use self-learning knowledge-base to deliver customer
                                    self-service, email management, live interaction, issue tracking, and
                                    customer satisfaction measurement capabilities.
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">07/12/2000</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            136 Enterprise Blvd Bozeman, <br />
                                            MT 59718 United States
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Phone</span>
                                        <span className="txt">1-406-522-4200</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink
                                                to="http://www.rightnow.com"
                                                target="_blank"
                                                className="link"
                                            >
                                                www.rightnow.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">1000-2000</span>
                                    </div>
                                </div>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based out
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book — Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three important needs
                                    of growing businesses — Sales, Customer Service and Marketing. Virtuos
                                    has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    )
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">18/01/2008</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            Tekcorp Office Building, <br />F Block Okhla Industrial Area -
                                            Phase 1, <br />
                                            New Delhi 110020 India
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Branch</span>
                                        <span className="txt">Hyderabad</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                                www.virtuos.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">10-20</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Signs_Partnership_With_Rightnow