import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Bags_Asia_Biggest_Ondemand_Crm = () => {
    useEffect(() => {
        document.title = "Virtuos bags Asia's biggest OnDemand CRM (for travel) deal from India's no.1 Online Travel Company – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-bags-asia-biggest-ondemand-crm-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">New Delhi - 30 June, 2009</span>
                                <h2 className="article-heading">
                                    Virtuos bags Asia's biggest OnDemand CRM (for travel) deal from
                                    India's no.1 Online Travel Company
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos has bagged the most prestigious CRM Project from MakeMyTrip —
                                    India's leading Online Travel Company. After a year-long battle with
                                    multiple Proof of Concepts (POC) and more than thirty In-Person
                                    meetings and a series of product demonstrations, the Virtuos team has
                                    landed a US$1 Million multi-year contract.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-rightnow-makemytrip-logo.jpg`}
                                        alt="Virtuos, RightNow Technologies, MakeMyTrip"
                                    />
                                </figure>
                                <br />
                                <p>
                                    Both Virtuos and RightNow Teams have worked on the requirements and
                                    needs of MakeMyTrip, which initially shortlisted CRM Software from
                                    Microsoft, Salesforce, Siebel, and lesser-known CRMs like Pivotel and
                                    Sage.
                                </p>
                                <p>
                                    The deal is a clear testimony of excellent solution consulting by the
                                    team members from both Virtuos and RightNow.
                                </p>
                                <p>
                                    It’s a proud moment for Virtuos after working tirelessly on this
                                    project by waking up early at 3 AM to work with RightNow Australia
                                    Teams and staying up late until 11 PM to stay in touch with the
                                    client’s requirements. For almost six months, we barely had time to
                                    sleep. Said Venky Vijay Reddi, CEO who led the project team members. I
                                    have interacted with MMT leaders like Rajesh Magow, CFO, Chetan
                                    Oberoi, CTO, Rajnish Kapur, CIO, and Mohit Gupta, CMO, along with
                                    dozen other CRM Committee members for over nine months to clinch this
                                    deal. Finally, a couple of large scale presentations to Deep Kalra,
                                    CEO of MMT, and 50 other Managers and C-Suite from various
                                    departments.
                                </p>
                                <p>
                                    The deal includes Cloud-based Software for a few hundred Agent Desktop
                                    licenses of Contact Center, Chat Licenses, Knowledge Management,
                                    Web-Self Service, and All the three modules – Sales, Service,
                                    Marketing. Besides, MMT has also placed the subscription order for
                                    Voice of Customer (VOC), aka Feedback Module, to conduct C-Sat
                                    Surveys.
                                </p>
                                <p>
                                    We have excelled on every front in comparison with leading OEM
                                    Software vendors and leading Consulting companies like Deloitte, TCS,
                                    Accenture amongst several others. MMT chose to put in trust in a small
                                    company like Virtuos despite having a prior relationship with
                                    Microsoft through another vendor who implemented Dynamics ERP. MMT has
                                    visited client reference sites of RightNow in India and also spoken to
                                    a few other leading brands who have been using RightNow for their
                                    Contact center and CRM operations. MMT has also enquired about the
                                    capabilities of Virtuos by speaking with Standard Chartered Bank and
                                    GE-Healthcare.
                                </p>
                                <p>
                                    This was one of the toughest deals we have signed, as MMT has enormous
                                    expectations from CRM by covering a dozen Lines of Business. Said
                                    Kristina Hermanns, Solution Analyst at Virtuos. Being in the industry
                                    for several years, I could somewhat understand the travel business and
                                    RightNow Software, which was used worldwide at leading brands like
                                    eBookers, Orbitz, Travelocity, etc. Said Kristina Hermanns, Solution
                                    Analyst at Virtuos.
                                </p>
                                <p>
                                    Satisfying every C-Suite member who happens to be questioning a wide
                                    variety of subjects related to technology, functionality, best
                                    practices, travel-related integrations, It was a tough time for me and
                                    My CEO – Venky Vijay. Said Priyanka Sood, Product Marketing Analyst at
                                    Virtuos. However, our early preparations and extensive study of travel
                                    CRM needs under our CEO's guidance has helped the team. It was such a
                                    great Team spirit that we carried on for over nine months
                                    single-handedly believing in the potential of this project. I could
                                    not have imagined what would happen if we failed on this project.
                                </p>
                                <p>
                                    I have been challenged several times by the techies at MMT as the
                                    company itself considers itself more as a tech company than as a
                                    Travel Company. Said Amarinder Singh, Solution Lead Engineer - II. I
                                    had to spend sleepless nights conducting POC at MMT. Being single, I
                                    have worked day and night on multiple CRM technologies all through
                                    these months without any distraction.
                                </p>
                                <p>
                                    I have made a few visits to India with my team members to influence
                                    MakeMyTrip's senior leadership to trust RightNow CRM despite our vast
                                    CRM Portfolio deployed at the major brands. Said Yong Sheng Li (YS).
                                    The dedication and the team spirit Virtuos has put in this deal is
                                    impressive, and all the kudos to teams involved in this project.
                                </p>
                                <h3 className="title">About MakeMyTrip</h3>
                                <p>
                                    MakeMyTrip was founded by{" "}
                                    <NavLink
                                        to="https://en.wikipedia.org/wiki/Deep_Kalra"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Deep Kalra
                                    </NavLink>
                                    ,<sup>[2]</sup> an alumnus of{" "}
                                    <NavLink
                                        to="https://en.wikipedia.org/wiki/Indian_Institute_of_Management_Ahmedabad"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        IIM-Ahmedabad
                                    </NavLink>
                                    . It was launched in the US market in the year 2000 to cater to the{" "}
                                    <NavLink
                                        to="https://en.wikipedia.org/wiki/Non-resident_Indian_and_person_of_Indian_origin"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        overseas Indian
                                    </NavLink>{" "}
                                    community for their US to India travel needs. MakeMyTrip started
                                    Indian operations in September 2005, offering online flight tickets to
                                    Indian travellers. The company also started to focus on non-air
                                    businesses like holiday packages and hotel bookings. MakeMyTrip has
                                    its website at{" "}
                                    <NavLink
                                        to="https://www.makemytrip.com"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        www.makemytrip.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About RightNow Technologies</h3>
                                <p>
                                    RightNow Technologies, Inc. (Nasdaq: RNOW) provides customer
                                    relationship management software and services. The Company offers
                                    solutions that use self-learning knowledge-base to deliver customer
                                    self-service, email management, live interaction, issue tracking, and
                                    customer satisfaction measurement capabilities.
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">07/12/2000</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            136 Enterprise Blvd Bozeman, <br />
                                            MT 59718 United States
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Phone</span>
                                        <span className="txt">1-406-522-4200</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink
                                                to="http://www.rightnow.com"
                                                target="_blank"
                                                className="link"
                                            >
                                                www.rightnow.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">1000-2000</span>
                                    </div>
                                </div>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based out
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book — Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three important needs
                                    of growing businesses — Sales, Customer Servicem and Marketing.
                                    Virtuos has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    )
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">18/01/2008</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            Tekcorp Office Building, <br />F Block Okhla Industrial Area -
                                            Phase 1, <br />
                                            New Delhi 110020 India
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Branch</span>
                                        <span className="txt">Hyderabad</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                                www.virtuos.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">10-20</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Bags_Asia_Biggest_Ondemand_Crm