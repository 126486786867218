import React from 'react'
import Subscribe from './Forms/Subscribe'

const SubscribeNow = () => {
    return (
        <>
            {/* <section className="subscribe-section grey-bg"> */}
            <section className="subscribe-section grey-bg">
                <div className="row page-wrap text-center subscribe">
                    <h3 className="section-main-heading">
                        Stay Updated with our <span className="highlight">Latest</span> News
                        Articles.
                    </h3>

                    <span className="section-desc">Subscribe now to stay connected</span>

                    <form
                        className="no-captcha"
                        action="https://us-central1-virtuos-625f6.cloudfunctions.net/Subscribe"
                        method="POST"
                        id="S_News"
                    >
                        <div className="input-wrapper">
                            <Subscribe />
                        </div>
                    </form>
                </div>
            </section>
        </>


    )
}

export default SubscribeNow
