import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const photos = [
  { id: 1, src: "/img/03.jpg", alt: "Photo 1" },
  { id: 2, src: "/img/02.jpg", alt: "Photo 2" },
  { id: 3, src: "/img/04.jpg", alt: "Photo 3" },
  { id: 4, src: "/img/01.jpg", alt: "Photo 4" },
];

const Anniversary_16th = () => {
  useEffect(() => {
    document.title =
      "Virtuos Celebrates 16th Anniversary in Shimla, India's Scenic Hill Station";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}/img/Img-shimla.jpg`}
                  alt="Virtuos to unveil NOVV — NewWe Digital Transformation Practice"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Shimla Jan 18, 2024</span>
                <h2 className="article-heading">
                  Virtuos Celebrates 16th Anniversary at Shimla, Hill Station of
                  India
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos, a leader in digital transformation and innovation,
                  recently celebrated its 16th anniversary with a grand event at
                  Shimla, a beautiful hill station in North India. The two-night
                  celebration brought together Virtuos employees, fondly known
                  as Virtuoso, to bond, connect, and rejuvenate in the serene
                  and picturesque surroundings of Shimla.
                </p>
                <p>
                  The event was not just a celebration but also a strategic
                  milestone for Virtuos. Amidst the festivities, Virtuos
                  launched two major initiatives and celebrated its impressive
                  double growth in revenue for FY24, with a bold forecast of
                  achieving 10X performance in FY25.
                </p>
                {/* <p>
                  Virtuos NOVV will be officially launched in the first week of
                  April 2023 in India followed by the USA and rest of the
                  geographies.
                </p> */}
                <h3 className="title">Strategic Initiatives Unveiled</h3>
                <p>
                  <span class="font-weight600"> AIAmbition:</span> Virtuos
                  announced the launch of Virtuos.ai, a groundbreaking
                  initiative that aims to bring a host of new products to the
                  market, leveraging Generative AI and other advanced AI
                  technologies. This initiative is set to position Virtuos at
                  the forefront of AI innovation, driving the next wave of
                  technological advancements.
                </p>
                <p>
                  <span class="font-weight600"> Virtuos Brand:</span> In a
                  strategic move to enhance its brand and design practice,
                  Virtuos has acquired Vbrand.com. This acquisition will help
                  enterprises develop robust brand strategies and introduce
                  innovative brands to the market. Integral to this initiative
                  is Brandcart, boasting a portfolio of over 1,000 brands.
                  Virtuos Brand embodies the company's vision of launching
                  'moonshotter' brands that revolutionize the market.
                </p>
                <h3 className="title">Business Evolution</h3>
                <p>
                  Virtuos also announced a significant restructuring, splitting
                  the company into two distinct businesses:
                </p>
                <p>
                  <span class="font-weight600"> Virtuos:</span> This division
                  will focus on product innovation, launching new brands, and
                  venturing into developing new businesses within the Experience
                  Economy (Xonomy). Virtuos is committed to pushing the
                  boundaries of innovation and creating high-impact products
                  that resonate with the evolving market demands.
                </p>
                <p>
                  <span class="font-weight600">
                    {" "}
                    Virtuos Digital (VDC.com):
                  </span>{" "}
                  With the acquisition of the premium domain VDC.com, worth
                  $500k, Virtuos Digital is set to singularly focus on
                  high-performance consulting services in Customer Experience
                  (CX), CRM, and Digital Transformation. By implementing
                  state-of-the-art products from partners like Oracle,
                  Salesforce, Microsoft, Agiloft, and Asana, Virtuos Digital
                  aims to deliver unparalleled consulting services.
                </p>
                <h3 className="title">A Vision for the Future</h3>
                <p>
                  Virtuos is excited about its trajectory and the transformation
                  into a high-growth company. The team is energized and ready to
                  make significant strides towards becoming one of the
                  fastest-growing companies in India. The company has also set
                  its sights on global expansion, offering a diverse array of
                  products and services from its partners, as well as its own
                  innovative, composable products.
                </p>

                <div className="photo-grid-news">
                  {photos.map((photo) => (
                    <div key={photo.id} className="photo-item-news">
                      <img src={photo.src} alt={photo.alt} />
                    </div>
                  ))}
                </div>

                <h3 className="title">A Memorable Celebration</h3>
                <p>
                  The anniversary event in Shimla provided the perfect backdrop
                  for these announcements. The pleasant weather and beautiful,
                  serene surroundings of Shimla allowed Virtuoso to relax and
                  rejuvenate, strengthening their bonds and fostering a sense of
                  unity and purpose.
                </p>
                <p>
                  As Virtuos moves forward, it remains committed to its vision
                  of driving innovation, achieving exceptional growth, and
                  expanding its global footprint. The 16th anniversary
                  celebration marks not just a milestone but the beginning of an
                  exciting new chapter in the Virtuos journey.
                </p>
                <h1
                  className="title"
                  style={{ fontSize: "2rem", color: "#01a2fe" }}
                >
                  {" "}
                  Virtuos Group
                </h1>
                <h3 className="title">Virtuos</h3>
                <p>
                  {/* Virtuos{" "}
                  <NavLink
                    to={`/xonomy/`}
                    className="link"
                  >
                    Xonomy
                  </NavLink>{" "} */}
                  Virtuos ideates, incubates and invests in the next-generation
                  businesses harnessing Artificial Intelligence (AI), Cloud,
                  ECommerce, Blockchain and Digital technologies that are
                  integral to the futuristic{" "}
                  <NavLink to="/xonomy" target="_blank" className="link">
                    Experience Economy (Xonomy)
                  </NavLink>
                  . At Virtuos, we have incubated and invested in several
                  companies that include{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart
                  </NavLink>{" "}
                  — Gifting Experiences Commerce Company;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic
                  </NavLink>
                  — the Portfolio of Experience Commerce Brands;{" "}
                  <NavLink
                    to="http://www.sweven.com"
                    target="_blank"
                    className="link"
                  >
                    Sweven
                  </NavLink>{" "}
                  — Salesforce CRM Mastermind services; and{" "}
                  <NavLink
                    to="http://www.tekcorp.com"
                    target="_blank"
                    className="link"
                  >
                    Tekcorp
                  </NavLink>{" "}
                  — the Technology Infrastructure.
                </p>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We have launched
                  Brandcart.com—the brand naming platform for businesses.We will
                  be unleashing an integrated and agile marketing platform
                  Services and Digital Agency— Wundero; Building CRM Studio
                  Suite powered by Composable No Code Platform. In 2024 Virtuos
                  will also be launching Expedience — The Talent advisory and
                  sourcing platform
                </p>
                <p>
                  Virtuos created a brand new{" "}
                  <NavLink
                    to="http://www.aiambition.com/"
                    target="_blank"
                    className="link"
                  >
                    AI-Ambition
                  </NavLink>{" "}
                  to leverage and harness generative AI’s full competitive
                  potential along with other AI technologies. We will be
                  building the right portfolio, ecosystem, deployment model,
                  skills and governance to help enterprise customers create
                  their #AIAmbition.
                </p>
                <h3 className="title">Virtuos Digital</h3>
                <p>
                  {/* <NavLink
                    to="http://www.experiencecloud.com"
                    target="_blank"
                    className="link"
                  >
                    Experience Cloud
                  </NavLink>{" "} */}
                  Virtuos Digital (vdc.com) delivers industry-specific counsel
                  and the integrated, end-to-end solutions that support its
                  customers' strategic, operational, and financial goals.
                  Virtuos Digital (VDC) is an Experience consultancy that
                  provides superior customer moments and implements leading CRM
                  and Customer Experience Applications — Oracle CX; Salesforce
                  Platforms, Microsoft Dynamics, Creatio No Code Platform & CRM
                  Applications; Agiloft Contract Lifecycle Management (CLM);
                  Asana Work Management amongst other Composable Products &
                  Services. For more information on Virtuos Digital please visit{" "}
                  <NavLink
                    to="http://www.vdc.com"
                    target="_blank"
                    className="link"
                  >
                    www.vdc.com
                  </NavLink>
                </p>
                {/* <h3 className="title">Virtuos Nexorama</h3>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We are building an
                  integrated and agile learning platform — Wundero; Carving out
                  a new business model building a marketplace for LowCode NoCode
                  Technologies by composing a new business UnCodify.com. Virtuos
                  will be launching Expedience — The Talent advisory and
                  sourcing platform and Audacis, a new digital marketing
                  services agency.
                </p> */}
                <div>
                  <p style={{ marginBottom: "0px" }}>
                    For more information visit{" "}
                    <NavLink
                      to="http://www.virtuos.com"
                      target="_blank"
                      className="link"
                    >
                      www.virtuos.com
                    </NavLink>
                  </p>
                </div>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Anniversary_16th;
