import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Implements_Oracle_Cx = () => {
    useEffect(() => {
        document.title = "Virtuos Implements Oracle CX at TVS Credit, Craftsvilla, India First – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-implements-oracle-cx-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    Chennai, Gurgaon and Mumbai - 28 February, 2017
                                </span>
                                <h2 className="article-heading">
                                    Virtuos implements Oracle CX at TVS Credit, Craftsvilla, India First,
                                    amongst others
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos, a market leader in Oracle CX Technologies in India has
                                    implemented large projects at TVS Credit, Chennai based leading
                                    Automotive Financial Services Company, and part of Top 100 Corporate
                                    houses in India. Virtuos also bagged others CX deals from Craftsvilla,
                                    venture capital-funded eCommerce company, and India First, a leading
                                    Insurance Company based at Mumbai
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/oracle-cx-diagram-tvs-credit-indiafirst-craftsvilla.jpg`}
                                        alt="Oracle RIghtNow CX, TVS Credit, India First, Craftsvilla"
                                    />
                                </figure>
                                <h3 className="title">Oracle CX and TVS Credit</h3>
                                <p>
                                    Virtuos has implemented Oracle CX Suite — RightNow Service Cloud for
                                    the agents, customers, internal employees supervising Leasing
                                    Services, and extended network of dealers. Virtuos RightApps has also
                                    implemented the{" "}
                                    <NavLink
                                        to={`/right-apps/#tvs-apps`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Smart Mobile App
                                    </NavLink>{" "}
                                    using RightNow APIs and{" "}
                                    <NavLink
                                        to="https://tvscs.custhelp.com/app/dealer/login"
                                        target="_blank"
                                        className="link"
                                        rel="noreferrer"
                                    >
                                        Customer Portal Framework
                                    </NavLink>
                                    .
                                </p>
                                <h3 className="title">Oracle CX and India First</h3>
                                <p>
                                    Virtuos has implemented Oracle CX Suite — RightNow Service Cloud at
                                    India First Insurance to automate customer service operations and
                                    provide the subscribers much-needed help through{" "}
                                    <NavLink
                                        to="https://iflicproduction.custhelp.com/opa-hub/manager/login"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Self-Service and Knowledge Management Portal
                                    </NavLink>
                                    .
                                </p>
                                <p>
                                    “Oracle’s powerful KM helps leading organizations globally to deflect
                                    voice calls, enabling customers to quickly find the relevant,
                                    contextual, and most appropriate answers to their questions.” Said
                                    Venky Vijay Reddi, CEO of Virtuos. “We are glad that India First has
                                    joined the elite of customers like Flipkart, MakeMyTrip, Thomas Cook,
                                    Myntra amongst 100+ leading brands around the world.”
                                </p>
                                <h3 className="title">Oracle CX and Craftsvilla</h3>
                                <p>
                                    Virtuos has implemented Oracle CX Suite — RightNow Service Cloud for
                                    supporting their eCommerce operations, customer service, web
                                    self-service, and chat.
                                </p>
                                <p>
                                    Virtuos and Oracle India have been on their mission to rid the world
                                    of bad experiences by creating Experience Platforms for leading
                                    brands. Three out of four top eCommerce companies, Two out of three
                                    top Online Travel companies, Two out of the top five Non-Banking
                                    Financial Services Companies are Virtuos CX Customers in India.
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Implements_Oracle_Cx