import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Moves_To_A_New_Office_2013 = () => {
    useEffect(() => {
        document.title = "Virtuos moves to a new Office to facilitate Consultare Ops &amp; IT Services on a 24/7 basis – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-new-office-enkay-square-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 3 November, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos moves to a new Office to facilitate Consultare Ops &amp; IT
                                    Services on a 24/7 basis
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos moves to a new building certified by the Govt. of Haryana to
                                    run 24x7 operations with uninterrupted power supply and easy
                                    accessibility to Metro and Other modes of transportation.
                                </p>
                                <p>
                                    Housed in the prestigious Enkay Square, on the fourth floor, Virtuos
                                    will be integrating both Virtuos and Giftcart Office spaces.
                                </p>
                                <p>
                                    A prominent building with ample parking space and right across the
                                    National Highway Enkay Square offers convenience and comfort for our
                                    employees. Said Shaloo Reddi, EVP - HR &amp; Strategy at Virtuos. With
                                    more than 70 workstations, about eight cabins for Executives and
                                    meetings, and an integrated cafeteria and washrooms, the office offers
                                    all the amenities we need.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/enkay-square-pics-2013.jpg`}
                                        alt="Enkay Square, Gurgaon"
                                    />
                                </figure>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely — Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas, and executing them with Virtuoso teams. Virtuos was
                                    founded in 2008 by cultivating an ambitious plan to become a
                                    multi-billion dollar company and a game changer by 2020. Thanks to the
                                    hard work and commitment of our team we continue to grow each year.
                                    With new innovative business ideas, delivering incredible
                                    transformation services and building powerful engagement platforms for
                                    the future we are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink to="http://www.fb.com/virtuos" target="_blank" className="link">
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Moves_To_A_New_Office_2013