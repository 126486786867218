import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_digital = () => {
  useEffect(() => {
    document.title =
      "Virtuos to unveil NOVV — NewWe Digital Transformation Practice – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/Bigthink_Change.png`}
                  alt="Virtuos to unveil NOVV — NewWe Digital Transformation Practice"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram Oct 25, 2023.</span>
                <h2 className="article-heading">
                  Virtuos spins a bigthink change — Virtuos Digital (VDC) is
                  carved out of Virtuos.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  The digital business landscape is constantly evolving. To stay
                  agile, be open to innovation, and adapt to changing market
                  dynamics, Organizations are required to transform their inner
                  business model applying the latest thinking. Success in the
                  digital business world often involves iterating and learning
                  from both successes and failures. Stay adaptable and
                  responsive to changes in your industry and your customers'
                  needs. For over 15 years, we have helped more than 200 midsize
                  and large enterprise customers offering CRM, Customer
                  Experience and Contract Lifecycle Management (CLM)
                  Technologies and Solutions.
                </p>
                <p>
                  In 2018, Virtuos on its Annual Day launched an initiative to
                  spin new businesses focussing on vivid portfolios and create
                  fractal business units to demarcate various Platform
                  partnerships.
                </p>
                <p>
                  Today, on 25th October 2023, Virtuos has carved Virtuos
                  Digital—VDC out of its business to create a new identity
                  integrating its various fractal businesses as one cohesive and
                  highly focussed business transformation consultancy. Digital
                  Transformation requires an integrated and holistic approach to
                  solve the challenges of enterprise customers by harnessing
                  modern stack of AI, Digital and Cloud technologies.
                </p>
                {/* <p>
                  Virtuos NOVV will be officially launched in the first week of
                  April 2023 in India followed by the USA and rest of the
                  geographies.
                </p> */}
                <h3 className="title">Two Businesses Within One Company.</h3>
                <p>
                  {" "}
                  {/* <NavLink
                    to={`/consultare`}
                    className="link"
                  >
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Microsoft Dynamics, Creatio Low Code No Code
                  Platform &amp; CRM Applications; Agiloft Contract Lifecycle
                  Management (CLM) amongst other Composable Products &amp;
                  Services. Virtuos Consulting services include Four Fabulous
                  business units namely —{" "} */}
                  Just like every mountain has a valley, every coin has two
                  sides and every human needs another companion, we created a
                  double edged business by unveiling Digital Business Twins.
                  {/* 
                  <NavLink
                    to="http://www.virtuos.com/vivacis"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Vivacis
                  </NavLink>
                  ,{" "}
                  <NavLink
                    to="http://www.virtuos.com/veracis"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Veracis
                  </NavLink>
                  ,{" "}
                  <NavLink
                    to="http://www.virtuos.com/vaves"
                    trget="_blank"
                    className="link"
                  >
                    Virtuos Vaves
                  </NavLink>
                  , and{" "}
                  <NavLink
                    to="http://www.vedam.com"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Vedam
                  </NavLink>
                  . */}
                </p>
                <p>
                  Virtuos Digital—the incumbent business of Virtuos Consulting
                  and Transformation Services including CRM, Customer
                  Experience, CLM, Work Management services, subscriptions and
                  platform engineering will have a new identity and new website
                  —vdc.com. The three letter precious domain —vdc.com worth more
                  than half-a-million dollars is our latest acquisition to power
                  Virtuos Digital business. VDC.com is an easy to remember three
                  letter domain showcasing some of the best Digital
                  Transformation Success stories across industries we serve in
                  alliance with leading egged partners worldwide.
                </p>
                <p>
                  Virtuos (virtuos.com) formerly known as Virtuos Xonomy will be
                  focussing on new ventures, investments, innovating new
                  products and portfolios that are integral to Experience
                  Economy. Current portfolio of ventures namely Giftcart, Indic,
                  Sweven and Tekcorp and Nexorama composable products include
                  Brandcart.com which is in its beta; Wundero—an agile
                  e-learning platform scheduled for launch before Christmas;
                  Expedience— the talent advisory and talent management platform
                  will be unveiled on January 18th, 2024 at the Annual Day.
                </p>{" "}
                <p>
                  <b>Combined Operations:</b> Both the operations are part of a
                  single business entity and they continue to operate in the
                  same premises with a shared talent pool until each business
                  grows to significant size in revenue.
                </p>{" "}
                <p>
                  The grand Invincible vision will be unveiled on January 18,
                  2024 in Shimla, India on the Annual day function—Velocity99.
                  Stay tuned for more updates.
                </p>
                <h1
                  className="title"
                  style={{ fontSize: "2rem", color: "#01a2fe" }}
                >
                  {" "}
                  Virtuos Group
                </h1>
                <h3 className="title">Virtuos</h3>
                <p>
                  {/* Virtuos{" "}
                  <NavLink
                    to={`/xonomy/`}
                    className="link"
                  >
                    Xonomy
                  </NavLink>{" "} */}
                  Virtuos ideates, incubates and invests in the next-generation
                  businesses harnessing Artificial Intelligence (AI), Cloud,
                  ECommerce, Blockchain and Digital technologies that are
                  integral to the futuristic{" "}
                  <NavLink to="/xonomy" target="_blank" className="link">
                    Experience Economy (Xonomy)
                  </NavLink>
                  . At Virtuos, we have incubated and invested in several
                  companies that include{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart
                  </NavLink>{" "}
                  — Gifting Experiences Commerce Company;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic
                  </NavLink>
                  — the Portfolio of Experience Commerce Brands;{" "}
                  <NavLink
                    to="http://www.sweven.com"
                    target="_blank"
                    className="link"
                  >
                    Sweven
                  </NavLink>{" "}
                  — Salesforce CRM Mastermind services; and{" "}
                  <NavLink
                    to="http://www.tekcorp.com"
                    target="_blank"
                    className="link"
                  >
                    Tekcorp
                  </NavLink>{" "}
                  — the Technology Infrastructure.
                </p>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We have launched
                  Brandcart.com—the brand naming platform for businesses.We will
                  be unleashing an integrated and agile marketing platform
                  Services and Digital Agency— Wundero; Building CRM Studio
                  Suite powered by Composable No Code Platform. In 2024 Virtuos
                  will also be launching Expedience — The Talent advisory and
                  sourcing platform
                </p>
                <p>
                  Virtuos created a brand new{" "}
                  <NavLink
                    to="http://www.aiambition.com/"
                    target="_blank"
                    className="link"
                  >
                    AI-Ambition
                  </NavLink>{" "}
                  to leverage and harness generative AI’s full competitive
                  potential along with other AI technologies. We will be
                  building the right portfolio, ecosystem, deployment model,
                  skills and governance to help enterprise customers create
                  their #AIAmbition.
                </p>
                <h3 className="title">Virtuos Digital</h3>
                <p>
                  {/* <NavLink
                    to="http://www.experiencecloud.com"
                    target="_blank"
                    className="link"
                  >
                    Experience Cloud
                  </NavLink>{" "} */}
                  Virtuos Digital (vdc.com) delivers industry-specific counsel
                  and the integrated, end-to-end solutions that support its
                  customers' strategic, operational, and financial goals.
                  Virtuos Digital (VDC) is an Experience consultancy that
                  provides superior customer moments and implements leading CRM
                  and Customer Experience Applications — Oracle CX; Salesforce
                  Platforms, Microsoft Dynamics, Creatio No Code Platform & CRM
                  Applications; Agiloft Contract Lifecycle Management (CLM);
                  Asana Work Management amongst other Composable Products &
                  Services. For more information on Virtuos Digital please visit{" "}
                  <NavLink
                    to="http://www.vdc.com"
                    target="_blank"
                    className="link"
                  >
                    www.vdc.com
                  </NavLink>
                </p>
                {/* <h3 className="title">Virtuos Nexorama</h3>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We are building an
                  integrated and agile learning platform — Wundero; Carving out
                  a new business model building a marketplace for LowCode NoCode
                  Technologies by composing a new business UnCodify.com. Virtuos
                  will be launching Expedience — The Talent advisory and
                  sourcing platform and Audacis, a new digital marketing
                  services agency.
                </p> */}
                <div>
                  <p style={{ marginBottom: "0px" }}>
                    For more information visit{" "}
                    <NavLink
                      to="http://www.virtuos.com"
                      target="_blank"
                      className="link"
                    >
                      www.virtuos.com
                    </NavLink>
                  </p>
                </div>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_digital;
