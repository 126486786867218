import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Bags_Crm_Service_Cloud_Contract_From_Flipkart = () => {
    useEffect(() => {
        document.title = "Virtuos bags a large CRM Service Cloud Contract from Flipkart - a significant accomplishment for the Virtuos team – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-bags-crm-service-cloud-contract-from-flipkart-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Bangalore - 30 November, 2011</span>
                                <h2 className="article-heading">
                                    Virtuos bags a large CRM Service Cloud Contract from Flipkart - a
                                    significant accomplishment for the Virtuos team
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    <strong>
                                        Flipkart.com, an upcoming eCommerce company, has chosen RightNow CX
                                        Suite from Virtuos to deliver superior Customer Experience (CX) to
                                        its customers.
                                    </strong>
                                </p>
                                <p>
                                    “It’s a mega project at Flipkart.com, India's leading eCommerce
                                    company that has maintained bullet speed growth over the last couple
                                    of years. Winning the Flipkart.com account amidst stiff competition
                                    from Salesforce.com and Microsoft Dynamics has been one of our major
                                    achievements.” Said Kristina Hermanns, Director of Solution Consulting
                                    at Virtuos. “The entire sales consulting to Filpkart.com was headed by
                                    Principal Consultant &amp; CEO Venky Vijay who offered new paradigms
                                    in Email Response Management, Contact Center, and Knowledge
                                    Management.”
                                </p>
                                <p>
                                    The RightNow CX Modules cover initial 200 Licenses extendable to a few
                                    thousand over the next two to three years; few Chat and Co-Browse
                                    Licenses and KM &amp; Web-Self-Service Sessions. Besides, Flipkart has
                                    also shown interest in Agent Scripting, Advanced Analytics, and
                                    Marketing Modules.
                                </p>
                                <p>
                                    The Deal signed between Venky Vijay Reddi, CEO, and Satish Udupi, the
                                    CIO of Flipkart, marks a new chapter in the history of Virtuos. After
                                    a series of discussions and several evaluations, Flipkart chose to
                                    implement RightNow with the condition that the Phase -1 of the
                                    solution must go live within 14 days.
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/flipkart-virtuos-logo-news.jpg`}
                                        alt="Flipkart, Virtuos Solutions"
                                    />
                                </figure>
                                <p>
                                    “We chose RightNow CX Suite for three reasons from Virtuos”, said
                                    Satish Udupi, CIO of Flipkart. Firstly RightNow is a highly scalable
                                    platform that has been used in India by MakeMyTrip and 1000's of other
                                    customers worldwide; Secondly, the exemplary consulting and best
                                    practices approach by Venky and his team; and the agility of the
                                    application to go live within weeks.”
                                </p>
                                <p>
                                    "I have always enjoyed partnering with YS Lee, my friend, and Channel
                                    Director at RightNow Technologies." Said Venky Vijay Reddi, CEO of
                                    Virtuos. "YS Lee has understood the special pricing conditions of the
                                    Indian market, and he always stood by me to offer our customers
                                    outstanding value."
                                </p>
                                <h3 className="title">About Flipkart</h3>
                                <p>
                                    Flipkart is an Indian e-commerce company based in Bangalore,
                                    Karnataka, India. It was founded by Sachin Bansal and Binny Bansal in
                                    2007. The company initially focused on book sales, before expanding
                                    into other product categories such as consumer electronics, fashion,
                                    home essentials &amp; groceries, and lifestyle products.
                                </p>
                                <p>
                                    Flipkart has been growing at 100% every month to month, handling more
                                    than 100,000 orders daily.
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, and
                                    Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has built a strong RightNow Practice for CRM Service Cloud by
                                    creating marquee clients like MakeMyTrip, Fundtech amongst others.
                                    Virtuos will continue to strengthen its business with RightNow and
                                    Oracle by becoming the latter’s partner. For more information visit{" "}
                                    <NavLink to="https://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About RightNow Technologies</h3>
                                <p>
                                    RightNow Technologies, Inc. (Nasdaq: RNOW) provides customer
                                    relationship management software and services. The Company offers
                                    solutions that use self-learning knowledge-base to deliver customer
                                    self-service, email management, live interaction, issue tracking, and
                                    customer satisfaction measurement capabilities.
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">07/12/2000</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            136 Enterprise Blvd Bozeman, <br />
                                            MT 59718 United States
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Phone</span>
                                        <span className="txt">1-406-522-4200</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink
                                                to="http://www.rightnow.com"
                                                target="_blank"
                                                className="link"
                                            >
                                                www.rightnow.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">1000-2000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Bags_Crm_Service_Cloud_Contract_From_Flipkart