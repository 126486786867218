import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import ContactFormC from "./Form/ContactFormC";

const Insidex = () => {
  const [activeTab, setActiveTab] = useState("phase-1");

  const handleHover = (tabId) => {
    // Your logic here
    console.log(`Hovered ${tabId}`);
    setActiveTab(tabId);
  };
  const handleTabClick = (tabId) => {
    console.log("inside hand", tabId);
    setActiveTab(tabId);
  };
  useEffect(() => {
    document.title =
      "HCM Applications Cloud And Employee Experience (EX) – Virtuos Digital ";
  }, []);
  return (
    <div className="landing-page hcm-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/svg/cxopia-header-bg.svg`}
                alt="CXOpia Header Image"
              />
            </figure>
            <div className="product-hero-wrapper">
              <figure className="right-fixed-logo">
                <img
                  style={{ height: "6rem", width: "6rem" }}
                  src={`/img/brandcart_insidex_final_logo.png`}
                  alt="Insidex logo"
                />
              </figure>
              <div className="row page-wrap">
                <div className="col-6 item-wrapper">
                  <h3 className="section-heading">
                    HCM Applications &amp; Cloud
                  </h3>
                  <p>HR Technology is Proliferating.</p>
                </div>
                <div className="col-8 header-img">
                  <figure></figure>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/ex/`}>
                    EX PRACTICE
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/Insidex/`}
                    className="active"
                  >
                    HCM TECHNOLOGIES
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/ex/strategy-design/`}
                  >
                    EX STRATEGY &amp; DESIGN
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/w-digital/`}>
                    DIGITAL WORKPLACE
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/w-digital/program/`}
                  >
                    W.DIGITAL PROGRAM
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/talentare/`}>
                    TALENTARE
                  </NavLink>
                </li>
              </ul>
            </div>
          </section>
          <section className="hrtech-container">
            <h2 className="section-main-heading">
              Highly proliferating <span className="highlight">#HRTECH</span>
            </h2>
            <span className="section-desc">
              Dozens of HR (or HCM) Technologies are rapidly emerging to bring
              automation, talent development, and employee engagement.
            </span>
            <span className="section-desc">
              Human Capital Management (HCM) Applications are being driven by
              legislation/regulation, process change, and a growing market of
              vendors of technologies, services, and solutions across the
              geographies. The sheer size and complexity run counter to the
              trend, and expectation of consolidation of HR technology.
            </span>
            <span className="section-desc">
              The Market Forecast for Global HCM Software is currently pegged at
              US$20-22 Billions and expected to grow to US$25-30 Billions by
              2023.
            </span>
            <div className="header">
              <img
                style={{ height: "4rem", width: "4rem" }}
                src={`/img/brandcart_insidex_final_logo.png`}
              />
              HR PORTFOLIO
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/hrtech-diagram-img.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="hcm-container">
            <h2 className="section-main-heading">
              <span className="highlight">HCM PLAY</span> AT VIRTUOS
            </h2>
            <span className="section-desc">
              Virtuos has forayed into HCM Applications business as a necessary
              function to bridge the experience gap by creating Employee
              Experience (EX) Practice.
            </span>
            <span className="section-desc">
              HCM products traditionally fall into two broad areas: 1) products
              that fulfill people operations and the employment contract (i.e.,
              payroll, time and attendance, employee records management) and 2)
              products that aid in the execution of an organization's people
              strategy by helping attract, develop, and retain talent (i.e.,
              talent acquisition, learning, performance management).
            </span>
            <div className="row page-wrap items-block">
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/ex-strategy-img.jpg`}
                    alt="EX Strategy"
                  />
                </figure>
                <h3>EX Strategy</h3>
                <p >
                  We can plan an agile HCM strategy and help organizations
                  harness the power of SaaS or PaaS to improve functionality,
                  integrations, and user experience. Our advisory services
                  entail change management, process improvements, implementing
                  methodologies, and frameworks.
                </p>
                <NavLink
                  id="margin1"
                  to={`/ex/strategy-design/`}
                >
                  Know More <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/technology-strategy-img.jpg`}
                    alt="Technology Strategy"
                  />
                </figure>
                <h3>Technology Strategy</h3>
                <p >
                  We continuously work with organizations to shift the HCM
                  Technology strategy from static to agile. Our years of
                  extensive Customer Experience (CX) Practice has given birth to
                  the most innovative products like Expedience, OKRX and
                  CulturO.
                </p>
                <NavLink
               
                  id="margin1"
                  to={`/ex/strategy-design/`}
                >
                  Know More <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/innovation-on-img.jpg`}
                    alt="Innovation On (iO)"
                  />
                </figure>
                <h3>Innovation On (iO)</h3>
                <p >
                  Our iO lab drives new process changes for organizations by
                  creating vibrant and consolidated HR Technology ecosystems. At
                  Virtuos, we have built “Virtuosoship,” an Employee Dexterity
                  Program as part of EX to drive digital transformation.
                </p>
                <NavLink
                  id="margin1"
                  to={`/io/`}
                >
                  Know More <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
            </div>
          </section>
          <section className="two-col-full-section cloudle">
            <figure className="bg-banner">
              <img
                src={`/img/hcm-breaker-bg.jpg`}
                alt="Talent Acquisition"
              />
            </figure>
            <div className="page-wrap row cloudle-wrapper">
              <div className="col-5 illus offport-left">
                <h3>
                  Virtuos offers Talent Acquisition to Talent Engagement and
                  Systems of Record to Systems of Action.
                </h3>
              </div>
              <div className="col-6 offport-right">
                <section
                  className="clients-container"
                  style={{ overflow: "hidden" }}
                >
                  <div className="row page-wrap">
                    <div className="col-12">
                      <ul
                        className="bxsliders"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        <li style={{ marginRight: "6em", marginTop: "2em" }}>
                          <img
                            src={`/img/svg/icons/expedience-logo.svg`}
                            alt="Expedience Logo"
                            style={{ width: "7rem", height: "auto" }}
                          />
                        </li>
                        <li style={{ marginRight: "6em", marginTop: "2em" }}>
                          <img
                            src={`/img/svg/icons/okrx-logo.svg`}
                            alt="OKRX Logo"
                            style={{ width: "5rem", height: "auto" }}
                          />
                        </li>
                        <li style={{ marginRight: "6em", marginTop: "2em" }}>
                          <img
                            src={`/img/svg/icons/culturo-logo.svg`}
                            alt="CultureO Logo"
                            style={{ width: "7rem", height: "auto" }}
                          />
                        </li>
                        {/* <li style={{ marginRight: "6em", marginTop: "2em" }}>
                          <img
                            src={`/img/josh-q-logo.png`}
                            alt="JoshQ Logo"
                            style={{ width: "5rem", height: "auto" }}
                          />
                        </li> */}
                        <li style={{ marginRight: "6em", marginTop: "2em" }}>
                          <img
                            src={`/img/svg/oracle-logo.svg`}
                            alt="Oracle Logo"
                            style={{ width: "7rem", height: "auto" }}
                          />
                        </li>
                        <li style={{ marginRight: "6em", marginTop: "2em" }}>
                          <img
                            src={`/img/svg/icons/talentare-logo.svg`}
                            alt="Talentare Logo"
                            style={{ width: "7rem", height: "auto" }}
                          />
                        </li>
                        <li style={{ marginRight: "6em", marginTop: "1em" }}>
                          <img
                            src={`/img/svg/salesforce-logo.svg`}
                            alt="Salesforce Logo"
                            style={{ width: "7rem", height: "auto" }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <section className="ejourney-container">
            <h2 className="section-main-heading">
              Employee <span className="highlight">Journey Map</span>
            </h2>
            <span className="section-desc">
              Candidate Engagement to Employee Experience and Ex-Employee Alumni
              relations are managed throughout an employee’s journey.
            </span>
            <span className="section-desc">
              Just like the customer journey, the employee journey is crucial
              for the organization’s success. Today’s employee can become
              tomorrow’s ex-employee, and tomorrow’s ex-employee can recommend
              or refer more candidates or possibly become a potential employee.
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/employee-journey-mapping-diagram.svg`}
                    alt="Employee Journey Mapping Diagram"
                  />
                </figure>
                <span className="section-desc">
                  Virtuos can offer solutions for employee experience and
                  efficiency, as outlined below throughout the employee’s
                  lifetime.
                </span>
              </div>
            </div>
            <div className="row page-wrap digital-program-wrapper">
              {/* Clickable Tabs */}
              <div className="col-4 digital-process-tabs-wrapper vertical-clickable-tabs">
                <ul>
                  <li
                    className={`item blue ${
                      activeTab === "phase-1" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("phase-1")}
                  >
                    {/* <NavLink className={`item blue   ${activeTab === 'phase-1' ? 'active' : ''}`} onClick={() => handleTabClick('phase-1')} onMouseEnter={() => handleHover('phase-1')}> */}
                    <NavLink>
                      <span>
                        Candidate Attraction <i className="fa fa-arrow-down" />
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`item blue ${
                      activeTab === "phase-2" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("phase-2")}
                  >
                    <NavLink>
                      <span>
                        Candidate Engagement <i className="fa fa-arrow-down" />
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`item blue ${
                      activeTab === "phase-3" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("phase-3")}
                  >
                    <NavLink>
                      <span>
                        Applicant Screening and Agreement{" "}
                        <i className="fa fa-arrow-down" />
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`item blue ${
                      activeTab === "phase-4" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("phase-4")}
                  >
                    <NavLink>
                      <span>
                        Interview and Selection{" "}
                        <i className="fa fa-arrow-down" />
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`item blue ${
                      activeTab === "phase-5" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("phase-5")}
                  >
                    <NavLink>
                      <span>
                        HR Operations and Onboarding{" "}
                        <i className="fa fa-arrow-down" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-8 vertical-tab-content-wrapper digital-process-content-wrapper">
                {/* Phase 1 */}
                <div
                  id="phase-1"
                  className={`vertical-tab-content   ${
                    activeTab === "phase-1" ? "active" : ""
                  }`}
                >
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Candidate Attraction</h3>
                      <ul>
                        <li>Outbound sourcing tools</li>
                        <li>Branding &amp; communication channel tools</li>
                        <li>Gamification (Attraction)</li>
                        <li>Career site platforms</li>
                        <li>Job board aggregators</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Phase 2 */}
                <div
                  id="phase-2"
                  className={`vertical-tab-content   ${
                    activeTab === "phase-2" ? "active" : ""
                  }`}
                >
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Candidate Engagement</h3>
                      <ul>
                        <li>Chatbots</li>
                        <li>Content curation tools</li>
                        <li>Text, email, newsletter tools</li>
                        <li>Candidate relationship management platforms</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Phase 3 */}
                <div
                  id="phase-3"
                  className={`vertical-tab-content   ${
                    activeTab === "phase-3" ? "active" : ""
                  }`}
                >
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Applicant Screening and Agreement</h3>
                      <ul>
                        <li>Screening software</li>
                        <li>Virtual recruiting assistants</li>
                        <li>Predictive software</li>
                        <li>Gamification (assessment)</li>
                        <li>Data-matching software</li>
                        <li>Neurscience trait-matching software</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Phase 4 */}
                <div
                  id="phase-4"
                  className={`vertical-tab-content  blue ${
                    activeTab === "phase-4" ? "active" : ""
                  }`}
                >
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Interview and Selection</h3>
                      <ul>
                        <li>Video interviewing platforms</li>
                        <li>Test-based interviews</li>
                        <li>Configured interview workflows</li>
                        <li>Recorded video responses</li>
                        <li>Embedded offer formulation tools</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Phase 5 */}
                <div
                  id="phase-5"
                  className={`vertical-tab-content  blue ${
                    activeTab === "phase-5" ? "active" : ""
                  }`}
                >
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>HR Operations and Onboarding</h3>
                      <ul>
                        <li>Scheduling bots</li>
                        <li>Virtual assistants</li>
                        <li>Virtual career fair software</li>
                        <li>On-site event management software</li>
                        <li>HR service delivery platforms</li>
                        <li>Enterprise integration platforms</li>
                        <li>
                          Onboarding platforms integrated with learning and
                          branding
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="ta-container">
            <h2 className="section-main-heading">
              Automation in{" "}
              <span className="highlight">Talent Acquisition (TA)</span>
            </h2>
            <span className="section-desc">
              Persona-based design efforts have been proven effective for TA
              teams and will increase in importance moving forward.
            </span>
            <div className="row page-wrap diagram-block">
              <div className="col-6 content-block">
                <figure className="logo">
                  <img
                    style={{ height: "4rem", width: "4rem" }}
                    src={`/img/brandcart_insidex_final_logo.png`}
                    alt="Insidex logo"
                  />
                </figure>
                <p>
                  At Virtuos Insidex, we can build a strategic plan for how
                  talent aligns with your organization, design for experiences,
                  career paths, and relevance. Our purpose-built platforms can
                  help organizations leverage employee-generated content.
                </p>
                <p>
                  Here is the state of automation in recruiting, and the
                  recruitment automation bullseye as per the CEB Recruiting
                  Innovations Survey.
                </p>
              </div>
              <div className="col-6">
                <figure>
                  <img
                    src={`/img/svg/talent-aquisition-diagram-img.svg`}
                    alt="Automation in Talent Acquisition Diagram"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="seven-c-wrapper">
            <div className="right-fixed-logo">
              <figure>
                <img
                  src={`/img/svg/icons/okrx-logo.svg`}
                />
              </figure>
            </div>
            <div className="page-wrap row">
              <div className="col-6 offport-left font-weight600">
                Modern EX is about creating Objectives, Key Results with
                “Experience” Framework.
              </div>
              <div className="col-5 offport-right">
                <img
                  src={`/img/svg/modern-ex-breaker-img.svg`}
                  alt="Modern EX"
                />
              </div>
            </div>
          </section>
          <section className="it-container">
            <h2 className="section-main-heading">
              IT Market Clock for{" "}
              <span className="highlight">HCM Software (2019)</span>
            </h2>
            <span className="section-desc">
              According to Forrester's Employee Experience Index, employees are
              more likely to be engaged when they believe their IT department is
              focusing on helping them be productive.
            </span>
            <div className="row page-wrap diagram-block">
              <div className="col-6">
                <figure>
                  <img
                    src={`/img/svg/it-market-clock-diagram-img.svg`}
                    alt="IT Market Clock Diagram"
                  />
                </figure>
              </div>
              <div className="col-6 content-block">
                <p>
                  Dozens of HR Technologies are increasing in the marketplace.
                  Primary objectives for adopting cloud-based HCM Suites are:
                </p>
                <ul>
                  <li>
                    <i className="fa fa-long-arrow-right" /> Improved quality of
                    reporting and people information
                  </li>
                  <li>
                    <i className="fa fa-long-arrow-right" /> Improved HCM
                    Process automation
                  </li>
                  <li>
                    <i className="fa fa-long-arrow-right" /> Standardized HCM
                    Processes
                  </li>
                  <li>
                    <i className="fa fa-long-arrow-right" /> Improved quality of
                    service to internal customers
                  </li>
                  <li>
                    <i className="fa fa-long-arrow-right" /> Improved HCM
                    application UX
                  </li>
                  <li>
                    <i className="fa fa-long-arrow-right" /> Increased workforce
                    efficiency/effectiveness
                  </li>
                  <li>
                    <i className="fa fa-long-arrow-right" /> Reduced cost of HR
                    Operations
                  </li>
                  <li>
                    <i className="fa fa-long-arrow-right" /> Globalized or
                    consolidate HCM processes
                  </li>
                  <li>
                    <i className="fa fa-long-arrow-right" /> Improved agility
                    and scale
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="hcm-offerings-container">
            <h2 className="section-main-heading">
              Virtuos{" "}
              <span
                style={{
                  background: "linear-gradient(to right, #26a0da, #348F50)",
                  color: "#fff",
                  padding: "0 10px",
                }}
              >
                INSIDEX
              </span>{" "}
              Current <span className="highlight">HCM Offerings</span>
            </h2>
            <span className="section-desc">
              At Virtuos Insidex, our niche HCM Technology product and service
              offerings include the integration of TA Solutions with Career
              sites to conduct Voice of Employee (VOE).
            </span>
            <span className="section-desc">
              Broadly our offerings are Talent Experience Solutions, Talent
              Intelligence Solutions; Talent marketplaces; Employee Experience
              &amp; Engagement software; and Performance Management.
            </span>
            <div className="row page-wrap sub-heading">
              Talent Experience Solutions
              <figure>
                <img
                  style={{ height: "3rem", width: "3rem" }}
                  src={`/img/brandcart_insidex_final_logo.png`}
                  alt="Insidex Logo"
                />
              </figure>
            </div>
            <div className="row page-wrap items-container">
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/career-website-icon.svg`}
                    alt="Career Site Integration Icon"
                  />
                </figure>
                <h3>Career Site Integration</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/mobility-icon.svg`}
                    alt="Mobility Icon"
                  />
                </figure>
                <h3>Mobility</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/content-management-icon.svg`}
                    alt="Content Management Icon"
                  />
                </figure>
                <h3>Content Management</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/erp-integration-icon.svg`}
                    alt="CRM / ERP Integration Icon"
                  />
                </figure>
                <h3>CRM / ERP Integration</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/hr-analytics-icon.svg`}
                    alt="HR Analytics Icon"
                  />
                </figure>
                <h3>HR Analytics</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/aury-chatbot-icon.svg`}
                    alt="Aury Chatbots Icon"
                  />
                </figure>
                <h3>Aury Chatbots</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/campaigns-icon.svg`}
                    alt="Campaigns Icon"
                  />
                </figure>
                <h3>Campaigns</h3>
              </div>
            </div>
            <div className="row page-wrap sub-heading">
              Talent Intelligence Solutions
              <figure>
                <img
                  style={{ height: "3rem", width: "3rem" }}
                  src={`/img/brandcart_insidex_final_logo.png`}
                  alt="Insidex Logo"
                />
              </figure>
            </div>
            <div className="row page-wrap items-container">
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/market-intellience-icon.svg`}
                    alt="Market Intelligence Icon"
                  />
                </figure>
                <h3>Market Intelligence</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/candidate-matching-icon.svg`}
                    alt="Candidate Matching Icon"
                  />
                </figure>
                <h3>Candidate Matching</h3>
              </div>
              <div className="col-3 item">
                <figure style={{ width: "40%" }}>
                  <img
                    src={`/img/svg/icons/candidate-screening-icon.svg`}
                    alt="Candidate Screening Icon"
                  />
                </figure>
                <h3>Candidate Screening</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/ta-data-icon.svg`}
                    alt="TA Organizational Data Icon"
                  />
                </figure>
                <h3>TA Organizational Data</h3>
              </div>
            </div>
            <div className="row page-wrap sub-heading">
              Talent Marketplace - Opencoder
              <figure>
                <img
                  style={{ height: "3rem", width: "3rem" }}
                  src={`/img/brandcart_insidex_final_logo.png`}
                  alt="Insidex Logo"
                />
              </figure>
            </div>
            <div className="row page-wrap agnitor-block">
              <div className="col-7">
                Talent Marketplace requires a newer platform to attract
                candidates and satisfy the exact needs of organizations.
                Traditional Freelancing/elancing sites could not meet the
                expectations of organized and established organizational
                requirements. These organizations require a higher degree of
                confidence and governance, and the Opencoder Talent
                ExperienceCrowd could fill the void by using crowdsourcing
                technology in a whole new way.
              </div>
              <div className="col-5">
                <figure>
                  <img
                    src={`/img/agnitor-expertise-img.jpg`}
                    alt="Agnitor Expertise Crowd"
                  />
                </figure>
              </div>
            </div>
            <div className="row page-wrap sub-heading">
              Employee Experience &amp; Engagement
              <figure>
                <img
                  style={{ height: "3rem", width: "3rem" }}
                  src={`/img/brandcart_insidex_final_logo.png`}
                  alt="Insidex Logo"
                />
              </figure>
            </div>
            <div className="row page-wrap items-container"  style={{justifyContent:"center"}}>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/ex-logo-icon.svg`}
                    alt="EX Strategy Icon"
                  />
                </figure>
                <h3>EX Strategy</h3>
              </div>
              <div className="col-3 item">
                <figure style={{ width: "40%" }}>
                  <img
                    src={`/img/svg/icons/culturo-logo.svg`}
                    alt="CulturO Icon"
                  />
                </figure>
                <h3>Organizational Culture</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/job-designing-icon.svg`}
                    alt="Job Designing Icon"
                  />
                </figure>
                <h3>Job Designing</h3>
              </div>
              {/* <div className="col-3 item">
                <figure style={{ width: "30%" }}>
                  <img
                    src={`/img/josh-q-logo.png`}
                    alt="JoshQ Logo"
                  />
                </figure>
                <h3>Ongoing Alignment</h3>
              </div> */}
            </div>
            <div className="row page-wrap sub-heading">
              Performance Management
              <figure>
                <img
                  style={{ height: "3rem", width: "3rem" }}
                  src={`/img/brandcart_insidex_final_logo.png`}
                  alt="Insidex Logo"
                />
              </figure>
            </div>
            <div className="row page-wrap items-container" style={{justifyContent:"center"}}>
              <div className="col-3 item">
                <figure style={{ width: "30%" }}>
                  <img
                    src={`/img/svg/icons/okrx-logo.svg`}
                    alt="OKRX Logo"
                  />
                </figure>
                <h3>Objectives, Key Results Experience (Behaviours)</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/360-icon.svg`}
                    alt="360 Outlook Assessment Icon"
                  />
                </figure>
                <h3>360 Outlook Assessment</h3>
              </div>
              <div className="col-3 item">
                <figure>
                  <img
                    src={`/img/svg/icons/voe-icon.svg`}
                    alt="Voice of Employee Icon"
                  />
                </figure>
                <h3>Voice of Employee (VOE)</h3>
              </div>
            </div>
          </section>
          <section className="cf-form-wrapper grey-bg">
            <div className="page-wrap ">
              <h2 className="section-main-heading">
                Want to create integrated{" "}
                <span className="highlight">EX and CX Rainbow</span>?
              </h2>
              <span className="section-desc">
                Virtuos links each process involving Customer Experience (CX)
                and Employee Experience (EX) to the organization 360 strategies.
              </span>
              <span className="section-desc">
                Insidex HR delivers cutting edge technologies for Employee
                Experience (EX) evaluating the right mix of functional,
                technical, and LOB roles, as well as "typical" employees and
                managers. We drive the vendor demos, sandbox, and follow-up
                activities, evaluate the customer communities, and tools and
                standardize reference checking and fully leverage the
                organization's network.
              </span>
              <ContactFormC />
            </div>
          </section>
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Insidex;
