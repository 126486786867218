import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Oracle_Cx_Tour_In_Delhi_Mumbai = () => {
    useEffect(() => {
        document.title = "Virtuos | Oracle and Virtuos CX Tour in Mumbai and Delhi (India)";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/oracle-virtuos-cx-tour-delhi-mumbai-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    New Delhi and Mumbai - 9 August, 2012
                                </span>
                                <h2 className="article-heading">
                                    Oracle and Virtuos CX Tour in Mumbai and Delhi (India)
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Oracle CX Forums were held in Mumbai and Delhi respectively on 8th
                                    August and 9th August 2012. Virtuos key Customers featured as
                                    Panelists in the CX Tour along with Venky Vijay Reddi, the CEO of
                                    Virtuos Solutions.
                                </p>
                                <p>
                                    Oracle Customer Experience Solutions covering RightNow, InQuira, ATG
                                    Commerce, Endeca are offered as parts of Oracle's grand CRM to CX
                                    transition.
                                </p>
                                <p>
                                    Built around the best-in-class CRM, commerce, and industry solutions,
                                    Oracle offers the most complete customer experience solution in the
                                    industry, enabling companies to differentiate themselves across all
                                    channels, touch points, and interactions. From marketing to sales,
                                    delivery to support, and initial engagement to a rewarding
                                    relationship, Oracle’s customer experience solutions deliver the
                                    business results that you want and the great experiences that the
                                    customers want.
                                </p>
                                <p>
                                    In separate 1-hour sessions of showcase in both the events held in
                                    Mumbai and Delhi, Virtuos and some of its customers - MakeMyTrip and
                                    Kyazoonga - spoke on the significant ROI achieved by implementing
                                    Oracle RightNow Suite. Venky Vijay Reddi, CEO of Virtuos Solutions has
                                    been on the panel with the co-founders of BookMyShow.com and
                                    Kyazoonga.com — both of them have been in the movie /show ticketing
                                    business.
                                </p>
                                <h3 className="title">About Oracle</h3>
                                <p>
                                    Oracle Corporation is an American multinational computer technology
                                    corporation headquartered in Redwood Shores, California. Oracle has
                                    acquired RightNow Technologies in 2011 to bring Service Automation on
                                    the cloud.
                                </p>
                                <p>
                                    Oracle has clients in India that include: M, WNS, Standard Chartered
                                    Bank, Siemens, Havells, HelpAge India, M3M India, MacMillan India,
                                    Reliance Capital, Borosil, Aurobindo Pharma, Pride Hotels, Kotak
                                    Securities, Nuthatch Nutricare Technologies, BPTP, Flipkart,
                                    Makemytrip, Cleartrip, BookMyshow, Fundtech, Kyazoonga, among others.
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has built a strong RightNow Practice for CRM Service Cloud by
                                    creating marquee clients like MakeMyTrip, Fundtech, Flipkart,
                                    BookMyShow amongst others. Virtuos will continue to strengthen its
                                    Oracle CX Practice by extending the portfolio of services and
                                    geographies.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Oracle_Cx_Tour_In_Delhi_Mumbai