import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Customer_Experience_Meet_2012 = () => {
    useEffect(() => {
        document.title = "Virtuos | Virtuos attends Dreamforce 2012, KANA Connect 2012 and Oracle Open World 2012  in the US";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-attends-dreamforce-kana-oow-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    Las Vegas and San Francisco - 12 October, 2012
                                </span>
                                <h2 className="article-heading">
                                    Virtuos attends Dreamforce 2012, KANA Connect 2012 and Oracle Open
                                    World 2012 in the US
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos' key executives and Venky Vijay Reddi, Principal
                                    Consultant/CEO have toured the US to attend key sessions at
                                    Dreamforce, Kana Connect, Oracle Open World, and Oracle Customer
                                    Experience Events during 17th September and 05th October 2012. Gaining
                                    significant insights from industry peers, Virtuos is fully charged to
                                    reinforce its strategy and thrust on Customer Experience (CX)
                                    Consulting.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/oracle-dreamforce-kana.jpg`}
                                        alt="Oracle Open World, Dreamforce, Kana Connect"
                                    />
                                </figure>
                                <br />
                                <p>
                                    KANA has showcased Lagaan Enterprise Application — Citizen Experience
                                    Engagement Suite for Government. According to the sources, Australian
                                    Govt. has implemented KANA Lagan Enterprise to provide citizen
                                    services to Australians. Venky Vijay Reddi, CEO of Virtuos has also
                                    met Mr. Tailor, VP Alliances and the Executive Team of KANA and had a
                                    detailed discussion on the business operations of India.
                                </p>
                                <p>
                                    KANA Enterprise which was showcased in 2010 has been delayed for
                                    production due to various reasons, and KANA IQ, KANA Response products
                                    will continue to be supported by KANA for another 10 years. The
                                    dynamic leadership of KANA has been able to support all of its
                                    existing customers with newly acquired Overtone — The social Audience
                                    and Engagement Software Suite.
                                </p>
                                <p>
                                    Virtuos has missed the opportunity of partnering with Salesforce when
                                    the latter approached Virtuos in 2008 to become their master
                                    distributor for India. Virtuos chose to partner with RightNow
                                    Technologies and eventually Oracle for its CRM Practice. Salesforce
                                    has been one of the fastest growing CRM software with year on year
                                    growth of over 50%. Dreamforce is the global event attracting
                                    thousands of customers, partners, and developers from over 100
                                    countries. It is held every year between September and December in San
                                    Francisco.
                                </p>
                                <p>
                                    Venky Vijay Reddi, the CEO of Virtuos has attended his first
                                    Dreamforce Event to witness the magnitude of the Salesforce ecosystem.
                                </p>
                                <p>
                                    “I have never seen an event of this size and scale.” Said Venky Vijay
                                    Reddi, the CEO of Virtuos. “Dreamforce is really for dreamers who can
                                    change the world, and I have instantly become a fan of Marc Benioff,
                                    the CEO and the Founder of Salesforce.”
                                </p>
                                <p>
                                    Marc Benioff’s keynote attracts a few thousands in line and only a few
                                    get to watch the keynote in person while others settle down to see
                                    online or on screen outside the hall.
                                </p>
                                <p>
                                    “Oracle Open World, our first event after becoming the Gold Certified
                                    Partner of Oracle this year. Oracle Open World attracts over 40,000
                                    people from around the world offering a showcase of technologies”.
                                    Said Venky Vijay Reddi, CEO of Virtuos Solutions”. Oracle offers a
                                    full bread of solutions ranging from Database, Hardware, Analytics,
                                    CRM, ERP to HCM and other software.”
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting and Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink href="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink href="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has built a strong RightNow Practice for CRM Service Cloud by
                                    creating marquee clients like MakeMyTrip, Fundtech, Flipkart,
                                    BookMyShow amongst others. Virtuos will continue to strengthen its
                                    Oracle CX Practice by extending the portfolio of services and
                                    geographies.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink href="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Customer_Experience_Meet_2012