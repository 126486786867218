import React, { useEffect } from 'react';

const LanguageSelector = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      function initializeGoogleTranslate() {
        if (window.googleTranslateElementInit) {
          return; // Do not reinitialize if already loaded
        }
        window.googleTranslateElementInit = function() {
          new google.translate.TranslateElement({
            pageLanguage: 'en',
            includedLanguages: 'de,en,es,fr,hi,it,ja,zh-CN',
            layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
            gaTrack: true,
            gaId: ''
          }, 'google_translate_element');
        };
      }
      initializeGoogleTranslate();
    `;
    document.head.appendChild(script);

    const translationScript = document.createElement('script');
    translationScript.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    translationScript.async = true;
    document.head.appendChild(translationScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(translationScript);
    };
  }, []);

  return (
    <div id="google_translate_element"></div>
  );
};

export default LanguageSelector;
