import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_To_Unveil_NOVV = () => {
  useEffect(() => {
    document.title =
      "Virtuos to unveil NOVV — NewWe Digital Transformation Practice – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-to-unveil-NOVV-img-big.jpg`}
                  alt="Virtuos to unveil NOVV — NewWe Digital Transformation Practice"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram, 18 January 2023</span>
                <h2 className="article-heading">
                  Virtuos announces NOVV — The New We Digital Transformation
                  Services.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos on its 15th Anniversary announces NOVV — The New We
                  Digital Transformation Services for select industries
                  leveraging its domain expertise in CRM, Customer Experience,
                  Employee Experience, and other Experience Centric
                  Technologies.
                </p>
                <p>
                  NOV + V, where "NOV" is a Latin root word, means "new." This
                  Latin root is the word origin of many English vocabulary
                  words, including a novel, supernova, and renovate. The Latin
                  root word nov is easily recalled through the English word
                  novel, for a novel experience has never happened before and is
                  "new" to you.
                </p>
                <p>
                  NOV + V, where "V" is the 22nd letter and 17th consonant of
                  the modern English alphabet, and V denotes "WE".
                </p>
                <p>
                  NOVV is our business advisory and consulting practice,
                  offering customers new avenues for launching the "Experience
                  New" paradigm shift and growing from where they left off
                  before the pandemic to embarking on new growth using a lot of
                  collective wisdom and cutting-edge digitalization services.
                  NOVV is one of the critical business units under Virtuos Vaves
                  Group offering holistic "NewWe" Digital Transformation
                  Services in addition to the most established "CXNOW" Customer
                  Experience Transformation Services. NOVV brings all of our
                  business units together as one cohesive and fluid change
                  management agent for companies, especially those at the
                  nascent stages of their business journeys.
                </p>
                <p>
                  Virtuos NOVV will be officially launched in the first week of
                  April 2023 in India followed by the USA and rest of the
                  geographies.
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Microsoft Dynamics, Creatio Low Code No Code
                  Platform &amp; CRM Applications; Agiloft Contract Lifecycle
                  Management (CLM) amongst other Composable Products &amp;
                  Services. Virtuos Consulting services include Four Fabulous
                  business units namely —{" "}
                  <NavLink
                    to="http://www.virtuos.com/vivacis"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Vivacis
                  </NavLink>
                  ,{" "}
                  <NavLink
                    to="http://www.virtuos.com/veracis"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Veracis
                  </NavLink>
                  ,{" "}
                  <NavLink
                    to="http://www.virtuos.com/vaves"
                    trget="_blank"
                    className="link"
                  >
                    Virtuos Vaves
                  </NavLink>
                  , and{" "}
                  <NavLink
                    to="http://www.vedam.com"
                    target="_blank"
                    className="link"
                  >
                    Virtuos Vedam
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/xonomy/`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates, incubates and invests in the next-generation
                  businesses harnessing Artificial Intelligence (AI), Cloud,
                  ECommerce, Blockchain and Digital technologies. At Virtuos
                  Xonomy, we have invested in several companies that include
                  Giftcart — Gifting Experiences Commerce Company; Indic — the
                  Portfolio of Experience Commerce Brands; Sweven — Salesforce
                  CRM Mastermind services; and Tekcorp — the Technology
                  Infrastructure.
                </p>
                <h3 className="title">Virtuos Experience Cloud</h3>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.experiencecloud.com"
                    target="_blank"
                    className="link"
                  >
                    Experience Cloud
                  </NavLink>{" "}
                  Business is building new products integrating our Customer
                  Experience (CX), Employee Experience (EX), and Everything
                  Experience (XX).
                </p>
                <h3 className="title">Virtuos Nexorama</h3>
                <p>
                  Virtuos{" "}
                  <NavLink
                    to="http://www.nexorama.com"
                    target="_blank"
                    className="link"
                  >
                    Nexorama
                  </NavLink>{" "}
                  is our brand new direction to build Composable Products and
                  Services in the Experience Economy. We are building an
                  integrated and agile learning platform — Wundero; Carving out
                  a new business model building a marketplace for LowCode NoCode
                  Technologies by composing a new business UnCodify.com. Virtuos
                  will be launching Expedience — The Talent advisory and
                  sourcing platform and Audacis, a new digital marketing
                  services agency.
                </p>
                <p>
                  For more information visit{" "}
                  <NavLink
                    to="http://www.virtuos.com"
                    target="_blank"
                    className="link"
                  >
                    www.virtuos.com
                  </NavLink>
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_To_Unveil_NOVV;
