import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Ceo_Attends_Dreamforce = () => {
    useEffect(() => {
        document.title = "Virtuos CEO Attends Dreamforce, Salesforce Global Event At San Francisco – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-ceo-attends-dreamforce-salesforce-global-event-2014-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">San Francisco - 16 October, 2014</span>
                                <h2 className="article-heading">
                                    Virtuos CEO attends Dreamforce, Salesforce Global Event at San
                                    Francisco
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Dreamforce has been one of the most important events that Virtuos
                                    teams attend every year without fail. Dreamforce 2014 like previous
                                    many dreamforce events was held at the Moscone Convention Center in
                                    San Francisco between October 13-16, 2014.
                                </p>
                                <p>
                                    “Dreamforce by far the world’s largest software conference.” Said
                                    Venky Vijay Reddi. “Hundreds of companies showcasing their products
                                    and more than 135,000 visitors in person were here in this mega show
                                    to witness the latest developments in CRM, Customer Experience, IOT,
                                    AI, Cloud Infrastructure, and complete SaaS.”
                                </p>
                                <p>
                                    <strong>
                                        Most fascinating sessions attended by Venky Vijay Reddi include
                                    </strong>
                                </p>
                                <ul>
                                    <li>Thee Keynote by Marc Benioff, CEO of Salesforce</li>
                                    <li>Reminiscences from Marc Andreessen</li>
                                    <li>Studio show by WEF Founder Klaus Schwab</li>
                                    <li>Mindfulness by Araina Huffinton</li>
                                    <li>Age of the Customer by George Colony, Forrester</li>
                                </ul>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services, and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely — Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas and executing them with Virtuoso teams. Virtuos was founded
                                    in 2008 by cultivating an ambitious plan to become a multi-billion
                                    dollar company and a game changer by 2020. Thanks to the hard work and
                                    commitment of our team we continue to grow each year. With new
                                    innovative business ideas, delivering incredible transformation
                                    services and building powerful engagement platforms for the future we
                                    are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink to="http://www.fb.com/virtuos" target="_blank" className="link">
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Ceo_Attends_Dreamforce