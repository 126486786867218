import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Banner from "./Banner";
import ProfessionalAnimation from "./ProfessionalAnimation";
import CreativePeople from "./CreativePeople";
import CreativeIdeas from "./CreativeIdeas";
import PowerfulCreatives from "./PowerfulCreatives";
import TechnologyExpert from "./TechnologyExpert";
import BestServices from "./BestServices";
import WebsiteBuilder from "./WebsiteBuilder";
import SubscribeNewsletter from "./SubscribeNewsletter";
import Footer from "../../components/Footer";

const Asana = () => {
  // useEffect(() => {
  //     document.title = "POFO – Creative Agency, Corporate and Portfolio Multi-purpose Template";

  // }, []);

  return (
    <>{""}</>
    // <div className='client-detail-page landing-page'>
    //     <div className='canvas'>
    //         <Navbar />
    //         <div id="smartsheet">
    //             {/* start page title section */}
    //             <Banner />
    //             {/* end page title section */}
    //             {/* start hero section */}
    //             <ProfessionalAnimation />
    //             {/* end banner section */}
    //             {/* start feature box section */}
    //             <CreativePeople />
    //             {/* end feature box section */}
    //             {/* start feature box section */}
    //             <CreativeIdeas />
    //             {/* end feature box section */}
    //             {/* start parallax section */}
    //             <PowerfulCreatives />
    //             {/* end parallax section */}
    //             {/* start feature box section */}
    //             <TechnologyExpert />
    //             {/* end feature box section */}
    //             {/* start feature box section  */}
    //             <BestServices />
    //             {/* end feature box section  */}
    //             {/* start feature box section */}
    //             <WebsiteBuilder />
    //             {/* start feature box section */}
    //             {/* start subscribe section */}
    //             <SubscribeNewsletter />

    //         </div>
    //         <Footer />
    //     </div>
    //     <div className='off-canvas'>
    //         <Nav />
    //     </div>
    // </div>
  );
};

export default Asana;
