import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import NewsTwitter from "../../components/NewsTwitter";
import SubscribeNow from "./SubscribeNow";

const Virtuos_Announces_Post_Covid_Businesses = () => {
  useEffect(() => {
    document.title =
      "Virtuos Announces Post-COVID Business: Xonomy and BrandExpo – Virtuos News &amp; Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-announces-post-covid-businesses-xonomy-and-brandexpo-img-big.jpg`}
                  alt="Virtuos Announces post-COVID Businesses - XONOMY and BrandExpo"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram, 4 June 2020</span>
                <h2 className="article-heading">
                  Virtuos Announces post-COVID Businesses — XONOMY and BrandExpo
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Today Virtuos announced the first two Post-COVID businesses
                  that promise to deliver new services to customers. XONOMY, a
                  new name for Virtuos Global Conferences and Events, will be
                  live with its first All Digital Event in November 2020.
                </p>
                <p>
                  “XONOMY, Dreamforce inspired conference will bring global CX
                  and Digital Enthusiasts together in a virtual world offering
                  great insights, best practices, and Industry’s leading-edge
                  counsel.” Said Venky Vijay Reddi, ExperienCEO of Virtuos
                  Digital. “XONOMY, a name we have liked instantly to become the
                  linchpin of the Experience Economy revolution.”
                </p>
                <figure className="box-shadow">
                  <img
                    src={`/img/brandexpo-xonomy-pic.jpg`}
                    alt="BrandExpo and Xonomy Launch"
                  />
                </figure>
                <br />
                <p>
                  BrandExpo is a new name for showcasing digital brand names and
                  Ideas for those who want to incorporate new companies or find
                  a niche name for their products or services. BrandExpo.com has
                  about 300+ Domain Names listed on its portfolio, and we will
                  be adding over 1000 new titles in the next 90 days.
                </p>
                <p>
                  “BrandExpo serves entrepreneurs, professionals, and brand
                  enthusiasts to ideate and buy a .com domain.” Said Venky Vijay
                  Reddi, ExperienCEO of Virtuos Digital. “At BrandExpo, we have
                  combined the Domain Name and Brand Logo services as a
                  package.”
                </p>
                <p>
                  BrandExpo will also organize All Digital Auctions, Domain
                  Expos bringing all the Domaineers, Branding Gurus, and
                  Internet Entrepreneurs on one platform.
                </p>
                <p>
                  Currently, BrandExpo is live at www.brandexpo.com, offering a
                  few of the services, and many other portfolios of services
                  will be added gradually.
                </p>
                <h3 className="title">About Xonomy</h3>
                <p>
                  At Virtuos, our vision is to rid the world of bad experiences
                  by creating Trusted Digital Human Ecosystems for better Today
                  and Tomorrow. Xonomy is a direction towards achieving this
                  vision by assembling the top-notch professionals to transform
                  the business landscape, to integrate Customer Experience (CX);
                  Employee Experience (EX) for better operational excellence and
                  thrive in the modern society.
                </p>
                <p>
                  The Global Community of Customer Experience (CX) and Digital
                  Transformation (DT) Professionals have enormous access to
                  information, insights, and industry-wide disruption. Customer
                  Experience Academy (CXA.Org) and Virtuos have come together to
                  launch XONOMY — Experience Economy focussed Conferences.
                  XONOMY will showcase products, services, experiences, and
                  business trends across the industries.
                </p>
                <p>
                  XONOMY is deeply inspired by Dreamforce, which focuses on CRM
                  Technologies, Industries, and the Society. For more
                  information, visit{" "}
                  <NavLink
                    to="http://www.xonomy.com"
                    target="_blank"
                    className="link"
                  >
                    www.xonomy.com
                  </NavLink>
                </p>
                <h3 className="title">About BrandExpo</h3>
                <p>
                  <strong>
                    BrandExpo, It's part of Vedam, Virtuos Company
                  </strong>
                  . At BrandExpo, we create a brand presence for our customers
                  from start to finish, regardless of whether you are a
                  start-up, a nonprofit, or a product.
                </p>
                <p>
                  At BrandExpo, our approach to brand identity takes disparate
                  elements and unifies them into whole systems by integrating
                  with domain idea, concept, and design. Design plays an
                  essential role in creating and building brands. Design
                  differentiates and embodies the intangibles — emotion,
                  context, and essence - that matter most to consumers.
                  BrandExpo offers hundreds of Domain names and brand names for
                  various industries and business verticals. For more
                  information visit{" "}
                  <NavLink
                    to="http://www.brandexpo.com"
                    target="_blank"
                    className="link"
                  >
                    www.brandexpo.com
                  </NavLink>
                </p>
                <h3 className="title">About Virtuos</h3>
                <p />
                <p>
                  Virtuos Digital Ltd. is a leading Customer Experience (CX)
                  &amp; Digital Transformation Consulting company with its
                  corporate headquarters in India, and the USA serving global
                  customers. Virtuos has two important business operations,
                  namely{" "}
                  <NavLink to={`/consultare/`} target="_blank" className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy/`} target="_blank" className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <p />
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/cxdesk/`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/xonomy/ai/`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce and Digital technologies. The{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  under the aegis of Virtuos Xonomy is a think tank to breed and
                  bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.vyo.com"
                    target="_blank"
                    className="link"
                  >
                    VYO.com
                  </NavLink>{" "}
                  (Part of BIGV 4.0 As A Strategy) and a host of unique
                  Experience Cloud and Experience Crowd applications. Virtuos
                  Experience Cloud business comprises a CEDAR strategy —{" "}
                  <NavLink
                    to="http://www.cloudle.com/"
                    target="_blank"
                    className="link"
                  >
                    Cloudle
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Announces_Post_Covid_Businesses;
