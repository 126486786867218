import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Terms_Of_Use = () => {
  useEffect(() => {
    document.title = "Website Terms and Conditions of Use – Virtuos Digital";
  }, []);
  return (
    <div className="term-condition-page listing-page">
      <Navbar />
      <main className="main-wrapper">
        <>
          <section className="top-hero-product-banner" >
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5">
                  <figure>
                    <img
                      src={`/img/svg/terms-condition-banner-img.svg`}
                      alt="Terms of Use - Virtuos"
                    />
                  </figure>
                </div>
                <div className="col-6">
                  <h3 className="section-heading">
                    <span className="highlight">Terms</span> of Use
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="legal-section" >
            <div className="page-wrap legal-wrapper">
              <div className="legal-doc-wrapper">
                <span className="date">Last Updated: 1 November 2022</span>
                <h2 className="section-heading">1. Introduction</h2>
                <p>
                  Welcome to Virtuos. These Terms of Use (“Terms”, “Terms of
                  Service”, “Agreement”) apply to the Virtuos website (“web
                  site”, “site”, “sites”) located at www.virtuos.com and to all
                  links associated with www.virtuos.com. By using
                  www.virtuos.com and its associated links, you agree to these
                  Terms of Use. These Terms of Use serve as a binding agreement
                  between you (“your”, “you”) and Virtuos Digital Ltd.
                  (“Virtuos”, “Virtuos Digital”, “Virtuos Corporation”, “we”,
                  “our”, “us”).
                </p>
                <h2 className="section-heading">
                  2. Changes to Terms / Website
                </h2>
                <p>
                  Virtuos reserves the right to modify these Terms of Use and
                  our website at any time. It is your responsibility to
                  periodically check these Terms of Use for changes. By
                  continuing to use our website after those changes are made,
                  will mean that you accept and agree to the changes.
                </p>
                <h2 className="section-heading">3. Content</h2>
                <p>
                  This site and the contents provided on this site, including,
                  but not limited to, images, logos, text, sounds, videos, user
                  interfaces, visual interfaces, html code, documents, may not
                  be copied, reproduced, republished, uploaded, posted,
                  transmitted, distributed, encoded, or translated, in any way,
                  without prior written consent of Virtuos, except that you may
                  download one copy of the materials on your personal computer
                  solely for your personal, non-commercial use, provided that
                  you do not modify the material in any way and keep all
                  trademarks, copyright, and proprietary notices intact.
                </p>
                <h2 className="section-heading">4. Your Use of the Site</h2>
                <p>
                  You may not use any deep-link, page-scrape, spider, robot,
                  crawl, index, Internet agent or other automatic device,
                  program, algorithm or technology which does the same things,
                  to use, access, copy, acquire information, generate
                  impressions, input information, store information, search,
                  generate searches or monitor any portion of this website.
                </p>
                <p>
                  You agree that (1) you will not attempt to gain unauthorized
                  access to any portion of this site or any Virtuos accounts,
                  computer systems or networks connected to this site, (2) you
                  will not probe, scan or test the vulnerability of the Site or
                  any network connected to the Site, nor breach the security or
                  authentication measures on the Site or any network connected
                  to the Site, (3) you will not take any action that imposes an
                  unreasonable or disproportionately large load on the
                  infrastructure of the Site or Virtuos’s systems or networks,
                  or any systems or networks connected to the Site or to
                  Virtuos.
                </p>
                <p>
                  Certain features on the site require you to fill personal
                  details and upload documents. You can only post/upload content
                  if you own all the rights to the contact, or if another rights
                  holder has given you permission.
                </p>
                <h2 className="section-heading">5. Indemnity</h2>
                <p>
                  You agree to indemnify and hold Virtuos and its employees
                  harmless from any demands, loss, liability, claims or expenses
                  (including attorneys’ fees), made against Virtuos by any third
                  party due to or arising out of or in connection with your use
                  of the Site.
                </p>
                <h2 className="section-heading">6. Privacy Policy</h2>
                <p>
                  Virtuos’s Privacy Policy applies to use of this Site, and its
                  terms are made a part of these Terms of Use by this reference.
                  Additionally, by using the Site, you acknowledge and agree
                  that Internet transmissions are never completely private or
                  secure. You understand that any message or information you
                  send to the Site may be read or intercepted by others, even if
                  there is a special notice that a particular transmission is
                  encrypted.
                </p>
                <h2 className="section-heading">7. Disclaimers</h2>
                <p>
                  VIRTUOS DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE
                  OR FEATURE OF THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR
                  THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE
                  SITE WILL PROVIDE SPECIFIC RESULTS. THE SITE AND ITS CONTENT
                  ARE DELIVERED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. ALL
                  INFORMATION PROVIDED ON THE SITE IS SUBJECT TO CHANGE WITHOUT
                  NOTICE. VIRTUOS CANNOT ENSURE THAT ANY FILES OR OTHER DATA YOU
                  DOWNLOAD FROM THE SITE WILL BE FREE OF VIRUSES OR
                  CONTAMINATION OR DESTRUCTIVE FEATURES. VIRTUOS DISCLAIMS ALL
                  WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF
                  ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
                  PARTICULAR PURPOSE. VIRTUOS DISCLAIMS ANY AND ALL LIABILITY
                  FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN
                  CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE AND/OR ANY
                  VIRTUOS SERVICES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE
                  OF THE SITE AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST
                  VIRTUOS FOR DISSATISFACTION WITH THE SITE OR ANY CONTENT IS TO
                  STOP USING THE SITE OR ANY SUCH CONTENT. THIS LIMITATION OF
                  RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
                </p>
                <h2 className="section-heading">8. Limitation of Liability</h2>
                <p>
                  IN NO EVENT SHALL VIRTUOS BE LIABLE FOR ANY DIRECT, INDIRECT,
                  INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR
                  LOSS OF PROFITS, REVENUE, DATA OR DATA USE, INCURRED BY YOU OR
                  ANY THIRD PARTY, WHETHER IN AN ACTION IN CONTRACT OR TORT,
                  ARISING FROM YOUR ACCESS TO, OR USE OF, THE SITE OR ANY
                  CONTENT PROVIDED ON OR THROUGH THE SITE.
                </p>
                <h2 className="section-heading">
                  9. Violation of these Terms of Use
                </h2>
                <p>
                  You agree that Virtuos may, in its sole discretion and without
                  prior notice, terminate your access to the Site and/or block
                  your future access to the Site if we determine that you have
                  violated these Terms of Use or other agreements or guidelines
                  which may be associated with your use of the Site. You agree
                  that Virtuos shall not be liable to you or any third-party for
                  any termination or limitation of your access to, or use of,
                  the Site or any Content, including Content that you may have
                  Shared.
                </p>
                <h2 className="section-heading">10. Feedback</h2>
                <p>
                  Any feedback you provide at this site shall be deemed to be
                  non-confidential. Virtuos shall be free to use such
                  information on an unrestricted basis. For any concern
                  regarding these Terms of Use, please contact Virtuos at{" "}
                  <NavLink to="mailto:info@virtuos.com" className="link">
                    info@virtuos.com
                  </NavLink>
                </p>
              </div>
            </div>
          </section>
        </>

        <Footer />
      </main>
    </div>
  );
};

export default Terms_Of_Use;
