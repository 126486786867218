import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Bags_Crm_Contract_From_Leading_Bpo = () => {
    useEffect(() => {
        document.title = "Virtuos bags a Multi-channel Customer Service CRM contract from a leading BPO – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-bags-crm-contract-from-leading-bpo-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Chennai, 25 Jan 2008</span>
                                <h2 className="article-heading">
                                    Virtuos bags a Multi-channel Customer Service CRM contract from a
                                    leading BPO
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    A week after its incorporation, Virtuos landed a major deal valued at
                                    US$100k, including the licensing and implementation services for KANA
                                    Response, a product Virtuos team has implemented for a leading client
                                    — Standard Chartered Bank.
                                </p>
                                <p>
                                    “Chennai has become a hotspot for KANA Software as many companies have
                                    been considering implementing KANA Response, a state of the art Email
                                    Engagement System with complete CRM”. said Venky Vijay Reddi. “Virtuos
                                    has won these deals at a significant premium over{" "}
                                    <NavLink
                                        to="https://www.talisma.com/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Talisma Software
                                    </NavLink>
                                    , which has a market presence in India with over 100 implementations”.
                                </p>
                                <p>
                                    Kana is a world leader in the CRM Interactive Suites and Knowledge
                                    Management category. In spite of a delayed entry into Indian market,
                                    KANA has picked up momentum with Virtuos. Because of its proven
                                    technological edge, KANA continuously fared better over Talisma and
                                    eGain software worldwide. At one point in time during the early dotcom
                                    days, the market capitalization of KANA was bigger than Oracle.
                                </p>
                                <p>
                                    “As part of our Vision framework, we had to partner with only the
                                    established world leaders and choose our customers who appreciate the
                                    aggrandized value that we bring to the table”. Said Amarinder Singh,
                                    Solution Engineer Associate (KANA Practice). “Our strong leadership
                                    was able to persuade and impress KANA’s senior leadership to design
                                    custom pricing for the Indian market”.
                                </p>
                                <p>
                                    "A leading BPO — Sutherland Global has put trust in our consulting
                                    capabilities and proven expertise to implement KANA Response Software.
                                    With the high standards of delivering world-class Business Process
                                    Outsourcing (BPO) services, we are looking for the state of the art
                                    software that can add value to our customers worldwide." Said T.
                                    Somasundaram, CTO of Sutherland Software. "I am confident that Kana
                                    and Virtuos teams deliver world-class solutions for our needs today
                                    and tomorrow."
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/kana+virtuos-sutherland-img.jpg`}
                                        alt="Sutherland"
                                    />
                                </figure>
                                <div className="note">
                                    Sutherland Global Implemented KANA Response Modules
                                </div>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd., a company registered with the Indian
                                    Companies Act, is a startup in the space of the CRM Cloud Computing
                                    business. As a company, Virtuos is born in the cloud by forging
                                    alliances with leading companies such as KANA Software and Maximizer.
                                    Virtuos has become a pioneer in CRM Cloud computing by migrating the
                                    CRM to the cloud for the first time in India, thanks to the deep
                                    expertise in this area as legacy practice from Tekcorp.
                                </p>
                                <p>
                                    The company's website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>{" "}
                                    with its corporate office in New Delhi, India's capital. Virtuos
                                    operates from a shared office premises with "Tekcorp combine" at the
                                    prestigious location — Okhla Industrial Area Phase 1 New Delhi in a
                                    building with more than 10,000 SQ FT space.
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Bags_Crm_Contract_From_Leading_Bpo