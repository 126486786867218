import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Co_Sponsors_Oracle_2014 = () => {
    useEffect(() => {
        document.title = "Virtuos Co-Sponsors Oracle Cloud World in New Delhi and Melbourne Australia – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/oracle-open-cloud-world-2014-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    New Delhi and Melbourne - 22 January, 2014
                                </span>
                                <h2 className="article-heading">
                                    Virtuos co-sponsors Oracle Cloud World 2014 in New Delhi and
                                    Melbourne, Australia
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos, a leading CX and Digital Transformation Consultancy has
                                    received the CX Partner of the year recognition from Oracle for the
                                    outstanding performance. Virtuos CEO also attended the Summit along
                                    with the Executive team to participate in Oracle Cloud World where
                                    Virtuos has been a co-sponsor.
                                </p>
                                <p>
                                    Oracle CloudWorld is being held in New Delhi on 22nd January 2014 and
                                    in Melbourne on 4th March 2014.
                                </p>
                                <p>
                                    Venky Vijay Reddi, CEO and Amarinder Singh, Solution Lead for RightNow
                                    CX will be attending the Oracle Cloud World in Melbourne.
                                </p>
                                <p>
                                    <strong>Keynote Speakers:</strong>
                                </p>
                                <ul>
                                    <li>Mark Hurd President, Oracle Corporation, USA</li>
                                    <li>Sandeep Mathur Managing Director Oracle India</li>
                                    <li>
                                        Reggie Bradford Senior Vice President, Product Development Oracle
                                    </li>
                                    <li>
                                        Rod Johnson Group Vice President, Applications &amp; Industry
                                        Solution Group, Oracle Corporation, USA
                                    </li>
                                    <p>
                                        Sangita Reddy Executive Director, Operations, Apollo Hospitals Group
                                    </p>
                                </ul>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/oracle-open-cloud-world-2014-pics.jpg`}
                                        alt="Oracle Open Cloud World 2014"
                                    />
                                </figure>
                                <h3 className="title">CloudWorld highlights from New Delhi</h3>
                                <p>
                                    The Customer Experience (CX) business stole the show — and videos from
                                    Virtuos clients — MakeMyTrip were played during the keynote sessions.
                                    Virtuos team have attended the keynote sessions, and the attractively
                                    decorated Virtuos Booth attracted a lot of invitees. The Booth
                                    Attendants have given away the Virtuos branded Pens, Coffee Mugs to
                                    visitors.
                                </p>
                                <p>
                                    During the cloud world — Virtuos was chosen to be one of the top five
                                    partners for the cloud, and a press call was called by Oracle to
                                    introduce our cloud offerings.
                                </p>
                                <p>
                                    Virtuos had the privilege of meeting the Eloqua Senior Management and
                                    Sandeep Mathur- Managing Director, Oracle India for more than 30
                                    minutes with each team exclusively in one to one session. Mr. Sandeep
                                    Mathur had complimented Virtuos efforts in fuelling the Customer
                                    Experience(CX) Practice in India with leadership position.
                                </p>
                                <p>
                                    <strong>
                                        Kristina Hermanns, Director of Solution Consulting has also been
                                        interviewed by Oracle PR Team:
                                    </strong>
                                </p>
                                <div className="video-wrapper offport-down">
                                    <div className="embed-container">
                                        <iframe
                                            src="https://www.youtube.com/embed/89FYQu9uNt0?autoplay=0&showinfo=0"
                                            frameBorder={0}
                                            allowFullScreen=""
                                        />
                                    </div>
                                    <span>Video Source: Youtube</span>
                                </div>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services, and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely — Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas, and executing them with Virtuoso teams. Virtuos was
                                    founded in 2008 by cultivating an ambitious plan to become a
                                    multi-billion dollar company and a game changer by 2020. Thanks to the
                                    hard work and commitment of our team we continue to grow each year.
                                    With new innovative business ideas, delivering incredible
                                    transformation services and building powerful engagement platforms for
                                    the future we are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink to="http://www.fb.com/virtuos" target="_blank" className="link">
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Co_Sponsors_Oracle_2014