import React from 'react'
// import {React,useEffect} from 'react'

import { NavLink, Link } from "react-router-dom";
import LanguageSelector from './LanguageSelector';
const Topheader = () => {

    return (
        <>
            <div className="top-header">
                <div className="row page-wrap">
                    <div className="row left-wrapper">
                        <span className="hero-line">DIGITAL FIRST&trade; BUSINESS</span>
                        <ul className="row top-social-wrapper" style={{ marginTop: "0px" }}>
                            {/* <li><NavLink to="http://localhost:3000/smartsheet" rel="noreferrer"
                            >Smartsheet</NavLink></li> */}
                            <li><NavLink to="https://www.facebook.com/Virtuos/" className="fab fa-facebook-f" rel="noreferrer"
                                target="_blank"></NavLink></li>
                            <li><NavLink to="https://twitter.com/virtuos" className="fab fa-twitter" rel="noreferrer" target="_blank"></NavLink>
                            </li>
                            <li><NavLink to="https://www.linkedin.com/company/virtuos/" className="fab fa-linkedin-in" rel="noreferrer"
                                target="_blank"></NavLink></li>
                            <li><NavLink to="http://pinterest.com/virtuoscorp" className="fab fa-pinterest-p" rel="noreferrer"
                                target="_blank"></NavLink></li>
                            <li><NavLink to="https://www.youtube.com/user/virtuoscorp" className="fab fa-youtube" rel="noreferrer"
                                target="_blank"></NavLink></li>
                            <li><NavLink to="mailto:info@virtuos.com" className="fas fa-envelope"></NavLink></li>
                        </ul>
                    </div>
                    <div className="row right-wrapper">
                        <ul className="row top-contact-wrapper" style={{ marginTop: "0px" }}>
                            <li><NavLink exact="true" to='/org'>Virtuos.org</NavLink></li>
                            <li><NavLink exact="true" to='/contact'>Contact</NavLink></li>
                            {/* <li><Link to="https://www.virtuos.com/contact">Contact</Link></li> */}
                            <li><NavLink exact="true" to="https://virtuos.custhelp.com/" target="_blank" rel="noreferrer">My Help</NavLink></li>
                            <li>
                                {/* <div id="google_translate_element"></div> */}
                                {/* {/* <LanguageSelector /> */}
                                {/* <script type="text/javascript">
                    function googleTranslateElementInit() {
                        new google.translate.TranslateElement({
                            pageLanguage: 'en',
                            includedLanguages: 'de,en,es,fr,hi,it,ja,zh-CN',
                            layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
                            gaTrack: true,
                            gaId: 'UA-119319304-1'
                        }, 'google_translate_element');
                    }
                    </script> */}
                                {/* <script type="text/javascript"
                        src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script> */}
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Topheader
