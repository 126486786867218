import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

const Exonomy_Ai = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };
  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/xonomy/ai");
  }, []);
  useEffect(() => {
    document.title =
      "Artificial Intelligence | Ideas and Incubation – Virtuos Xonomy";
  }, []);
  return (
    <div className="ai-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/ai-bg-banner.jpg`}
                alt="AI - A Digital Nerve Center"
              />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5">
                  <h3 className="section-heading">
                    AI is Not Just A Technology.
                  </h3>
                  <span>It’s the Digital Nerve Center.</span>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/xonomy/`}>Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/xonomy/ai/`} className="active">
                    AI
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/xonomy/ai/self-service/`}>
                    AI SELF-SERVICE
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud/`}>EXPERIENCE CLOUD</NavLink>
                </li>
              </ul>

              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${
                    showExtraLinks ? "active" : ""
                  } inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" /> More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/experience-crowd/`}>
                          EXPERIENCE CROWD
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/io/`}>INNOVATION ON</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/business4-0/`}>
                          BUSINESS 4.0 STRATEGY
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/aury/`}>AURY PLATFORM</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/ventures/`}>VENTURES</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/`}>VIRTUOS VUCA</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/covid/`}>VIRTUOS FOR COVID</NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="advantages-of-aury ecosystem-section">
            <h3 className="section-main-heading">
              THE WORLD OF TOMORROW WITH <span className="highlight">AI</span>
            </h3>
            <span className="section-desc">
              Artificial Intelligence stands out as the next biggest
              transformation in the digital age even surpassing the impact we
              have witnessed with Internet. Internet has opened whole new
              economy — Amazon, Netflix, Airbnb, Uber, Alibaba, Salesforce,
              Workday are true beneficiaries of internet. At Virtuos Xonomy, we
              are at the helm of harnessing AI and democratizing the AI for our
              customers by assembling the best of techniques.
            </span>
            <div className="page-wrap row">
              <figure className="offport-left">
                <img
                  src={`/img/svg/world-of-tomorrow-ai-img.svg`}
                  alt="The World of Tomorrow with AI"
                />
              </figure>
              <div className="col-6 points">
                <ul>
                  <li className="row offport-right">
                    <span className="icon">
                      <img
                        src={`/img/svg/icons/infinity-icon.svg`}
                        alt="Infinity Icon"
                      />
                    </span>
                    <div className="content-wrapper">
                      <h3 className="font-weight600">
                        CX, EX and XX with Digital
                      </h3>
                      <p>
                        Marketing Analytics, Algorithms, and AI Will Become
                        Integral — and Integrated — Functionality for Most CRM,
                        CX, HCM and DPA Implementations that Virtuos Consultare
                        will be implementing. AI Technologies include IBM
                        Watson, Google and Salesforce Einstein amongst other key
                        players.
                      </p>
                      <p>
                        <i className="fal fa-long-arrow-right" />{" "}
                        <NavLink to={`/cx-digital`} className="link">
                          CX.Digital
                        </NavLink>{" "}
                        &nbsp; &nbsp; <i className="fal fa-long-arrow-right" />{" "}
                        <NavLink to={`/ex-digital`} className="link">
                          EX.Digital
                        </NavLink>{" "}
                        &nbsp; &nbsp; <i className="fal fa-long-arrow-right" />{" "}
                        <NavLink to={`/xx-digital`} className="link">
                          XX.Digital
                        </NavLink>
                      </p>
                    </div>
                  </li>
                  <li className="row offport-right">
                    {" "}
                    <span className="icon">
                      <img
                        src={`/img/svg/icons/exonomy-logo-icon.svg`}
                        alt="ABC of Xonomy"
                      />
                    </span>
                    <div className="content-wrapper">
                      <h3 className="font-weight600">ABC of Xonomy</h3>
                      <p>
                        AIcelerate - The AI foundation to accelerate business
                        through Experience Cloud and Crowd technologies.
                        Business 4.0 As A Strategy is the 2020 vision to
                        transform customers to be the Virtuoso heroes.
                        CustomerWide - The customer led, insights-driven, fast
                        and connected model.
                      </p>
                      <p>
                        <i className="fal fa-long-arrow-right" />{" "}
                        <NavLink to={`/business4-0/`} className="link">
                          Business 4.0 Strategy
                        </NavLink>
                      </p>
                    </div>
                  </li>
                  <li className="row offport-right">
                    <span className="icon">
                      <img
                        src={`/img/svg/icons/aicceklerate-ai-icon.svg`}
                        alt="AI Powered Products"
                      />
                    </span>
                    <div className="content-wrapper">
                      <h3 className="font-weight600">AI Powered Products</h3>
                      <p>
                        Virtuos Xonomy has been investing in new AI powered
                        products such as{" "}
                        <NavLink
                          to={`/xonomy/aury/`}
                          className="link"
                          target="_blank"
                        >
                          Aury
                        </NavLink>{" "}
                        — the Chatbot and infusing AI across the products
                        through our Open AI Architecture solutions from
                        partners. AIccent is a step forward to make "AI" an
                        integral part of every implementation we do for CRM,
                        CLM, and other CX Services.
                      </p>
                    </div>
                  </li>
                  <li className="row offport-right">
                    <span className="icon">
                      <img
                        src={`/img/svg/aiccent-logo.svg`}
                        alt="AI in Customer Self-Service"
                      />
                    </span>
                    <div className="content-wrapper">
                      <h3 className="font-weight600">
                        AI in Customer Self-Service
                      </h3>
                      <p>
                        AI powered self-service solutions enable your customers
                        to proactively find the information they're looking for
                        without needing to contact your customer support agents.
                        By drawing on your existing knowledge base, our patented
                        natural language processing (NLP) and machine learning
                        (ML){" "}
                        <NavLink to={`/rainbowcx/`} className="link">
                          Rainbow CX
                        </NavLink>
                        technologies dialogue with your customers and guide them
                        towards a successful resolution.
                      </p>
                      <p>
                        <i className="fal fa-long-arrow-right" />{" "}
                        <NavLink
                          to={`/xonomy/ai/self-service/`}
                          className="link"
                        >
                          Explore more
                        </NavLink>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="ai-wrapper grey-bg">
            <div className="page-wrap">
              <div className="row ai-container">
                <div className="col-12">
                  <h2>
                    WHAT’S NEW WITH <br />
                    VIRTUOS.
                    <span className="highlight">
                      <strong>AI</strong>
                    </span>
                  </h2>
                </div>
                <div className="col-4 item-block offport-down">
                  <div className="item">
                    <div className="col-9">
                      <h3 className="font-weight600">
                        <span className="red">AI</span>CCENT
                      </h3>
                      <p id="margin1">
                        Virtuos has an ambitious Project code named as "AIccent"
                        to help customers to leverage Artificial Intelligence
                        (AI) when implementing CRM, HCM, and ERP from Oracle,
                        Creatio, Verint, Microsoft amongst others.
                      </p>
                    </div>
                    <figure>
                      <img
                        src={`/img/svg/aiccent-logo.svg`}
                        alt="Aiccent Logo"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-4 item-block offport-down">
                  <div className="item">
                    <div className="col-9">
                      <h3 className="font-weight600">Sweven</h3>
                      <p id="margin1">
                        Virtuos Xonomy has invested in Sweven which is focussing
                        on Salesforce CRM Mastermind practice with
                        differentiated services across industry verticals.
                        Sweven will be building several industry specific
                        product portfolios on the Salesforce platform.
                      </p>
                    </div>
                    <figure>
                      <img
                        src={`/img/svg/icons/sweven-emblem.svg`}
                        alt="Sweven Logo Emblem"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-4 item-block offport-down">
                  <div className="item">
                    <div className="col-9">
                      <h3>Aury</h3>
                      <p id="margin1">
                        <NavLink to={`/xonomy/aury/`} className="link">
                          Aury<sub>®</sub>
                        </NavLink>{" "}
                        is our first AI rich conversational platform with
                        patented natural language processing (NLP) and machine
                        learning (ML) technologies dialogue.{" "}
                        <NavLink to={`/xonomy/aury/`} className="link">
                          Aury<sub>®</sub>
                        </NavLink>{" "}
                        is in production and ready for delivering next edge AI
                        Self Service..
                      </p>
                    </div>
                    <figure>
                      <img
                        src={`/img/svg/icons/aury-logo-icon.svg`}
                        alt="Aury Emblem"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="advantages-of-aury ecosystem-section">
            <h2 className="section-main-heading">
              <span className="red">INNOVATE</span> WITH{" "}
              <span className="highlight">GENNEXT AI IDEAS</span>
            </h2>
            <span className="section-desc">
              We are crowdsourcing ideas to build GenNext AI applications, and
              also seeking active participation from Gen-Z and Millennials to
              enable the future generation with Augmented AI Projects code named
              as AIccelerate.
            </span>
            <div className="page-wrap row gen">
              <div className="col-6 points">
                <ul>
                  <li className="row offport-left">
                    {" "}
                    <span className="icon">
                      <img
                        src={`/img/svg/icons/crowdsourcing-icon.svg`}
                        alt="Humans and Algorithm-based Automation"
                      />
                    </span>
                    <div className="content-wrapper">
                      <h3 className="font-weight600">
                        Human-in-the-Loop Crowdsourcing
                      </h3>
                      <p>
                        It is the complementary use of humans and
                        algorithm-based automation to solve a problem or perform
                        a task, where the human input further improves the
                        automated AI or data management solution.
                      </p>
                    </div>
                  </li>
                  <li className="row offport-left">
                    {" "}
                    <span className="icon">
                      <img src={`/img/svg/vyo-logo.svg`} alt="VYO Logo" />
                    </span>
                    <div className="content-wrapper">
                      <h3 className="font-weight600">
                        VYO.com V (We), Y (You), O (Opportunity)
                      </h3>
                      <p>
                        Three-year-olds know to ask Alexa and other VPAs to play
                        songs, and older students request for assistance with
                        schoolwork. It's as natural to go with Digital Twin in
                        the future as we have more important things to do in
                        life.
                      </p>
                    </div>
                  </li>
                  <li className="row offport-left">
                    {" "}
                    <span className="icon">
                      <img
                        src={`/img/svg/icons/aury-logo-icon.svg`}
                        alt="Aury ChatBots"
                      />
                    </span>
                    <div className="content-wrapper">
                      <h3 className="font-weight600">Aury ChatBots</h3>
                      <p>
                        Aury is coming soon with microservices that can operate
                        on other bots, apps or services in response to event
                        triggers or user requests. They may invoke other
                        services or applications, often emulating a user or app,
                        or use an API to achieve the same effect.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-6 offport-right">
                <figure>
                  <img
                    src={`/img/svg/gen-ai-ideas-img.svg`}
                    alt="GenNext AI Applications"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="skills-container grey-bg">
            <figure className="offport-right">
              <img
                src={`/img/skills-breaker-bg.png`}
                alt="Virtuos Skills and Strengths"
              />
            </figure>
            <div className="page-wrap row">
              <div className="col-6">
                <h2>
                  OUR SKILLS AND <br />
                  <strong>
                    <span className="highlight">STRENGTHS</span>
                  </strong>
                </h2>
                <p>
                  We are hiring data scientists and building our capabilities in
                  harnessing AI, Machine Learning, and Deep learning{" "}
                  <NavLink
                    to="https://www.mckinsey.com/business-functions/mckinsey-analytics/our-insights/an-executives-guide-to-ai"
                    rel="noreferrer"
                    target="_blank"
                    className="link"
                  >
                    techniques
                  </NavLink>
                  . Our strengths include Mapping AI techniques to problem
                  types; collating and analyzing hundreds of use cases across 20
                  industries and various business functions, and sizing the
                  potential of AI drawing insights from use cases.
                </p>
                <div className="skills-wrapper offport-down">
                  <div className="skills-wrap">
                    <div className="row">
                      <i>Artificial Intelligence - (55%)</i>
                    </div>
                    <div className="barWrapper">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow={55}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "55%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="skills-wrap">
                    <div className="row">
                      <i>Machine Learning - (50%)</i>
                    </div>
                    <div className="barWrapper">
                      <div className="progress">
                        <div
                          className="progress-bar blue"
                          role="progressbar"
                          aria-valuenow={50}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "50%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="skills-wrap">
                    <div className="row">
                      <i>Deep Learning - (50%)</i>
                    </div>
                    <div className="barWrapper">
                      <div className="progress">
                        <div
                          className="progress-bar pink"
                          role="progressbar"
                          aria-valuenow={50}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "50%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="skills-wrap">
                    <div className="row">
                      <i>AI in Experience Applications - (75%)</i>
                    </div>
                    <div className="barWrapper">
                      <div className="progress">
                        <div
                          className="progress-bar red"
                          role="progressbar"
                          aria-valuenow={75}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: "75%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="connect-wrapper">
            <figure>
              <img src={`/img/voc.jpg`} alt="Voice of Customer" />
            </figure>
            <div className="page-wrap row offport-up">
              <h2>EXPERIMENT IDEAS WITH OUR INNOVATION ON LAB</h2>
              <p>
                Let’s know how we can partner with you to bring new products,
                services and ecosystems to business and society changing the
                world around us.{" "}
              </p>
            </div>
          </section>
          <section className="page-wrap connect-container">
            <div className="row">
              <div className="col-4 item offport-down">
                <span className="icon office" style={{ padding: "2.7rem" }}>
                  <i className="fas fa-map-marker-alt" />
                </span>
                <span className="title">Our Office</span>
                <span className="text">Contact us to get prompt response</span>
                <NavLink to={`/contact`} className="btn btn-secondary">
                  Open Map
                </NavLink>
              </div>
              <div className="col-4 item offport-down">
                <span className="icon email" style={{ padding: "2.7rem" }}>
                  <i className="fal fa-envelope" />
                </span>
                <span className="title">Our Email</span>
                <span className="text">Email us to reach you quickly</span>
                <NavLink
                  to="mailto:info@virtuos.com"
                  className="btn btn-secondary"
                >
                  Send Email
                </NavLink>
              </div>
              <div className="col-4 item offport-down">
                <span className="icon support" style={{ padding: "2.7rem" }}>
                  <i className="fas fa-headphones" />
                </span>
                <span className="title">Our Support</span>
                <span className="text"> Go to customer support portal</span>
                <NavLink
                  to="http://virtuos.custhelp.com"
                  rel="nofollow"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Open Incident
                </NavLink>
              </div>
            </div>
          </section>
          {/*?php include '../../footer-xonomy.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Exonomy_Ai;
