import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Co_Sponsors_Oracle_Cloud_Mumbai = () => {
    useEffect(() => {
        document.title = "Virtuos co-sponsors Oracle CloudWorld in Mumbai – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-oracle-cloud-world-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Mumbai - 2 April, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos co-sponsors Oracle CloudWorld in Mumbai
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Solutions and Oracle are jointly working on building Customer
                                    Experience transformation services to some of India's biggest
                                    companies. Virtuos key customers — MakeMyTrip, Flipkart.com, Myntra,
                                    BookMyShow, etc. featured prominently in the press coverage of Oracle
                                    CloudWorld 2013 Mumbai.
                                </p>
                                <p>
                                    Venky Vijay Reddi, the CEO, participated in the Customer Experience
                                    Panel along with its Clients and Oracle Senior Executives from Oracle.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-oracle-cloud-world-pic.jpg`}
                                        alt="Virtuos Oracle CloudWorld, Mumbai"
                                    />
                                </figure>
                                <br />
                                <p>
                                    <strong>
                                        Venky Vijay Reddi stated in the video about his working relationship
                                        with Oracle.
                                    </strong>
                                </p>
                                <div className="video-wrapper offport-down">
                                    <div className="embed-container">
                                        <iframe
                                            src="https://www.youtube.com/embed/8y1JLjaebKE?autoplay=0&showinfo=0"
                                            frameBorder={0}
                                            allowFullScreen=""
                                        />
                                    </div>
                                    <span>Video Source: Youtube</span>
                                </div>
                                <br />
                                <p>
                                    At Grand Hyatt Mumbai, Oracle Cloud World has received more than 2400
                                    delegates from across the country. Virtuos booth has been managed by
                                    Virtuos Executives showcasing RightNow CX Cloud in action. Key
                                    Executives of Virtuos' Oracle customers — Flipkart, MakeMyTrip, and
                                    Myntra have attended the event.
                                </p>
                                <p>
                                    Venky and the senior leadership has a breakfast meeting with Rajnish
                                    Kapur, Sachin Arora, and Other Oracle Senior Leaders.
                                </p>
                                <h2 className="title">
                                    Key Customer: MakeMyTrip interviewed in the CloudWorld
                                </h2>
                                <div className="video-wrapper offport-down">
                                    <div className="embed-container">
                                        <iframe
                                            src="https://www.youtube.com/embed/XXfz_s1roLg?autoplay=0&showinfo=0"
                                            frameBorder={0}
                                            allowFullScreen=""
                                        />
                                    </div>
                                    <span>Video Source: Youtube</span>
                                </div>
                                <h2 className="title">About Oracle</h2>
                                <p>
                                    Oracle Corporation is an American multinational computer technology
                                    corporation headquartered in Redwood Shores, California.
                                </p>
                                <p>
                                    Oracle has clients in India that include: M, WNS, Standard Chartered
                                    Bank, Siemens, Havells, HelpAge India, M3M India, MacMillan India,
                                    Reliance Capital, Borosil, Aurobindo Pharma, Pride Hotels, Kotak
                                    Securities, Nuthatch Nutricare Technologies, BPTP, Flipkart,
                                    Makemytrip, Cleartrip, BookMyshow, Fundtech, Myntra, Kyazoonga, among
                                    others.
                                </p>
                                <h3 className="title">About Virtuos Consultare</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting, and Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has implemented CRM and Customer Experience Projects to some
                                    of the largest global brands. Our customers include: MakeMyTrip,
                                    Flipkart, Cleartrip, BookMyShow, FundTech, Canon Singapore, National
                                    University of Singapore (NUS), Singapore Institute of Management
                                    (SIM), NZPost, Telstra, Myntra, Standard Chartered Bank,
                                    GE-Healthcare, Sutherland Global, Party Gaming amongst several others.
                                </p>
                                <p>
                                    Virtuos has forayed into high performance Consulting Services
                                    integrating CX and Digital through Consultare division. For more
                                    information visit us at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Co_Sponsors_Oracle_Cloud_Mumbai