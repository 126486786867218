import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Presents_Business_Through_Social_At_Oracle = () => {
    useEffect(() => {
        document.title = "Virtuos presents “Business through Social” at the “Oracle Social CRM Events” in Delhi, Bengaluru and Mumbai – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/oracle-social-crm-event-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 14 December, 2012</span>
                                <h2 className="article-heading">
                                    Virtuos presents “Business through Social” at the “Oracle Social CRM
                                    Events” in Delhi, Bengaluru and Mumbai
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos, the Gold certified Partner of Oracle is the only partner to
                                    showcase its presentation at the Oracle Social CRM Event held on 12th,
                                    13th, and 14th December in Delhi, Bengaluru, and Mumbai. The event was
                                    abuzz with over 50 CXO level attendees from leading organizations and
                                    witnessed significant interest in Oracle Social Relationship
                                    Management (SRM) and Oracle Customer Experience (CX) Solutions. Venky
                                    Vijay Reddi, the CEO presented a 45 minute session followed by Q &amp;
                                    A from the audience on why Social is becoming important for business
                                    and how some of the Virtuos Customers are using Oracle SRM and CX
                                    Solutions to differentiate themselves.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/oracle-virtuos-logo.jpg`}
                                        alt="Oracle, Virtuos"
                                    />
                                </figure>
                                <br />
                                <p>
                                    <strong>
                                        Virtuos broadens Cloud, Mobile, Social and Enterprise Apps with
                                        Virtuos Cloud
                                    </strong>
                                </p>
                                <p>
                                    <img
                                        src={`/img/virtuus-cloud-logo-2012.png`}
                                        style={{ float: "left", width: "auto", marginRight: 30 }}
                                    />
                                    “We are excited about launching Virtuos Cloud as a new avatar for the
                                    flagship business with significant changes to boost our Cloud
                                    Solutions Business.” Said Venky Vijay Reddi, CEO of Virtuos . “We are
                                    launching Virtuos Cloud by broadening our horizons beyond Oracle CX,
                                    KANA CEM, and Maximizer CRM. to enter into areas.”
                                </p>
                                <p>
                                    All the Enterprise Business Sales and Pre-sales teams will now be a
                                    part of Virtuos Cloud across all regions to sharpen our focus on Apps
                                    business.
                                </p>
                                <p>
                                    “We see the market size of cloud-based solutions as very big if tapped
                                    well and about 80 % of the market is lying in the untapped space of
                                    SMB and we want to leverage our deep experience of CRM.” said Venky
                                    Vijay Reddi, the CEO. “Until now our focus has been on the niche
                                    market in the enterprise space, and with Virtuos Cloud we will be
                                    launching a wide range of applications catering to SMB, some of which
                                    might be sold through Channel Partners.”
                                </p>
                                <h3 className="title">About Oracle</h3>
                                <p>
                                    Oracle Corporation is an American multinational computer technology
                                    corporation headquartered in Redwood Shores, California. Oracle has
                                    acquired RightNow Technologies in 2011 to bring Service Automation on
                                    the cloud.
                                </p>
                                <p>
                                    Oracle has clients in India that include: M, WNS, Standard Chartered
                                    Bank, Siemens, Havells, HelpAge India, M3M India, MacMillan India,
                                    Reliance Capital, Borosil, Aurobindo Pharma, Pride Hotels, Kotak
                                    Securities, Nuthatch Nutricare Technologies, BPTP, Flipkart,
                                    Makemytrip, Cleartrip, BookMyshow, Fundtech, Kyazoonga, among others.
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting and Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book –
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses – Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Presents_Business_Through_Social_At_Oracle