import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { scroller } from "react-scroll";

const IoDesignThinking = () => {
  useEffect(() => {
    document.title =
      "Design Thinking View for Digital Transformation – Virtuos Xonomy";
  }, []);
  const scrollToSection = (sectionId) => {
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 1000,
        smooth: true,
        offset: -50, // Adjust this offset if needed
      });
    }, 100); // Delay in milliseconds
  };
  return (
    <div className="dthinking-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/design-thinking-header-img.jpg`}
                alt="Design Thinking"
              />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5 item-wrapper">
                  <h3 className="section-heading">
                    A VIEW OF DESIGN THINKING FOR DIGITAL TRANSFORMATION
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="key-issues" id="what">
            <h2 className="section-main-heading">
              <span className="highlight">Key Issues</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="items-container">
                  <NavLink to="" className="item active smooth">
                    What Is Design Thinking?
                    <span className="number">1</span>
                  </NavLink>
                  <NavLink
                    to={`/io/design-thinking/#how`}
                    className="item"
                    onClick={() => scrollToSection("how")}
                  >
                    How Do You Do Design Thinking?
                    <span className="number">2</span>
                  </NavLink>
                  <NavLink
                    to={`/io/design-thinking/#where`}
                    className="item"
                    onClick={() => scrollToSection("where")}
                  >
                    Where Can You Begin?
                    <span className="number">3</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="premise-container light-blue-bg">
            <h2 className="section-main-heading">
              Design Thinking{" "}
              <span className="highlight">Has a Simple Premise</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-3 item">If I focus on people</div>
              <div className="col-1">
                <span className="animate-arrows-horizontal animated-arrows">
                  <i className="fal fa-angle-right arrow" />
                  <i className="fal fa-angle-right arrow" />
                  <i className="fal fa-angle-right arrow" />
                </span>
              </div>
              <div className="col-3 item">Observe them in context</div>
              <div className="col-1">
                <span className="animate-arrows-horizontal animated-arrows">
                  <i className="fal fa-angle-right arrow" />
                  <i className="fal fa-angle-right arrow" />
                  <i className="fal fa-angle-right arrow" />
                </span>
              </div>
              <div className="col-3 item">Can find what they need</div>
            </div>
            <div className="row page-wrap content">
              <div className="col-5">
                <ul>
                  <li>
                    <strong>Behaviors</strong> — what are they doing?
                  </li>
                  <li>
                    <strong>Motivation</strong> — why are they doing it?
                  </li>
                  <li>
                    <strong>Outcomes</strong> — what are they trying to achieve?
                  </li>
                  <li>
                    <strong>Ecosystem</strong> — what is the network of
                    relationships?
                  </li>
                </ul>
              </div>
              <div className="col-5">
                <figure>
                  <img src={`/img/svg/expertise-resource-img_1.svg`} alt="" />
                </figure>
              </div>
            </div>
          </section>
          <section className="analysis-container">
            <h2 className="section-main-heading">
              Design Thinking Combines{" "}
              <span className="highlight">Analysis and Intuition</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img src={`/img/svg/analysis-intuition-diagram.svg`} alt="" />
                </figure>
              </div>
            </div>
          </section>
          <section className="breaker-container">
            <figure className="bg-banner">
              <img src={`/img/design-thinking-breaker-bg.jpg`} alt="" />
            </figure>
            <div className="row page-wrap">
              <div className="col-7">
                <p>
                  "Design thinking is a human centered and collaborative
                  approach to problem solving, using a designed mindset to solve
                  complex problems."
                </p>{" "}
                <span className="author">
                  <strong>Tim Brown</strong> <br />
                  (British Industrial Designer and President of Ideo)
                </span>
              </div>
            </div>
          </section>
          <section className="compare-container">
            <h2 className="section-main-heading">
              Traditional vs. <span className="highlight">Design Thinking</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-5 traditional">
                <ul>
                  <li>TRADITIONAL THINKING</li>
                  <li>Flawless planning</li>
                  <li>Avoid failure</li>
                  <li>Rigorous analysis</li>
                  <li>Presentations</li>
                  <li>Arm's length customer research</li>
                  <li>Periodic</li>
                  <li>Thinking</li>
                </ul>
              </div>
              <div className="col-5 dthinking">
                <ul>
                  <li>DESIGN THINKING</li>
                  <li>Enlightened trial and error</li>
                  <li>Fail fast</li>
                  <li>Rigorous testing</li>
                  <li>Lightweight experiments</li>
                  <li>Deep customer immersion</li>
                  <li>Continuous</li>
                  <li>Doing</li>
                </ul>
              </div>
            </div>
          </section>
          <section className="key-issues" id="how">
            <h2 className="section-main-heading">
              <span className="highlight">Key Issues</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="items-container">
                  <NavLink
                    to={`/io/design-thinking/#what`}
                    className="item"
                    onClick={() => scrollToSection("what")}
                  >
                    What Is Design Thinking?
                    <span className="number">1</span>
                  </NavLink>
                  <NavLink to="" className="item active smooth">
                    How Do You Do Design Thinking?
                    <span className="number">2</span>
                  </NavLink>
                  <NavLink
                    to={`/io/design-thinking/#where`}
                    className="item"
                    onClick={() => scrollToSection("where")}
                  >
                    Where Can You Begin?
                    <span className="number">3</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="stages-container grey-bg">
            <h2 className="section-main-heading">
              Design Thinking Has <span className="highlight">Five Stages</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/design-thinking-five-stages-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="integrates-container">
            <h2 className="section-main-heading">
              Design Thinking Integrates{" "}
              <span className="highlight">
                People,
                <br />
                Technology and Business
              </span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/design-thinking-integrates-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="mindset-container light-blue-bg">
            <h2 className="section-main-heading">
              Design Thinking Is Based on{" "}
              <span className="highlight">Six Mindsets</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-4 item">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/svg/icons/empathy-icon.svg`}
                      alt="Design and Develop Solutions"
                    />
                  </figure>
                </span>
                <h3>Empathy</h3>
                <p>
                  Engineering must have empathy (EQ) to design and develop
                  solutions that customers love.
                </p>
              </div>
              <div className="col-4 item">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/svg/icons/show-don't-tell-icon.svg`}
                      alt="Improve Customer Experience"
                    />
                  </figure>
                </span>
                <h3>Show, Don’t tell</h3>
                <p>
                  Bring ideas to life by showing the big picture. Don’t tell
                  customers what you will do, instead, show.
                </p>
              </div>
              <div className="col-4 item">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/svg/icons/experiment-icon.svg`}
                      alt="Preparation for Success"
                    />
                  </figure>
                </span>
                <h3>Experiment</h3>
                <p>
                  Iteration is nothing but preparation for success. Create
                  prototypes to gauge peoples’ reactions.
                </p>
              </div>
              <div className="col-4 item">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/svg/icons/mindfulness-icon.svg`}
                      alt="Voice of Customer"
                    />
                  </figure>
                </span>
                <h3>Mindfulness</h3>
                <p>
                  Pay attention to surroundings, and develop attention span by
                  having the time and space.
                </p>
              </div>
              <div className="col-4 item">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/svg/icons/action-oriented-icon.svg`}
                      alt="Action Oriented"
                    />
                  </figure>
                </span>
                <h3>Action-Oriented</h3>
                <p>
                  Agility is integral to design. There’s a need to reach and
                  interact with customers in more ways than ever.
                </p>
              </div>
              <div className="col-4 item">
                <span className="icon">
                  <figure>
                    <img
                      src={`/img/svg/icons/collaboration-icon.svg`}
                      alt="Collaboration"
                    />
                  </figure>
                </span>
                <h3>Collaboration</h3>
                <p>
                  Happiests Team culture with elements such as communication,
                  support, problem solving and sharing.
                </p>
              </div>
            </div>
          </section>
          <section className="doctor-technology">
            <h2 className="section-main-heading">
              Doctors Wanted to Use the{" "}
              <span className="highlight">Latest Technology</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-5">
                <h2 style={{ color: "#3a3939c9", fontWeight: "bold" }}>
                  Initial ideas:
                </h2>
                <ul>
                  <li>Review observations and patient condition</li>
                  <li>Order additional tests and treatments</li>
                  <li>Adjust medications</li>
                  <li>Be able to do all this anywhere, anytime</li>
                </ul>
              </div>
              <div className="col-5">
                <figure>
                  <img
                    src={`/img/svg/doctor-latest-technology-img.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="doctor-day">
            <h2 className="section-main-heading">
              Observed a <span className="highlight">Doctor’s Typical Day</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-4">
                <h3>Patient Visits</h3>
                <figure>
                  <img src={`/img/svg/patient-visit-diagram.svg`} alt="" />
                </figure>
              </div>
              <div className="col-4">
                <h3>Surgery</h3>
                <figure>
                  <img src={`/img/svg/surgery-diagram.svg`} alt="" />
                </figure>
              </div>
              <div className="col-4">
                <h3>Clinics</h3>
                <figure>
                  <img src={`/img/svg/clinics-diagram.svg`} alt="" />
                </figure>
              </div>
            </div>
          </section>
          <section className="cx-map-container">
            <h2 className="section-main-heading">
              Created the{" "}
              <span className="highlight">Customer Experience Map</span>
            </h2>
            <div className="row">
              <div className="col-12">
                <div className="headers">
                  {/* <ul>
                    <li>Review Notes</li>
                    <li>Discuss Case</li>
                    <li>Adjust Treatment</li>
                    <li>Observe Outcomes</li>
                    <li>Readjust</li>
                  </ul> */}
                </div>
                <figure>
                  <img src={`/img/svg/doctor-cx-map-diagram.svg`} alt="" />
                </figure>
              </div>
            </div>
          </section>
          <section className="ecosystem-container">
            <h2 className="section-main-heading">
              Mapped the{" "}
              <span className="highlight">Patient-Centric Ecosystem</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/patient-centric-ecosystem-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="develop-container grey-bg">
            <h2 className="section-main-heading">
              Developed an <span className="highlight">Initial Prototype</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-5">
                <ul>
                  <li>Robust multifactor security</li>
                  <li>
                    Basic functionality — observations, messaging, tests,
                    treatment plans
                  </li>
                  <li>Easy-to-use, simple interfaces</li>
                  <li>Release to a small group of pilot doctors</li>
                </ul>
              </div>
              <div className="col-5">
                <figure>
                  <img src={`/img/develop-prototype-img.jpg`} alt="" />
                </figure>
              </div>
            </div>
          </section>
          <section className="iterate-container">
            <h2 className="section-main-heading">
              <span className="highlight">Iterate</span> to Learn, Evolve and
              Test the Design
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img src={`/img/svg/ux-processes.svg`} alt="" />
                </figure>
              </div>
            </div>
          </section>
          <section className="key-issues" id="where">
            <h2 className="section-main-heading">
              <span className="highlight">Key Issues</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <div className="items-container">
                  <NavLink
                    to={`/io/design-thinking/#what`}
                    className="item"
                    onClick={() => scrollToSection("what")}
                  >
                    What Is Design Thinking?
                    <span className="number">1</span>
                  </NavLink>
                  <NavLink
                    to={`/io/design-thinking/#how`}
                    className="item"
                    onClick={() => scrollToSection("how")}
                  >
                    How Do You Do Design Thinking?
                    <span className="number">2</span>
                  </NavLink>
                  <NavLink to="" className="item active smooth">
                    Where Can You Begin?
                    <span className="number">3</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="build-container light-blue-bg">
            <h2 className="section-main-heading">
              Build a Collaborative{" "}
              <span className="highlight">Cross-Functional Team</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/cross-functional-team-diagram.svg`}
                    alt="Cross Functional Team Diagram"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="start-grow-container">
            <h2 className="section-main-heading">
              Start Small and <span className="highlight">Grow</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/start-grow-tree-diagram.svg`}
                    alt="Start Small and Grow Diagram"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="breaker-container">
            <figure className="bg-banner">
              <img src={`/img/design-thinking-breaker-bg2.jpg`} alt="" />
            </figure>
            <div className="row page-wrap">
              <div className="col-7">
                <p>
                  "There's no shortage of remarkable ideas what's missing is the
                  will to execute them."
                </p>
                <span className="author">
                  <strong>Seth Godin</strong> <br />
                  (Author and Entrepreneur)
                </span>
              </div>
            </div>
          </section>
          <section className="recommendation">
            <h2 className="section-main-heading">Recommendations</h2>
            <div className="row page-wrap">
              <div className="col-12">
                <ul>
                  <li>
                    Do not start by looking at the IT systems. Identify customer
                    journey moments delivering the highest value for customers
                    and your business. <span>1</span>
                  </li>
                  <li>
                    Treat customer engagement hub as a multiyear technology and
                    process initiative.
                    <span>2</span>
                  </li>
                  <li>
                    Find executive sponsorship and owner of the project and
                    break organization barriers.
                    <span>3</span>
                  </li>
                  <li>
                    Question, assess and then match the speed of response to the
                    specific requirements of the business activity by
                    determining the right time for every decision.{" "}
                    <span>4</span>
                  </li>
                  <li>
                    Acquire or develop the skills necessary to implement
                    real-time continuous intelligence.
                    <span>5</span>
                  </li>
                  <li>
                    Prepare for extensive system integration of products from
                    multiple vendors to achieve business value. <span>6</span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          {/*?php include '../../footer-xonomy.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default IoDesignThinking;
