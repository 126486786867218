import React, { useEffect, useState } from 'react'

import { NavLink } from "react-router-dom";
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import Nav from '../../../components/Nav';
import ApplyForJob from './Forms/ApplyForJob';
import CommonApplyForJob from './CommonApplyForJob';
import { NavigateNext } from '@mui/icons-material';

const SolutionConsultant = () => {
	useEffect(() => {
		document.title = "Career Opportunities | Microsoft .Net Developer – Virtuos Jobs";
	}, []);
	return (
		<div className="careers-job-detail-page landing-page">
			<div className="canvas">
				<Navbar />
				<main className="main-wrapper">
					{/* Careers Job Detail */}
					<section className="job-detail-wrapper">
						<div className="row page-wrap">
							<div class="col-9 job-details">
								<div class="job-header">
									<div class="profile-illustration">
										<figure>
											<img src={`/img/svg/crm-solution-consultant-career-img.svg`} alt="CRM/Rightnow Solution Consultant Job" />
										</figure>
									</div>
									<div class="profile-detail">
										<h2 class="section-heading">CRM/RightNow Solution Consultant</h2>
										<ul class="job-attributes">
											<li class="">
												<strong>Location:</strong>
												<span>Gurgaon</span>
											</li>
											<li class="">
												<strong>Experience:</strong>
												<span>2-7 Years</span>
											</li>
											<li class="">
												<strong>No. of Positions:</strong>
												<span>1</span>
											</li>
											<li class="">
												<strong>Qualification:</strong>
												<span>BE/BTech/ME/MTech/MS/MCA</span>
											</li>
										</ul>
									</div>
								</div>
								<p>This role requires experience of successfully delivering CRM projects in large corporate environments and requires someone who understands how business works and how CRM solutions can support the business.</p>
								<h3 class="title">Roles &amp; Responsibilities</h3>
								<p>The role is customer-facing, and at the same time a cross-functional interaction to deliver the the project successfully, so ideally the candidate should have strong experience of the successful delivery of Oracle Service Cloud in functional/technical roles.</p>
								<p>In this role your responsibilities will include:</p>
								<ul class="list">
									<li>Assist with pre-sales demo preparation of Oracle Service Cloud applications</li>
									<li>Assist with solution definition, effort estimation, and project planning</li>
									<li>Provide expertise on Oracle Service Cloud product features, configuration, and customisations</li>
									<li>Design, configure, support testing and production migration and deployment of Oracle Service Cloud solutions</li>
									<li>Support the application upgrades, enhancements, and fixes of our clients instances as part of Managed Services</li>
									<li>Learn and gain skills with new products and features as they emerge to facilitate and champion innovation via pre-configured industry solutions</li>
								</ul>

								<h3 class="title">Skill set and Qualification</h3>
								<p><strong>Qualification:</strong></p>
								<ul class="list">
									<li>B.E/ B. Tech. preferably with Computer Science or Related discipline from renowned institutes/colleges</li>
								</ul>
								<p><strong>Experience:</strong></p>
								<ul class="list">
									<p>Must Have:</p>
									<li>2 years to 7 years of experience in Oracle CX Practice, preferably Service Cloud</li>
								</ul>
								<ul class="list">
									<p>Good to Have:</p>
									<li>2+ years of experience in other Oracle CX Practices like Sales Cloud, Marketing Cloud, and Social Cloud</li>
								</ul>
								<p><strong>Soft Skills:</strong></p>
								<ul class="list">
									<li>Experience in working within a client facing environment</li>
									<li>Exceptional presentation and communication skills</li>
									<li>Should be an analytical thinker and ability to pay attention to details</li>
								</ul>
								<p><strong>Technical/Functional Skills:</strong></p>
								<ul class="list">
									<p>Preferably having one of the following  certification with Oracle Service Cloud:</p>
									<li>Oracle RightNow Cloud Service 2016 Technical Implementation Essentials 1Z0-326</li>
									<li>Oracle RightNow Cloud Service 2016 Implementation Essentials 1Z0-325</li>
								</ul>
								<p><strong>Fundamentals:</strong></p>
								<ul class="list">
									<li>Oracle Service Cloud application overview</li>
									<li>Navigation (panes, ribbons, explorers, and help)</li>
								</ul>
								<p><strong>Initial Setup:</strong></p>
								<ul class="list">
									<li>Navigation sets, profiles, permissions, and queues</li>
									<li>Staff accounts, groups, and passwords</li>
									<li>Customizable menus and values</li>
								</ul>
								<p><strong>Core Functionality:</strong></p>
								<ul class="list">
									<li>Business rules (states, functions, variables, conditions, expressions, and actions)</li>
									<li>Workspaces (displaying and editing records)</li>
									<li>Workspace rules and triggers (dynamically adjust display, behavior, and values)</li>
									<li>Custom objects, and relationships (packages and deployment)</li>
									<li>Creating and monitoring answers and the knowledge base</li>
									<li>Service level agreements (SLAs), response times, and holidays</li>
								</ul>
								<p><strong>Agent assistance:</strong></p>
								<ul class="list">
									<li>Guided assistance (questions, answers, responses, and branching)</li>
									<li>Agent scripts (pages, fields, controls, questions, branching, rules, and events)</li>
									<li>Workflow processes and templates (user interface, automation, search, and decision elements)</li>
								</ul>
								<p><strong>Interactions:</strong></p>
								<ul class="list">
									<li>Messages, templates, notifications, emails, and tracking</li>
									<li>Surveys and results</li>
									<li>Chats, routing, skills, and advanced routing</li>
									<li>Mailboxes (outgoing and incoming), email addresses, and distribution lists</li>
								</ul>
								<p><strong>Technical Skills:</strong></p>
								<ul class="list">
									<p>All of the above and strong coding skills in one of the areas such as .Net, PHP, Java and Bootstrap Technologies. People familiar with Angular JS will be preferred. Having experience in the following will be preferred:</p>
									<li>Add-ins (.Net for Classic/advanced Agent Console, Java script for BUI)</li>
									<li>Custom Script and Custom Processes (Event Handlers)</li>
									<li>Customer Portal Development</li>
									<li>Experience integrating with REST APIs</li>
								</ul>
								<h3 class="title">What we are looking for</h3>
								<p>We are looking out for Oracle Service Cloud Consultant, who will be working closely with our Project/Delivery Manager, pre-sales team, and consulting team to support pre-sales, design, configure, implement, and support the delivery of the Oracle Service Cloud Solution to our clients.</p>
								<p>We are looking for truly Virtuoso teams who can bring aggrandized value to our business offerings and help our customers succeed with superior customer experience edge.</p>
								<p>At Virtuos, we are looking for innovative minds and committed talent with highest track records of trust, possessing digital dexterity standards to deliver ExperienceJob - Not an ordinary job to our customers.</p>
								<h3 class="title">What we offer</h3>
								<p>We not only provide you a job opportunity but a long term career to help you to develop both personally and professionally. We are a launchpad for people with skill set and qualification.</p>
								<ul class="list">
									<li>Work-life balance</li>
									<li>Professional development</li>
									<li>Happiest culture</li>
									<li>Skill training and learning opportunities</li>
									<li>Time off for vacations</li>
									<li>Rewards and recognition</li>
									<li>Lot of fun and employee engagement activities</li>
								</ul>
							</div>
							<div className="col-3 job-application-wrapper sticky-content">
								<CommonApplyForJob />
							</div>
						</div>
					</section>

					{/*?php include '../../footer.php';?*/}
					<Footer />
				</main>

			</div>
			<div className="off-canvas">
				<Nav />
			</div>
		</div>
	)
}

export default SolutionConsultant
