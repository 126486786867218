import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_Vlocis_Implements_CLM = () => {
  useEffect(() => {
    document.title = "Virtuos Vivacis implements CLM for new clients";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/vivacis-implements-CLM-img-big.jpg`}
                  alt="Virtuos Vivacis implements CLM for new clients"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurugram, 6 Nov 2019</span>
                <h2 className="article-heading">
                  Virtuos Vivacis implements CLM for new clients
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Vivacis is a new division kicked off last quarter to enter
                  into the burgeoning space of Digital Process Automation (DPA),
                  Quote to Cash, Configure Price Quote (CPQ) Automation,
                  Robotics Process Automation and Contract Lifecycle Management
                  (CLM). Collectively these five businesses are placed under the
                  umbrella of Virtuos Vivacis, part of our O.Digital Business
                  Group.
                </p>
                <p>
                  Virtuos has forged alliances with world leaders such as
                  Creatio (Formerly BPM’Online), Automation Anywhere, UIPath,
                  Oracle, Agiloft, amongst others.
                </p>
                <p>
                  {" "}
                  Virtuos has signed several new clients in the last few months
                  for CLM and DPA. A leading Accounts Receivable (AR)
                  Collections company has implemented Creatio DPA for
                  transforming the processes end to end with its customers and
                  principals.
                </p>
                <p>
                  A Leading Bank (Part of Largest Private Bank in India) for its
                  Life Insurance Business in UAE has embarked on Digital Process
                  Automation (DPA) by implementing Creatio Business Suite.
                  India’s leading Apparel and fashion retailer working with
                  thousands of artisans and supplying apparel and fashion
                  accessories worldwide implemented CLM from Agiloft. It’s a
                  great moment to have this customer using comprehensive CLM.
                  One of the best alternatives to WhatsApp in India, part of
                  leading Telecom Company’s venture, implemented CLM by Agiloft
                  for transforming Contract Management.
                </p>
                <p>
                  {" "}
                  A leading Multinational Security Agency with worldwide
                  presence has implemented CLM by Agiloft to transform its
                  processes by keeping an eye on their contracts, terms, and
                  policies.
                </p>
                <p>
                  Vivacis has an ambitious plan to become a critical business
                  unit within O.Digital Group in the next one year with a growth
                  trajectory like this.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. (Formerly Virtuos Solutions Private Ltd.)
                  is a leading Customer Experience (CX) &amp; Digital
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business3`} className="link">
                    Business 3.0/4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/xonomy/ai`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Newly created Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/cxdesk`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation Open lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="https://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Vlocis_Implements_CLM;
