import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import svglogo from "./images/salesforce+sweven-logo.svg";
import svgimg from "./images/salesforce-sweven-img.svg";

const Salesforce = () => {
  useEffect(() => {
    document.title = "Virtuos | High performance Digital Transformation Consulting Services";
  }, []);
  const handleClick = () => {
    window.location.href = "http://www.sweven.com";
  };
  return (
    <div className="main-area-wrapper">
      <div className="main-area center-text">
        <div className="header">
          <div className="right">
            <figure>
              <img src={svglogo} alt="Saleforce | Sweven logo" />
            </figure>
            <h2>
              Virtuos is no longer into Salesforce Consulting Practice. SWEVEN
              is our associate, and you can visit
              <NavLink
                to="http://www.sweven.com"
                target="_blank"
                className="linkdeco"
              >
                www.sweven.com
              </NavLink>{" "}
              to learn more about Salesforce Consulting &amp; Implementation
              Services.
            </h2>
            <button type="button" onClick={handleClick}>
              Visit Sweven.com
            </button>
          </div>
          <div className="main-img">
            <img
              src={svgimg}
              alt="Saleforce | Sweven"
              title="Saleforce | Sweven"
            />
          </div>
        </div>
        {/* display-table-cell */}
      </div>
      {/* main-area */}
    </div>
  );
};

export default Salesforce;
