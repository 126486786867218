import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Bags_Orders_For_Kana_And_Maximizer = () => {
    useEffect(() => {
        document.title = "Virtuos bags additional orders from the existing Customers for Kana Software and Maximizer CRM business – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-bags-orders-for-kana-maximizer-crm-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">New Delhi, 01 July 2008</span>
                                <h2 className="article-heading">
                                    Virtuos bags additional orders from the existing Customers for Kana
                                    Software and Maximizer CRM business
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos has been growing with the portfolio of CRM and Customer
                                    Experience/Customer Service Solutions from leading Players — Kana,
                                    Maximizer, and RightNow. The company has further expanded the
                                    footprint in Sutherland Global by implementing yet another Solution
                                    for the customers’ Philippines BPO Center. Virtuos has forayed into
                                    implementing its first overseas project remotely by delivering the
                                    state of the art implementation services and training.
                                </p>
                                <p>
                                    "Using the train-the-trainer, we have been able to train hundreds of
                                    Agents who are working on multiple customer projects." Said Amarinder
                                    Singh, Solution Lead Engineer Kana Software Practice at Virtuos. "We
                                    also have Remote Infrastructure Services by fully deploying KANA at
                                    the client-side with real-time backup and security software".
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/virtuos-sutherland-kana-logo.jpg`}
                                        alt="Virtuos, Sutherland, Kana"
                                    />
                                </figure>
                                <p>
                                    Virtuos has already implemented the excellent Customer Service and CRM
                                    Projects at some of the leading organizations in India, including
                                    Standard Chartered Bank, GE Healthcare, and Sutherland Global Chennai.
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based out
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book — Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three important needs
                                    of growing businesses — Sales, Customer Service and Marketing. Virtuos
                                    has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    )
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">18/01/2008</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            Tekcorp Office Building, <br />F Block Okhla Industrial Area -
                                            Phase 1, <br />
                                            New Delhi 110020 India
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Branch</span>
                                        <span className="txt">Hyderabad</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                                www.virtuos.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">10-20</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Bags_Orders_For_Kana_And_Maximizer