import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Implements_Kana_Solution = () => {
    useEffect(() => {
        document.title = "Virtuos implements KANA 'Enterprise Customer Experience' Management Solution at Standard Chartered Bank – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-implements-kana-solution-at-standard-chartered-bank-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Singapore - 13 January, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos implements KANA CX SUITE at Standard Chartered Bank APAC
                                    Locations
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos implements KANA Response Email Management System for Standard
                                    Chartered Bank Malaysia and in other parts of APAC including
                                    Singapore. Using both offshore and Onshore project delivery, Virtuos
                                    delivered a robust Email Management Solution and powerful analytics to
                                    help Standard Chartered Bank manage its customer service in the APAC
                                    region. The Solution also effectively handles Email Channel while
                                    Providing 360 degree view of customer interactions to the Customer
                                    Service Agents.
                                </p>
                                <p>
                                    “Standard Chartered Bank (sc.com) India has recommended its APAC
                                    headquarters to implement KANA Software through Virtuos and it’s a
                                    major testimony of our exemplary services.” Said Venky Vijay Reddi,
                                    CEO of Virtuos Consultare. “Standard Chartered Bank has received the
                                    highest Return On Investment (ROI) using KANA Platform by providing a
                                    great customer experience.”
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/standard-chartered-virtuos-logo.jpg`}
                                        alt="Standard Chartered Bank, Virtuos"
                                    />
                                </figure>
                                <h2 className="title">About Standard Chartered Bank</h2>
                                <p>
                                    Standard Chartered Singapore is the Singapore based subsidiary of
                                    British banking and financial services company, Standard Chartered.
                                    Opening its first branch in 1859, the bank is the oldest in its
                                    continuous operation in Singapore.
                                </p>
                                <p>
                                    For more information visit{" "}
                                    <NavLink to="http://www.sc.com" target="_blank" className="link">
                                        www.sc.com
                                    </NavLink>
                                </p>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting and Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has implemented CRM and Customer Experience Projects to some
                                    of the largest global brands. Our customers include: MakeMyTrip,
                                    Flipkart, Cleartrip, BookMyShow, FundTech, Canon Singapore, National
                                    University of Singapore (NUS), Singapore Institute of Management
                                    (SIM), NZPost, Telstra, Standard Chartered Bank, GE-Healthcare,
                                    Sutherland Global, Party Gaming amongst several others.
                                </p>
                                <p>
                                    Virtuos has forayed into high performance Consulting Services
                                    integrating CX and Digital through Consultare division. For more
                                    information visit us at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Implements_Kana_Solution