import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Signs_Two_New_Deals = () => {
    useEffect(() => {
        document.title = "Virtuos signs two new deals from Party Gaming and Cashtech – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-signs-two-new-deals-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 31 March, 2010</span>
                                <h2 className="article-heading">
                                    Virtuos signs two new deals from Party Gaming and Cashtech
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos has completed its 2nd Financial Year with an impressive growth
                                    trajectory with a series of significant deals from Party Gaming, the
                                    Global Gaming Software Giant through its Indian subsidiary Ivy
                                    Comptech, Hyderabad, and CashTech, an Indian subsidiary of Fundtech, a
                                    global giant in Transaction Banking Software.
                                </p>
                                <p>
                                    Party Gaming (Ivy Comptech) and Virtuos will work over the next two to
                                    three months in empowering PartyGaming’s customers with state of the
                                    art Contact Center Solutions ranging from Email Response, Integration
                                    with Gaming App and Knowledge Management &amp; Web Self-Service. KANA
                                    Suite of Applications will be deployed at the Party Gaming’s Indian
                                    Subsidiary at Hyderabad by expanding the scope to Gibraltar, South
                                    Spain.
                                </p>
                                <p>
                                    “In line with Virtuos “
                                    <NavLink
                                        to="http://www.customerondemand.com/"
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer OnDemand
                                    </NavLink>
                                    ” strategy, It has always been a priority to think outside-in and
                                    create dynamic customer journeys; optimize channels while deflecting
                                    the expensive channels like voice.” Said Venky Vijay Reddi, CEO of
                                    Virtuos. The legacy technology stack using IVR, Lengthy Voice calls,
                                    and Static FAQs have become obsolete and old fashioned.”
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/ivy-comptect-partygaming-kana-virtuos-logos.jpg`}
                                        alt="IVY Comptect, Party Gaming, Kana, Virtuos Solutions"
                                    />
                                </figure>
                                <p>
                                    Virtuos sees increased adoption of Knowledge Management from the KANA
                                    iQ Platform as we enter the next phase of the Internet revolution,
                                    where customers use the Mobile First approach to engage with the
                                    brands.
                                </p>
                                <p>
                                    Virtuos and Cashtech, a leading Transactional Banking Software Company
                                    (In the B2B space) and a subsidiary of Fundtech USA, will be working
                                    on building a comprehensive engagement engine for dozens of their
                                    Customer Service and Sales Agents. For the first time in India,
                                    RightNow CRM Cloud Application will be used to solve many
                                    complexities. It will extend its powerful APIs and Customer Portal
                                    Technology to offer a B2B2C flow of engagement.{" "}
                                </p>
                                <p>
                                    “It was an ecstatic moment when I and YS Lee met the client in Pune to
                                    discover the scope and functionality requirements. Said Venky Vijay
                                    Reddi, CEO of Virtuos. The customer has quite big expectations to use
                                    the applications internally for their employees and extend the
                                    engagement channels to the end-users of their B2B clients.”
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/cashtech-fundtech-rightnow-virtuos-logos.jpg`}
                                        alt="Cashtech, Fundtech, RightNow Technologies, Virtuos Solutions"
                                    />
                                </figure>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based in
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book – Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three essential needs
                                    of growing businesses — Sales, Customer Service, and Marketing.
                                    Virtuos has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has been fiercely growing in the space of CRM, Service Cloud
                                    and Cloud Computing space by delivering the cutting edge solutions to
                                    leading brands like MakeMyTrip, GE-Healthcare, Standard Chartered
                                    Bank, Party Gaming, Fundtech amongst other companies. For more
                                    information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Signs_Two_New_Deals