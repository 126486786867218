import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Verint_Sign_Partnership = () => {
    useEffect(() => {
        document.title = "Virtuos and Verint Sign Partnership To Bring CX Ecosystems to Key Enterprise Customers – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-verint-sign-partnership-to-bring-cx-ecosystems-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 21 March, 2017</span>
                                <h2 className="article-heading">
                                    Virtuos and Verint sign partnership to bring CX Ecosystems to key
                                    Enterprise customers
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Verint has taken full control of Brand KANA and its assets to run it
                                    as part of its Customer Engagement Practice using its "Actionable
                                    Intelligence" edge.{" "}
                                </p>
                                <p>
                                    Venky Vijay Reddi, CEO of Virtuos with his team and Ady Meretz, the
                                    President of Verint Australia with his India leadership, met recently
                                    to discuss the strategy of taking Verint to some of the key customers
                                    in India.
                                </p>
                                <p>
                                    Verint is the only company that offers a complete spectrum of Customer
                                    Experience Components. Said Venky Vijay Reddi. Verint has powerful
                                    marketing leading US$1 Billion business of Voice of Customer
                                    Analytics, Speech Analytics, Workforce Engagement, and ability to
                                    manage structured and unstructured customer data covering dozens of
                                    local languages, including Hindi, Telugu, Bengali, etc.
                                </p>
                                <p>
                                    Amarinder Singh, the Director CX Practice recently was in Singapore
                                    for his Training on Verint's new CRM Products.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/verint-virtuos-logo.jpg`}
                                        alt="Verint, Virtuos"
                                    />
                                </figure>
                                <br />
                                <p>
                                    Virtuos will position Verint's Customer Engagement &amp; Experience
                                    Suite, Contact Center Technologies, Voice of Customer (VOC), and
                                    Workforce Engagement to some of the large customers. Virtuos is also
                                    getting equipped to offer holistic technologies such as Workforce
                                    Engagement, VOC Technologies as part of "CXNow."
                                </p>
                                <h2 className="title">About Verint</h2>
                                <p>
                                    Verint Systems is a Melville, New York-based Customer Engagement
                                    company which was founded in 2002. The company sells software and
                                    hardware products for customer engagement management, security,
                                    surveillance, and business intelligence. Their products are designed
                                    to assist clients in data analysis, specifically large data sets.
                                </p>
                                <p>
                                    Gartner also named Verint as the market leader in its latest{" "}
                                    <NavLink
                                        to="https://www.verint.com/press-room/2017-press-releases/verint-named-a-leader-in-gartners-new-magic-quadrant-for-workforce-engagement-management/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        Magic Quadrant
                                    </NavLink>
                                    .
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media, and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Verint_Sign_Partnership