import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

const Hike_Case_Study = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };

    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Virtuos Customer Success Stories | Hike CLM case study – Virtuos Digital";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/hike-header-img.jpg`}
                                    alt="Hike"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/hike-logo.png`}
                                        alt="Hike Logo"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        Hike works with Virtuos to implement Agiloft CLM for automating
                                        Contracts
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <section className="breadcrumb-kanaban">
                            <div className="page-wrap breadcrumb-wrapper">
                                <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                                    <li>
                                        <NavLink to={`/corporate/`}>About Virtuos</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/customers/`} className="active">
                                            Customer Success
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={`/customers#success-stories`}
                                            className="smooth"
                                        >
                                            CUSTOMER SUCCESS STORIES
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/succeedant/`} className="smooth">
                                            SUCCEEDANT CSM
                                        </NavLink>
                                    </li>

                                </ul>
                                <ul className="more-hidden-tabs">
                                    <li
                                        className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <i className="fal fa-plus" />  More
                                        {showExtraLinks && (
                                            <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                                                <li>
                                                    <NavLink to={`/corporate/experience-edge/`}>
                                                        EXPERIENCE EDGE
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`/insights/`}>
                                                        INSIGHTS AND RESOURCES
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                <h3 className="title">About Hike Messenger</h3>
                                <p>
                                    Hike Messenger, also called Hike Sticker Chat, is an Indian freeware,
                                    cross-platform instant messaging, Voice over IP application which was
                                    launched on 12 December 2012 by Kavin Bharti Mittal and is now owned
                                    by Hike Private Limited.
                                </p>
                                <h3 className="title">Challenges</h3>
                                <ul>
                                    <li>Lack of visibility of Contract details with the management</li>
                                    <li>Unable to track the expiry of contract in advance</li>
                                    <li>
                                        Legal team unable to get information on the contracts that are
                                        pending for approvals/clearances
                                    </li>
                                    <li>Maintenance of contracts in physical format</li>
                                    <li>Unable to track changes in a contract in form of amendments</li>
                                </ul>
                                <h3 className="title">Need</h3>
                                <ul>
                                    <li>
                                        Legal team wanted to measure the performance of their team and
                                        understand the number of contracts that are coming in, the team
                                        working on them individually and the status of each contract
                                    </li>
                                    <li>
                                        Hike wanted a single platform on which all the interactions related
                                        to the contract is captured across the teams
                                    </li>
                                    <li>
                                        Hike wanted to capture all of their contracts from the ecosystem
                                        partners with timely action points to these contracts
                                    </li>
                                    <li>
                                        Hike being a digital era company wanted to eliminate manual
                                        processes bringing enterprise wide automation
                                    </li>
                                    <li>
                                        Hike wanted to schedule reports of various teams performance with
                                        regards to their approvals, active contracts and various other
                                        parameters
                                    </li>
                                </ul>
                                <h3 className="title">Win</h3>
                                <ul>
                                    <li>
                                        Hike chose Agiloft Contract Management solution after a lot of
                                        deliberations with the team and also evaluating Agiloft’s
                                        competitors
                                    </li>
                                    <li>
                                        Agiloft was chosen due to its simple interface and wide range of
                                        features for managing diverse contracts
                                    </li>
                                    <li>
                                        Agiloft’s UI and functionality offered intuitive design for citizen
                                        development reducing the dependency on the programmers
                                    </li>
                                </ul>
                                <h3 className="title">Partner</h3>
                                <ul>
                                    <li>
                                        Virtuos is a Gold Partner for Agiloft, has rich experience in
                                        implementing complex solutions and our team has delivered CRM,
                                        Customer Experience (CX) Solutions to leading brands.
                                    </li>
                                    <li>
                                        Virtuos comes with Industry best practices and state of the art
                                        digital design skills meeting the expectations of the customer
                                    </li>
                                    <li>
                                        Virtuos presented a balanced and rapid implementation cycle using
                                        Vlocis pre-built templates and best practices
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="customer-success-section">
                        <h2 className="section-main-heading">
                            Other <span className="highlight">Case Studies</span>
                        </h2>
                        <div className="clients-wrapper">
                            <ul className="row page-wrap offport-down">
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/standard-chartered-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/standard-chartered-casestudy-img.jpg`}
                                                alt="Standard Chartered Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/standard_chartered.svg`}
                                                alt="Standard Chartered Bank Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">CX and Contact Center Automation</p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/tvs-credit-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/tvsc-casestudy-img.jpg`}
                                                alt="TVS Credit Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/tvscs-logo.png`}
                                                alt="TVS Credit"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer Experience Transformation Project using Oracle platform
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/makemytrip-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/makemytrip-casestudy-img.jpg`}
                                                alt="Make My Trip Casestudy"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/make-my-trip-logo.svg`}
                                                alt="Make My Trip Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            MakeMyTrip.com halved avg. call handling time and increased
                                            revenue with CX Cloud
                                        </p>
                                    </div>
                                </li>
                                <li className="col-3">
                                    <NavLink
                                        to={`/customers/electronics-company-case-study`}
                                        className="item"
                                    >
                                        <figure>
                                            <img
                                                src={`/img/electronics-company-casestudy-img.jpg`}
                                                alt="Electronics Company Case Study"
                                            />
                                        </figure>
                                        <span className="c-logo">
                                            <img
                                                src={`/img/svg/confidential-logo.svg`}
                                                alt="Confidential Logo"
                                            />
                                        </span>
                                    </NavLink>
                                    <div className="item-wrapper">
                                        <p className="title">
                                            Customer engagement on phone, email, chat, and social channels
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Hike_Case_Study