import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import { FoodBank } from '@mui/icons-material';
import Footer from '../../components/Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const Rigour = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    document.title = "Rigour – Virtuos Salesforce Practice and SBU";
  }, []);
  return (
    <div className="rigour-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <div className="slider-container bxslider">
          <Slider {...settings}>
              <div className="product-hero-wrapper slider1 item">
                <div className="row page-wrap">
                  <div className="col-5 item-wrapper">
                    <span className="title">
                      Rigour <br /> Design
                    </span>
                  </div>
                  <div className="col-7">
                    <figure>
                      <img
                        src={`/img/rigour-header-slider-img1.jpg`}
                        alt="Rigour Design"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="product-hero-wrapper slider2 item">
                <div className="row page-wrap">
                  <div className="col-5 item-wrapper">
                    <span className="title">
                      Multi X <br /> Platformation
                    </span>
                  </div>
                  <div className="col-7">
                    <figure>
                      <img
                        src={`/img/svg/rigour-header-slider-img2.svg`}
                        alt="Multi X Platformation"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="product-hero-wrapper slider3 item">
                <div className="row page-wrap">
                  <div className="col-5 item-wrapper">
                    <span className="title">
                      CRM Domain <br /> Industry Expertise
                    </span>
                  </div>
                  <div className="col-7">
                    <figure>
                      <img
                        src={`/img/svg/rigour-header-slider-img3.svg`}
                        alt="CRM Domain Industry Expertise"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              </Slider>
            </div>
            <div className="right-fixed-logo">
              <img
                src={`/img/svg/icons/rigour-logo.svg`}
                alt="Rigour Logo"
              />
            </div>
          </section>
          <section className="block2-container">
            <div className="row page-wrap">
              <div className="col-4">
                <span className="number">01</span>
                <span className="title">WE ARE SALESFORCE FOCUSSED</span>
              </div>
              <div className="col-4">
                <figure>
                  <img
                    src={`/img/salesforce-focused-img.jpg`}
                    alt="Virtuos - Salesforce Focused"
                  />
                </figure>
              </div>
              <div className="col-4">
                <p className="highlight">
                  Virtuos springs a new Strategic Business Unit (SBU) — Rigour to focus
                  on Salesforce Ecosystem of Products and Services.
                </p>
                <p>
                  Virtuos has more than ten years of proven experience in rolling out
                  multiple CRM and CX consulting and implementation projects. Utilizing
                  our core strengths, we are entering Salesforce practice to achieve the
                  goals of our customers set out by sales, marketing, customer service,
                  field service, digital commerce leaders, and chief digital officers.
                </p>
                <p>
                  These goals may include improving customer satisfaction, acquiring new
                  customers, retaining existing customers, creating a single view of the
                  customer, cross-selling to customers, and improve campaign response
                  rates
                </p>
                <NavLink to={`/corporate/experience-edge`}>
                  OUR EXPERIENCE EDGE <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
            </div>
          </section>
          <section className="block3-container">
            <div className="row page-wrap">
              <div className="col-5">
                <p className="highlight">CRM and CX Portfolio of Services</p>
                <p>
                  Customer experience and CRM implementation services continue to be in
                  high demand with the advent of digital business transformation. CRM
                  functionality falls into five main categories: sales, marketing,
                  customer service, field service and e-commerce, as well as several
                  cross-CRM applications.
                </p>
                <NavLink
                  to={`/consultare/professional-services`}

                >
                  Consultare services <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
              <div className="col-5">
                <div className="progress-bar-container">
                  <div className="bar-wrapper">
                    <div className="labels">
                      {" "}
                      <span>CX Strategy, Design and Architecture</span> <span>95%</span>
                    </div>
                    <div className="bar">
                      {" "}
                      <span className="blue" />{" "}
                    </div>
                  </div>
                  <div className="bar-wrapper">
                    <div className="labels">
                      {" "}
                      <span>Business 4.0 Consulting Skills</span> <span>90%</span>{" "}
                    </div>
                    <div className="bar">
                      {" "}
                      <span className="yellow" />{" "}
                    </div>
                  </div>
                  <div className="bar-wrapper">
                    <div className="labels">
                      {" "}
                      <span>Flexibility and Customer Success</span> <span>98%</span>
                    </div>
                    <div className="bar">
                      {" "}
                      <span className="orange" />{" "}
                    </div>
                  </div>
                  <div className="bar-wrapper">
                    <div className="labels">
                      {" "}
                      <span>Project Management and Execution</span> <span>85%</span>
                    </div>
                    <div className="bar">
                      {" "}
                      <span className="green" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="block2-container">
            <div className="row page-wrap">
              <div className="col-4">
                <span className="number">02</span>
                <span className="title">WE DON’T JUST IMPLEMENT, BUT MASTERMIND.</span>
              </div>
              <div className="col-4">
                <figure>
                  <img
                    src={`/img/svg/implement-mastermind-img.svg`}
                    alt="Virtuos - Mastermind"
                  />
                </figure>
              </div>
              <div className="col-4">
                <p className="highlight">
                  We deliver four main disciplines of CRM implementation — Sales,
                  Service, Marketing and Click Field Service.
                </p>
                <p>
                  Virtuos delivers solutions built around Salesforce to support more
                  industries in greater depth. We have products for E-Commerce/Retail,
                  Online Travel, Hospitality, Higher Education, and Non-Banking Finance.
                  We also invested in the Salesforce core platform and designed for
                  extending, configuring, and building on many of the products that
                  Salesforce offers.
                </p>
                <p>
                  Rigour’s continual investment in innovation and its supporting
                  Salesforce ecosystem have enabled us to build industry specialization
                  conforming to Business 4.0 standards.
                </p>
                <NavLink to={`/consultare/pace-layered`}>
                  LEARN MORE ABOUT PACE LAYERED APPROACH
                  <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
            </div>
          </section>
          <section className="portfolio-container">
            <div className="row page-wrap">
              <div className="col-3">
                <div className="row">
                  <NavLink
                    to="https://www.salesforce.com/live"
                    target="_blank"
                    rel="noreferrer"
                    className="col-12 item"
                  >
                    <figure>
                      <img
                        src={`/img/dreamforce-2019-live-img.jpg`}
                        alt="Dreamforce 2019 - Keynotes and fire chat sessions"
                      />
                    </figure>
                    <div className="overlay">
                      <span>Watch Dreamforce 2019 live</span>
                      <span>Keynotes and fire chat sessions</span>
                    </div>
                  </NavLink>
                </div>
                <div className="row">
                  <NavLink
                    to="https://fortune.com/2019/10/16/trailblazer-marc-benioff-book-review/"
                    target="_blank"
                    rel="noreferrer"
                    className="col-12 item"
                  >
                    <figure>
                      <img
                        src={`/img/marc-benioff-trailblazer-book-img.jpg`}
                        alt="Marc Benioff CEO - Trailblazer book with raving reviews"
                      />
                    </figure>
                    <div className="overlay">
                      <span>Marc Benioff CEO</span>
                      <span>Trailblazer book with raving reviews</span>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <NavLink
                    to="https://www.virtuos.com/downloads/5-steps-to-get-more-value-ebook.pdf"
                    target="_blank"
                    className="col-12 item"
                  >
                    <figure>
                      <img
                        src={`/img/5-steps-to-get-more-value-img.jpg`}
                        alt="Five steps to get more value"
                      />
                    </figure>
                    <div className="overlay">
                      <span>Five steps to get more value</span>
                      <span className="btn-download">
                        Download &nbsp; <i className="fa fa-download" />
                      </span>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="col-3">
                <div className="row">
                  <NavLink
                    to="https://www.salesforce.com/company/news-press/stories/2019/10/102419-IDCandSalesforce/?d=cta-hp-promo-143"
                    target="_blank"
                    rel="noreferrer"
                    className="col-12 item"
                  >
                    <figure>
                      <img
                        src={`/img/svg/salesforce-economy-img.svg`}
                        alt=""
                      />
                    </figure>
                    <div className="overlay">
                      <span>Salesforce Economy</span>
                      <span>2.4 Million Jobs - See story</span>
                    </div>
                  </NavLink>
                </div>
                <div className="row">
                  <NavLink
                    to="https://www.virtuos.com//downloads/state-of-the-connected-customers.pdf"
                    target="_blank"
                    className="col-12 item"
                  >
                    <figure>
                      <img
                        src={`/img/svg/state-of-connected-customers-img.svg`}
                        alt="The State of Connected Customers"
                      />
                    </figure>
                    <div className="overlay">
                      <span>The State of Connected Customers</span>
                      <span className="btn-download">
                        Download &nbsp; <i className="fa fa-download" />
                      </span>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <NavLink
                    to={`/cxnow/cxprimer`}

                    className="col-12 item"
                  >
                    <figure>
                      <img
                        src={`/img/salesforce-cxprimer-img.jpg`}
                        alt="Accelerate Salesforce - Introducing CX Primer"
                      />
                    </figure>
                    <div className="overlay">
                      <span>Accelerate Salesforce</span>
                      <span>Introducing CX Primer</span>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <NavLink
                    to={`/news/unleashing-business-4-strategy`}

                    className="col-12 item"
                  >
                    <figure>
                      <img
                        src={`/img/best-of-salesforce-business4-img.jpg`}
                        alt="Best of Salesforce - Business 4.0"
                      />
                    </figure>
                    <div className="overlay">
                      <span>Best of Salesforce</span>
                      <span>With Business 4.0</span>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="people-container">
            <div className="row page-wrap">
              <div className="col-4 content-block">
                <span className="number">03</span>
                <span className="title">
                  SALESFORCE + RIGOUR <br /> FOR SELECT INDUSTRIES
                </span>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-4 item">
                    <div className="front">
                      <img
                        src={`/img/svg/industry-commerce-img.svg`}
                        alt="Salesforce Service Cloud"
                      />
                      <div className="overlay">
                        <div className="content">
                          <p>Rigour for E-Commerce on Salesforce</p>
                        </div>
                      </div>
                    </div>
                    <div className="des">
                      <p>E-Commerce</p>
                      <p>Salesforce Service Cloud</p>
                    </div>
                  </div>
                  <div className="col-4 item">
                    <div className="front">
                      <img
                        src={`/img/svg/industry-travel-img.svg`}
                        alt="Salesforce Service Cloud"
                      />
                      <div className="overlay">
                        <div className="content">
                          <p>Rigour for Travel &amp; Hospitality on Salesforce</p>
                        </div>
                      </div>
                    </div>
                    <div className="des">
                      <p>Travel &amp; Hospitality</p>
                      <p>Salesforce Service Cloud</p>
                    </div>
                  </div>
                  <div className="col-4 item">
                    <div className="front">
                      <img
                        src={`/img/svg/industry-hitech-img.svg`}
                        alt="Salesforce Sales Cloud"
                      />
                      <div className="overlay">
                        <div className="content">
                          <p>Rigour for Hi-Tech on Salesforce</p>
                        </div>
                      </div>
                    </div>
                    <div className="des">
                      <p>Hi-Tech</p>
                      <p>Salesforce Sales Cloud</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 item">
                    <div className="front">
                      <img
                        src={`/img/svg/industry-education-img.svg`}
                        alt="Salesforce Force and Community"
                      />
                      <div className="overlay">
                        <div className="content">
                          <p>Rigour for Higher Education on Salesforce</p>
                        </div>
                      </div>
                    </div>
                    <div className="des">
                      <p>Higher Education</p>
                      <p>Salesforce Force and Community</p>
                    </div>
                  </div>
                  <div className="col-4 item">
                    <div className="front">
                      <img
                        src={`/img/svg/industry-financial-services-img.svg`}
                        alt="Salesforce Sales and Service Cloud"
                      />
                      <div className="overlay">
                        <div className="content">
                          <p>Rigour for Non-Banking on Salesforce</p>
                        </div>
                      </div>
                    </div>
                    <div className="des">
                      <p>Non Banking Finance</p>
                      <p>Salesforce Sales and Service Cloud</p>
                    </div>
                  </div>
                  <div className="col-4 item">
                    <div className="front">
                      <img
                        src={`/img/svg/industry-automotive-img.svg`}
                        alt="Salesforce Sales and Service Cloud"
                      />
                      <div className="overlay">
                        <div className="content">
                          <p>Rigour for Automotives on Salesforce</p>
                        </div>
                      </div>
                    </div>
                    <div className="des">
                      <p>Automotives</p>
                      <p>Salesforce Sales and Service Cloud</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="blog-container">
            <div className="row page-wrap">
              <div className="col-4 content-block">
                <span className="number">04</span>
                <span className="title">DISCOVER OUR LATEST STORY</span>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-6 blog-wrapper">
                    <figure>
                      <img
                        src={`/img/dreamforce-partnership-img.jpg`}
                        alt="Virtuos - Rigour Latest Story"
                      />
                    </figure>
                    <div className="blog">
                      <div className="info">NOV 19-22, 2019 | SAN FRANCISCO</div>
                      <div className="heading">
                        Virtuos teams have attended Dreamforce consecutively for more
                        than eight times.
                      </div>
                    </div>
                  </div>
                  <div className="col-6 blog-wrapper">
                    <figure>
                      <img
                        src={`/img/svg/crm-mastermind-talent-pool-img.svg`}
                        alt="Virtuos - Rigour Latest Story"
                      />
                    </figure>
                    <div className="blog">
                      <div className="info">Oct 05, 2019 | GURUGRAM</div>
                      <div className="heading">
                        Virtuos to create CRM Mastermind Talent pool for Rigour
                        Salesforce practice.
                      </div>
                    </div>
                  </div>
                  <div className="col-6 blog-wrapper">
                    <figure>
                      <img
                        src={`/img/rigour-salesforce-practice-img.jpg`}
                        alt="Virtuos - Rigour Latest Story"
                      />
                    </figure>
                    <div className="blog">
                      <div className="info">SEP 07, 2019 | GURUGRAM</div>
                      <div className="heading">
                        Rigour is the new name for Salesforce Practice. We are hiring.
                      </div>
                    </div>
                  </div>
                  <div className="col-6 blog-wrapper">
                    <figure>
                      <img
                        src={`/img/svg/virtuos-focus-on-salesforce-practice-img.svg`}
                        alt="Virtuos - Rigour Latest Story"
                      />
                    </figure>
                    <div className="blog">
                      <div className="info">AUG 12, 2019 | GURUGRAM, INDIA</div>
                      <div className="heading">
                        Virtuos plans to focus on Salesforce Practice through SBU.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*?php include '../../footer.php';?*/}
          <Footer />
        </main>

      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  )
}

export default Rigour
