import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Team_Attends_Dreamforce_2013 = () => {
    useEffect(() => {
        document.title = "Three member Virtuos Team attends Dreamforce 2013 at San Francisco – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/dreamforce-2013-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">San Francisco - 22 November, 2013</span>
                                <h2 className="article-heading">
                                    Three member Virtuos Team attends Dreamforce 2013 at San Francisco
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos Team comprising Venky Vijay Reddi, CEO, Kristina Hermanns,
                                    Director — Solution Consulting and Business Head - ExperienceCloud
                                    attended the world’s largest technology event — Dreamforce. The event
                                    was attended by more than 1,40,000 people from over 100 countries.
                                </p>
                                <p>
                                    <strong>Dreamforce 2013 Highlights:</strong>
                                </p>
                                <p>
                                    Dreamforce is Salesforce.com's flagship event. A four-day long event
                                    from Nov 18-21, 2013 in San Francisco; it is the world’s largest
                                    technology show, with more than 1,40,000 executives from across the
                                    globe having attended in 2013. As a budding salesforce.com Consulting
                                    Partner, Virtuos has attended all the key notes and sessions. Virtuos
                                    is investing significant time and resources in Salesforce Practice
                                    which it acquired as part of Consultare business in 2013.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/dreamforce-2013-pics.jpg`}
                                        alt="Virtuos team at Dreamforce 2013"
                                    />
                                </figure>
                                <br />
                                <p>
                                    Dreamforce 2013 attendees were given the chance to learn how to
                                    transform their businesses at the all new, immersive Customer Company
                                    Showcase in Moscone West. Keynote presenters included the following:
                                </p>
                                <ul>
                                    <li>Marc Benioff, CEO, salesforce.com</li>
                                    <li>Marissa Mayer, President and CEO, Yahoo!</li>
                                    <li>Sheryl Sandberg, COO, Facebook</li>
                                    <li>Deepak Chopra, Founder, The Chopra Foundation</li>
                                </ul>
                                <p>
                                    Marc Benioff encouraged the developer community by launching Hackathon
                                    and giving away more than US$ 2 millions in cash.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/dreamforce-2013-upshot.jpg`}
                                        alt="Dreamforce 2013"
                                    />
                                    <span>
                                        Upshot receives US$ 1 Million as give away from Marc Benioff for
                                        being the best innovation this year.
                                    </span>
                                </figure>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely — Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas, and executing them with Virtuoso teams. Virtuos was
                                    founded in 2008 by cultivating an ambitious plan to become a
                                    multi-billion dollar company and a game changer by 2020. Thanks to the
                                    hard work and commitment of our team we continue to grow each year.
                                    With new innovative business ideas, delivering incredible
                                    transformation services and building powerful engagement platforms for
                                    the future we are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink to="http://www.fb.com/virtuos" target="_blank" className="link">
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Team_Attends_Dreamforce_2013