import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_Acquires_Enforce = () => {
    useEffect(() => {
        document.title = "Virtuos acquires Enforce.com, a new digital asset to be part of ExperienceCloud – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-acquires-enforce-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 20 Sep, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos acquires Enforce.com, a new digital asset to be part of
                                    ExperienceCloud
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos acquired Enforce.com, a new digital asset currently valued at
                                    more than US$50,000 as per valuate.com to develop and launch as
                                    Strategy &amp; Business Execution ExperienceCloud. Potentially the
                                    single dictionary word domain would have the valuation of more than
                                    US$500k in the near future. Enforce.com is the new addition into our
                                    ambitious ExperienceCloud Project that brings a lot of synergy to
                                    offer — one of the very first solutions of its kind.
                                </p>
                                <p className="italic">
                                    “There are several types of Business Augmentation or Automation
                                    software and these include ERP, CRM, BPM, HCM, and even BI/Analytics,
                                    EPM, etc., and unfortunately there's no software for Strategic
                                    Business Execution &amp; Delivery. Traditionally the big league
                                    research and management Consultancy organizations have been engaging
                                    with companies for years to re-engineer and execute a strategy with
                                    these companies. This strategic approach often is dwarfed by the less
                                    dynamic rigour &amp; practice by the management due to fast paced
                                    business environmental changes and divided attention on execution.
                                    "says Venky Vijay Reddi". “The next generation businesses need to
                                    rapidly evolve and invent out of the box approaches at the speed of
                                    the thought. Enforce.com, our newest innovation as a Strategy &amp;
                                    Business Execution piece of enterprise application aims to bring
                                    transformational execution capabilities by mapping the resources,
                                    skillets, and business processes.”
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/enforce-logo-news.jpg`}
                                        alt="Enforce Logo"
                                    />
                                </figure>
                                <br />
                                <p>
                                    A complex algorithm of alignment around business and strategy for
                                    Business 3.0 organizations is imminent for success as the business
                                    models have undergone rapid changes and these changes will continue to
                                    be driven by the nexus of forces — Cloud, Social and Mobility in the
                                    millennial age. Enforce.com is the next generation business model
                                    regeneration software with Strategy &amp; Business Execution at the
                                    heart of its engine.
                                </p>
                                <p>
                                    A recent study indicates that 69% of workforce are not focused on
                                    their job resulting in poor productivity for an organization and more
                                    than 75% of the workforce do not have the required talent and
                                    experience resulting in poor execution capabilities for the company.
                                    Companies fail because of employees' poor contribution and employees
                                    fail because of the lack of strategy &amp; business execution followed
                                    with rigour. And more than 95% of Strategy &amp; Business execution
                                    suffers due to the lack of systematic pursuit.
                                </p>
                                <p>
                                    The new generation companies can no longer work in the conventional
                                    method(s) to generate revenue. Enforce.com will analyse these gaps to
                                    bring a holistic solution that can be the CEO's smart solution for
                                    his/her company.
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is an experience consultancy delivering exceptional customer
                                    and employee engagement solutions, services, and platforms around web,
                                    social, mobile foundations. Virtuos has four fabulous business
                                    divisions namely - Virtuos Consultare: The Consulting &amp; IT
                                    Services; Virtuos Cloud: The Enterprise Business Solutions and Apps;
                                    Yippee Media: The SocialWeb, UX Design, and Digital Marketing; and
                                    Virtuos.io: The Connected Engagement Platforms and Ideas. At Virtuos,
                                    we transform companies of high growth potential with Business 3.0
                                    modelling that involves high performance consulting services, hunting
                                    more ideas, and executing them with Virtuoso teams. Virtuos was
                                    founded in 2008 by cultivating an ambitious plan to become a
                                    multi-billion dollar company and a game changer by 2020. Thanks to the
                                    hard work and commitment of our team we continue to grow each year.
                                    With new innovative business ideas, delivering incredible
                                    transformation services and building powerful engagement platforms for
                                    the future we are trending at the right path.
                                </p>
                                <p>
                                    Virtuos is headquartered in the US in San Jose with global offices in
                                    the APAC region including India and our website is located at{" "}
                                    <NavLink to="https://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                    . Tweet us{" "}
                                    <NavLink
                                        to="https://twitter.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        @virtuos
                                    </NavLink>
                                    ; and like us on{" "}
                                    <NavLink
                                        to="http://www.fb.com/virtuos"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="link"
                                    >
                                        http://www.fb.com/virtuos
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Acquires_Enforce