import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_Vivacis_Oracle_Netsuite_Form_Alliance = () => {
  useEffect(() => {
    document.title =
      "Virtuos Vivacis and Oracle NetSuite Form Alliance to Introduce the World's Most Cloud Deployed ERP – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-vivacis-oracle-netsuite-img-big.jpg`}
                  alt="Virtuos Vivacis and Oracle NetSuite form alliance"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Gurgaon, 9 Nov 2018</span>
                <h2 className="article-heading">
                  Virtuos Vivacis and Oracle NetSuite form alliance to introduce
                  the world’s most cloud deployed ERP to Indian companies.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Virtuos unveils Vivacis, a new brand under the umbrella of
                  Virtuos as a Strategic Business Unit (SBU) to cater to Small
                  &amp; Medium sized businesses (SMB) offering Cloud First, and
                  Digital First Applications, Cloud based solutions, and Cloud
                  professional services.
                </p>
                <p>
                  "Virtuos Vivacis now has several alliances under its belt, and
                  they include{" "}
                  <NavLink
                    to="https://www.creatio.com"
                    rel="noreferrer"
                    target="_blank"
                    className="link"
                  />
                  Creatio (Formerly BPM’Online) — leading brand in CRM Space for
                  SMB,{" "}
                  <NavLink
                    to="http://www.netsuite.com/"
                    target="_blank"
                    className="link"
                  >
                    NetSuite
                  </NavLink>{" "}
                  — the leading Cloud deployed ERP applications in the world,
                  and{" "}
                  <NavLink
                    to="http://www.agiloft.com/"
                    target="_blank"
                    className="link"
                  >
                    {" "}
                    Agiloft
                  </NavLink>
                  , new generation Contract Lifecycle Management (CLM)
                  Applications,” said Venky Vijay Reddi, CEO of Virtuos. "We are
                  excited to partner with Oracle NetSuite offering modern ERP
                  Cloud Application Services in India, and select strategic
                  markets outside India."
                </p>
                <p>
                  "Virtuos is one of the Oracle's elite service cloud partner
                  with a history of closing million $$ deals and got logos such
                  as Flipkart, MakeMyTrip, MYNTRA, TVS, Thomas Cook, India First
                  and many more," said Abhishek Gupta, Director of Alliances,
                  Oracle NetSuite business. "Winner of many India and APAC
                  awards with offices in U.S, Hyderabad, and Gurgaon with strong
                  expertise in the area of E-Commerce, Services, and retail
                  industry verticals, an exclusive 12 member technical team
                  focusing on NetSuite, heartiest welcome to the NetSuite
                  Alliance’s world and looking forward to creating a bigger and
                  better business with Virtuos and delivering customer
                  satisfaction."
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. is a leading Customer Experience (CX)
                  &amp; Digital Transformation Consulting company with its
                  corporate headquarters in India, and the USA serving global
                  customers. Virtuos has two important business operations
                  namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/xonomy/ai`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Newly created Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/cxdesk`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Vivacis</h3>
                <p>
                  Virtuos unveils Vivacis, a new Strategic Business Unit (SBU)
                  to focus on Cloud Applications, Business Solutions, and
                  Consulting Services catering to Small &amp; Medium Businesses
                  (SMB) worldwide. Vivacis with its 12 member team will soon be
                  expanded to more than 100 consultants operating from Gurugram,
                  and Hyderabad to serve customers on a 24x7x365 basis offering
                  Digital Software Subscriptions, Business Applications, and
                  Cloud-based software. Vivacis plans to develop an Accelerated
                  Insight Sales Model in alliance with leading information
                  services companies such as Dun &amp; Bradstreet, LinkedIn,
                  Hubspot, amongst others.
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Vivacis_Oracle_Netsuite_Form_Alliance;
