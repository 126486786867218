// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import { hydrate, render } from "react-dom";

// // const root = ReactDOM.createRoot(document.getElementById('root'));
// // root.render(
// //   <React.StrictMode>
// //     <App />
// //   </React.StrictMode>
// // );

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }


// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals




import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

import routes from "./routes"
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

let router = createBrowserRouter(routes);
ReactDOM.hydrateRoot(
  document.getElementById("root"),
  // <ErrorBoundary>
    <RouterProvider router={router} />
  // </ErrorBoundary>
)