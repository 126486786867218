import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { scroller } from "react-scroll";
import CountUp from "react-countup";
const Nexorama = () => {
  const uncodifySectionRef = useRef();

  useEffect(() => {
    document.title = "Nexorama New Digital Transformation | Virtuos Digital";

    const hash = window.location.hash;
    if (hash === "#uncodify") {
      scrollToUncodifySection();
    }
  }, []);

  const scrollToUncodifySection = () => {
    scroller.scrollTo("uncodify", {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -50, // Adjust this offset if needed
    });
  };

  return (
    <div className="nexorama-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="vertical-section">
            <div className="full-screen-wrapper">
              <figure>
                <img
                  src={`/img/nexorama-home-slider-img4.jpg`}
                  alt="Nexorama"
                />
              </figure>
              <div className="center-wrapper">
                <h2 className="section-main-heading">Welcome to Nexorama</h2>
                <p>The post-COVID digital Transformation</p>
                <NavLink
                  to="#uncodify"
                  className="btn btn-secondary"
                  onClick={() =>
                    uncodifySectionRef.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }
                >
                  Learn More <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
            </div>
          </section>
          <section className="nex-xonomy">
            <div className="row page-wrap">
              <div className="col-4 content">
                <h3 className="font-weight600">IN THE EXPERIENCE XONOMY</h3>
                <h2>Virtuos Xonomy Post-COVID strategy — Nexorama.</h2>
                <p>
                  Virtuos Xonomy established in 2018 has incubated and invested
                  in four companies. Xonomy Nexorama is our next strategy.
                </p>
                <NavLink to={`/xonomy/`} className="btn btn-black">
                  About Xonomy
                </NavLink>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/exonomy-ventures-img.jpg`}
                    alt="Virtuos Xonomy Ventures"
                  />
                </figure>
                <h3 className="font-weight600">Virtuos Xonomy Ventures</h3>
                <p>
                  Virtuos Xonomy Ventures (VXV) has incubated and Invested in
                  four companies —{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart Ecommerce
                  </NavLink>
                  ,{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic Digital
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.sweven.com"
                    target="_blank"
                    className="link"
                  >
                    Sweven
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.tekcorp.com"
                    target="_blank"
                    className="link"
                  >
                    Tekcorp
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/exonomy-nexorama-img.jpg`}
                    alt="Xonomy Nexorama"
                  />
                </figure>
                <h3 className="font-weight600">Virtuos Xonomy Nexorama</h3>
                <p>
                  Virtuos Xonomy Nexorama (VXN) is incubating four new
                  businesses within Virtuos Digital —{" "}
                  <NavLink
                    to="http://www.uncodify.com"
                    target="_blank"
                    className="link"
                  >
                    Uncodify
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.audacis.com"
                    target="_blank"
                    className="link"
                  >
                    Audacis
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.Skillnow.com"
                    target="_blank"
                    className="link"
                  >
                    Skillnow
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.expedience.com"
                    target="_blank"
                    className="link"
                  >
                    Expedience
                  </NavLink>
                  .
                </p>
              </div>
            </div>
          </section>
          <section className="innovation">
            <div className="row page-wrap">
              <div className="col-12">
                <ul>
                  <li>
                    <figure>
                      <img
                        src={`${process.env.REACT_APP_MEDIA_URL}/img/svg/icons/skillnow_emblem.svg`}
                        alt="Skillnow"
                      />
                    </figure>
                    <p>Skillnow — Elearning</p>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/audacis-icon-white.svg`}
                        alt="Audacis"
                        style={{ height: 30 }}
                      />
                    </figure>
                    <p>Audacis — Digital Agency</p>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/expedience-icon-white.svg`}
                        alt="Expedience"
                      />
                    </figure>
                    <p>Expedience — Talent Hub</p>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/uncodify-icon-white.svg`}
                        alt="Uncodify"
                        style={{ height: 30 }}
                      />
                    </figure>
                    <p>Uncodify — Low Code</p>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/innovation-on-icon-white.svg`}
                        alt="Innovation On"
                      />
                    </figure>
                    <p>Innovation On — The lab</p>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/nexorama-icon-white.svg`}
                        alt="Nexorama"
                        style={{ height: 30 }}
                      />
                    </figure>
                    <p>Nexorama — Next New</p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="nexo-brand" id="nexorama">
            <div className="row page-wrap">
              <div className="col-12">
                <h2>
                  Nexorama is our brand new Post-COVID Digital Transformation
                  Drive at Virtuos
                </h2>
                <p>
                  NEXORAMA has the foundation of four Essentials:{" "}
                  <strong>CHANGEABL</strong> NEXORAMA has the foundation of four
                  Essentials: CHANGEABL Framework building superpowers (aka
                  virtues) transforming business models from{" "}
                  <strong>Innovation On</strong> Lab. <strong>COMPOSABL</strong>{" "}
                  — Composable Thinking; Composable Architecture; and Composable
                  Technologies; <strong>FLUIDIBLE</strong> — Capable of flow
                  even under pressure; The flow of Skills across #Domains using
                  our Legendary Primary and Secondary skill metrics.{" "}
                  <strong>ALL AGILE</strong> — Focus on customer needs and
                  outcomes using agile methodologies.
                </p>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-4">
                <figure>
                  <img
                    src={`/img/nexorama-composabl-img.jpg`}
                    alt="Nexorama Composabl"
                  />
                </figure>
                <figure>
                  <img
                    src={`/img/nexorama-fluidible-img.jpg`}
                    alt="Nexorama Fluidible"
                  />
                </figure>
              </div>
              <div className="col-4">
                <figure>
                  <img
                    src={`/img/nexorama-all-agile-img.jpg`}
                    alt="Nexorama All Agile"
                  />
                </figure>
                <figure>
                  <img
                    src={`/img/nexorama-changeabl-img.jpg`}
                    alt="Nexorama Changeabl"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="new-business">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="font-weight600">THINKING MODEL</h3>
                  <h2>Nexorama is unleashing Four New Businesses in FY23</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="break">
                    <figure>
                      <img
                        src={`/img/nexorama-business-audacis-img.jpg`}
                        alt="Audacis"
                      />
                      <div className="content">
                        <span className="title">Blitzscaling with AUDACIS</span>
                        <span className="subtitle">
                          The NextGen Digital Agency
                        </span>
                      </div>
                    </figure>
                    <figure>
                      <img
                        src={`${process.env.REACT_APP_MEDIA_URL}/img/nexorama-business-wundero-img.jpg`}
                        alt="Skillnow"
                      />
                      <div className="content">
                        <span className="title">Introducing Skillnow</span>
                        <span className="subtitle">
                          The Agile E-Learning Platform
                        </span>
                      </div>
                    </figure>
                  </div>
                  <figure>
                    <img
                      src={`/img/nexorama-business-new-you-img.jpg`}
                      alt="New You"
                    />
                    <div className="content">
                      <span className="title">NewYou Business — NOVO</span>
                      <span className="subtitle">
                        NewYou — Accelerating Value
                      </span>
                    </div>
                  </figure>
                </div>
                <div className="col-4">
                  <figure>
                    <img
                      src={`/img/nexorama-business-expedience-img.jpg`}
                      alt="Expedience"
                    />
                    <div className="content">
                      <span className="title">Unleashing EXPEDIENCE</span>
                      <span className="subtitle">
                        The disruptive Talent Platform
                      </span>
                    </div>
                  </figure>
                  <figure>
                    <img
                      src={`/img/nexorama-business-invincible-img.jpg`}
                      alt="Invincible"
                    />
                    <div className="content">
                      <span className="title">
                        Creating Invincible Business
                      </span>
                      <span className="subtitle">
                        Inspired from Invincible Co.
                      </span>
                    </div>
                  </figure>
                </div>
                <div className="col-4">
                  <figure>
                    <img
                      src={`/img/nexorama-business-uncodify-img.jpg`}
                      alt="Uncodify"
                    />
                    <div className="content">
                      <span className="title">Unveiling UNCODIFY</span>
                      <span className="subtitle">
                        Low Code No Code Business
                      </span>
                    </div>
                  </figure>
                  <figure>
                    <img
                      src={`/img/nexorama-business-nexorama-img.jpg`}
                      alt="Nexorama"
                    />
                    <div className="content">
                      <span className="title">NEXORAMA</span>
                      <span className="subtitle">
                        The Digital Next New @ Virtuos
                      </span>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section
            className="nexo-economy"
            id="uncodify"
            ref={uncodifySectionRef}
          >
            <div className="row page-wrap">
              <div className="col-4-half content">
                <h3 className="font-weight600">
                  Post COVID Experience Economy Play
                </h3>
                <h2>
                  Creating a New Business Model to accelerate Digital
                  Transformation.
                </h2>
                <p>
                  Nexorama is our newest strategy (Post-COVID Digital
                  Transformation Initiative) to help organizations worldwide
                  accelerate their operations with whole new business models. In
                  the FY2024, We are incubating four newest businesses
                  complementing Virtuos Digital (vdc.com) in the space of CRM,
                  Customer Experience, LowCode NoCode Platforms, and Consulting
                  & Advisory Services.
                </p>
              </div>
              <div className="col-7 item">
                <ul>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/brandcart-logo.png`}
                        alt="Audacis Logo"
                      />
                    </figure>
                    <h3 className="font-weight600">Brand Naming Platform</h3>
                    <p>
                      Post-COVID economy needs new kinds of innovation around
                      Branding principles to help startups innovate and
                      differentiate.
                    </p>
                  </li>
                  <li>
                    <figure>
                      <img
                        style={{ height: "38px" }}
                        src={`/img/svg/cloudle-logo.svg`}
                        alt="Uncodify Logo"
                      />
                    </figure>
                    <h3 className="font-weight600">B2B SaaS Marketplace</h3>
                    <p>
                      Cloudle is a newest kind of B2B Platform for SaaS
                      Marketplace offering a range of technologies in an easy to
                      buy basis.
                    </p>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`${process.env.REACT_APP_MEDIA_URL}/img/svg/icons/wundero-logo.svg`}
                        alt="Wundero Logo"
                      />
                    </figure>
                    <h3 className="font-weight600">Agile Marketing</h3>
                    <p>
                      Wundero is an Agile Marketing Platform Services and
                      Digital Agency to help enterprise customers in whole new
                      way.
                    </p>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/expedience-logo.svg`}
                        alt="Expedience Logo"
                      />
                    </figure>
                    <h3 className="font-weight600">Talent Advisory</h3>
                    <p>
                      The COVID has disrupted the organizations with huge Talent
                      shortages. Expedience offers Talent On Demand.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="customer-success">
            <div className="row">
              <div className="col-6 img-container" />
              <div className="col-6 content-container">
                <div className="title">
                  Disruptive and Timely Help for Organizations
                </div>
                <ul className="points">
                  <li>
                    <div className="heading">
                      <span className="number">01.</span>
                      <span className="heading">Any Process Any Time</span>
                    </div>
                    <p>
                      Implement any process any time using our Low Code No Code
                      (No qualms) Platform powered by our partners at the
                      fraction of the cost.
                    </p>
                  </li>
                  <li>
                    <div className="heading">
                      <span className="number">02.</span>
                      <span className="heading">
                        Agile Learning for Employees
                      </span>
                    </div>
                    <p>
                      Skillnow Platform comes with bundled services to design,
                      curate and construct most contextual courses/programs for
                      your employees.
                    </p>
                  </li>
                  <li>
                    <div className="heading">
                      <span className="number">03.</span>
                      <span className="heading">DemandGen</span>
                    </div>
                    <p>
                      Audacis offers comprehensive DemandGen Services— Digital
                      Marketing, SEO, Branding, UX/MX Creative Design Services,
                      and Mobile Apps.
                    </p>
                  </li>
                  <li>
                    <div className="heading">
                      <span className="number">04.</span>
                      <span className="heading">Hire Resources Quickly</span>
                    </div>
                    <p>
                      We can provide talented and skilled workforce for working
                      on short-term or long-term projects with complete peace of
                      mind and assurance.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="numbers-container" id="numbers">
            <div className="row page-wrap">
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/customer-journey-icon.svg`}
                    alt="Happy Clients"
                  />
                </span>
                <div className="title">
                  <span className="fig-number">
                    <CountUp end={850} duration={200} />+
                  </span>
                </div>
                <span className="subtitle">Happy Clients</span>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/crm-technology-icon.svg`}
                    alt="Projects Completed"
                  />
                </span>
                <div className="title">
                  <span className="fig-number">
                    <CountUp end={850} duration={300} />+
                  </span>
                </div>
                <span className="subtitle">Projects Completed</span>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/crm-functionality-icon.svg`}
                    alt="Years of Existence"
                  />
                </span>
                <div className="title">
                  <span className="fig-number">
                    <CountUp end={850} duration={14} />+
                  </span>
                </div>
                <span className="subtitle">Years of Existence</span>
              </div>
              <div className="col-3 item">
                <span className="icon">
                  <img
                    src={`/img/svg/icons/hours-icon.svg`}
                    alt="Man years of Expertise"
                  />
                </span>
                <div className="title">
                  <span className="fig-number">
                    <CountUp end={850} duration={1000} />+
                  </span>
                </div>
                <span className="subtitle">Man years of Expertise</span>
              </div>
            </div>
          </section>
          <section className="xonomy-bootstrap">
            <div className="right-logo">
              <img
                src={`/img/svg/virtuos-exonomy-logo.svg`}
                alt="Virtuos Xonomy"
              />
            </div>
            <figure>
              <img src={`/img/exonomy-bootstrap-bg.jpg`} alt="" />
            </figure>
            <div className="row page-wrap">
              <div className="col-4 left-block">
                <span className="subtitle">Virtuos Xonomy</span>
                <h2>
                  Virtuos Xonomy is a think tank to breed and bootstrap new
                  ventures.
                </h2>
                <NavLink to={`/xonomy/`} className="btn btn-secondary">
                  Learn <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
              <div className="col-8 right-block">
                <ul>
                  <li>
                    <figure>
                      <NavLink to="http://www.giftcart.com" target="_blank">
                        <img
                          src={`/img/svg/icons/giftcart-logo.svg`}
                          alt="Giftcart logo"
                        />
                      </NavLink>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <NavLink to="http://www.indic.com" target="_blank">
                        <img
                          src={`/img/svg/icons/indic-logo.svg`}
                          alt="Indic logo"
                        />
                      </NavLink>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <NavLink to="http://www.sweven.com" target="_blank">
                        <img
                          src={`/img/svg/icons/sweven-logo.svg`}
                          alt="Sweven logo"
                        />
                      </NavLink>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <NavLink to="http://www.tekcorp.com" target="_blank">
                        <img
                          src={`/img/svg/icons/tekcorp-logo-without-emblem.svg`}
                          alt="Tekcorp logo"
                        />
                      </NavLink>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <NavLink to="http://www.uncodify.com" target="_blank">
                        <img
                          src={`/img/svg/icons/uncodify-logo.svg`}
                          alt="Uncodify logo"
                        />
                      </NavLink>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <NavLink to="http://www.audacis.com" target="_blank">
                        <img
                          src={`/img/svg/icons/audacis-logo.svg`}
                          alt="Audacis logo"
                        />
                      </NavLink>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <NavLink to="http://www.Skillnow.com" target="_blank">
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}/img/svg/icons/skillnow_final_logo.png`}
                          alt="Skillnow logo"
                        />
                      </NavLink>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <NavLink to="http://www.expedience.com" target="_blank">
                        <img
                          src={`/img/svg/icons/expedience-logo.svg`}
                          alt="Expedience logo"
                        />
                      </NavLink>
                    </figure>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="showcase-news-cards-wrapper">
            <h2
              className="section-main-heading wow fadeInUp animated"
              data-wow-duration="500ms"
              data-wow-delay="300ms"
            >
              Press &amp; News
            </h2>
            <div className="page-wrap row">
              <div
                className="col-4 item-wrapper wow fadeInUp animated"
                data-wow-duration="500ms"
                data-wow-delay="300ms"
              >
                <NavLink to={`/news/nexorama-strategy`} className="item">
                  <figure>
                    <img
                      src={`/img/nexorama-strategy-img.jpg`}
                      alt="Virtuos unveils Nexorama strategy to diversify into new areas"
                    />
                  </figure>
                  <div className="content-wrapper">
                    <span className="date">1 Nov 2022</span>
                    <h3 className="section-heading">
                      Virtuos unveils Nexorama strategy to diversify into new
                      areas
                    </h3>
                  </div>
                </NavLink>
              </div>
              <div
                className="col-4 item-wrapper wow fadeInUp animated"
                data-wow-duration="500ms"
                data-wow-delay="300ms"
              >
                <NavLink
                  to={`/news/virtuos-vivacis-global-projects`}
                  className="item"
                >
                  <figure>
                    <img
                      src={`/img/virtuos-vivacis-global-projects-img.jpg`}
                      alt="Virtuos Vivacis implements CRM and CLM Global Projects"
                    />
                  </figure>
                  <div className="content-wrapper">
                    <span className="date">18 Oct 2022</span>
                    <h3 className="section-heading">
                      Virtuos Vivacis implements CRM and CLM Global Projects
                    </h3>
                  </div>
                </NavLink>
              </div>
              <div
                className="col-4 item-wrapper wow fadeInUp animated"
                data-wow-duration="500ms"
                data-wow-delay="300ms"
              >
                <NavLink
                  to={`/news/virtuos-veracis-launches-cxdesk-expedience`}
                  className="item"
                >
                  <figure>
                    <img
                      src={`/img/virtuos-veracis-launches-cxdesk-expedience-img.jpg`}
                      alt="Virtuos Veracis launches CXDesk® and Expedience® Customer Management Operations"
                    />
                  </figure>
                  <div className="content-wrapper">
                    <span className="date">2 Apr 2022</span>
                    <h3 className="section-heading">
                      Virtuos Veracis launches CXDesk<sub>®</sub> and Expedience
                      <sub>®</sub> Customer Management Ops
                    </h3>
                  </div>
                </NavLink>
              </div>
            </div>
          </section>
          {/*?php include '../footer.php'; ?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Nexorama;
