import React, { useEffect, useState,useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import { scroller } from "react-scroll";

const Digital_Dragon = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);

  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };

  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };

  useEffect(() => {
    document.title = "Winning In A World of Digital Dragons. The C.Digital Mastermind – Virtuos Digital";

  }, []);
  useEffect(() => {
    const ids = ['nav1', 'nav2', 'nav3', 'nav4','nav5','nav6']; // Array of IDs to remove the active class from

    ids.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.classList.remove('active');
      }
    });
  }, []);

  const scrollToSection = (sectionId) => {
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 1000,
        smooth: true,
        offset: -50, // Adjust this offset if needed
      });
    }, 100); // Delay in milliseconds
  };

  const [activePhase, setActivePhase] = useState("phase-1");

  const handleItemClick = (rel) => {
    setActivePhase(rel);
  };

  const [activePhase2, setActivePhase2] = useState("phase-1");

  const handleItemClick2 = (rel) => {
    setActivePhase2(rel);
  };

  return (
    <div className="landing-page dragon-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img
                src={`/img/digital-dragons-header-img.jpg`}
                alt=""
              />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div className="col-5 item-wrapper">
                  <h3 className="section-heading">
                    Winning in a World of Digital Dragons
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/c-digital/`}>C.DIGITAL</NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/c-digital/digital-dragons/`}
                    className="active"
                  >
                    DIGITAL DRAGONS
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/`}>
                    CUSTOMER 360
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/c-digital/customer360/ci/`}>
                    CONTINUOUS INTELLIGENCE
                  </NavLink>
                </li>

              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${showExtraLinks ? 'active' : ''} inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" />  More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/c-digital/customer360/ceh/`}>
                          CUSTOMER ENGAGEMENT HUB
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/c-digital/xlv/`}>EXPERIENCE ID</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/c-digital/customer-wide/`}>
                          CUSTOMER WIDE
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="keys" id="keys-1">
            <div className="row page-wrap">
              <div className="col-12">
                <div className="items-container">
                  <NavLink to="" className="item">
                    Enter the Dragons
                    <span className="number">1</span>
                  </NavLink>
                  <NavLink id="nav1" to="#keys-2" className="item"  onClick={() => scrollToSection("keys-2")}>
                    Build Your Perspective
                    <span className="number">2</span>
                  </NavLink>
                  <NavLink id="nav2" to="#keys-3" className="item smooth"  onClick={() => scrollToSection("keys-3")}>
                    Use 3 Behaviors to Maximize
                    <span className="number">3</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="digital-industry">
            <h2 className="section-main-heading">
              Every Industry Is Becoming a{" "}
              <span className="highlight">Digital Industry</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-5">
                <ul>
                  <li>Products are becoming platforms</li>
                  <li>Digital services are differentiators</li>
                  <li>Ecosystems bring value</li>
                  <li>Industry boundaries blur</li>
                  <li>CX becomes digital anthropology</li>
                </ul>
              </div>
              <div className="col-7">
                <p>
                  E.g. The car is becoming a moving platform for digital service
                  consumption
                </p>
                <figure>
                  <img
                    src={`/img/svg/car-digital-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="digital-dragon">
            <h2 className="section-main-heading">
              <span className="highlight">The Digital Dragons</span> Are a Critical Part{" "}
              <br />
              of This Digital Story
            </h2>
            <div className="row page-wrap">
              <div className="col-7">
                <figure>
                  <img
                    src={`/img/svg/digital-dragons-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
              <div className="col-5">
                <p>
                  Digital dragons are large organizations that exhibit seven key
                  characteristics:
                </p>
                <ul>
                  <li>
                    Build and orchestrate large, modern platforms, ecosystems, de facto
                    standards with many partners.
                  </li>
                  <li>
                    Active and skilled in collecting, analyzing, monetizing big data
                    about customers and others, using AI.
                  </li>
                  <li>
                    End-to-end involvement and capability in industry supply chains.
                  </li>
                  <li>Provide infrastructure, cloud-based apps, and tools at scale.</li>
                  <li>
                    Have “deep pockets.” Invest heavily in R&amp;D in areas like AI,
                    IoT, and robotics.
                  </li>
                  <li>
                    Compete as principals in industries beyond digital services, such as
                    online retail.
                  </li>
                  <li>
                    Talent magnets, attracting scarce skills; e.g., data scientists,
                    digital anthropologists, cloud architects.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="digital-exp">
            <h2 className="section-main-heading">
              Example: <span className="highlight">The Amazon Ecosystem</span>
            </h2>
            <div className="row page-wrap digital-program-wrapper">
              {/* Clickable Tabs */}
              <div className="col-4 digital-process-tabs-wrapper vertical-clickable-tabs">
                <ul>
                  <li rel="phase-1" className={`item ${activePhase === "phase-1" ? "active" : ""}`} onClick={() => handleItemClick("phase-1")}>
                    <span>Digital Commerce (Core)</span>
                  </li>
                  <li rel="phase-2" className={`item ${activePhase === "phase-2" ? "active" : ""}`} onClick={() => handleItemClick("phase-2")}>
                    <span>Retail</span>
                  </li>
                  <li rel="phase-3" className={`item ${activePhase === "phase-3" ? "active" : ""}`} onClick={() => handleItemClick("phase-3")}>
                    <span>Logistics and Fulfillment</span>
                  </li>
                  <li rel="phase-4" className={`item ${activePhase === "phase-4" ? "active" : ""}`} onClick={() => handleItemClick("phase-4")}>
                    <span>Digital Media and Entertainment</span>
                  </li>
                  <li rel="phase-5" className={`item ${activePhase === "phase-5" ? "active" : ""}`} onClick={() => handleItemClick("phase-5")}>
                    <span>Technology</span>
                  </li>
                  <li rel="phase-6" className={`item ${activePhase === "phase-6" ? "active" : ""}`} onClick={() => handleItemClick("phase-6")}>
                    <span>Financial Services</span>
                  </li>
                  <li rel="phase-7" className={`item ${activePhase === "phase-7" ? "active" : ""}`} onClick={() => handleItemClick("phase-7")}>
                    <span>Customer Insights and Marketing</span>
                  </li>
                  <li rel="phase-8" className={`item ${activePhase === "phase-8" ? "active" : ""}`} onClick={() => handleItemClick("phase-8")}>
                    <span>Healthcare</span>
                  </li>
                </ul>
              </div>
              <div className="col-8 vertical-tab-content-wrapper digital-process-content-wrapper">
                {/* Phase 1 */}
                {activePhase === "phase-1" && (
                <div id="phase-1" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Retail</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon.com-logo.jpg`}
                            alt="Amazon.com"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/amazon-prime-logo.jpg`}
                            alt="Amazon Prime"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/amazon-seller-central-logo.jpg`}
                            alt="Amazon Seller Central"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/souq-logo.jpg`}
                            alt="SOUQ logo"
                            style={{ width: "40%" }}
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/zappos-logo.jpg`}
                            alt="Zappos.com logo"
                            style={{ width: "40%" }}
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>Whole Sale</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-business-logo.jpg`}
                            alt="Amazon Business logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/amazon-seller-central-logo.jpg`}
                            alt="Amazon Seller Central"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>International/Cross-Border</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon.com-logo.jpg`}
                            alt="Amazon.com"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/amazon-seller-central-logo.jpg`}
                            alt="Amazon Seller Central"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {/* Phase 2 */}
                
                {activePhase === "phase-2" && (
                <div id="phase-2" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Food</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-go-logo.jpg`}
                            alt="Amazon Go logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/whole-foods-logo.jpg`}
                            alt="Whole Foods logo"
                            style={{ width: "30%" }}
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>General Merch</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-books-logo.jpg`}
                            alt="Amazon Books logo"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {/* Phase 3 */}
                
                {activePhase === "phase-3" && (
                <div id="phase-3" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Logistics and Fulfillment</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-flex-logo.jpg`}
                            alt="Amazon Flex logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/fulfill-by-amazon-logo.jpg`}
                            alt="Fulfill by Amazon logo"
                          />
                        </li>
                        <li style={{ width: "100%" }}>
                          Beijing Century Joyo Courier Services
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {/* Phase 4 */}
                
                {activePhase === "phase-4" && (
                <div id="phase-4" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Film and Video</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-studios-logo.jpg`}
                            alt="Amazon Studios logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/prime-video-logo.jpg`}
                            alt="Prime Video logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>Entertainment &amp; Digital Media</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/audible-logo.jpg`}
                            alt="Audible logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/good-reads-logo.jpg`}
                            alt="Good Reads logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/amazon-kindle-logo.jpg`}
                            alt="Amazon Kindle logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/twitch-logo.jpg`}
                            alt="Twitch logo"
                            style={{ width: "50%" }}
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/music-logo.jpg`}
                            alt="Music logo"
                            style={{ width: "50%" }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> )}
                {/* Phase 5 */}
               
                {activePhase === "phase-5" && (
                <div id="phase-5" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Cloud Computing</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/aws-logo.jpg`}
                            alt="AWS logo"
                            style={{ width: "40%" }}
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>Devices</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-key-logo.jpg`}
                            alt="Amazon Key logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/amazon-kindle-logo.jpg`}
                            alt="Amazon Kindle logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/amazon-echo-logo.jpg`}
                            alt="Amazon Echo logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/amazon-fire-logo.jpg`}
                            alt="Amazon Fire logo"
                            style={{ width: "40%" }}
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>VPA &amp; AI</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-alexa-logo.jpg`}
                            alt="Amazon Alexa logo"
                          />
                        </li>
                        <li>Amazon AI Services</li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>IoT</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/aws-greengrass-logo.jpg`}
                            alt="AWS Greengrass logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/aws-iot-logo.jpg`}
                            alt="AWS IoT logo"
                            style={{ width: "30%" }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> )}
               
                {activePhase === "phase-6" && (
                <div id="phase-6" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Payment</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-coins-logo.jpg`}
                            alt="Amazon Coins logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/amazon-pay-logo.jpg`}
                            alt="Amazon Pay logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>Banking</h3>
                      <ul>
                        <li>Amazon Lending</li>
                        <li>
                          <img
                            src={`/img/amazon-seller-central-logo.jpg`}
                            alt="Amazon Seller Central logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>Investment</h3>
                      <ul>
                        <li>Joint venture with Citi and Berkshire Hathaway</li>
                        <li>Investments in Indian Fintechs</li>
                      </ul>
                    </div>
                  </div>
                </div>
                )}
                {activePhase === "phase-7" && (
                <div id="phase-7" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Customer Insights and Marketing</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-advertising-logo.jpg`}
                            alt="Amazon Advertising logo"
                            style={{ width: "75%" }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                )}
                {activePhase === "phase-8" && (
                <div id="phase-8" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Healthcare</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/pillpack-logo.jpg`}
                            alt="Amazon Pillpack logo"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
              </div>
            </div>
          </section>
          <section className="digital-exp">
            <h2 className="section-main-heading">
              Example: <span className="highlight">The Alibaba Ecosystem</span>
            </h2>
            <div className="row page-wrap digital-program-wrapper">
              {/* Clickable Tabs */}
              <div className="col-4 digital-process-tabs-wrapper vertical-clickable-tabs">
                <ul>
                  <li rel="phase-1" className={`item ${activePhase2 === "phase-1" ? "active" : ""}`} onClick={() => handleItemClick2("phase-1")}>
                    <span>Digital Commerce (Core)</span>
                  </li>
                  <li rel="phase-2" className={`item ${activePhase2 === "phase-2" ? "active" : ""}`} onClick={() => handleItemClick2("phase-2")}>
                    <span>Retail</span>
                  </li>
                  <li rel="phase-3" className={`item ${activePhase2 === "phase-3" ? "active" : ""}`} onClick={() => handleItemClick2("phase-3")}>
                    <span>Automotive</span>
                  </li>
                  <li rel="phase-4" className={`item ${activePhase2 === "phase-4" ? "active" : ""}`} onClick={() => handleItemClick2("phase-4")}>
                    <span>Digital Media and Entertainment</span>
                  </li>
                  <li rel="phase-5" className={`item ${activePhase2 === "phase-5" ? "active" : ""}`} onClick={() => handleItemClick2("phase-5")}>
                    <span>Technology</span>
                  </li>
                  <li rel="phase-6" className={`item ${activePhase2 === "phase-6" ? "active" : ""}`} onClick={() => handleItemClick2("phase-6")}>
                    <span>Financial Services</span>
                  </li>
                  <li rel="phase-7" className={`item ${activePhase2 === "phase-7" ? "active" : ""}`} onClick={() => handleItemClick2("phase-7")}>
                    <span>Customer Insights and Marketing</span>
                  </li>
                  <li rel="phase-8" className={`item ${activePhase2 === "phase-8" ? "active" : ""}`} onClick={() => handleItemClick2("phase-8")}>
                    <span>Healthcare</span>
                  </li>
                </ul>
              </div>
              <div className="col-8 vertical-tab-content-wrapper digital-process-content-wrapper">
                {/* Phase 1 */}
                {activePhase2 === "phase-1" && (
                <div id="phase-1" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Retail (China)</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/cun.taobao-logo.jpg`}
                            alt="Cun.taobao.com"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/juhuasuan.com-logo.jpg`}
                            alt="Juhuasuan.com"
                            style={{ width: "50%" }}
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/taobao.com-logo.jpg`}
                            alt="Taobao.com"
                            style={{ width: "50%" }}
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/tmall-logo.jpg`}
                            alt="Tmall logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>Whole Sale (China Domestic)</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/1688.com-logo.jpg`}
                            alt="1st.1688.com logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>International/Cross-Border</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/alibaba.com-logo.jpg`}
                            alt="Alibaba.com"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/aliexpress-logo.jpg`}
                            alt="Aliexpress logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/lazada-group-logo.jpg`}
                            alt="Lazada Group logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/tmall-global-logo.jpg`}
                            alt="TMall Global logo"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {/* Phase 2 */}
                {activePhase2 === "phase-2" && (
                <div id="phase-2" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Food Delivery</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/ele.me-logo.jpg`}
                            alt="Ele.me logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/koubei-logo.jpg`}
                            alt="Koubei logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>General Merch</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/easyhome-logo.jpg`}
                            alt="Easyhome logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/b&q-logo.jpg`}
                            alt="b&q logo"
                            style={{ width: "40%" }}
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/sunart-logo.jpg`}
                            alt="Sun Art logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/suning-logo.jpg`}
                            alt="Suning logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/intime-retail-logo.jpg`}
                            alt="Intime Retail logo"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {/* Phase 3 */}
                {activePhase2 === "phase-" && (
                <div id="phase-3" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Logistics and Fulfillment</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-flex-logo.jpg`}
                            alt="Amazon Flex logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/fulfill-by-amazon-logo.jpg`}
                            alt="Fulfill by Amazon logo"
                          />
                        </li>
                        <li style={{ width: "100%" }}>
                          Beijing Century Joyo Courier Services
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {activePhase2 === "phase-3" && (
                <div id="phase-4" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Automotive</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amap.com-logo.jpg`}
                            alt="Amao.com logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/banma-technologies-logo.jpg`}
                            alt="Banma Technologies logo"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {activePhase2 === "phase-" && (
                <div id="phase-5" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Film and Video</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amazon-studios-logo.jpg`}
                            alt="Amazon Studios logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/prime-video-logo.jpg`}
                            alt="Prime Video logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>Entertainment &amp; Digital Media</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/audible-logo.jpg`}
                            alt="Audible logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/good-reads-logo.jpg`}
                            alt="Good Reads logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/amazon-kindle-logo.jpg`}
                            alt="Amazon Kindle logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/twitch-logo.jpg`}
                            alt="Twitch logo"
                            style={{ width: "50%" }}
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/music-logo.jpg`}
                            alt="Music logo"
                            style={{ width: "50%" }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {activePhase2 === "phase-4" && (
                <div id="phase-6" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Film and Video</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/alibaba-pictures-logo.jpg`}
                            alt="Alibaba Pictures logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>Entertainment &amp; Digital Media</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/alisports-logo.jpg`}
                            alt="Alisports logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/alibaba-music-logo.jpg`}
                            alt="Alibaba Music logo"
                            style={{ width: "40%" }}
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/weibo.com-logo.jpg`}
                            alt="Weibo.com logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/ucbrowser-logo.jpg`}
                            alt="UC Browser logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/youku-logo.jpg`}
                            alt="Youku logo"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {activePhase2 === "phase-5" && (
                <div id="phase-7" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Cloud Computing</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/alibaba-cloud-logo.jpg`}
                            alt="Alibaba Cloud logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>Devices</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/tmall-genie-logo.jpg`}
                            alt="Tmall Genie logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>VPA &amp; AI</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/aligenie-logo.jpg`}
                            alt="Ali Genie logo"
                          />
                        </li>
                        <li>Blockchain</li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>IoT</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/alios-logo.jpg`}
                            alt="Alios logo"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {activePhase2 === "phase-6" && (
                <div id="phase-8" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Payment</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/alipay-logo.jpg`}
                            alt="Alipay logo"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 item">
                      <h3>Banking &amp; Investment</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/amt-check-later-logo.jpg`}
                            alt="ANT Check Later logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/get-capital-logo.jpg`}
                            alt="Get Capital logo"
                          />
                        </li>
                        <li>Jiebei</li>
                        <li>
                          <img
                            src={`/img/ant-financial-logo.jpg`}
                            alt="ANT Financial logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/mybank-logo.jpg`}
                            alt="My Bank logo"
                          />
                        </li>
                        <li>
                          <img
                            src={`/img/zhima-credit-logo.jpg`}
                            alt="Zhima Credit logo"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {activePhase2 === "phase-7" && (
                <div id="phase-9" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Customer Insights and Marketing</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/alimama-logo.jpg`}
                            alt="Alimama logo"
                          />
                        </li>
                        <li style={{color:"#00a1fe"}}>Brand Databank</li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {activePhase2 === "phase-8" && (
                <div id="phase-10" className="vertical-tab-content active blue">
                  <div className="row content-block">
                    <div className="col-12 item">
                      <h3>Healthcare</h3>
                      <ul>
                        <li>
                          <img
                            src={`/img/alihealth-logo.jpg`}
                            alt="Alihealth logo"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
              </div>
            </div>
          </section>
          <section className="dragon-partner grey-bg">
            <h2 className="section-main-heading">
              The Digital Dragons Are{" "}
              <span className="highlight">More Complex Partners</span> <br />
              Than Have Ever Existed Before
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/digital-dragons-complex-partners-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="keys" id="keys-2">
            <div className="row page-wrap">
              <div className="col-12">
                <div className="items-container">
                  <NavLink id="nav3" to="#keys-1" className="item smooth" onClick={() => scrollToSection("keys-1")}>
                    Enter the Dragons
                    <span className="number">1</span>
                  </NavLink>
                  <NavLink to="" className="item active">
                    Build Your Perspective
                    <span className="number">2</span>
                  </NavLink>
                  <NavLink id="nav4" to="#keys-3" className="item smooth"  onClick={() => scrollToSection("keys-3")}>
                    Use 3 Behaviors to Maximize
                    <span className="number">3</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="attitude">
            <h2 className="section-main-heading">
              Attitudes to the Dragons{" "}
              <span className="highlight">Vary Considerably</span>
            </h2>
            <span className="section-desc">
              How do the digital giants (Google, Amazon, Baidu, etc.) factor in to your
              company’s (business) strategic thinking?
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/dragon-attitude-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/dragon-attitude-diagram1.svg`}
                    alt=""
                  />
                </figure>
                <blockquote>
                  “Our business strategy is based on evolving a partner ecosystem; we
                  won’t succeed alone. We are currently co-developing solutions with one
                  of the digital giants, and discussing with another. The collaboration
                  is enlightening. We learn new methodologies and other things, and
                  mixing people is making for better results. It seems to us a
                  non-zero-sum game, where both really get value.” —{" "}
                  <strong>Martin Piqueras Caro, CIO, SUEZ</strong>
                </blockquote>
              </div>
            </div>
          </section>
          <section className="dragon-deck light-blue-bg">
            <h2 className="section-main-heading">
              Each Organization Must Build the Digital Dragons <br />
              Into Their{" "}
              <span className="highlight">Planning and Execution</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/importance-of-digital-dragons-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="dragon-deck">
            <h2 className="section-main-heading">
              Build Your <span className="highlight">Digital Dragon Deck</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/digital-dragons-deck-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="audi-container grey-bg">
            <h2 className="section-main-heading">
              Example: How Audi Deals With{" "}
              <span className="highlight">“Digital Champions”</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-8-half">
                <ul>
                  <li>
                    Digital strategy goals: $1 billion profit by 2025. Effortless
                    customer experience. Reducing general complexity.
                  </li>
                  <li>
                    Share with others in Volkswagen group using groupwide digital
                    services committee. For example, leveraging Volkswagen Connect.
                  </li>
                  <li>
                    Four components: Big-ticket items (e.g., bringing existing products
                    into the digital age), must-dos (e.g., support for new smartphones),
                    wow factors (things customers didn’t expect), enablers (heavy
                    lifting, like in-car connectivity).
                  </li>
                  <li>Spun off an autonomous intelligent driving company, AID.</li>
                  <li>
                    Digital is a “two-speed industry.” Top digital companies, wholly
                    focused on digital, with an army of smart digital talent, patient
                    investors, and big visions. And everyone else. Even the giants in
                    each industry shouldn’t go toe to toe with the digital giants on
                    their own turf. Companies have to be realistic and understand
                    trade-offs of working with the digital giants versus going it alone.
                  </li>
                  <li>
                    There is an opportunity/imperative to learn from these digital
                    giants. “We made a list of 60 digital champions. We studied them and
                    distilled out a set of 12 principles for digital success. We then
                    boiled them down to four to five rules.”
                  </li>
                </ul>
              </div>
              <div className="col-3">
                <img
                  src={`/img/john-newman-img.png`}
                  alt="John Newman"
                />
                <span>
                  <strong>John Newman</strong> <br />
                  VP and Head of Digitalization, Audi AG
                </span>
              </div>
            </div>
          </section>
          <section className="digital-dragons">
            <h2 className="section-main-heading">
              Model How the{" "}
              <span className="highlight">Digital Dragons See Your Business</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/digital-dgraon-diagram.svg`}
                    alt=""
                  />
                </figure>
                <blockquote>
                  "We want BAT (Baidu, Alibaba, Tencent) to be a part of our supply
                  chain, but they see us as a participant in their platform." —{" "}
                  <strong>Li Hongwei</strong>, head of IT and IS planning and
                  management, Dongfeng Motor
                </blockquote>
              </div>
            </div>
          </section>
          <section className="digital-dragons light-blue-bg">
            <h2 className="section-main-heading">
              Think Through Your{" "}
              <span className="highlight">Posture Regarding the Digital Dragons</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/digital-dragon-posture-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="keys" id="keys-3">
            <div className="row page-wrap">
              <div className="col-12">
                <div className="items-container">
                  <NavLink id="nav5" to="#keys-1" className="item smooth" onClick={() => scrollToSection("keys-1")}>
                    Enter the Dragons
                    <span className="number">1</span>
                  </NavLink>
                  <NavLink id="nav6" to="#keys-2" className="item smooth" onClick={() => scrollToSection("keys-2")}>
                    Build Your Perspective
                    <span className="number">2</span>
                  </NavLink>
                  <NavLink to="" className="item active">
                    Use 3 Behaviors to Maximize
                    <span className="number">3</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
          <section className="dragon-breaker">
            <figure className="bg-banner">
              <img src="/img/dragon-bg.jpg" alt="" />
            </figure>
            <div className="row page-wrap">
              <div className="col-12">
                <figure>
                  <img
                    src={`/img/svg/three-behavior-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="peril-container">
            <h2 className="section-main-heading">
              Ignore the <span className="highlight">Chinese Digital Dragons</span>
              at Your Peril
            </h2>
            <div className="row page-wrap">
              <div className="col-12">
                <table width="100%" border={0} cellSpacing={0} className="market-value">
                  <tbody>
                    <tr>
                      <td height={50} colSpan={5} align="center" valign="top">
                        <strong>
                          Public / Private Internet Companies, Ranked by Market
                          Valuation (5/29/18)
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Rank</strong>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td colSpan={2} align="center" bgcolor="#eee">
                        <strong>Market Value ($B)</strong>
                      </td>
                    </tr>
                    <tr>
                      <td bgcolor="#1c3055">2018</td>
                      <td bgcolor="#1c3055">Company</td>
                      <td bgcolor="#1c3055">Region</td>
                      <td bgcolor="#1c3055">5/29/13</td>
                      <td bgcolor="#1c3055">5/29/18</td>
                    </tr>
                    <tr>
                      <td>1)</td>
                      <td>Apple</td>
                      <td>USA</td>
                      <td>$418</td>
                      <td>$924</td>
                    </tr>
                    <tr>
                      <td bgcolor="#eee">2)</td>
                      <td bgcolor="#eee">Amazon</td>
                      <td bgcolor="#eee">USA</td>
                      <td bgcolor="#eee">121</td>
                      <td bgcolor="#eee">783</td>
                    </tr>
                    <tr>
                      <td>3)</td>
                      <td>Microsoft</td>
                      <td>USA</td>
                      <td>291</td>
                      <td>753</td>
                    </tr>
                    <tr>
                      <td bgcolor="#eee">4)</td>
                      <td bgcolor="#eee">Google/Alphabet</td>
                      <td bgcolor="#eee">USA</td>
                      <td bgcolor="#eee">288</td>
                      <td bgcolor="#eee">739</td>
                    </tr>
                    <tr>
                      <td>5)</td>
                      <td>Facebook</td>
                      <td>USA</td>
                      <td>56</td>
                      <td>538</td>
                    </tr>
                    <tr>
                      <td bgcolor="#eee">6)</td>
                      <td bgcolor="#eee">Alibaba</td>
                      <td bgcolor="#eee">China</td>
                      <td bgcolor="#eee">-</td>
                      <td bgcolor="#eee">509</td>
                    </tr>
                    <tr>
                      <td>7)</td>
                      <td>Tencent</td>
                      <td>China</td>
                      <td>71</td>
                      <td>483</td>
                    </tr>
                    <tr>
                      <td bgcolor="#eee">8)</td>
                      <td bgcolor="#eee">Netflix</td>
                      <td bgcolor="#eee">USA</td>
                      <td bgcolor="#eee">13</td>
                      <td bgcolor="#eee">152</td>
                    </tr>
                    <tr>
                      <td>9)</td>
                      <td>Ant Financial</td>
                      <td>China</td>
                      <td>-</td>
                      <td>150</td>
                    </tr>
                    <tr>
                      <td bgcolor="#eee">10)</td>
                      <td bgcolor="#eee">eBay + Paypal*</td>
                      <td bgcolor="#eee">USA</td>
                      <td bgcolor="#eee">71</td>
                      <td bgcolor="#eee">133</td>
                    </tr>
                    <tr>
                      <td>11)</td>
                      <td>Booking Holdings</td>
                      <td>USA</td>
                      <td>41</td>
                      <td>100</td>
                    </tr>
                    <tr>
                      <td bgcolor="#eee">12)</td>
                      <td bgcolor="#eee">Salesforce.com</td>
                      <td bgcolor="#eee">USA</td>
                      <td bgcolor="#eee">25</td>
                      <td bgcolor="#eee">94</td>
                    </tr>
                    <tr>
                      <td>13)</td>
                      <td>Baidu</td>
                      <td>China</td>
                      <td>34</td>
                      <td>84</td>
                    </tr>
                    <tr>
                      <td bgcolor="#eee">14)</td>
                      <td bgcolor="#eee">Xiaomi</td>
                      <td bgcolor="#eee">China</td>
                      <td bgcolor="#eee">-</td>
                      <td bgcolor="#eee">75</td>
                    </tr>
                    <tr>
                      <td>15)</td>
                      <td>Uber</td>
                      <td>USA</td>
                      <td>-</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td bgcolor="#eee">16)</td>
                      <td bgcolor="#eee">Didi Chuxing</td>
                      <td bgcolor="#eee">China</td>
                      <td bgcolor="#eee">-</td>
                      <td bgcolor="#eee">56</td>
                    </tr>
                    <tr>
                      <td>17)</td>
                      <td>JD.com</td>
                      <td>China</td>
                      <td>-</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td bgcolor="#eee">18)</td>
                      <td bgcolor="#eee">Airbnb</td>
                      <td bgcolor="#eee">USA</td>
                      <td bgcolor="#eee">-</td>
                      <td bgcolor="#eee">31</td>
                    </tr>
                    <tr>
                      <td>19)</td>
                      <td>Meituan-Dianping</td>
                      <td>China</td>
                      <td>-</td>
                      <td>30</td>
                    </tr>
                    <tr>
                      <td bgcolor="#eee">20)</td>
                      <td bgcolor="#eee">Toutiao</td>
                      <td bgcolor="#eee">China</td>
                      <td bgcolor="#eee">-</td>
                      <td bgcolor="#eee">30</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Total</td>
                      <td>$1,429</td>
                      <td>$5,788</td>
                    </tr>
                  </tbody>
                </table>
                <div className="note">
                  <strong>Source:</strong> Kleiner Perkins, 2018 Internet Trends
                </div>
                <table
                  width="100%"
                  border={0}
                  cellSpacing={0}
                  cellPadding={0}
                  className="agenda"
                >
                  <tbody>
                    <tr>
                      <td height={30} colSpan={2} align="center" valign="top">
                        <h3>
                          China’s Government Is Strongly Driving the Digital Agenda
                        </h3>
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">
                        <strong>Initiative</strong>
                      </td>
                      <td width="70%">Details</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Made in China 2025</strong>
                      </td>
                      <td>
                        Issued May 2015 by Premier Li Keqiang. Inspired by Germany’s
                        Industrie 4.0. To upgrade Chinese industry. 3 timelines — 2025,
                        2035, 2049 for China to become leader. Priority sectors include
                        advanced IT, automated machine tools &amp; robotics.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Social Credit System</strong>
                      </td>
                      <td>
                        Extensive plans to collect data about people and companies,
                        score their behavior, and provide incentives based on social
                        credit score. Data initially being collected from government
                        agencies and stored on the National Credit Information Sharing
                        Platform.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Next-Generation AI Development Plan</strong>
                      </td>
                      <td>
                        Issued by State Council 20 July 2017. Catch up to global AI
                        leaders by 2020. By 2025, achieve major breakthroughs in AI
                        research. Use AI to drive industry reform. By 2030, become world
                        leader in AI research and application. Use AI to build
                        intelligent economy/society to make China world leader. Value of
                        AI to surpass 1 trillion yuan ($147.80 billion) by 2030.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>13th Five-Year Plan (2016-2020)</strong>
                      </td>
                      <td>
                        Adopted March 2016. A broad and ambitious set of objectives,
                        including 6.5% annual GDP growth and Increasing R&amp;D spend
                        from 2.1% to 2.5% of GDP.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Belt and Road Initiative (BRI)</strong>
                      </td>
                      <td>
                        <p>
                          (Also known as Silk Road Economic Belt and the 21st-century
                          Maritime Silk Road.) Issued by Xi Jinping in 2013. Goal is to
                          connect 2/3 of the world’s population across 70 countries
                          through land links (the belt),
                          <br />
                          and sea routes (the road). Also includes banking/funding,
                          university/engineering alliances and clean energy programs.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>19 Super Regions/City Clusters</strong>
                      </td>
                      <td>
                        As outlined in China’s 11th five-year plan, Chinese has been
                        developing of regional city clusters, to drive shared economic
                        prosperity and growth. Three are designated as super-clusters
                        (Yangtze River Delta, Inc., Shanghai; Pearl River Delta, Inc.,
                        Guangzhou; and Jingjinji, Inc., Beijing). Eight are described as
                        emerging clusters, and a further eight are frontier clusters.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-12">
                <h3>Baidu builds global autonomous driving ecosystem</h3>
                <figure>
                  <img
                    src={`/img/svg/baidu-apollo-project-diagram.svg`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="recommendation">
            <h2 className="section-main-heading">
              OUR <span className="highlight"> RECOMMENDATIONS</span>
            </h2>
            <div className="row page-wrap">
              <div className="col-9 left-block">
                <ul>
                  <li>
                    Accept, understand and socialize the role and importance of the
                    digital dragons in the next decade.
                  </li>
                  <li>Build your digital dragon knowledge deck; don’t ignore China.</li>
                  <li>Construct your view of how they see your company/industry.</li>
                  <li>
                    Choose your strategic posture regarding the digital dragons; each
                    has their advantages and risks.
                  </li>
                  <li>Build time in for learning through productive friction.</li>
                </ul>
              </div>
              <div className="col-3 right-block">
                <span className="title1">
                  Bolster your competitive advantages with Virtuos
                </span>
                <span className="title2">
                  Be a mastermind, <br />a Virtuoso!
                </span>
                <NavLink to={`/contact/`} >
                  Contact Us
                </NavLink>
              </div>
            </div>
          </section>
          {/*?php include '../../footer-consultare.php';?*/}
          <Footer />
        </main>

      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  )
}

export default Digital_Dragon
