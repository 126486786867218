import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Oracle_Acquires_Rightnow_Technologies = () => {
    useEffect(() => {
        document.title = "Oracle, all set to acquire RightNow Technologies for US$1.5B – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/oracle-acquires-rightnow-technologies-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Montana, USA - 24 October, 2011</span>
                                <h2 className="article-heading">
                                    Oracle, all set to acquire RightNow Technologies for US$1.5B
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Oracle to acquire RightNow Technologies (Nasdaq:RNOW) for US $1.5
                                    Billion (approximately), a leader in Cloud based Customer Experience
                                    (CX) Solutions with Over 2000+ customers including some of the biggest
                                    names such as British Telecom, Toshiba, Sony, Electronic Arts (EA) to
                                    name a few.
                                </p>
                                <p>
                                    RightNow recently changed its focus to drive holistic “Customer
                                    Experience” (CX) across channels departing from the previous CRM
                                    Offerings. RightNow has also changed its logo to reflect the changed
                                    vision of delivering Experience Management (XM) Software.
                                </p>
                                <p>
                                    Oracle is moving aggressively with a complete suite of Cloud based
                                    solutions under Oracle Public Cloud — a new platform for offering next
                                    generation CRM, ERP, HCM solutions.
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/oracle-rightnow-news-img.jpg`}
                                        alt="Oracle and RightNow Technologies"
                                    />
                                </figure>
                                <h3 className="title">News from the Press:</h3>
                                <div className="row journal">
                                    <div className="col-3 item">
                                        <NavLink
                                            to="https://www.cio.com/article/2403103/oracle-buys-rightnow-for-about-us-1-5-billion.html"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={`/img/svg/icons/cio-logo.svg`}
                                                alt="CIO.com"
                                                style={{ width: "50%" }}
                                            />
                                        </NavLink>
                                    </div>
                                    <div className="col-3 item">
                                        <NavLink
                                            to="https://www.zdnet.com/article/oracle-acquires-rightnow-for-1-5-billion-aims-turrets-at-salesforce-com/"
                                            rel="noreferrer"
                                            target="_blank"
                                            className="link"
                                        >
                                            <img
                                                src={`/img/zdnet-logo.png`}
                                                alt="ZDNet.com"
                                                style={{ width: "50%" }}
                                            />
                                        </NavLink>
                                    </div>
                                    <div className="col-3 item">
                                        <NavLink
                                            to="https://www.businesswire.com/news/home/20111024005796/en/Oracle-Acquire-RightNow"
                                            rel="noreferrer"
                                            target="_blank"
                                            className="link"
                                        >
                                            <img
                                                src={`/img/businesswire-logo.jpg`}
                                                alt="Businesswire.com"
                                            />
                                        </NavLink>
                                    </div>
                                    <div className="col-3 item">
                                        <NavLink
                                            to="https://dealbook.nytimes.com/2011/10/24/oracle-to-buy-rightnow-for-1-5-billion/"
                                            rel="noreferrer"
                                            target="_blank"
                                            className="link"
                                        >
                                            <img
                                                src={`/img/new-york-times-logo.jpg`}
                                                alt="nytimes.com"
                                            />
                                        </NavLink>
                                    </div>
                                </div>
                                <br />
                                <p>
                                    Post completion of the acquisition, RightNow Technologies may become
                                    an independent strategic unit (SBU) under Oracle or possibly replace
                                    Oracle’s CRM OnDemand Product by introducing RightNow stack.
                                </p>
                                <p>
                                    “The partnership with RightNow Technologies will continue to remain
                                    unaffected until Oracle completes the acquisition formalities of
                                    RightNow Technologies.” Said Venky Vijay Reddi, CEO of Virtuos.
                                    “Customers do hope to gain from this new development as Oracle is
                                    building its own data centers. Customers can look forward to seeing a
                                    broader and bigger roadmap for RightNow CX Suite under the aegis of
                                    Oracle leadership.”
                                </p>
                                <p>
                                    Both Mark Hurd, the Co-CEO of Oracle and Thomas Kurien, the EVP of
                                    Applications &amp; Cloud have reinforced their commitment to the
                                    employees, partners, and customers of RightNow Technologies.
                                </p>
                                <h3 className="title">About RightNow Technologies</h3>
                                <p>
                                    RightNow Technologies, Inc. (Nasdaq: RNOW) provides customer
                                    relationship management software and services. The Company offers
                                    solutions that use self-learning knowledge-base to deliver customer
                                    self-service, email management, live interaction, issue tracking, and
                                    customer satisfaction measurement capabilities.
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">07/12/2000</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            136 Enterprise Blvd Bozeman, <br />
                                            MT 59718 United States
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Phone</span>
                                        <span className="txt">1-406-522-4200</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink
                                                to="http://www.rightnow.com"
                                                target="_blank"
                                                className="link"
                                            >
                                                www.rightnow.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">1000-2000</span>
                                    </div>
                                </div>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, and
                                    Internet Startups.
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has built a strong RightNow Practice for CRM Service Cloud by
                                    creating marquee clients like MakeMyTrip, Fundtech amongst others.
                                    Virtuos will continue to strengthen its business with RightNow and
                                    Oracle by becoming the latter’s partner. For more information visit{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Oracle_Acquires_Rightnow_Technologies