import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Sidebar from "../pages/Sidebar/Sidebar";
import vdc from "../img/NewLogo/virtuos_digital_Icon.svg";
// import Sidebar from '../pages/Sidebar/Sidebar';
const Main_nav_wrapper = () => {
  const [showConsultareLinks, setshowConsultareLinks] = useState("consultare1");
  const [showCompanyLinks, setshowCompanyLinks] = useState("company1");
  const [showExonomyLinks, setshowExonomyLinks] = useState("exonomy1");
  const [showBusinessLinks, setshowBusinessLinks] = useState("business1");
  // console.log(showConsultareLinks)

  const openRightLink = (menuname, showmenu) => {
    console.log(menuname);
    console.log(showmenu);
    if (menuname === "consultare") {
      setshowConsultareLinks(showmenu);
    } else if (menuname === "company") {
      setshowCompanyLinks(showmenu);
    } else if (menuname === "xonomy") {
      setshowExonomyLinks(showmenu);
    } else if (menuname === "business") {
      setshowBusinessLinks(showmenu);
    }
  };

  const openRightLinks = (evt, tabName) => {
    var i, rightlinks, leftlinks;
    rightlinks = document.getElementsByClassName("rightlinks");
    for (i = 0; i < rightlinks.length; i++) {
      rightlinks[i].style.display = "none";
    }
    leftlinks = document.getElementsByClassName("leftlinks");
    for (i = 0; i < leftlinks.length; i++) {
      leftlinks[i].className = leftlinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  };
  const openRightLinks1 = (evt, tabName) => {
    var i, rightlinks, leftlinks;
    rightlinks = document.getElementsByClassName("rightlinks1");
    for (i = 0; i < rightlinks.length; i++) {
      rightlinks[i].style.display = "none";
    }
    leftlinks = document.getElementsByClassName("leftlinks1");
    for (i = 0; i < leftlinks.length; i++) {
      leftlinks[i].className = leftlinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  };
  const openRightLinks2 = (evt, tabName) => {
    var i, rightlinks, leftlinks;
    rightlinks = document.getElementsByClassName("rightlinks2");
    for (i = 0; i < rightlinks.length; i++) {
      rightlinks[i].style.display = "none";
    }
    leftlinks = document.getElementsByClassName("leftlinks2");
    for (i = 0; i < leftlinks.length; i++) {
      leftlinks[i].className = leftlinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  };
  const openRightLinks3 = (evt, tabName) => {
    var i, rightlinks, leftlinks;
    rightlinks = document.getElementsByClassName("rightlinks3");
    for (i = 0; i < rightlinks.length; i++) {
      rightlinks[i].style.display = "none";
    }
    leftlinks = document.getElementsByClassName("leftlinks3");
    for (i = 0; i < leftlinks.length; i++) {
      leftlinks[i].className = leftlinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  };
  return (
    <div className="menu-container">
      <div className="main-menu header-nav-wrapper">
        <div className="dropdown">
          {" "}
          <NavLink to={`/consultare`} className="dropbtn">
            Consultare <i className="fa fa-caret-down" />{" "}
          </NavLink>
          <div className="dropdown-content">
            <div className="row">
              <div className="left-section">
                <div className="top-block dblue-divider">
                  {" "}
                  <span className="header dblue">
                    Con
                    <br />
                    sul
                    <br />
                    tare
                  </span>{" "}
                  <span className="description">
                    Consultare means "Experts to consult" on Customer Digital
                    Strategy, Operational efficiency, and Digital Workplace
                    Transformation.
                  </span>
                  <NavLink to={`/consultare`} className="dblue">
                    Explore More <i className="fa fa-long-arrow-right" />
                  </NavLink>
                </div>
                <div className="bottom-block">
                  <ul>
                    <li>
                      <figure>
                        <NavLink to="https://www.vdc.com" target="_blank">
                          <img
                            style={{
                              width: "12rem",
                              marginBottom: "6rem",
                              marginLeft: "-3rem",
                            }}
                            src={vdc}
                            alt="vdc Logo"
                          />
                        </NavLink>
                      </figure>
                    </li>
                    {/* <li>
                                            <figure>
                                                <NavLink to="http://www.vivacis.com" target="_blank">
                                                    <img
                                                        src={`/img/svg/icons/vivacis-logo.svg`}
                                                        alt="Vivacis Logo"
                                                    />
                                                </NavLink>
                                            </figure>
                                        </li>
                                        <li>
                                            <figure>
                                                <NavLink to="http://www.veracis.com" target="_blank">
                                                    <img
                                                        src={`/img/svg/icons/veracis-logo.svg`}
                                                        alt="Veracis Logo"
                                                    />

                                                </NavLink>
                                            </figure>
                                        </li>
                                        <li>
                                            <figure>
                                                <NavLink to="http://www.vedam.com" target="_blank">
                                                    <img
                                                        src={`/img/svg/icons/vedam-logo.svg`}
                                                        alt="Vedam Logo"
                                                    />

                                                </NavLink>
                                            </figure>
                                        </li> */}
                  </ul>
                </div>
              </div>
              <div className="mid-section">
                <div className="consultare-menu-container">
                  <div className="left-block">
                    <ul>
                      <li
                        className="leftlinks first"
                        // onmouseover="openRightLinks(event, 'consultare1')"
                        onClick={() =>
                          openRightLink("consultare", "consultare1")
                        }
                        onMouseOver={() =>
                          openRightLink("consultare", "consultare1")
                        }
                      >
                        <div className="txt">
                          <NavLink to={`/crm-digital/`} className="dblue">
                            CRM DIGITAL
                          </NavLink>
                          <span className="description">
                            CRM Mastermind Design, Digital strategy,
                            Implementation, and Professional Services
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink
                            to="http://www.crmdigital.com"
                            target="_blank"
                          >
                            <img
                              src={`/img/svg/icons/crm-logo.svg`}
                              alt="CRM logo"
                              style={{ width: 56 }}
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks"
                        // onmouseover="openRightLinks(event, 'consultare2')"
                        onClick={() =>
                          openRightLink("consultare", "consultare2")
                        }
                        onMouseOver={() =>
                          openRightLink("consultare", "consultare2")
                        }
                      >
                        <div className="txt">
                          <NavLink to={`/cx/`} className="dblue">
                            CUSTOMER EXPERIENCE
                          </NavLink>

                          <span className="description">
                            World's first "CX and Digital" Fusion of
                            technologies and transformation services
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to="http://cx.digital" target="_blank">
                            <img
                              src={`/img/svg/icons/cx-logo.svg`}
                              alt="CX logo"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks"
                        onClick={() =>
                          openRightLink("consultare", "consultare3")
                        }
                        onMouseOver={() =>
                          openRightLink("consultare", "consultare3")
                        }
                      >
                        <div className="txt">
                          <NavLink to={`/ex/`} className="dblue">
                            EMPLOYEE EXPERIENCE
                          </NavLink>

                          <span className="description">
                            HR Technologies, Digital WorkPlace Programs, Talent
                            Management, and EX Strategy
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to="http://ex.digital" target="_blank">
                            <img
                              src={`/img/svg/icons/ex-logo.svg`}
                              alt="EX logo"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks"
                        onClick={() =>
                          openRightLink("consultare", "consultare4")
                        }
                        onMouseOver={() =>
                          openRightLink("consultare", "consultare4")
                        }
                      >
                        <div className="txt">
                          <NavLink to={`/xx-digital`} className="dblue">
                            EVERYTHING EXPERIENCE
                          </NavLink>

                          <span className="description">
                            The Hyper Automation, DevOps, and Low-Code No-Code
                            Technologies
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to="http://xx.digital" target="_blank">
                            <img
                              src={`/img/svg/icons/xx-logo.svg`}
                              alt="XX logo"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks"
                        onClick={() =>
                          openRightLink("consultare", "consultare5")
                        }
                        onMouseOver={() =>
                          openRightLink("consultare", "consultare5")
                        }
                      >
                        <div className="txt">
                          <NavLink to={`/alliances/`} className="dblue">
                            KEY PARTNERS
                          </NavLink>

                          <span className="description">
                            Experience Partnerships (PX) with leading brands
                            offering software/platforms
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/alliances/`}>
                            <img
                              src={`/img/svg/icons/key-partners-menu-icon.svg`}
                              alt="Key Partners"
                            />
                          </NavLink>
                        </div>

                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                    </ul>
                  </div>
                  <div className="right-block">
                    <div
                      id="consultare1"
                      className="rightlinks"
                      style={{
                        display:
                          showConsultareLinks === "consultare1"
                            ? "block"
                            : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/crm-digital/`}>
                            About CRM Digital
                          </NavLink>{" "}
                          <span className="description">
                            Digital CRM Applications — Sales, Service,
                            Marketing, and Commerce
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/crm-digital/sales`}>
                            Sales
                          </NavLink>{" "}
                          <span className="description">
                            Sales Automation, CPQ, and Performance tools
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/crm-digital/service`}>
                            SERVICE
                          </NavLink>{" "}
                          <span className="description">
                            Omni-channel Customer Service and Field Service
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/crm-digital/marketing/`}>
                            MARKETING
                          </NavLink>
                          <span className="description">
                            Multi-channel marketing mix of technologies
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/crm-digital/commerce/`}>
                            DIGITAL COMMERCE
                          </NavLink>{" "}
                          <span className="description">
                            Digital Commerce Implementation and digital
                            strategies
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/consultare/professional-services/`}>
                            CRM PROFESSIONAL SERVICES
                          </NavLink>{" "}
                          <span className="description">
                            CRM Implementation, Integration, and Customization
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/c-digital`}>
                            C.DIGITAL MASTERMIND
                          </NavLink>{" "}
                          <span className="description">
                            Business 4.0 Augmented CRM Mastermind Strategy
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div
                      id="consultare2"
                      className="rightlinks"
                      style={{
                        display:
                          showConsultareLinks === "consultare2"
                            ? "block"
                            : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/cx/`}>
                            About CX.Digital Practice
                          </NavLink>{" "}
                          <span className="description">
                            Customer Experience Management (CXM), Services, and
                            Operations
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cx/strategy-design/`}>
                            CX STRATEGY
                          </NavLink>{" "}
                          <span className="description">
                            Road map to actualize, deliver, and measure CX
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/rainbowcx/`}>
                            CX TECHNOLOGIES
                          </NavLink>{" "}
                          <span className="description">
                            RainbowCX Technologies for every business need
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cxnow/`}>
                            CX TRANSFORMATION
                          </NavLink>{" "}
                          <span className="description">
                            CXNow Program to deliver CX driven Outcomes
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cxdesk/`}>CX OPERATIONS</NavLink>{" "}
                          <span className="description">
                            Blended, multi-talent, and hybrid CX tech service
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/vaves/`}>VIRTUOS VAVES</NavLink>{" "}
                          <span className="description">
                            Virtuos Vaves — CX Practice offering Solutions from
                            leading Partners.
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cxnow/cxprimer/`}>
                            CXPRIMER FREE WORKSHOP
                          </NavLink>{" "}
                          <span className="description">
                            For a limited period CXPrimer is FREE for new
                            customers
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div
                      id="consultare3"
                      className="rightlinks"
                      style={{
                        display:
                          showConsultareLinks === "consultare3"
                            ? "block"
                            : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/ex/`}>
                            About EX.Digital Practice
                          </NavLink>{" "}
                          <span className="description">
                            Employee Experience (EX), Talent Management, Digital
                            WorkPlace
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/ex/strategy-design/`}>
                            EX STRATEGY
                          </NavLink>{" "}
                          <span className="description">
                            Employee engagement to improve Operational CX
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/insidex`}>
                            HCM TECHNOLOGIES
                          </NavLink>{" "}
                          <span className="description">
                            Technologies for CHROs to manage talent lifecycle
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/smartsheet`}>
                            WORK MANAGEMENT PLATFORM
                          </NavLink>{" "}
                          <span className="description">
                            Virtuos and Smartsheet present Powerful Work
                            Platform.
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/w-digital`}>
                            DIGITAL WORKPLACE
                          </NavLink>{" "}
                          <span className="description">
                            W.Digital — the mastermind program for workplace
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/talentare`}>TALENTARE</NavLink>{" "}
                          <span className="description">
                            Enterprise Talent Acquisition and Management
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      id="consultare4"
                      className="rightlinks"
                      style={{
                        display:
                          showConsultareLinks === "consultare4"
                            ? "block"
                            : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/it`}>
                            About XX.Digital Practice
                          </NavLink>{" "}
                          <span className="description">
                            Everything IT Automation, Solutions, Products, and
                            Services.
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/nexorama/#uncodify`}>
                            UNCODIFY — THE LOW-CODE NO-CODE
                          </NavLink>{" "}
                          <span className="description">
                            Solve Complex Business Challenges with LowCode
                            Platform
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/vivacis`}>
                            VIVACIS — DIGITAL AUTOMATION
                          </NavLink>
                          <span className="description">
                            Leading CRM, BPM, and Contract LifeCycle Management
                            (CLM)
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/veracis/`} target="_blank">
                            VERACIS — THE DIGITAL OPERATIONS
                          </NavLink>{" "}
                          <span className="description">
                            Customer Trust powered by O.Digital — The mastermind
                            Ops Model
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cxdesk/`}>
                            CXDESK — THE CX MANAGEMENT
                          </NavLink>{" "}
                          <span className="description">
                            Real-time Customer Experience Management (CXM)
                            Operations
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/consultare/engineering-services/`}>
                            DIGITAL ENGINEERING
                          </NavLink>{" "}
                          <span className="description">
                            Virtuos.dev — Software and Application Engineering
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cloud/`}>VIRTUOS CLOUD</NavLink>{" "}
                          <span className="description">
                            Complete spectrum of Cloud Technologies and Services
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/right-apps/`}>RIGHT APPS</NavLink>{" "}
                          <span className="description">
                            Progressive Web Apps (PWA), Apps for IOS, and
                            Android
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div
                      id="consultare5"
                      className="rightlinks"
                      style={{
                        display:
                          showConsultareLinks === "consultare5"
                            ? "block"
                            : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/oracle/`}>ORACLE</NavLink>{" "}
                          <span className="description">
                            Oracle CX, HCM, ERP, PaaS, and Other Cloud
                            Technologies
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/salesforce/`}>SALESFORCE</NavLink>{" "}
                          <span className="description">
                            Salesforce Sales, Service, Marketing, and Platform
                            Services
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/microsoft/`}>MICROSOFT</NavLink>{" "}
                          <span className="description">
                            Microsoft Dynamics CRM, BI, PowerApps, and Azure
                            Cloud
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/creatio/`}>CREATIO</NavLink>{" "}
                          <span className="description">
                            Creatio Sales, Service, Marketing, and BPM Services
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/agiloft/`}>Agiloft</NavLink>{" "}
                          <span className="description">
                            Agiloft is a market leader in Contract Lifecycle
                            Management
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/alliances/specialized/`}>
                            SPECIALIZED PARTNERS
                          </NavLink>{" "}
                          <span className="description">
                            Cool and specialized partners with cutting edge
                            technologies
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dropdown">
          {" "}
          <NavLink to={`/xonomy`} className="dropbtn">
            Xonomy <i className="fa fa-caret-down" />{" "}
          </NavLink>
          <div className="dropdown-content">
            <div className="row">
              <div className="left-section">
                <div className="top-block pink-divider">
                  {" "}
                  <span className="header pink">
                    Xo
                    <br />
                    no
                    <br />
                    my
                  </span>{" "}
                  <span className="description">
                    The Innovation and incubation division foraying into digital
                    technologies and products in the experience economy
                    (xonomy).
                  </span>{" "}
                  <NavLink to={`/xonomy`} className="pink">
                    Explore More <i className="fa fa-long-arrow-right" />
                  </NavLink>{" "}
                </div>
                <div className="bottom-block">
                  <ul>
                    <li>
                      <figure>
                        <NavLink to="https://www.giftcart.com" target="_blank">
                          <img
                            src={`/img/svg/icons/giftcart-logo.svg`}
                            alt="Giftcart Logo"
                          />
                        </NavLink>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <NavLink to="http://www.indic.com" target="_blank">
                          <img
                            src={`/img/svg/icons/indic-logo.svg`}
                            alt="Indic Logo"
                          />
                        </NavLink>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <NavLink to="http://www.sweven.com" target="_blank">
                          <img
                            src={`/img/svg/icons/sweven-logo.svg`}
                            alt="Sweven Logo"
                          />
                        </NavLink>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <NavLink to="http://www.tekcorp.com" target="_blank">
                          <img
                            src={`/img/svg/icons/tekcorp-logo.svg`}
                            alt="Tekcorp Logo"
                          />
                        </NavLink>
                      </figure>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mid-section">
                <div className="xonomy-menu-container">
                  <div className="left-block">
                    <ul>
                      <li
                        className="leftlinks1 first"
                        onClick={() => openRightLink("xonomy", "exonomy1")}
                        onMouseOver={() => openRightLink("xonomy", "exonomy1")}
                      >
                        <div className="txt">
                          <NavLink
                            to="http://www.giftcart.com"
                            className="pink"
                          >
                            EXPERIENCE COMMERCE BUSINESS
                          </NavLink>
                          <span className="description">
                            Virtuos Xonomy invests in GIFTCART — Gifting
                            Commerce Company.
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to="http://www.giftcart.com">
                            <img
                              src={`/img/svg/icons/ex-menu-icon.svg`}
                              alt="Experience Commerce"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks1"
                        onClick={() => openRightLink("xonomy", "exonomy2")}
                        onMouseOver={() => openRightLink("xonomy", "exonomy2")}
                      >
                        <div className="txt">
                          <NavLink
                            to="http://www.indic.com"
                            target="_blank"
                            className="pink"
                          >
                            DIGITAL EXPERIENCE PORTFOLIO
                          </NavLink>
                          <span className="description">
                            Virtuos Incubates INDIC — the Experience Brands
                            Portfolio Company.
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/salesforce/`}>
                            <img
                              src={`/img/svg/icons/dx-menu-icon.svg`}
                              alt="Digital Experience"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks1"
                        onClick={() => openRightLink("xonomy", "exonomy3")}
                        onMouseOver={() => openRightLink("xonomy", "exonomy3")}
                      >
                        <div className="txt">
                          <NavLink
                            to="http://www.sweven.com"
                            target="_blank"
                            className="pink"
                          >
                            CRM &amp; DIGITAL TRANSFORMATION
                          </NavLink>
                          <span className="description">
                            Virtuos invests in SWEVEN — CRM Mastermind focussing
                            on Salesforce.
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink
                            to="http://www.crmdigital.com"
                            target="_blank"
                          >
                            <img
                              src={`/img/svg/icons/crm-logo.svg`}
                              alt="CRM logo"
                              style={{ width: 56 }}
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks1"
                        onClick={() => openRightLink("xonomy", "exonomy4")}
                        onMouseOver={() => openRightLink("xonomy", "exonomy4")}
                      >
                        <div className="txt">
                          <NavLink
                            to="http://www.tekcorp.com"
                            target="_blank"
                            className="pink"
                          >
                            CLOUD &amp; IT INFRASTRUCTURE
                          </NavLink>
                          <span className="description">
                            Virtuos invests in TEKCORP — Cloud Mastermind &amp;
                            IT Infrastructure Specialist.
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to="http://tekcorp.com" target="_blank">
                            <img
                              src={`/img/svg/icons/exp-cloud-crowd-icon.svg`}
                              alt="Cloud and IT Infrastructure"
                            />
                          </NavLink>
                        </div>
                      </li>
                      <li
                        className="leftlinks1"
                        onClick={() => openRightLink("xonomy", "exonomy5")}
                        onMouseOver={() => openRightLink("xonomy", "exonomy5")}
                      >
                        <div className="txt">
                          <NavLink to={`/nexorama/`} className="pink">
                            VIRTUOS NEXORAMA
                          </NavLink>
                          <span className="description">
                            Nexorama is our new model for innovating and
                            diversifying into new areas.
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/nexorama/`}>
                            <img
                              src={`/img/svg/icons/nexorama-icon.svg`}
                              alt="Virtuos Nexorama"
                              style={{ width: 30 }}
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                    </ul>
                  </div>
                  <div className="right-block">
                    <div
                      id="exonomy1"
                      className="rightlinks1"
                      style={{
                        display:
                          showExonomyLinks === "exonomy1" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to="http://www.giftcart.com">
                            ABOUT GIFTCART.COM
                          </NavLink>{" "}
                          <span className="description">
                            With huge strengths in technology, experience
                            design, strong commitment and insight to make
                            Giftcart.com a leading name in e-gifting globally,
                            the team has put together some of the industry's
                            first and unique propositions such as Personality
                            based gifting, Gift Wizard, Gift Registry, Wish
                            list, Event Butler, Advanced Search and more.
                            <br />
                            Visit{" "}
                            <NavLink
                              to="http://www.giftcart.com"
                              target="_blank"
                              style={{ textTransform: "none", marginTop: 10 }}
                            >
                              www.giftcart.com
                            </NavLink>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      id="exonomy2"
                      className="rightlinks1"
                      style={{
                        display:
                          showExonomyLinks === "exonomy2" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to="http://www.indic.com">
                            ABOUT INDIC.COM
                          </NavLink>{" "}
                          <span className="description">
                            Indic is a company that has digital experience
                            platforms to serve a broad range of audiences with
                            consistent, secure and personalized access to
                            products, services, and experiences across many
                            digital touchpoints.
                            <br />
                            <br />
                            Indic has a portfolio of brands in stealth mode.{" "}
                            <br />
                            For more information visit{" "}
                            <NavLink
                              to="http://www.indic.com"
                              target="_blank"
                              style={{ textTransform: "none", marginTop: 10 }}
                            >
                              www.indic.com
                            </NavLink>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      id="exonomy3"
                      className="rightlinks1"
                      style={{
                        display:
                          showExonomyLinks === "exonomy3" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to="http://www.sweven.com" target="_blank">
                            ABOUT SWEVEN DIGITAL
                          </NavLink>
                          <span className="description">
                            Sweven is a brand new company with 1000+ Man years
                            of CRM and Customer Experience Proficiency. Sweven
                            branched out to craft a new story of Sweveners — The
                            dreamers and doers operating in the US$1 Trillion
                            Salesforce Economy. Sweven is headquartered in San
                            Francisco with operations and delivery centers in
                            India and fast expanding into other regions. <br />
                            For more information visit{" "}
                            <NavLink
                              to="http://www.sweven.com"
                              target="_blank"
                              style={{ textTransform: "none", marginTop: 10 }}
                            >
                              www.sweven.com
                            </NavLink>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      id="exonomy4"
                      className="rightlinks1"
                      style={{
                        display:
                          showExonomyLinks === "exonomy4" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to="http://www.tekcorp.com" target="_blank">
                            ABOUT TEKCORP
                          </NavLink>
                          <span className="description">
                            Tekcorp offers complete Cloud Transformation
                            Journeys for the customers along with IT
                            Infrastructure Services such as Networking, Storage,
                            Security, and Trust Management across Industry
                            verticals. Tekcorp also operates Technology Division
                            in alliance with leading partners.
                            <br />
                            For more information visit{" "}
                            <NavLink
                              to="http://www.tekcorp.com"
                              target="_blank"
                              style={{ textTransform: "none", marginTop: 10 }}
                            >
                              www.tekcorp.com
                            </NavLink>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      id="exonomy5"
                      className="rightlinks1"
                      style={{
                        display:
                          showExonomyLinks === "exonomy5" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          <NavLink to={`/io/`}>INNOVATION ON — THE LAB</NavLink>
                          <span className="description">
                            Ideas to Value with "Innovation On" Lab.
                          </span>
                        </li>
                        <li>
                          <NavLink to={`/ventures/`}>VIRTUOS VENTURES</NavLink>
                          <span className="description">
                            Incubating and investing in startups and our own
                            portfolio.
                          </span>
                        </li>
                        <li>
                          <NavLink to={`/nexorama/`}>WUNDERO</NavLink>
                          <span className="description">
                            WUNDERO is our new Democratized and Agile E-Learning
                            Platform.
                          </span>
                        </li>
                        <li>
                          <NavLink to={`/nexorama/`}>
                            VIRTUOS EXPEDIENCE
                          </NavLink>
                          <span className="description">
                            Global Talent Management and Advisory Services.
                          </span>
                        </li>
                        <li>
                          <NavLink to={`/`}>VIRTUOS CRM STUDIO</NavLink>
                          <span className="description">
                            CRM Studio is the No Code CRM Platform for
                            Industries.
                          </span>
                        </li>{" "}
                        <li>
                          <NavLink to={"https://www.brandcart.com"}>
                            BRANDCART.COM
                          </NavLink>
                          <span className="description">
                            Brandcart is the New Age Brand Naming Platform
                          </span>
                        </li>{" "}
                        <li>
                          <NavLink to={`/novv`}>NOVV DX Platform</NavLink>
                          <span className="description">
                            NOVV is the Modern Digital Transformation Platform.
                          </span>
                        </li>{" "}
                        <li>
                          <NavLink to={`/insidex`}>INSIDEX EX PLATFORM</NavLink>
                          <span className="description">
                            InsideX is the Employee Experience Platform
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dropdown">
          {" "}
          <NavLink to={`/business`} className="dropbtn">
            The Business<sup style={{ fontSize: "10px" }}>(9)</sup>{" "}
            <i className="fa fa-caret-down" />{" "}
          </NavLink>
          <div className="dropdown-content">
            <div className="row">
              <div className="left-section">
                <div className="top-block purple-divider">
                  {" "}
                  <span className="header purple">
                    BUS
                    <br />
                    INE
                    <br />
                    SS
                  </span>{" "}
                  <span className="description">
                    The anatomy of Virtuos Business — Consultare: Consulting
                    Services; and Xonomy: The Experience Economy Business.
                  </span>{" "}
                  <NavLink to={`/business`} className="dblue">
                    Explore More <i className="fa fa-long-arrow-right" />
                  </NavLink>{" "}
                </div>
                <div className="bottom-block img">
                  <figure>
                    <img
                      src={`/img/svg/business-menu-img.svg`}
                      alt="Business Navigation Image"
                    />
                  </figure>
                </div>
              </div>
              <div className="mid-section">
                <div className="business-menu-container">
                  <div className="left-block">
                    <ul>
                      <li
                        className="leftlinks3 first"
                        onClick={() => openRightLink("business", "business1")}
                        onMouseOver={() =>
                          openRightLink("business", "business1")
                        }
                      >
                        <div className="txt">
                          <NavLink to={`/business`} className="purple">
                            Business<sup style={{ fontSize: "10px" }}>(9)</sup>{" "}
                            by Needs
                          </NavLink>
                          <span className="description">
                            CRM, Cloud, Digital, and IT Solutions for Small to
                            Large Businesses.
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/business`}>
                            <img
                              src={`/img/svg/icons/business-n-icon.svg`}
                              alt="Business by Needs"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks3"
                        onClick={() => openRightLink("business", "business2")}
                        onMouseOver={() =>
                          openRightLink("business", "business2")
                        }
                      >
                        <div className="txt">
                          <NavLink to={`/solutions/`} className="purple">
                            SOLUTIONS BY FUNCTION
                          </NavLink>
                          <span className="description">
                            CX, EX, and IT Solutions for Sales, Marketing,
                            Finance, Operations, etc.
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/solutions/`}>
                            <img
                              src={`/img/svg/icons/solution-menu-icon.svg`}
                              alt="Solution by Function"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks3"
                        onClick={() => openRightLink("business", "business3")}
                        onMouseOver={() =>
                          openRightLink("business", "business3")
                        }
                      >
                        <div className="txt">
                          <NavLink to={`/industry/`} className="purple">
                            INDUSTRIES
                          </NavLink>
                          <span className="description">
                            Modern technologies, solutions, and services
                            tailored for Industry verticals.
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/industry/`}>
                            <img
                              src={`/img/svg/icons/industry-menu-icon.svg`}
                              alt="Solution by Industry"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks3"
                        onClick={() => openRightLink("business", "business4")}
                        onMouseOver={() =>
                          openRightLink("business", "business4")
                        }
                      >
                        <div className="txt">
                          <NavLink to={`/business4-0/`} className="purple">
                            INNOVATION INSIGHT &amp; STRATEGY
                          </NavLink>
                          <span className="description">
                            Technology Strategies and Insights for Executive
                            Leadership and Management.{" "}
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/business4-0/`}>
                            <img
                              src={`/img/svg/icons/innovation-menu-icon.svg`}
                              alt="Innovation Insight and Strategy"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks3"
                        onClick={() => openRightLink("business", "business5")}
                        onMouseOver={() =>
                          openRightLink("business", "business5")
                        }
                      >
                        <div className="txt">
                          <NavLink to={`/alliances/`} className="purple">
                            PARTNER SOLUTIONS
                          </NavLink>
                          <span className="description">
                            Certified and top-notch Partner Solutions for CRM,
                            HCM, and Other Technologies.
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/alliances/`}>
                            <img
                              src={`/img/svg/icons/handshake-menu-icon.svg`}
                              alt="Partner Solutions"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                    </ul>
                  </div>
                  <div className="right-block">
                    <div
                      id="business1"
                      className="rightlinks3"
                      style={{
                        display:
                          showBusinessLinks === "business1" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/crm-digital/`}>
                            CRM TECHNOLOGIES
                          </NavLink>{" "}
                          <span className="description">
                            Sales, Marketing, Service, and Commerce Applications
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cx-digital/`}>
                            CUSTOMER EXPERIENCE
                          </NavLink>{" "}
                          <span className="description">
                            Customer Experience Transformation and Optimization
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/vivacis/`}>
                            DIGITAL AUTOMATION
                          </NavLink>{" "}
                          <span className="description">
                            DPA Applications, ERP, Contracts Lifecycle, and RPA
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/ex-digital/`}>
                            EMPLOYEE EXPERIENCE
                          </NavLink>{" "}
                          <span className="description">
                            Employee Engagement, Talent, Digital Workplace, and
                            More
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/it/`}>
                            CLOUD AND IT SERVICES
                          </NavLink>{" "}
                          <span className="description">
                            Cloud Adoption, IT Solutions, Products, and Services
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/veracis/`}>
                            CUSTOMER TRUST OPERATIONS
                          </NavLink>
                          <span className="description">
                            Post-COVID Business Process Operations improving
                            Customer Trust
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      id="business2"
                      className="rightlinks3"
                      style={{
                        display:
                          showBusinessLinks === "business2" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/solutions#sales`}>Sales</NavLink>{" "}
                          <span className="description">
                            SFA, Sales Performance, Quote to Order Management
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/solutions#marketing`}>
                            Marketing
                          </NavLink>{" "}
                          <span className="description">
                            Digital Multi-channel Marketing, campaigns, and more
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/solutions#service`}>
                            Service
                          </NavLink>{" "}
                          <span className="description">
                            Omni-channel Service, Contact center Tech, and more
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/solutions#commerce`}>
                            Digital Commerce
                          </NavLink>
                          <span className="description">
                            Digital Commerce Implementation and digital
                            strategies
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/o-digital/`}>Operations</NavLink>{" "}
                          <span className="description">
                            Supply Chain, Training, and operational excellence
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/vivacis#oracle-netsuite`}>
                            Finance
                          </NavLink>{" "}
                          <span className="description">
                            ERP, Financial Planning, Budgeting, and Cost
                            controls
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/insidex`}>
                            HR &amp; DIGITAL WORKPLACE
                          </NavLink>
                          <span className="description">
                            HR Technologies, Digital Workplace programs, and
                            more
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/it`}>IT and Automation</NavLink>{" "}
                          <span className="description">
                            Digital Process Automation, IT Services, DevOps, and
                            more
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div
                      id="business3"
                      className="rightlinks3"
                      style={{
                        display:
                          showBusinessLinks === "business3" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          <NavLink to={`/industry#indus-banking`}>
                            Banking &amp; Finance
                          </NavLink>{" "}
                          <span className="description">
                            IT Solutions for Banking, Insurance, and Financial
                            Services
                          </span>{" "}
                        </li>
                        <li>
                          <NavLink to={`/industry#indus-comm`}>
                            Communications
                          </NavLink>
                          <span className="description">
                            CX to CRM, HRTech to EX, and Digital Solutions for
                            Telecom
                          </span>
                        </li>
                        <li>
                          <NavLink to={`/industry#indus-goods`}>
                            Consumer Goods
                          </NavLink>
                          <span className="description">
                            Solutions for Manufacturing, Consumer durables
                            industries
                          </span>
                        </li>
                        <li>
                          <NavLink to={`/industry#indus-education`}>
                            Education
                          </NavLink>
                          <span className="description">
                            CRM, Knowledge Management, and Campus Management
                          </span>
                        </li>
                        <li>
                          <NavLink to={`/industry#indus-entertain`}>
                            Entertainment
                          </NavLink>
                          <span className="description">
                            Business Applications for Internet businesses in
                            this space
                          </span>
                        </li>
                        <li>
                          <NavLink to={`/industry#indus-health`}>
                            Healthcare
                          </NavLink>{" "}
                          <span className="description">
                            Patient Experience, IT Automation, and Solutions
                          </span>{" "}
                        </li>
                        <li>
                          <NavLink to={`/industry#indus-hitech`}>
                            Hitech &amp; Internet
                          </NavLink>{" "}
                          <span className="description">
                            Solutions for BPOs, Startups, Internet, and Tech
                            companies
                          </span>{" "}
                        </li>
                        <li>
                          <NavLink to="http://www.viraata.com" target="_blank">
                            GOV. BUSINESS BY VIRAATA
                          </NavLink>{" "}
                          <span className="description">
                            Technologies and Business Solutions for State and
                            Central Gov.
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div
                      id="business4"
                      className="rightlinks3"
                      style={{
                        display:
                          showBusinessLinks === "business4" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/business4-0/`}>
                            BUSINESS 4.0 STRATEGY
                          </NavLink>
                          <span className="description">
                            Global Expansion, Digital Dexterity, and Industry
                            Best practices
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cxnow/`}>
                            CX DRIVEN DIGITAL TRANSFORMATION
                          </NavLink>{" "}
                          <span className="description">
                            Customer Experience As A Program for growth
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cx/strategy-design/`}>
                            CX STRATEGY
                          </NavLink>{" "}
                          <span className="description">
                            Customer Experience Strategy, Design, and Consulting
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/ex/strategy-design/`}>
                            EX STRATEGY
                          </NavLink>{" "}
                          <span className="description">
                            Employee Experience Strategy, Design, and Consulting
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/it/`}>ENTERPRISE IT</NavLink>{" "}
                          <span className="description">
                            IT and Digital Process Automation Strategies and
                            Consulting
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/io/`}>
                            INNOVATION FRAMEWORK
                          </NavLink>{" "}
                          <span className="description">
                            Ideas and Insights for the Industry, Business, and
                            Economy
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/c-digital/`}>CRM MASTERMIND™</NavLink>
                          <span className="description">
                            Outstanding Customer Digital Strategy Framework for
                            Transformation
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cloud/`}>
                            Cloud MASTERMIND™
                          </NavLink>{" "}
                          <span className="description">
                            Cloud First and Cloud Only Strategy transforming
                            business models
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div
                      id="business5"
                      className="rightlinks3"
                      style={{
                        display:
                          showBusinessLinks === "business5" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/oracle/`}>Oracle</NavLink>{" "}
                          <span className="description">
                            Service Cloud, Engagement Cloud, Marketing, and
                            NetSuite
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/salesforce/`}>Salesforce</NavLink>{" "}
                          <span className="description">
                            Sales Cloud, Service Cloud, Marketing Cloud, and
                            Platform
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/microsoft/`}>Microsoft</NavLink>{" "}
                          <span className="description">
                            Dynamics CRM, Power Apps, and Power Consulting
                            Services
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/creatio/`}>Creatio</NavLink>{" "}
                          <span className="description">
                            Sales, Service, Marketing, Studio, and Digital
                            Automation
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/agiloft/`}>Agiloft</NavLink>{" "}
                          <span className="description">
                            Contract Lifecycle Management (CLM) and ITIL
                            Automation
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/alliances/specialized/`}>
                            Other Partner Solutions
                          </NavLink>{" "}
                          <span className="description">
                            CRM, CLM, HCM, ERP, and Digital Process Automation
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dropdown">
          {" "}
          <NavLink to={`/corporate`} className="dropbtn">
            The Company <i className="fa fa-caret-down" />{" "}
          </NavLink>
          <div className="dropdown-content">
            <div className="row">
              <div className="left-section">
                <div className="top-block blue-divider">
                  {" "}
                  <span className="header blue">
                    Com
                    <br />
                    pa
                    <br />
                    ny
                  </span>{" "}
                  <span className="description">
                    Company with a team that has the explicit mission to change
                    the world — big change, big objectives, a brand-new system
                    never done before.
                  </span>{" "}
                  <NavLink to={`/corporate`} className="blue">
                    Explore More <i className="fa fa-long-arrow-right" />
                  </NavLink>{" "}
                </div>
                <div className="bottom-block img">
                  <figure>
                    <img
                      src={`/img/svg/the-company-menu-img.svg`}
                      alt="Company Menu Image"
                    />
                  </figure>
                </div>
              </div>
              <div className="mid-section">
                <div className="company-menu-container">
                  <div className="left-block">
                    <ul>
                      <li
                        className="leftlinks2 first"
                        // onmouseover="openRightLinks2(event, 'company1')"
                        onClick={() => openRightLink("company", "company1")}
                        onMouseOver={() => openRightLink("company", "company1")}
                      >
                        <div className="txt">
                          <NavLink to={`/corporate`} className="blue">
                            ABOUT VIRTUOS
                          </NavLink>
                          <span className="description">
                            The birth of an idea that stretches the Virtuosos
                            and the customer
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/corporate`}>
                            <img
                              src={`/img/svg/icons/virtuos-heart.svg`}
                              alt="About Virtuos"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks2"
                        onClick={() => openRightLink("company", "company2")}
                        onMouseOver={() => openRightLink("company", "company2")}
                      >
                        <div className="txt">
                          <NavLink to={`/business`} className="blue">
                            OUR BUSINESS
                          </NavLink>
                          <span className="description">
                            The anatomy of modern business at Virtuos —
                            Consulting to Digital Business
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/business`}>
                            <img
                              src={`/img/svg/icons/our-business-menu-icon.svg`}
                              alt="Our Business"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks2"
                        onClick={() => openRightLink("company", "company3")}
                        onMouseOver={() => openRightLink("company", "company3")}
                      >
                        <div className="txt">
                          <NavLink to={`/customers/`} className="blue">
                            CUSTOMER SUCCESS
                          </NavLink>
                          <span className="description">
                            Customer success stories, case studies, insights,
                            and resources
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/customers/`}>
                            <img
                              src={`/img/svg/icons/customer-success-menu-icon.svg`}
                              alt="Customers"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks2"
                        onClick={() => openRightLink("company", "company4")}
                        onMouseOver={() => openRightLink("company", "company4")}
                      >
                        <div className="txt">
                          <NavLink to={`/careers/`} className="blue">
                            CAREERS
                          </NavLink>
                          <span className="description">
                            Explore an enriching and rewarding career in sales,
                            marketing, engineering, etc.
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/careers/`}>
                            <img
                              src={`/img/svg/icons/career-menu-icon.svg`}
                              alt="Careers"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li
                        className="leftlinks2"
                        onClick={() => openRightLink("company", "company5")}
                        onMouseOver={() => openRightLink("company", "company5")}
                      >
                        <div className="txt">
                          <NavLink to={`/news/`} className="blue">
                            NEWS AND EVENTS
                          </NavLink>
                          <span className="description">
                            Sign up for the latest news, press releases, Xonomy
                            events, and webinars
                          </span>
                        </div>
                        <div className="logo">
                          <NavLink to={`/news/`}>
                            <img
                              src={`/img/svg/icons/news-menu-icon.svg`}
                              alt="News"
                            />
                          </NavLink>
                        </div>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                    </ul>
                  </div>
                  <div className="right-block">
                    <div
                      id="company1"
                      className="rightlinks2"
                      style={{
                        display:
                          showCompanyLinks === "company1" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/corporate`}>
                            Digital First Business
                          </NavLink>
                          <span className="description">
                            Creation of new value chains and business
                            opportunities
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/corporate/story`}>
                            OUR BRAND STORY
                          </NavLink>
                          <span className="description">
                            Inspired thinking behind brand Virtuos
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/corporate/#leadership`}>
                            LEADERSHIP
                          </NavLink>
                          <span className="description">
                            Setting standards of excellence and high integrity
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/corporate/team`}>
                            VIRTUOSO TEAMS
                          </NavLink>{" "}
                          <span className="description">
                            Play by a different set of rules than other teams do
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/virtuosoship`}>
                            VIRTUOSOSHIP
                          </NavLink>{" "}
                          <span className="description">
                            Enterprisewide Employee Digital Dexterity
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/investors`}>
                            INVESTOR RELATIONS
                          </NavLink>{" "}
                          <span className="description">
                            Virtuos is expanding its footprint and horizons
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/corporate/#vision`}>
                            ONE VOW
                          </NavLink>{" "}
                          <span className="description">
                            OneVow tied to Virtuos vision, mission, and values
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/org/`}>VIRTUOS.ORG</NavLink>{" "}
                          <span className="description">
                            Social impact with 1-2-3 Edge model
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div
                      id="company2"
                      className="rightlinks2"
                      style={{
                        display:
                          showCompanyLinks === "company2" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/business`}>
                            The Business<sup>(n)</sup>
                          </NavLink>
                          <span className="description">
                            Business landscape under Consultare and Xonomy
                            stable
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/cx-digital/`}>
                            CX.DIGITAL PRACTICE
                          </NavLink>{" "}
                          <span className="description">
                            CRM and Customer Experience (CX) Consulting
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/ex-digital/`}>
                            EX.DIGITAL PRACTICE
                          </NavLink>{" "}
                          <span className="description">
                            HCM and Employee Experience (EX) Consulting
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/xx-digital/`}>
                            XX.DIGITAL PRACTICE
                          </NavLink>{" "}
                          <span className="description">
                            Automation, Technology, Infrastrcuture, and
                            Operations
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/consultare/`}>CONSULTARE</NavLink>{" "}
                          <span className="description">
                            High Performance Consulting and Professional
                            Services
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/xonomy`}>XONOMY</NavLink>{" "}
                          <span className="description">
                            Experience Economy (Xonomy) Business
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/community`} target="_blank">
                            COMMUNITY
                          </NavLink>
                          <span className="description">
                            Creating Shared Value (CSV) initiative with
                            community
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      id="company3"
                      className="rightlinks2"
                      style={{
                        display:
                          showCompanyLinks === "company3" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/customers`}>
                            OUR CUSTOMERS
                          </NavLink>{" "}
                          <span className="description">
                            Sample list of our customers with their success
                            stories
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/corporate/experience-edge/`}>
                            EXPERIENCE EDGE
                          </NavLink>{" "}
                          <span className="description">
                            Obsessed with new ideas, skills, and Pace Layered
                            Strategy
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/succeedant`}>SUCCEEDANT</NavLink>{" "}
                          <span className="description">
                            Customer Success and Best Practices in Business
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/insights/`}>
                            INSIGHTS AND RESOURCES
                          </NavLink>
                          <span className="description">
                            Download whitepapers, collaterals, and other
                            information
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/customers#success-stories`}>
                            CUSTOMER CASE STUDIES
                          </NavLink>{" "}
                          <span className="description">
                            Customer references and case studies
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div
                      id="company4"
                      className="rightlinks2"
                      style={{
                        display:
                          showCompanyLinks === "company4" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/careers/`}>
                            CAREER WITH VIRTUOS
                          </NavLink>{" "}
                          <span className="description">
                            Learn how we develop people and offer rewarding
                            careers
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/careers/opportunities/`}>
                            JOB OPPORTUNITIES
                          </NavLink>{" "}
                          <span className="description">
                            Find out new job openings and apply
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/careers/experience-job/`}>
                            EXPERIENCEJOB
                          </NavLink>
                          <span className="description">
                            New "Jobs to be done" theory applying "experience
                            Quotient"
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/careers/culture/`}>
                            CULTURE AND VALUES
                          </NavLink>
                          <span className="description">
                            Happiests Culture with deep embedded values
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/careers/life-at-virtuos/`}>
                            LIFE AT VIRTUOS
                          </NavLink>{" "}
                          <span className="description">
                            Workforce development, employee benefits, and more
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="http://www.opencoder.com"
                            target="_blank"
                          >
                            FREELANCERS
                          </NavLink>{" "}
                          <span className="description">
                            Register to become a freelancer and earn while
                            working
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div
                      id="company5"
                      className="rightlinks2"
                      style={{
                        display:
                          showCompanyLinks === "company5" ? "block" : "none",
                      }}
                    >
                      <ul>
                        <li>
                          {" "}
                          <NavLink to={`/news/`}>
                            VIRTUOS IN THE NEWS
                          </NavLink>{" "}
                          <span className="description">
                            Gain access to the latest News and Press releases
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/news/events/`}>EVENTS</NavLink>{" "}
                          <span className="description">
                            Digital, Virtual, and in-person events throughout
                            the year
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/webinars/`}>WEBINARS</NavLink>{" "}
                          <span className="description">
                            Sign up for the latest webinars and podcasts
                          </span>
                        </li>
                        <li>
                          {" "}
                          <NavLink to="http://www.xonomy.com" target="_blank">
                            XONOMY
                          </NavLink>{" "}
                          <span className="description">
                            Global Summit covering Experience Economy
                            Technologies
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="https://www.youtube.com/user/virtuoscorp"
                            target="_blank"
                            rel="noreferrer"
                          >
                            VIRTUOS.TV
                          </NavLink>{" "}
                          <span className="description">
                            Subscribe to Virtuos Youtube Channel for the latest
                            videos
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to={`/branding/`}>
                            MEDIA KIT &amp; BRAND GUIDE
                          </NavLink>
                          <span className="description">
                            Media Kit and Virtuos brand identity guidelines and
                            resources
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="new-sidebar">
        <Sidebar />
      </span>

      {/* <ul className="hamburger-menu">
                <li>
                    {" "}
                    <span className="nav-menu-icon">
                        {" "}
                        <Sidebar/>
                    </span>
                </li>
            </ul> */}
    </div>
  );
};

export default Main_nav_wrapper;
