import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import SubscribeNow from './SubscribeNow';
import NewsTwitter from '../../components/NewsTwitter';

const Virtuos_India_Closes_Financial_Year_2012 = () => {
    useEffect(() => {
        document.title = "Virtuos India closes Financial Year with record revenue and robust growth – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-india-closes-financial-year-2012-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 5 April, 2013</span>
                                <h2 className="article-heading">
                                    Virtuos India closes Financial Year with record revenue and robust
                                    growth
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos witnessed significant growth in Customer Experience (CX)
                                    Consulting practice with record revenue contributed by Cloud-based
                                    Application renewals and new acquisition of clients. All the client
                                    renewals came with an impressive 20% to 100% jump in license
                                    consumption over the last term despite a severe pricing pressure due
                                    to the US dollar surge against INR.
                                </p>
                                <p>
                                    New Customers such as Standard Chartered Bank APAC region and
                                    Myntra.com also contributed to the revenue growth. On the Professional
                                    Services side, Virtuos continues its efforts to integrate operations
                                    with Consultare teams and emerge as a leading Professional Services
                                    Consulting Company in the space of Customer Experience.
                                    Implementation, Maintenance, and Upgrade Services also contributed to
                                    the company's growth during the fiscal year.
                                </p>
                                <p>
                                    "In less than five years, we have grown Year on Year at an impressive
                                    100% growth outperforming most of our competitors in our Cloud
                                    Computing CRM space." Said Venky Vijay Reddi, the CEO of Virtuos.
                                    "With a growth trajectory like this, Virtuos will be foreseeing a
                                    quantum leap performance in the next three to five years."
                                </p>
                                <h3 className="title">About Virtuos Consultare</h3>
                                <p>
                                    Virtuos is a leading Experience Consultancy Organization based in
                                    Gurgaon, India, with interests in CRM Technologies, E-Commerce, High
                                    performance Consulting and Internet Startups.{" "}
                                </p>
                                <p>
                                    Virtuos Solutions Private Ltd. offers Customer Relationship Management
                                    Services and Cloud Computing Technologies for a variety of industry
                                    verticals. The company is based in NewDelhi/NCR with a registered
                                    office in Hyderabad, Andhra Pradesh. Virtuos is inspired by the book —
                                    Virtuoso Teams and has entered the burgeoning CRM space by addressing
                                    three essential needs of growing businesses — Sales, Customer Service,
                                    and Marketing. Virtuos has forged partnerships with leading Global
                                    Players in the CRM Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ) and Oracle (
                                    <NavLink to="http://www.oracle.com" target="_blank" className="link">
                                        www.oracle.com
                                    </NavLink>
                                    ).
                                </p>
                                <p>
                                    Virtuos has implemented CRM and Customer Experience Projects to some
                                    of the largest global brands. Our customers include: MakeMyTrip,
                                    Flipkart, Cleartrip, BookMyShow, FundTech, Canon Singapore, National
                                    University of Singapore (NUS), Singapore Institute of Management
                                    (SIM), NZPost, Telstra, Myntra, Standard Chartered Bank,
                                    GE-Healthcare, Sutherland Global, Party Gaming amongst several others.
                                </p>
                                <p>
                                    Virtuos has forayed into high performance Consulting Services
                                    integrating CX and Digital through Consultare division. For more
                                    information visit us at{" "}
                                    <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                        www.virtuos.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_India_Closes_Financial_Year_2012