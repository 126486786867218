import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import SubscribeNow from './SubscribeNow';

const Virtuos_Implements_Clicksoftware = () => {
    useEffect(() => {
        document.title = "Virtuos Implements Clicksoftware FieldService at BARC and HiCare – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-implements-clicksoftware-field-service-at-barc-and-hicare-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">Gurgaon - 31 March, 2017</span>
                                <h2 className="article-heading">
                                    Virtuos implements Clicksoftware Field Service at BARC and HiCare
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos and Clicksoftware have signed a partnership to position the
                                    latter’s industry-leading Field Service Automation (FSA) to leading
                                    customers in India. Virtuos has spent nearly US$100 on the training
                                    and certification for some of its employees to understand better niche
                                    technology and integrations offered by Clicksoftware.
                                </p>
                                <p>
                                    Clicksoftware works with Salesforce, SAP as an OEM/Private Label
                                    Partner, and yet at the same time competes with dozen other leading
                                    Field Service Players. Click software has strengths in job forecasting
                                    and shift management, scheduling and dispatch, performance
                                    measurement, and mobile functionality. ClickSoftware also optimizes
                                    the customer engagement experience for all kinds of service
                                    organizations across large industries, be it utilities, government,
                                    high tech, or telecommunications. Many of the Fortune 500 customers
                                    have automated and augmented their Field Service Operations using
                                    Clicksoftware.
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/virtuos-clicksoftware-logo.jpg`}
                                        alt="Virtuos, Clicksoftware"
                                    />
                                </figure>
                                <br />
                                <p>
                                    “Clicksoftware has stable Field Service Capabilities combined with
                                    industry leading-edge technology stack, implementation practice, and
                                    impressive clientele.” Said Venky Vijay Reddi, CEO of Virtuos. “We are
                                    excited to partner with Clicksoftware and deliver our first two
                                    projects to reputed organizations like the Broadcast Audience Research
                                    Council (BARC) and HiCare.”
                                </p>
                                <figure className="box-shadow">
                                    <img
                                        src={`/img/barc-hicare-img.jpg`}
                                        alt="Virtuos, Clicksoftware, Broadcast Audience Research Council (BARC), HiCare"
                                        useMap="#Map"
                                    />
                                    <map name="Map">
                                        <map name="Map">
                                            <area
                                                shape="rect"
                                                coords="5,5,392,345"
                                                href="/customers/barc-case-study.php"
                                                target="_blank"
                                                alt="BARC Case Study"
                                            />
                                            <area
                                                shape="rect"
                                                coords="409,5,796,346"
                                                href="javascript:void(0);"
                                                alt="HiCare Case Study"
                                            />
                                        </map>

                                    </map>
                                </figure>
                                <br />
                                <p>
                                    Virtuos is also planning to ramp up Clicksoftware practice by hiring
                                    more personnel and creating new CXDesk practice for helping customers
                                    with Industry best practices, Relationware Integration, and advanced
                                    adaptation standards. Virtuos has been taking insights on
                                    Clicksoftware with the help of Gartner. Nearly thirty calls each with
                                    a duration of 30 minutes (i.e more than 15 hours of advisory services)
                                    were made between Gartner’s Expert Analysts and Virtuos Employees on
                                    CRM, associated technologies like CLM, RPA, FSM, CPQ, etc.
                                </p>
                                <h3 className="title">About Clicksoftware</h3>
                                <p>
                                    Clicksoftware works with Salesforce, SAP as an OEM/Private Label
                                    Partner, and yet at the same time competes with dozen other leading
                                    Field Service Players. Click software has strengths in job forecasting
                                    and shift management, scheduling and dispatch, performance
                                    measurement, and mobile functionality. ClickSoftware also optimizes
                                    the customer engagement experience for all kinds of service
                                    organizations across large industries, be it utilities, government,
                                    high tech, or telecommunications. Many of the Fortune 500 customers
                                    have automated and augmented their Field Service Operations using
                                    Clicksoftware.
                                </p>
                                <h3 className="title">About Virtuos</h3>
                                <p>
                                    Virtuos is a leading Customer Experience Transformation Consulting
                                    company with its corporate headquarters in India, and the USA serving
                                    global customers. Virtuos has four important business streams under
                                    Consultare — Consulting &amp; Professional Services Business, Virtuos
                                    Cloud, Yippee Media and Virtuos.iO
                                </p>
                                <h3 className="title">Virtuos Consultare</h3>
                                <p>
                                    Virtuos Consultare delivers industry-specific counsel and the
                                    integrated, end-to-end solutions that support its customers'
                                    strategic, operational, and financial goals. Virtuos is an Experience
                                    consultancy that provides superior customer moments and implements
                                    leading CRM and Customer Experience Applications of its Alliance
                                    Partners. Other services include Our Industry 4.0 ready Business 3.0
                                    operations —{" "}
                                    <NavLink
                                        to={`/cxdesk/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Desk
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/o-digital/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        O.Digital
                                    </NavLink>{" "}
                                    and{" "}
                                    <NavLink
                                        to={`/talentare/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Talentare
                                    </NavLink>
                                    ; Digital transformation and high-performance Customer Experience (CX)
                                    Consulting Services. Consultare flagship{" "}
                                    <NavLink
                                        to={`/cxnow/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CXNow
                                    </NavLink>{" "}
                                    Program covers{" "}
                                    <NavLink
                                        to={`/cxnow/cxaudit/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        CX Audit
                                    </NavLink>
                                    ;{" "}
                                    <NavLink
                                        to={`/cxnow/customer-journey/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Customer Journey Mapping
                                    </NavLink>
                                    , and{" "}
                                    <NavLink
                                        to={`/cxnow/customer-voice/`}
                                        target="_blank"
                                        className="link"
                                    >
                                        Voice of Customer Analytics
                                    </NavLink>
                                    .
                                </p>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Implements_Clicksoftware