import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Thankyou = () => {
  useEffect(() => {
    document.title = "Virtuos | Thank you";
  }, []);
  return (
    <div className="thankyou-page">
      <Navbar />
      <main className="main-wrapper">
        <div className="thankyou-wrapper">
          <div className="page-wrap row">
            <div className="col-5 illus-wrapper">
              <span className="animated-heart">
                <img
                  id="smiling-heart"
                  src={`/img/svg/icons/smiling-heart.svg`}
                  alt=""
                />
              </span>
            </div>
            <div className="col-7 text-wrap">
              <h1>
                Thank you for contacting{" "}
                <span className="highlight">Virtuos</span>
              </h1>
              <h2 style={{ fontSize: "1em" }}>
                We'll get back to you very soon
              </h2>
              <div className="btn-wrapper">
                <NavLink
                  to={`/`}
                  className="btn btn-tertiary"
                >
                  <i className="fas fa-home" /> Back to home
                </NavLink>
                <NavLink
                  to={`/customers`}
                  className="btn btn-primary"
                >
                  Read Success Stories
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </main>
    </div>
  );
};

export default Thankyou;
