import React from "react";
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const NewsTwitter = () => {
    return (
        <div className="twitter-timeline twitter-timeline-rendered" >
            <TwitterTimelineEmbed
                sourceType="profile"
                screenName="virtuos"
                options={{
                    tweetLimit: 5,
                    height: 400,
                    chrome: 'nofooter noborder noscrollbar noheader transparent'
                }}
            />
        </div>
    );
};

export default NewsTwitter;
