import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Element } from "react-scroll";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../../components/Footer";
import "../../../src/style/autosliders.css";
import SubscribeNow from "./Form/SubscribeNow";


const Vlocis = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    document.title =
      "Digital Transformation is at the speed of thought – Virtuos Vlocis";

    const handleScroll = (event) => {
      const { documentElement, body } = document;
      const { clientHeight, scrollHeight, scrollTop } = documentElement || body;
      const delta = event.deltaY;

      const scrollThreshold = 0.8; // Adjust this value to control when the slide changes

      if (
        delta > 0 &&
        scrollTop + clientHeight >= scrollHeight * scrollThreshold
      ) {
        // Scrolling down and reached the threshold
        sliderRef.current.slickNext();
      } else if (
        delta < 0 &&
        scrollTop <= clientHeight * (1 - scrollThreshold)
      ) {
        // Scrolling up and reached the threshold
        sliderRef.current.slickPrev();
      }
    };

    window.addEventListener("wheel", handleScroll);

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };

  return (
    <div className="cx-digital-page sub-home-page listing-page vlocis-page">
      <div className="canva">
        <Navbar />
      </div>
      <main className="main-wrapper">
        <div className="slides">
          <Slider {...settings} ref={sliderRef}>
            <div className="sliderdiv">
              <Element name="digital-transformation">
                <section
                  className="vertical-section"
                  id="digital-transformation"
                >
                  <div className="full-screen-wrapper">
                    <figure>
                      <img
                        src={`/img/digital-transformation-speed-thought-img.jpg`}
                        alt="Digital Transformation"
                      />
                    </figure>
                    <div className="center-wrapper">
                      <h2 className="section-main-heading">
                        DIGITAL TRANSFORMATION IS @ THE SPEED OF THOUGHT
                      </h2>
                      <p>
                        Agility and Resilience to bolster or disrupt traditional
                        business models is vital.
                      </p>
                      {/*						<p class="launch-date">Launching on 12 AUG 2019</p>*/}
                      <NavLink
                        to={`/bpm-cx`}
                        className="btn btn-secondary"
                      >
                        Explore More
                      </NavLink>
                      <span className="blinking-down-arrow">
                        Scroll Down
                        <i className="fal fa-angle-down arrow" />
                      </span>
                    </div>
                  </div>
                </section>
              </Element>
            </div>
            <div>
              <Element name="robo-code">
                <section className="vertical-section" id="robo-code">
                  <div className="full-screen-wrapper">
                    <figure>
                      <img
                        src={`/img/low-code-no-code-img.jpg`}
                        alt="Robo Code"
                      />
                    </figure>
                    <div className="center-wrapper">
                      <h2 className="section-main-heading">
                        LOW CODE, NO CODE OR ROBO CODE ARE THE ORDER OF TODAY
                      </h2>
                      <p>
                        Confluence of digital disruptions has led to an influx
                        of tools to meet rising demand.
                      </p>
                      <NavLink
                        to="https://www.virtuos.com/downloads/digital-process-automation-for-financial-services.pdf"
                        target="_blank"
                        className="btn btn-secondary"
                      >
                        DOWNLOAD BROCHURE FOR FINANCIAL SERVICES
                      </NavLink>
                      <span className="blinking-down-arrow">
                        Scroll Down
                        <i className="fal fa-angle-down arrow" />
                      </span>
                    </div>
                  </div>
                </section>
              </Element>
            </div>

            <div>
              <Element name="cloud-vlocis">
                <section className="vertical-section" id="cloud-vlocis">
                  <div className="full-screen-wrapper">
                    <figure>
                      <img
                        src={`/img/cloud-crowd-hybrid-vlocis-img.jpg`}
                        alt="Vlocis Matters"
                      />
                    </figure>
                    <div className="center-wrapper">
                      <h2 className="section-main-heading">
                        CLOUD, CROWD OR HYBRID HARDLY MATTERS. VLOCIS MATTERS
                      </h2>
                      <p>
                        Forget about complex and long implementation cycles. Go
                        with Vlocis Digital Process Automation (DPA) tools.
                      </p>
                      <span className="blinking-down-arrow">
                        Scroll Down
                        <i className="fal fa-angle-down arrow" />
                      </span>
                    </div>
                  </div>
                </section>
              </Element>
            </div>
            <div>
              <Element name="digital-dexterity">
                <section className="vertical-section" id="digital-dexterity">
                  <div className="full-screen-wrapper">
                    <figure>
                      <img
                        src={`/img/transformation-digital-dexterity-img.jpg`}
                        alt="Digital Dexterity"
                      />
                    </figure>
                    <div className="center-wrapper">
                      <h2 className="section-main-heading">
                        TRANSFORMATION OR OPTIMIZATION? CONTINUE NEXT WITH
                        DIGITAL DEXTERITY
                      </h2>
                      <p>
                        Start agile adaptive budgeting for digital
                        transformation to manage Continuousness into the next
                        level.
                      </p>
                      <span className="blinking-down-arrow">
                        Scroll Down
                        <i className="fal fa-angle-down arrow" />
                      </span>
                    </div>
                  </div>
                </section>
              </Element>
            </div>
            <div>
              <Element name="last-slide">
                <section
                  className="vertical-section last-slide"
                  id="last-slide"
                >
                  <div
                    className="content-wrapper"
                    style={{ marginTop: "-8em" }}
                  >
                    <div className="subscribe-section">
                      <h3 className="section-main-heading">
                        Get all the <span className="highlight">Updates</span>
                      </h3>
                      <span className="section-desc">
                        Subscribe now to stay connected
                      </span>
                      <form
                        className="no-captcha"
                        action="https://us-central1-virtuos-625f6.cloudfunctions.net/Subscribe"
                        method="POST"
                        id="S_CXD"
                      >
                        <div className="input-wrapper">
                          {/* <NavLink
                            rel="enquiry-form"
                            className="btn btn-secondary popup-form-trigger"
                          >
                            Subscribe Now
                          </NavLink> */}
                          <SubscribeNow />
                        </div>
                      </form>
                    </div>
                  </div>
                  <Footer />
                </section>
              </Element>
            </div>
          </Slider>
        </div>
      </main>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Vlocis;
