import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_Team_Attends_Gartner_2018 = () => {
  useEffect(() => {
    document.title =
      "Virtuos Team Attends Gartner Application Strategies and Solutions Summit in Las Vegas – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-team-attends-gartner-application-strategies-solutions-summit-2018-img-big.jpg`}
                  alt="Virtuos Team attends Gartner’s Application Strategies and Solutions Summit 2018"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Las Vegas, 29 Nov 2018</span>
                <h2 className="article-heading">
                  Virtuos Team attends Gartner’s Application Strategies and
                  Solutions Summit 2018
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  One of Gartner's key summits, The Application Strategies, and
                  Solutions Summit 2018 (ASSS 2018), was held at Caesars Palace,
                  Las Vegas, between Nov 27-29, 2018.
                </p>
                <p>
                  The summit is expected to draw more than 1,800 professionals
                  and feature more than 60 Gartner analysts and 100
                  research-driven sessions spanning 8 tracks. Key topics
                  include:
                </p>
                <ul>
                  <li>Modernizing Legacy Applications</li>
                  <li>DevOps, Agile and Lean IT</li>
                  <li>CX for Internal and External Customers</li>
                  <li>Event-Driven Architecture</li>
                  <li>IoT, Blockchain, Microservices</li>
                  <li>Organizational Change Leadership</li>
                  <li>Aligning IT to Business Strategy</li>
                  <li>Development to Support Superior CX, UX, EX</li>
                  <li>Cloud Computing</li>
                  <li>AI / Machine Learning</li>
                </ul>
                <h3 className="title">Keynotes:</h3>
                <ul>
                  <li>
                    <span className="italic">
                      Gartner Opening Keynote: Optimize, Integrate, and
                      Transform To Achieve Your Digital Future
                    </span>{" "}
                    — Gartner experts Gene Phifer, Daryl Plummer and Elizabeth
                    Golluscio.
                  </li>
                  <li>
                    <span className="italic">
                      Guest Keynote: Business Advice We Shouldn’t Believe
                      Anymore
                    </span>
                    — Andrew McAfee, a technology luminary, futurist, and author
                    of the book Machine, Platform, and Crowd.
                  </li>
                  <li>
                    <span className="italic">
                      Gartner Keynote: Everything You Always Wanted to Know
                      About Technical Debt (but Were Afraid to Ask)
                    </span>{" "}
                    — Gartner expert Andy Kyte.
                  </li>
                  <li>
                    <span className="italic">
                      Guest Keynote: The New Economy: What's It All About?
                    </span>{" "}
                    — April Rinne, a global authority on disruptive innovation
                    and the future of work.
                  </li>
                  <li>
                    <span className="italic">
                      Gartner closing Keynote: Architect Your Digital Ecosystem
                    </span>
                    — Gartner experts Danny Brian, Kirk Knoernschild.
                  </li>
                </ul>
                <p>
                  “This is one of the three top events I have attended besides
                  Dreamforce, Gartner’s Digital Marketing Symposium, during the
                  last couple of years.” Said Venky Vijay Reddi. “The best part
                  with ASSS 2018 is that it offers the breadth of the solutions,
                  strategies central to CRM, ERP, and many other customer-facing
                  technologies, and Las Vegas is a great place to host a
                  conference of this size.”
                </p>
                <p>
                  Venky Vijay Reddi, the CEO of Virtuos, also met the BPM'Online
                  Team, who attended the event. Venky interacted with many
                  Gartner analysts on Customer Experience Strategies, CX Design
                  Thinking, and EX-CX Integration strategies.
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. (Formerly Virtuos Solutions Private Ltd.)
                  is a leading Customer Experience (CX) &amp; Digital
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/xonomy/ai`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Newly created Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/cxdesk`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Team_Attends_Gartner_2018;
