import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import NewsTwitter from '../../components/NewsTwitter';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import SubscribeNow from './SubscribeNow';
const Rightnow_Technologies_Honours_Virtuos = () => {
    const CustomPrevArrow = ({ onClick }) => (
        <div className="prev-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
    );
    const CustomNextArrow = ({ onClick }) => (
        <div className="next-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };
    useEffect(() => {
        document.title = "RightNow Technologies honours Virtuos for the debut performance – Virtuos News & Events";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/rightnow-technologies-honours-virtuos-img-big.jpg`}
                                    alt=""
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">
                                    Gold Coast, Australia - 11 November, 2009
                                </span>
                                <h2 className="article-heading">
                                    RightNow Technologies honours Virtuos for the debut performance
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <p>
                                    Virtuos attends the Annual APAC Summit hosted by RightNow Technologies
                                    (Nasdaq: RNOW) with partners all over Asia.
                                </p>
                                <p>
                                    The summit was held at Radisson Resort Gold Coast Australia from 9th
                                    to 11th November 2009. RightNow's User conference is an extraordinary
                                    opportunity, a meeting of customer experience owners coming together
                                    at a world-class destination to network, exchange information, and
                                    learn from peers and RightNow experts.
                                </p>
                                <figure className="border">
                                    <img
                                        src={`/img/rightnow-summit-2009-img.jpg`}
                                        alt=""
                                    />
                                </figure>
                                <p>
                                    Virtuos clocked the first deal, possibly the biggest On-demand Travel
                                    CRM deal in that year by any partner in RightNow circles. Venky Vijay
                                    Reddi, CEO of Virtuos, has received the memento on behalf of
                                    MakeMyTrip.
                                </p>
                                <p>
                                    "I have attended dozens of User Summits across APAC in the past in my
                                    previous jobs, but RightNow APAC Summit has been fantastic and one of
                                    my very best conferences." Said Venky Vijay Reddi, CEO of Virtuos. "I
                                    was able to meet and interact with the Founder of RightNow
                                    Technologies Greg Gianforte and The Senior VPs from Global Sales,
                                    Channels, and Marketing."
                                </p>
                                <p>
                                    On 12th November 2009, Virtuos CEO and RightNow Sales Leadership had a
                                    meeting at the latter's office in Sydney on India's outlook for CRM
                                    Service Cloud Business. In the evening, Yong Sheng Lee (YS) and his
                                    wife also hosted Virtuos CEO for a grand dinner at a seaside
                                    restaurant.
                                </p>
                                <div className="photo-slider-wrapper">
                                    <Slider {...settings} className="bxslider photo-slider">
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/rightnow-summit-2009-pic-01.jpg`}
                                                    alt="Venky Vijay Reddi receiving the memento from Senior VP, Steve Daines"
                                                />
                                            </figure>
                                            <span>
                                                Venky Vijay Reddi receiving the memento from Senior VP, Steve Daines
                                            </span>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/rightnow-summit-2009-pic-02.jpg`}
                                                    alt="Teete-e-teete with Ruben Martin and Another colleague"
                                                />
                                            </figure>
                                            <span>Teete-e-teete with Ruben Martin and Another colleague</span>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/rightnow-summit-2009-pic-03.jpg`}
                                                    alt="Dinner Party with APAC Partners and YS Lee"
                                                />
                                            </figure>
                                            <span className="">
                                                Dinner Party with APAC Partners and YS Lee
                                            </span>
                                        </div>
                                    </Slider>
                                </div>
                                <h3 className="title">About RightNow Technologies</h3>
                                <p>
                                    RightNow Technologies, Inc. (Nasdaq: RNOW) provides customer
                                    relationship management software and services. The Company offers
                                    solutions that use self-learning knowledge-base to deliver customer
                                    self-service, email management, live interaction, issue tracking, and
                                    customer satisfaction measurement capabilities.
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">07/12/2000</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            136 Enterprise Blvd Bozeman, <br />
                                            MT 59718 United States
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Phone</span>
                                        <span className="txt">1-406-522-4200</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink
                                                to="http://www.rightnow.com"
                                                target="_blank"
                                                className="link"
                                            >
                                                www.rightnow.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">1000-2000</span>
                                    </div>
                                </div>
                                <h3 className="title">About Virtuos Solutions</h3>
                                <p>
                                    Virtuos Solutions Private Ltd. is a leading Customer Relationship
                                    Management Services and Cloud Computing Technologies Company based out
                                    NewDelhi/NCR with a registered office in Hyderabad, Andhra Pradesh.
                                    The Company has been inspired by the book — Virtuoso Teams and has
                                    entered the burgeoning CRM space by addressing three important needs
                                    of growing businesses — Sales, Customer Service, and Marketing.
                                    Virtuos has forged partnerships with leading Global Players in the CRM
                                    Industry namely Kana Software (
                                    <NavLink to="http://www.kana.com" target="_blank" className="link">
                                        www.kana.com
                                    </NavLink>
                                    ), Maximizer (
                                    <NavLink to="http://www.maximizer.com" target="_blank" className="link">
                                        www.maximizer.com
                                    </NavLink>
                                    ) and RightNow Technologies (
                                    <NavLink to="http://www.rightnow.com" target="_blank" className="link">
                                        www.rightnow.com
                                    </NavLink>
                                    )
                                </p>
                                <div className="row company-detail light-blue-bg">
                                    <div className="col-3 item">
                                        <span className="label">Sector</span>
                                        <span className="txt">Technology</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Industry</span>
                                        <span className="txt">Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Sub-Industry</span>
                                        <span className="txt">Application Software</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Founded</span>
                                        <span className="txt">18/01/2008</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Address</span>
                                        <span className="txt">
                                            Tekcorp Office Building, <br />F Block Okhla Industrial Area -
                                            Phase 1, <br />
                                            New Delhi 110020 India
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Branch</span>
                                        <span className="txt">Hyderabad</span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">Website</span>
                                        <span className="txt">
                                            <NavLink to="http://www.virtuos.com" target="_blank" className="link">
                                                www.virtuos.com
                                            </NavLink>
                                        </span>
                                    </div>
                                    <div className="col-3 item">
                                        <span className="label">No. of Employees</span>
                                        <span className="txt">10-20</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>
                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Rightnow_Technologies_Honours_Virtuos