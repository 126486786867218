import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Drawer,
  Button,
  // List,
  // ListItem,
  // ListItemButton,
  // ListItemIcon,
  // ListItemText,
} from "@mui/material";
import ReCaptcha from "react-google-recaptcha";
// import emailjs from "@emailjs/browser";
import CloseIcon from "@mui/icons-material/Close";
const ApplyForJob = () => {
  const [phoneError, setPhoneError] = useState(false);
  const [resumeError, setResumeError] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(true);
  const [userData, setuserData] = useState({
    webURL: `/careers/jobs`,
    formType: "Job_new",
    name: "",
    email: "",
    phone: "",
    message: "",
    jobfile: null,
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  //we are storign data in states
  const handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setuserData({ ...userData, [name]: value });
    // console.log("inside handlechange");
    // console.log(userData)
  };
  const handleFileInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.files;
    // console.log(name, value[0]);
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpeg'];

    if (!allowedTypes.includes(value[0].type)) {
      alert('Unsupported file type. Only PDF, DOC, PNG, and JPG are allowed.');
      e.target.value = ''; // Clear the file input
    }
    setuserData({ ...userData, [name]: value[0] });
    // console.log(userData);
  }
  const handleRecaptchaChange = (value) => {
    console.log("Recaptcha");
    setRecaptchaValue(value);
    setCaptchaError(false);
  };

  const handleSubmit = async (e) => {
    console.log("insidde contcat form");
    e.preventDefault();

    if (captchaError) {
      return;
    }
    console.log(userData);
    const { webURL, formType, name, email, phone, message, jobfile } = userData;
    console.log(typeof jobfile)
    if (!userData.phone) {
      setPhoneError(true);
      return;
    } else {
      setPhoneError(false);
    }

    console.log(jobfile);
    console.log("babays")
    if (!jobfile) {
      // console.log("hell");
      setResumeError(true);
      return;
    } else {
      setResumeError(false);
      console.log("alsfdkjlskfdj")
    }

    const formData = new FormData();
    formData.append('webURL', webURL);
    formData.append('type', formType);
    formData.append('full_name', name);
    formData.append('email', email);
    formData.append('mobile', phone);
    formData.append('message', message);
    formData.append('jobfile', jobfile);

    const res = await fetch(`${process.env.REACT_APP_API_URL}/db/job`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
      body: formData,
    });
    const data = await res.json();

    if (!data) {
      console.log("message not send");
    } else {
      // alert( "message send" );
      setuserData({ ...userData, message: "" });
      navigate("/thankyou"); // Navigate to "/thankyou" route
    }
    /*emailjs
     .send(
       process.env.REACT_APP_SERVICE_ID,
       process.env.REACT_APP_TEMPLATE_ID,
       {
         from_name: userData.name,
         to_name: process.env.REACT_APP_TO_NAME,
         from_email: userData.email,
         to_email: process.env.REACT_APP_TO_EMAIL,
         message: userData.message,
       },
       process.env.REACT_APP_PUBLIC_KEY
     )
     .then(
       () => {

         // alert("Thank you. I will get back to you as soon as possible.");

         setuserData({
           name: "",
           email: "",
           phone: "",
           message: ""
         });

       },
       (error) => {

         console.error(error);

         // alert("Ahh, something went wrong. Please try again.");
       }
     );*/
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [message, setMessage] = useState("");
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleChangeMessage = (event) => {
    const value = event.target.value;
    setMessage(value);
  };

  const remainingChars = 193 - userData.message.length;
  const [isHovered, setIsHovered] = useState(false);
  const buttonStyle = {
    color: isHovered ? "red" : "black",
    fontWeight: "bold",
    transition: "color 0.3s",
  };

  return (
    // <NavLink rel="apply-job" className="btn btn-secondary popup-form-trigger">
    //                             Apply for this job
    //                         </NavLink>
    <div>
      <NavLink
        onClick={toggleDrawer}
        className="btn btn-secondary popup-form-trigger"
      >
        Apply for this job
      </NavLink>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={toggleDrawer}>
        <div
          className="form-wrapper page-wrap"
          style={{ height: "430px" }}
          id="form-mobile-view"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 className="section-heading" style={{ marginTop: "1.5rem" }}>
              Apply for this job
            </h3>
            <Button
              onClick={toggleDrawer}
              style={buttonStyle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <CloseIcon />
            </Button>
          </div>
          <p>
            Please fill out the form below and attach your updated resume/CV. We
            will forward your application to the respective departments for next
            steps.
          </p>
          <div cf-context="">
            <form
              onSubmit={handleSubmit}
              action=""
              method="POST"
              id="E_Alliances"
              encType="multipart/form-data"
            >
              <div className="row" id="gap1">
                <div className="col-6 left-wrapper">
                  <div className="input-wrapper hidden-fields">
                    <input type="text" name="pageURL" className="pageURL" />
                    <input
                      type="text"
                      name="formType"
                      id="formType"
                      defaultValue="E_Alliances"
                    />
                    <input
                      type="text"
                      name="location"
                      className="userLocation"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Full Name</label>
                    <input
                      name="name"
                      tabIndex={1}
                      type="text"
                      maxlenght={100}
                      value={userData.name}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Email</label>
                    <input
                      name="email"
                      tabIndex={2}
                      value={userData.email}
                      onChange={handleInput}
                      required
                      type="email"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Mobile</label>
                    <input
                      name="phone"
                      tabIndex={3}
                      value={userData.phone}
                      onChange={handleInput}
                      required
                      type="tel"
                    />
                    {phoneError && (
                      <p
                        className="required-message"
                        style={{ fontSize: "12px", color: "red" }}
                      >
                        *mobile is required
                      </p>
                    )}
                  </div>
                  <div className="input-wrapper">
                    {/* {currentFile && (
                      <Box className="mb25" display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                          <BorderLinearProgress variant="determinate" value={progress} />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                        </Box>
                      </Box>)
                    } */}
                    <label htmlFor="">Resume</label>
                    <label htmlFor="btn-upload">
                      <input
                        id="btn-upload"
                        name="jobfile"
                        style={{ display: 'none' }}
                        type="file"
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                        onChange={handleFileInput}
                        />
                      <Button
                        className="btn-choose"
                        variant="outlined"
                        component="span" >
                        Choose File
                      </Button>
                      {/* {userData.jobfile && <div>{userData.jobfile.name}</div>} */}
                    </label>
                    <div className="file-name">
                      {userData.jobfile ? userData.jobfile.name : null}
                    </div>
                    {/* <Button
                      className="btn-upload"
                      color="primary"
                      variant="contained"
                      component="span"
                      // disabled={!selectedFiles}
                      // onClick={this.upload}
                      >
                      Upload
                    </Button> */}

                    {/* <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
                      {message}
                    </Typography> */}
                    {/* <label htmlFor="resume">Upload Resume</label>
                    <input
                      name="resume"
                      tabIndex={3}
                      // onChange={handleFileUpload} // Add your file upload handler function here
                      required
                      type="file"
                      // accept=".pdf,.doc,.docx" // Specify accepted file formats
                    /> */}
                    {resumeError && (
                      <p className="required-message" style={{ fontSize: "12px", color: "red" }}>
                        *Please upload a valid resume file (PDF or Word document).
                      </p>
                    )}
                  </div>

                </div>
                <div className="col-6 right-wrapper">
                  <div className="input-wrapper">
                    <label htmlFor="">Message (If any)</label>
                    <textarea
                      name="message"
                      cols={30}
                      rows={3}
                      value={userData.message}
                      onChange={handleInput}
                      placeholder="Optional"
                      onKeyDown={(event) => {
                        if (
                          event.target.value.length >= 193 &&
                          event.key !== "Backspace"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onKeyUp={(event) => {
                        if (event.target.value.length > 193) {
                          const truncatedValue = event.target.value.slice(
                            0,
                            193
                          );
                          setuserData((prevUserData) => ({
                            ...prevUserData,
                            message: truncatedValue,
                          }));
                        }
                      }}
                    />
                    <span style={{ fontSize: "12px" }}>
                      Remaining characters: {remainingChars}
                    </span>
                  </div>
                  <div className="input-wrapper">
                    <ReCaptcha
                      sitekey={process.env.REACT_APP_RECAPTCHA}
                      onChange={handleRecaptchaChange}
                    />
                  </div>
                  {captchaError && (
                    <p
                      className="required-message"
                      style={{ fontSize: "12px", color: "red" }}
                    >
                      * Please select CAPTCHA
                    </p>
                  )}
                </div>
              </div>
              <div className="btn-wrapper">
                <input
                  className="btn btn-secondary"
                  type="submit"
                  value="Submit"
                  tabIndex={7}
                />
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default ApplyForJob;
