import React from "react";
import { NavLink, Link } from "react-router-dom";
const Nav = () => {
  return (
    <>
      <nav className="main-nav">
        <ul className="first-level-wrapper">
          <li className="tagline">The Digital Century Business</li>
          <li className="first-level">
            <div className="list-item">
              <NavLink to={`/consultare`}>Consultare</NavLink>

              <span className="sub-level-trigger" rel="consultare">
                <i className="fal fa-angle-right icon" />
              </span>
            </div>
          </li>
          <li className="first-level">
            <div className="list-item">
              <NavLink to={`/xonomy`}>Xonomy</NavLink>
              <span className="sub-level-trigger" rel="xonomy">
                <i className="fal fa-angle-right icon" />
              </span>
            </div>
          </li>
          <li className="first-level">
            <div className="list-item">
              <NavLink to={`/business`}>
                The Business<sup style={{ fontSize: "10px" }}>(9)</sup>
              </NavLink>
              <span className="sub-level-trigger" rel="business">
                <i className="fal fa-angle-right icon" />
              </span>
            </div>
          </li>
          <li className="first-level">
            <div className="list-item">
              <NavLink to={`/corporate/`}>The Company</NavLink>
              <span className="sub-level-trigger" rel="about">
                <i className="fal fa-angle-right icon" />
              </span>
            </div>
          </li>
          <li className="first-level">
            <div className="list-item">
              <NavLink to={`/alliances/`}>Alliances</NavLink>
            </div>
          </li>
          <li className="first-level">
            <div className="list-item">
              <NavLink to={`/alliances/`}>News &amp; Events</NavLink>
            </div>
          </li>
          <li className="first-level">
            <div className="lis...........................................................................................................................t-item">
              <NavLink to={`/careers/`}>Careers</NavLink>
              <span className="sub-level-trigger" rel="careers">
                <i className="fal fa-angle-right icon" />
              </span>
            </div>
          </li>
          <li className="first-level">
            <div className="list-item">
              <NavLink to={`/contact/`}>Contact</NavLink>
            </div>
          </li>
        </ul>
        <div className="second-level-wrapper">
          <ul className="sub-menu" id="consultare">
            <li className="back-nav-btn">
              <span>
                <i className="fal fa-angle-left" /> Back
              </span>
            </li>
            <li>
              <NavLink to={`/crm-digital/`}>CRM DIGITAL</NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/cx/`} className="heading">
                CUSTOMER EXPERIENCE
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/ex/`} className="heading">
                EMPLOYEE EXPERIENCE
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/it/`} className="heading">
                ENTERPRISE IT SERVICES
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/alliances/`} className="heading">
                KEY PARTNERS
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
          </ul>
          <ul className="sub-menu" id="xonomy">
            <li className="back-nav-btn">
              <span>
                <i className="fal fa-angle-left" /> Back
              </span>
            </li>
            <li>
              <NavLink to={`/experience-cloud/`} className="heading">
                EXPERIENCE CLOUD
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/experience-crowd/`} className="heading">
                EXPERIENCE CROWD
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink
                to="http://www.vedam.com"
                target="_blank"
                className="heading"
              >
                DIGITAL EXPERIENCE (DX) FIRST
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink
                to="http://www.virtuez.com"
                target="_blank"
                className="heading"
              >
                VIRTUEZ XONOMY
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/experience-new/`} className="heading">
                EXPERIENCENEW IN THE XONOMY
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
          </ul>
          <ul className="sub-menu" id="business">
            <li className="back-nav-btn">
              <span>
                <i className="fal fa-angle-left" /> Back
              </span>
            </li>
            <li>
              <NavLink to={`/business/`} className="heading">
                BUSINESS<sup style={{ fontSize: "9px" }}>(9)</sup> BY NEEDS
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/solutions/`} className="heading">
                SOLUTIONS BY FUNCTION
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/industry/`} className="heading">
                INDUSTRIES
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/business4-0/`} className="heading">
                INNOVATION INSIGHT &amp; STRATEGY
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/alliances/`} className="heading">
                PARTNER SOLUTIONS
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
          </ul>
          <ul className="sub-menu" id="about">
            <li className="back-nav-btn">
              <span>
                <i className="fal fa-angle-left" /> Back
              </span>
            </li>
            <li>
              <NavLink to={`/corporate/`} className="heading">
                ABOUT VIRTUOS
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/business/`} className="heading">
                OUR BUSINESS
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/customers/`} className="heading">
                CUSTOMER SUCCESS
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/careers/`} className="heading">
                CAREERS
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
            <li>
              <NavLink to={`/news/`} className="heading">
                NEWS AND EVENTS
              </NavLink>{" "}
              <i className="fal fa-angle-right icon" />
            </li>
          </ul>
          <ul className="sub-menu" id="careers">
            <li className="back-nav-btn">
              <span>
                <i className="fal fa-angle-left" /> Back
              </span>
            </li>
            <li>
              <NavLink to={`/careers/opportunities/`}>Job Opportunity</NavLink>
            </li>
            <li>
              <NavLink to={`/careers/culture/`}>Culture</NavLink>
            </li>
            <li>
              <NavLink to={`/careers/life-at-virtuos/`}>Life @ Virtuos</NavLink>
            </li>
            <li>
              <NavLink to={`/careers/experience-job/`}>Experience Job</NavLink>
            </li>
            <li>
              <NavLink to={`/careers/training-development/`}>
                Training &amp; Development
              </NavLink>
            </li>
            <li>
              <NavLink to={`/careers/happiests-culture/`}>
                Happiests Culture
              </NavLink>
            </li>
            <li>
              <NavLink to={`/careers/employee-benefits/`}>
                Employee Benefits
              </NavLink>
            </li>
            <li>
              <NavLink to={`/careers/virtuos-alumni/`}>Virtuos Alumni</NavLink>
            </li>
            <li>
              <NavLink to={`/careers/360Outlook/`}>360 Degree Outlook</NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <span className="close-nav-btn">
        <i className="fal fa-times" />
      </span>
    </>
  );
};

export default Nav;
