import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import SubscribeNow from "./SubscribeNow";
import NewsTwitter from "../../components/NewsTwitter";

const Virtuos_Solutions_Changes = () => {
  useEffect(() => {
    document.title =
      "Virtuos Solutions Changes its Name To Virtuos Digital – Virtuos News & Events";
  }, []);
  return (
    <div className="client-detail-page landing-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper" id="news_main">
          {/* News Detail */}
          <section className="main-blogs" id="news">
            <div className="article-wrapper">
              <figure className="article-image">
                <img
                  src={`/img/virtuos-solutions-changes-its-name-to-virtuos-digital-img-big.jpg`}
                  alt="Virtuos Solutions changes its name to Virtuos Digital"
                />
              </figure>
              <div className="page-wrap article-info">
                <span className="date-posted">Hyderabad, 14 Sept 2018</span>
                <h2 className="article-heading">
                  Virtuos Solutions changes its name to Virtuos Digital to
                  reinforce its commitment to transform brands with digital
                  design.
                </h2>
              </div>
            </div>
            <div className="page-wrap row">
              <div className="col-8 article-content">
                <p>
                  Digital is the fuel for "living businesses" in the age of
                  Artificial Intelligence (AI), Machine Learning (ML),
                  Blockchain, and Cloud transforming the way we live and thrive
                  in the fourth industrial society. In pursuit of customer
                  excellence, we have transformed more than 100 brands in India,
                  APAC, and Europe with modern Experience services, products and
                  platforms continuously helping them to stay ahead of their
                  competition. Our CX fused Digital Design Services have
                  impacted the brand's customer satisfaction, loyalty, and
                  advocacy contributing to high-performance growth, and market
                  leadership.
                </p>
                <p>
                  As Virtuos Solutions, we have CX design - build - integration
                  capabilities and strong IT consulting track record besides
                  deep expertise in Information Architecture, and Software
                  Engineering, said Venky Vijay Reddi, CEO of Virtuos. "Over the
                  last five years, we have developed new capabilities, skills,
                  and expertise in Digital Transformation Services by
                  aggrandizing the core CX Services. With the new name — Virtuos
                  Digital, we put digital at the core of everything we do in
                  Customer Experience (CX), Business Transformation, and CRM
                  Technology Services".
                </p>
                <h3 className="title">About Virtuos</h3>
                <p>
                  Virtuos Digital Ltd. (Formerly Virtuos Solutions Private Ltd.)
                  is a leading Customer Experience (CX) &amp; Digital
                  Transformation Consulting company with its corporate
                  headquarters in India, and the USA serving global customers.
                  Virtuos has two important business operations namely{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>
                  .
                </p>
                <h3 className="title">Virtuos Consultare</h3>
                <p>
                  Virtuos{" "}
                  <NavLink to={`/consultare`} className="link">
                    Consultare
                  </NavLink>{" "}
                  delivers industry-specific counsel and the integrated,
                  end-to-end solutions that support its customers' strategic,
                  operational, and financial goals. Virtuos is an Experience
                  consultancy that provides superior customer moments and
                  implements leading CRM and Customer Experience Applications —
                  Oracle CX; Salesforce CRM and Microsoft Dynamics amongst other
                  niche players. Other consulting services include Our Industry
                  4.0 ready{" "}
                  <NavLink to={`/business4-0/`} className="link">
                    Business 4.0
                  </NavLink>{" "}
                  operations —{" "}
                  <NavLink to={`/cxdesk/`} className="link">
                    CXDesk
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/o-digital`} className="link">
                    O.Digital
                  </NavLink>{" "}
                  and{" "}
                  <NavLink to={`/talentare`} className="link">
                    Talentare
                  </NavLink>
                  ; Digital transformation and high-performance Customer
                  Experience (CX) Transformation Services. Consultare flagship{" "}
                  <NavLink to={`/cxnow`} className="link">
                    CXNow
                  </NavLink>{" "}
                  Program delivers{" "}
                  <NavLink to={`/cxnow/cxaudit`} className="link">
                    CX Audit
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/cxnow/customer-journey`} className="link">
                    Customer Journey Mapping
                  </NavLink>
                  , and{" "}
                  <NavLink to={`/cxnow/customer-voice`} className="link">
                    Voice of Customer Analytics
                  </NavLink>{" "}
                  for large customers worldwide.
                </p>
                <h3 className="title">Virtuos Xonomy</h3>
                <p>
                  Newly created Virtuos{" "}
                  <NavLink to={`/xonomy`} className="link">
                    Xonomy
                  </NavLink>{" "}
                  ideates and incubates next-generation businesses harnessing{" "}
                  <NavLink to={`/xonomy/ai/`} className="link">
                    Artificial Intelligence (AI)
                  </NavLink>
                  , Cloud, ECommerce and Digital technologies. Virtuos Xonomy
                  also launched the{" "}
                  <NavLink to={`/io`} className="link">
                    Innovation On (formerly Innovation Open) lab
                  </NavLink>{" "}
                  to breed and bootstrap new ventures that include{" "}
                  <NavLink to={`/xonomy/budh`} className="link">
                    Budh
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.indic.com"
                    target="_blank"
                    className="link"
                  >
                    Indic.com
                  </NavLink>{" "}
                  and{" "}
                  <NavLink
                    to="http://www.giftcart.com"
                    target="_blank"
                    className="link"
                  >
                    Giftcart.com
                  </NavLink>{" "}
                  (Part of BIG Strategy) and a host of unique Experience Cloud
                  applications. Virtuos Experience Cloud business comprises a
                  DARE strategy —{" "}
                  <NavLink
                    to="http://definite.com/"
                    target="_blank"
                    className="link"
                  >
                    Definite.com
                  </NavLink>
                  ;{" "}
                  <NavLink to={`/xonomy/aury`} className="link">
                    Aury
                  </NavLink>
                  ;{" "}
                  <NavLink
                    to="http://www.rigour.com"
                    target="_blank"
                    className="link"
                  >
                    Rigour
                  </NavLink>
                  ; and{" "}
                  <NavLink
                    to="http://www.enforce.com/"
                    target="_blank"
                    className="link"
                  >
                    Enforce
                  </NavLink>
                  .
                </p>
              </div>
              <div className="col-3-half sticky-content">
                <NewsTwitter />
              </div>
            </div>
          </section>
          <SubscribeNow />
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Virtuos_Solutions_Changes;
