import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../../../components/Navbar';
import Nav from '../../../components/Nav';
import Footer from '../../../components/Footer';
import SubscribeNow from '../SubscribeNow';
import NewsTwitter from '../../../components/NewsTwitter';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Virtuos_Cx_Summit_2016 = () => {
    const CustomPrevArrow = ({ onClick }) => (
        <div className="prev-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
    );
    const CustomNextArrow = ({ onClick }) => (
        <div className="next-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    useEffect(() => {
        document.title = "Latest Events | Information on Events - Virtuos";

    }, []);
    return (
        <div className='client-detail-page landing-page'>
            <div className='canvas'>
                <Navbar />
                <main className="main-wrapper" id="news_main">
                    {/* News Detail */}
                    <section className="main-blogs" id="news">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/virtuos-cx-summit-2018-bg.jpg`}
                                    alt="Virtuos CX Summit 2016"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="date-posted">12 Dec, 2016</span>
                                <h2 className="article-heading">
                                    A look back at Virtuos CX Summit 2016
                                </h2>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-8 article-content">
                                <h3 className="title">The Must Attend Event</h3>
                                <p>
                                    In today’s day and age, great Customer Experience is the biggest
                                    differentiating factor for any organization. They say "Experience is
                                    the new brand and experiences are the new branding." Thus, in-depth
                                    understanding of your customers is imperative. This is an era of
                                    empowered customer, thus achieving best levels of customer
                                    satisfaction is paramount. This event highlighted how customer
                                    experiences can be managed by providing them the best service across
                                    channels of communication and how technology can help.
                                </p>
                                <h3 className="title">Agenda Highlights</h3>
                                <ul>
                                    <li>
                                        We discussed strategies on how to optimize your Customer Experience
                                        with multi-channel support, based on recognized industry best
                                        practices.
                                    </li>
                                    <li>
                                        We saw a sneak-peek (a live demo) of our key offerings and the value
                                        it adds to your business.
                                    </li>
                                    <li>
                                        We shared some of our experiences by taking insights from difficult
                                        scenarios faced by other industry peers and ways to raise your
                                        customer service from ordinary to extraordinary.
                                    </li>
                                    <li>
                                        We shared our objective of Virtuos being an "Experience Consultancy"
                                        and overview of our CX Transformation practice.
                                    </li>
                                </ul>
                                <div className="photo-slider-wrapper">
                                    <Slider {...settings} className="bxslider photo-slider">
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/virtuos-cx-summit-2016-img-01.jpg`}
                                                    alt="Virtuos CX Summit 2016"
                                                />
                                            </figure>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/virtuos-cx-summit-2016-img-02.jpg`}
                                                    alt="Virtuos CX Summit 2016"
                                                />
                                            </figure>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/virtuos-cx-summit-2016-img-03.jpg`}
                                                    alt="Virtuos CX Summit 2016"
                                                />
                                            </figure>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/virtuos-cx-summit-2016-img-04.jpg`}
                                                    alt="Virtuos CX Summit 2016"
                                                />
                                            </figure>
                                        </div>
                                        <div className="item">
                                            <figure>
                                                <img
                                                    src={`/img/virtuos-cx-summit-2016-img-05.jpg`}
                                                    alt="Virtuos CX Summit 2016"
                                                />
                                            </figure>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            <div className="col-3-half sticky-content">
                                <NewsTwitter />
                            </div>
                        </div>

                    </section>
                    <SubscribeNow />
                    <Footer />
                </main>

            </div>
            <div className='off-canvas'>
                <Nav />
            </div>
        </div>
    )
}

export default Virtuos_Cx_Summit_2016