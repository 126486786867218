import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import ContactUs from "./Form/ContactUs";
import { Height } from "@mui/icons-material";

const Agnitor = () => {
  const [showExtraLinks, setShowExtraLinks] = useState(false);
  const handleMouseEnter = () => {
    setShowExtraLinks(true);
  };
  const handleMouseLeave = () => {
    setShowExtraLinks(false);
  };
  useEffect(() => {
    document.title = "Agnitor, The Expertise Crowd – Virtuos Xonomy";
  }, []);
  return (
    <div className="o-digital-page landing-page agnitor-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper business3-page">
          <section className="top-hero-product-banner image-title-layout">
            <figure className="bg-image-wrapper">
              <img src={`/img/svg/agnitor-header-img.svg`} alt="Agnitor" />
            </figure>
            <div className="product-hero-wrapper">
              <div className="row page-wrap">
                <div
                  className="col-5 item-wrapper"
                  style={{ position: "absolute" }}
                >
                  <figure>
                    <img
                      src={`/img/svg/icons/agnitor-logo.svg`}
                      alt="Agnitor Logo"
                    />
                  </figure>{" "}
                  <h3 style={{ fontSize: "62px" }}>The Expertise Crowd</h3>
                  <span>
                    Agnitor means a talented Person who recognizes, understands,
                    and perceives the opportunities.
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="breadcrumb-kanaban">
            <div className="page-wrap breadcrumb-wrapper">
              <ul className="row main-breadcrumbs" id="main-breadcrumbs">
                <li>
                  <NavLink to={`/xonomy/`}>Xonomy</NavLink>
                </li>
                <li>
                  <NavLink to={`/agnitor/`} className="active">
                    AGNITOR
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-crowd/`}>EXPERIENCE CROWD</NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-crowd#dati`} className="smooth">
                    DATI DATA CROWD
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/experience-cloud/`}>EXPERIENCE CLOUD</NavLink>
                </li>
              </ul>
              <ul className="more-hidden-tabs">
                <li
                  className={`more-btn ${
                    showExtraLinks ? "active" : ""
                  } inline-flex items-center bg-gray-100 rounded px-4 py-2 cursor-pointer transition duration-300`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <i className="fal fa-plus" /> More
                  {showExtraLinks && (
                    <ul className="extra-links absolute top-full left-0 bg-white rounded shadow-lg p-1 w-full">
                      <li>
                        <NavLink to={`/io/`}>INNOVATION ON</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/business4-0/`}>
                          BUSINESS 4.0 STRATEGY
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/xonomy/aury/`}>AURY PLATFORM</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/ventures/`}>VENTURES</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/`}>VIRTUOS VUCA</NavLink>
                      </li>
                      <li>
                        <NavLink to={`/vuca/covid/`}>VIRTUOS FOR COVID</NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </section>
          <section className="business3-tab-section tab-content-wrapper">
            {/* O.Digital */}
            <div id="o-digital-section">
              <div className="consultare-services">
                <div className="page-wrap">
                  <h2 className="section-main-heading">
                    AGNITOR,{" "}
                    <span className="highlight">
                      THE POWER OF GLOBAL TALENT
                    </span>
                  </h2>
                  <span className="section-desc">
                    Agnitor delivers value in harnessing the power of global
                    talent, shared services, and expertise across the domains.
                  </span>
                  <span className="section-desc">
                    Agnitor is born to take the advantages of crowdsourcing —
                    including the ability to tap into a global talent pool,
                    connect with global experts, and infuse innovation into
                    delivery. Agnitor has three engagement models for
                    crowdsourcing the talent that’s often unexplored, invisible,
                    or unexploited. The vision of the Agnitor is to help the
                    organizations identify the highest levels of experts,
                    masterminds, and connoisseurs; harness their skills,
                    knowledge, and experience; and engage with them for
                    transforming customers’ digital business.{" "}
                  </span>
                  <div className="page-wrap">
                    <div className="two-col-section">
                      <h2 className="offport-down">
                        Agnitor is a network of elite experts aka "Virtuoso
                        ninja" helping businesses worldwide and solving their
                        challenges. There are two engagement models of Agnitor
                        Crowdsourcing:
                      </h2>
                      <div className="row harmony">
                        <div className="col-6 item-wrap offport-left">
                          <div className="content-wrap">
                            <h3 className="section-heading">
                              Expertise As A Service
                            </h3>
                            <p id="margin1">
                              Agnitor has independent experts on the panel —
                              mostly crowdsourced using a stringent Agnitor
                              framework of onboarding by clearly defining and
                              articulating the goals, conducting thorough
                              background checks on potential problems with
                              quality, security, integration, and support.
                            </p>
                          </div>
                        </div>
                        <div className="col-6 illus offport-right">
                          <figure>
                            <img
                              src={`/img/svg/expertise-service-img.svg`}
                              alt="Expertise As A Service"
                            />
                          </figure>
                        </div>
                      </div>
                      <div className="row harmony">
                        <div className="col-6 illus offport-right">
                          <figure>
                            <img
                              src={`/img/svg/expertise-resource-img_1.svg`}
                              alt="Expertise As Your Resource"
                            />
                          </figure>
                        </div>
                        <div className="col-6 item-wrap offport-left">
                          <div className="content-wrap">
                            <h3 className="section-heading">
                              Expertise As Your Resource
                            </h3>
                            <p id="margin1">
                              Independent consultants, technology, and
                              management experts, global practitioners can sign
                              into Agnitor network as an expert resource with
                              their availability cycles. Organizations with an
                              additional or untapped inventory of their talent
                              can also sponsor their in-house experts to join
                              the Agnitor network.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="challenge-container">
            <h2 className="section-main-heading">
              Agnitor addresses{" "}
              <span className="highlight">three sourcing challenges</span>
            </h2>
            <span className="section-desc">
              CEOs face pressure from clients, stakeholders, and venture capital
              firms to lower the cost of operating.
            </span>
            <span className="section-desc">
              Increased pressure to differentiate and compete in the
              marketplace, CEOs adopt different strategies — optimizing
              resources and innovate solutions quickly. Direct hiring and staff
              augmentation seem to be a quick solution to address the speed of
              innovation, but the activities take time and drive costs, often
              exceeding the budgets. Not to mention the typical hiring costs
              from screening, recruiting, onboarding, and monitoring the
              performance.
            </span>
            <div className="row page-wrap">
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/icons/hiring-talent-icon.svg`}
                    alt="Difficulty Hiring Talent Icon"
                  />
                </figure>
                <h3 className="font-weight600">Difficulty Hiring Talent</h3>
                <p>
                  Inability to find enough IT resources with in-depth domain
                  knowledge, skills, and experience; massive shortage of talent
                  in geography for a particular budget; lower reliability of
                  people accepting the offer and joining the company on time as
                  committed.
                </p>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/icons/personnel-cost-icon.svg`}
                    alt="High Personnel Costs Icon"
                  />
                </figure>
                <h3 className="font-weight600">High Personnel Costs</h3>
                <p>
                  Resource constraints and increased high personnel costs;
                  Ongoing demand and supply in the talent market are forcing
                  organizations to hire more; Lower productivity and increased
                  training/transition costs; Inability to plan and forecast new
                  business and Staff alignment.
                </p>
              </div>
              <div className="col-4 item">
                <figure>
                  <img
                    src={`/img/svg/icons/increasing-innovation-icon.svg`}
                    alt="Lack of Innovation Icon"
                  />
                </figure>
                <h3 className="font-weight600">Lack of Innovation</h3>
                <p>
                  Increasing and encouraging innovative solutions; organizations
                  don’t have time or money to innovate in new market areas;
                  championing open innovation from the crowd — the large pool of
                  community talent; crowdsourcing ideas beyond borders.
                </p>
              </div>
            </div>
          </section>
          <section className="breaker-container">
            <figure className="bg-banner">
              <img src={`/img/agnitor-breaker-img.jpg`} alt="Rome" />
            </figure>
            <div className="row page-wrap">
              <div className="col-6 left-block">
                <p>
                  "Crowdsourcing has proven advantageous for generating creative
                  ideas, achieving scientific breakthroughs, and raising
                  awareness or pooling knowledge among key stakeholder groups."
                  — Gartner
                </p>
              </div>
              <div className="col-6 right-block">
                <ul>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/agnitor-logo.svg`}
                        alt="Agnitor Logo"
                      />
                    </figure>
                    <span>Expertise Crowd</span>
                  </li>
                  <li>
                    <figure>
                      <img
                        src={`/img/svg/icons/opencoder-logo.svg`}
                        alt="Opencoder Logo"
                      />
                    </figure>
                    <span>Coding Talent Crowd</span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="new-model-container">
            <h2 className="section-main-heading">
              AGNITOR, A{" "}
              <span className="highlight">NEW MODEL FOR CROWDSOURCING</span>
            </h2>
            <span className="section-desc">
              A mounting body of research on the performance of crowdsourcing
              systems has shown significant gains for organizations in terms of
              speed, quality, and quantity.
            </span>
            <span className="section-desc">
              Agnitor offers end-to-end assistance in scoping a problem,
              preparing it for an elite crowd, providing legal support around
              issues such as intellectual property, instituting quality
              controls, and receiving solutions from the crowd.
            </span>
            <span className="section-desc">
              The Crowdsourcing now is a mature and tested concept. Agnitor
              Crowd Consultants advise the clients with few recommendations and
              best practices before embarking on this journey.
            </span>
            <div className="row page-wrap">
              <div className="col-3 item" id="agnitor-iconss">
                <figure>
                  <img
                    src={`/img/svg/icons/identify-problem-icon.svg`}
                    alt="Identify Problems Icon"
                  />
                </figure>
                <h3 className="font-weight600">Identify Problems</h3>
                <p>
                  Virtuos Agnitor Consultants can find problems that are better
                  solved by the crowd. Usually these problems involve nuances
                  that are difficult to standardize or automate, or they require
                  specific skills that are unavailable or in scarcity.
                </p>
              </div>
              <div className="col-3 item" id="agnitor-iconss">
                <figure>
                  <img
                    src={`/img/svg/icons/choose-approches-icon.svg`}
                    alt="Choose Approaches Icon"
                  />
                </figure>
                <h3 className="font-weight600">Choose Approaches</h3>
                <p>
                  We help organizations in choosing an appropriate crowdsourcing
                  approach. Understanding the contours of a given problem facing
                  the IT environment leads to the selection of the right
                  crowdsourcing approach.
                </p>
              </div>
              <div className="col-3 item" id="agnitor-iconss">
                <figure>
                  <img
                    src={`/img/svg/icons/study-crowd-icon.svg`}
                    alt="Study the Crowd Icon"
                  />
                </figure>
                <h3 className="font-weight600">Study the Crowd</h3>
                <p>
                  We work with the clients to study the crowd, learning the
                  needs, desires, and expectations that can be accomplished
                  through Agnitor Expertise Crowd (AEC) or any other crowds.
                  Major activities include the designing of AEC workflow, and
                  align resources.
                </p>
              </div>
              <div className="col-3 item" id="agnitor-iconss">
                <figure>
                  <img
                    src={`/img/svg/icons/account-risk-icon.svg`}
                    alt="Account for Risks Icon"
                  />
                </figure>
                <h3 className="font-weight600">Account for Risks</h3>
                <p>
                  Any crowdsourcing can present significant pitfalls, including
                  out-of-control monetary and labour costs, security
                  vulnerabilities, and quality control issues. But with AEC,
                  there are collateral benefits that come with well-executed{" "}
                  <NavLink to={`/consultare/pace-layered/`} className="link">
                    Virtuos.PLUS&nbsp;
                  </NavLink>
                  project methodology.
                </p>
              </div>
            </div>
            <div className="row page-wrap">
              <div className="col-12 note">
                At Virtuos Agnitor, our team is meticulous in designing
                crowdsourcing activities. The skeleton framework of an Agnitor
                Expertise Crowd (AEC) is flexible enough to tackle many problems
                in the Digital Transformation realm.
              </div>
            </div>
          </section>
          <section className="why-container light-blue-bg">
            <h2 className="section-main-heading">
              <span className="highlight">WHY AGNITOR?</span> WHERE TO
              CROWDSOURCE?
            </h2>
            <span className="section-desc">
              Agnitor is better suited to address some problem types through
              crowdsourcing over regular outsourcing.
            </span>
            <span className="section-desc">
              Generally, there are two types of problems that can be
              crowdsourced, and each problem type can be further divided into
              two crowdsourcing approaches based on the nature of the work to be
              done. The Decision tree below illustrates the four crowdsourcing
              approaches that align with different kinds of problems, divided
              between information management problems, and ideation problems.
            </span>
            <div className="row page-wrap">
              <div className="col-12">
                <h3>
                  A DECISION TREE ILLUSTRATING PROBLEM TYPES FOR OUTSOURCING.
                </h3>
                <figure>
                  <img
                    src={`/img/svg/decision-tree-diagram-img.svg`}
                    alt="Desicion Tree Diagram"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="four-blocks-container">
            <div className="row page-wrap items-container">
              <div className="col-3 item">
                <figure>
                  <img src={`/img/svg/knowledge-discovery-img.svg`} alt="" />
                </figure>
                <h3>The Knowledge Discovery and Management Approach</h3>
                <p>
                  The Knowledge Discovery and Management (KDM) amplifies an
                  organization’s ability to find and gather critical information
                  into a single place by tasking the Agnitor Crowd with
                  on-the-ground reconnaissance. For example Agnitor KDM Crowd
                  works very well for generating Knowledge base and conducting
                  audits within organizations.
                </p>
              </div>
              <div className="col-3 item">
                <figure>
                  <img src={`/img/svg/broadcast-search-img.svg`} alt="" />
                </figure>
                <h3>Broadcast Search</h3>
                <p>
                  Broadcast search is an ideation problem which is quite
                  different from a management problem. An organization would
                  turn to a broadcast search crowdsourcing approach if their
                  internal R&amp;D or Analytics teams are unable to find a
                  solution for any Data Science or blockchain, AI / Machine
                  Learning or most advanced challenges.
                </p>
              </div>
              <div className="col-3 item">
                <figure>
                  <img src={`/img/svg/human-intelligence-img.svg`} alt="" />
                </figure>
                <h3>Distributed Human Intelligence Tasking</h3>
                <p>
                  The Distributed Human Intelligence Tasking (DHIT) approach
                  involves an organization using a crowd to assist in the
                  analysis of a dataset that’s already in-hand and has been
                  decomposed into smaller microtasks. For example tasks related
                  to verification, cleaning, labeling or transformation of data
                  (most commonly referred as Data Crowd).
                </p>
              </div>
              <div className="col-3 item">
                <figure>
                  <img src={`/img/svg/creative-production-img.svg`} alt="" />
                </figure>
                <h3>Peer-Vetted Creative Production</h3>
                <p>
                  The Peer-Vetted Creative Production (PVCP) will be used to
                  provide both a creation solution to a given problem brief and
                  as assessment of other ideas that have been submitted. The
                  PVCP process allows crowds to have a say in creating and
                  vetting ideas from which they will ultimately benefit.
                  Examples include conducting a poll for a creative logo,
                  advertising, etc.
                </p>
              </div>
            </div>
          </section>
          <section className="contact-agnitor-container grey-bg">
            <h2 className="section-main-heading">
              GET IN TOUCH WITH <span className="highlight">AGNITOR</span>
            </h2>
            <span className="section-desc">
              Agnitor — the first of its kind crowdsourcing platform will be
              live with its beta very soon.
            </span>
            <span className="section-desc">
              At Virtuos, we are continuously progressing to use Agnitor as one
              of the key differentiators to help organizations access expert
              services and deliver increased value to their customers.
              Currently, we are accepting candidates who have proficiency in any
              of the following fields to be a member of Agnitor.
            </span>
            <ul className="tags">
              <li>Artificial Intelligence</li>
              <li>Customer Experience</li>
              <li>Cloud Infrastructure</li>
              <li>Management</li>
              <li>Industry 4.0 Expertise</li>
              <li>Leadership</li>
              <li>Digital Transformation</li>
              <li>Strategic Advisory</li>
              <li>Startups</li>
              <li>Media and Digital Marketing</li>
              <li>Financial Re-Engineering</li>
              <li>Business Continuity</li>
            </ul>
            {/*  */}
            <div className="row page-wrap">
              <div className="col-12">
                <ContactUs />
              </div>
            </div>
          </section>
          {/*?php include '../footer.php';?*/}
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Nav />
      </div>
    </div>
  );
};

export default Agnitor;
